import { render, staticRenderFns } from "./info-alert.vue?vue&type=template&id=5c6c698c&scoped=true"
import script from "./info-alert.vue?vue&type=script&lang=ts"
export * from "./info-alert.vue?vue&type=script&lang=ts"
import style0 from "./info-alert.vue?vue&type=style&index=0&id=5c6c698c&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c6c698c",
  null
  
)

export default component.exports