<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { downloadFile } from '@/helpers/file-download-helper';
import { useCertificateManagementStore } from '../store';
import { CourseCertificate, GetCertificatePDFAsAppManagerQuery } from '../types';

@Component
export default class CertificateList extends Vue {

  readonly store = useCertificateManagementStore();

  get isLoading(): boolean {
    return this.store.isGetCertificatesProcessing;
  }

  get isNoInvitesVisible(): boolean {
    return !this.isLoading && this.store.certificates.length === 0;
  }

  mounted(): void {
    this.store.getCertificates()
      .catch((error) => showErrorResponse(error));
  }

  certificateDownloadClicked(courseCertificate: CourseCertificate): void {
    const query: GetCertificatePDFAsAppManagerQuery = {
      courseCertificateId: courseCertificate.courseCertificateId,
    };
    this.store.getCertificatePDFAsAppManager(query)
      .then((fileResponse) => downloadFile(fileResponse.data, fileResponse.contentType, 'Teilnahmebescheinigung.pdf'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <v-card class="mb-4" outlined :loading="isLoading">

    <v-list flat class="py-0">
      <v-list-item class="pl-4" v-if="isNoInvitesVisible" disabled>
        <v-list-item-content>
          Noch keine Benutzer eingeladen
        </v-list-item-content>
      </v-list-item>

      <template v-for="(courseCertificate, index) in store.certificates">

        <v-list-item
          class="pl-4"
          :ripple="false"
          :key="`${courseCertificate.courseCertificateId}-item`"
        >
          <v-list-item-content>
            <span class="d-block">Bescheinigung für {{ courseCertificate.userEmailAddress }}</span>
            <span class="d-block font-italic mt-1">
              Erstellt am {{ courseCertificate.createdAt | formatDate }} um {{ courseCertificate.createdAt | formatTime }} Uhr
            </span>
          </v-list-item-content>
          <v-list-item-icon class="mb-0">
            <icon-button
              small
              :icon="['far', 'download']"
              tooltip="Teilnahmebescheinigung herunterladen"
              @click="certificateDownloadClicked(courseCertificate)"
            />
          </v-list-item-icon>
        </v-list-item>

        <v-divider
          v-if="index !== store.certificates.length - 1"
          :key="`${courseCertificate.courseCertificateId}-divider`"
        />

      </template>
    </v-list>
  </v-card>

</v-container>
</template>
