<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { DialogWidth, NotificationType, notificationTypes } from '@/helpers/data';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { UpdateNotificationSettingsCommand } from '@/application/whitelabel/authentication/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { AppType } from '@/types';

interface Controls extends FormControls {
  isPushHabitReminderEnabled: FormControl<boolean>;
  isEmailHabitReminderEnabled: FormControl<boolean>;
  isPushModuleUnlockedEnabled: FormControl<boolean>;
  isEmailModuleUnlockedEnabled: FormControl<boolean>;
}

@Component
export default class UpdateNotificationSettingsDialog extends Vue {

  readonly store = useAuthenticationStore();

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isModuleUnlockedVisible(): boolean {
    return this.store.appType === AppType.COURSE;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        isEmailHabitReminderEnabled: {
          label: 'E-Mail',
          value: this.store.user!.notificationSettings.includes(notificationTypes.EMAIL_HABIT_REMINDER),
        },
        isPushHabitReminderEnabled: {
          label: 'Push Notification',
          value: this.store.user!.notificationSettings.includes(notificationTypes.PUSH_HABIT_REMINDER),
        },
        isEmailModuleUnlockedEnabled: {
          label: 'E-Mail',
          value: this.store.user!.notificationSettings.includes(notificationTypes.EMAIL_MODULE_UNLOCKED),
        },
        isPushModuleUnlockedEnabled: {
          label: 'Push Notification',
          value: this.store.user!.notificationSettings.includes(notificationTypes.PUSH_MODULE_UNLOCKED),
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const notificationSettings: NotificationType[] = [];
    if (this.store.appType === AppType.COURSE) {
      if (formValues.isEmailHabitReminderEnabled!) {
        notificationSettings.push(notificationTypes.EMAIL_HABIT_REMINDER);
      }
      if (formValues.isPushHabitReminderEnabled!) {
        notificationSettings.push(notificationTypes.PUSH_HABIT_REMINDER);
      }
      if (formValues.isEmailModuleUnlockedEnabled!) {
        notificationSettings.push(notificationTypes.EMAIL_MODULE_UNLOCKED);
      }
      if (formValues.isPushModuleUnlockedEnabled!) {
        notificationSettings.push(notificationTypes.PUSH_MODULE_UNLOCKED);
      }
    } else {
      if (formValues.isEmailHabitReminderEnabled!) {
        notificationSettings.push(notificationTypes.EMAIL_HABIT_REMINDER);
      }
      if (formValues.isPushHabitReminderEnabled!) {
        notificationSettings.push(notificationTypes.PUSH_HABIT_REMINDER);
      }
    }

    const command: UpdateNotificationSettingsCommand = {
      notificationSettings,
    };

    this.store.updateNotificationSettings(command)
      .then(() => showSuccessMessage('Einstellungen für Benachrichtigungen wurden aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isUpdateNotificationSettingsProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Benachrichtungen anpassen</v-card-title>
      <v-card-text>

        <v-row>
          <v-col cols="12" class="mb-4">
            <span class="d-block label mb-2">Erinnerung an Gewohnheit</span>
            <a-checkbox-form-control
              :form-control="form.controls.isPushHabitReminderEnabled"
              messages-are-hidden
            />
            <a-checkbox-form-control
              :form-control="form.controls.isEmailHabitReminderEnabled"
              messages-are-hidden
            />
          </v-col>
          <v-col cols="12" class="mb-4" v-if="isModuleUnlockedVisible">
            <span class="d-block label mt-4 mb-2">Neues Modul wurde freigeschalten</span>
            <a-checkbox-form-control
              :form-control="form.controls.isPushModuleUnlockedEnabled"
              messages-are-hidden
            />
            <a-checkbox-form-control
              :form-control="form.controls.isEmailModuleUnlockedEnabled"
              messages-are-hidden
            />
          </v-col>
        </v-row>

      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isUpdateNotificationSettingsProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isUpdateNotificationSettingsProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Aktualisieren
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
