<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showSuccessMessage } from '@/application/common/snackbar/service';

@Component
export default class RegistrationLinkDialog extends Vue {

  @Prop({ type: String, required: true })
  readonly registrationLink!: string;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  copyLinkConfirmed(): void {
    navigator.clipboard.writeText(this.registrationLink);

    showSuccessMessage('Link wurde in die Zwischenablage kopiert.');

    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
>
  <template #activator="{ on }">
    <div class="d-inline-block" v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <v-card-title>Link zum Abschließen der Registrierung</v-card-title>
    <v-card-text>
      <p>
        Über folgenden Link, kann der Benutzer die Registrierung abschließen.
      </p>
      <pre class="mb-4">{{ registrationLink }}</pre>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <a-execute-primary-action-button @click="copyLinkConfirmed">
        Link kopieren
      </a-execute-primary-action-button>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
<style lang="sass" scoped>
pre
  width: 100%
  white-space: pre-wrap
  padding: 1rem
  background: var(--color-grey-9)
</style>
