<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import dayjs, { Dayjs } from 'dayjs';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { floorToMinutes } from '@/helpers/date-helpers';
import { atLeastOneRequiredRule, constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useNutritionDiaryStore } from '../store';
import { CreateNutritionDiaryEntryCommand, NutritionDiaryItemType } from '../types';

interface Controls extends FormControls {
  consumedAt: FormControl<Dayjs>,
  image: FormControl<Blob>,
  description: FormControl<string>,
}

@Component
export default class CreateNutritionDiaryEntryDialog extends Vue {

  readonly store = useNutritionDiaryStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        consumedAt: {
          label: 'Zeitpunkt',
          value: floorToMinutes(dayjs(), 5),
          isRequired: true,
        },
        image: {
          label: 'Bild',
          rules: [],
          value: null,
          validateFormControlsOnInput: [
            'description',
          ],
        },
        description: {
          label: 'Beschreibung',
          rules: [],
          value: null,
          validateFormControlsOnInput: [
            'image',
          ],
        },
      },
    });

    form.controls.image.rules!.push(atLeastOneRequiredRule(
      [form.controls.description],
      'Bild oder Beschreibung ist erforderlich'
    ));
    form.controls.description.rules!.push(atLeastOneRequiredRule(
      [form.controls.image],
      'Bild oder Beschreibung ist erforderlich'
    ));

    return form;
  }

  isDateAllowed(date: Dayjs): boolean {
    const today = dayjs().startOf('day');

    return date.isSame(today, 'day')
      || date.isBefore(today, 'day');
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateNutritionDiaryEntryCommand = {
      body: {
        consumedAt: formValues.consumedAt!,
        description: formValues.description,
      },
      files: {
        imageFile: formValues.image,
      },
    };

    const doesFirstTimelineEntryIncludeTipBeforeCreate = this.store.timelineEntries.length > 0
      && this.store.timelineEntries[0].items.length > 0
      && this.store.timelineEntries[0].items[0].type === NutritionDiaryItemType.TIP;

    this.store.createNutritionDiaryEntry(command)
      .then(() => showSuccessMessage('Eintrag wurde hinzugefügt.'))
      .then(() => {
        const doesFirstTimelineEntryIncludeTipAfterCreate = this.store.timelineEntries.length > 0
          && this.store.timelineEntries[0].items.length > 0
          && this.store.timelineEntries[0].items[0].type === NutritionDiaryItemType.TIP;
        if (!doesFirstTimelineEntryIncludeTipBeforeCreate
          && doesFirstTimelineEntryIncludeTipAfterCreate
        ) {
          this.$emit('tip-added');
        }
      })
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateNutritionDiaryEntryProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Eintrag erstellen</v-card-title>
      <v-card-text>
        <p>
          Der Eintrag benötigt ein Bild oder eine Beschreibung. Du kannst auch beides hinterlegen.
        </p>

        <v-row>
          <v-col cols="12">
            <a-date-time-form-control
              :form-control="form.controls.consumedAt"
              :is-date-allowed="isDateAllowed"
            />
          </v-col>

          <v-col cols="12">
            <a-image-cropper-form-control
              :form-control="form.controls.image"
              :image-width="300"
            />
          </v-col>

          <v-col cols="12">
            <a-textarea-form-control
              :form-control="form.controls.description"
              :rows="4"
            />
          </v-col>
        </v-row>

      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateNutritionDiaryEntryProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateNutritionDiaryEntryProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Eintragen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
