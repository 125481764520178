<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import CreateStrategyDialog from './create-strategy-dialog.vue';
import UpdateStrategyDialog from './update-strategy-dialog.vue';

@Component({
  components: {
    CreateStrategyDialog,
    UpdateStrategyDialog,
  },
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class StrategiesFormControl extends Vue implements FormControlComponent<string[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<string[]>;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: string[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  createItem(item: string): void {
    this.internalValue.push(item);

    this.blurred();

    internalValuesChanged(this);
  }

  updateItem(index: number, item: string): void {
    this.internalValue.splice(index, 1, item);

    this.blurred();

    internalValuesChanged(this);
  }

  deleteItem(index: number): void {
    this.internalValue.splice(index, 1);

    this.blurred();

    internalValuesChanged(this);
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? []
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<string[]> {
    return this.internalValue.length > 0
      ? this.internalValue
      : null;
  }

}
</script>
<template>
<div class="form-control strategies-form-control" v-bind="$attrs">
  <fieldset class="a-field pt-2 pb-4">
    <legend>{{ labelWithRequiredIndicator(formControl) }}</legend>
    <div class="form-control-input">
      <div class="strategies">
        <div
          class="strategy d-flex"
          v-for="(item, index) in internalValue"
          :key="index"
        >
          <div class="content d-flex flex-grow-1">
            <div class="number">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="description" v-html="item" />
          </div>
          <div class="actions flex-shrink-0 pl-2">
            <update-strategy-dialog
              :strategy="item"
              @submitted="updateItem(index, $event)"
              @closed="blurred"
            >
              <icon-button
                :icon="['far', 'pencil']"
                tooltip="Anpassen"
                small
              />
            </update-strategy-dialog>
            <icon-button
              :icon="['far', 'times']"
              @click="deleteItem(index)"
              tooltip="Löschen"
              small
            />
          </div>
        </div>
      </div>
      <create-strategy-dialog
        @submitted="createItem"
        @closed="blurred"
      >
        <a-execute-secondary-action-button
          @click="focused"
          is-small
          class="mt-2"
        >
          Strategie hinzufügen
        </a-execute-secondary-action-button>
      </create-strategy-dialog>
    </div>
  </fieldset>

    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
</div>
</template>
<style lang="sass" scoped>
.strategies
  .strategy
    display: flex
    flex-direction: row
    margin-top: 0.5rem
    margin-bottom: 0.5rem

    .number
      flex-basis: 55px
      flex-shrink: 0
      flex-grow: 0

      span
        display: block
        width: 40px
        height: 40px
        line-height: 40px
        text-align: center
        font-weight: 700
        font-family: Montserrat, sans-serif
        border-radius: 50%
        color: var(--color-white)
        background: var(--color-brand)

    .description::v-deep
      flex-grow: 1

      p
        margin-bottom: 0
</style>
