<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { VuetifySelectItem } from '@/application/types';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { formatDate, formatTime } from '@/filters/dates';
import { useReferencesStore } from '@/private/management/references/store';
import { useAdminUserManagementStore } from '../store';
import { GetImpersonationURLForUserQuery, User } from '../types';

@Component
export default class UserList extends Vue {

  readonly store = useAdminUserManagementStore();
  readonly referencesStore = useReferencesStore();

  get appSelectItems(): VuetifySelectItem<any>[] {
    return this.referencesStore.apps.map((appReference) => ({
      text: appReference.title,
      value: appReference.appId,
    }));
  }

  mounted(): void {
    this.referencesStore.getAppReferences()
      .then(() => {
        this.store.updateCurrentApp(this.referencesStore.apps[0].appId)
          .catch((error) => showErrorResponse(error));
      })
      .catch((error) => showErrorResponse(error));
  }

  appWasSelected(appId: string): void {
    this.store.updateCurrentApp(appId)
      .catch((error) => showErrorResponse(error));
  }

  lastAuthenticated(user: User): string {
    return user.lastAuthenticatedAt
      ? `Zuletzt aktiv am ${formatDate(user.lastAuthenticatedAt)} um ${formatTime(user.lastAuthenticatedAt)} Uhr.`
      : 'Noch nie angemeldet.';
  }

  impersonateUserWasClicked(user: User): void {
    const query: GetImpersonationURLForUserQuery = {
      userId: user.userId,
    };

    this.store.getImpersonationURLForUser(query)
      .then((impersonationURL) => {
        window.open(impersonationURL, '_blank');
      })
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <v-card outlined class="mb-4">
    <v-card-text>
      <v-autocomplete
        label="App"
        :items="appSelectItems"
        :value="store.currentAppId"
        @change="appWasSelected"
        outlined
        hide-details
      />
    </v-card-text>
  </v-card>

  <template>

    <v-card outlined>
      <a-action-list
        :items="store.usersForCurrentApp"
        item-id="userId"
        :is-loading="store.isGetUsersForAppProcessing"
        empty-list-message="Noch keine Benutzer die diese App nutzen."
      >
        <template #content="{ item: user }">
          <span class="item-title">
            <font-awesome-icon
              v-if="user.isAppManager"
              :icon="['fas', 'user-tie']"
            />
            {{ user.emailAddress }}
          </span>
          <span class="details">
            {{ lastAuthenticated(user) }}
          </span>
        </template>
        <template #actions="{ item: user }">
          <a-execute-secondary-action-button
            @click="impersonateUserWasClicked(user)"
            :is-processing="store.isGetImpersonationURLForUserProcessingForUser(user.userId)"
            is-small
          >
            Übernehmen
          </a-execute-secondary-action-button>
        </template>
      </a-action-list>
    </v-card>

  </template>

</v-container>
</template>
