import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AdminProperty } from '@/helpers/default-parameter-helper';
import { GetUsersForAppQuery, GrantUserPaywallAccessCommand, User } from './types';

// -- Queries

export function getUsersForApp(query: GetUsersForAppQuery & AdminProperty): Promise<User[]> {
  const url = `${apiUrl}/admin-api/paywall-access/get-users-for-app-query`;
  return performApiRequest<User[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function grantUserPaywallAccess(command: GrantUserPaywallAccessCommand & AdminProperty): Promise<void> {
  const url = `${apiUrl}/admin-api/paywall-access/grant-user-paywall-access-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
