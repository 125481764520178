<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControlRule, FormControls, FormControlValue, getFormValues, ValuesOfFormControls } from '@/components/form';
import { IngredientUnit } from '@/types';
import { Ingredient } from '../types';

interface Controls extends FormControls {
  amount: FormControl<number>;
  unit: FormControl<IngredientUnit>;
  ingredient: FormControl<string>;
}

function requiredWhenNotToTasteRule(ingredientUnitFormControl: FormControl<IngredientUnit>): FormControlRule<FormControlValue<any>> {
  return (value) => (
    ingredientUnitFormControl.value === IngredientUnit.TO_TASTE
    || value !== null
  ) ? true
    : 'Eine Menge ist erforderlich, wenn die Einheit nicht "Nach Geschmack" ist.';
}

@Component
export default class CreateIngredientDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.largeNested;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isAmountVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.unit !== IngredientUnit.TO_TASTE;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        amount: {
          label: 'Menge',
          value: null,
          rules: [],
        },
        unit: {
          label: 'Einheit',
          value: IngredientUnit.PIECE,
          isRequired: true,
          transformValuesOnInput: (values: ValuesOfFormControls<Controls>): Partial<ValuesOfFormControls<Controls>> => {
            if (values.unit === IngredientUnit.TO_TASTE) {
              return {
                amount: null,
              };
            }

            return {};
          },
          validateFormControlsOnInput: [
            'amount',
          ],
        },
        ingredient: {
          label: 'Zutat',
          value: null,
          isRequired: true,
        },
      },
    });

    form.controls.amount.rules!.push(requiredWhenNotToTasteRule(form.controls.unit));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const ingredient: Ingredient = {
      amount: formValues.amount,
      unit: formValues.unit!,
      ingredient: formValues.ingredient!,
    };

    this.$emit('submitted', ingredient);

    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
    this.$emit('closed');
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Zutat hinzufügen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <a-number-form-control
              v-show="isAmountVisible"
              :form-control="form.controls.amount"
              is-autofocused
            />
          </v-col>
          <v-col cols="12" md="6">
            <a-ingredient-unit-form-control :form-control="form.controls.unit" />
          </v-col>
          <v-col cols="12">
            <a-text-form-control :form-control="form.controls.ingredient" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button @click="closeDialog" />
        <a-submit-primary-action-button :is-disabled="isSubmitDisabled">
          Hinzufügen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
