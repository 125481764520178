import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"6"}},[_c(VFlex,{staticClass:"login"},[_c(VCard,{staticClass:"pt-2 mt-0 mt-sm-4",attrs:{"outlined":""}},[_c(VCardText,[(_vm.form)?_c('a-form',{attrs:{"form":_vm.form}},[_c('a-email-address-form-control',{attrs:{"form-control":_vm.form.controls.emailAddress,"is-autofocused":"","autocomplete":"username"}}),_c('a-password-form-control',{attrs:{"form-control":_vm.form.controls.password,"autocomplete":"current-password"}}),_c('div',{staticClass:"mt-2"},[_c('a-submit-primary-action-button',{attrs:{"is-disabled":_vm.isSubmitDisabled,"is-processing":_vm.adminAuthenticationState.isLoginProcessing}},[_vm._v(" Anmelden ")])],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }