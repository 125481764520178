<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { isNavigationFailure } from 'vue-router';
import { useRouter } from 'vue-router/composables';
import { showError } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { isAndroid, isNativeApplication } from '@/helpers/detection-helpers';
import RequestCompanyAccessDialog from '@/public/contact/components/request-company-access-dialog.vue';
import CreateContactMessageDialog from '@/public/contact/components/create-contact-message-dialog.vue';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { AppType } from '@/types';
import { LoginAction } from '../types';

const authenticationStore = useAuthenticationStore();
const router = useRouter();

interface Controls extends FormControls {
  emailAddress: FormControl<string>;
  password: FormControl<string>;
}
const form = ref<Form<Controls> | null>(null);

const isSubmitDisabled = computed<boolean>(() => !form.value || !form.value.isValid);

const isCompanyRegistrationVisible = computed<boolean>(() => authenticationStore.appType === AppType.SPONSOR
  && (
    !isNativeApplication()
    || isAndroid()
  ));

const isGeneralContactVisible = computed<boolean>(() => authenticationStore.appType === AppType.COURSE);

const isTechnicalContactVisible = computed<boolean>(() => authenticationStore.appType === AppType.SPONSOR
  && (
    !isNativeApplication()
    || isAndroid()
  ));

const areStoreLinksVisible = computed<boolean>(() => !isNativeApplication()
  && (
    !!authenticationStore.appStoreLink
    || !!authenticationStore.playStoreLink
  ));

const isAppStoreLinkVisible = computed<boolean>(() => !isNativeApplication()
  && !!authenticationStore.appStoreLink);

const isPlayStoreLinkVisible = computed<boolean>(() => !isNativeApplication()
  && !!authenticationStore.playStoreLink);

onMounted(() => {
  form.value = buildForm();

  if (authenticationStore.isAuthenticated) {
    router.push({ name: 'default' });
  }
});

function buildForm(): Form<Controls> {
  return constructForm({
    submitted,
    controls: {
      emailAddress: {
        label: 'E-Mail Adresse',
        value: null,
        isRequired: true,
      },
      password: {
        label: 'Passwort',
        value: null,
        isRequired: true,
      },
    },
  });
}

function submitted(): void {
  const formValues = getFormValues(form.value!);

  const command: LoginAction = {
    emailAddress: formValues.emailAddress!,
    password: formValues.password!,
  };

  authenticationStore.login(command)
    .then(() => router.push({ name: 'default' }))
    .catch((error) => {
      if (isNavigationFailure(error)) {
        return;
      }

      // Don't show a message for disabled user as this is already done by the authentication interceptor
      if (error.response?.data.identifier !== 'user-disabled') {
        const message = error.response?.data.message || 'Die Anmeldung ist fehlgeschlagen';
        showError({ message });
      }
    });
}
</script>
<template>
<v-container>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="6">
      <v-flex class="login">
        <v-card outlined class="pt-2 mt-0 mt-sm-4">
          <v-card-text>

            <a-form
              v-if="form"
              :form="form"
            >
              <a-email-address-form-control
                :form-control="form.controls.emailAddress"
                is-autofocused
                autocomplete="username"
              />

              <a-password-form-control
                :form-control="form.controls.password"
                autocomplete="current-password"
              />

              <div class="mt-2">
                <a-submit-primary-action-button
                  :is-disabled="isSubmitDisabled"
                  :is-processing="authenticationStore.isLoginProcessing"
                >
                  Anmelden
                </a-submit-primary-action-button>
                <a-trigger-tertiary-process-button
                  :to="{ name: 'authentication-request-password-reset'}"
                  class="mt-6 mt-md-0 mb-2 mb-md-0 ml-0 ml-md-4"
                >
                  Passwort zurücksetzen
                </a-trigger-tertiary-process-button>
              </div>
            </a-form>

          </v-card-text>
        </v-card>

        <template v-if="isCompanyRegistrationVisible">
          <h4 class="mt-8">Noch kein Konto?</h4>
          <p>
            Hier kannst du einen Unternehmenskonto beantragen.
          </p>
          <request-company-access-dialog>
            <a-trigger-secondary-process-button>
              Unternehmenskonto beantragen
            </a-trigger-secondary-process-button>
          </request-company-access-dialog>
        </template>

        <template v-if="isGeneralContactVisible">
          <h4 class="mt-6">Du hast Fragen?</h4>
          <p>
            Wenn du Fragen zum Kurs oder zum Registrierungsprozess hast, kannst du dich jederzeit bei uns melden.
          </p>
          <a-trigger-secondary-process-button :to="{ name: 'unauthorized-contact-contact-introduction'}">
            Kontakt
          </a-trigger-secondary-process-button>
        </template>

        <template v-if="isTechnicalContactVisible">
          <h4 class="mt-6">Technischer Support</h4>
          <p>
            Wenn du technische Fragen haben solltest, kannst du dich jederzeit bei uns melden.
          </p>
          <create-contact-message-dialog>
            <a-trigger-secondary-process-button>
              Support anfordern
            </a-trigger-secondary-process-button>
          </create-contact-message-dialog>
        </template>

        <div class="store-links" v-if="areStoreLinksVisible">
          <a
            v-if="isAppStoreLinkVisible"
            :href="authenticationStore.appStoreLink"
            target="_blank"
          >
            <img
              alt="Laden im App Store"
              src="../../../../assets/images/stores/app-store-logo.png"
              srcset="../../../../assets/images/stores/app-store-logo@2x.png 2x"
            />
          </a>
          <a
            v-if="isPlayStoreLinkVisible"
            :href="authenticationStore.playStoreLink"
            target="_blank"
          >
            <img
              alt="Jetzt bei Google Play"
              src="../../../../assets/images/stores/google-play-logo.png"
              srcset="../../../../assets/images/stores/google-play-logo@2x.png 2x"
            />
          </a>
        </div>

      </v-flex>
    </v-col>
  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.store-links
  display: flex
  justify-content: center

  a
    display: block
    margin: 32px 16px 16px
</style>
