import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppProperty } from '@/helpers/default-parameter-helper';
import { CreateContactMessageCommand, RequestCompanyAccessCommand } from './types';

// -- Commands

export function createContactMessage(command: CreateContactMessageCommand & AppProperty): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/contact/create-contact-message-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function requestCompanyAccess(command: RequestCompanyAccessCommand & AppProperty): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/contact/request-company-access-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
