<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { UserGoalProposalId } from '@/types';
import { CreateUserGoalCommand } from '../types';
import { useUserProgressStore } from '../store';

interface Controls extends FormControls {
  userGoalProposal: FormControl<UserGoalProposalId>;
  initialScore: FormControl<number>;
}

@Component
export default class CreateUserGoalFromProposalsDialog extends Vue {

  readonly store = useUserProgressStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      if (!this.isDialogVisible) {
        this.form = null;
        return;
      }

      this.store.getUserGoalProposals()
        .then(() => {
          this.form = this.buildForm();
        })
        .catch((error) => showErrorResponse(error));
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        userGoalProposal: {
          label: 'Ziel',
          value: this.store.userGoalProposals[0].userGoalProposalId,
          isRequired: true,
        },
        initialScore: {
          label: 'Ausgangssituation',
          value: 1,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const userGoalProposalTitle = this.store.userGoalProposals
      .find((userGoalProposal) => userGoalProposal.userGoalProposalId === formValues.userGoalProposal)!
      .title;

    const command: CreateUserGoalCommand = {
      title: userGoalProposalTitle,
      initialScore: formValues.initialScore!,
    };

    this.store.createUserGoal(command)
      .then(() => showSuccessMessage('Ziel wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
  <v-dialog
    v-model="isDialogVisible"
    :max-width="dialogMaxWidth"
    :persistent="store.isCreateUserGoalProcessing"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
    :hide-overlay="$vuetify.breakpoint.smAndDown"
  >
    <template #activator="{ on }">
      <span v-on="on">
        <slot />
      </span>
    </template>
    <v-card>
      <a-form
        v-if="form"
        :form="form"
      >
        <v-card-title>Ziel auswählen</v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="12">
              <a-user-goal-proposal-form-control
                :form-control="form.controls.userGoalProposal"
                :items="store.userGoalProposals"
                is-autofocused
              />
            </v-col>
          </v-row>

          <p>
            Wie schätzt du die Ausgangssituation dieses Ziels ein?<br />
            1 ist dabei "noch ganz am Anfang" und 10 "Ich habe es bereits erreicht".
          </p>

          <a-number-slider-form-control
            :form-control="form.controls.initialScore"
            :min="1"
            :max="10"
          />

        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <a-cancel-action-button
            :is-processing="store.isCreateUserGoalProcessing"
            @click="closeDialog"
          />
          <a-submit-primary-action-button
            :is-disabled="isSubmitDisabled"
            :is-processing="store.isCreateUserGoalProcessing"
          >
            Ziel auswählen
          </a-submit-primary-action-button>
        </v-card-actions>

      </a-form>
    </v-card>
  </v-dialog>
</template>
