<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AboutNubali extends Vue {

  get kraaibeekLogoUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nubali/kraaibeek.svg');
  }

}
</script>
<template>
<v-container class="about-nubali">
  <v-row>
    <v-col md="12" lg="9">
      <v-card outlined>
        <v-card-text class="pb-0">

          <v-row>
            <v-col cols="12" sm="9" md="6">
              <img
                :src="kraaibeekLogoUrl"
                alt="Kraaibeek GmbH"
                width="100%"
                class="mt-1 mb-4"
              />
            </v-col>
          </v-row>

          <p>
            Nubali ist eine interaktive Ernährungsapp exklusiv und maßgeschneidert für die Partner*innen & Kund*innen der Kraaibeek GmbH.
          </p>
          <p>
            Die Kraaibeek GmbH ist ein inhabergeführtes Unternehmen aus Pinneberg, gegründet 2009 durch die Diplom-Oecotrophologin und Geschäftsführerin Hanna-Kathrin Kraaibeek. Wir bringen langjährige Erfahrung in der Ernährungsberatung und -therapie sowie in der Konzeption und Entwicklung von Fortbildungen und Schulungen für Ernährungsfachkräfte mit.
          </p>
          <p>
            Seit mehreren Jahren sind wir zudem aktiver Dienstleister in der betrieblichen Gesundheitsförderung. Zu unseren Kunden zählen zahlreiche Krankenkassen, Unternehmen und Institutionen, Verbände und Kliniken.
          </p>
          <p>
            Die Inhalte der App werden von den Ernährungsexpert*innen der Kraaibeek GmbH kontinuierlich nach aktuellen Erkenntnissen weiterentwickelt.
          </p>

        </v-card-text>
      </v-card>

      <a-trigger-tertiary-process-button
        :to="{ name: 'authentication-login' }"
        class="my-4"
      >
        Zurück
      </a-trigger-tertiary-process-button>

    </v-col>
  </v-row>
</v-container>
</template>
