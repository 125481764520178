import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.dialogMaxWidth,"disabled":_vm.isDisabled,"persistent":_vm.isProcessing,"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"transition":_vm.$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null,"hide-overlay":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.title))]),_c(VCardText,{staticClass:"pt-0"},[_vm._t("content")],2),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{attrs:{"is-processing":_vm.isProcessing},on:{"click":_vm.closeDialog}},[_vm._v(" Abbrechen ")]),(!_vm.highlight)?_c('a-execute-primary-action-button',{attrs:{"is-processing":_vm.isProcessing},on:{"click":_vm.confirmed}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e(),(_vm.highlight === 'warning')?_c('a-execute-warning-action-button',{attrs:{"is-processing":_vm.isProcessing},on:{"click":_vm.confirmed}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e(),(_vm.highlight === 'danger')?_c('a-execute-dangerous-action-button',{attrs:{"is-processing":_vm.isProcessing},on:{"click":_vm.confirmed}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }