import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.dialogMaxWidth,"persistent":_vm.store.isInviteUsersWithDirectLicenseProcessing},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c(VCard,[(_vm.form)?_c('a-form',{attrs:{"form":_vm.form}},[_c(VCardTitle,[_vm._v("Benutzer mit direkten Lizenzen einladen")]),_c(VCardText,[_c('p',[_vm._v(" Es darf "),_c('strong',[_vm._v("pro Zeile nur eine E-Mail Adresse")]),_vm._v(" eingegeben werden. ")]),_c(VRow,[_c(VCol,[_c('a-multi-email-address-textarea-form-control',{attrs:{"form-control":_vm.form.controls.emailAddresses,"is-autofocused":""}})],1)],1),_c(VList,{staticClass:"mb-2 pt-0",attrs:{"flat":"","dense":""}},[_c(VSubheader,[_vm._v("E-Mail Adressen die eingeladen werden")]),(_vm.isEmptyEmailAddressListVisible)?_c(VListItem,[_c(VListItemContent,[_vm._v(" Noch keine E-Mail Adressen eingegeben. ")])],1):_vm._e(),_vm._l((_vm.emailAddresses),function(emailAddress,index){return _c(VListItem,{key:index},[_c(VListItemContent,[_vm._v(" "+_vm._s(emailAddress)+" ")])],1)})],2),_c('a-info-alert',{staticClass:"mt-4"},[_vm._v(" Jeder eingeladene Benutzer wird in Rechnung gestellt."),_c('br'),_vm._v(" Benutzer die hier eingeladen werden, bekommen keine Teilnehmerbescheinigung und können nichts bei der Krankenkasse einreichen. ")])],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{attrs:{"is-processing":_vm.store.isInviteUsersWithDirectLicenseProcessing},on:{"click":_vm.closeDialog}}),_c('a-submit-primary-action-button',{attrs:{"is-processing":_vm.store.isInviteUsersWithDirectLicenseProcessing,"is-disabled":_vm.isSubmitDisabled}},[_vm._v(" "+_vm._s(_vm.submitText)+" ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }