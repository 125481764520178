import { apiUrl, performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { AppAndUserProperty, AppAndUserPropertyForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { AddNutritionDiaryEntryImageCommand, CreateNutritionDiaryEntryCommand, TimelineEntry, DeleteNutritionDiaryEntryImageCommand, UpdateNutritionDiaryEntryDescriptionCommand, DeleteNutritionDiaryEntryCommand, UpdateConsumedAtOfNutritionDiaryEntryCommand } from './types';

// -- Queries

export function getNutritionDiaryTimeline(query: AppAndUserProperty): Promise<TimelineEntry[]> {
  const url = `${apiUrl}/api/nutrition-diary/get-nutrition-diary-timeline-query`;
  return performApiRequest<TimelineEntry[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createNutritionDiaryEntry(
  command: CreateNutritionDiaryEntryCommand & AppAndUserPropertyForCommandWithFiles
): Promise<void> {
  const url = `${apiUrl}/api/nutrition-diary/create-nutrition-diary-entry-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function addNutritionDiaryEntryImage(
  command: AddNutritionDiaryEntryImageCommand & AppAndUserPropertyForCommandWithFiles
): Promise<void> {
  const url = `${apiUrl}/api/nutrition-diary/add-nutrition-diary-entry-image-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateNutritionDiaryEntryDescription(command: UpdateNutritionDiaryEntryDescriptionCommand): Promise<void> {
  const url = `${apiUrl}/api/nutrition-diary/update-nutrition-diary-entry-description-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateConsumedAtOfNutritionDiaryEntry(command: UpdateConsumedAtOfNutritionDiaryEntryCommand): Promise<void> {
  const url = `${apiUrl}/api/nutrition-diary/update-consumed-at-of-nutrition-diary-entry-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteNutritionDiaryEntryImage(command: DeleteNutritionDiaryEntryImageCommand): Promise<void> {
  const url = `${apiUrl}/api/nutrition-diary/delete-nutrition-diary-entry-image-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteNutritionDiaryEntry(command: DeleteNutritionDiaryEntryCommand): Promise<void> {
  const url = `${apiUrl}/api/nutrition-diary/delete-nutrition-diary-entry-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
