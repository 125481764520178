<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { VuetifySelectItem } from '@/application/types';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { coursePaywallAccessThroughTranslation } from '@/helpers/data';
import { useReferencesStore } from '@/private/management/references/store';
import { User } from '../types';
import { useAdminPaywallAccessStore } from '../store';
import GrantUserPaywallAccessDialog from './grant-user-paywall-access-dialog.vue';

@Component({
  components: {
    GrantUserPaywallAccessDialog,
  },
})
export default class UserList extends Vue {

  readonly store = useAdminPaywallAccessStore();
  readonly referencesStore = useReferencesStore();

  get appSelectItems(): VuetifySelectItem<any>[] {
    return this.referencesStore.apps.map((appReferences) => ({
      text: appReferences.title,
      value: appReferences.appId,
    }));
  }

  mounted(): void {
    this.referencesStore.getAppReferences()
      .then(() => {
        this.store.updateCurrentApp(this.referencesStore.apps[0].appId)
          .catch((error) => showErrorResponse(error));
      })
      .catch((error) => showErrorResponse(error));
  }

  paywallStatusDescription(user: User): string {
    return user.hasPaywallAccess ? `Zugriff durch ${coursePaywallAccessThroughTranslation[user.gotAccessThrough!]}` : 'Freemium';
  }

  appWasSelected(appId: string): void {
    this.store.updateCurrentApp(appId)
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <v-card outlined class="mb-4">
    <v-card-text>
      <v-autocomplete
        label="App"
        :items="appSelectItems"
        :value="store.currentAppId"
        @change="appWasSelected"
        outlined
        hide-details
      />
    </v-card-text>
  </v-card>

  <template>

    <v-card outlined>
      <a-action-list
        :items="store.usersForCurrentApp"
        item-id="userId"
        :is-loading="store.isGetUsersForAppProcessing"
        empty-list-message="Noch keine Benutzer die diese App nutzen."
      >
        <template #content="{ item: user }">
          <span class="item-title">{{ user.emailAddress }}</span>
          <span class="details">{{ paywallStatusDescription(user) }}</span>
        </template>
        <template #actions="{ item: user }">
          <grant-user-paywall-access-dialog :user="user" v-if="!user.hasPaywallAccess">
            <a-trigger-secondary-process-button is-small>
              Freischalten
            </a-trigger-secondary-process-button>
          </grant-user-paywall-access-dialog>
        </template>
      </a-action-list>
    </v-card>

  </template>

</v-container>
</template>
<style lang="sass" scoped>
.v-list-item
  .payment-status
    color: var(--color-grey-4)
</style>
