import Vue from 'vue';

export function formatNumber(number: any, withThousandsSeparators = true): string {
  if (typeof number !== 'number') {
    return number;
  }

  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: withThousandsSeparators,
  })
    .format(number);
}

Vue.filter('formatNumber', formatNumber);
