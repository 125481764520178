import { render, staticRenderFns } from "./blog-article-list.vue?vue&type=template&id=1456a79a&scoped=true"
import script from "./blog-article-list.vue?vue&type=script&lang=ts"
export * from "./blog-article-list.vue?vue&type=script&lang=ts"
import style0 from "./blog-article-list.vue?vue&type=style&index=0&id=1456a79a&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1456a79a",
  null
  
)

export default component.exports