import { RawLocation, Route, Location, RouteMeta } from 'vue-router';
import { Role } from '@/helpers/data';
import { AuthenticatedUser } from '@/application/whitelabel/authentication/types';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { AppType, Feature } from '@/types';

function getRedirectRouteForAuthenticatedUser(): Location {
  return { name: 'default' };
}

function getRedirectRouteForUnauthenticatedUser(): Location {
  return { name: 'root' };
}

export async function accessGuard(
  to: Route,
  from: Route,
  next: (to?: RawLocation | false | ((vm: any) => any) | void) => void
) {
  if (!to.meta) {
    throw Error('The route meta is undefined');
  }

  const authenticationStore = useAuthenticationStore();
  if (!authenticationStore.wasInitialAuthenticationAttempted) {
    await authenticationStore.getInitialAuthentication()
      .catch(() => {});
  }

  const user = authenticationStore.user;
  const appType = authenticationStore.appType ?? AppType.SPONSOR;

  const redirectLocation = nextPotentialRedirectLocation(
    to.name ?? null,
    to.meta,
    appType,
    authenticationStore.appEnabledFeatures ?? [],
    user
  );
  if (redirectLocation === null) {
    next();
  } else {
    next(redirectLocation);
  }
}

export function nextPotentialRedirectLocation(
  routeName: string | null,
  meta: RouteMeta,
  appType: AppType,
  appEnabledFeatures: Feature[],
  user: AuthenticatedUser | null
): Location | null {
  // Route is always accessible
  if (meta.alwaysAccessible) {
    return null;
  }

  const isAuthenticated = !!user;

  // User is authenticated but route is just accessible as unauthenticated user
  if (isAuthenticated && meta.requiresAuth === false) {
    return getRedirectRouteForAuthenticatedUser();
  }

  // User is unauthenticated but route is just accessible as authenticated user
  if (!isAuthenticated && meta.requiresAuth === true) {
    return getRedirectRouteForUnauthenticatedUser();
  }

  if (isAuthenticated
    && user.role.role !== Role.ROLE_APP_MANAGER
    && user.role.role !== Role.ROLE_SPONSOR_MANAGER
    && !user.hasCompletedOnboarding
    && routeName !== 'onboarding'
  ) {
    return { name: 'onboarding' };
  }

  // If user role is required to access the route
  if (meta.requiresRole) {
    const hasUserRole = user?.role.role === meta.requiresRole;

    // If user doesn't have required role and is authenticated
    if (!hasUserRole && isAuthenticated) {
      return getRedirectRouteForAuthenticatedUser();
    }

    // If user doesn't have required role and is unauthenticated
    if (!hasUserRole && !isAuthenticated) {
      return getRedirectRouteForUnauthenticatedUser();
    }
  }

  // If one or a couple roles is required to access the route
  if (meta.requiresOneOfRoles) {
    const hasOneOfRoles = isAuthenticated
      && meta.requiresOneOfRoles.includes(user.role.role);

    // If user doesn't have required role and is authenticated
    if (!hasOneOfRoles && isAuthenticated) {
      return getRedirectRouteForAuthenticatedUser();
    }

    // If user doesn't have required role and is unauthenticated
    if (!hasOneOfRoles && !isAuthenticated) {
      return getRedirectRouteForUnauthenticatedUser();
    }
  }

  // If feature is required to access the route
  if (meta.requiresFeature) {
    const hasUserAccessToFeature = isAuthenticated
      && appEnabledFeatures.includes(meta.requiresFeature);

    // If user doesn't have access to feature and is authenticated
    if (!hasUserAccessToFeature && isAuthenticated) {
      return getRedirectRouteForAuthenticatedUser();
    }

    // If user doesn't have access to feature and is unauthenticated
    if (!hasUserAccessToFeature && !isAuthenticated) {
      return getRedirectRouteForUnauthenticatedUser();
    }
  }

  // If app type is required to access the route
  if (meta.requiresAppType) {
    const isAppTypeMatching = meta.requiresAppType === appType;

    // If user doesn't have access to feature and is authenticated
    if (!isAppTypeMatching && isAuthenticated) {
      return getRedirectRouteForAuthenticatedUser();
    }

    // If user doesn't have access to feature and is unauthenticated
    if (!isAppTypeMatching && !isAuthenticated) {
      return getRedirectRouteForUnauthenticatedUser();
    }
  }

  return null;
}
