import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAdmin, attachAppAndUser } from '@/helpers/default-parameter-helper';
import { getAppReferences, getDocumentReferencesForApp, getModuleReferencesForApp } from './service';
import { AppReference, DocumentReference, ModuleReference } from './types';

interface ReferencesState {
  apps: AppReference[];
  modules: ModuleReference[];
  documents: DocumentReference[];

  getAppReferencesStatus: ActionStatus;
  getModuleReferencesForAppStatus: ActionStatus;
  getDocumentReferencesForAppStatus: ActionStatus;
}

function initialState(): ReferencesState {
  return {
    apps: [],
    modules: [],
    documents: [],

    getAppReferencesStatus: ActionStatus.None,
    getModuleReferencesForAppStatus: ActionStatus.None,
    getDocumentReferencesForAppStatus: ActionStatus.None,
  };
}

export const useReferencesStore = defineStore('references', {
  state: (): ReferencesState => initialState(),
  getters: {
    isGetAppReferencesProcessing: (state: ReferencesState): boolean =>
      state.getAppReferencesStatus === ActionStatus.InProgress,
    isGetModuleReferencesForAppProcessing: (state: ReferencesState): boolean =>
      state.getModuleReferencesForAppStatus === ActionStatus.InProgress,
    isGetDocumentReferencesForAppProcessing: (state: ReferencesState): boolean =>
      state.getDocumentReferencesForAppStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getAppReferences(): Promise<void> {
      const { getAppReferencesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAppReferencesStatus,
        () => getAppReferences(attachAdmin({}))
          .then(async (apps) => {
            this.apps = apps;
          })
      );
    },

    getModuleReferencesForApp(): Promise<void> {
      const { getModuleReferencesForAppStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getModuleReferencesForAppStatus,
        () => getModuleReferencesForApp(attachAppAndUser({}))
          .then(async (modules) => {
            this.modules = modules;
          })
      );
    },

    getDocumentReferencesForApp(): Promise<void> {
      const { getDocumentReferencesForAppStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDocumentReferencesForAppStatus,
        () => getDocumentReferencesForApp(attachAppAndUser({}))
          .then(async (documents) => {
            this.documents = documents;
          })
      );
    },

  },
});
