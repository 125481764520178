<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/application/common/breadcrumb/components/breadcrumb.vue';
import { isNativeApplication } from '@/helpers/detection-helpers';
import Snackbar from '@/application/common/snackbar/snackbar.vue';
import LocalStorageHint from '@/application/common/local-storage-hint/local-storage-hint.vue';
import AppVersionHint from '@/application/common/app-version-hint/app-version-hint.vue';
import { useAdminAuthenticationStore } from '@/application/admin/authentication/store';
import AppHeader from './app-header.vue';

@Component({
  components: {
    AppHeader,
    Breadcrumb,
    Snackbar,
    LocalStorageHint,
    AppVersionHint,
  },
})
export default class App extends Vue {

  readonly adminAuthenticationState = useAdminAuthenticationStore();

  get styleClasses(): string[] {
    const styleClasses = [
      'default',
    ];

    if (isNativeApplication()) {
      styleClasses.push('is-native');
    }

    if (this.$route.name) {
      styleClasses.push(this.$route.name);
    }

    if (this.$route.meta?.styleClass) {
      styleClasses.push(this.$route.meta.styleClass);
    }

    return styleClasses;
  }

  async mounted() {
    const head = document.getElementsByTagName('head')[0];

    const faviconLink = document.createElement('link') as HTMLLinkElement;
    faviconLink.type = 'image/png';
    faviconLink.rel = 'icon';
    faviconLink.href = `/favicon-default.png`;
    // @ts-ignore
    // noinspection JSConstantReassignment
    faviconLink.sizes = '32x32';
    head.appendChild(faviconLink);

    const icon192Link = document.createElement('link') as HTMLLinkElement;
    icon192Link.type = 'image/png';
    icon192Link.rel = 'icon';
    icon192Link.href = `/favicon-default-192x192.png`;
    // @ts-ignore
    // noinspection JSConstantReassignment
    icon192Link.sizes = '192x192';
    head.appendChild(icon192Link);

    const appleTouchIcon = document.createElement('link') as HTMLLinkElement;
    appleTouchIcon.type = 'image/png';
    appleTouchIcon.rel = 'apple-touch-icon';
    appleTouchIcon.href = `/favicon-default-192x192.png`;
    head.appendChild(appleTouchIcon);

    const androidTouchIcon = document.createElement('link') as HTMLLinkElement;
    androidTouchIcon.type = 'image/png';
    androidTouchIcon.rel = 'icon shortcut';
    androidTouchIcon.href = `/favicon-default-192x192.png`;
    head.appendChild(androidTouchIcon);

    // Update authenticated user when reopening the app
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState !== 'hidden' && this.adminAuthenticationState.isAuthenticated) {
        await this.adminAuthenticationState.fetchAuthenticatedUser()
          .catch(() => {});
      }
    });
  }

}
</script>
<template>
<div id="app" :class="styleClasses">
  <v-app>

    <app-version-hint />
    <local-storage-hint />

    <!-- Navigation -->
    <app-header />

    <!--  Content  -->
    <v-main>
      <breadcrumb />
      <v-container fluid class="main-container">
        <router-view />
      </v-container>
    </v-main>

    <!-- Snackbar -->
    <snackbar />

  </v-app>
</div>
</template>
<style lang="sass">
.container
  &.impersonated-user,
  &.demo-farm
    padding-bottom: 0

    .v-alert
      margin-bottom: 0

  &.demo-farm
    .v-alert
      background: var(--color-grey-7) !important
      color: var(--color-grey-3) !important
</style>
