if (!Promise.prototype.finally) {
  // eslint-disable-next-line no-extend-native, func-names
  Promise.prototype.finally = function (callback) {
    if (typeof callback !== 'function') {
      return this.then(callback, callback);
    }
    const P = this.constructor || Promise;
    return this.then(
      // @ts-ignore
      (value) => P.resolve(callback()).then(() => value),
      // @ts-ignore
      (err) => P.resolve(callback()).then(() => { throw err; })
    );
  };
}
