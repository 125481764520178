<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import dayjs, { Dayjs } from 'dayjs';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { AppType, PublicationType } from '@/types';
import { useBibliographyStore } from '../store';
import { Publication } from '../types';

@Component
export default class PublicationList extends Vue {

  readonly store = useBibliographyStore();
  readonly authenticationStore = useAuthenticationStore();

  get isLoading(): boolean {
    return this.store.isGetPublicationsProcessing;
  }

  get isUpdatedAtVisible(): boolean {
    return this.store.publications.length > 0;
  }

  get lastPublicationUpdated(): string {
    if (this.store.publications.length === 0) {
      return '';
    }

    return this.store.publications
      .map((publication) => publication.updatedAt)
      .reduce(
        (previousUpdatedAt: Dayjs, updatedAt: Dayjs) => updatedAt.isAfter(previousUpdatedAt)
          ? updatedAt
          : previousUpdatedAt,
        dayjs('2000-01-01')
      ).format('DD.MM.YYYY');
  }

  get isNoPublicationsVisible(): boolean {
    return !this.isLoading && this.store.publications.length === 0;
  }

  get isCourseDescriptionVisible(): boolean {
    return this.authenticationStore.appType === AppType.COURSE;
  }

  get isSponsorDescriptionVisible(): boolean {
    return this.authenticationStore.appType === AppType.SPONSOR;
  }

  mounted(): void {
    this.store.getPublications()
      .catch((error) => showErrorResponse(error));
  }

  publicationClicked(publication: Publication): void {
    if (publication.type === PublicationType.BOOK) {
      const text = `${publication.title} - ${publication.author} - ${publication.publisher}`;

      const url = `https://www.google.com/search?q=${encodeURIComponent(text)}`;
      window.open(url, '_blank');
      return;
    }

    if (publication.type === PublicationType.ONLINE) {
      window.open(publication.link, '_blank');
      return;
    }

    throw new Error('Invalid publication type');
  }

}
</script>
<template>
<v-container class="bibliography-list">
  <v-row>
    <v-col md="12" lg="9">
      <p v-if="isCourseDescriptionVisible">
        Wenn du dich noch tiefer mit dem Thema auseinander setzen möchtest haben wir dir hier eine Liste von weiterführenden Büchern. Alle Empfehlungen hier sind weiterführende Gesundheitsinformationen und nicht Teil des Präventionskurses.
      </p>
      <p v-if="isSponsorDescriptionVisible">
        Wenn du dich noch tiefer mit dem Thema auseinander setzen möchtest haben wir dir hier eine Liste von weiterführenden Quellen.
      </p>
    </v-col>
  </v-row>

  <v-progress-linear v-if="isLoading" color="primary" indeterminate />

  <p v-if="isNoPublicationsVisible">
    Noch keine Literaturliste angelegt.
  </p>

  <v-row class="mt-2 mt-md-4">
    <v-col
      cols="12"
      md="6"
      lg="4"
      v-for="publication in store.publications"
      :key="publication.publicationId"
      class="publication mb-6"
      @click="publicationClicked(publication)"
    >
      <div v-if="publication.type === 'BOOK'">
        <strong class="publication-title d-block">{{ publication.title }}</strong>
        <span class="d-block">von {{ publication.author }}</span>
        <span class="d-block">veröffentlicht {{ publication.release }} durch {{ publication.publisher }}</span>
        <span class="d-block action-link">Quelle finden</span>
      </div>
      <div v-if="publication.type === 'ONLINE'">
        <strong class="publication-title d-block">{{ publication.title }}</strong>
        <span class="d-block">{{ publication.publisher }}</span>
        <span class="d-block action-link">Quelle anzeigen</span>
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col md="12" lg="9">
      <p class="mb-0" v-if="isUpdatedAtVisible">
        Stand: {{ lastPublicationUpdated }}
      </p>
    </v-col>
  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.bibliography-list
  font-size: 0.875rem

  .publication
    cursor: pointer

    strong.publication-title
      font-weight: 700
      font-family: Montserrat, sans-serif
      color: var(--color-grey-2)

    .action-link
      color: var(--color-brand)
      font-weight: 600
</style>
