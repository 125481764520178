<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsRule, maxLengthRule, minNumberRule } from '@/components/form';
import { AppType, Feature, PreventionType } from '@/types';
import { StyleAlias } from '@/plugins/vuetify';
import { convertDecimalToCurrency } from '@/helpers/money-helper';
import { useAdminManageAppsStore } from '../store';
import { App, UpdateAppCommand } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  appType: FormControl<AppType>;
  preventionType: FormControl<PreventionType>;
  zppCourseId: FormControl<string>;
  styleAlias: FormControl<StyleAlias>;
  host: FormControl<string>;
  allowedHostsForInitialConnection: FormControl<string[]>;
  enabledFeatures: FormControl<Feature[]>;
  moduleCountForCertificate: FormControl<number>;
  moduleLengthInMinutesForCertificate: FormControl<number>;
  preventionPrinciple: FormControl<string>;
  instructorGender: FormControl<string>;
  instructorName: FormControl<string>;
  instructorSupportEmailAddress: FormControl<string>;
  senderEmailAddress: FormControl<string>;
  webPurchasePrice: FormControl<number>;
  inAppProductId: FormControl<string>;
  isEnabledForPurchase: FormControl<boolean>;
  appStoreLink: FormControl<string>;
  playStoreLink: FormControl<string>;
}

@Component
export default class UpdateAppDialog extends Vue {

  readonly store = useAdminManageAppsStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly app!: App;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Bezeichnung',
          value: this.app.title,
          isRequired: true,
        },
        appType: {
          label: 'App Typ',
          value: this.app.appType,
          isRequired: true,
        },
        preventionType: {
          label: 'Präventionsart',
          value: this.app.preventionType,
          isRequired: true,
        },
        preventionPrinciple: {
          label: 'Präventionsprinzip',
          value: this.app.preventionPrinciple,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        zppCourseId: {
          label: 'ZPP Kurs ID',
          value: this.app.zppCourseId,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        styleAlias: {
          label: 'Style',
          value: this.app.styleAlias,
          isRequired: true,
        },
        host: {
          label: 'Host',
          value: this.app.host,
          isRequired: true,
        },
        allowedHostsForInitialConnection: {
          label: 'Erlaubte Hosts für initiale Verbindung',
          value: this.app.allowedHostsForInitialConnection,
          isRequired: true,
        },
        enabledFeatures: {
          label: 'Aktivierte Features',
          value: this.app.enabledFeatures,
          isRequired: true,
        },
        moduleCountForCertificate: {
          label: 'Anzahl der Module für Teilnahmebescheinigung',
          value: this.app.moduleCountForCertificate,
          isRequired: true,
          rules: [
            minNumberRule(1),
            maxDecimalsRule(0),
          ],
        },
        moduleLengthInMinutesForCertificate: {
          label: 'Länger der Module in Minuten für Teilnahmebescheinigung',
          value: this.app.moduleLengthInMinutesForCertificate,
          isRequired: true,
          rules: [
            minNumberRule(1),
            maxDecimalsRule(0),
          ],
        },
        instructorGender: {
          label: 'Kursleiter Anrede',
          value: this.app.instructorGender,
          isRequired: true,
        },
        instructorName: {
          label: 'Kursleiter Name',
          value: this.app.instructorName,
          isRequired: true,
        },
        instructorSupportEmailAddress: {
          label: 'Support E-Mail Adresse',
          value: this.app.instructorSupportEmailAddress,
          isRequired: true,
        },
        senderEmailAddress: {
          label: 'Versand E-Mail Adresse',
          value: this.app.senderEmailAddress,
          isRequired: true,
        },
        webPurchasePrice: {
          label: 'Verkaufspreis im Web',
          value: this.app.webPurchasePrice / 100,
          isRequired: true,
          rules: [
            minNumberRule(0),
            maxDecimalsRule(2),
          ],
        },
        inAppProductId: {
          label: 'In App Produkt ID',
          value: this.app.inAppProductId ?? null,
          rules: [
            maxLengthRule(255),
          ],
        },
        isEnabledForPurchase: {
          label: 'Für Verkauf freigegeben?',
          value: this.app.isEnabledForPurchase,
        },
        appStoreLink: {
          label: 'App Store Link',
          value: this.app.appStoreLink ?? null,
          rules: [
            maxLengthRule(255),
          ],
        },
        playStoreLink: {
          label: 'Play Store Link',
          value: this.app.playStoreLink ?? null,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateAppCommand = {
      appId: this.app.appId,
      title: formValues.title!,
      appType: formValues.appType!,
      preventionType: formValues.preventionType!,
      preventionPrinciple: formValues.preventionPrinciple!,
      zppCourseId: formValues.zppCourseId!,
      styleAlias: formValues.styleAlias!,
      host: formValues.host!,
      allowedHostsForInitialConnection: formValues.allowedHostsForInitialConnection!,
      enabledFeatures: formValues.enabledFeatures!,
      moduleCountForCertificate: formValues.moduleCountForCertificate!,
      moduleLengthInMinutesForCertificate: formValues.moduleLengthInMinutesForCertificate!,
      instructorGender: formValues.instructorGender!,
      instructorName: formValues.instructorName!,
      instructorSupportEmailAddress: formValues.instructorSupportEmailAddress!,
      senderEmailAddress: formValues.senderEmailAddress!,
      webPurchasePrice: convertDecimalToCurrency(formValues.webPurchasePrice!),
      inAppProductId: formValues.inAppProductId,
      isEnabledForPurchase: formValues.isEnabledForPurchase!,
      appStoreLink: formValues.appStoreLink,
      playStoreLink: formValues.playStoreLink,
    };

    this.store.updateApp(command)
      .then(() => showSuccessMessage('App wurde aktualisiert'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isUpdateAppProcessing"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>App anpassen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <a-text-form-control
              :form-control="form.controls.title"
              is-autofocused
            />
          </v-col>
          <v-col cols="12" md="6">
            <a-app-type-form-control :form-control="form.controls.appType" />
          </v-col>
          <v-col cols="12" md="6">
            <a-prevention-type-form-control :form-control="form.controls.preventionType" />
          </v-col>
          <v-col cols="12" md="6">
            <a-text-form-control :form-control="form.controls.preventionPrinciple" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <a-text-form-control :form-control="form.controls.zppCourseId" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <a-text-form-control :form-control="form.controls.host" />
          </v-col>
          <v-col cols="12" md="6">
            <a-style-alias-form-control :form-control="form.controls.styleAlias" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <a-multi-text-form-control :form-control="form.controls.allowedHostsForInitialConnection" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <a-features-form-control :form-control="form.controls.enabledFeatures" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <a-number-form-control :form-control="form.controls.moduleCountForCertificate" />
          </v-col>
          <v-col cols="12" sm="6">
            <a-number-form-control :form-control="form.controls.moduleLengthInMinutesForCertificate" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <a-gender-form-control :form-control="form.controls.instructorGender" />
          </v-col>
          <v-col cols="12" md="6">
            <a-text-form-control :form-control="form.controls.instructorName" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <a-email-address-form-control :form-control="form.controls.instructorSupportEmailAddress" />
          </v-col>
          <v-col cols="12" md="6">
            <a-email-address-form-control :form-control="form.controls.senderEmailAddress" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <a-number-form-control :form-control="form.controls.webPurchasePrice" />
          </v-col>
          <v-col cols="12" md="6">
            <a-text-form-control :form-control="form.controls.inAppProductId" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <a-checkbox-form-control :form-control="form.controls.isEnabledForPurchase" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <a-text-form-control :form-control="form.controls.appStoreLink" />
          </v-col>
          <v-col cols="12" md="6">
            <a-text-form-control :form-control="form.controls.playStoreLink" />
          </v-col>
        </v-row>

      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isUpdateAppProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isUpdateAppProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Aktualisieren
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
