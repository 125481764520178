import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"user-registration"},[_c('a-info-alert',[_vm._v(" Nach der Registrierung erhältst du eine E-Mail mit einem Bestätigungslink. Dein Konto wird erst mit dem Klick auf den Link angelegt. ")]),_c(VRow,[_c(VCol,{attrs:{"md":"12","lg":"9"}},[_c(VCard,{attrs:{"outlined":""}},[(_vm.form)?_c('a-form',{attrs:{"form":_vm.form}},[_c(VCardText,{staticClass:"pt-6"},[_c('a-email-address-form-control',{attrs:{"form-control":_vm.form.controls.emailAddress,"is-autofocused":"","autocomplete":"username"}}),_c('a-checkbox-form-control',{staticClass:"mt-0",attrs:{"form-control":_vm.form.controls.acceptDataProtection}}),_c('a-checkbox-form-control',{attrs:{"form-control":_vm.form.controls.acceptTermsOfUse}})],1),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{staticClass:"mt-4 mt-md-0",attrs:{"to":{ name: 'authentication-login' }}},[_vm._v(" Zurück ")]),_c('a-submit-primary-action-button',{attrs:{"is-processing":_vm.authenticationStore.isRegisterUserProcessing,"is-disabled":_vm.isSubmitDisabled}},[_vm._v(" Registrieren ")])],1)],1):_vm._e()],1)],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VCard,{staticClass:"mt-4 mt-md-0",attrs:{"outlined":""}},[_c(VCardText,{staticClass:"py-2 px-4"},[_c(VList,{staticClass:"pt-0 pb-0",attrs:{"flat":""}},[_c(VListItem,{attrs:{"to":{ name: 'legal-data-protection' }}},[_c(VListItemIcon,{staticClass:"mr-0"},[_c('font-awesome-icon',{attrs:{"icon":['far', 'angle-right']}})],1),_c(VListItemContent,[_vm._v(" Datenschutzerklärung ")])],1),_c(VDivider),_c(VListItem,{attrs:{"to":{ name: 'legal-terms-of-use' }}},[_c(VListItemIcon,{staticClass:"mr-0"},[_c('font-awesome-icon',{attrs:{"icon":['far', 'angle-right']}})],1),_c(VListItemContent,[_vm._v(" Nutzungsbedingungen ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }