import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"recipe-list"},[_c(VRow,{staticClass:"mt-md-4"},[_c(VCol,{attrs:{"cols":"12"}},[(_vm.isLoading)?_c(VProgressLinear,{staticClass:"mb-4",attrs:{"color":"primary","indeterminate":""}}):_vm._e(),(_vm.areNoRecipesAvailable)?_c('p',[_vm._v(" Noch keine Rezepte verfügbar. ")]):_vm._e()],1),_vm._l((_vm.store.recipes),function(recipe,index){return _c(VCol,{key:recipe.recipeId,attrs:{"cols":"12","md":"6","lg":"4"}},[_c(VRow,{staticClass:"no-gutters recipe",class:{ 'mb-4': index !== _vm.store.recipes.length - 1 },on:{"click":function($event){return _vm.recipeClicked(recipe)}}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{staticClass:"overflow-hidden",attrs:{"outlined":""}},[_c('div',{staticClass:"blur-hash-image-container"},[_c('blur-hash-image',{attrs:{"hash":recipe.heroImage.hash,"src":recipe.heroImage.url,"alt":recipe.name,"width":20,"height":Math.floor(20 / recipe.heroImage.aspectRatio)}}),_c('span',{staticClass:"total-time"},[_vm._v(" "+_vm._s(recipe.totalTimeInMinutes)+"min ")])],1)])],1),_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"recipe-info"},[_c('strong',{staticClass:"recipe-title"},[_vm._v(_vm._s(recipe.name))]),(recipe.subTitle)?_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(recipe.subTitle))]):_vm._e(),_c('span',{staticClass:"d-block action-link"},[_vm._v("Rezept anzeigen")])])])],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }