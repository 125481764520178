<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { isNavigationFailure } from 'vue-router';
import { showError } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useAdminAuthenticationStore } from '../store';
import { LoginAction } from '../types';

interface Controls extends FormControls {
  emailAddress: FormControl<string>;
  password: FormControl<string>;
}

@Component
export default class Login extends Vue {

  readonly adminAuthenticationState = useAdminAuthenticationStore();

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted() {
    this.form = this.buildForm();

    if (this.adminAuthenticationState.isAuthenticated) {
      this.$router.push({ name: 'default' });
    }
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        emailAddress: {
          label: 'E-Mail Adresse',
          value: null,
          isRequired: true,
        },
        password: {
          label: 'Passwort',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: LoginAction = {
      emailAddress: formValues.emailAddress!,
      password: formValues.password!,
    };

    this.adminAuthenticationState.login(command)
      .then(() => this.$router.push({ name: 'default' }))
      .catch((error) => {
        if (isNavigationFailure(error)) {
          return;
        }

        // Don't show a message for disabled user as this is already done by the authentication interceptor
        if (error.response?.data.identifier !== 'user-disabled') {
          const message = error.response?.data.message || 'Die Anmeldung ist fehlgeschlagen';
          showError({ message });
        }
      });
  }

}
</script>
<template>
<v-container>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="6">
      <v-flex class="login">
        <v-card outlined class="pt-2 mt-0 mt-sm-4">
          <v-card-text>
            <a-form
              v-if="form"
              :form="form"
            >
              <a-email-address-form-control
                :form-control="form.controls.emailAddress"
                is-autofocused
                autocomplete="username"
              />

              <a-password-form-control
                :form-control="form.controls.password"
                autocomplete="current-password"
              />

              <div class="mt-2">
                <a-submit-primary-action-button
                  :is-disabled="isSubmitDisabled"
                  :is-processing="adminAuthenticationState.isLoginProcessing"
                >
                  Anmelden
                </a-submit-primary-action-button>
              </div>
            </a-form>
          </v-card-text>
        </v-card>

      </v-flex>
    </v-col>
  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.register-container
  border: 1px solid var(--color-grey-7)
  background: var(--color-grey-9)
  border-radius: 4px
  padding: 1.25rem
  color: var(--color-grey-2)
</style>
