import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { UserGoalProposal, CreateUserGoalProposalCommand, UpdateUserGoalProposalCommand, DeleteUserGoalProposalCommand } from './types';

// -- Queries

export function getUserGoalProposals(query: AppAndUserProperty): Promise<UserGoalProposal[]> {
  const url = `${apiUrl}/api/user-progress/get-user-goal-proposals-as-manager-query`;
  return performApiRequest<UserGoalProposal[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createUserGoalProposal(command: CreateUserGoalProposalCommand): Promise<void> {
  const url = `${apiUrl}/api/user-progress/create-user-goal-proposal-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateUserGoalProposal(command: UpdateUserGoalProposalCommand): Promise<void> {
  const url = `${apiUrl}/api/user-progress/update-user-goal-proposal-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteUserGoalProposal(command: DeleteUserGoalProposalCommand): Promise<void> {
  const url = `${apiUrl}/api/user-progress/delete-user-goal-proposal-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
