<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, requiredCheckboxRule } from '@/components/form';
import { useAuthenticationStore } from '../store';
import { IsSponsorSlugValidQuery, RegisterUserForSponsorCommand } from '../types';

interface Controls extends FormControls {
  emailAddress: FormControl<string>;
  acceptDataProtection: FormControl<boolean>;
  acceptTermsOfUse: FormControl<boolean>;
}

@Component
export default class UserRegistrationWithSponsorLink extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid
      || this.authenticationStore.sponsorSlugResponse === null
      || !this.authenticationStore.sponsorSlugResponse.isValid;
  }

  get isSponsorSlugInvalidAlertVisible(): boolean {
    if (this.authenticationStore.sponsorSlugResponse === null) {
      return false;
    }

    return !this.authenticationStore.sponsorSlugResponse.isValid;
  }

  mounted(): void {
    this.form = this.buildForm();

    const query: IsSponsorSlugValidQuery = {
      registrationLinkSlug: this.$route.params.registrationLinkSlug,
    };
    this.authenticationStore.isSponsorSlugValid(query)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        emailAddress: {
          label: 'E-Mail Adresse',
          value: null,
          isRequired: true,
        },
        acceptDataProtection: {
          label: 'Ich akzeptiere die Datenschutzbedingungen',
          value: false,
          isRequired: true,
          rules: [
            requiredCheckboxRule(),
          ],
        },
        acceptTermsOfUse: {
          label: 'Ich akzeptiere die Nutzungsbedingungen',
          value: false,
          isRequired: true,
          rules: [
            requiredCheckboxRule(),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: RegisterUserForSponsorCommand = {
      emailAddress: formValues.emailAddress!,
      registrationLinkSlug: this.$route.params.registrationLinkSlug,
    };

    this.authenticationStore.registerUserForSponsor(command)
      .then(() => showSuccessMessage('Erfolgreich abgeschickt. Bitte klicke auf den Link in der E-Mail.', 4000))
      .then(() => this.$router.push({ name: 'authentication-login' }))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>
  <a-info-alert>
    Nach der Registrierung erhältst du eine E-Mail mit einem Bestätigungslink. Dein Konto wird erst mit dem Klick auf den Link angelegt.
  </a-info-alert>

  <v-row>
    <v-col md="12" lg="9">

      <a-danger-alert v-if="isSponsorSlugInvalidAlertVisible">
        {{ authenticationStore.sponsorSlugResponse.explanation }}
      </a-danger-alert>

      <v-card outlined>
        <a-form
          v-if="form"
          :form="form"
        >
          <v-card-text class="pt-6">

            <v-row>
              <v-col cols="12">
                <a-email-address-form-control
                  :form-control="form.controls.emailAddress"
                  is-autofocused
                  autocomplete="username"
                />
              </v-col>
              <v-col cols="12">
                <a-checkbox-form-control
                  :form-control="form.controls.acceptDataProtection"
                  class="mt-0"
                />

                <a-checkbox-form-control :form-control="form.controls.acceptTermsOfUse" />
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <a-submit-primary-action-button
              :is-disabled="isSubmitDisabled"
              :is-processing="authenticationStore.isRegisterUserForSponsorProcessing"
            >
              Registrieren
            </a-submit-primary-action-button>
          </v-card-actions>
        </a-form>
      </v-card>

    </v-col>

    <v-col cols="12" md="3">
      <v-card outlined class="mt-4 mt-md-0">
        <v-card-text class="py-2 px-4">

          <v-list flat class="pt-0 pb-0">
            <v-list-item :to="{ name: 'legal-data-protection' }">
              <v-list-item-icon class="mr-0">
                <font-awesome-icon :icon="['far', 'angle-right']" />
              </v-list-item-icon>
              <v-list-item-content>
                Datenschutzerklärung
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item :to="{ name: 'legal-terms-of-use' }">
              <v-list-item-icon class="mr-0">
                <font-awesome-icon :icon="['far', 'angle-right']" />
              </v-list-item-icon>
              <v-list-item-content>
                Nutzungsbedingungen
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card-text>
      </v-card>
    </v-col>

  </v-row>
</v-container>
</template>
