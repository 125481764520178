<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TriggerSecondaryProcessButton extends Vue {

  @Prop({ type: Boolean, default: false })
  readonly isSmall!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isAlwaysShownInBlockMode!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  get isInternalButtonBlock(): boolean {
    return this.$vuetify.breakpoint.smAndDown
      || this.isAlwaysShownInBlockMode;
  }

}
</script>
<template>
<v-btn
  type="button"
  color="primary"
  :block="isInternalButtonBlock"
  v-bind="$attrs"
  v-on="$listeners"
  :disabled="isDisabled"
  :small="isSmall"
  depressed
  outlined
>
  <slot />
</v-btn>
</template>
<style lang="sass" scoped>
.v-btn
  background: white !important
</style>
