import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { CreateHabitCommand, Habit, UpdateHabitCommand } from './types';

// -- Queries

export function getHabits(query: AppAndUserProperty): Promise<Habit[]> {
  const url = `${apiUrl}/api/learning/get-habits-as-app-manager-query`;
  return performApiRequest({ url, method: 'POST', data: query });
}

// -- Commands

export function createHabit(command: CreateHabitCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-habit-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateHabit(command: UpdateHabitCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-habit-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
