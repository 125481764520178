<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import CreateAppDialog from './create-app-dialog.vue';
import UpdateAppDialog from './update-app-dialog.vue';
import { useAdminManageAppsStore } from '../store';

@Component({
  components: {
    CreateAppDialog,
    UpdateAppDialog,
  },
})
export default class AppList extends Vue {

  readonly store = useAdminManageAppsStore();

  get isLoading(): boolean {
    return this.store.isGetAppsProcessing;
  }

  mounted(): void {
    this.store.getApps()
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <v-progress-linear v-if="isLoading" color="primary" indeterminate />

  <template v-if="!isLoading">

    <create-app-dialog>
      <a-trigger-primary-process-button>
        App erstellen
      </a-trigger-primary-process-button>
    </create-app-dialog>

    <v-card
      outlined
      class="mt-4"
    >
      <a-action-list
        :items="store.apps"
        item-id="appId"
        empty-list-message="Noch keine Apps angelegt."
      >
        <template #content="{ item: app }">
          <span class="item-title">{{ app.title }}</span>
          <span class="details">{{ app.host }}</span>
        </template>
        <template #actions="{ item: app }">
          <update-app-dialog :app="app">
            <a-trigger-secondary-process-button is-small>
              Anpassen
            </a-trigger-secondary-process-button>
          </update-app-dialog>
        </template>
      </a-action-list>
    </v-card>

  </template>

</v-container>
</template>
