import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"action-list"},[(_vm.isLoading)?_c(VProgressLinear,{attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_c(VList,{staticClass:"py-0",attrs:{"flat":""}},[(_vm.isListEmpty)?_c(VListItem,{attrs:{"disabled":""}},[_c(VListItemContent,[_vm._v(" "+_vm._s(_vm.emptyListMessage)+" ")])],1):_vm._e(),_vm._l((_vm.sortedItems),function(item,itemIndex){return [_c(VListItem,{key:_vm.key(item),staticClass:"pl-4 pr-0",attrs:{"ripple":false}},[_c(VListItemContent,[_vm._t("content",null,{"item":item,"itemIndex":itemIndex})],2),_c(VListItemAction,{staticClass:"pr-4"},[_vm._t("actions",null,{"item":item,"itemIndex":itemIndex})],2)],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }