<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Device } from '@capacitor/device';
import { OperatingSystem } from '@capacitor/device/dist/esm/definitions';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { NotificationType } from '@/helpers/data';
import { isNativeApplication } from '@/helpers/detection-helpers';
import UpdateNotificationSettingsDialog from './update-notification-settings-dialog.vue';

@Component({
  components: {
    UpdateNotificationSettingsDialog,
  },
})
export default class HabitNotificationSettings extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  readonly operatingSystemMap: Record<OperatingSystem, string> = {
    ios: 'iOS',
    android: 'Android',
    windows: 'Windows',
    mac: 'Mac',
    unknown: 'Unbekannt',
  };

  deviceIdentifier: string | null = null;
  deviceOperatingSystem: OperatingSystem | null = null;

  get isPushNotificationDisabledForAppNoticeVisible(): boolean {
    return isNativeApplication()
      && this.deviceIdentifier !== null
      && this.authenticationStore.user!.pushNotificationConfigurations.some(
        (pushNotificationConfiguration) => pushNotificationConfiguration.deviceIdentifier === this.deviceIdentifier
          && !pushNotificationConfiguration.isAccepted
      );
  }

  isTypeActive(notificationType: NotificationType): boolean {
    return this.authenticationStore.user!.notificationSettings.includes(notificationType);
  }

  async mounted(): Promise<void> {
    if (isNativeApplication()) {
      const deviceId = await Device.getId();
      // @ts-ignore - uuid is the fallback for the previous capacitor version.
      this.deviceIdentifier = deviceId.identifier ?? deviceId.uuid;
      this.deviceOperatingSystem = (await Device.getInfo()).operatingSystem;
    }
  }

}
</script>
<template>
<div class="notification-settings" v-bind="$attrs">

  <h3>Benachrichtigungen</h3>

  <a-info-alert v-if="isPushNotificationDisabledForAppNoticeVisible">
    Push Notifications wurden für diese App abgelehnt. Damit die Push Notifications ankommen können, musst du sie in den {{ operatingSystemMap[deviceOperatingSystem] }} Einstellungen unter Mitteilungen aktivieren.
  </a-info-alert>

  <v-row>
    <v-col cols="12" md="6" lg="4" class="mt-md-0">

      <span class="d-block">
        <span class="icon" :class="{ 'active': isTypeActive('PUSH_HABIT_REMINDER') }">
          <font-awesome-icon class="mr-1" :icon="['fas', 'bell']" />
        </span>
        <template v-if="isTypeActive('PUSH_HABIT_REMINDER')">
          Push Notification aktiviert
        </template>
        <template v-else>
          Push Notification deaktiviert
        </template>
      </span>
      <span class="d-block mt-1">
        <span class="icon" :class="{ 'active': isTypeActive('EMAIL_HABIT_REMINDER') }">
          <font-awesome-icon class="mr-1" :icon="['fas', 'envelope']" />
        </span>
        <template v-if="isTypeActive('EMAIL_HABIT_REMINDER')">
          E-Mail aktiviert
        </template>
        <template v-else>
          E-Mail deaktiviert
        </template>
      </span>

      <update-notification-settings-dialog>
        <a-trigger-secondary-process-button class="mt-4">
          Benachrichtigungen anpassen
        </a-trigger-secondary-process-button>
      </update-notification-settings-dialog>

    </v-col>
  </v-row>

</div>
</template>
<style lang="sass" scoped>
.icon
  // Inactive as the default
  color: var(--color-grey-7)

  &.active
    color: var(--color-brand)
</style>
