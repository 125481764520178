<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useUserProgressStore } from '@/private/user/user-progress/store';
import { useOnboardingStore } from '../store';
import { UserGoalProposal } from '../types';
import CreateUserGoalFromProposalDialog from './create-user-goal-from-proposal-dialog.vue';
import UserGoalProgressDummyChart from './user-goal-progress-dummy-chart.vue';

@Component({
  components: {
    UserGoalProgressDummyChart,
    CreateUserGoalFromProposalDialog,
  },
})
export default class CreateUserGoalsStep extends Vue {

  readonly store = useOnboardingStore();
  readonly authenticationStore = useAuthenticationStore();
  readonly userProgressStore = useUserProgressStore();

  get isContinueActionShownAsPrimaryAction(): boolean {
    return !!this.userProgressStore.userProgress
      && this.userProgressStore.userProgress.userGoalProgress.length > 0;
  }

  mounted(): void {
    this.store.getUserGoalProposals()
      .catch((error) => showErrorResponse(error));
  }

  continueClicked(): void {
    this.$emit('submitConfirmed');
  }

  skipClicked(): void {
    this.$emit('skipConfirmed');
  }

  isGoalProposalAlreadyAccepted(userGoalProposal: UserGoalProposal): boolean {
    return !!this.userProgressStore.userProgress
      && this.userProgressStore.userProgress.userGoalProgress
        .some((userGoalProgress) => userGoalProgress.title === userGoalProposal.title);
  }

}
</script>
<template>
<div>

  <v-card-text>

    <h2 class="mb-4">Ziele</h2>

    <strong class="d-block mt-4">Klare Ziele setzen</strong>

    <p>
      Feste Ziele erhöhen deine Erfolgschancen. Suche dir daher am besten eines oder gleich mehrere der Ziele aus dieser Liste aus.
    </p>

    <v-progress-linear
      v-if="store.isGetUserGoalProposalsProcessing"
      color="primary"
      indeterminate
    />

    <v-list dense class="pt-0 mb-2">
      <template v-for="(userGoalProposal, index) in store.userGoalProposals">
        <create-user-goal-from-proposal-dialog
          :user-goal-proposal="userGoalProposal"
          :key="userGoalProposal.userGoalProposalId"
          :is-disabled="isGoalProposalAlreadyAccepted(userGoalProposal)"
        >
          <v-list-item
            link
            class="py-1 px-1"
            :disabled="isGoalProposalAlreadyAccepted(userGoalProposal)"
          >
            <v-list-item-action class="mr-2">
              <font-awesome-icon :icon="['far', 'bullseye-arrow']" small />
            </v-list-item-action>
            <v-list-item-content>
              {{ userGoalProposal.title }}
            </v-list-item-content>
          </v-list-item>
        </create-user-goal-from-proposal-dialog>
        <v-divider
          v-if="index !== store.userGoalProposals.length - 1"
          :key="`divider-${userGoalProposal.userGoalProposalId}`"
        />
      </template>
    </v-list>

    <p>
      Du kannst deine Ziele später jederzeit ändern und auch komplett eigene formulieren.
    </p>

    <v-divider class="mt-4 mb-6" />

    <strong class="d-block mt-4">Fortschritt tracken</strong>

    <p>
      Wir fragen regelmäßig deinen Fortschritt ab, damit du deine Ziele nicht aus den Augen verlierst und die stetige Verbesserung siehst.
    </p>

    <user-goal-progress-dummy-chart />

  </v-card-text>

  <v-divider />
  <v-card-actions>
    <v-spacer />
    <a-cancel-action-button @click="skipClicked">
      Einführung überspringen
    </a-cancel-action-button>
    <a-execute-primary-action-button
      v-if="isContinueActionShownAsPrimaryAction"
      @click="continueClicked"
      :is-processing="store.isGetUserGoalProposalsProcessing"
    >
      Weiter
    </a-execute-primary-action-button>
    <a-execute-secondary-action-button
      v-else
      @click="continueClicked"
      :is-processing="store.isGetUserGoalProposalsProcessing"
    >
      Weiter
    </a-execute-secondary-action-button>
  </v-card-actions>

</div>
</template>
<style lang="sass" scoped>
strong
  font-weight: 700
  margin-bottom: 0.25rem
  font-size: 0.875rem
  font-family: Montserrat, sans-serif
  color: var(--color-grey-2)

.v-list-item
  svg
    color: var(--color-brand)

  &.v-list-item--disabled
    svg
      color: var(--color-grey-5)

.v-stepper

  .v-stepper__step
    padding-left: 1rem

    &.v-stepper__step--active
      font-weight: bold

  .v-stepper__content
    margin-left: 1.75rem !important
</style>
