<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentElementSelfReflectionForPreview } from '../types';

@Component
export default class SelfReflectionPreview extends Vue {

  @Prop({ type: Object, required: true })
  readonly contentElement!: ContentElementSelfReflectionForPreview;

  selectedSelfReflectionIndex: number|null = null;

  selectReflectionOption(index: number): void {
    this.selectedSelfReflectionIndex = index;
  }

}
</script>
<template>
<div class="self-reflection">
  <span v-html="contentElement.question" />
  <v-radio-group :value="selectedSelfReflectionIndex">
    <div class="option"
      v-for="(selfReflectionOption, index) in contentElement.selfReflectionOptions"
      :key="index"
      @click="selectReflectionOption(index)"
    >
      <div class="option-button">
        <v-radio
          :value="index"
          :disabled="!!selectedSelfReflectionIndex"
        />
      </div>
      <div class="answer">
        {{ selfReflectionOption.answer }}
        <v-expand-transition>
          <div class="reaction mt-2" v-if="selectedSelfReflectionIndex === index">
            <span v-html="selfReflectionOption.reaction" />
          </div>
        </v-expand-transition>
      </div>
    </div>
  </v-radio-group>
</div>
</template>
<style lang="sass" scoped>
.self-reflection
  background: var(--color-grey-9)
  padding: 1.25rem 1rem
  border: solid var(--color-grey-8)
  border-width: 1px 0
  color: var(--color-grey-3)

  .option
    display: flex

    .option-button
      flex-basis: 50px
      flex-shrink: 0
      flex-grow: 0
      padding-bottom: 1rem
      cursor: pointer

    .answer
      flex-grow: 1
      padding-top: 1px
      padding-bottom: 1rem
      cursor: pointer

    &:last-of-type
      .option-button,
      .answer
        padding-bottom: 0
  span
    ::v-deep
      p
        font-size: 1rem

  ::v-deep
    .v-input__slot
      margin-bottom: 0
    .v-messages
      display: none

    .v-radio.v-item--active
      .v-icon
        color: var(--color-brand)

  .reaction::v-deep
    p,
    ul,
    ol
      margin-bottom: 0.75rem

    p:last-child,
    ul:last-child,
    ol:last-child
      margin-bottom: 0
</style>
