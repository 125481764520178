import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAdmin } from '@/helpers/default-parameter-helper';
import { App } from './types';
import { getAvailableSeatsHistory } from './service';

interface AdminAccountingState {
  apps: App[]

  getAvailableSeatsHistoryStatus: ActionStatus;
}

function initialState(): AdminAccountingState {
  return {
    apps: [],

    getAvailableSeatsHistoryStatus: ActionStatus.None,
  };
}

export const useAdminAccountingStore = defineStore('adminAccounting', {
  state: (): AdminAccountingState => initialState(),
  getters: {
    isGetAvailableSeatsHistoryProcessing: (state: AdminAccountingState): boolean =>
      state.getAvailableSeatsHistoryStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getAvailableSeatsHistory(): Promise<void> {
      const { getAvailableSeatsHistoryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAvailableSeatsHistoryStatus,
        () => getAvailableSeatsHistory(attachAdmin({}))
          .then(async (apps) => {
            this.apps = apps;
          })
      );
    },

  },
});
