<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SubmitPrimaryActionButton extends Vue {

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isProcessing!: boolean;

  get isInternalButtonDisabled(): boolean {
    return this.isDisabled
      || this.isProcessing;
  }

}
</script>
<template>
<v-btn
  type="submit"
  color="primary"
  :block="$vuetify.breakpoint.smAndDown"
  v-bind="$attrs"
  v-on="$listeners"
  :disabled="isInternalButtonDisabled"
  :loading="isProcessing"
  depressed
>
  <slot />
</v-btn>
</template>
