import { RouteConfig } from 'vue-router';
import SupportIntroduction from './components/support-introduction.vue';

export const contactSupportRoutes: RouteConfig[] = [
  {
    name: 'contact-support-support-introduction',
    path: '/support',
    component: SupportIntroduction,
    meta: {
      requiresAuth: true,
      title: 'Support',
    },
  },
];
