import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.size,"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Benachrichtigungen aktivieren")]),_c(VCardText,[_c('p',[_vm._v(" Wir schicken Ihnen eine Benachrichtigung sobald ein neues Modul freigeschalten wird und erinnern Sie an Ihre Gewohnheiten. ")])]),_c(VCardActions,[_c(VSpacer),_c('a-execute-primary-action-button',{on:{"click":_vm.requestPermission}},[_vm._v(" Verstanden ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }