<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAdminAuthenticationStore } from '@/application/admin/authentication/store';

@Component
export default class DefaultRedirect extends Vue {

  readonly adminAuthenticationState = useAdminAuthenticationStore();

  mounted(): void {
    // Not authenticated
    if (!this.adminAuthenticationState.isAuthenticated) {
      this.$router.push({ name: 'root' });
      return;
    }

    this.$router.push({ name: 'admin-user-management-user-list' });
  }

}
</script>
<template>
<div id="default-redirect"></div>
</template>
