<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { VuetifySelectItem } from '@/application/types';
import { Feature } from '@/types';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class FeaturesFormControl extends Vue implements FormControlComponent<Feature[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Feature[]>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  readonly formControlId = createFormControlId();

  readonly items: VuetifySelectItem<Feature>[] = [
    { value: Feature.NUTRITION_DIARY, text: 'Ernährungstagebuch' },
    { value: Feature.INVITES, text: 'Einladungen' },
    { value: Feature.SALES_DASHBOARD, text: 'Sales' },
    { value: Feature.BIBLIOGRAPHY, text: 'Literaturverzeichnis' },
    { value: Feature.CERTIFICATE_MANAGEMENT, text: 'Teilnahmebescheinigungen' },
    { value: Feature.BLOG, text: 'Blog' },
    { value: Feature.EXPERIENCE_REPORTS, text: 'Erfahrungsberichte' },
    { value: Feature.RECIPES, text: 'Rezepte' },
    { value: Feature.DOCUMENTS, text: 'Dokumente' },
    { value: Feature.SPONSORS, text: 'Sponsoren' },
    { value: Feature.ABOUT, text: 'Über' },
    { value: Feature.GOAL_TRACKING, text: 'Ziele-Tracking' },
  ];

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: Feature[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? []
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<Feature[]> {
    return this.internalValue;
  }

}
</script>
<template>
<div class="form-control features-form-control" v-bind="$attrs">
  <div class="form-control-input">
    <v-autocomplete
      v-model="internalValue"
      :label="labelWithRequiredIndicator(formControl)"
      :items="items"
      multiple
      :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
      :autofocus="isAutofocused ? $vuetify.breakpoint.mdAndUp : false"
      @change="selectionChanged"
      @focus="focused"
      @blur="blurred"
      menu-props="auto"
      hide-details
      outlined
    />
    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
  </div>
</div>
</template>
