export const schlauerBewegerTheme = {
  primary: {
    base: '#5A5A5A',
    darken4: '#171717',
    darken3: '#282828',
    darken2: '#3E3E3E',
    darken1: '#5A5A5A',
    lighten1: '#7E7E7E',
    lighten2: '#9D9C9C',
    lighten3: '#B2B2B2',
    lighten4: '#CACACA',
    lighten5: '#F0F0F0',
  },
  secondary: {
    base: '#7E7E7E',
    darken4: '#171717',
    darken3: '#282828',
    darken2: '#3E3E3E',
    darken1: '#5A5A5A',
    lighten1: '#7E7E7E',
    lighten2: '#9D9C9C',
    lighten3: '#B2B2B2',
    lighten4: '#CACACA',
    lighten5: '#F0F0F0',
  },
  accent: {
    base: '#2B79C2',
    darken4: '#040A11',
    darken3: '#0E273F',
    darken2: '#1C4E7D',
    darken1: '#2568A7',
    lighten1: '#2B79C2',
    lighten2: '#4791D7',
    lighten3: '#71AAE0',
    lighten4: '#B0D0EE',
    lighten5: '#F2F8FC',
  },
  info: {
    base: '#7E7E7E',
    darken4: '#171717',
    darken3: '#282828',
    darken2: '#3E3E3E',
    darken1: '#5A5A5A',
    lighten1: '#7E7E7E',
    lighten2: '#9D9C9C',
    lighten3: '#B2B2B2',
    lighten4: '#CACACA',
    lighten5: '#F0F0F0',
  },
  warning: {
    base: '#FFB800',
    darken4: '#A67700',
    darken3: '#C38D00',
    darken2: '#DEA000',
    darken1: '#F3AF00',
    lighten1: '#FFB800',
    lighten2: '#FFC940',
    lighten3: '#FFD771',
    lighten4: '#FFE9B1',
    lighten5: '#FFECBC',
  },
  error: {
    base: '#EC584A',
    darken4: '#340C0F',
    darken3: '#490E12',
    darken2: '#641117',
    darken1: '#8E171F',
    lighten1: '#EC584A',
    lighten2: '#F3B3B0',
    lighten3: '#F9DCD8',
    lighten4: '#FFEEE9',
    lighten5: '#FFF3FC',
  },
  success: {
    base: '#40AF1A',
    darken4: '#184309',
    darken3: '#25660E',
    darken2: '#2D7E11',
    darken1: '#349513',
    lighten1: '#40AF1A',
    lighten2: '#53AC34',
    lighten3: '#6EBB53',
    lighten4: '#87C672',
    lighten5: '#AAD79B',
  },
  red: {
    base: '#EC584A',
    darken4: '#340C0F',
    darken3: '#490E12',
    darken2: '#641117',
    darken1: '#8E171F',
    lighten1: '#EC584A',
    lighten2: '#F3B3B0',
    lighten3: '#F9DCD8',
    lighten4: '#FFEEE9',
    lighten5: '#FFF3FC',
  },
  green: {
    base: '#40AF1A',
    darken4: '#184309',
    darken3: '#25660E',
    darken2: '#2D7E11',
    darken1: '#349513',
    lighten1: '#40AF1A',
    lighten2: '#53AC34',
    lighten3: '#6EBB53',
    lighten4: '#87C672',
    lighten5: '#AAD79B',
  },
  yellow: {
    base: '#FAFF00',
    darken4: '#BFC200',
    darken3: '#D8DC00',
    darken2: '#E8EC00',
    darken1: '#F2F600',
    lighten1: '#FAFF00',
    lighten2: '#FBFF29',
    lighten3: '#FCFF7C',
    lighten4: '#FEFFBD',
    lighten5: '#FEFFD9',
  },
  blue: {
    base: '#2B79C2',
    darken4: '#040A11',
    darken3: '#0E273F',
    darken2: '#1C4E7D',
    darken1: '#2568A7',
    lighten1: '#2B79C2',
    lighten2: '#4791D7',
    lighten3: '#71AAE0',
    lighten4: '#B0D0EE',
    lighten5: '#F2F8FC',
  },
  grey: {
    base: '#7E7E7E',
    darken4: '#171717',
    darken3: '#282828',
    darken2: '#3E3E3E',
    darken1: '#5A5A5A',
    lighten1: '#7E7E7E',
    lighten2: '#9D9C9C',
    lighten3: '#B2B2B2',
    lighten4: '#CACACA',
    lighten5: '#F0F0F0',
  },
};
