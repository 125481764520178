import { RouteConfig } from 'vue-router';
import DashboardList from './components/dashboard-list.vue';

export const dashboardRoutes: RouteConfig[] = [
  {
    path: '/dashboard',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'dashboard-dashboard-list',
        path: '/',
        component: DashboardList,
        meta: {
          requiresAuth: true,
          title: 'Übersicht',
        },
      },
    ],
  },
];
