<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { RequestPasswordResetCommand } from '../types';

interface Controls extends FormControls {
  emailAddress: FormControl<string>;
}

@Component
export default class RequestPasswordReset extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        emailAddress: {
          label: 'E-Mail Adresse',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: RequestPasswordResetCommand = {
      userEmailAddress: formValues.emailAddress!,
    };

    this.authenticationStore.requestPasswordReset(command)
      .then(() => showSuccessMessage(
        /* eslint-disable-next-line vue/max-len */
        'Wenn die E-Mail Adresse in unserem System existiert wirst du in den nächsten Minuten eine E-Mail mit einem Link zum Zurücksetzen erhalten.',
        8000
      ))
      .then(() => this.$router.push({ name: 'authentication-login' }))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>
  <v-row>
    <v-col md="12" lg="9">
      <a-info-alert>
        Wenn Du dein Passwort zurücksetzen möchtest, dann gib bitte hier deine E-Mail Adresse ein. Wir schicken dir eine E-Mail mit einem Link über den Du dein Passwort zurücksetzen kannst.
      </a-info-alert>
      <p>
        Aus Sicherheitsgründen werden wir nicht verraten ob die E-Mail Adresse in unserem System existiert. Daher musst du dir sicher sein, dass Du die richtige E-Mail Adresse eingibst.
      </p>

      <v-card outlined class="mt-6">
        <a-form
          v-if="form"
          :form="form"
        >
          <v-card-text class="pt-6 pb-0">

            <a-email-address-form-control
              :form-control="form.controls.emailAddress"
              is-autofocused
            />

          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <a-cancel-action-button :to="{ name: 'authentication-login' }">
              Zurück
            </a-cancel-action-button>
            <a-submit-primary-action-button
              :is-disabled="isSubmitDisabled"
              :is-processing="authenticationStore.isRequestPasswordResetProcessing"
            >
              Passwort zurücksetzen
            </a-submit-primary-action-button>
          </v-card-actions>
        </a-form>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
