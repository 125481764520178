import { apiUrl, performApiRequest, performApiRequestForFile } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { CourseCertificate, GetCertificatePDFAsAppManagerQuery } from './types';

// -- Queries

export function getCertificates(query: AppAndUserProperty): Promise<CourseCertificate[]> {
  const url = `${apiUrl}/api/user-management/get-certificates-query`;
  return performApiRequest<CourseCertificate[]>({ url, method: 'POST', data: query });
}

export function getCertificatePDFAsAppManager(query: GetCertificatePDFAsAppManagerQuery & AppAndUserProperty): Promise<FileResponse> {
  const url = `${apiUrl}/api/user-management/get-certificate-pdf-as-app-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}
