<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useOnboardingStore } from '../store';

@Component
export default class HabitsStep extends Vue {

  readonly store = useOnboardingStore();
  readonly authenticationStore = useAuthenticationStore();

  submitClicked(): void {
    this.$emit('submitConfirmed');
  }

  skipClicked(): void {
    this.$emit('skipConfirmed');
  }

}
</script>
<template>
<div class="habits">
  <v-card-text class="pb-0">
    <h2>Gewohnheiten</h2>

    <v-row v-if="store.featureExamples && store.featureExamples.habits.length > 0">
      <v-col cols="12" md="6">

        <v-card
          outlined
          class="today-view mt-2 mb-4"
        >
          <v-list class="py-0">
            <template v-for="(habit, index) in store.featureExamples.habits">
              <v-list-item :key="habit.habitId">
                <v-list-item-action class="mr-3">
                  <font-awesome-icon :icon="['far', 'check-circle']" class="check" v-if="habit.isCompleted" />
                  <font-awesome-icon :icon="['far', 'circle']" v-else />
                </v-list-item-action>
                <v-list-item-content>
                  <strong>{{ habit.title }}</strong>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index !== store.featureExamples.habits.length - 1"
                :key="index"
              />
            </template>
          </v-list>
        </v-card>

      </v-col>
    </v-row>

    <p>
      Passend zu den inhaltlichen Themen der Module bieten wir dir Optionen für neue gesunde Lebensgewohnheiten an, die du direkt im jeweiligen Modul erkunden und planen kannst.
    </p>
    <p>
      Damit du deine neuen Gewohnheiten im Alltag nicht vergisst, bieten wir dir die Möglichkeit, dich per <span class="no-wrap">E-Mail</span> oder Push-Benachrichtigung erinnern zu lassen.
    </p>
  </v-card-text>

  <v-divider />
  <v-card-actions>
    <v-spacer />
    <a-cancel-action-button @click="skipClicked">
      Einführung überspringen
    </a-cancel-action-button>
    <a-trigger-primary-process-button @click="submitClicked">
      Weiter
    </a-trigger-primary-process-button>
  </v-card-actions>

</div>
</template>
<style lang="sass" scoped>
.today-view
  cursor: default

  strong
    font-weight: 600

  svg
    font-size: 1.25rem
    color: var(--color-grey-5)

    &.check
      color: var(--color-brand)
</style>
