import Vue from 'vue';
import ActionBanner from '@/components/design-system/action-banner.vue';
import ActionList from '@/components/design-system/action-list.vue';
import CancelActionButton from '@/components/design-system/cancel-action-button.vue';
import ConfirmationDialog from '@/components/design-system/confirmation-dialog.vue';
import ExecuteDangerousActionButton from '@/components/design-system/execute-dangerous-action-button.vue';
import ExecutePrimaryActionButton from '@/components/design-system/execute-primary-action-button.vue';
import ExecuteSecondaryActionButton from '@/components/design-system/execute-secondary-action-button.vue';
import ExecuteWarningActionButton from '@/components/design-system/execute-warning-action-button.vue';
import DangerAlert from '@/components/design-system/danger-alert.vue';
import InfoAlert from '@/components/design-system/info-alert.vue';
import SubmitDangerousActionButton from '@/components/design-system/submit-dangerous-action-button.vue';
import SubmitPrimaryActionButton from '@/components/design-system/submit-primary-action-button.vue';
import TriggerPrimaryProcessButton from '@/components/design-system/trigger-primary-process-button.vue';
import TriggerSecondaryProcessButton from '@/components/design-system/trigger-secondary-process-button.vue';
import TriggerTertiaryProcessButton from '@/components/design-system/trigger-tertiary-process-button.vue';
import WarningAlert from '@/components/design-system/warning-alert.vue';

Vue.component('a-action-banner', ActionBanner);
Vue.component('a-action-list', ActionList);
Vue.component('a-cancel-action-button', CancelActionButton);
Vue.component('a-confirmation-dialog', ConfirmationDialog);
Vue.component('a-execute-dangerous-action-button', ExecuteDangerousActionButton);
Vue.component('a-execute-primary-action-button', ExecutePrimaryActionButton);
Vue.component('a-execute-secondary-action-button', ExecuteSecondaryActionButton);
Vue.component('a-execute-warning-action-button', ExecuteWarningActionButton);
Vue.component('a-danger-alert', DangerAlert);
Vue.component('a-info-alert', InfoAlert);
Vue.component('a-submit-dangerous-action-button', SubmitDangerousActionButton);
Vue.component('a-submit-primary-action-button', SubmitPrimaryActionButton);
Vue.component('a-trigger-primary-process-button', TriggerPrimaryProcessButton);
Vue.component('a-trigger-secondary-process-button', TriggerSecondaryProcessButton);
Vue.component('a-trigger-tertiary-process-button', TriggerTertiaryProcessButton);
Vue.component('a-warning-alert', WarningAlert);
