import { RouteConfig } from 'vue-router';
import { Role } from '@/helpers/data';
import { Feature } from '@/types';
import RecipesList from './components/recipe-list.vue';

export const manageRecipesRoutes: RouteConfig[] = [
  {
    path: 'rezepte-verwalten',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'manage-recipes-recipe-list',
        path: '/',
        component: RecipesList,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          requiresFeature: Feature.RECIPES,
          title: 'Rezepte verwalten',
          breadcrumbs: [
            { text: 'Rezepte verwalten' },
          ],
        },
      },
    ],
  },
];
