<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { clearAllStores } from '@/helpers/store-reset-helper';
import { useAdminAuthenticationStore } from '@/application/admin/authentication/store';

@Component
export default class Logout extends Vue {

  readonly adminAuthenticationStore = useAdminAuthenticationStore();

  mounted(): void {
    setTimeout(() => {
      this.adminAuthenticationStore.logout()
        .catch((error) => showErrorResponse(error))
        .finally(() => {
          clearAllStores();
          this.$router.push({ name: 'root' });
        });
    }, 1000);
  }

}
</script>
<template>
<v-container class="logout">

  <h1>Abmeldung</h1>

  <v-progress-linear color="primary" indeterminate class="mb-4" />

  <p>
    Du wirst sicher abgemeldet...
  </p>

</v-container>
</template>
