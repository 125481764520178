import { watch } from 'vue';
import { FormControlComponent } from './types';

export function mountFormControl(
  formControlComponent: FormControlComponent<any>
): void {
  formControlComponent.formControl.componentReferences = {
    formControlId: formControlComponent.formControlId,
    validateInternalValue: formControlComponent.validateInternalValue,
    validateFormValue: formControlComponent.validateFormValue,
  };

  formControlComponent.updateInternalValues();

  const isInternalValueValid = formControlComponent.validateInternalValue
    ? formControlComponent.validateInternalValue()
    : true;
  const isFormValueValid = formControlComponent.validateFormValue();
  formControlComponent.formFunctions.publishValidationResult(
    formControlComponent.formControlId,
    isInternalValueValid && isFormValueValid
  );

  watchFormFieldValue(formControlComponent);
}

export function internalValuesChanged(formControlComponent: FormControlComponent<any>): void {
  const isInternalValueValid = formControlComponent.validateInternalValue
    ? formControlComponent.validateInternalValue()
    : true;
  const value = formControlComponent.formValueFromInternalValues();

  unwatchFormFieldValue(formControlComponent);
  formControlComponent.formControl.value = value;
  formControlComponent.$emit('input', value);
  const isFormValueValid = formControlComponent.validateFormValue();

  formControlComponent.formFunctions.publishValidationResult(
    formControlComponent.formControlId,
    isInternalValueValid && isFormValueValid
  );

  // Values have to be set before the trigger is called as otherwise the value isn't updated yet
  formControlComponent.formFunctions.inputChanged(formControlComponent.formControl);
  watchFormFieldValue(formControlComponent);
}

export function watchFormFieldValue(formControlComponent: FormControlComponent<any>): void {
  formControlComponent.formFieldValueWatcher = watch(
    () => formControlComponent.formControl.value,
    () => {
      formControlComponent.updateInternalValues();

      const isInternalValueValid = formControlComponent.validateInternalValue
        ? formControlComponent.validateInternalValue()
        : true;
      const isFormValueValid = formControlComponent.validateFormValue();

      formControlComponent.formFunctions.publishValidationResult(
        formControlComponent.formControlId,
        isInternalValueValid && isFormValueValid
      );
    }
  );
}

export function unwatchFormFieldValue(formControlComponent: FormControlComponent<any>): void {
  formControlComponent.formFieldValueWatcher();
}
