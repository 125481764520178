<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VuetifySelectItem } from '@/application/types';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import RichTextPreview from './rich-text-preview.vue';
import ImageElementPreview from './image-element-preview.vue';
import VideoElementPreview from './video-element-preview.vue';
import DocumentPreview from './document-preview.vue';
import QuizPreview from './quiz-preview.vue';
import SelfReflectionPreview from './self-reflection-preview.vue';
import HabitProposalPreview from './habit-proposal-preview.vue';
import AcknowledgementPreview from './acknowledgement-preview.vue';
import { useManageModulesStore } from '../store';
import { Module } from '../types';

@Component({
  components: {
    RichTextPreview,
    ImageElementPreview,
    VideoElementPreview,
    DocumentPreview,
    QuizPreview,
    SelfReflectionPreview,
    HabitProposalPreview,
    AcknowledgementPreview,
  },
})
export default class ModulePagePreview extends Vue {

  readonly store = useManageModulesStore();
  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Object, required: true })
  readonly currentModule!: Module;

  get isModuleEmpty(): boolean {
    return this.currentModule.pages.length === 0;
  }

  get modulePageSelectItems(): VuetifySelectItem<any>[] {
    return this.currentModule.pages.map((modulePage) => ({
      text: modulePage.title,
      value: modulePage.modulePageId,
    }));
  }

  mounted(): void {
    if (this.currentModule.pages.length > 0) {
      this.store.updateCurrentModulePageForPreview(this.currentModule.pages[0].modulePageId)
        .catch((error) => showErrorResponse(error));
    }
  }

  modulePageForPreviewWasSelected(modulePageId: string): void {
    this.store.updateCurrentModulePageForPreview(modulePageId)
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<div class="module-page-preview">
  <v-card
    class="mb-4"
    outlined
  >
    <v-card-text>
      <v-autocomplete
        label="Seite"
        :items="modulePageSelectItems"
        :value="store.currentModulePageIdForPreview"
        @change="modulePageForPreviewWasSelected"
        outlined
        hide-details
      />
    </v-card-text>
  </v-card>

  <v-card
    :loading="store.isGetCurrentModulePageForPreviewProcessing"
    outlined
  >
    <template v-if="isModuleEmpty">
      <div class="text-center my-8">
        Diese Seite hat noch keine Inhalte.
      </div>
    </template>
    <template v-if="store.currentModulePageForPreview !== null">
      <div class="title-container">
        <font-awesome-icon
          :icon="['fas', 'bars']"
          class="menu"
        />
        <div class="module-page-title">
          {{ store.currentModulePageForPreview.title }}
        </div>
        <div class="health-points-badge">
          <span>270</span>

          <font-awesome-icon :icon="['far', 'salad']" small v-if="authenticationStore.appPreventionType === 'NUTRITION'" />
          <font-awesome-icon :icon="['far', 'running']" small v-if="authenticationStore.appPreventionType === 'MOVEMENT'" />
          <font-awesome-icon :icon="['far', 'smile']" small v-if="authenticationStore.appPreventionType === 'STRESS'" />

        </div>
      </div>
      <div class="progress-bar">
        <v-progress-linear :value="store.currentProgressForPreview" color="primary" height="20" class="full-with" />

        <v-btn
          fab
          small
          elevation="3"
          class="summary-trigger"
          @click.prevent=""
          :ripple="false"
        >
          <font-awesome-icon :icon="['far', 'bookmark']" />
        </v-btn>

      </div>

      <div class="content">
        <div v-if="store.currentModulePageForPreview.contentElements.length === 0">
          <div class="text-center mt-8">
            Diese Seite hat noch keine Inhalte.
          </div>
        </div>

        <template v-for="contentElement in store.currentModulePageForPreview.contentElements">

          <div
            v-if="contentElement.type === 'rich-text'"
            :key="contentElement.contentElementId"
            class="pl-4 pr-4"
          >
            <rich-text-preview :content-element="contentElement" />
          </div>

          <div
            v-if="contentElement.type === 'image'"
            :key="contentElement.contentElementId"
          >
            <image-element-preview :content-element="contentElement" />
          </div>

          <div
            v-if="contentElement.type === 'video'"
            :key="contentElement.contentElementId"
          >
            <video-element-preview :content-element="contentElement" ref="videoElement" />
          </div>

          <div
            class="mb-4"
            v-if="contentElement.type === 'quiz'"
            :key="contentElement.contentElementId"
          >
            <quiz-preview :content-element="contentElement" />
          </div>

          <div
            class="mb-4"
            v-if="contentElement.type === 'habit-proposal'"
            :key="contentElement.contentElementId"
          >
            <habit-proposal-preview :content-element="contentElement" />
          </div>

          <div
            class="mb-4"
            v-if="contentElement.type === 'acknowledgement'"
            :key="contentElement.contentElementId"
          >
            <acknowledgement-preview :content-element="contentElement" />
          </div>

          <div
            class="mb-4"
            v-if="contentElement.type === 'document'"
            :key="contentElement.contentElementId"
          >
            <document-preview :content-element="contentElement" />
          </div>

          <div
            class="mb-4"
            v-if="contentElement.type === 'self-reflection'"
            :key="contentElement.contentElementId"
          >
            <self-reflection-preview :content-element="contentElement" />
          </div>

        </template>

        <div
          v-if="store.currentModulePageForPreview.isExerciseIncluded"
          class="exercise-confirmation pl-4 pr-4"
        >
          <p>
            Damit die Übungen ihre gewünschte Wirkung erzielen ist es wichtig, dass sie immer vollständig durchgeführt werden. Bestätige nach jeder Übung die korrekte Durchführung um fortfahren zu können.
          </p>
          <v-checkbox label="Ich habe die Übung mit der genannten Anzahl an Wiederholungen absolviert" />
        </div>
      </div>
    </template>
  </v-card>
</div>
</template>
<style lang="sass" scoped>
.module-page-preview
  position: sticky
  top: 77px

  .progress-bar
    background: var(--color-white)
    z-index: 5
    position: relative

    .v-btn.summary-trigger
      background: var(--color-green-5)
      color: white
      font-size: 1.25rem
      position: absolute
      top: 0.25rem
      right: 1rem
      margin-right: 0
      opacity: 1
      cursor: not-allowed

      svg
        margin-right: 0 !important

  .title-container
    overflow: hidden
    padding: 0 1.5rem
    max-width: 100%
    width: 100%
    display: flex
    align-items: center
    height: 60px
    border-bottom: 1px solid var(--color-grey-8)

    svg.menu
      flex-grow: 0
      margin-right: 1.5rem
      cursor: not-allowed

    .module-page-title
      display: block
      font-family: Montserrat, sans-serif
      letter-spacing: normal
      font-weight: 800
      font-size: 15px
      text-overflow: ellipsis
      overflow: hidden
      line-height: 1.5
      white-space: nowrap
      flex-grow: 1
      margin-right: 60px

    .health-points-badge
      position: absolute
      top: calc(15px + env(safe-area-inset-top))
      right: 15px
      height: 28px
      border-radius: 0.25rem
      background: var(--color-white)
      color: var(--color-brand)
      border: 1px solid var(--color-grey-8)
      font-size: 0.875rem
      transition: font-size 300ms
      display: flex
      align-items: center
      justify-content: right
      min-width: 3rem
      padding: 0 0.5rem
      font-family: Montserrat, sans-serif
      font-weight: 800
      cursor: not-allowed

      span
        margin-right: 0.3rem

      img
        background: var(--color-brand)

      svg
        margin-top: 1px

        path
          fill: var(--color-brand)

  .content
    height: 763px // 844 - 61px (title + border) - 20px (progress bar)
    overflow-x: hidden
    overflow-y: scroll

  .exercise-confirmation
    .v-input::v-deep .v-label
      line-height: 1.5
</style>
