<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useOnboardingStore } from '../store';

@Component
export default class DocumentsStep extends Vue {

  readonly store = useOnboardingStore();

  submitClicked(): void {
    this.$emit('submitConfirmed');
  }

  skipClicked(): void {
    this.$emit('skipConfirmed');
  }

}
</script>
<template>
<div class="modules">
  <v-card-text class="pb-0">
    <h2>Dokumente</h2>

    <p>
      Innerhalb der Module bieten wir dir vertiefende Dokumente wie Arbeitsblätter, Anleitungen usw. an. Du kannst diese Dokumente herunterladen oder direkt an deine E-Mail Adresse schicken lassen, damit du sie von einem PC aus ausdrucken kannst.
    </p>

    <v-row
      v-if="store.featureExamples && store.featureExamples.documents.length > 0"
      class="document-list mt-4"
    >
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="document in store.featureExamples.documents"
        :key="document.documentId"
        class="document mb-6"
      >
        <strong class="document-title d-block">{{ document.title }}</strong>
        <span class="short-description d-block">{{ document.shortDescription }}</span>

        <v-row
          no-gutters
          class="mt-3"
        >
          <v-col class="mr-1">
            <a-execute-secondary-action-button
              is-small
              is-always-shown-in-block-mode
            >
              Herunterladen
            </a-execute-secondary-action-button>
          </v-col>
          <v-col class="ml-1">
            <a-execute-secondary-action-button
              is-small
              is-always-shown-in-block-mode
            >
              Als E-Mail
            </a-execute-secondary-action-button>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <p>
      Über das Menü findest du den Bereich “Dokumente”, in dem du auf alle Dokumente gesammelt zugreifen kannst, unabhängig davon, ob du das zugehörige Modul bereits  durchgearbeitet hast oder noch nicht.
    </p>
  </v-card-text>

  <v-divider />
  <v-card-actions>
    <v-spacer />
    <a-cancel-action-button @click="skipClicked">
      Einführung überspringen
    </a-cancel-action-button>
    <a-trigger-primary-process-button @click="submitClicked">
      Weiter
    </a-trigger-primary-process-button>
  </v-card-actions>

</div>
</template>
<style lang="sass" scoped>
.document-list
  font-size: 0.875rem

  .document

    strong.document-title
      font-weight: 700
      font-family: Montserrat, sans-serif
      color: var(--color-grey-2)
</style>
