import { RouteConfig } from 'vue-router';
import { Role } from '@/helpers/data';
import ModuleList from './components/module-list.vue';
import ContentManagement from './components/content-management.vue';

export const manageModulesRoutes: RouteConfig[] = [
  {
    path: 'module',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'manage-modules-module-list',
        path: '/',
        component: ModuleList,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          title: 'Module verwalten',
          breadcrumbs: [
            { text: 'Module verwalten' },
          ],
        },
      },
      {
        name: 'manage-modules-content-management',
        path: 'inhalte-verwalten/:id',
        component: ContentManagement,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          title: 'Inhalte verwalten',
          breadcrumbs: [
            { text: 'Module verwalten', to: { name: 'manage-modules-module-list' } },
          ],
        },
      },
    ],
  },
];
