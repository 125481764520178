<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentElementAcknowledgementForPreview } from '../types';

@Component
export default class AcknowledgementPreview extends Vue {

  @Prop()
  readonly contentElement!: ContentElementAcknowledgementForPreview;

  onSelectCheckbox(): void {
    this.contentElement.isConfirmed = !this.contentElement.isConfirmed;
  }

}
</script>
<template>
<div class="acknowledgement">
  <div v-html="contentElement.introduction" />
  <v-checkbox
    :label="contentElement.acknowledgement"
    @change="onSelectCheckbox"
    hide-details
  />
</div>
</template>
<style lang="sass" scoped>
.acknowledgement
  background: var(--color-grey-9)
  padding: 1.25rem 1rem
  border: solid var(--color-grey-8)
  border-width: 1px 0
  color: var(--color-grey-3)

  ::v-deep
    p
      font-size: 1rem

  .v-input::v-deep .v-label
    line-height: 1.5
</style>
