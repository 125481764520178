import { FormControl, FormControlRule, FormControlValue } from './types';

export function requiredRule(message?: string): FormControlRule<FormControlValue<unknown>> {
  return (value) => (
    value !== null
  ) ? true
    : message
      ?? 'Dieses Feld muss gefüllt sein';
}

export function requiredCheckboxRule(message?: string): FormControlRule<FormControlValue<boolean>> {
  return (value) => (
    value === true
  ) ? true
    : message
    ?? 'Diese Option muss ausgewählt sein';
}

export function atLeastOneRequiredRule(
  otherControls: FormControl<any>[],
  message?: string
): FormControlRule<FormControlValue<unknown>> {
  return (value) => (
    value !== null
    || otherControls.some((control) => control.value !== null)
  ) ? true
    : message
    ?? 'Mindestens eines der Felder muss gefüllt sein';
}

export function minLengthRule(min: number, message?: string): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
      || value.length >= min
  ) ? true
    : message
      ?? `Der Wert muss mindestens ${min} Zeichen lang sein`;
}

export function maxLengthRule(max: number, message?: string): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
      || value.length <= max
  ) ? true
    : message
      ?? `Der Wert darf höchstens ${max} Zeichen lang sein`;
}

export function minNumberRule(min: number, message?: string): FormControlRule<FormControlValue<number>> {
  return (value) => (
    value === null
    || value >= min
  ) ? true
    : message
    ?? `Die Zahl muss mindestens ${min} sein`;
}

export function moduloByRule(modulo: number, message?: string): FormControlRule<FormControlValue<number>> {
  return (value) => (
    value === null
    || value % modulo === 0
  ) ? true
    : message
    ?? `Der Wert muss ein Faktor von ${modulo} sein`;
}

export function minArrayCountRule(min: number, message?: string): FormControlRule<FormControlValue<any[]>> {
  return (value) => (
    value === null
    || value.length >= min
  ) ? true
    : message
    ?? (
      min === 1
        ? `Es muss mindestens ${min} Option ausgewählt werden`
        : `Es müssen mindestens ${min} Optionen ausgewählt werden`
    );
}

export function maxArrayCountRule(max: number, message?: string): FormControlRule<FormControlValue<any[]>> {
  return (value) => (
    value === null
    || value.length <= max
  ) ? true
    : message
    ?? (
      max === 1
        ? `Es kann maximal ${max} Option ausgewählt werden`
        : `Es können maximal ${max} Optionen ausgewählt werden`
    );
}

export function noDuplicateStringsRule(message = 'Es sind doppelte Einträge vorhanden'): FormControlRule<FormControlValue<string[]>> {
  return (value) => (
    value === null
    || !value
      .map((item) => item.trim())
      .some(
        (line, index) => value.indexOf(line) !== index
      )
  ) ? true
    : message;
}

export function maxDecimalsRule(max: number, message?: string): FormControlRule<FormControlValue<number>> {
  return (value) => {
    const decimalCount = value !== null
      && !Number.isInteger(value)
      ? value.toString().split('.')[1].length
      : 0;
    return (
      value === null
      || decimalCount <= max
    ) ? true
      : message
      ?? `Die Zahl muss mindestens ${max} sein`;
  };
}

export function phoneRule(message?: string): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || /^(?:([+][0-9]{1,2})+[ .-]*)?([(]{1}[0-9]{1,6}[)])?([0-9 .-/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/.test(value)
  ) ? true
    : message
    ?? 'Dies ist keine gültige Telefonnummer';
}

export function identicalStringRule(
  expectedString: string,
  message?: string
): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || value === expectedString
  ) ? true
    : message
    ?? `Der Wert muss "${expectedString}" sein`;
}

export function notIdenticalStringRule(
  expectedString: string,
  message?: string
): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || value !== expectedString
  ) ? true
    : message
    ?? `Der Wert darf nicht "${expectedString}" sein`;
}

export function identicalStringFormControlRule(
  formControl: FormControl<string>,
  message?: string
): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || value === formControl.value
  ) ? true
    : message
      ?? `Der Text muss mit "${formControl.label}" übereinstimmen`;
}

export function identicalPasswordFormControlRule(
  formControl: FormControl<string>,
  message?: string
): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || value === formControl.value
  ) ? true
    : message
    ?? `Das Passwort muss mit "${formControl.label}" übereinstimmen`;
}

export function mustEndWithPeriodRule(
  message?: string
): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || value.substr(value.length - 1) === '.'
  ) ? true
    : message
    ?? `Der Satz muss mit einem Punkt enden`;
}
