<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useNutritionDiaryStore } from '../store';
import { DeleteNutritionDiaryEntryImageCommand, NutritionDiaryEntry } from '../types';

@Component
export default class DeleteNutritionDiaryEntryImageDialog extends Vue {

  readonly store = useNutritionDiaryStore();

  @Prop({ type: Object, required: true })
  readonly entry!: NutritionDiaryEntry;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  deleteNutritionDiaryEntryImageConfirmed(): void {
    const command: DeleteNutritionDiaryEntryImageCommand = {
      nutritionDiaryEntryId: this.entry.nutritionDiaryEntryId,
    };

    this.store.deleteNutritionDiaryEntryImage(command)
      .then(() => showSuccessMessage('Bild wurde gelöscht'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isDeleteNutritionDiaryEntryImageProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <v-card-title>Bild löschen</v-card-title>
    <v-card-text>
      <p>Willst du das Bild für <strong>{{ entry.consumedAt | formatTime }} Uhr</strong> wirklich löschen?</p>
      <p><strong>Das Bild wird endgültig gelöscht und kann nicht wiederhergestellt werden!</strong></p>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <a-cancel-action-button
        :is-processing="store.isDeleteNutritionDiaryEntryImageProcessing"
        @click="closeDialog"
      />
      <a-execute-dangerous-action-button
        @click="deleteNutritionDiaryEntryImageConfirmed"
        :is-processing="store.isDeleteNutritionDiaryEntryImageProcessing"
      >
        Löschen
      </a-execute-dangerous-action-button>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
