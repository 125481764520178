import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { createContactMessage, requestCompanyAccess } from './service';
import { CreateContactMessageCommand, RequestCompanyAccessCommand } from './types';

interface UnauthorizedContactState {
  createContactMessageStatus: ActionStatus;
  requestCompanyAccessStatus: ActionStatus;
}

function initialState(): UnauthorizedContactState {
  return {
    createContactMessageStatus: ActionStatus.None,
    requestCompanyAccessStatus: ActionStatus.None,
  };
}

export const useUnauthorizedContactStore = defineStore('unauthorizedContact', {
  state: (): UnauthorizedContactState => initialState(),
  getters: {
    isCreateContactMessageProcessing: (state: UnauthorizedContactState): boolean =>
      state.createContactMessageStatus === ActionStatus.InProgress,
    isRequestCompanyAccessProcessing: (state: UnauthorizedContactState): boolean =>
      state.requestCompanyAccessStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Commands

    createContactMessage(command: CreateContactMessageCommand): Promise<void> {
      const { createContactMessageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContactMessageStatus,
        () => createContactMessage(attachAppAndUser(command))
      );
    },

    requestCompanyAccess(command: RequestCompanyAccessCommand): Promise<void> {
      const { requestCompanyAccessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        requestCompanyAccessStatus,
        () => requestCompanyAccess(attachAppAndUser(command))
      );
    },

  },
});
