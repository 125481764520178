<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useBreadcrumbStore } from '@/application/common/breadcrumb/store';
import CreateRichTextDialog from './create-rich-text-dialog.vue';
import UpdateRichTextDialog from './update-rich-text-dialog.vue';
import CreateTipBoxDialog from './create-tip-box-dialog.vue';
import UpdateTipBoxDialog from './update-tip-box-dialog.vue';
import CreateImageDialog from './create-image-dialog.vue';
import UpdateImageDialog from './update-image-dialog.vue';
import CreateImageWithDescriptionDialog from './create-image-with-description-dialog.vue';
import UpdateImageWithDescriptionDialog from './update-image-with-description-dialog.vue';
import CreateStrategiesDialog from './create-strategies-dialog.vue';
import UpdateStrategiesDialog from './update-strategies-dialog.vue';
import { useManageBlogArticlesStore } from '../store';
import { BlogArticle, BlogArticleContentElement, DeleteContentElementCommand, MoveContentElementDownCommand, MoveContentElementUpCommand } from '../types';

@Component({
  components: {
    CreateRichTextDialog,
    UpdateRichTextDialog,
    CreateTipBoxDialog,
    UpdateTipBoxDialog,
    CreateImageDialog,
    UpdateImageDialog,
    CreateImageWithDescriptionDialog,
    UpdateImageWithDescriptionDialog,
    CreateStrategiesDialog,
    UpdateStrategiesDialog,
  },
})
export default class ContentManagement extends Vue {

  readonly store = useManageBlogArticlesStore();
  readonly breadcrumbStore = useBreadcrumbStore();

  get isLoading(): boolean {
    return this.store.isGetCurrentBlogArticleProcessing
      || this.store.isCreateContentElementRichTextProcessing
      || this.store.isUpdateContentElementRichTextProcessing
      || this.store.isCreateContentElementTipBoxProcessing
      || this.store.isUpdateContentElementTipBoxProcessing
      || this.store.isCreateContentElementImageProcessing
      || this.store.isUpdateContentElementImageProcessing
      || this.store.isCreateContentElementImageWithDescriptionProcessing
      || this.store.isUpdateContentElementImageWithDescriptionProcessing
      || this.store.isCreateContentElementStrategiesProcessing
      || this.store.isUpdateContentElementStrategiesProcessing;
  }

  get isContentElementMoveInProgress(): boolean {
    return this.store.isMoveContentElementUpProcessing
      || this.store.isMoveContentElementDownProcessing;
  }

  mounted(): void {
    watch(() => this.store.currentBlogArticle, () => {
      const breadcrumbTitle = this.store.currentBlogArticle
        ? `Inhalte für "${this.store.currentBlogArticle.title}" verwalten`
        : 'Inhalte verwalten';

      this.breadcrumbStore.updateItems([
        ...this.$route.meta!.breadcrumbs!,
        { text: breadcrumbTitle },
      ]);
    }, { immediate: true });

    watch(() => this.$route.path, () => {
      this.store.updateCurrentBlogArticle(this.$route.params.blogArticleId)
        .catch((error) => showErrorResponse(error));
    }, { immediate: true });
  }

  isContentElementMoveUpDisabled(index: number): boolean {
    return index === 0
      || this.isContentElementMoveInProgress
      || this.isLoading;
  }

  isContentElementMoveDownDisabled(blogArticle: BlogArticle, index: number): boolean {
    return index >= blogArticle.contentElements.length - 1
      || this.isContentElementMoveInProgress
      || this.isLoading;
  }

  onMoveContentElementUpClicked(contentElement: BlogArticleContentElement): void {
    const command: MoveContentElementUpCommand = {
      blogArticleContentElementId: contentElement.blogArticleContentElementId,
    };

    this.store.moveContentElementUp(command)
      .then(() => showSuccessMessage('Inhalt wurde verschoben.'))
      .catch((error) => showErrorResponse(error));
  }

  onMoveContentElementDownClicked(contentElement: BlogArticleContentElement): void {
    const command: MoveContentElementDownCommand = {
      blogArticleContentElementId: contentElement.blogArticleContentElementId,
    };

    this.store.moveContentElementDown(command)
      .then(() => showSuccessMessage('Inhalt wurde verschoben.'))
      .catch((error) => showErrorResponse(error));
  }

  async deleteContentElementConfirmed(contentElement: BlogArticleContentElement): Promise<void> {
    const command: DeleteContentElementCommand = {
      blogArticleContentElementId: contentElement.blogArticleContentElementId,
    };
    return this.store.deleteContentElement(command)
      .then(() => showSuccessMessage('Inhalt gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <v-card outlined class="mt-4 mb-4" :loading="isLoading">

    <v-progress-linear v-if="!store.currentBlogArticle" color="primary" indeterminate />

    <v-list v-if="store.currentBlogArticle" class="pt-0">
      <v-subheader class="d-flex justify-space-between">
        {{ store.currentBlogArticle.title }}
        <icon-menu small :icon="['far', 'plus']">
            <create-rich-text-dialog>
              <v-list-item @click="$root.$emit('close-icon-menu')">
                <v-list-item-title>Text hinzufügen</v-list-item-title>
              </v-list-item>
            </create-rich-text-dialog>
            <create-tip-box-dialog>
              <v-list-item @click="$root.$emit('close-icon-menu')">
                <v-list-item-title>Tipp-Box hinzufügen</v-list-item-title>
              </v-list-item>
            </create-tip-box-dialog>
            <create-image-dialog>
              <v-list-item @click="$root.$emit('close-icon-menu')">
                <v-list-item-title>Bild hinzufügen</v-list-item-title>
              </v-list-item>
            </create-image-dialog>
            <create-image-with-description-dialog>
              <v-list-item @click="$root.$emit('close-icon-menu')">
                <v-list-item-title>Bild mit Beschreibung hinzufügen</v-list-item-title>
              </v-list-item>
            </create-image-with-description-dialog>
            <create-strategies-dialog>
              <v-list-item @click="$root.$emit('close-icon-menu')">
                <v-list-item-title>Strategien hinzufügen</v-list-item-title>
              </v-list-item>
            </create-strategies-dialog>
          </icon-menu>
      </v-subheader>

      <v-list-item v-if="store.currentBlogArticle.contentElements.length === 0" disabled :ripple="false">
        <v-list-item-icon>
          <font-awesome-icon :icon="['far', 'file']" class="ml-4 mr-0" />
        </v-list-item-icon>
        <v-list-item-content>
          Noch keine Inhalte angelegt
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mb-2" v-else />

      <template v-for="(contentElement, index) in store.currentBlogArticle.contentElements">

        <v-list-item
          :key="contentElement.blogArticleContentElementId"
          :ripple="false"
          class="px-2"
        >

          <template v-if="contentElement.type === 'rich-text'">
            <v-list-item-icon class="mr-4">
              <font-awesome-icon :icon="['far', 'align-left']" class="ml-4 mr-0" />
            </v-list-item-icon>
            <v-list-item-content>
              <span class="page-title">{{ contentElement.title }}</span>
            </v-list-item-content>
            <v-list-item-icon class="mr-2 mt-2 mb-0">
              <icon-button
                small
                :icon="['far', 'arrow-up']"
                :disabled="isContentElementMoveUpDisabled(index)"
                tooltip="Nach oben schieben"
                disabled-tooltip="Bereits erstes Element"
                @click="onMoveContentElementUpClicked(contentElement)"
              />
              <icon-button
                small
                :icon="['far', 'arrow-down']"
                :disabled="isContentElementMoveDownDisabled(store.currentBlogArticle, index)"
                tooltip="Nach unten schieben"
                disabled-tooltip="Bereits letztes Element"
                @click="onMoveContentElementDownClicked(contentElement)"
              />
              <update-rich-text-dialog :content-element="contentElement">
                <icon-button small :icon="['far', 'pencil']" tooltip="Text anpassen" />
              </update-rich-text-dialog>
              <a-confirmation-dialog
                title="Text löschen"
                button-text="Löschen"
                highlight="danger"
                :action="() => deleteContentElementConfirmed(contentElement)"
                :is-processing="store.isDeleteContentElementProcessing"
              >
                <icon-button
                  small
                  :icon="['far', 'times']"
                  tooltip="Löschen"
                />
                <template #content>
                  <p>
                    Soll der Inhalt mit dem Titel {{ contentElement.title }} wirklich gelöscht werden?
                  </p>
                </template>
              </a-confirmation-dialog>
            </v-list-item-icon>
          </template>

          <template v-if="contentElement.type === 'tip-box'">
            <v-list-item-icon class="mr-4">
              <font-awesome-icon :icon="['far', 'info-circle']" class="ml-4 mr-0" />
            </v-list-item-icon>
            <v-list-item-content>
              <span class="page-title">{{ contentElement.title }}</span>
            </v-list-item-content>
            <v-list-item-icon class="mr-2 mt-2 mb-0">
              <icon-button
                small
                :icon="['far', 'arrow-up']"
                :disabled="isContentElementMoveUpDisabled(index)"
                tooltip="Nach oben schieben"
                disabled-tooltip="Bereits erstes Element"
                @click="onMoveContentElementUpClicked(contentElement)"
              />
              <icon-button
                small
                :icon="['far', 'arrow-down']"
                :disabled="isContentElementMoveDownDisabled(store.currentBlogArticle, index)"
                tooltip="Nach unten schieben"
                disabled-tooltip="Bereits letztes Element"
                @click="onMoveContentElementDownClicked(contentElement)"
              />
              <update-tip-box-dialog :content-element="contentElement">
                <icon-button small :icon="['far', 'pencil']" tooltip="Tipp-Box anpassen" />
              </update-tip-box-dialog>
              <a-confirmation-dialog
                title="Tipp-Box löschen"
                button-text="Löschen"
                highlight="danger"
                :action="() => deleteContentElementConfirmed(contentElement)"
                :is-processing="store.isDeleteContentElementProcessing"
              >
                <icon-button
                  small
                  :icon="['far', 'times']"
                  tooltip="Löschen"
                />
                <template #content>
                  <p>
                    Soll der Inhalt mit dem Titel {{ contentElement.title }} wirklich gelöscht werden?
                  </p>
                </template>
              </a-confirmation-dialog>
            </v-list-item-icon>
          </template>

          <template v-if="contentElement.type === 'image'">
            <v-list-item-icon class="mr-4">
              <font-awesome-icon :icon="['far', 'image']" class="ml-4 mr-0" />
            </v-list-item-icon>
            <v-list-item-content>
              <span class="page-title">{{ contentElement.title }}</span>
            </v-list-item-content>
            <v-list-item-icon class="mr-2 mt-2 mb-0">
              <icon-button
                small
                :icon="['far', 'arrow-up']"
                :disabled="isContentElementMoveUpDisabled(index)"
                tooltip="Nach oben schieben"
                disabled-tooltip="Bereits erstes Element"
                @click="onMoveContentElementUpClicked(contentElement)"
              />
              <icon-button
                small
                :icon="['far', 'arrow-down']"
                :disabled="isContentElementMoveDownDisabled(store.currentBlogArticle, index)"
                tooltip="Nach unten schieben"
                disabled-tooltip="Bereits letztes Element"
                @click="onMoveContentElementDownClicked(contentElement)"
              />
              <update-image-dialog :content-element="contentElement">
                <icon-button small :icon="['far', 'pencil']" tooltip="Bild anpassen" />
              </update-image-dialog>
              <a-confirmation-dialog
                title="Bild löschen"
                button-text="Löschen"
                highlight="danger"
                :action="() => deleteContentElementConfirmed(contentElement)"
                :is-processing="store.isDeleteContentElementProcessing"
              >
                <icon-button
                  small
                  :icon="['far', 'times']"
                  tooltip="Löschen"
                />
                <template #content>
                  <p>
                    Soll der Inhalt mit dem Titel {{ contentElement.title }} wirklich gelöscht werden?
                  </p>
                </template>
              </a-confirmation-dialog>
            </v-list-item-icon>
          </template>

          <template v-if="contentElement.type === 'image-with-description'">
            <v-list-item-icon class="mr-4">
              <font-awesome-icon :icon="['far', 'image']" class="ml-4 mr-0" />
            </v-list-item-icon>
            <v-list-item-content>
              <span class="page-title">{{ contentElement.title }}</span>
            </v-list-item-content>
            <v-list-item-icon class="mr-2 mt-2 mb-0">
              <icon-button
                small
                :icon="['far', 'arrow-up']"
                :disabled="isContentElementMoveUpDisabled(index)"
                tooltip="Nach oben schieben"
                disabled-tooltip="Bereits erstes Element"
                @click="onMoveContentElementUpClicked(contentElement)"
              />
              <icon-button
                small
                :icon="['far', 'arrow-down']"
                :disabled="isContentElementMoveDownDisabled(store.currentBlogArticle, index)"
                tooltip="Nach unten schieben"
                disabled-tooltip="Bereits letztes Element"
                @click="onMoveContentElementDownClicked(contentElement)"
              />
              <update-image-with-description-dialog :content-element="contentElement">
                <icon-button small :icon="['far', 'pencil']" tooltip="Bild mit Beschreibung anpassen" />
              </update-image-with-description-dialog>
              <a-confirmation-dialog
                title="Bild mit Beschreibung löschen"
                button-text="Löschen"
                highlight="danger"
                :action="() => deleteContentElementConfirmed(contentElement)"
                :is-processing="store.isDeleteContentElementProcessing"
              >
                <icon-button
                  small
                  :icon="['far', 'times']"
                  tooltip="Löschen"
                />
                <template #content>
                  <p>
                    Soll der Inhalt mit dem Titel {{ contentElement.title }} wirklich gelöscht werden?
                  </p>
                </template>
              </a-confirmation-dialog>
            </v-list-item-icon>
          </template>

          <template v-if="contentElement.type === 'strategies'">
            <v-list-item-icon class="mr-4">
              <font-awesome-icon :icon="['far', 'list']" class="ml-4 mr-0" />
            </v-list-item-icon>
            <v-list-item-content>
              <span class="page-title">{{ contentElement.title }}</span>
            </v-list-item-content>
            <v-list-item-icon class="mr-2 mt-2 mb-0">
              <icon-button
                small
                :icon="['far', 'arrow-up']"
                :disabled="isContentElementMoveUpDisabled(index)"
                tooltip="Nach oben schieben"
                disabled-tooltip="Bereits erstes Element"
                @click="onMoveContentElementUpClicked(contentElement)"
              />
              <icon-button
                small
                :icon="['far', 'arrow-down']"
                :disabled="isContentElementMoveDownDisabled(store.currentBlogArticle, index)"
                tooltip="Nach unten schieben"
                disabled-tooltip="Bereits letztes Element"
                @click="onMoveContentElementDownClicked(contentElement)"
              />
              <update-strategies-dialog :content-element="contentElement">
                <icon-button small :icon="['far', 'pencil']" tooltip="Strategien anpassen" />
              </update-strategies-dialog>
              <a-confirmation-dialog
                title="Strategien löschen"
                button-text="Löschen"
                highlight="danger"
                :action="() => deleteContentElementConfirmed(contentElement)"
                :is-processing="store.isDeleteContentElementProcessing"
              >
                <icon-button
                  small
                  :icon="['far', 'times']"
                  tooltip="Löschen"
                />
                <template #content>
                  <p>
                    Soll der Inhalt mit dem Titel {{ contentElement.title }} wirklich gelöscht werden?
                  </p>
                </template>
              </a-confirmation-dialog>
            </v-list-item-icon>
          </template>

        </v-list-item>

      </template>

    </v-list>

  </v-card>

</v-container>
</template>
<style lang="sass" scoped>
.module-page-disabled
  .v-list-item__icon
    color: var(--color-grey-5)
  .page-title
    color: var(--color-grey-5)
</style>
