<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import CreateHabitDialog from './create-habit-dialog.vue';
import EditHabitDialog from './edit-habit-dialog.vue';
import { useManageHabitsStore } from '../store';

@Component({
  components: {
    CreateHabitDialog,
    EditHabitDialog,
  },
})
export default class HabitList extends Vue {

  readonly store = useManageHabitsStore();

  mounted(): void {
    this.store.getHabits()
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <create-habit-dialog>
    <a-trigger-primary-process-button>
      Gewohnheit erstellen
    </a-trigger-primary-process-button>
  </create-habit-dialog>

  <v-card
    class="mt-4 mb-4 overflow-hidden"
    outlined
  >
    <a-action-list
      :items="store.habits"
      item-id="habitId"
      :is-loading="store.isGetHabitsProcessing"
      empty-list-message="Noch keine Gewohnheiten angelegt."
    >
      <template #content="{ item: habit }">
        <span class="item-title">
          {{ habit.title }}
        </span>
        <span class="details">
          Erstellt am {{ habit.createdAt | formatDate }}
        </span>
      </template>
      <template #actions="{ item: habit }">

        <edit-habit-dialog :habit="habit">
          <icon-button
            small
            :icon="['far', 'pencil']"
            tooltip="Gewohnheit anpassen"
          />
        </edit-habit-dialog>

      </template>
    </a-action-list>
  </v-card>

</v-container>
</template>
