<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { ExtensionOption } from '@/components/form/html-form-control.vue';
import { useManageModulesStore } from '../store';
import { CreateContentElementAcknowledgementCommand, ModulePage } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  introduction: FormControl<string>;
  acknowledgement: FormControl<string>;
}

@Component
export default class CreateAcknowledgementDialog extends Vue {

  readonly store = useManageModulesStore();

  @Prop({ type: Object, required: true })
  readonly modulePage!: ModulePage;

  readonly dialogMaxWidth = DialogWidth.large;

  readonly enabledExtensions: ExtensionOption[] = [
    'h2',
    'h3',
    'bold',
    'italic',
    'bullet-list',
    'ordered-list',
  ];

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Bezeichnung',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        introduction: {
          label: 'Einleitung',
          value: null,
          isRequired: true,
        },
        acknowledgement: {
          label: 'Bestätigung',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateContentElementAcknowledgementCommand = {
      modulePageId: this.modulePage.modulePageId,
      title: formValues.title!,
      introduction: formValues.introduction!,
      acknowledgement: formValues.acknowledgement!,
    };

    this.store.createContentElementAcknowledgement(command)
      .then(() => showSuccessMessage('Bestätigung wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateContentElementAcknowledgementProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Bestätigung erstellen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <a-text-form-control
              :form-control="form.controls.title"
              is-autofocused
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-html-form-control
              :form-control="form.controls.introduction"
              :extensions="enabledExtensions"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-text-form-control :form-control="form.controls.acknowledgement" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateContentElementAcknowledgementProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateContentElementAcknowledgementProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Erstellen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
