<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useUserProgressStore } from '@/private/user/user-progress/store';
import HealthPoints from '@/private/user/user-progress/components/health-points.vue';
import UserGoalTrends from '@/private/user/user-progress/components/user-goal-trends.vue';
import RateGoalProgressDialog from '@/private/user/user-progress/components/rate-goal-progress-dialog.vue';
import { downloadFile } from '@/helpers/file-download-helper';
import { formatDate } from '@/filters/dates';
import { Feature } from '@/types';
import CreateCertificateDialog from './create-certificate-dialog.vue';
import Notifications from './notifications.vue';
import { useDashboardStore } from '../store';
import { BlogArticle, ExperienceReport, Recipe } from '../types';

@Component({
  components: {
    RateGoalProgressDialog,
    HealthPoints,
    UserGoalTrends,
    CreateCertificateDialog,
    Notifications,
  },
})
export default class DashboardList extends Vue {

  readonly store = useDashboardStore();
  readonly authenticationStore = useAuthenticationStore();
  readonly userProgressStore = useUserProgressStore();

  get isLoading(): boolean {
    return this.store.isGetDashboardProcessing;
  }

  get isCertificateCardVisible(): boolean {
    return this.store.dashboard !== null
      && (
        this.store.dashboard!.isCertificateIssuingEnabled
        || this.store.dashboard!.isCertificateIssued
      );
  }

  get isCertificateIssuingVisible(): boolean {
    return this.store.dashboard !== null
      && this.store.dashboard!.isCertificateIssuingEnabled
      && !this.store.dashboard!.isCertificateIssued;
  }

  get isCertificateDownloadAndSendVisible(): boolean {
    return this.store.dashboard !== null
      && this.store.dashboard!.isCertificateIssuingEnabled
      && this.store.dashboard!.isCertificateIssued;
  }

  get isActiveModuleActionAvailable(): boolean {
    return !this.store.dashboard!.activeModule!.lock;
  }

  get isGoalTrackingVisible(): boolean {
    return this.authenticationStore.isFeatureEnabled(Feature.GOAL_TRACKING)
      && !!this.userProgressStore.userProgress
      && this.userProgressStore.userProgress.userGoalProgress.length > 0;
  }

  get moduleClass(): string {
    if (this.store.dashboard!.activeModule!.lock) {
      return 'locked';
    }

    if (!this.store.dashboard!.activeModule!.lock) {
      return this.store.dashboard!.activeModule!
        ? 'start'
        : 'in-progress';
    }

    throw new Error('Invalid state');
  }

  get activeModuleDescription(): string {
    const module = this.store.dashboard!.activeModule!;

    if (!module.lock) {
      if (module.completionPercentage === 0) {
        return 'Bereit';
      }

      return `Zu ${module.completionPercentage}% abgeschlossen`;
    }

    const lock = module.lock;

    if (lock.lockReason === 'time') {
      const unlockedAt = lock.unlockedAt!;

      if (unlockedAt!.isSame(dayjs().add(1, 'day'), 'day')) {
        return `Wird <strong>Morgen um ${unlockedAt.format('HH:mm')} Uhr</strong> freigeschalten`;
      }

      if (unlockedAt.isSame(dayjs(), 'day')) {
        return `Wird <strong>Heute um ${unlockedAt.format('HH:mm')} Uhr</strong> freigeschalten`;
      }

      if (unlockedAt.clone().startOf('day').diff(dayjs().startOf('day'), 'day') <= 3) {
        // eslint-disable-next-line vue/max-len
        return `Wird in <strong>${unlockedAt.clone().startOf('day').diff(dayjs().startOf('day'), 'day')} Tagen um ${unlockedAt.format('HH:mm')} Uhr</strong> freigeschalten`;
      }

      return `Wird am <strong>${unlockedAt.format('DD.MM')} um ${unlockedAt.format('HH:mm')} Uhr</strong> freigeschalten`;
    }

    return 'Kostenpflichtiges Modul';
  }

  get activeModuleAction(): string {
    switch (this.store.dashboard!.activeModule!.completionPercentage) {
      case 0:
        return 'Starten';
      case 100:
        return 'Zum Inhaltsverzeichnis';
      default:
        return 'Fortsetzen';
    }
  }

  get isNutritionDiaryLinkVisible(): boolean {
    return this.authenticationStore.isFeatureEnabled(Feature.NUTRITION_DIARY);
  }

  get areBlogArticlesVisible(): boolean {
    return this.authenticationStore.isFeatureEnabled(Feature.BLOG)
      && this.store.dashboard!.newBlogArticles.length > 0;
  }

  get areExperienceReportsVisible(): boolean {
    return this.authenticationStore.isFeatureEnabled(Feature.EXPERIENCE_REPORTS)
      && this.store.dashboard!.randomExperienceReports.length > 0;
  }

  get areRecipesVisible(): boolean {
    return this.authenticationStore.isFeatureEnabled(Feature.RECIPES)
      && this.store.dashboard!.randomRecipes.length > 0;
  }

  get isTerminationNoticeVisible(): boolean {
    return !!this.store.dashboard
      && !!this.store.dashboard.scheduledForTerminationAt
      && (!this.store.dashboard.hasUserAccessToCourse
        || this.store.dashboard.isCertificateIssuingPossible
      );
  }

  // Users that bought the course through other means then web / ios / android / Machtfit won't see the dialog as they aren't able to get a
  // certificate and the app will still be available.
  get terminationNotice(): string {
    if (!this.store.dashboard
      || !this.store.dashboard.scheduledForTerminationAt
    ) {
      return '';
    }

    // Users that bought the course
    if (this.store.dashboard.hasUserAccessToCourse
      && this.store.dashboard.isCertificateIssuingPossible
    ) {
      return `Die Zertifizierung des Kurses läuft zum ${formatDate(this.store.dashboard.scheduledForTerminationAt)} ab. Bitte schließe den Kurs bis zu diesem Datum ab und lade deine Teilnahme&shy;bescheinigung herunter. Danach können wir keine Teilnahme&shy;bescheinigung mehr ausstellen.`;
    }

    // Users that didn't buy the course yet
    if (!this.store.dashboard.hasUserAccessToCourse) {
      return `Die Zertifizierung des Kurses läuft zum ${formatDate(this.store.dashboard.scheduledForTerminationAt)} ab. Danach kann der Kurs nicht mehr von der Krankenkasse erstattet werden.`;
    }

    return '';
  }

  mounted(): void {
    this.store.getDashboard()
      .catch((error) => showErrorResponse(error));
  }

  onActiveModuleClicked(): void {
    if (this.store.dashboard!.activeModule!.lock) {
      return;
    }
    this.$router.push({ name: 'consume-modules-consume-module', params: { id: this.store.dashboard!.activeModule!.moduleId } });
  }

  showAllModulesClicked(): void {
    this.$router.push({ name: 'consume-modules-module-list' });
  }

  onDownloadCertificateClicked(): void {
    this.store.getUserCertificatePDF()
      .then((fileResponse) => downloadFile(fileResponse.data, fileResponse.contentType, 'Teilnahmebescheinigung.pdf'))
      .catch((error) => showErrorResponse(error));
  }

  onSendCertificateToUserClicked(): void {
    this.store.sendCertificateToUser({})
      .then(() => showSuccessMessage('Teilnahmebescheinigung wurde verschickt.'))
      .catch((error) => showErrorResponse(error));
  }

  blogArticleClicked(blogArticle: BlogArticle): void {
    this.$router.push({ name: 'consume-blog-articles-blog-article-details', params: { blogArticleId: blogArticle.blogArticleId } });
  }

  showAllBlogArticlesClicked(): void {
    this.$router.push({ name: 'consume-blog-articles-blog-article-list' });
  }

  experienceReportClicked(experienceReport: ExperienceReport): void {
    this.$router.push({
      name: 'consume-experience-reports-experience-report-details',
      params: { experienceReportId: experienceReport.experienceReportId },
    });
  }

  showAllExperienceReportsClicked(): void {
    this.$router.push({ name: 'consume-experience-reports-experience-report-list' });
  }

  recipeClicked(recipe: Recipe): void {
    this.$router.push({
      name: 'consume-recipes-recipe-details',
      params: { recipeId: recipe.recipeId },
    });
  }

  showAllRecipesClicked(): void {
    this.$router.push({ name: 'consume-recipes-recipe-list' });
  }

}
</script>
<template>
<v-container>
  <v-progress-linear
    v-if="!store.dashboard"
    color="primary"
    class="mb-4"
    indeterminate
  />

  <a-danger-alert v-if="isTerminationNoticeVisible">
    <span v-html="terminationNotice" />
  </a-danger-alert>

  <v-row v-if="isCertificateCardVisible" class="mb-8">
    <v-col cols="12" md="6">

      <v-card outlined>
        <v-card-text>
          <h2>Deine Teilnahmebescheinigung</h2>
          <p class="mt-2">
            Hole dir hier deine Teilnahmebescheinigung für deine Krankenkasse.
          </p>

          <create-certificate-dialog v-if="isCertificateIssuingVisible">
            <a-trigger-primary-process-button>
              Ausstellen
            </a-trigger-primary-process-button>
          </create-certificate-dialog>

          <template v-if="isCertificateDownloadAndSendVisible">
            <v-row
              no-gutters
              class="mt-2"
            >
              <v-col class="mr-1">
                <a-execute-secondary-action-button
                  @click="onDownloadCertificateClicked"
                  is-small
                  is-always-shown-in-block-mode
                >
                  Herunterladen
                </a-execute-secondary-action-button>
              </v-col>
              <v-col class="ml-1">
                <a-execute-secondary-action-button
                  @click="onSendCertificateToUserClicked"
                  :is-processing="store.isSendCertificateToUserProcessing"
                  is-small
                  is-always-shown-in-block-mode
                >
                  Als E-Mail
                </a-execute-secondary-action-button>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>

    </v-col>
  </v-row>

  <notifications class="mb-8" />

  <template v-if="store.dashboard">
    <v-row class="modules mb-10 mt-2" v-if="store.dashboard.activeModule">
      <v-col cols="12">
        <h2>Module</h2>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-row
          class="no-gutters element"
          :class="moduleClass"
          :disabled="store.dashboard.activeModule.lock"
          @click="onActiveModuleClicked"
        >
          <v-col cols="4">
            <v-card outlined class="overflow-hidden">
              <blur-hash-image
                v-if="store.dashboard.activeModule.image"
                :hash="store.dashboard.activeModule.image.hash"
                :src="store.dashboard.activeModule.image.url"
                :alt="store.dashboard.activeModule.title"
                :width="20"
                :height="Math.floor(20 / store.dashboard.activeModule.image.aspectRatio)"
              />
            </v-card>
          </v-col>
          <v-col cols="8">
            <div class="element-info">
              <strong class="element-title">{{ store.dashboard.activeModule.title }}</strong>
              <span class="d-block" v-html="activeModuleDescription"></span>
              <span class="d-block action-link" v-if="isActiveModuleActionAvailable">{{ activeModuleAction }}</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <span class="d-inline-block mt-4 secondary-action-link" @click="showAllModulesClicked">
          <font-awesome-icon :icon="['far', 'list']" class="mr-1" />
          Alle Module anzeigen
        </span>
      </v-col>
    </v-row>

    <v-row class="blog-articles mb-10 mt-2" v-if="areBlogArticlesVisible">
      <v-col cols="12">
        <h2>Neue Blogartikel</h2>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="blogArticle in store.dashboard.newBlogArticles"
        :key="blogArticle.blogArticleId"
      >

        <v-row
          class="no-gutters element mb-4"
          @click="blogArticleClicked(blogArticle)"
        >
          <v-col cols="4">
            <v-card outlined class="overflow-hidden">
              <div class="blur-hash-image-container">
                <blur-hash-image
                  v-if="blogArticle.image"
                  :hash="blogArticle.image.hash"
                  :src="blogArticle.image.url"
                  :alt="blogArticle.title"
                  :width="20"
                  :height="Math.floor(20 / blogArticle.image.aspectRatio)"
                />
                <span class="badge">
                  Neu
                </span>
              </div>
            </v-card>
          </v-col>
          <v-col cols="8">
            <div class="element-info">
              <strong class="element-title">{{ blogArticle.title }}</strong>
              <span class="d-block action-link">Lesen</span>
            </div>
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="12">
        <span class="d-inline-block secondary-action-link" @click="showAllBlogArticlesClicked">
          <font-awesome-icon :icon="['far', 'list']" class="mr-1" />
          Alle Blogartikel anzeigen
        </span>
      </v-col>
    </v-row>

    <v-row class="experience-reports mb-10 mt-2" v-if="areExperienceReportsVisible">
      <v-col cols="12">
        <h2>Erfahrungsberichte</h2>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="experienceReport in store.dashboard.randomExperienceReports"
        :key="experienceReport.experienceReportId"
      >

        <v-row
          class="no-gutters element mb-4"
          @click="experienceReportClicked(experienceReport)"
        >
          <v-col cols="4">
            <v-card outlined class="overflow-hidden">
              <div class="blur-hash-image-container">
                <blur-hash-image
                  :hash="experienceReport.image.hash"
                  :src="experienceReport.image.url"
                  :alt="experienceReport.name"
                  :width="20"
                  :height="Math.floor(20 / experienceReport.image.aspectRatio)"
                />
              </div>
            </v-card>
          </v-col>
          <v-col cols="8">
            <div class="element-info">
              <strong class="element-title">{{ experienceReport.name }}</strong>
              <span class="d-block">{{ experienceReport.job }}</span>
              <span class="d-block action-link">Lesen</span>
            </div>
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="12">
        <span class="d-inline-block secondary-action-link" @click="showAllExperienceReportsClicked">
          <font-awesome-icon :icon="['far', 'list']" class="mr-1" />
          Alle Erfahrungsberichte anzeigen
        </span>
      </v-col>
    </v-row>

    <v-row class="recipes mb-10 mt-2" v-if="areRecipesVisible">
      <v-col cols="12">
        <h2>Rezepte</h2>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="recipe in store.dashboard.randomRecipes"
        :key="recipe.recipeId"
      >

        <v-row
          class="no-gutters element mb-4"
          @click="recipeClicked(recipe)"
        >
          <v-col cols="4">
            <v-card outlined class="overflow-hidden">
              <div class="blur-hash-image-container">
                <blur-hash-image
                  :hash="recipe.heroImage.hash"
                  :src="recipe.heroImage.url"
                  :alt="recipe.name"
                  :width="20"
                  :height="Math.floor(20 / recipe.heroImage.aspectRatio)"
                />
                <span class="badge">
                  {{ recipe.totalTimeInMinutes }}min
                </span>
              </div>
            </v-card>
          </v-col>
          <v-col cols="8">
            <div class="element-info">
              <strong class="element-title">{{ recipe.name }}</strong>
              <span class="d-block" v-if="recipe.subTitle">{{ recipe.subTitle }}</span>
              <span class="d-block action-link">Rezept anzeigen</span>
            </div>
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="12">
        <span class="d-inline-block secondary-action-link" @click="showAllRecipesClicked">
          <font-awesome-icon :icon="['far', 'list']" class="mr-1" />
          Alle Rezepte anzeigen
        </span>
      </v-col>
    </v-row>

    <v-row
      v-if="userProgressStore.userProgress"
      class="flex-column-reverse flex-md-row"
    >

      <v-col
        v-if="isGoalTrackingVisible"
        cols="12"
        sm="12"
        md="7"
        lg="8"
        class="mt-4 mt-md-0"
      >
        <h3>Zielerreichung</h3>

        <user-goal-trends :user-progress="userProgressStore.userProgress" />

        <rate-goal-progress-dialog :user-goal-progresses="userProgressStore.userProgress.userGoalProgress">
          <a-trigger-secondary-process-button class="mt-4">
            Ziele bewerten
          </a-trigger-secondary-process-button>
        </rate-goal-progress-dialog>

      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="5"
        lg="4"
      >

        <h3>Gesundheitspunkte</h3>

        <health-points
          :user-progress="userProgressStore.userProgress"
          class="mt-4"
        />

      </v-col>
    </v-row>

    <v-row class="tracking mt-8">
      <v-col cols="12">
        <h2>Tracking</h2>
        <a-trigger-secondary-process-button
          :to="{ name: 'manage-habit-intents-habit-intent-list' }"
          is-small
          class="mt-2"
        >
          Gewohnheit eintragen
        </a-trigger-secondary-process-button>
        <a-trigger-secondary-process-button
          v-if="isNutritionDiaryLinkVisible"
          :to="{ name: 'nutrition-diary-nutrition-diary-timeline' }"
          is-small
          class="mt-2"
        >
          Tagebucheintrag schreiben
        </a-trigger-secondary-process-button>
      </v-col>
    </v-row>
  </template>

</v-container>
</template>
<style lang="sass" scoped>
h2
  font-size: 1rem
  margin-bottom: 1rem

.modules,
.blog-articles,
.recipes,
.experience-reports
  font-size: 0.875rem

  .element
    cursor: pointer

    .blur-hash-image-container
      position: relative

      .badge
        position: absolute
        bottom: 0
        right: 0
        color: var(--color-white)
        background: var(--color-brand)
        padding: 2px 0.5rem
        font-weight: 600
        border-top-left-radius: 0.25rem

    &.start,
    &.in-progress,
    &.completed
      cursor: pointer

    &.locked::v-deep

      img
        opacity: 0.6

      .element-info
        opacity: 0.7

    .element-info
      padding: 0 0 0 0.75rem
      line-height: 1.15

      strong.element-title
        font-weight: 700
        font-family: Montserrat, sans-serif
        color: var(--color-grey-2)
        display: block
        margin-top: 0.25rem
        margin-bottom: 0.25rem

      span::v-deep
        font-size: 0.875rem

        strong
          font-weight: 600

      .action-link
        margin-top: 0.25rem
        font-weight: 600

  .action-link
    color: var(--color-brand)
    font-weight: 600
    cursor: pointer

  .secondary-action-link
    color: var(--color-grey-4)
    font-weight: 600
    font-size: 0.875rem
    cursor: pointer

.blog-articles
  .blur-hash-image-container
    position: relative

  .new-sign
    position: absolute
    bottom: 0
    right: 0
    color: var(--color-white)
    background: var(--color-brand)
    padding: 2px 0.5rem
    font-weight: 600
    border-top-left-radius: 0.25rem

.user-progress
  font-size: 0.875rem

  .level
    white-space: nowrap

.v-card.icon-card

  .image-container
    position: relative

    &.list
      svg
        color: var(--color-grey-9)

      .v-list-item__content
        z-index: 2

    svg
      font-size: 5rem
      position: absolute
      right: 0.5rem
      bottom: 0.5rem
      color: var(--color-white)
      z-index: 1

  .icon-container
    svg
      font-size: 2rem
      position: absolute
      right: 0.5rem
      bottom: 0.5rem
      color: var(--color-grey-8)
      z-index: 1

  .module-title
    color: var(--color-grey-1)

  .description
    font-size: 0.875rem
    color: var(--color-grey-4)

    &.locked
      color: var(--color-grey-2)

  &.current
    .v-card__text
      background: var(--color-brand)
      color: var(--color-white)

      .module-title,
      .description
        color: var(--color-white)

    .icon-container
      svg
        color: var(--color-white)

  &.v-card--disabled
    > *
      opacity: 1

    ::v-deep img
      opacity: 0.6

.v-list-item

  .highlight
    color: var(--color-brand)

    &.locked
      color: var(--color-grey-2)

  .module-title
    color: var(--color-grey-2)

  .description
    color: var(--color-grey-2)
    line-height: 1.5
</style>
