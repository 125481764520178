<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import dayjs, { Dayjs } from 'dayjs';
import CreateNutritionDiaryEntryDialog from './create-nutrition-diary-entry-dialog.vue';

@Component({
  components: {
    CreateNutritionDiaryEntryDialog,
  },
})
export default class NutritionDiaryIntroduction extends Vue {

  get threeDaysAgo(): Dayjs {
    return dayjs().subtract(3, 'days');
  }

  get porridgeUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nutrition-diary-introduction/porridge.jpg');
  }

  get salmonUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nutrition-diary-introduction/salmon.jpg');
  }

  get saladUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nutrition-diary-introduction/salad.jpg');
  }

  get secondSaladUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nutrition-diary-introduction/second-salad.jpg');
  }

  get espressoUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nutrition-diary-introduction/espresso.jpg');
  }

  get cookiesUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nutrition-diary-introduction/cookies.jpg');
  }

  get pestoUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nutrition-diary-introduction/pesto.jpg');
  }

  get pizzaUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nutrition-diary-introduction/pizza.jpg');
  }

}
</script>
<template>
<div class="nutrition-diary-example">

  <h3 class="mt-6">Einführung</h3>

  <p>
    Das Ernährungstagebuch ist ein Werkzeug zur Reflexion des eigenen Ernährungsverhaltens. Dabei liegt der Fokus nicht auf dem Zählen von Kalorien, sondern dem Einfluss deiner Ernährung auf dein Wohlbefinden.
  </p>

  <h4>Ein Beispiel</h4>

  <p>
    Für dein besseres Verständnis erlaubt dir Sanne einen Einblick, wie sie ihr Tagebuch führt.
  </p>

  <hr class="mt-6 mb-6" />

  <div class="story-element">
    <p>
      <em>Sanne hat vor 3 Tagen mit dem Ernährungstagebuch begonnen. Am ersten Tag ist sie total motiviert mit einem Porridge in den Tag gestartet. Mittags gab es Fisch und abends einen großen Salat.</em>
    </p>
  </div>

  <v-timeline
    dense
    align-top
    class="mt-4"
  >
    <v-timeline-item small>
      <div class="timeline-entry">
        <strong class="date">{{ threeDaysAgo | formatDate }}</strong>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mt-2"
          >
            <v-row class="entry no-gutters">
              <v-col cols="4">
                <v-card outlined class="overflow-hidden">
                  <div class="image-container">
                    <blur-hash-image
                      :src="porridgeUrl"
                      hash="LFD]k^xG008_?^ogIADi56odxusp"
                      alt="09:00 Uhr"
                      :width="20"
                      :height="Math.floor(20 / 1.5)"
                    />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="details">
                    <strong class="d-block time">09:00 Uhr</strong>
                    <span class="description">War ziemlich voll danach. Kaffee dazu war nötig.</span>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mt-2"
          >
            <v-row class="entry no-gutters">
              <v-col cols="4">
                <v-card outlined class="overflow-hidden">
                  <div class="image-container">
                    <blur-hash-image
                      :src="salmonUrl"
                      hash="LHHUUbAI-UOZ}6MxE2IqRB?GRjR*"
                      alt="12:05 Uhr"
                      :width="20"
                      :height="Math.floor(20 / 1.5)"
                    />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="details">
                  <strong class="d-block time">12:05 Uhr</strong>
                  <span class="description">Das Kartoffelpüree dazu war ne gute Idee.</span>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mt-2"
          >
            <v-row class="entry no-gutters">
              <v-col cols="4">
                <v-card outlined class="overflow-hidden">
                  <div class="image-container">
                    <blur-hash-image
                      :src="saladUrl"
                      hash="LqKv7_%fx[%1}?x[XSaf%1S#WVjF"
                      alt="18:30 Uhr"
                      :width="20"
                      :height="Math.floor(20 / 1.5)"
                    />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="details">
                  <strong class="d-block time">18:30 Uhr</strong>
                  <span class="description">Fühle mich sehr gut nach dem Salat.</span>
                </div>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </div>
    </v-timeline-item>
  </v-timeline>

  <div class="story-element mt-6">
    <p>
      <em>Am nächsten Tag musste sie so schnell aus dem Haus, dass sie morgens vergessen hat, ein Foto zu machen und nur schnell notiert hat, was sie getrunken hat. Mittags gab's Kantinenessen und abends hat sie wieder nur einen leichten Salat gegessen.</em>
    </p>
  </div>

  <v-timeline
    dense
    align-top
    class="mt-4"
  >
    <v-timeline-item small>
      <div class="timeline-entry">
        <strong class="date">Vorgestern</strong>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mt-2"
          >
            <v-row class="entry no-gutters">
              <v-col cols="4">
                <v-card outlined class="overflow-hidden">
                  <div class="image-container">
                    <div class="image-placeholder">
                      <font-awesome-icon :icon="['fa-thin', 'salad']" />
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="details">
                  <strong class="d-block time">07:10 Uhr</strong>
                  <span class="description">Hatte nur Zeit für einen schnellen Espresso.</span>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mt-2"
          >
            <v-row class="entry no-gutters">
              <v-col cols="4">
                <v-card outlined class="overflow-hidden">
                  <div class="image-container">
                    <div class="image-placeholder">
                      <font-awesome-icon :icon="['fa-thin', 'salad']" />
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="details">
                  <strong class="d-block time">12:30 Uhr</strong>
                  <span class="description">Großes Schnitzel war zu viel, hab jetzt nen Durchhänger.</span>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mt-2"
          >
            <v-row class="entry no-gutters">
              <v-col cols="4">
                <v-card outlined class="overflow-hidden">
                  <div class="image-container">
                    <blur-hash-image
                      :src="secondSaladUrl"
                      hash="L5F;s9m,00}Z0016_4M_9lw@-2%#"
                      alt="19:00 Uhr"
                      :width="20"
                      :height="Math.floor(20 / 1.5)"
                    />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="details">
                  <strong class="d-block time">19:00 Uhr</strong>
                  <span class="description">So viel besser als heute Mittag.</span>
                </div>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </div>
    </v-timeline-item>
  </v-timeline>

  <div class="story-element mt-6">
  <p>
    <em>Gestern ist sie gemütlich mit einem Espresso in den Tag gestartet. Im Büro gab es erst mal zum Geburtstag einer Kollegin Kekse, zu denen sie (alleine aus Höflichkeit) nicht nein sagen konnte. Mittags gab es ein frisches Pesto und abends eine Pizza zur Belohnung der letzten Tage.</em>
  </p>
  </div>

  <v-timeline
    dense
    align-top
    class="mt-4"
  >
    <v-timeline-item small>
      <div class="timeline-entry">
        <strong class="date">Gestern</strong>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mt-2"
          >
            <v-row class="entry no-gutters">
              <v-col cols="4">
                <v-card outlined class="overflow-hidden">
                  <div class="image-container">
                    <blur-hash-image
                      :src="espressoUrl"
                      hash="L6B:dCoy00WB00jE?wbc00f+^+e-"
                      alt="19:00 Uhr"
                      :width="20"
                      :height="Math.floor(20 / 1.5)"
                    />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="details">
                  <strong class="d-block time">08:00 Uhr</strong>
                  <span class="description">Gemütlicher Espresso auf der Terrasse.</span>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mt-2"
          >
            <v-row class="entry no-gutters">
              <v-col cols="4">
                <v-card outlined class="overflow-hidden">
                  <div class="image-container">
                    <blur-hash-image
                      :src="cookiesUrl"
                      hash="L3Ed@Y9a00$*S0IpN|}=00^j=_9u"
                      alt="19:00 Uhr"
                      :width="20"
                      :height="Math.floor(20 / 1.5)"
                    />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="details">
                  <strong class="d-block time">10:20 Uhr</strong>
                  <span class="description">Hatte Lust auf zwei Kekse, hab mir nur einen genommen.</span>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mt-2"
          >
            <v-row class="entry no-gutters">
              <v-col cols="4">
                <v-card outlined class="overflow-hidden">
                  <div class="image-container">
                    <blur-hash-image
                      :src="pestoUrl"
                      hash="LKF=%C?dMx${f#D*9FM{D+M^IUxu"
                      alt="19:00 Uhr"
                      :width="20"
                      :height="Math.floor(20 / 1.5)"
                    />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="details">
                  <strong class="d-block time">12:10 Uhr</strong>
                  <span class="description">Frisches Pesto vertrage ich wohl viel besser als gekauftes.</span>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mt-2"
          >
            <v-row class="entry no-gutters">
              <v-col cols="4">
                <v-card outlined class="overflow-hidden">
                  <div class="image-container">
                    <blur-hash-image
                      :src="pizzaUrl"
                      hash="LJJ7N;tn6Utl~pR.xaoM16WB$|wc"
                      alt="19:00 Uhr"
                      :width="20"
                      :height="Math.floor(20 / 1.5)"
                    />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="details">
                  <strong class="d-block time">18:40 Uhr</strong>
                  <span class="description">Hätte die ganze essen können, hab mich zurückgehalten!</span>
                </div>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </div>
    </v-timeline-item>
  </v-timeline>

  <hr class="mt-6 mb-6" />

  <p>
    Verschaffe auch du dir einen Überblick darüber, welchen Einfluss deine Ernährung auf dein Leben hat und starte mit deinem Ernährungstagebuch.
  </p>

  <create-nutrition-diary-entry-dialog>
    <a-trigger-primary-process-button class="mt-4">
      Jetzt ersten Eintrag erstellen
    </a-trigger-primary-process-button>
  </create-nutrition-diary-entry-dialog>

</div>
</template>
<style lang="sass" scoped>
.diary-slider
  font-size: 0.875rem

  .timeline-entry
    margin-top: 2px

    strong.date
      font-weight: 700
      font-family: Montserrat, sans-serif
      color: var(--color-grey-2)

    strong.time
      font-weight: 600

.v-card.no-entries
  padding: 1rem

.story-element
  background: var(--color-grey-8)
  padding: 1rem
  margin-left: -1rem
  margin-right: -1rem
  margin-top: 1rem

  p
    color: var(--color-grey-1)
    margin: 0

  @media (min-width: 600px)
    margin-left: 0
    margin-right: 0

.entry
  display: flex
  cursor: default

  .image-container
    flex-basis: 120px
    flex-shrink: 0
    overflow: hidden
    position: relative
    background: var(--color-grey-9)

    // ::before and ::after are to add aspect ratio
    &::before
      content: ""
      width: 1px
      margin-left: -1px
      float: left
      height: 0
      padding-top: 130px / 200px * 100%

    &::after
      content: ""
      display: table
      clear: both

    .image-placeholder
      display: flex
      position: absolute
      left: 0
      right: 0
      top: 0
      bottom: 0
      margin: auto
      align-items: center
      justify-content: center
      height: 100%
      padding: 0.75rem 0
      background: var(--color-white)

      svg
        display: block
        font-size: 3rem
        color: var(--color-grey-8)

  ::v-deep .details
    padding: 0.25rem 0.25rem 0 0.75rem
    flex-grow: 1

    .description
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      text-overflow: ellipsis
      overflow: hidden
      max-height: 48px
</style>
