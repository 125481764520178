import { RouteConfig } from 'vue-router';
import ProfileView from './components/profile-view.vue';

export const profileRoutes: RouteConfig[] = [
  {
    name: 'profile-show-profile',
    path: '/profil',
    component: ProfileView,
    meta: {
      requiresAuth: true,
      title: 'Profil',
    },
  },
];
