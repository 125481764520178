<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useNutritionDiaryStore } from '../store';
import { NutritionDiaryEntry, UpdateNutritionDiaryEntryDescriptionCommand } from '../types';

interface Controls extends FormControls {
  description: FormControl<string>,
}

@Component
export default class UpdateNutritionDiaryEntryDescriptionDialog extends Vue {

  readonly store = useNutritionDiaryStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly entry!: NutritionDiaryEntry;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        description: {
          label: 'Beschreibung',
          value: this.entry.description ?? null,
          isRequired: !this.entry.image,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateNutritionDiaryEntryDescriptionCommand = {
      nutritionDiaryEntryId: this.entry.nutritionDiaryEntryId,
      description: formValues.description,
    };

    this.store.updateNutritionDiaryEntryDescription(command)
      .then(() => showSuccessMessage('Beschreibung wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isUpdateNutritionDiaryEntryDescriptionProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Beschreibung anpassen</v-card-title>
      <v-card-text>

        <v-row>
          <v-col cols="12">
            <a-textarea-form-control
              :form-control="form.controls.description"
              :rows="4"
            />
          </v-col>
        </v-row>

      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isUpdateNutritionDiaryEntryDescriptionProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isUpdateNutritionDiaryEntryDescriptionProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
