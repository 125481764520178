export const defaultTheme = {
  primary: {
    base: '#2B79C2',
    darken4: '#040A11',
    darken3: '#0E273F',
    darken2: '#1C4E7D',
    darken1: '#2568A7',
    lighten1: '#2B79C2',
    lighten2: '#4791D7',
    lighten3: '#71AAE0',
    lighten4: '#B0D0EE',
    lighten5: '#F2F8FC',
  },
  secondary: {
    base: '#797E86',
    darken4: '#0C0D0D',
    darken3: '#303336',
    darken2: '#494C50',
    darken1: '#61656B',
    lighten1: '#797E86',
    lighten2: '#94989E',
    lighten3: '#C9CCCF',
    lighten4: '#DFE0E2',
    lighten5: '#F2F2F3',
  },
  accent: {
    base: '#2B79C2',
    darken4: '#040A11',
    darken3: '#0E273F',
    darken2: '#1C4E7D',
    darken1: '#2568A7',
    lighten1: '#2B79C2',
    lighten2: '#4791D7',
    lighten3: '#71AAE0',
    lighten4: '#B0D0EE',
    lighten5: '#F2F8FC',
  },
  info: {
    base: '#797E86',
    darken4: '#0C0D0D',
    darken3: '#303336',
    darken2: '#494C50',
    darken1: '#61656B',
    lighten1: '#797E86',
    lighten2: '#94989E',
    lighten3: '#C9CCCF',
    lighten4: '#DFE0E2',
    lighten5: '#F2F2F3',
  },
  warning: {
    base: '#FCC611',
    darken4: '#282210',
    darken3: '#362D11',
    darken2: '#54471C',
    darken1: '#C89B09',
    lighten1: '#FCC611',
    lighten2: '#FDD13F',
    lighten3: '#F6DE8D',
    lighten4: '#F7E6B1',
    lighten5: '#FBF9F4',
  },
  error: {
    base: '#B63507',
    darken4: '#3A1204',
    darken3: '#60220B',
    darken2: '#7C2709',
    darken1: '#942B05',
    lighten1: '#B63507',
    lighten2: '#D24A19',
    lighten3: '#F07F56',
    lighten4: '#F2B6A1',
    lighten5: '#FAE4DC',
  },
  success: {
    base: '#0E7222',
    darken4: '#062D0E',
    darken3: '#084013',
    darken2: '#0A4D17',
    darken1: '#166426',
    lighten1: '#0E7222',
    lighten2: '#1D9034',
    lighten3: '#32B34C',
    lighten4: '#85D595',
    lighten5: '#F3FBF5',
  },
  red: {
    base: '#B63507',
    darken4: '#3A1204',
    darken3: '#60220B',
    darken2: '#7C2709',
    darken1: '#942B05',
    lighten1: '#B63507',
    lighten2: '#D24A19',
    lighten3: '#F07F56',
    lighten4: '#F2B6A1',
    lighten5: '#FAE4DC',
  },
  green: {
    base: '#0E7222',
    darken4: '#062D0E',
    darken3: '#084013',
    darken2: '#0A4D17',
    darken1: '#166426',
    lighten1: '#0E7222',
    lighten2: '#1D9034',
    lighten3: '#32B34C',
    lighten4: '#85D595',
    lighten5: '#F3FBF5',
  },
  yellow: {
    base: '#FCC611',
    darken4: '#282210',
    darken3: '#362D11',
    darken2: '#54471C',
    darken1: '#C89B09',
    lighten1: '#FCC611',
    lighten2: '#FDD13F',
    lighten3: '#F6DE8D',
    lighten4: '#F7E6B1',
    lighten5: '#FBF9F4',
  },
  blue: {
    base: '#2B79C2',
    darken4: '#040A11',
    darken3: '#0E273F',
    darken2: '#1C4E7D',
    darken1: '#2568A7',
    lighten1: '#2B79C2',
    lighten2: '#4791D7',
    lighten3: '#71AAE0',
    lighten4: '#B0D0EE',
    lighten5: '#F2F8FC',
  },
  grey: {
    base: '#797E86',
    darken4: '#0C0D0D',
    darken3: '#303336',
    darken2: '#494C50',
    darken1: '#61656B',
    lighten1: '#797E86',
    lighten2: '#94989E',
    lighten3: '#C9CCCF',
    lighten4: '#DFE0E2',
    lighten5: '#F2F2F3',
  },
};
