<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import safeAreaInsets from 'safe-area-insets';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { useAppStore } from '@/application/common/app/store';
import { useAdminAuthenticationStore } from '@/application/admin/authentication/store';

@Component
export default class AppHeader extends Vue {

  readonly adminAuthenticationState = useAdminAuthenticationStore();
  readonly appStore = useAppStore();

  readonly isNativeApplication = isNativeApplication;

  navigationDrawerVisible = false;
  toolbarHeight = 60;
  navigationDrawerWidth = 230;

  get isLoginVisible(): boolean {
    return !this.adminAuthenticationState.isAuthenticated;
  }

  get isManagementVisible(): boolean {
    return this.adminAuthenticationState.isAuthenticated;
  }

  mounted() {
    this.calculateSafeAreas();

    safeAreaInsets.onChange(() => this.calculateSafeAreas());

    watch(() => this.$route.path, () => {
      this.appStore.updateTitle(this.$route.meta?.title ?? null);
    }, { immediate: true });
  }

  private calculateSafeAreas(): void {
    this.toolbarHeight = 60 + safeAreaInsets.top;
    this.navigationDrawerWidth = 230 + safeAreaInsets.left;
  }

}
</script>
<template>
<div class="header-component admin d-print-none">
  <v-app-bar
    app
    flat
    dense
    light
    :height="toolbarHeight"
  >
    <v-app-bar-nav-icon
      @click="navigationDrawerVisible = !navigationDrawerVisible"
    />

    <v-toolbar-title>
      {{ appStore.title }}
    </v-toolbar-title>
  </v-app-bar>

  <v-navigation-drawer
    app
    v-model="navigationDrawerVisible"
    :width="navigationDrawerWidth"
    :hide-overlay="true"
    :temporary="true"
  >
    <v-list dense>

      <v-list-item link :to="{ name: 'admin-authentication-login' }" v-if="isLoginVisible">
        <v-list-item-icon>
          <font-awesome-icon :icon="['far', 'sign-in']" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Anmeldung</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-if="isManagementVisible">

        <v-list-item link :to="{ name: 'admin-user-management-user-list' }">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'users']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Benutzerverwaltung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'admin-paywall-access-user-list' }">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'shield']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Paywall-Zugriff</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'admin-manage-apps-app-list' }">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'boxes']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Apps</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'admin-accounting-available-sponsor-seats' }">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'calculator']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Buchhaltung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </template>

      <v-list-item
        link
        :to="{ name: 'logout' }"
        v-if="adminAuthenticationState.isAuthenticated"
      >
        <v-list-item-icon>
          <font-awesome-icon :icon="['far', 'sign-out']" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>

</div>
</template>
<style lang="sass">
.header-component.admin
  .v-toolbar
    background: var(--color-white) !important

    .v-toolbar__title
      font-weight: 600 !important
      font-size: 15px !important
      padding-left: 0.5rem !important

  .v-app-bar
    border-bottom: 1px solid var(--color-grey-8)

    .v-app-bar__nav-icon
      transition: margin-left 0.4s
      margin-right: 0
      color: var(--color-grey-2)

  .v-navigation-drawer
    top: calc(60px + env(safe-area-inset-top)) !important
    height: calc(100vh - (env(safe-area-inset-top) + 60px)) !important
    padding-left: env(safe-area-inset-left)
    box-shadow: none

    .v-list-item
      padding: 0 8px

    .v-list-item__icon:first-child
      margin-right: 1rem !important

    .v-list-group--no-action
      > .v-list-group__items
        > .v-list-item
          padding-left: 52px !important

    .v-list-item--dense,
    .v-list--dense .v-list-item .v-list-item__icon
      font-size: 1.2rem
      padding: 2px 0

      svg
        margin: 0 auto

    .v-list-item--dense,
    .v-list--dense
      .v-list-item__title,
      .v-list-item__subtitle
        font-weight: 400 !important

    .v-navigation-drawer--is-mobile,
    .v-navigation-drawer--temporary
      &:not(.v-navigation-drawer--close)
        box-shadow: none

    .v-list

      .v-list-item__icon
        margin-left: 6px

      .v-list-item
        &.v-list-item--active
          .v-list-item__content,
          .v-list-item__icon svg
            color: var(--color-white)

          &.v-list-group__header
            border-right: 1px solid var(--color-grey-9)

            .v-list-item__icon svg
              color: var(--color-brand-5)

            .v-list-item__content
              color: var(--color-grey-2)

            &:before
              background: var(--color-white)

          &:before
            background-color: var(--color-brand-5)
            opacity: 1
            z-index: 1

          .v-list-item__icon,
          .v-list-item__content
            z-index: 10

        &.v-list-item--disabled
          .v-list-item__content
            color: var(--color-grey-6)

          .v-list-item__icon svg
            color: var(--color-grey-6)

      // Badge within navigation drawer - Size independent
      .v-list-item__icon
        .v-badge
          .v-badge__wrapper
            left: -2px
            top: 3px

  .v-toolbar__content
    padding-top: env(safe-area-inset-top) !important

#app
  &.is-native
    .header-component
      .v-app-bar
        .v-app-bar__nav-icon
          margin-left: -12px !important
</style>
