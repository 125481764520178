import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { Publication, CreatePublicationCommand, UpdatePublicationCommand } from './types';

export function getPublications(query: AppAndUserProperty): Promise<Publication[]> {
  const url = `${apiUrl}/api/publications/get-publications-for-management-query`;
  return performApiRequest<Publication[]>({ url, method: 'POST', data: query });
}

export function createPublication(command: CreatePublicationCommand): Promise<void> {
  const url = `${apiUrl}/api/publications/create-publication-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updatePublication(command: UpdatePublicationCommand): Promise<void> {
  const url = `${apiUrl}/api/publications/update-publication-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
