<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { GetAuthenticationWithImpersonationQuery } from '../types';

@Component
export default class UserImpersonation extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  get isProcessing(): boolean {
    return this.authenticationStore.isGetAuthenticationWithImpersonationProcessing;
  }

  mounted(): void {
    const command: GetAuthenticationWithImpersonationQuery = {
      token: this.$route.params.token,
    };

    this.authenticationStore.getAuthenticationWithImpersonation(command)
      .then(() => this.$router.push({ name: 'default' }))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>
  <v-row>
    <v-col md="12" lg="9">
      <v-card outlined>
        <v-card-text>
          <v-progress-linear v-if="isProcessing" color="primary" indeterminate />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
