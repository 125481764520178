<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useConsumeBlogArticlesStore } from '../store';
import { BlogArticle } from '../types';

@Component
export default class BlogArticleList extends Vue {

  readonly store = useConsumeBlogArticlesStore();

  get isLoading(): boolean {
    return this.store.isGetBlogArticlesProcessing;
  }

  get areNoBlogArticlesAvailable(): boolean {
    return !this.isLoading
      && this.store.blogArticles.length === 0;
  }

  mounted(): void {
    this.store.getBlogArticles()
      .catch((error) => showErrorResponse(error));
  }

  blogArticleClicked(blogArticle: BlogArticle): void {
    this.$router.push({ name: 'consume-blog-articles-blog-article-details', params: { blogArticleId: blogArticle.blogArticleId } });
  }

}
</script>
<template>
<v-container class="blog-article-list">
  <v-row class="mt-md-4">
    <v-col cols="12">

      <v-progress-linear v-if="isLoading" color="primary" indeterminate class="mb-4" />

      <p v-if="areNoBlogArticlesAvailable">
        Noch keine Blog Artikel verfügbar.
      </p>

    </v-col>

    <v-col
      cols="12"
      md="6"
      lg="4"
      v-for="(blogArticle, index) in store.blogArticles"
      :key="blogArticle.blogArticleId"
    >

      <v-row
        class="no-gutters blog-article"
        :class="{ 'mb-4': index !== store.blogArticles.length - 1 }"
        @click="blogArticleClicked(blogArticle)"
      >
        <v-col cols="4">
          <v-card outlined class="overflow-hidden">
            <div class="blur-hash-image-container">
              <blur-hash-image
                :hash="blogArticle.image.hash"
                :src="blogArticle.image.url"
                :alt="blogArticle.title"
                :width="20"
                :height="Math.floor(20 / blogArticle.image.aspectRatio)"
              />
              <span class="new-sign" v-if="blogArticle.isNew">
                Neu
              </span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="8">
          <div class="blog-article-info">
            <strong class="blog-article-title">{{ blogArticle.title }}</strong>
            <span class="d-block action-link">Lesen</span>
          </div>
        </v-col>
      </v-row>

    </v-col>

  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.blog-article-list
  font-size: 0.875rem

  .blog-article
    cursor: pointer

    .blur-hash-image-container
      position: relative

      .new-sign
        position: absolute
        bottom: 0
        right: 0
        color: var(--color-white)
        background: var(--color-brand)
        padding: 2px 0.5rem
        font-weight: 600
        border-top-left-radius: 0.25rem

    .blog-article-info
      padding: 0 0 0 0.75rem
      line-height: 1.15

      strong.blog-article-title
        font-weight: 700
        font-family: Montserrat, sans-serif
        color: var(--color-grey-2)
        display: block
        margin-top: 0.25rem
        margin-bottom: 0.25rem

      span::v-deep
        font-size: 0.875rem

        strong
          font-weight: 600

  .action-link
    color: var(--color-brand)
    font-weight: 600
    margin-top: 0.25rem
</style>
