import axios, { AxiosRequestConfig } from 'axios';
import merge from 'lodash-es/merge';
import dayjs, { Dayjs, isDayjs } from 'dayjs';
import { CommandWithFiles, FileResponse } from '@/application/types';
import { getMobileAppVersion } from '@/infrastructure/device-information';
import { Time } from '@/types';
import { LOCAL_STORAGE_AUTH_TOKEN, setLocalStorageItem } from '@/helpers/local-storage-helper';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { webOrReviewAppMarker } from '@/application/whitelabel/app/review-app-helper';

/**
 * The API url is empty as relative paths are used in the web, only for the mobile application where we don't have the context, we use a
 * specific API url.
 */
export const apiUrl = isNativeApplication()
  && process.env.MOBILE_APP_API_URL
  && process.env.MOBILE_APP_API_URL !== webOrReviewAppMarker
  ? process.env.MOBILE_APP_API_URL
  : '';

const HEADER_AUTH_TOKEN = 'x-auth-token';

// Regular expression to pre-check if strings contain an ISO-8601 date.
// Based on http://dotat.at/tmp/ISO_8601-2004_E.pdf, implementation from https://stackoverflow.com/a/3143231
// eslint-disable-next-line vue/max-len
const dateTimeRegex = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;

function isDateTimeString(value: unknown): boolean {
  // Check if string contains ISO-8601 or DIN-5008 date before parsing to avoid warnings.
  return typeof value === 'string' && dateTimeRegex.test(value);
}

function dayjsAndTimeReviver(key: string, value: unknown): Dayjs | unknown {
  if (isDateTimeString(value)) {
    return dayjs(value as string);
  }

  if (Time.isValidTimeString(value)) {
    return Time.fromString(value as string);
  }

  return value;
}

function dayjsAndTimeReplacer(this: any, key: string, value: unknown): string | unknown {
  if (isDayjs(this[key])) {
    return (this[key] as Dayjs).toISOString();
  }

  if (Time.isTime(this[key])) {
    return (this[key] as Time).format();
  }

  return value;
}

function transformRequest(data: unknown): unknown {
  // Catch calls of transform function with unexpected payload (string|undefined) to avoid double-encoding.
  if (typeof data !== 'object') {
    return data;
  }
  return JSON.stringify(data, dayjsAndTimeReplacer);
}

// eslint-disable-next-line no-unused-vars
function transformRequestWithFiles(command: CommandWithFiles, headers?: Record<string, string>): unknown {
  const data = new FormData();
  data.append('body', JSON.stringify(command.body, dayjsAndTimeReplacer));
  Object.keys(command.files).forEach((key) => {
    const file = command.files[key];
    // We need to filter files with name `body` as they otherwise would overwrite the other JSON serialized content we want to send
    if (key !== 'body' && file) {
      data.append(key, file);
    }
  });

  return data;
}

function transformResponse(data: string): string {
  if (data.length > 0) {
    try {
      return JSON.parse(data, dayjsAndTimeReviver);
    } catch (error) {
      return data;
    }
  }

  return data;
}

function defaultHeaders(): Record<string, string> {
  const mobileAppVersion = getMobileAppVersion();
  const headers: Record<string, string> = {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'application/json;charset=utf-8',
    'X-APP-VERSION': process.env.SOURCE_VERSION,
  };
  const authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);
  if (authToken) {
    headers[HEADER_AUTH_TOKEN] = authToken;
  }
  if (!mobileAppVersion) {
    return headers;
  }

  return {
    ...headers,
    'X-MOBILE-APP-VERSION': mobileAppVersion,
  };
}

function formDataHeaders(): Record<string, string> {
  const mobileAppVersion = getMobileAppVersion();
  const headers: Record<string, string> = {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'multipart/form-data',
    'X-APP-VERSION': process.env.SOURCE_VERSION,
  };
  const authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);
  if (authToken) {
    headers[HEADER_AUTH_TOKEN] = authToken;
  }

  if (!mobileAppVersion) {
    return headers;
  }

  return {
    ...headers,
    'X-MOBILE-APP-VERSION': mobileAppVersion,
  };
}

function generateTrialRunHeader(isTrialRun: boolean): AxiosRequestConfig {
  return (isTrialRun)
    ? { headers: { 'X-TRIAL-RUN': true } }
    : {};
}

function buildRequest(config: AxiosRequestConfig, isTrialRun: boolean): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: defaultHeaders(),
    transformRequest,
    transformResponse,
    withCredentials: true,
  };
  const trialRunHeader = generateTrialRunHeader(isTrialRun);

  return merge(defaults, config, trialRunHeader);
}

function buildRequestWithFiles(config: AxiosRequestConfig, isTrialRun: boolean): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: formDataHeaders(),
    transformRequest: transformRequestWithFiles,
    transformResponse,
    withCredentials: true,
  };
  const trialRunHeader = generateTrialRunHeader(isTrialRun);

  return merge(defaults, config, trialRunHeader);
}

function buildRequestForBinary(config: AxiosRequestConfig): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: defaultHeaders(),
    transformRequest,
    withCredentials: true,
    responseType: 'blob',
  };

  return merge(defaults, config);
}

export async function performApiRequestWithTokenUpdate<T = void>(config: AxiosRequestConfig, isTrialRun = false): Promise<T> {
  return axios.request(buildRequest(config, isTrialRun))
    .then((response) => {
      if (response.headers[HEADER_AUTH_TOKEN]) {
        setLocalStorageItem(LOCAL_STORAGE_AUTH_TOKEN, response.headers[HEADER_AUTH_TOKEN]);
      }

      return response.data;
    });
}

export async function performApiRequest<T = void>(config: AxiosRequestConfig, isTrialRun = false): Promise<T> {
  return axios.request(buildRequest(config, isTrialRun))
    .then((response) => response.data);
}

export async function performApiRequestWithFiles<T = void>(config: AxiosRequestConfig, isTrialRun = false): Promise<T> {
  return axios.request(buildRequestWithFiles(config, isTrialRun))
    .then((response) => response.data);
}

export async function performApiRequestForFile(config: AxiosRequestConfig): Promise<FileResponse> {
  return axios.request(buildRequestForBinary(config))
    .then((response) => ({
      data: response.data,
      contentType: response.headers['content-type'],
    }))
    .catch(async (error) => {
      if (error.response.data instanceof Blob) {
        error.response.data = JSON.parse(await error.response.data.text());
      }

      return Promise.reject(error);
    });
}

export async function performUnauthorizedApiRequest<T>(request: AxiosRequestConfig): Promise<T> {
  return axios.request(request)
    .then((response) => response.data);
}
