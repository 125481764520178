<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth, MimeTypes } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useManageDocumentsStore } from '../store';
import { CreateDocumentCommand } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  shortDescription: FormControl<string>;
  file: FormControl<File>;
}

@Component
export default class CreateDocumentDialog extends Vue {

  readonly store = useManageDocumentsStore();

  readonly dialogMaxWidth = DialogWidth.large;

  readonly acceptedMimeTypes = [
    MimeTypes.PDF,
    MimeTypes.DOC,
    MimeTypes.DOCX,
  ];

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Titel',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        shortDescription: {
          label: 'Kurzbeschreibung',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        file: {
          label: 'Datei',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateDocumentCommand = {
      body: {
        title: formValues.title!,
        shortDescription: formValues.shortDescription!,
      },
      files: {
        file: formValues.file!,
      },
    };

    this.store.createDocument(command)
      .then(() => showSuccessMessage('Dokument wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateDocumentProcessing"
>
  <template #activator="{ on }">
    <div class="d-inline-block" v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Dokument erstellen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <a-text-form-control
              :form-control="form.controls.title"
              is-autofocused
            />
          </v-col>
          <v-col cols="12">
            <a-text-form-control :form-control="form.controls.shortDescription" />
          </v-col>
          <v-col cols="12">
            <a-file-form-control
              :form-control="form.controls.file"
              :accepted-mime-types="acceptedMimeTypes"
              allowed-format-text="PDF oder Word Datei"
              class="mb-4"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateDocumentProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateDocumentProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Erstellen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
