import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { Publication, CreatePublicationCommand, UpdatePublicationCommand } from './types';
import { createPublication, getPublications, updatePublication } from './service';

interface ManagePublicationsState {
  publications: Publication[];

  getPublicationsStatus: ActionStatus;
  createPublicationStatus: ActionStatus;
  updatePublicationStatus: ActionStatus;
}

function initialState(): ManagePublicationsState {
  return {
    publications: [],

    getPublicationsStatus: ActionStatus.None,
    createPublicationStatus: ActionStatus.None,
    updatePublicationStatus: ActionStatus.None,
  };
}

export const useManagePublicationsStore = defineStore('managePublications', {
  state: (): ManagePublicationsState => initialState(),
  getters: {
    isGetPublicationsProcessing: (state: ManagePublicationsState): boolean =>
      state.getPublicationsStatus === ActionStatus.InProgress,
    isCreatePublicationProcessing: (state: ManagePublicationsState): boolean =>
      state.createPublicationStatus === ActionStatus.InProgress,
    isUpdatePublicationProcessing: (state: ManagePublicationsState): boolean =>
      state.updatePublicationStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getPublications(): Promise<void> {
      const { getPublicationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPublicationsStatus,
        () => getPublications(attachAppAndUser({}))
          .then(async (publications) => {
            this.publications = publications;
          })
      );
    },

    // -- Commands

    createPublication(command: CreatePublicationCommand): Promise<void> {
      const { createPublicationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createPublicationStatus,
        () => createPublication(attachAppAndUser(command))
          .then(() => this.getPublications())
      );
    },

    updatePublication(command: UpdatePublicationCommand): Promise<void> {
      const { updatePublicationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePublicationStatus,
        () => updatePublication(attachAppAndUser(command))
          .then(() => this.getPublications())
      );
    },

  },
});
