import { RouteConfig } from 'vue-router';
import HabitIntentList from './components/habit-intent-list.vue';
import Habit from './components/habit.vue';
import SelectNewHabit from './components/select-new-habit.vue';

export const manageHabitIntentsRoutes: RouteConfig[] = [
  {
    path: '/gewohnheiten',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'manage-habit-intents-habit-intent-list',
        path: '/',
        component: HabitIntentList,
        meta: {
          requiresAuth: true,
          title: 'Meine Gewohnheiten',
        },
      }, {
        name: 'manage-habit-intents-habit',
        path: 'gewohnheit/:id',
        component: Habit,
        meta: {
          requiresAuth: true,
        },
      }, {
        name: 'manage-habit-intents-select-new-habit',
        path: 'neue-gewohnheit',
        component: SelectNewHabit,
        meta: {
          requiresAuth: true,
          title: 'Neue Gewohnheit',
        },
      },
    ],
  },
];
