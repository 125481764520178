import { Component, Vue, Watch } from 'vue-property-decorator';
import { FormDefinition } from '@/types/form';

export type DialogWidth = 'sm' | 'lg' | 'xl';

@Component
export class BaseDialogComponent extends Vue {

  protected sizeMap = {
    sm: 500,
    lg: 800,
    xl: 1140,
  };
  protected dialogWidth!: DialogWidth;
  protected form: FormDefinition = {
    valid: false,
    fields: {},
  };

  @Watch('dialog')
  onVisibilityChanged() {
    if (!this.dialog) {
      this.destroyActionSubscriptions();

      if (this.$refs.form) {
        this.resetForm();
      }
    }
  }

  public get size(): number {
    return this.dialogWidth && this.sizeMap[this.dialogWidth] || this.sizeMap.sm;
  }

  protected dialog = false;

  protected actionsSubscription: any;

  protected destroyed() {
    this.destroyActionSubscriptions();
  }

  // Has to be overwritten in component when there are subscriptions for the dialog
  protected setupActionSubscriptions() {}

  protected destroyActionSubscriptions() {
    if (this.actionsSubscription) {
      this.actionsSubscription();
    }
  }

  protected resetForm() {
    // @ts-ignore
    this.$refs.form.reset();

    // Reset for date pickers
    for (const prop in this.form.fields) {
      if (
        Object.prototype.hasOwnProperty.call(this.form.fields, prop)
        && typeof this.form.fields[prop] === 'string'
        && this.form.fields[prop].length > 0
      ) {
        this.form.fields[prop] = '';
      }
    }
  }

  public show() {
    this.setupActionSubscriptions();
    this.dialog = true;
  }

  public hide() {
    this.destroyActionSubscriptions();
    this.dialog = false;
  }

}
