import { RouteConfig } from 'vue-router';
import ContactIntroduction from './components/contact-introduction.vue';

export const unauthorizedContactRoutes: RouteConfig[] = [
  {
    name: 'unauthorized-contact-contact-introduction',
    path: '/technischer-support',
    component: ContactIntroduction,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      title: 'Technischer Support',
    },
  },
];
