import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { Authentication, LoginAction } from './types';

// -- Actions

export function login(command: LoginAction): Promise<Authentication> {
  const url = `${apiUrl}/admin-api/unauthorized/application/authentication/login`;
  return performApiRequest<Authentication>({ url, method: 'POST', data: command });
}

export function logout(): Promise<void> {
  const url = `${apiUrl}/admin-api/unauthorized/application/authentication/logout`;
  return performApiRequest({ url, method: 'POST' });
}

export function fetchAuthenticatedUser(): Promise<Authentication> {
  const url = `${apiUrl}/admin-api/application/authentication/authenticated-user`;
  return performApiRequest<Authentication>({ url, method: 'GET' });
}
