<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentElementDocumentForPreview } from '../types';

@Component
export default class DocumentPreview extends Vue {

  @Prop()
  readonly contentElement!: ContentElementDocumentForPreview;

}
</script>
<template>
<div class="document pt-4">
  <h3>
    {{ contentElement.documentTitle }}
  </h3>
  <span v-html="contentElement.documentExplanation" />
  <v-row
    no-gutters
    class="mt-3"
  >
    <v-col class="mr-1">
      <a-execute-secondary-action-button
        is-small
        is-always-shown-in-block-mode
      >
        Herunterladen
      </a-execute-secondary-action-button>
    </v-col>
    <v-col class="ml-1">
      <a-execute-secondary-action-button
        is-small
        is-always-shown-in-block-mode
      >
        Als E-Mail
      </a-execute-secondary-action-button>
    </v-col>
  </v-row>
</div>
</template>
<style lang="sass" scoped>
.document
  background: var(--color-grey-9)
  padding: 1.25rem 1rem
  border: solid var(--color-grey-8)
  border-width: 1px 0
  color: var(--color-grey-3)

  strong
    font-weight: 700
    font-family: Montserrat, sans-serif
    color: var(--color-grey-2)

  &::v-deep
    p,
    ul
      font-size: 1rem

  .v-btn
    cursor: not-allowed
</style>
