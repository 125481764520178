<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Time, Weekday } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, minArrayCountRule } from '@/components/form';
import { useManageHabitIntentsStore } from '../store';
import { HabitIntent, UpdateHabitIntentCommand } from '../types';

interface Controls extends FormControls {
  trigger: FormControl<string>;
  weekdays: FormControl<Weekday[]>;
  notificationTime: FormControl<Time>;
}

@Component
export default class UpdateHabitIntentDialog extends Vue {

  readonly store = useManageHabitIntentsStore();

  @Prop({ type: Object, required: true })
  readonly habitIntent!: HabitIntent;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        trigger: {
          label: 'Nach was willst du die Aktivität ausführen?',
          value: this.habitIntent.trigger ?? null,
        },
        weekdays: {
          label: 'An welchen Tagen?',
          value: this.habitIntent.weekdays,
          isRequired: true,
          rules: [
            minArrayCountRule(1, 'Es muss mindestens ein Wochentag ausgewählt werden'),
          ],
        },
        notificationTime: {
          label: 'Uhrzeit für Notification',
          value: this.habitIntent.notificationTime,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateHabitIntentCommand = {
      habitIntentId: this.habitIntent.habitIntentId,
      trigger: formValues.trigger,
      notificationTime: formValues.notificationTime!,
      weekdays: formValues.weekdays!,
    };

    this.store.updateHabitIntent(command)
      .then(() => showSuccessMessage('Gewohnheit wurde aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isUpdateHabitIntentProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Gewohnheit anpassen</v-card-title>
      <v-card-text>
        <a-text-form-control
          :form-control="form.controls.trigger"
          placeholder="Z.B. Nach dem Aufstehen"
          is-autofocused
        />
        <a-weekdays-form-control
          :form-control="form.controls.weekdays"
          placeholder="Wähle 2 - 4 Tage"
        />

        <a-time-form-control :form-control="form.controls.notificationTime" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isUpdateHabitIntentProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isUpdateHabitIntentProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
