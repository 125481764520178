<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useAppStore } from '@/application/common/app/store';
import { ActionStatus } from '@/store';
import { useConsumeModulesStore } from '../store';
import TableOfContentsPage from './table-of-contents-page.vue';
import SuccessPage from './success-page.vue';
import HabitProposal from './habit-proposal.vue';
import Quiz from './quiz.vue';
import Acknowledgement from './acknowledgement.vue';
import VideoElement from './video-element.vue';
import ImageElement from './image-element.vue';
import RichText from './rich-text.vue';
import Document from './document.vue';
import SelfReflection from './self-reflection.vue';
import TableOfContentsNavigation from './table-of-contents-navigation.vue';

/**
 * Changes here might also need to be done in the content management for the preview of the module.
 */
@Component({
  components: {
    TableOfContentsPage,
    SuccessPage,
    HabitProposal,
    Quiz,
    Acknowledgement,
    VideoElement,
    ImageElement,
    RichText,
    TableOfContentsNavigation,
    Document,
    SelfReflection,
  },
})
export default class ConsumeModule extends Vue {

  readonly store = useConsumeModulesStore();
  readonly appStore = useAppStore();

  isSummaryMenuVisible = false;

  get isLoading(): boolean {
    return this.store.getModuleStatus === ActionStatus.InProgress;
  }

  mounted(): void {
    watch(() => this.store.activeModule, () => {
      if (this.store.activeModule) {
        this.appStore.updateTitle(this.store.activeModule.title);
      }
    });

    this.store.startModule(this.$route.params.id)
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.pauseVideos();
    this.store.$reset();
  }

  onSelectExerciseCheckbox(value: boolean): void {
    if (value) {
      this.store.confirmExerciseForActiveModulePage();
    } else {
      this.store.retractExerciseConfirmationForActiveModulePage();
    }
  }

  pauseVideos(): void {
    const videoElements = this.$refs.videoElement as VideoElement[];
    if (videoElements) {
      videoElements.forEach((videoElement) => {
        // @ts-ignore
        videoElement.pause();
      });
    }
  }

  onNextPageClicked(): void {
    this.pauseVideos();
    this.store.moveToNextPage()
      .then(() => window.scrollTo({ top: 0 }))
      .catch((error) => showErrorResponse(error));
  }

  onTouchLeft(): void {
    if (this.store.isFastForwardNextPageButtonAllowed) {
      this.onNextPageClicked();
    }
  }

  onTouchRight(): void {
    if (this.store.activeModulePageIndex !== null
      && this.store.activeModulePageIndex > 0
    ) {
      this.pauseVideos();
      this.store.moveToPreviousPage();
      window.scrollTo({ top: 0 });
    }
  }

  toggleSummaryClicked(): void {
    this.isSummaryMenuVisible = !this.isSummaryMenuVisible;
  }

  navigatedThroughSummary(): void {
    this.isSummaryMenuVisible = false;
  }

}
</script>
<template>
<div>
  <div class="progress-bar">
    <v-progress-linear :value="store.progress" color="primary" height="20" class="full-with" />

    <v-btn
      v-if="store.isActivePageTypeModule"
      fab
      small
      elevation="3"
      class="summary-trigger hidden-md-and-up"
      :class="{ 'summary-open': isSummaryMenuVisible }"
      @click="toggleSummaryClicked"
    >
      <font-awesome-icon :icon="['far', 'bookmark']" />
    </v-btn>

    <template v-if="isSummaryMenuVisible">
      <v-scroll-y-reverse-transition>
        <v-card
          class="mobile-table-of-contents pb-2"
        >
          <v-card-text class="pb-2">
            <h3>Inhaltsverzeichnis</h3>
          </v-card-text>
          <table-of-contents-navigation
            dense
            @navigated="navigatedThroughSummary"
          />
        </v-card>
      </v-scroll-y-reverse-transition>
    </template>

  </div>

  <v-container class="content-container">

    <v-progress-linear v-if="isLoading && !store.activeModule" color="primary" indeterminate />

    <template v-if="store.activeModule">

      <v-row no-gutters>
        <v-col cols="12" md="7" lg="8">
          <v-card outlined>
            <v-card-text class="pl-0 pt-0 pr-0">

              <table-of-contents-page v-if="store.isActivePageTypeTableOfContents" />

              <div v-if="store.isActivePageTypeModule">
                <v-window
                  :value="store.activeModulePageIndex"
                  :show-arrows="false"
                  :touch="{ left: onTouchLeft, right: onTouchRight }"
                  height="auto"
                >
                  <v-window-item
                    v-for="(modulePage) in store.activeModule.pages"
                    :key="modulePage.modulePageId"
                    :transition="false"
                  >

                    <template v-for="contentElement in modulePage.contentElements">

                      <div
                        v-if="contentElement.type === 'rich-text'"
                        :key="contentElement.contentElementId"
                        class="pl-4 pr-4"
                      >
                        <rich-text :content-element="contentElement" />
                      </div>

                      <div
                        v-if="contentElement.type === 'image'"
                        :key="contentElement.contentElementId"
                      >
                        <image-element :content-element="contentElement" />
                      </div>

                      <div
                        v-if="contentElement.type === 'video'"
                        :key="contentElement.contentElementId"
                      >
                        <video-element :content-element="contentElement" ref="videoElement" />
                      </div>

                      <div
                        v-if="contentElement.type === 'quiz'"
                        :key="contentElement.contentElementId"
                        class="mb-4"
                      >
                        <quiz :content-element="contentElement" />
                      </div>

                      <div
                        v-if="contentElement.type === 'habit-proposal'"
                        :key="contentElement.contentElementId"
                        class="mb-4"
                      >
                        <habit-proposal :content-element="contentElement" />
                      </div>

                      <div
                        v-if="contentElement.type === 'acknowledgement'"
                        :key="contentElement.contentElementId"
                        class="mb-4"
                      >
                        <acknowledgement :content-element="contentElement" />
                      </div>

                      <div
                        v-if="contentElement.type === 'document'"
                        :key="contentElement.contentElementId"
                        class="mb-4"
                      >
                        <document :content-element="contentElement" />
                      </div>

                      <div
                        v-if="contentElement.type === 'self-reflection'"
                        :key="contentElement.contentElementId"
                        class="mb-4"
                      >
                        <self-reflection :content-element="contentElement" />
                      </div>

                    </template>

                    <div class="exercise-confirmation pl-4 pr-4" v-if="store.isExerciseCheckboxShown">
                      <p>
                        Damit die Übungen ihre gewünschte Wirkung erzielen ist es wichtig, dass sie immer vollständig durchgeführt werden. Bestätige nach jeder Übung die korrekte Durchführung um fortfahren zu können.
                      </p>
                      <v-checkbox
                        label="Ich habe die Übung mit der genannten Anzahl an Wiederholungen absolviert"
                        @change="onSelectExerciseCheckbox"
                      />
                    </div>

                    <div class="ml-4 mr-4">
                      <a-execute-primary-action-button
                        v-if="store.isNextPageButtonShown"
                        @click="onNextPageClicked"
                        :is-disabled="store.isNextPageButtonDisabled"
                        :is-processing="store.isMoveToNextPageProcessing"
                      >
                        Weiter
                      </a-execute-primary-action-button>
                    </div>

                  </v-window-item>
                </v-window>
              </div>

              <success-page v-if="store.isActivePageTypeSuccess" />

            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="5"
          lg="4"
          v-if="store.isActivePageTypeModule"
          class="hidden-sm-and-down"
        >
          <v-card outlined class="table-of-contents ml-4 pb-2">
            <v-card-text class="pb-2">
              <h3>Inhaltsverzeichnis</h3>
            </v-card-text>
            <table-of-contents-navigation dense />
          </v-card>
        </v-col>

      </v-row>

    </template>

  </v-container>
</div>
</template>
<style lang="sass" scoped>
@media screen and (max-width: 960px)

  .content-container
    padding: 0

  .v-card
    border: none

.progress-bar
  background: var(--color-white)
  position: sticky
  top: calc(env(safe-area-inset-top) + 60px)
  z-index: 5

.v-btn.summary-trigger
  background: var(--color-green-5)
  color: white
  font-size: 1.25rem
  position: absolute
  top: 0.25rem
  right: 1rem
  margin-right: 0
  opacity: 1

  &.summary-open
    color: var(--color-green-5)
    background: white

  svg
    margin-right: 0 !important

.mobile-table-of-contents
  position: fixed
  top: calc(env(safe-area-inset-top) + 114px)
  left: calc(env(safe-area-inset-left) + 1rem)
  right: calc(env(safe-area-inset-right) + 1rem)
  bottom: calc(env(safe-area-inset-bottom) + 1rem)
  overflow-y: scroll

.v-window-item
  .rich-text:first-of-type::v-deep
    > p:first-of-type
      margin-top: 1rem

  .quiz:first-of-type
    margin-top: 1rem

.exercise-confirmation
  .v-input::v-deep .v-label
      line-height: 1.5
</style>
<style lang="sass">
@media screen and (max-width: 960px)
  .consume-modules-consume-module
    .v-main
      background: var(--color-white)
</style>
