import { RouteConfig } from 'vue-router';
import { Feature } from '@/types';
import ExperienceReportList from './components/experience-report-list.vue';
import ExperienceReportDetails from './components/experience-report-details.vue';

export const consumeExperienceReportsRoutes: RouteConfig[] = [
  {
    path: '/erfahrungsberichte',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'consume-experience-reports-experience-report-list',
        path: '/',
        component: ExperienceReportList,
        meta: {
          requiresAuth: true,
          requiresFeature: Feature.EXPERIENCE_REPORTS,
          title: 'Erfahrungsberichte',
        },
      },
      {
        name: 'consume-experience-reports-experience-report-details',
        path: 'erfahrungsbericht/:experienceReportId',
        component: ExperienceReportDetails,
        meta: {
          requiresAuth: true,
          requiresFeature: Feature.EXPERIENCE_REPORTS,
          title: 'Erfahrungsberichte',
        },
      },
    ],
  },
];
