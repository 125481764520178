<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import dayjs from 'dayjs';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { Feature } from '@/types';
import LevelUpDialog from './level-up-dialog.vue';
import RateGoalProgressDialog from './rate-goal-progress-dialog.vue';
import { useUserProgressStore } from '../store';

/**
 * Changes here might also need to be done in the content management for the preview of the module.
 */
@Component({
  components: {
    LevelUpDialog,
    RateGoalProgressDialog,
  },
})
export default class HealthPointsBadge extends Vue {

  readonly store = useUserProgressStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly progressPageRouteName = 'user-progress-user-goal-list';
  readonly onboardingRouteName = 'onboarding';

  @Ref()
  readonly levelUpDialog!: LevelUpDialog;

  @Ref()
  readonly rateGoalProgressDialog!: RateGoalProgressDialog;

  snapshotOfHealthPoints: number | null = null;
  snapshotOfLevel: number | null = null;

  werePointsChanged = false;

  get isLoading(): boolean {
    return this.store.isGetUserProgressProcessing;
  }

  get isNotClickable(): boolean {
    return [
      this.onboardingRouteName,
      this.progressPageRouteName,
    ].includes(this.$route.name!);
  }

  mounted(): void {
    watch(() => this.store.userProgress, (userProgress) => {
      if (userProgress === null) {
        return;
      }

      // The snapshots are null on the first run and therefore ignored
      if (this.snapshotOfHealthPoints !== null
        && this.snapshotOfHealthPoints !== userProgress.healthPoints
      ) {
        this.snapshotOfHealthPoints = userProgress.healthPoints;
        this.werePointsChanged = true;
        setTimeout(() => {
          this.werePointsChanged = false;
        }, 500);
      }

      if (this.snapshotOfLevel !== null
        && this.snapshotOfLevel !== userProgress.level
      ) {
        this.snapshotOfLevel = userProgress.level;
        this.levelUpDialog.show();
      }

      const now = dayjs();
      const oneWeekAgo = now.subtract(1, 'week');
      if (this.authenticationStore.isFeatureEnabled(Feature.GOAL_TRACKING)) {
        const wasLastRatedMoreThenAWeekAgo = userProgress.lastRatingForAGoalAt
          && userProgress.lastRatingForAGoalAt.isBefore(oneWeekAgo);

        const wasRatingNotRejectedToday = !userProgress.lastRejectedRatingGoalsAt
          || !userProgress.lastRejectedRatingGoalsAt.isSame(now, 'day');

        if (wasLastRatedMoreThenAWeekAgo
          && wasRatingNotRejectedToday
        ) {
          this.rateGoalProgressDialog.show();
        }
      }
    });

    this.store.getUserProgress()
      .then(() => {
        this.snapshotOfHealthPoints = this.store.userProgress!.healthPoints;
        this.snapshotOfLevel = this.store.userProgress!.level;
      })
      .catch(() => {});
  }

  onClick(): void {
    if (!this.isNotClickable) {
      this.$router.push({ name: this.progressPageRouteName });
    }
  }

}
</script>
<template>
<div class="health-points-badge-container">
  <div
    class="health-points-badge"
    :class="{ 'points-changed': werePointsChanged, 'not-clickable': isNotClickable }"
    @click="onClick"
  >
    <span v-if="store.userProgress">{{ store.userProgress.healthPoints }}</span>

    <font-awesome-icon :icon="['far', 'salad']" small v-if="authenticationStore.appPreventionType === 'NUTRITION'" />
    <font-awesome-icon :icon="['far', 'running']" small v-if="authenticationStore.appPreventionType === 'MOVEMENT'" />
    <font-awesome-icon :icon="['far', 'smile']" small v-if="authenticationStore.appPreventionType === 'STRESS'" />

  </div>

  <level-up-dialog ref="levelUpDialog" />

  <rate-goal-progress-dialog
    v-if="store.userProgress"
    :user-goal-progresses="store.userProgress.userGoalProgress"
    ref="rateGoalProgressDialog"
  />

</div>
</template>
<style lang="sass" scoped>
.health-points-badge-container

  .health-points-badge
    position: absolute
    top: calc(15px + env(safe-area-inset-top))
    right: 15px
    height: 28px
    border-radius: 0.25rem
    background: var(--color-white)
    color: var(--color-brand)
    border: 1px solid var(--color-grey-8)
    font-size: 0.875rem
    transition: font-size 300ms
    display: flex
    align-items: center
    justify-content: right
    min-width: 3rem
    cursor: pointer
    padding: 0 0.5rem
    font-family: Montserrat, sans-serif
    font-weight: 800

    &.not-clickable
      cursor: default

    span
      margin-right: 0.3rem

    img
      background: var(--color-brand)

    svg
      margin-top: 1px

      path
        fill: var(--color-brand)

    &.points-changed
      font-size: 1.5rem
</style>
