<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

@Component({
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class NumberSliderFormControl extends Vue implements FormControlComponent<number> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<number>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: Number, default: 1 })
  readonly min!: number;

  @Prop({ type: Number, default: 10 })
  readonly max!: number;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue = 1;

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  sliderChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value ?? 1;
  }

  formValueFromInternalValues(): FormControlValue<number> {
    return this.internalValue;
  }

}
</script>
<template>
<div class="form-control number-slider-form-control" v-bind="$attrs">
  <fieldset class="a-field pt-2">
    <legend>{{ labelWithRequiredIndicator(formControl) }}</legend>
    <div class="form-control-input">

      <v-slider
        v-model="internalValue"
        @input="sliderChanged"
        @focus="focused"
        @blur="blurred"
        :min="min"
        :max="max"
        :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
        :autofocus="isAutofocused ? $vuetify.breakpoint.mdAndUp : false"
        color="primary"
        thumb-label
      />

    </div>
  </fieldset>

  <a-form-control-messages
    :messages="messages"
    :is-focussed="isFocused"
    :is-touched="isTouched"
  />
</div>
</template>
<style lang="sass" scoped>
.v-input::v-deep
  .v-input__slot
    margin-bottom: 0 !important
</style>
