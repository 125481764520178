<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useAppStore } from '@/application/common/app/store';
import { useConsumeExperienceReportsStore } from '../store';

@Component
export default class ExperienceReportDetails extends Vue {

  readonly store = useConsumeExperienceReportsStore();
  readonly appStore = useAppStore();

  get isLoading(): boolean {
    return this.store.isGetExperienceReportsProcessing;
  }

  mounted(): void {
    this.store.updateCurrentExperienceReport(this.$route.params.experienceReportId);

    if (this.store.currentExperienceReport === null) {
      this.store.getExperienceReports()
        .catch((error) => showErrorResponse(error));
    }

    watch(() => this.store.currentExperienceReport, () => {
      if (this.store.currentExperienceReport === null) {
        return;
      }

      this.appStore.updateTitle(this.store.currentExperienceReport.name);
    });
  }

  destroyed(): void {
    this.store.resetCurrentExperienceReport();
  }

}
</script>
<template>
<v-container class="experience-report-details">
  <v-progress-linear v-if="isLoading" indeterminate class="mb-4" />
  <v-row
    v-if="store.currentExperienceReport"
    no-gutters
    class="flex-column-reverse flex-md-row"
  >
    <v-col
      cols="12"
      md="7"
      lg="8"
    >
      <v-card outlined>
        <v-card-text class="pb-0">
          <div class="content" v-html="store.currentExperienceReport.content" />
        </v-card-text>
      </v-card>

      <a-trigger-tertiary-process-button
        :to="{ name: 'consume-experience-reports-experience-report-list' }"
        class="mt-2 mt-md-4 mb-6 mb-md-4"
      >
        Alle Erfahrungsberichte
      </a-trigger-tertiary-process-button>
    </v-col>
    <v-col
      cols="12"
      md="5"
      lg="4"
    >
      <v-card class="ml-0 ml-md-4" outlined>
        <v-card-text class="pa-0">
          <blur-hash-image
            :hash="store.currentExperienceReport.image.hash"
            :src="store.currentExperienceReport.image.url"
            :alt="store.currentExperienceReport.name"
            :width="20"
            :height="Math.floor(20 / store.currentExperienceReport.image.aspectRatio)"
          />
          <div class="pa-4">
            <h3>{{ store.currentExperienceReport.name }}</h3>
            <p class="mb-0">
              <span class="d-block">{{ store.currentExperienceReport.job }}</span>
              <span class="d-block">{{ store.currentExperienceReport.familySituation }} - {{ store.currentExperienceReport.ageInYears }} Jahre alt</span>
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.content::v-deep
  p
    padding-bottom: 1rem
    margin-bottom: 0

  ul,
  ol
    padding-bottom: 1rem

  li
    p
      padding-bottom: 0

@media screen and (max-width: 960px)

  .experience-report-details
    padding: 0

  .v-card
    border: none
</style>
<style lang="sass">
@media screen and (max-width: 960px)
  .consume-experience-reports-experience-report-details
    .v-main
      background: var(--color-white)
</style>
