<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TriggerTertiaryProcessButton extends Vue {

  @Prop({ type: Boolean, default: false })
  readonly isExact!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

}
</script>
<template>
<v-btn
  type="button"
  color="primary"
  :block="$vuetify.breakpoint.smAndDown"
  v-bind="$attrs"
  v-on="$listeners"
  :disabled="isDisabled"
  class="trigger-tertiary-process-button"
  :ripple="false"
  :exact="isExact"
  depressed
>
  <slot />
</v-btn>
</template>
<style lang="sass">
#app.v-application .v-btn.primary
  &.trigger-tertiary-process-button
    background: transparent !important
    color: var(--color-brand) !important
    border: none !important
    padding: 0 !important
    height: auto !important
    font-weight: 500

    &::before
      opacity: 0 !important
</style>
