import { FileResponse } from '@/application/types';
import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { getCertificatePDFAsAppManager, getCertificates } from './service';
import { CourseCertificate, GetCertificatePDFAsAppManagerQuery } from './types';

interface CertificateManagementState {
  certificates: CourseCertificate[];

  getCertificatesStatus: ActionStatus;
  getCertificatePDFAsAppManagerStatus: ActionStatus;
}

function initialState(): CertificateManagementState {
  return {
    certificates: [],

    getCertificatesStatus: ActionStatus.None,
    getCertificatePDFAsAppManagerStatus: ActionStatus.None,
  };
}

export const useCertificateManagementStore = defineStore('certificateManagement', {
  state: (): CertificateManagementState => initialState(),
  getters: {
    isGetCertificatesProcessing: (state: CertificateManagementState): boolean =>
      state.getCertificatesStatus === ActionStatus.InProgress,
    isGetCertificatePDFAsAppManagerProcessing: (state: CertificateManagementState): boolean =>
      state.getCertificatePDFAsAppManagerStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getCertificates(): Promise<void> {
      const { getCertificatesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCertificatesStatus,
        () => getCertificates(attachAppAndUser({}))
          .then(async (certificates) => {
            this.certificates = certificates;
          })
      );
    },

    getCertificatePDFAsAppManager(query: GetCertificatePDFAsAppManagerQuery): Promise<FileResponse> {
      const { getCertificatePDFAsAppManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCertificatePDFAsAppManagerStatus,
        () => getCertificatePDFAsAppManager(attachAppAndUser(query))
      );
    },

  },
});
