<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import CreateBlogArticleDialog from './create-blog-article-dialog.vue';
import UpdateBlogArticleDialog from './update-blog-article-dialog.vue';
import { useManageBlogArticlesStore } from '../store';
import { BlogArticleListItem, PublishBlogArticleCommand, UnpublishBlogArticleCommand } from '../types';

@Component({
  components: {
    CreateBlogArticleDialog,
    UpdateBlogArticleDialog,
  },
})
export default class BlogArticleList extends Vue {

  readonly store = useManageBlogArticlesStore();

  mounted(): void {
    this.store.getBlogArticles()
      .catch((error) => showErrorResponse(error));
  }

  async publishBlogArticleConfirmed(blogArticle: BlogArticleListItem): Promise<void> {
    const command: PublishBlogArticleCommand = {
      blogArticleId: blogArticle.blogArticleId,
    };
    return this.store.publishBlogArticle(command)
      .catch((error) => showErrorResponse(error));
  }

  async unpublishBlogArticleConfirmed(blogArticle: BlogArticleListItem): Promise<void> {
    const command: UnpublishBlogArticleCommand = {
      blogArticleId: blogArticle.blogArticleId,
    };
    return this.store.unpublishBlogArticle(command)
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <create-blog-article-dialog>
    <a-trigger-primary-process-button>
      Blog Artikel erstellen
    </a-trigger-primary-process-button>
  </create-blog-article-dialog>

  <v-card
    class="mt-4 mb-4 overflow-hidden"
    outlined
  >
    <a-action-list
      :items="store.blogArticles"
      item-id="blogArticleId"
      :is-loading="store.isGetBlogArticlesProcessing"
      empty-list-message="Noch keine Blog Artikel angelegt."
    >
      <template #content="{ item: blogArticle }">
        <router-link :to="{ name: 'manage-blog-content-management', params: { blogArticleId: blogArticle.blogArticleId }}">
          {{ blogArticle.title }}
          <template v-if="!blogArticle.isPublished"> (Unveröffentlicht)</template>
        </router-link>
        <span class="details">
          Erstellt am {{ blogArticle.createdAt | formatDate }}
        </span>
      </template>
      <template #actions="{ item: blogArticle }">

        <update-blog-article-dialog :blog-article="blogArticle">
          <icon-button
            small
            :icon="['far', 'pencil']"
            tooltip="Einstellungen bearbeiten"
          />
        </update-blog-article-dialog>

        <a-confirmation-dialog
          title="Blog Artikel veröffentlichen"
          button-text="Veröffentlichen"
          :action="() => publishBlogArticleConfirmed(blogArticle)"
          :is-processing="store.isPublishBlogArticleProcessing"
        >
          <icon-button
            v-if="!blogArticle.isPublished"
            small
            :icon="['far', 'eye']"
            tooltip="Veröffentlichen"
          />
          <template #content>
            <p>
              Soll der Blog Artikel wirklich veröffentlicht werden?
            </p>
          </template>
        </a-confirmation-dialog>
        <a-confirmation-dialog
          title="Blog Artikel verstecken"
          button-text="Verstecken"
          highlight="warning"
          :action="() => unpublishBlogArticleConfirmed(blogArticle)"
          :is-processing="store.isUnpublishBlogArticleProcessing"
        >
          <icon-button
            v-if="blogArticle.isPublished"
            small
            :icon="['far', 'eye-slash']"
            tooltip="Verstecken"
          />
          <template #content>
            <p>
              Soll der Blog Artikel wirklich verstecken werden?
            </p>
          </template>
        </a-confirmation-dialog>
      </template>
    </a-action-list>
  </v-card>

</v-container>
</template>
