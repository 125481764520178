import { Dayjs } from 'dayjs';
import { Command } from '@/application/types';
import { UserGoalId, UserGoalProposalId } from '@/types';

// -- Models

export interface UserProgress {
  level: number;
  levelName: string;
  healthPoints: number;
  necessaryHealthPointsOfCurrentLevel: number;
  necessaryHealthPointsOfNextLevel?: number;
  userGoalProgress: UserGoalProgresses;
  lastRatingForAGoalAt?: Dayjs;
  lastRejectedRatingGoalsAt?: Dayjs;
}

export type UserGoalProgresses = UserGoalProgress[];

export interface UserGoalProgress {
  userGoalId: UserGoalId;
  title: string;
  ratedGoalsAt: Dayjs[];
  scores: number[];
  firstScore?: number;
  latestScore?: number;
}

export interface UserGoalProgressWithAtLeastOneRating extends UserGoalProgress {
  firstScore: number;
}

export function isUserGoalProgressWithAtLeastOneRating(
  userGoalProgress: UserGoalProgress
): userGoalProgress is UserGoalProgressWithAtLeastOneRating {
  return userGoalProgress.firstScore !== undefined;
}

export interface UserGoalProposal {
  userGoalProposalId: UserGoalProposalId;
  title: string;
}

export interface UserGoalProgressRating {
  userGoalId: UserGoalId;
  userGoalProgressScore: number;
}

// -- Commands

export interface DeleteUserGoalCommand extends Command {
  userGoalId: UserGoalId;
}

export interface RateUserGoalProgressCommand extends Command {
  userGoalProgresses: UserGoalProgressRating[];
}

export interface CreateUserGoalCommand extends Command {
  title: string;
  initialScore: number;
}

export interface UpdateUserGoalCommand extends Command {
  userGoalId: UserGoalId;
  title: string;
}
