<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { publicationTypeTranslations } from '@/helpers/data';
import CreatePublicationDialog from './create-publication-dialog.vue';
import UpdatePublicationDialog from './update-publication-dialog.vue';
import { useManagePublicationsStore } from '../store';
import { Publication } from '../types';

@Component({
  components: {
    CreatePublicationDialog,
    UpdatePublicationDialog,
  },
})
export default class PublicationList extends Vue {

  readonly store = useManagePublicationsStore();

  mounted(): void {
    this.store.getPublications()
      .catch((error) => showErrorResponse(error));
  }

  translatedType(publication: Publication): string {
    return publicationTypeTranslations[publication.type];
  }

}
</script>
<template>
<v-container>

  <create-publication-dialog>
    <a-trigger-primary-process-button>
      Publikation erstellen
    </a-trigger-primary-process-button>
  </create-publication-dialog>

  <v-card
    class="mt-4 mb-4 overflow-hidden"
    outlined
  >
    <a-action-list
      :items="store.publications"
      item-id="publicationId"
      :is-loading="store.isGetPublicationsProcessing"
      empty-list-message="Noch keine Publikationen angelegt."
    >
      <template #content="{ item: publication }">
        <span class="item-title">
          {{ publication.title }}
        </span>
        <span class="details">
          {{ translatedType(publication) }}
        </span>
      </template>
      <template #actions="{ item: publication }">

        <update-publication-dialog :publication="publication">
          <icon-button
            small
            :icon="['far', 'pencil']"
            tooltip="Publikation anpassen"
          />
        </update-publication-dialog>

      </template>
    </a-action-list>
  </v-card>

</v-container>
</template>
