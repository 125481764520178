<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { UpdateUserGoalCommand, UserGoalProgress } from '../types';
import { useUserProgressStore } from '../store';

interface Controls extends FormControls {
  title: FormControl<string>;
}

@Component
export default class UpdateUserGoalDialog extends Vue {

  readonly store = useUserProgressStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly userGoalProgress!: UserGoalProgress;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Bezeichnung',
          value: this.userGoalProgress.title,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateUserGoalCommand = {
      userGoalId: this.userGoalProgress.userGoalId,
      title: formValues.title!,
    };

    this.store.updateUserGoal(command)
      .then(() => showSuccessMessage('Ziel wurde aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
  <v-dialog
    v-model="isDialogVisible"
    :max-width="dialogMaxWidth"
    :persistent="store.isUpdateUserGoalProcessing"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
    :hide-overlay="$vuetify.breakpoint.smAndDown"
  >
    <template #activator="{ on }">
      <span v-on="on">
        <slot />
      </span>
    </template>
    <v-card>
      <a-form
        v-if="form"
        :form="form"
      >
        <v-card-title>Ziel anpassen</v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="12">
              <a-text-form-control
                :form-control="form.controls.title"
                is-autofocused
              />
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <a-cancel-action-button
            :is-processing="store.isUpdateUserGoalProcessing"
            @click="closeDialog"
          />
          <a-submit-primary-action-button
            :is-disabled="isSubmitDisabled"
            :is-processing="store.isUpdateUserGoalProcessing"
          >
            Ziel anpassen
          </a-submit-primary-action-button>
        </v-card-actions>

      </a-form>
    </v-card>
  </v-dialog>
</template>
