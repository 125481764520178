import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modules"},[_c(VCardText,{staticClass:"pb-0"},[_c('h2',[_vm._v("Erfahrungsberichte")]),(_vm.store.featureExamples && _vm.store.featureExamples.experienceReports.length > 0)?_c(VRow,{staticClass:"experience-report-list mt-4 mb-4 mb-md-2"},_vm._l((_vm.store.featureExamples.experienceReports),function(experienceReport,index){return _c(VCol,{key:experienceReport.experienceReportId,attrs:{"cols":"12","md":"6","lg":"4"}},[_c(VRow,{staticClass:"no-gutters experience-report",class:{ 'mb-4': index !== _vm.store.featureExamples.experienceReports.length - 1 }},[_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{staticClass:"overflow-hidden",attrs:{"outlined":""}},[_c('div',{staticClass:"blur-hash-image-container"},[_c('blur-hash-image',{attrs:{"hash":experienceReport.image.hash,"src":experienceReport.image.url,"alt":experienceReport.name,"width":20,"height":Math.floor(20 / experienceReport.image.aspectRatio)}})],1)])],1),_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"experience-report-info"},[_c('strong',{staticClass:"experience-report-title"},[_vm._v(_vm._s(experienceReport.name))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(experienceReport.job))]),_c('span',{staticClass:"d-block action-link"},[_vm._v("Lesen")])])])],1)],1)}),1):_vm._e(),_c('p',[_vm._v(" Hier erzählen andere Nutzer aus ihrem Leben. Das gibt dir eine Einblick, wie andere Personen ihr Leben durch eine angepasste Ernährung verbessert haben. Du findest Geschichten von Personen aus allen Lebensbereichen, Berufen und Altersgruppen. Da entdeckst du bestimmt den einen oder anderen Anknüpfungspunkt für dich. ")])],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{on:{"click":_vm.skipClicked}},[_vm._v(" Einführung überspringen ")]),_c('a-trigger-primary-process-button',{on:{"click":_vm.submitClicked}},[_vm._v(" Weiter ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }