<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { UserProgress } from '../types';

@Component
export default class HealthPoints extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Object, required: true })
  readonly userProgress!: UserProgress;

  get badgeImage(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require,max-len
    return require(`@/assets/images/badges/${this.authenticationStore.appPreventionType.toLowerCase()}/level-${this.userProgress.level}.png`);
  }

  get missingHealthPointsToNextLevel(): number {
    if (!this.userProgress.necessaryHealthPointsOfNextLevel) {
      return 0;
    }

    return this.userProgress.necessaryHealthPointsOfNextLevel - this.userProgress.healthPoints;
  }

  get progressBarValue(): number {
    if (!this.userProgress.necessaryHealthPointsOfNextLevel) {
      return 0;
    }

    const end = this.userProgress.necessaryHealthPointsOfNextLevel - this.userProgress.necessaryHealthPointsOfCurrentLevel;
    const now = this.userProgress.healthPoints - this.userProgress.necessaryHealthPointsOfCurrentLevel;

    const percentage = now / end;

    return percentage * 100;
  }

  get hasUserNotReachedTheHighestLevel(): boolean {
    return this.userProgress.level !== 9;
  }

}
</script>
<template>
<div class="health-points">
  <v-row class="no-gutters health-points mb-4 mt-0 mt-md-4">
    <v-col cols="4">
      <img :src="badgeImage" alt="Badge" class="pr-2" width="100%" />
    </v-col>
    <v-col cols="8">
      <template v-if="hasUserNotReachedTheHighestLevel">
        <p class="mb-2">
          Du bist aktuell <strong>{{ userProgress.levelName }}</strong> auf <strong class="level">Stufe {{ userProgress.level }}</strong> mit {{ userProgress.healthPoints }} Gesundheits&shy;punkten.
        </p>
        <p class="mb-0">
          Bis zur nächsten Stufe benötigst du noch <strong>{{ missingHealthPointsToNextLevel }} Gesundheitspunkte!</strong>
        </p>
      </template>
      <template v-else>
        <strong class="d-block mb-1">Herzlichen Glückwunsch</strong>
        Du bist mit {{ userProgress.healthPoints }} Gesundheits&shy;punkten auf der höchsten <strong class="level">Stufe {{ userProgress.level }}</strong> angekommen und damit ein <strong>{{ userProgress.levelName }}</strong>!
      </template>
    </v-col>
    <v-col cols="12" v-if="hasUserNotReachedTheHighestLevel">
      <v-progress-linear
        :value="progressBarValue"
        height="25"
        class="mt-2"
      />
    </v-col>
  </v-row>
</div>
</template>
<style lang="sass" scoped>
.health-points
  font-size: 0.875rem

  p,
  ul
    font-size: 0.875rem

  .level
    white-space: nowrap
</style>
