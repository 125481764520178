<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';

type HighlightType = 'danger' | 'warning';

@Component
export default class ConfirmationDialog extends Vue {

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, default: 'Ok' })
  readonly buttonText!: string;

  @Prop({ type: Function, required: true })
  readonly action!: () => Promise<void>;

  @Prop({ type: Boolean, default: false })
  readonly isProcessing!: boolean;

  @Prop({ type: String, default: null })
  readonly highlight!: HighlightType | null;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  confirmed(): void {
    this.action()
      .then(() => this.closeDialog());
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :disabled="isDisabled"
  :persistent="isProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>

  <v-card>
    <v-card-title>{{ title }}</v-card-title>

    <v-card-text class="pt-0">
      <slot name="content" />
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer />
      <a-cancel-action-button
        @click="closeDialog"
        :is-processing="isProcessing"
      >
        Abbrechen
      </a-cancel-action-button>
      <a-execute-primary-action-button
        v-if="!highlight"
        @click="confirmed"
        :is-processing="isProcessing"
      >
        {{ buttonText }}
      </a-execute-primary-action-button>
      <a-execute-warning-action-button
        v-if="highlight === 'warning'"
        @click="confirmed"
        :is-processing="isProcessing"
      >
        {{ buttonText }}
      </a-execute-warning-action-button>
      <a-execute-dangerous-action-button
        v-if="highlight === 'danger'"
        @click="confirmed"
        :is-processing="isProcessing"
      >
        {{ buttonText }}
      </a-execute-dangerous-action-button>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
