import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { Habit, CreateHabitCommand, UpdateHabitCommand } from './types';
import { createHabit, getHabits, updateHabit } from './service';

interface ManageHabitsState {
  habits: Habit[];

  getHabitsStatus: ActionStatus;
  createHabitStatus: ActionStatus;
  updateHabitStatus: ActionStatus;
}

function initialState(): ManageHabitsState {
  return {
    habits: [],

    getHabitsStatus: ActionStatus.None,
    createHabitStatus: ActionStatus.None,
    updateHabitStatus: ActionStatus.None,
  };
}

export const useManageHabitsStore = defineStore('manageHabits', {
  state: (): ManageHabitsState => initialState(),
  getters: {
    isGetHabitsProcessing: (state: ManageHabitsState): boolean =>
      state.getHabitsStatus === ActionStatus.InProgress,
    isCreateHabitProcessing: (state: ManageHabitsState): boolean =>
      state.createHabitStatus === ActionStatus.InProgress,
    isUpdateHabitProcessing: (state: ManageHabitsState): boolean =>
      state.updateHabitStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getHabits(): Promise<void> {
      const { getHabitsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHabitsStatus,
        () => getHabits(attachAppAndUser({}))
          .then(async (habits) => {
            this.habits = habits;
          })
      );
    },

    // -- Commands

    createHabit(command: CreateHabitCommand): Promise<void> {
      const { createHabitStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHabitStatus,
        () => createHabit(attachAppAndUser(command))
          .then(() => this.getHabits())
      );
    },

    updateHabit(command: UpdateHabitCommand): Promise<void> {
      const { updateHabitStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateHabitStatus,
        () => updateHabit(attachAppAndUser(command))
          .then(() => this.getHabits())
      );
    },

  },
});
