import { RouteConfig } from 'vue-router';
import { Role } from '@/helpers/data';
import { Feature } from '@/types';
import BlogArticleList from './components/blog-article-list.vue';
import ContentManagement from './components/content-management.vue';

export const manageBlogArticlesRoutes: RouteConfig[] = [
  {
    path: 'blog-verwalten',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'manage-blog-blog-article-list',
        path: '/',
        component: BlogArticleList,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          requiresFeature: Feature.BLOG,
          title: 'Blog verwalten',
          breadcrumbs: [
            { text: 'Blog verwalten' },
          ],
        },
      },
      {
        name: 'manage-blog-content-management',
        path: 'inhalte-verwalten/:blogArticleId',
        component: ContentManagement,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          title: 'Inhalte verwalten',
          breadcrumbs: [
            { text: 'Blog verwalten', to: { name: 'manage-blog-blog-article-list' } },
          ],
        },
      },
    ],
  },
];
