import { AppInfo } from '@capacitor/app';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@/helpers/local-storage-helper';
import { isNativeApplication } from '@/helpers/detection-helpers';

const LOCAL_STORAGE_MOBILE_APP_VERSION = 'mobile_app_version';

export function storeDeviceInformation(appInfo: AppInfo): void {
  setLocalStorageItem(LOCAL_STORAGE_MOBILE_APP_VERSION, appInfo.version);
}

export function clearDeviceInformation(): void {
  removeLocalStorageItem(LOCAL_STORAGE_MOBILE_APP_VERSION);
}

export function getMobileAppVersion(): string | undefined {
  if (!isNativeApplication()) {
    return undefined;
  }

  const mobileAppVersion = getLocalStorageItem(LOCAL_STORAGE_MOBILE_APP_VERSION);

  return mobileAppVersion || undefined;
}
