import { apiUrl, performApiRequest, performApiRequestForFile } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { ContentElementType, GetModuleQuery, GetModuleSummaryPDFQuery, MarkModuleAsCompletedCommand, MarkModuleAsMostRecentlySeenCommand, MarkModulePageAsSeenCommand, MarkQuizAsAnsweredCorrectlyCommand, MarkQuizAsAnsweredIncorrectlyCommand, Module, ModuleListItem, SendModuleSummaryPDFViaEmailCommand, UserGoal } from './types';

export interface ModuleResponse {
  moduleId: string;
  title: string;
  number: number;
  imageFileName?: string;
  imageBlurHash?: string;
  pages: ModulePageResponse[];
  lastSeenModulePageIndex: number;
  completionPercentage: number;
  userGoals: UserGoal[];
  isMostRecentlyViewedModule: boolean;
  isBehindPaywall: boolean;
  isGoalRatingEnabled: boolean;
  isGoalRatingCompleted: boolean;
  isCompleted: boolean;
}

export interface ModulePageResponse {
  modulePageId: string;
  title: string;
  contentElements: ContentElementResponse[];
  isExerciseIncluded: boolean;
}

export interface ContentElementResponse {
  contentElementId: string;
  type: ContentElementType;
  title: string;
}

export function getModules(query: AppAndUserProperty): Promise<ModuleListItem[]> {
  const url = `${apiUrl}/api/learning/get-modules-query`;
  return performApiRequest({ url, method: 'POST', data: query });
}

export function getModule(query: GetModuleQuery): Promise<Module> {
  const url = `${apiUrl}/api/learning/get-module-query`;
  return performApiRequest<ModuleResponse>({ url, method: 'POST', data: query })
    .then((moduleResponse: ModuleResponse) => ({
      ...moduleResponse,
      pages: moduleResponse.pages.map((modulePage) => ({
        ...modulePage,
        isExerciseConfirmed: false,
        contentElements: modulePage.contentElements.map((contentElement) => {
          if (contentElement.type === 'image' as ContentElementType) {
            return {
              ...contentElement,
              hasToBeRendered: false,
            };
          }

          if (contentElement.type === 'acknowledgement' as ContentElementType) {
            return {
              ...contentElement,
              isConfirmed: false,
            };
          }

          return {
            ...contentElement,
          };
        }),
      })),
    }));
}

export function getModuleSummaryPDF(query: GetModuleSummaryPDFQuery & AppAndUserProperty): Promise<FileResponse> {
  const url = `${apiUrl}/api/learning/get-module-summary-pdf-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function markModulePageAsSeen(command: MarkModulePageAsSeenCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/mark-module-page-as-seen-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markModuleAsMostRecentlySeen(command: MarkModuleAsMostRecentlySeenCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/mark-module-as-most-recently-seen-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markModuleAsCompleted(command: MarkModuleAsCompletedCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/mark-module-as-completed-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function sendModuleSummaryPDFViaEmail(command: SendModuleSummaryPDFViaEmailCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/send-module-summary-pdf-via-email-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markQuizAsAnsweredCorrectly(command: MarkQuizAsAnsweredCorrectlyCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/mark-quiz-as-answered-correctly-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markQuizAsAnsweredIncorrectly(command: MarkQuizAsAnsweredIncorrectlyCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/mark-quiz-as-answered-incorrectly-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
