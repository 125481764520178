import { RouteConfig } from 'vue-router';
import { Role } from '@/helpers/data';
import CertificateList from './components/certificate-list.vue';

export const certificateManagementRoutes: RouteConfig[] = [
  {
    path: 'teilnahmebescheinigungen',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'certificate-management-certificate-list',
        path: '/',
        component: CertificateList,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          title: 'Teilnahmebescheinigungen',
          breadcrumbs: [
            { text: 'Teilnahmebescheinigungen' },
          ],
        },
      },
    ],
  },
];
