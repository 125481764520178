import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._b({staticClass:"form-control date-time-picker-form-control"},'div',_vm.$attrs,false),[_c('fieldset',{staticClass:"a-field pb-4"},[_c('legend',[_vm._v(_vm._s(_vm.labelWithRequiredIndicator(_vm.formControl)))]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VMenu,{attrs:{"max-width":"290","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',{staticClass:"form-control-input mb-0"},[_c(VTextField,_vm._g({attrs:{"value":_vm.formattedTextFieldValue,"label":"Datum","readonly":"","hide-details":"","outlined":"","error":_vm.isFieldShownAsContainingAnError(_vm.isFocused, _vm.isTouched, _vm.messages)},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":['far', 'calendar']}})]},proxy:true}],null,true)},on))],1)]}}]),model:{value:(_vm.isMenuVisible),callback:function ($$v) {_vm.isMenuVisible=$$v},expression:"isMenuVisible"}},[_c(VDatePicker,{attrs:{"first-day-of-week":"1","allowed-dates":_vm.allowedDates},on:{"input":_vm.dateChanged},model:{value:(_vm.internalDateValue),callback:function ($$v) {_vm.internalDateValue=$$v},expression:"internalDateValue"}})],1)],1),_c(VCol,{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":"6"}},[_c(VAutocomplete,{attrs:{"items":_vm.timesSelectItems,"error":_vm.isFieldShownAsContainingAnError(_vm.isFocused, _vm.isTouched, _vm.messages),"label":"Uhrzeit","menu-props":"auto","hide-details":"auto","outlined":""},on:{"change":_vm.timeChanged},model:{value:(_vm.internalTimeValue),callback:function ($$v) {_vm.internalTimeValue=$$v},expression:"internalTimeValue"}})],1)],1)],1),_c('a-form-control-messages',{attrs:{"messages":_vm.messages,"is-focussed":_vm.isFocused,"is-touched":_vm.isTouched}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }