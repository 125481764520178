export function setUpDocument(
  appStyle: string,
  appTitle: string
): void {
  document.title = appTitle;

  const head = document.getElementsByTagName('head')[0];

  const faviconLink = document.createElement('link') as HTMLLinkElement;
  faviconLink.type = 'image/png';
  faviconLink.rel = 'icon';
  faviconLink.href = `/favicon-${appStyle}.png`;
  // @ts-ignore
  // noinspection JSConstantReassignment
  faviconLink.sizes = '32x32';
  head.appendChild(faviconLink);

  const icon192Link = document.createElement('link') as HTMLLinkElement;
  icon192Link.type = 'image/png';
  icon192Link.rel = 'icon';
  icon192Link.href = `/favicon-${appStyle}-192x192.png`;
  // @ts-ignore
  // noinspection JSConstantReassignment
  icon192Link.sizes = '192x192';
  head.appendChild(icon192Link);

  const appleTouchIcon = document.createElement('link') as HTMLLinkElement;
  appleTouchIcon.type = 'image/png';
  appleTouchIcon.rel = 'apple-touch-icon';
  appleTouchIcon.href = `/favicon-${appStyle}-192x192.png`;
  head.appendChild(appleTouchIcon);

  const androidTouchIcon = document.createElement('link') as HTMLLinkElement;
  androidTouchIcon.type = 'image/png';
  androidTouchIcon.rel = 'icon shortcut';
  androidTouchIcon.href = `/favicon-${appStyle}-192x192.png`;
  head.appendChild(androidTouchIcon);
}
