<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { constructForm, Form, FormControl, FormControls, getFormValues, identicalPasswordFormControlRule, maxLengthRule } from '@/components/form';
import { AppType } from '@/types';
import { ConfirmUserRegistrationCommand, GetUserRegistrationRequestQuery, LoginAction } from '../types';

interface Controls extends FormControls {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  password: FormControl<string>;
  repeatPassword: FormControl<string>;
}

@Component
export default class UserRegistrationConfirmation extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get areNameFieldsVisible(): boolean {
    return this.authenticationStore.appType === AppType.SPONSOR;
  }

  get isFirstNameAutofocused(): boolean {
    return this.areNameFieldsVisible;
  }

  get isPasswordAutofocused(): boolean {
    return !this.areNameFieldsVisible;
  }

  get isProcessing(): boolean {
    return this.authenticationStore.isConfirmUserRegistrationProcessing
    || this.authenticationStore.isLoginProcessing;
  }

  mounted(): void {
    const query: GetUserRegistrationRequestQuery = {
      confirmationToken: this.$route.params.confirmationToken,
    };
    this.authenticationStore.getUserRegistrationRequest(query)
      .catch((error) => showErrorResponse(error));

    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        firstName: {
          label: 'Vorname',
          value: null,
          isRequired: this.authenticationStore.appType === AppType.SPONSOR,
          rules: [
            maxLengthRule(255),
          ],
        },
        lastName: {
          label: 'Nachname',
          value: null,
          isRequired: this.authenticationStore.appType === AppType.SPONSOR,
          rules: [
            maxLengthRule(255),
          ],
        },
        password: {
          label: 'Passwort',
          value: null,
          isRequired: true,
          rules: [],
          validateFormControlsOnInput: [
            'repeatPassword',
          ],
        },
        repeatPassword: {
          label: 'Passwort wiederholen',
          value: null,
          isRequired: true,
          rules: [],
          validateFormControlsOnInput: [
            'password',
          ],
        },
      },
    });

    form.controls.password.rules!.push(identicalPasswordFormControlRule(form.controls.repeatPassword));
    form.controls.repeatPassword.rules!.push(identicalPasswordFormControlRule(form.controls.password));

    return form;
  }

  public submitted() {
    const formValues = getFormValues(this.form!);

    const command: ConfirmUserRegistrationCommand = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      confirmationToken: this.$route.params.confirmationToken,
      password: formValues.password!,
    };

    this.authenticationStore.confirmUserRegistration(command)
      .then(async () => {
        const loginAction: LoginAction = {
          emailAddress: this.authenticationStore.userRegistrationRequest!.emailAddress,
          password: formValues.password!,
        };
        return this.authenticationStore.login(loginAction)
          .then(() => showSuccessMessage('Registrierung abgeschlossen und angemeldet.'));
      })
      .then(() => this.$router.push({ name: 'default' }))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>
  <v-progress-linear
    v-if="authenticationStore.isGetUserRegistrationRequestProcessing"
    indeterminate
  />

  <a-danger-alert v-if="authenticationStore.userRegistrationRequestError">
    {{ authenticationStore.userRegistrationRequestError }}
  </a-danger-alert>

  <div v-if="authenticationStore.userRegistrationRequest">

    <a-info-alert>
      Bitte lege hier ein Passwort für dein Konto mit der E-Mail Adresse <strong>{{ authenticationStore.userRegistrationRequest.emailAddress }}</strong> fest. Damit wird deine Registrierung abgeschlossen und du wirst automatisch angemeldet.
    </a-info-alert>

    <v-card
      outlined
      class="mt-4"
    >
      <a-form
        v-if="form"
        :form="form"
      >
        <v-card-text class="pt-6 pb-0">

          <v-row>
            <v-col
              v-show="areNameFieldsVisible"
              cols="12"
              md="6"
            >
              <a-text-form-control
                :form-control="form.controls.firstName"
                :is-autofocused="isFirstNameAutofocused"
              />
            </v-col>
            <v-col
              v-show="areNameFieldsVisible"
              cols="12"
              md="6"
            >
              <a-text-form-control :form-control="form.controls.lastName" />
            </v-col>
            <v-col cols="12" md="6">

              <a-password-form-control
                :form-control="form.controls.password"
                :is-autofocused="isPasswordAutofocused"
              />

            </v-col>
            <v-col cols="12" md="6">

              <a-password-form-control :form-control="form.controls.repeatPassword" />

            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <a-cancel-action-button :to="{ name: 'authentication-login' }">
            Zur Anmeldung
          </a-cancel-action-button>
          <a-submit-primary-action-button
            :is-disabled="isSubmitDisabled"
            :is-processing="isProcessing"
          >
            Registrierung abschließen
          </a-submit-primary-action-button>
        </v-card-actions>
      </a-form>
    </v-card>

  </div>
</v-container>
</template>
