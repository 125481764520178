import { RouteConfig } from 'vue-router';
import { Role } from '@/helpers/data';
import { Feature } from '@/types';
import PublicationList from './components/publication-list.vue';

export const managePublicationsRoutes: RouteConfig[] = [
  {
    path: 'literaturverzeichnis',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'manage-bibliography-publication-list',
        path: '/',
        component: PublicationList,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          requiresFeature: Feature.BIBLIOGRAPHY,
          title: 'Literaturverzeichnis verwalten',
          breadcrumbs: [
            { text: 'Literaturverzeichnis verwalten' },
          ],
        },
      },
    ],
  },
];
