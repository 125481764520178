<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useOnboardingStore } from '../store';
import { Module } from '../types';

@Component
export default class ModulesStep extends Vue {

  readonly store = useOnboardingStore();
  readonly authenticationStore = useAuthenticationStore();

  progressDescription(module: Module): string {
    if (module.isCompleted) {
      return 'Abgeschlossen';
    }

    if (module.completionPercentage === 0) {
      return 'Bereit';
    }

    return `Zu ${module.completionPercentage}% abgeschlossen`;
  }

  moduleAction(module: Module): string {
    if (!module.isCompleted) {
      return module.completionPercentage === 0
        ? 'Starten'
        : 'Fortsetzen';
    }

    if (module.isCompleted) {
      return 'Zum Inhaltsverzeichnis';
    }

    throw new Error('Invalid action');
  }

  submitClicked(): void {
    this.$emit('submitConfirmed');
  }

  skipClicked(): void {
    this.$emit('skipConfirmed');
  }

}
</script>
<template>
<div class="modules">
  <v-card-text class="pb-0">
    <h2>Module</h2>

    <v-row
      v-if="store.featureExamples && store.featureExamples.modules.length > 0"
      class="module-list mt-4 mb-6 mb-md-2"
    >
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="(module, index) in store.featureExamples.modules"
        :key="module.moduleId"
      >

        <v-row
          class="no-gutters module"
          :class="{ 'mb-4': index !== store.featureExamples.modules.length - 1 }"
        >
          <v-col cols="4">
            <v-card outlined class="overflow-hidden">
              <blur-hash-image
                v-if="module.image"
                :hash="module.image.hash"
                :src="module.image.url"
                :alt="module.title"
                :width="20"
                :height="Math.floor(20 / module.image.aspectRatio)"
              />
            </v-card>
          </v-col>
          <v-col cols="8">
            <div class="module-info">
              <strong class="module-title">{{ module.title }}</strong>
              <span class="d-block" v-html="progressDescription(module)"></span>
              <span class="d-block action-link">{{ moduleAction(module) }}</span>
            </div>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <strong class="d-block mt-4">Wissensinhalte</strong>
    <p>
      In den Modulen gruppieren wir Wissen und Informationen zu einem spezifischen Thema. Hier siehst du neben Texten interessante Tabellen, Graphen, Bilder und Videos.
    </p>

    <strong class="d-block">Quizfragen</strong>
    <p>
      Damit du die neuen Informationen besser behältst, gibt es zu den verschiedenen Abschnitten kurze Quizfragen mit Erläuterungen zu richtigen und falschen Antworten. Beim Beantworten der Fragen sammelst du Gesundheitspunkte.
    </p>

    <strong class="d-block">Reflexion</strong>
    <p>
      Zudem findest du inspirierende Fragen zu deinem persönlichen Ernährungsverhalten. Neues Wissen wird dich dazu anregen, deine eigenen Routinen aus einer anderen Perspektive zu betrachten.
    </p>

    <strong class="d-block">Wie geh ich’s an?</strong>
    <p>
      Die Module sind sehr abwechslungsreich, sie unterscheiden sich nicht nur durch ihr Thema, sondern auch der Aufbau und die Länge sind je nach Bedarf unterschiedlich, sodass du das Beste geboten bekommst.<br />
      Schau dir am besten als Erstes das Inhaltsverzeichnis an und entscheide dann selbst, ob du ein Modul in einem Rutsch durcharbeiten möchtest oder dir immer wieder einen kleineren Abschnitt vornimmst. Beide Wege führen zum Ziel!
    </p>

  </v-card-text>

  <v-divider />
  <v-card-actions>
    <v-spacer />
    <a-cancel-action-button @click="skipClicked">
      Einführung überspringen
    </a-cancel-action-button>
    <a-trigger-primary-process-button @click="submitClicked">
      Weiter
    </a-trigger-primary-process-button>
  </v-card-actions>

</div>
</template>
<style lang="sass" scoped>
strong
  font-weight: 700
  margin-bottom: 0.25rem
  font-size: 0.875rem
  font-family: Montserrat, sans-serif
  color: var(--color-grey-2)

.module-list
  font-size: 0.875rem

  .module
    cursor: default

    .module-info
      padding: 0 0 0 0.75rem
      line-height: 1.15

      strong.module-title
        font-weight: 700
        font-family: Montserrat, sans-serif
        color: var(--color-grey-2)
        display: block
        margin-top: 0.25rem
        margin-bottom: 0.25rem

      span::v-deep
        font-size: 0.875rem

        strong
          font-weight: 600

  .action-link
    color: var(--color-brand)
    font-weight: 600
    margin-top: 0.25rem
</style>
