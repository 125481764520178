import { RouteConfig } from 'vue-router';
import Onboarding from './components/onboarding.vue';

export const onboardingRoutes: RouteConfig[] = [
  {
    // Name is used in access-guard and in health-points-badge
    name: 'onboarding',
    path: '/einfuehrung',
    component: Onboarding,
    meta: {
      requiresAuth: true,
      title: 'Einführung',
    },
  },
];
