import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"experience-report-details"},[(_vm.isLoading)?_c(VProgressLinear,{staticClass:"mb-4",attrs:{"indeterminate":""}}):_vm._e(),(_vm.store.currentExperienceReport)?_c(VRow,{staticClass:"flex-column-reverse flex-md-row",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","md":"7","lg":"8"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,{staticClass:"pb-0"},[_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.store.currentExperienceReport.content)}})])],1),_c('a-trigger-tertiary-process-button',{staticClass:"mt-2 mt-md-4 mb-6 mb-md-4",attrs:{"to":{ name: 'consume-experience-reports-experience-report-list' }}},[_vm._v(" Alle Erfahrungsberichte ")])],1),_c(VCol,{attrs:{"cols":"12","md":"5","lg":"4"}},[_c(VCard,{staticClass:"ml-0 ml-md-4",attrs:{"outlined":""}},[_c(VCardText,{staticClass:"pa-0"},[_c('blur-hash-image',{attrs:{"hash":_vm.store.currentExperienceReport.image.hash,"src":_vm.store.currentExperienceReport.image.url,"alt":_vm.store.currentExperienceReport.name,"width":20,"height":Math.floor(20 / _vm.store.currentExperienceReport.image.aspectRatio)}}),_c('div',{staticClass:"pa-4"},[_c('h3',[_vm._v(_vm._s(_vm.store.currentExperienceReport.name))]),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.store.currentExperienceReport.job))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.store.currentExperienceReport.familySituation)+" - "+_vm._s(_vm.store.currentExperienceReport.ageInYears)+" Jahre alt")])])])],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }