import { Dayjs } from 'dayjs';

export type ManipulateType = 'hours' | 'hour' | 'minutes' | 'minute' | 'seconds' | 'second';

export type FormatType = 'HH:mm' | 'HH:mm:ss';

export type DiffReturnType = 'hours' | 'minutes' | 'seconds';

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_DAY = 86400;

export interface TimeFrame {
  timeFrom: Time;
  timeTo: Time;
}

export class Time {

  public hour: number;
  public minute: number;
  public second: number;

  get totalSeconds(): number {
    return this.hour * SECONDS_IN_HOUR + this.minute * SECONDS_IN_MINUTE + this.second;
  }

  // Construction

  constructor(
    hour: number,
    minute: number,
    second: number
  ) {
    if (!Time.isValidHour(hour)) {
      throw new Error(`"${hour}" is an invalid hour`);
    }

    if (!Time.isValidMinute(minute)) {
      throw new Error(`"${minute}" is an invalid minute`);
    }

    if (!Time.isValidSecond(second)) {
      throw new Error(`"${second}" is an invalid second`);
    }

    this.hour = hour;
    this.minute = minute;
    this.second = second;
  }

  static fromString(timeString: string): Time {
    if (!Time.isValidTimeString(timeString)) {
      throw new Error(`The value "${timeString}" is no valid time`);
    }

    const [hour, minute, second] = timeString.split(':');

    return new Time(
      parseInt(hour, 10),
      parseInt(minute, 10),
      parseInt(second, 10)
    );
  }

  static fromDate(date: Dayjs): Time {
    return new Time(
      date.hour(),
      date.minute(),
      date.second()
    );
  }

  static fromSeconds(seconds: number): Time {
    return new Time(
      Math.floor(seconds / SECONDS_IN_HOUR),
      Math.floor(seconds % SECONDS_IN_HOUR / SECONDS_IN_MINUTE),
      Math.floor(seconds % SECONDS_IN_HOUR % SECONDS_IN_MINUTE)
    );
  }

  // Conversion

  format(type: FormatType = 'HH:mm:ss'): string {
    const hourString = this.hour < 10 ? `0${this.hour}` : this.hour;
    const minuteString = this.minute < 10 ? `0${this.minute}` : this.minute;
    const secondString = this.second < 10 ? `0${this.second}` : this.second;

    switch (type) {
      case 'HH:mm:ss':
        return `${hourString}:${minuteString}:${secondString}`;
      case 'HH:mm':
        return `${hourString}:${minuteString}`;
      default:
        throw new Error(`The format type "${type} is invalid`);
    }
  }

  diff(timeTo: Time, returnType: DiffReturnType = 'seconds'): number {
    const diffInSeconds = this.totalSeconds - timeTo.totalSeconds;

    switch (returnType) {
      case 'seconds':
        return diffInSeconds;
      case 'minutes':
        return diffInSeconds > 0
          ? Math.floor(diffInSeconds / SECONDS_IN_MINUTE)
          : Math.ceil(diffInSeconds / SECONDS_IN_MINUTE);
      case 'hours':
        return diffInSeconds > 0
          ? Math.floor(diffInSeconds / SECONDS_IN_HOUR)
          : Math.ceil(diffInSeconds / SECONDS_IN_HOUR);
      default:
        throw new Error(`The diff return type "${returnType}" is invalid`);
    }
  }

  // Manipulation

  clone(): Time {
    return new Time(
      this.hour,
      this.minute,
      this.second
    );
  }

  add(value: number, type: ManipulateType): Time {
    let seconds = this.totalSeconds;

    switch (type) {
      case 'hour':
      case 'hours':
        seconds += value * SECONDS_IN_HOUR;
        break;
      case 'minute':
      case 'minutes':
        seconds += value * SECONDS_IN_MINUTE;
        break;
      case 'second':
      case 'seconds':
        seconds += value;
        break;
      default:
        throw new Error(`The manipulation type "${type}" is invalid`);
    }

    seconds %= SECONDS_IN_DAY;

    return Time.fromSeconds(seconds);
  }

  substract(value: number, type: ManipulateType): Time {
    let seconds = this.totalSeconds;

    switch (type) {
      case 'hour':
      case 'hours':
        seconds -= value * SECONDS_IN_HOUR;
        break;
      case 'minute':
      case 'minutes':
        seconds -= value * SECONDS_IN_MINUTE;
        break;
      case 'second':
      case 'seconds':
        seconds -= value;
        break;
      default:
        throw new Error(`The manipulation type "${type}" is invalid`);
    }

    seconds %= SECONDS_IN_DAY;

    if (seconds < 0) {
      seconds = SECONDS_IN_DAY + seconds;
    }

    return Time.fromSeconds(seconds);
  }

  // Validation

  isSame(timeToCompare: Time): boolean {
    return this.diff(timeToCompare) === 0;
  }

  isBefore(timeToCompare: Time): boolean {
    return this.diff(timeToCompare) < 0;
  }

  isAfter(timeToCompare: Time): boolean {
    return this.diff(timeToCompare) > 0;
  }

  isSameOrAfter(timeToCompare: Time): boolean {
    return this.diff(timeToCompare) >= 0;
  }

  isSameOrBefore(timeToCompare: Time): boolean {
    return this.diff(timeToCompare) <= 0;
  }

  isWithinTimeFrame(timeFrame: TimeFrame): boolean {
    return this.isSameOrAfter(timeFrame.timeFrom) && this.isSameOrBefore(timeFrame.timeTo);
  }

  isNotWithinTimeFrame(timeFrame: TimeFrame): boolean {
    return !this.isWithinTimeFrame(timeFrame);
  }

  static isTime(value: unknown): value is Time {
    return value instanceof Time;
  }

  static isValidTimeString(value: unknown): boolean {
    const timeRegex = /(?:[01]\d|2[0123]):[012345]\d:[012345]\d/;

    return typeof value === 'string' && timeRegex.test(value);
  }

  static isValidHour(value: number): boolean {
    return Number.isInteger(value) && value >= 0 && value < 24;
  }

  static isValidMinute(value: number): boolean {
    return Number.isInteger(value) && value >= 0 && value < 60;
  }

  static isValidSecond(value: number): boolean {
    return Number.isInteger(value) && value >= 0 && value < 60;
  }
}
