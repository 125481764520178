import Vue from 'vue';

const euroFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
});

export function formatCurrency(cents: any, dashForZero?: boolean): string {
  if (typeof cents !== 'number') {
    return cents;
  }

  if (dashForZero && cents === 0) {
    return '–';
  }

  return euroFormatter.format(cents / 100);
}

Vue.filter('formatCurrency', formatCurrency);
