<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useConsumeExperienceReportsStore } from '../store';
import { ExperienceReport } from '../types';

@Component
export default class ExperienceReportList extends Vue {

  readonly store = useConsumeExperienceReportsStore();

  get isLoading(): boolean {
    return this.store.isGetExperienceReportsProcessing;
  }

  get areNoExperienceReportsAvailable(): boolean {
    return !this.isLoading
      && this.store.experienceReports.length === 0;
  }

  mounted(): void {
    this.store.getExperienceReports()
      .catch((error) => showErrorResponse(error));
  }

  experienceReportClicked(experienceReport: ExperienceReport): void {
    this.$router.push({
      name: 'consume-experience-reports-experience-report-details',
      params: { experienceReportId: experienceReport.experienceReportId },
    });
  }

}
</script>
<template>
<v-container class="experience-report-list">
  <v-row class="mt-md-4">
    <v-col cols="12">

      <v-progress-linear v-if="isLoading" color="primary" indeterminate class="mb-4" />

      <p v-if="areNoExperienceReportsAvailable">
        Noch keine Erfahrungsberichte verfügbar.
      </p>

    </v-col>

    <v-col
      cols="12"
      md="6"
      lg="4"
      v-for="(experienceReport, index) in store.experienceReports"
      :key="experienceReport.experienceReportId"
    >

      <v-row
        class="no-gutters experience-report"
        :class="{ 'mb-4': index !== store.experienceReports.length - 1 }"
        @click="experienceReportClicked(experienceReport)"
      >
        <v-col cols="4">
          <v-card outlined class="overflow-hidden">
            <div class="blur-hash-image-container">
              <blur-hash-image
                :hash="experienceReport.image.hash"
                :src="experienceReport.image.url"
                :alt="experienceReport.name"
                :width="20"
                :height="Math.floor(20 / experienceReport.image.aspectRatio)"
              />
            </div>
          </v-card>
        </v-col>
        <v-col cols="8">
          <div class="experience-report-info">
            <strong class="experience-report-title">{{ experienceReport.name }}</strong>
            <span class="d-block">{{ experienceReport.job }}</span>
            <span class="d-block action-link">Lesen</span>
          </div>
        </v-col>
      </v-row>

    </v-col>

  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.experience-report-list
  font-size: 0.875rem

  .experience-report
    cursor: pointer

    .experience-report-info
      padding: 0 0 0 0.75rem
      line-height: 1.15

      strong.experience-report-title
        font-weight: 700
        font-family: Montserrat, sans-serif
        color: var(--color-grey-2)
        display: block
        margin-top: 0.25rem
        margin-bottom: 0.25rem

      span::v-deep
        font-size: 0.875rem

        strong
          font-weight: 600

  .action-link
    color: var(--color-brand)
    font-weight: 600
    margin-top: 0.25rem
</style>
