import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modules"},[_c(VCardText,{staticClass:"pb-0"},[_c('h2',[_vm._v("Blog")]),(_vm.store.featureExamples && _vm.store.featureExamples.blogArticles.length > 0)?_c(VRow,{staticClass:"blog-article-list mt-4 mb-4 mb-md-2"},_vm._l((_vm.store.featureExamples.blogArticles),function(blogArticle,index){return _c(VCol,{key:blogArticle.blogArticleId,attrs:{"cols":"12","md":"6","lg":"4"}},[_c(VRow,{staticClass:"no-gutters blog-article",class:{ 'mb-4': index !== _vm.store.featureExamples.blogArticles.length - 1 }},[_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{staticClass:"overflow-hidden",attrs:{"outlined":""}},[_c('div',{staticClass:"blur-hash-image-container"},[_c('blur-hash-image',{attrs:{"hash":blogArticle.image.hash,"src":blogArticle.image.url,"alt":blogArticle.title,"width":20,"height":Math.floor(20 / blogArticle.image.aspectRatio)}}),(blogArticle.isNew)?_c('span',{staticClass:"new-sign"},[_vm._v(" Neu ")]):_vm._e()],1)])],1),_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"blog-article-info"},[_c('strong',{staticClass:"blog-article-title"},[_vm._v(_vm._s(blogArticle.title))]),_c('span',{staticClass:"d-block action-link"},[_vm._v("Lesen")])])])],1)],1)}),1):_vm._e(),_c('p',[_vm._v(" Hier wirst du über Neuigkeiten im Themenfeld Ernährung informiert und bleibst so auf dem Laufenden. In den Blog-Artikeln gehen wir regelmäßig auf neue Trends ein und machen dich auf spannende Themen aufmerksam, mit denen du dich vielleicht noch nicht beschäftigt hast. ")])],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{on:{"click":_vm.skipClicked}},[_vm._v(" Einführung überspringen ")]),_c('a-trigger-primary-process-button',{on:{"click":_vm.submitClicked}},[_vm._v(" Weiter ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }