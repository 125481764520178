<script lang="ts">
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import { Form, FormFunctions, FormControl, getFormValues } from './index';

@Component
export default class FormComponent extends Vue {

  @Prop({ type: Object, default: null })
  readonly form!: Form<any> | null;

  @Provide('formFunctions')
  readonly formFunctions: FormFunctions = {
      inputChanged: this.inputChanged,
      publishValidationResult: this.publishValidationResult,
    };

  validationResults: Record<string, boolean> = {};

  inputChanged(formControl: FormControl<any>): void {
    if (this.form === null) {
      throw new Error('Form is null');
    }

    if (formControl.transformValuesOnInput !== undefined) {
      const transformedValues = formControl.transformValuesOnInput(getFormValues(this.form));
      for (const [key, value] of Object.entries(transformedValues)) {
        this.form.controls[key].value = value;
      }
    }

    if (formControl.validateFormControlsOnInput !== undefined) {
      formControl.validateFormControlsOnInput.forEach((controlName) => {
        const componentReferences = this.form!.controls[controlName].componentReferences;
        if (componentReferences === undefined) {
          throw new Error(`FormControl ${controlName} has not been mounted`);
        }

        const isInternalValueValid = componentReferences.validateInternalValue
          ? componentReferences.validateInternalValue()
          : true;
        const isFormValueValid = componentReferences.validateFormValue();

        this.publishValidationResult(
          componentReferences.formControlId,
          isInternalValueValid && isFormValueValid
        );
      });
    }

    if (formControl.afterTransformationAndValidation !== undefined) {
      formControl.afterTransformationAndValidation(formControl.value);
    }
  }

  publishValidationResult(id: string, wasValidationSuccessful: boolean): void {
    this.validationResults[id] = wasValidationSuccessful;

    const isValid = !Object
      .values(this.validationResults)
      .some((result) => !result);

    this.form!.isValid = isValid;

    // For some reason this also has to be set on the next tick as it's otherwise not pushed to the watching components
    this.$nextTick(() => {
      this.form!.isValid = isValid;
    });
  }

  submitted(): void {
    this.form!.submitted();
  }

}
</script>
<template>
<v-form
  v-if="form"
  :value="form.isValid"
  @submit.prevent="submitted"
>
  <slot />
</v-form>
</template>
<style lang="sass" scoped>
::v-deep .form-control-input
  margin-bottom: 1rem

  .v-text-field,
  .v-text-field.v-text-field--enclosed
    margin-bottom: 0
</style>
