import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"md":"12","lg":"9"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[_c('p',[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('a-trigger-primary-process-button',{staticClass:"mt-4",attrs:{"to":{ name: 'authentication-login' }}},[_vm._v(" Zur Anmeldung ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }