import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modules"},[_c(VCardText,{staticClass:"pb-0"},[_c('h2',[_vm._v("Dokumente")]),_c('p',[_vm._v(" Innerhalb der Module bieten wir dir vertiefende Dokumente wie Arbeitsblätter, Anleitungen usw. an. Du kannst diese Dokumente herunterladen oder direkt an deine E-Mail Adresse schicken lassen, damit du sie von einem PC aus ausdrucken kannst. ")]),(_vm.store.featureExamples && _vm.store.featureExamples.documents.length > 0)?_c(VRow,{staticClass:"document-list mt-4"},_vm._l((_vm.store.featureExamples.documents),function(document){return _c(VCol,{key:document.documentId,staticClass:"document mb-6",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('strong',{staticClass:"document-title d-block"},[_vm._v(_vm._s(document.title))]),_c('span',{staticClass:"short-description d-block"},[_vm._v(_vm._s(document.shortDescription))]),_c(VRow,{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"mr-1"},[_c('a-execute-secondary-action-button',{attrs:{"is-small":"","is-always-shown-in-block-mode":""}},[_vm._v(" Herunterladen ")])],1),_c(VCol,{staticClass:"ml-1"},[_c('a-execute-secondary-action-button',{attrs:{"is-small":"","is-always-shown-in-block-mode":""}},[_vm._v(" Als E-Mail ")])],1)],1)],1)}),1):_vm._e(),_c('p',[_vm._v(" Über das Menü findest du den Bereich “Dokumente”, in dem du auf alle Dokumente gesammelt zugreifen kannst, unabhängig davon, ob du das zugehörige Modul bereits durchgearbeitet hast oder noch nicht. ")])],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{on:{"click":_vm.skipClicked}},[_vm._v(" Einführung überspringen ")]),_c('a-trigger-primary-process-button',{on:{"click":_vm.submitClicked}},[_vm._v(" Weiter ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }