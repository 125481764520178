<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsRule, maxLengthRule, minNumberRule } from '@/components/form';
import { useManageModulesStore } from '../store';
import { CreateModuleCommand } from '../types';

interface Controls extends FormControls {
  number: FormControl<number>;
  title: FormControl<string>;
  image: FormControl<Blob>;
  isBehindPaywall: FormControl<boolean>;
  isEnabled: FormControl<boolean>;
}

@Component
export default class CreateModuleDialog extends Vue {

  readonly store = useManageModulesStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        number: {
          label: 'Nummer',
          value: 0,
          isRequired: true,
          rules: [
            maxDecimalsRule(0),
            minNumberRule(0),
          ],
        },
        title: {
          label: 'Bezeichnung',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        image: {
          label: 'Bild',
          value: null,
        },
        isBehindPaywall: {
          label: 'Soll das Modul hinter der Paywall sein?',
          value: false,
          rules: [],
        },
        isEnabled: {
          label: 'Soll das Modul den Endbenutzern angezeigt werden?',
          value: true,
          rules: [],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateModuleCommand = {
      body: {
        number: formValues.number!,
        title: formValues.title!,
        isBehindPaywall: formValues.isBehindPaywall!,
        isEnabled: formValues.isEnabled!,
      },
      files: {
        imageFile: formValues.image,
      },
    };

    this.store.createModule(command)
      .then(() => showSuccessMessage('Modul wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateModuleProcessing"
>
  <template #activator="{ on }">
    <div class="d-inline-block" v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Modul erstellen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <a-number-form-control
              :form-control="form.controls.number"
              is-autofocused
            />
          </v-col>
          <v-col cols="10">
            <a-text-form-control :form-control="form.controls.title" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-image-cropper-form-control :form-control="form.controls.image" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-checkbox-form-control :form-control="form.controls.isBehindPaywall" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-checkbox-form-control :form-control="form.controls.isEnabled" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateModuleProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateModuleProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Erstellen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
