import { RouteConfig } from 'vue-router';
import Imprint from './components/imprint.vue';
import DataProtection from './components/data-protection.vue';
import TermsOfUse from './components/terms-of-use.vue';

export const legalRoutes: RouteConfig[] = [
  {
    name: 'legal-terms-of-use',
    path: '/nutzungsbedingungen',
    component: TermsOfUse,
    meta: {
      alwaysAccessible: true,
      title: 'Nutzungsbedingungen',
    },
  },
  {
    name: 'legal-data-protection',
    path: '/datenschutz',
    component: DataProtection,
    meta: {
      alwaysAccessible: true,
      title: 'Datenschutz',
    },
  },
  {
    name: 'legal-imprint',
    path: '/impressum',
    component: Imprint,
    meta: {
      alwaysAccessible: true,
      title: 'Impressum',
    },
  },
];
