<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import dayjs, { Dayjs } from 'dayjs';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { formatDate } from '@/filters/dates';
import { NutritionDiaryTipType } from '@/types';
import { nutritionDiaryTipTypeTranslations } from '@/helpers/data';
import CreateNutritionDiaryEntryDialog from './create-nutrition-diary-entry-dialog.vue';
import ShowDetailsDialog from './show-details-dialog.vue';
import NutritionDiaryIntroduction from './nutrition-diary-introduction.vue';
import ShowNewTipDialog from './show-new-tip-dialog.vue';
import { useNutritionDiaryStore } from '../store';
import { isNutritionDiaryItemEntry, isNutritionDiaryItemTip, isTypeDay, isTypeGap, NutritionDiaryItem, NutritionDiaryTip } from '../types';

@Component({
  methods: { isNutritionDiaryItemTip, isNutritionDiaryItemEntry },
  components: {
    CreateNutritionDiaryEntryDialog,
    ShowDetailsDialog,
    NutritionDiaryIntroduction,
    ShowNewTipDialog,
  },
})
export default class NutritionDiaryTimeline extends Vue {

  readonly store = useNutritionDiaryStore();

  @Ref('showNewTipDialog')
  readonly showNewTipDialog!: ShowNewTipDialog;

  readonly isTypeDay = isTypeDay;
  readonly isTypeGap = isTypeGap;

  get isLoading(): boolean {
    return this.store.isGetNutritionDiaryTimelineProcessing;
  }

  get isEmptyStateVisible(): boolean {
    return !this.isLoading
      && this.store.timelineEntries.length === 0;
  }

  get isTimelineVisible(): boolean {
    return this.store.timelineEntries.length > 0;
  }

  mounted(): void {
    this.store.getNutritionDiaryTimeline()
      .catch((error) => showErrorResponse(error));
  }

  formattedDate(date: Dayjs): string {
    const now = dayjs();
    if (date.isSame(now, 'day')) {
      return 'Heute';
    }

    if (date.isSame(now.subtract(1, 'day'), 'day')) {
      return 'Gestern';
    }

    if (date.isSame(now.subtract(2, 'day'), 'day')) {
      return 'Vorgestern';
    }

    return formatDate(date);
  }

  emptyDescription(date: Dayjs): string {
    const now = dayjs();
    if (date.isSame(now, 'day')) {
      return 'Heute noch keine Einträge.';
    }

    if (date.isSame(now.subtract(1, 'day'), 'day')) {
      return 'Gestern wurde kein Eintrag erstellt.';
    }

    if (date.isSame(now.subtract(2, 'day'), 'day')) {
      return 'Vorgestern wurde kein Eintrag erstellt.';
    }

    return 'Kein Eintrag an diesen Tag.';
  }

  itemKey(item: NutritionDiaryItem): string {
    return isNutritionDiaryItemEntry(item)
      ? item.content.nutritionDiaryEntryId
      : (item.content as NutritionDiaryTip).nutritionDiaryTipId;
  }

  translateType(type: NutritionDiaryTipType): string {
    return nutritionDiaryTipTypeTranslations[type];
  }

  tipAdded(): void {
    const newTip = this.store.timelineEntries[0].items[0].content as NutritionDiaryTip;
    // @ts-ignore
    this.showNewTipDialog.showTip(newTip);
  }

}
</script>
<template>
<v-container class="diary-slider">

  <create-nutrition-diary-entry-dialog @tip-added="tipAdded">
    <a-trigger-primary-process-button>
      Neuer Eintrag
    </a-trigger-primary-process-button>
  </create-nutrition-diary-entry-dialog>

  <v-progress-linear
    v-if="isLoading"
    color="primary"
    indeterminate
    class="mt-4"
  />

  <nutrition-diary-introduction
    v-if="isEmptyStateVisible"
    class="mt-4"
  />

  <v-timeline
    dense
    align-top
    class="mt-4"
    v-if="isTimelineVisible"
  >
    <v-timeline-item
      small
      v-for="(timelineEntry, index) in store.timelineEntries"
      :key="`card-${index}`"
    >
      <div class="timeline-entry">
        <template v-if="isTypeDay(timelineEntry)">
          <strong class="date">{{ formattedDate(timelineEntry.date) }}</strong>
          <p v-if="timelineEntry.items.length === 0" class="mb-0">
            <span>{{ emptyDescription(timelineEntry.date) }}</span>
          </p>
          <v-row>
            <v-col
              v-for="item in timelineEntry.items"
              cols="12"
              :md="isNutritionDiaryItemEntry(item) ? 6 : 12"
              :lg="isNutritionDiaryItemEntry(item) ? 4: 12"
              :key="itemKey(item)"
              class="mt-2"
            >
              <show-details-dialog
                v-if="isNutritionDiaryItemEntry(item)"
                :entry="item.content"
              >
                <v-row class="entry no-gutters">
                  <v-col cols="4">
                    <v-card outlined class="overflow-hidden">
                      <div class="image-container">
                        <template v-if="item.content.image">
                          <blur-hash-image
                            :hash="item.content.image.hash"
                            :src="item.content.image.url"
                            :alt="item.content.consumedAt | formatTime"
                            :width="20"
                            :height="Math.floor(20 / item.content.image.aspectRatio)"
                          />
                        </template>
                        <div class="image-placeholder" v-else>
                          <font-awesome-icon :icon="['fa-thin', 'salad']" />
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="8">
                    <div class="details">
                      <strong class="d-block time">{{ item.content.consumedAt | formatTime }} Uhr</strong>
                      <span class="description">{{ item.content.description }}</span>
                    </div>
                  </v-col>
                </v-row>
              </show-details-dialog>
              <div v-if="isNutritionDiaryItemTip(item)">
                <a-info-alert class="mb-2">
                  <strong>{{ translateType(item.content.type) }}:</strong> {{ item.content.description }}
                </a-info-alert>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-if="isTypeGap(timelineEntry)">
          <strong class="date">{{ formattedDate(timelineEntry.from) }} bis {{ formattedDate(timelineEntry.to) }}</strong>
          <p class="mb-0">
            <span>An diesen Tagen wurden keine Einträge erstellt.</span>
          </p>
        </template>

      </div>
    </v-timeline-item>
  </v-timeline>

  <show-new-tip-dialog ref="showNewTipDialog" />

</v-container>
</template>
<style lang="sass" scoped>
.diary-slider
  font-size: 0.875rem

  .timeline-entry
    margin-top: 2px

    strong.date
      font-weight: 700
      font-family: Montserrat, sans-serif
      color: var(--color-grey-2)

    strong.time
      font-weight: 600
      color: var(--color-grey-2)

.v-card.no-entries
  padding: 1rem

.entry
  display: flex
  cursor: pointer

  .image-container
    flex-basis: 120px
    flex-shrink: 0
    overflow: hidden
    position: relative
    background: var(--color-grey-9)

    // ::before and ::after are to add aspect ratio
    &::before
      content: ""
      width: 1px
      margin-left: -1px
      float: left
      height: 0
      padding-top: 130px / 200px * 100%

    &::after
      content: ""
      display: table
      clear: both

    .image-placeholder
      display: flex
      position: absolute
      left: 0
      right: 0
      top: 0
      bottom: 0
      margin: auto
      align-items: center
      justify-content: center
      height: 100%
      padding: 0.75rem 0
      background: var(--color-grey-9)

      svg
        display: block
        font-size: 3rem
        color: var(--color-grey-8)

  ::v-deep .details
    padding: 0.25rem 0.25rem 0 0.75rem
    flex-grow: 1

    .description
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      color: var(--color-grey-2)
      text-overflow: ellipsis
      overflow: hidden
      max-height: 48px
</style>
