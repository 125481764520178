<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useManageSponsorsStore } from '../store';

@Component
export default class ManageSponsors extends Vue {

  readonly store = useManageSponsorsStore();

  mounted(): void {
    this.store.getSponsors()
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<router-view />
</template>
