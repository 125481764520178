import Vue from 'vue';
import dayjs, { Dayjs, isDayjs } from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from '@/helpers/data';

Vue.mixin({
  methods: {
    // time will be something like 12:00:00 but must be shown as 12:00
    formatTimePickerValue: (time: string) => {
      if (time === '') {
        return '';
      }

      return time.substr(0, 5);
    },
  },
});

const weekdayTranslation : { [key: number]: string } = {
  1: 'Montag',
  2: 'Dienstag',
  3: 'Mittwoch',
  4: 'Donnerstag',
  5: 'Freitag',
  6: 'Samstag',
  7: 'Sonntag',
};

export function formatDate(date: Dayjs | string): string {
  const dateToFormat = isDayjs(date) ? date : dayjs(date);
  return dateToFormat.format(DATE_FORMAT);
}

export function formatTime(time: Dayjs | string): string {
  const timeToFormat = isDayjs(time) ? time : dayjs(time);
  return timeToFormat.format(TIME_FORMAT);
}

export function translatedWeekday(date: Dayjs | string): string {
  const dateToFormat = isDayjs(date) ? date : dayjs(date);
  return weekdayTranslation[dateToFormat.isoWeekday()];
}

export function formatDateTime(datetime: Dayjs | string): string {
  const dateToFormat = isDayjs(datetime) ? datetime : dayjs(datetime);
  return `am ${dateToFormat.format(DATE_FORMAT)} um ${dateToFormat.format(TIME_FORMAT)} Uhr`;
}

Vue.filter('formatDate', formatDate);
Vue.filter('formatTime', formatTime);
Vue.filter('formatDateTime', formatDateTime);
Vue.filter('translatedWeekday', translatedWeekday);
