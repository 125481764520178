<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useConsumeRecipesStore } from '../store';
import { Recipe } from '../types';

@Component
export default class RecipeList extends Vue {

  readonly store = useConsumeRecipesStore();

  get isLoading(): boolean {
    return this.store.isGetRecipesProcessing;
  }

  get areNoRecipesAvailable(): boolean {
    return !this.isLoading
      && this.store.recipes.length === 0;
  }

  mounted(): void {
    this.store.getRecipes()
      .catch((error) => showErrorResponse(error));
  }

  recipeClicked(recipe: Recipe): void {
    this.$router.push({
      name: 'consume-recipes-recipe-details',
      params: { recipeId: recipe.recipeId },
    });
  }

}
</script>
<template>
<v-container class="recipe-list">
  <v-row class="mt-md-4">
    <v-col cols="12">

      <v-progress-linear v-if="isLoading" color="primary" indeterminate class="mb-4" />

      <p v-if="areNoRecipesAvailable">
        Noch keine Rezepte verfügbar.
      </p>

    </v-col>

    <v-col
      cols="12"
      md="6"
      lg="4"
      v-for="(recipe, index) in store.recipes"
      :key="recipe.recipeId"
    >

      <v-row
        class="no-gutters recipe"
        :class="{ 'mb-4': index !== store.recipes.length - 1 }"
        @click="recipeClicked(recipe)"
      >
        <v-col cols="4">
          <v-card outlined class="overflow-hidden">
            <div class="blur-hash-image-container">
              <blur-hash-image
                :hash="recipe.heroImage.hash"
                :src="recipe.heroImage.url"
                :alt="recipe.name"
                :width="20"
                :height="Math.floor(20 / recipe.heroImage.aspectRatio)"
              />
              <span class="total-time">
                {{ recipe.totalTimeInMinutes }}min
              </span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="8">
          <div class="recipe-info">
            <strong class="recipe-title">{{ recipe.name }}</strong>
            <span v-if="recipe.subTitle" class="d-block">{{ recipe.subTitle }}</span>
            <span class="d-block action-link">Rezept anzeigen</span>
          </div>
        </v-col>
      </v-row>

    </v-col>

  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.recipe-list
  font-size: 0.875rem

  .recipe
    cursor: pointer

    .blur-hash-image-container
      position: relative

      .total-time
        position: absolute
        bottom: 0
        right: 0
        color: var(--color-white)
        background: var(--color-brand)
        padding: 2px 0.5rem
        font-weight: 600
        border-top-left-radius: 0.25rem

    .recipe-info
      padding: 0 0 0 0.75rem
      line-height: 1.15

      strong.recipe-title
        font-weight: 700
        font-family: Montserrat, sans-serif
        color: var(--color-grey-2)
        display: block
        margin-top: 0.25rem
        margin-bottom: 0.25rem

      span::v-deep
        font-size: 0.875rem

        strong
          font-weight: 600

  .action-link
    color: var(--color-brand)
    font-weight: 600
    margin-top: 0.25rem
</style>
