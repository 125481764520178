<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import ExtensionDocument from '@tiptap/extension-document';
import ExtensionHistory from '@tiptap/extension-history';
import ExtensionParagraph from '@tiptap/extension-paragraph';
import ExtensionText from '@tiptap/extension-text';
import ExtensionListItem from '@tiptap/extension-list-item';
import ExtensionHardBreak from '@tiptap/extension-hard-break';
import ExtensionBold from '@tiptap/extension-bold';
import ExtensionItalic from '@tiptap/extension-italic';
import ExtensionLink from '@tiptap/extension-link';
import ExtensionBulletList from '@tiptap/extension-bullet-list';
import ExtensionOrderedList from '@tiptap/extension-ordered-list';
import ExtensionHeading from '@tiptap/extension-heading';
import ExtensionTable from '@tiptap/extension-table';
import ExtensionTableRow from '@tiptap/extension-table-row';
import ExtensionTableHeader from '@tiptap/extension-table-header';
import ExtensionTableCell from '@tiptap/extension-table-cell';
import ExtensionTextStyle from '@tiptap/extension-text-style';
import ExtensionColor from '@tiptap/extension-color';
import { Editor, EditorContent } from '@tiptap/vue-2';
import { showError } from '@/application/common/snackbar/service';
import isURL, { IsURLOptions } from 'validator/es/lib/isURL';
import { watch } from 'vue';

const redHexCode = '#7d1820';

export type ExtensionOption =
  | 'bold'
  | 'italic'
  | 'link'
  | 'color'
  | 'bullet-list'
  | 'ordered-list'
  | 'h2'
  | 'h3'
  | 'table';

function allExtensionOptions(): ExtensionOption[] {
  return [
    'bold',
    'italic',
    'link',
    'color',
    'bullet-list',
    'ordered-list',
    'h2',
    'h3',
    'table',
  ];
}

@Component({
  components: {
    EditorContent,
  },
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class HtmlFormControl extends Vue implements FormControlComponent<string> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<string>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: String, default: null })
  readonly dataCy!: string | null;

  @Prop({ type: Number, default: 3 })
  readonly rows!: number;

  @Prop({ type: Array, default: () => allExtensionOptions() })
  readonly extensions!: ExtensionOption[];

  readonly formControlId = createFormControlId();

  editor: Editor | null = null;

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  get isH2Active(): boolean {
    return this.editor!.isActive('heading', { level: 2 });
  }

  get isH3Active(): boolean {
    return this.editor!.isActive('heading', { level: 3 });
  }

  get isBoldActive(): boolean {
    return this.editor!.isActive('bold');
  }

  get isItalicActive(): boolean {
    return this.editor!.isActive('italic');
  }

  get isBulletListActive(): boolean {
    return this.editor!.isActive('bulletList');
  }

  get isOrderedListActive(): boolean {
    return this.editor!.isActive('orderedList');
  }

  get isLinkActive(): boolean {
    return this.editor!.isActive('link');
  }

  get isUnlinkDisabled(): boolean {
    return !this.editor!.isActive('link');
  }

  get toggleTableHeaderTooltip(): string {
    return this.editor!.isActive('tableHeader')
      ? 'Kopfzeile deaktivieren'
      : 'Kopfzeile aktivieren';
  }

  get isUnsetColorDisabled(): boolean {
    return !this.editor!.isActive('textStyle', { color: redHexCode });
  }

  mounted(): void {
    const enabledExtensions: any[] = [
      ExtensionDocument,
      ExtensionHistory,
      ExtensionParagraph,
      ExtensionText,
      ExtensionListItem,
      ExtensionHardBreak,
    ];

    if (this.extensions.includes('bold')) {
      enabledExtensions.push(ExtensionBold);
    }

    if (this.extensions.includes('italic')) {
      enabledExtensions.push(ExtensionItalic);
    }

    if (this.extensions.includes('link')) {
      enabledExtensions.push(ExtensionLink.configure({
        openOnClick: false,
      }));
    }

    if (this.extensions.includes('bullet-list')) {
      enabledExtensions.push(ExtensionBulletList);
    }

    if (this.extensions.includes('ordered-list')) {
      enabledExtensions.push(ExtensionOrderedList);
    }

    if (this.extensions.includes('h2') || this.extensions.includes('h3')) {
      enabledExtensions.push(ExtensionHeading.configure({
        levels: [2, 3],
      }));
    }

    if (this.extensions.includes('table')) {
      enabledExtensions.push(ExtensionTable.configure({
        resizable: false,
      }));
      enabledExtensions.push(ExtensionTableRow);
      enabledExtensions.push(ExtensionTableHeader);
      enabledExtensions.push(ExtensionTableCell);
    }

    if (this.extensions.includes('color')) {
      enabledExtensions.push(ExtensionTextStyle);
      enabledExtensions.push(ExtensionColor.configure({
        types: ['textStyle'],
      }));
    }

    this.editor = new Editor({
      content: this.internalValue,
      extensions: enabledExtensions,
      enablePasteRules: false,
      onBlur: () => this.blurred(),
      onFocus: () => this.focused(),
      onUpdate: () => {
        this.internalValue = this.editor!.getHTML();
        internalValuesChanged(this);
      },
    });

    watch(() => this.internalValue, (internalValue: string) => {
      if (!this.editor || this.editor.getHTML() === internalValue) {
        return;
      }

      this.editor.commands.setContent(internalValue, false);
    });

    mountFormControl(this);

    if (this.isAutofocused
      && this.$vuetify.breakpoint.mdAndUp
    ) {
      this.editor.commands.focus();
    }
  }

  beforeDestroy(): void {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  toggleH2(): void {
    this.editor!.commands.toggleHeading({ level: 2 });
  }

  toggleH3(): void {
    this.editor!.commands.toggleHeading({ level: 3 });
  }

  toggleBold(): void {
    this.editor!.chain().focus().toggleBold().run();
  }

  toggleItalic(): void {
    this.editor!.chain().focus().toggleItalic().run();
  }

  toggleBulletList(): void {
    this.editor!.chain().focus().toggleBulletList().run();
  }

  toggleOrderedList(): void {
    this.editor!.chain().focus().toggleOrderedList().run();
  }

  addTable(): void {
    this.editor!.chain().focus().insertTable({ rows: 2, cols: 3, withHeaderRow: true }).run();
  }

  toggleTableHeader(): void {
    this.editor!.chain().focus().toggleHeaderRow().run();
  }

  addTableColumn(): void {
    this.editor!.chain().focus().addColumnAfter().run();
  }

  deleteTableColumn(): void {
    this.editor!.chain().focus().deleteColumn().run();
  }

  deleteTable(): void {
    this.editor!.commands.deleteTable();
  }

  colorRed(): void {
    this.editor!.chain().focus().setColor(redHexCode).run();
  }

  unsetColor(): void {
    this.editor!.chain().focus().unsetColor().run();
  }

  setLink() {
    const previousUrl = this.editor!.getAttributes('link').href;
    // eslint-disable-next-line no-alert
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // Empty link
    if (url === '') {
      this.editor!
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run();

      return;
    }

    if (!this.isValidHttpUrl(url)) {
      showError({ message: 'Der Link ist leider nicht valide.' });
      return;
    }

    // Update link
    this.editor!
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run();
  }

  unsetLink(): void {
    this.editor!.chain().focus().unsetLink().run();
  }

  isValidHttpUrl(url: string): boolean {
    const options: IsURLOptions = {
      protocols: ['http', 'https'],
      require_protocol: true,
    };

    return isURL(url, options);
  }

  isExtensionEnabled(extension: ExtensionOption): boolean {
    return this.extensions.includes(extension);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ''
      : this.formControl.value.trim();
  }

  formValueFromInternalValues(): FormControlValue<string> {
    return this.internalValue.replace(/<\/?[^>]+(>|$)/g, '').trim().length > 0
      ? this.internalValue.trim()
      : null;
  }

}
</script>
<template>
<div class="form-control html-form-control" v-bind="$attrs">
  <fieldset class="a-field pt-2 pb-4">
    <legend>{{ labelWithRequiredIndicator(formControl) }}</legend>
    <div class="form-control-input">
      <div
        class="editor-frame"
        :class="{ 'has-errors': isFieldShownAsContainingAnError(isFocused, isTouched, messages), 'focused': isFocused }"
      >
        <div class="editor-actions" v-if="editor">

          <icon-button
            v-if="isExtensionEnabled('h2')"
            :icon="['fas', 'h2']"
            tooltip="Mittlere Überschrift"
            @click="toggleH2"
            @focus="focused"
            :class="{ 'is-active': isH2Active }"
          />

          <icon-button
            v-if="isExtensionEnabled('h3')"
            :icon="['fas', 'h3']"
            tooltip="Kleine Überschrift"
            @click="toggleH3"
            @focus="focused"
            class="mr-3"
            :class="{ 'is-active': isH3Active }"
          />

          <icon-button
            v-if="isExtensionEnabled('bold')"
            :icon="['fas', 'bold']"
            tooltip="Fett"
            @click="toggleBold"
            @focus="focused"
            :class="{ 'is-active': isBoldActive }"
          />

          <icon-button
            v-if="isExtensionEnabled('italic')"
            :icon="['fas', 'italic']"
            tooltip="Kursiv"
            @click="toggleItalic"
            @focus="focused"
            class="mr-3"
            :class="{ 'is-active': isItalicActive }"
          />

          <icon-button
            v-if="isExtensionEnabled('bullet-list')"
            :icon="['fas', 'list-ul']"
            tooltip="Ungeordnete Liste"
            @click="toggleBulletList"
            @focus="focused"
            :class="{ 'is-active': isBulletListActive }"
          />

          <icon-button
            v-if="isExtensionEnabled('ordered-list')"
            :icon="['fas', 'list-ol']"
            tooltip="Geordnete Liste"
            @click="toggleOrderedList"
            @focus="focused"
            class="mr-3"
            :class="{ 'is-active': isOrderedListActive }"
          />

          <icon-button
            v-if="isExtensionEnabled('link')"
            :icon="['fas', 'link']"
            tooltip="Verknüpfung erstellen"
            @click="setLink"
            @focus="focused"
            :class="{ 'is-active': isLinkActive }"
          />

          <icon-button
            v-if="isExtensionEnabled('link')"
            :icon="['fas', 'unlink']"
            tooltip="Verknüpfung auflösen"
            disabled-tooltip="Keine Verknüpfung"
            @click="unsetLink"
            @focus="focused"
            class="mr-3"
            :disabled="isUnlinkDisabled"
          />

          <icon-button
            v-if="isExtensionEnabled('table')"
            :icon="['fas', 'table']"
            tooltip="Tabelle einfügen"
            @click="addTable"
            @focus="focused"
            :disabled="editor.isActive('table')"
          />

          <icon-button
            v-if="isExtensionEnabled('table')"
            :icon="['fas', 'heading']"
            :tooltip="toggleTableHeaderTooltip"
            @click="toggleTableHeader"
            @focus="focused"
            :class="{ 'is-active': editor.isActive('tableHeader') }"
            :disabled="!editor.isActive('table')"
          />

          <icon-button
            v-if="isExtensionEnabled('table')"
            :icon="['fas', 'layer-plus']"
            tooltip="Spalte einfügen"
            @click="addTableColumn"
            @focus="focused"
            :disabled="!editor.isActive('table')"
          />

          <icon-button
            v-if="isExtensionEnabled('table')"
            :icon="['fas', 'layer-minus']"
            tooltip="Spalte löschen"
            @click="deleteTableColumn"
            @focus="focused"
            :disabled="!editor.isActive('table')"
          />

          <icon-button
            v-if="isExtensionEnabled('table')"
            :icon="['fas', 'trash']"
            tooltip="Tabelle löschen"
            @click="deleteTable"
            @focus="focused"
            class="mr-3"
            :disabled="!editor.isActive('table')"
          />

          <icon-button
            v-if="isExtensionEnabled('color')"
            :icon="['fas', 'tint']"
            tooltip="Rot einfärben"
            @click="colorRed"
            @focus="focused"
          />

          <icon-button
            v-if="isExtensionEnabled('color')"
            :icon="['fas', 'tint-slash']"
            tooltip="Farbe entfernen"
            @click="unsetColor"
            @focus="focused"
            :disabled="isUnsetColorDisabled"
          />

        </div>

        <editor-content :editor="editor" class="editor-content" />
      </div>
    </div>
  </fieldset>
  <a-form-control-messages
    :messages="messages"
    :is-focussed="isFocused"
    :is-touched="isTouched"
  />
</div>
</template>
<style lang="sass" scoped>
.editor-frame
  border: 1px solid var(--color-grey-6)
  padding: 1px
  border-radius: 0.25rem

  &.focused
    border-color: var(--color-green-5) !important
    border-width: 2px
    padding: 0

  &.has-errors
    border-color: var(--color-red-5) !important
    border-width: 2px
    padding: 0

.editor-actions
  //background-color: var(--color-grey-9)
  border-bottom: 1px solid var(--color-grey-6)

  .icon-button
    ::v-deep
      .v-btn
        padding: 0
        min-width: 40px
        border-radius: 0

    &.is-active
      ::v-deep
        .v-btn
          color: var(--color-brand) !important
          caret-color: var(--color-brand) !important
          background: var(--color-grey-9)

.editor-content
  padding: 4px

  ::v-deep
    .ProseMirror
      outline: 0
      margin: 12px

      &.ProseMirror-focused
        outline: none

      h1
        margin-bottom: 20px

      h2
        margin-bottom: 16px

      a
        cursor: text

      ol, ul
        margin-bottom: 16px

      li
        p
          margin: 0

      table,
      th,
      td
        border-collapse: collapse
        border: 1px solid var(--color-grey-8)

        p
          margin: 0

      table
        width: 100%
        margin-bottom: 16px

        th
          background: var(--color-grey-9)
          font-weight: bold
          text-align: left
          padding: 0.25rem 0.5rem

        td
          padding: 0.25rem 0.5rem
</style>
