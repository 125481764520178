<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { DropzoneFile } from 'dropzone';
import DropzoneUpload from '@/components/dropzone-upload.vue';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { MimeTypes } from '@/helpers/data';

@Component({
  components: { DropzoneUpload },
  methods: { isNativeApplication, labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class FileFormControl extends Vue implements FormControlComponent<File> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<File>;

  @Prop({ type: Number, default: 10 })
  readonly maxFileSize!: number;

  @Prop({ type: String, default: 'Datei auswählen' })
  readonly emptyText!: string;

  @Prop({ type: String, default: 'Neue Datei auswählen' })
  readonly selectedText!: string;

  @Prop({ type: Array, default: [MimeTypes.PDF] })
  readonly acceptedMimeTypes!: string[];

  @Prop({ type: String, default: 'PDF Datei' })
  readonly allowedFormatText!: string;

  readonly formControlId = createFormControlId();

  get isFileSelected(): boolean {
    return this.internalValue !== null;
  }

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: File | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  onDropzoneFileUploaded(file: DropzoneFile) {
    this.internalValue = file;

    internalValuesChanged(this);
  }

  onDropzoneFileRemoved(): void {
    this.internalValue = null;

    internalValuesChanged(this);
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    // TODO: Add when value exists
    // this.internalValue = this.formControl.value === null
    //   ? ''
    //   : this.formControl.value.trim();
  }

  formValueFromInternalValues(): FormControlValue<File> {
    return this.internalValue;
  }

}
</script>
<template>
<div class="form-control file-form-control" v-bind="$attrs">
  <fieldset class="a-field pt-2 pb-4">
    <legend>{{ labelWithRequiredIndicator(formControl) }}</legend>
    <div class="form-control-input">
      <dropzone-upload
        :empty-text="emptyText"
        :selected-text="selectedText"
        :selected="isFileSelected"
        :max-file-size="10"
        :accepted-mime-types="acceptedMimeTypes"
        :allowed-format-text="allowedFormatText"
        @upload-file="onDropzoneFileUploaded"
        @remove-file="onDropzoneFileRemoved"
        v-bind="$attrs"
      />
    </div>
  </fieldset>

    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
</div>
</template>
