<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { AppType } from '@/types';
import { useConsumeModulesStore } from '../store';
import { ModuleListItem } from '../types';

@Component
export default class ModuleList extends Vue {

  readonly store = useConsumeModulesStore();
  readonly authenticationStore = useAuthenticationStore();

  get isLoading(): boolean {
    return this.store.isGetModulesProcessing;
  }

  get areNoModulesAvailable(): boolean {
    return !this.isLoading
      && this.store.moduleList.length === 0;
  }

  get isContentCertificationNoticeVisible(): boolean {
    return this.authenticationStore.appType === AppType.COURSE;
  }

  get isPurchaseCourseTriggerVisible(): boolean {
    return this.authenticationStore.appType === AppType.COURSE;
  }

  mounted(): void {
    this.store.getModules()
      .catch((error) => showErrorResponse(error));
  }

  progressDescription(module: ModuleListItem): string {
    if (module.isCompleted) {
      return 'Abgeschlossen';
    }

    if (!module.lock) {
      if (module.completionPercentage === 0) {
        return 'Bereit';
      }

      return `Zu ${module.completionPercentage}% abgeschlossen`;
    }

    if (module.lock.lockReason === 'time') {
      const unlockedAt = module.lock.unlockedAt!;

      if (unlockedAt!.isSame(dayjs().add(1, 'day'), 'day')) {
        return `Wird <strong>Morgen um ${unlockedAt.format('HH:mm')} Uhr</strong> freigeschalten`;
      }

      if (unlockedAt.isSame(dayjs(), 'day')) {
        return `Wird <strong>Heute um ${unlockedAt.format('HH:mm')} Uhr</strong> freigeschalten`;
      }

      return `Wird am <strong>${unlockedAt.format('DD.MM')} um ${unlockedAt.format('HH:mm')} Uhr</strong> freigeschalten`;
    }

    if (module.lock.lockReason === 'previous-not-completed') {
      return 'Vorheriges Modul nicht abgeschlossen';
    }

    return 'Kostenpflichtiges Modul';
  }

  isModuleActionAvailable(module: ModuleListItem): boolean {
    return !module.lock;
  }

  moduleAction(module: ModuleListItem): string {
    if (!module.lock && !module.isCompleted) {
      return module.completionPercentage === 0
        ? 'Starten'
        : 'Fortsetzen';
    }

    if (module.isCompleted) {
      return 'Zum Inhaltsverzeichnis';
    }

    throw new Error('Invalid action');
  }

  isModuleLocked(module: ModuleListItem): boolean {
    return !!module.lock;
  }

  isModuleCompleted(module: ModuleListItem): boolean {
    return !module.lock && module.isCompleted;
  }

  isModuleStart(module: ModuleListItem): boolean {
    return !module.lock && !module.isCompleted && module.completionPercentage === 0;
  }

  isModuleInProgress(module: ModuleListItem): boolean {
    return !module.lock && !module.isCompleted && module.completionPercentage !== 0;
  }

  moduleClicked(module: ModuleListItem): void {
    if (module.lock) {
      return;
    }

    this.$router.push({ name: 'consume-modules-consume-module', params: { id: module.moduleId } });
  }

}
</script>
<template>
<v-container class="module-list">
  <v-row class="mt-md-4">
    <v-col cols="12">

      <v-progress-linear v-if="isLoading" color="primary" indeterminate class="mb-4" />

      <p v-if="areNoModulesAvailable">
        Noch keine Module verfügbar.
      </p>

    </v-col>

    <v-col
      cols="12"
      md="6"
      lg="4"
      v-for="(module, index) in store.moduleList"
      :key="module.moduleId"
    >

      <v-row
        class="no-gutters module"
        :class="{ 'locked': isModuleLocked(module), 'completed': isModuleCompleted(module), 'in-progress': isModuleInProgress(module), 'start': isModuleStart(module), 'mb-4': index !== store.moduleList.length - 1 }"
        :disabled="!!module.lock"
        @click="moduleClicked(module)"
      >
        <v-col cols="4">
          <v-card outlined class="overflow-hidden">
            <blur-hash-image
              v-if="module.image"
              :hash="module.image.hash"
              :src="module.image.url"
              :alt="module.title"
              :width="20"
              :height="Math.floor(20 / module.image.aspectRatio)"
            />
          </v-card>
        </v-col>
        <v-col cols="8">
          <div class="module-info">
            <strong class="module-title">{{ module.title }}</strong>
            <span class="d-block" v-html="progressDescription(module)"></span>
            <span class="d-block action-link" v-if="isModuleActionAvailable(module)">{{ moduleAction(module) }}</span>
          </div>
        </v-col>
      </v-row>

    </v-col>

  </v-row>

  <v-row v-if="isContentCertificationNoticeVisible">
    <v-col md="12" lg="9">
      <p class="mb-0 mt-4">
        Alle Informationen in den Modulen wurden von der <strong>Zentralen Prüfstelle Prävention</strong> (ZPP) als Kooperationsgemeinschaft gesetzlicher Krankenkassen geprüft und zertifiziert.
      </p>
    </v-col>
  </v-row>

</v-container>
</template>
<style lang="sass" scoped>
.module-list
  font-size: 0.875rem

  .module
    &.start,
    &.in-progress,
    &.completed
      cursor: pointer

    &.locked::v-deep

      img
        opacity: 0.6

      .module-info
        opacity: 0.7

    .module-info
      padding: 0 0 0 0.75rem
      line-height: 1.15

      strong.module-title
        font-weight: 700
        font-family: Montserrat, sans-serif
        color: var(--color-grey-2)
        display: block
        margin-top: 0.25rem
        margin-bottom: 0.25rem

      span::v-deep
        font-size: 0.875rem

        strong
          font-weight: 600

  .action-link
    color: var(--color-brand)
    font-weight: 600
    margin-top: 0.25rem
</style>
