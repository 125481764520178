import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAppStore } from '@/application/common/app/store';

function validateAppVersion(serverVersion: string): void {
  useAppStore().validateAppVersion(serverVersion);
}

function validateAppVersionOnResponse(response: AxiosResponse): Promise<AxiosResponse> {
  validateAppVersion(response.headers['x-app-version']);

  return Promise.resolve(response);
}

function validateAppVersionOnError(error: AxiosError): Promise<AxiosError> {
  const isMaintenanceMode = error?.response?.status === 503;

  if (isMaintenanceMode) {
    return Promise.reject(error);
  }

  if (error?.response) {
    validateAppVersion(error.response.headers['x-app-version']);
  }

  return Promise.reject(error);
}

export default function initAppVersionInterceptor() {
  axios.interceptors.response.use(
    validateAppVersionOnResponse,
    validateAppVersionOnError
  );
}
