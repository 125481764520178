import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAdmin } from '@/helpers/default-parameter-helper';
import { getUsersForApp, grantUserPaywallAccess } from './service';
import { GetUsersForAppQuery, GrantUserPaywallAccessCommand, User } from './types';

interface AdminPaywallAccessState {
  currentAppId: string | null;
  usersForCurrentApp: User[];

  getUsersForAppStatus: ActionStatus;
  grantUserPaywallAccessStatus: ActionStatus;
}

function initialState(): AdminPaywallAccessState {
  return {
    currentAppId: null,
    usersForCurrentApp: [],

    getUsersForAppStatus: ActionStatus.None,
    grantUserPaywallAccessStatus: ActionStatus.None,
  };
}

export const useAdminPaywallAccessStore = defineStore('adminPaywallAccess', {
  state: (): AdminPaywallAccessState => initialState(),
  getters: {
    isGetUsersForAppProcessing: (state: AdminPaywallAccessState): boolean =>
      state.getUsersForAppStatus === ActionStatus.InProgress,
    isGrantUserPaywallAccessProcessing: (state: AdminPaywallAccessState): boolean =>
      state.grantUserPaywallAccessStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentApp(appId: string): Promise<void> {
      this.currentAppId = appId;

      return this.getUsersForCurrentApp();
    },

    // -- Queries

    getUsersForCurrentApp(): Promise<void> {
      const query: GetUsersForAppQuery = {
        appId: this.currentAppId!,
      };
      const { getUsersForAppStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUsersForAppStatus,
        () => getUsersForApp(attachAdmin(query))
          .then(async (users) => {
            this.usersForCurrentApp = users;
          })
      );
    },

    // -- Commands

    grantUserPaywallAccess(command: GrantUserPaywallAccessCommand): Promise<void> {
      const { grantUserPaywallAccessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        grantUserPaywallAccessStatus,
        () => grantUserPaywallAccess(attachAdmin(command))
          .then(() => this.getUsersForCurrentApp())
      );
    },

  },
});
