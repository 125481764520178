<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, mustEndWithPeriodRule } from '@/components/form';
import { ContentElementQuizAnswer } from '../types';

interface Controls extends FormControls {
  text: FormControl<string>;
  explanation: FormControl<string>;
}

@Component
export default class UpdatePossibleAnswerDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.largeNested;

  @Prop({ type: Object, required: true })
  readonly possibleAnswer!: ContentElementQuizAnswer;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        text: {
          label: 'Mögliche Antwort',
          value: this.possibleAnswer.text,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        explanation: {
          label: 'Erklärung',
          value: this.possibleAnswer.explanation,
          isRequired: true,
          rules: [
            mustEndWithPeriodRule(),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const contentElementQuizAnswer: ContentElementQuizAnswer = {
      contentElementQuizAnswerId: this.possibleAnswer.contentElementQuizAnswerId,
      text: formValues.text!,
      explanation: formValues.explanation!,
    };

    this.$emit('submitted', contentElementQuizAnswer);

    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
    this.$emit('closed');
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Mögliche Antwort anpassen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <a-text-form-control
              :form-control="form.controls.text"
              is-autofocused
            />
          </v-col>
          <v-col cols="12">
            <a-textarea-form-control :form-control="form.controls.explanation" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button @click="closeDialog" />
        <a-submit-primary-action-button :is-disabled="isSubmitDisabled">
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
