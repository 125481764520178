<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentElementQuizAnswerForPreview, ContentElementQuizForPreview } from '../types';

@Component
export default class QuizPreview extends Vue {

  @Prop()
  readonly contentElement!: ContentElementQuizForPreview;

  selectedAnswerId: string|null = null;

  get isDisabled(): boolean {
    return this.selectedAnswerId !== null;
  }

  selectAnswer(quizAnswer: ContentElementQuizAnswerForPreview): void {
    if (this.isDisabled) {
      return;
    }

    this.contentElement.isAnswered = true;
    this.selectedAnswerId = quizAnswer.contentElementQuizAnswerId;
  }

  getItemColor(quizAnswer: ContentElementQuizAnswerForPreview): string {
    if (this.selectedAnswerId === null) {
      return '';
    }

    return quizAnswer.isCorrectAnswer
      ? 'correct'
      : 'wrong';
  }

}
</script>
<template>
<div class="quiz">
  <span>{{ contentElement.question }}</span>
  <v-radio-group :value="selectedAnswerId">
    <div
      class="option"
      v-for="quizAnswer in contentElement.possibleAnswers"
      :key="quizAnswer.contentElementQuizAnswerId"
      :class="getItemColor(quizAnswer)"
      @click="selectAnswer(quizAnswer)"
    >
      <div class="option-button">
        <v-radio
          :value="quizAnswer.contentElementQuizAnswerId"
          :disabled="isDisabled"
        />
      </div>
      <div class="answer">
        {{ quizAnswer.text }}
        <v-expand-transition>
          <div class="mt-2" v-if="contentElement.isAnswered && selectedAnswerId === quizAnswer.contentElementQuizAnswerId">
            {{ quizAnswer.explanation }}
          </div>
        </v-expand-transition>
      </div>
    </div>
  </v-radio-group>
</div>
</template>
<style lang="sass" scoped>
.quiz
  background: var(--color-grey-9)
  padding: 1.25rem 1rem
  border: solid var(--color-grey-8)
  border-width: 1px 0
  color: var(--color-grey-3)

  .option
    display: flex

    .option-button
      flex-basis: 50px
      flex-shrink: 0
      flex-grow: 0
      padding-bottom: 1rem

    .answer
      flex-grow: 1
      padding-top: 1px
      padding-bottom: 1rem

    &:last-of-type
      .option-button,
      .answer
        padding-bottom: 0
  span
    ::v-deep
      p
        font-size: 1rem

  ::v-deep
    .v-input__slot
      margin-bottom: 0
    .v-messages
      display: none

    .v-radio.v-item--active
      .v-icon
        color: var(--color-brand)

  .reaction::v-deep
    p,
    ul,
    ol
      margin-bottom: 0.75rem

    p:last-child,
    ul:last-child,
    ol:last-child
      margin-bottom: 0

  ::v-deep
    .correct
      .answer
        color: var(--color-green-3) !important
      .v-icon
        color: var(--color-green-3) !important

    .wrong
      .answer
        color: var(--color-red-4) !important
      .v-icon
        color: var(--color-red-4) !important
</style>
