<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isUserGoalProgressWithAtLeastOneRating, UserGoalProgressWithAtLeastOneRating, UserProgress } from '../types';

@Component
export default class UserGoalTrends extends Vue {

  @Prop({ type: Object, required: true })
  readonly userProgress!: UserProgress;

  get userGoalProgressesWithAtLeastOneRating(): UserGoalProgressWithAtLeastOneRating[] {
    return this.userProgress.userGoalProgress
      .filter((userGoalProgress) => isUserGoalProgressWithAtLeastOneRating(userGoalProgress)) as UserGoalProgressWithAtLeastOneRating[];
  }

  direction(userGoalProgress: UserGoalProgressWithAtLeastOneRating): string {
    if (userGoalProgress.latestScore === undefined) {
      return 'equals';
    }
    if (userGoalProgress.latestScore === 10) {
      return 'top';
    }

    const difference = userGoalProgress.latestScore - userGoalProgress.firstScore;

    if (difference > 0) {
      return 'up';
    } else if (difference < 0) {
      return 'down';
    } else {
      return 'equals';
    }
  }

  icon(userGoalProgress: UserGoalProgressWithAtLeastOneRating): string {
    if (userGoalProgress.latestScore === undefined) {
      return 'location-dot';
    }

    if (userGoalProgress.latestScore === 10) {
      return 'party-horn';
    }

    const difference = userGoalProgress.latestScore - userGoalProgress.firstScore;

    if (difference > 0) {
      return 'arrow-trend-up';
    } else if (difference < 0) {
      return 'arrow-trend-down';
    } else {
      return 'arrow-rotate-back';
    }
  }

  scoreDifference(userGoalProgress: UserGoalProgressWithAtLeastOneRating): string {
    if (userGoalProgress.latestScore === undefined) {
      return '';
    }

    if (userGoalProgress.firstScore === userGoalProgress.latestScore) {
      return '';
    }

    if (userGoalProgress.latestScore === 10) {
      return '';
    }

    const difference = userGoalProgress.latestScore - userGoalProgress.firstScore;

    return difference > 0
      ? `+${difference.toString(10)}`
      : difference.toString(10);
  }

  description(userGoalProgress: UserGoalProgressWithAtLeastOneRating): string {
    if (userGoalProgress.latestScore === undefined) {
      return 'Du hast dir ein neues Ziel gesteckt, wir drücken dir die Daumen.';
    }
    if (userGoalProgress.latestScore === 10) {
      return 'Du hast alles raus geholt und dein Ziel vollumfänglich erreicht.';
    }

    const difference = userGoalProgress.firstScore < userGoalProgress.latestScore
      ? userGoalProgress.latestScore - userGoalProgress.firstScore
      : (userGoalProgress.latestScore - userGoalProgress.firstScore) * -1;

    if (difference === 0) {
      return 'Du befindest dich auf deinem Weg noch auf der Ausgangsposition.';
    }

    return userGoalProgress.firstScore < userGoalProgress.latestScore
      ? `Seit dem Start hast du dich um ${difference} ${difference === 1 ? 'Punkt' : 'Punkte'} auf der Skala verbessert.`
      : `Seit dem Start hast du dich um ${difference} ${difference === 1 ? 'Punkt' : 'Punkte'} auf der Skala verschlechtert.`;
  }

}
</script>
<template>
<div
  class="user-goal-trends"
  v-bind="$attrs"
>
  <v-row>
    <v-col
      v-for="userGoalProgress in userGoalProgressesWithAtLeastOneRating"
      :key="userGoalProgress.userGoalId"
      cols="12"
      md="6"
    >
      <div class="d-flex trend mt-2">
        <div class="trend-direction" :class="direction(userGoalProgress)">
          <font-awesome-icon :icon="['fas', icon(userGoalProgress)]" />
          <span class="d-block">{{ scoreDifference(userGoalProgress) }}</span>
        </div>
        <div class="description">
          <strong>{{ userGoalProgress.title }}</strong>
          <p class="mb-0">{{ description(userGoalProgress) }}</p>
        </div>
      </div>

    </v-col>
  </v-row>
</div>
</template>
<style lang="sass" scoped>
.trend-direction
  // Default is used for no movement or down
  color: var(--color-grey-5)
  flex-basis: 56px
  flex-shrink: 0

  svg
    height: 32px
    font-size: 2rem

  span
    font-weight: bold
    font-size: 1.5rem
    display: block
    margin-top: -12px

  &.up,
  &.top
    color: var(--color-green-5)

  &.equals
    svg
      padding-top: 4px

  &.top
    svg
      padding-top: 6px

.description
  font-size: 0.875rem

  strong
    font-weight: 700
    font-family: Montserrat, sans-serif
    color: var(--color-grey-2)
</style>
