<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { PublicationType } from '@/types';
import { useManagePublicationsStore } from '../store';
import { Publication, UpdatePublicationCommand } from '../types';
import { requiredWhenBookRule, requiredWhenOnlineRule } from '../rules';

interface Controls extends FormControls {
  title: FormControl<string>;
  publisher: FormControl<string>;
  type: FormControl<PublicationType>;
  author: FormControl<string>;
  release: FormControl<string>;
  link: FormControl<string>;
}

@Component
export default class UpdatePublicationDialog extends Vue {

  readonly store = useManagePublicationsStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly publication!: Publication;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isAuthorVisible(): boolean {
    const formValues = getFormValues(this.form!);

    return formValues.type === PublicationType.BOOK;
  }

  get isReleaseVisible(): boolean {
    const formValues = getFormValues(this.form!);

    return formValues.type === PublicationType.BOOK;
  }

  get isLinkVisible(): boolean {
    const formValues = getFormValues(this.form!);

    return formValues.type === PublicationType.ONLINE;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Bezeichnung',
          value: this.publication.title,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        publisher: {
          label: 'Herausgeber',
          value: this.publication.publisher,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        type: {
          label: 'Typ',
          value: this.publication.type,
          isRequired: true,
          validateFormControlsOnInput: [
            'author',
            'release',
            'link',
          ],
        },
        author: {
          label: 'Autor',
          value: this.publication.author ?? null,
          rules: [
            maxLengthRule(255),
          ],
        },
        release: {
          label: 'Erscheinungsjahr',
          value: this.publication.release ?? null,
          rules: [
            maxLengthRule(255),
          ],
        },
        link: {
          label: 'Link',
          value: this.publication.link ?? null,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });

    form.controls.author.rules!.push(requiredWhenBookRule(form.controls.type));
    form.controls.release.rules!.push(requiredWhenBookRule(form.controls.type));

    form.controls.link.rules!.push(requiredWhenOnlineRule(form.controls.type));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdatePublicationCommand = {
      publicationId: this.publication!.publicationId,
      title: formValues.title!,
      publisher: formValues.publisher!,
      type: formValues.type!,
      author: formValues.type === PublicationType.BOOK
        ? formValues.author
        : null,
      release: formValues.type === PublicationType.BOOK
        ? formValues.release
        : null,
      link: formValues.type === PublicationType.ONLINE
        ? formValues.link
        : null,
    };

    this.store.updatePublication(command)
      .then(() => showSuccessMessage('Publikation wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isUpdatePublicationProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Publikation anpassen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <a-text-form-control
              :form-control="form.controls.title"
              is-autofocused
            />
          </v-col>
          <v-col cols="12">
            <a-text-form-control :form-control="form.controls.publisher" />
          </v-col>
          <v-col cols="12">
            <a-publication-type-form-control :form-control="form.controls.type" />
          </v-col>
          <v-col cols="12" v-show="isAuthorVisible">
            <a-text-form-control :form-control="form.controls.author" />
          </v-col>
          <v-col cols="12" v-show="isReleaseVisible">
            <a-text-form-control :form-control="form.controls.release" />
          </v-col>
          <v-col cols="12" v-show="isLinkVisible">
            <a-text-form-control :form-control="form.controls.link" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          @click="closeDialog"
          :is-processing="store.isUpdatePublicationProcessing"
        />
        <a-submit-primary-action-button
          :is-disabled="isSubmitDisabled"
          :is-processing="store.isUpdatePublicationProcessing"
        >
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
