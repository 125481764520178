import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { Conversions } from './types';
import { getConversionHistory } from './service';

interface AppManagerDashboardState {
  conversionHistory: Conversions[];

  getConversionHistoryStatus: ActionStatus;
}

function initialState(): AppManagerDashboardState {
  return {
    conversionHistory: [],

    getConversionHistoryStatus: ActionStatus.None,
  };
}

export const useAppManagerDashboardStore = defineStore('appManagerDashboard', {
  state: (): AppManagerDashboardState => initialState(),
  getters: {
    isGetConversionHistoryProcessing: (state: AppManagerDashboardState): boolean =>
      state.getConversionHistoryStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getConversionHistory(): Promise<void> {
      const { getConversionHistoryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getConversionHistoryStatus,
        () => getConversionHistory(attachAppAndUser({}))
          .then(async (conversionHistory) => {
            this.conversionHistory = conversionHistory;
          })
      );
    },

  },
});
