<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import CreatePreparationStepDialog from './create-preparation-step-dialog.vue';
import UpdatePreparationStepDialog from './update-preparation-step-dialog.vue';

@Component({
  components: {
    CreatePreparationStepDialog,
    UpdatePreparationStepDialog,
  },
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class PreparationStepsFormControl extends Vue implements FormControlComponent<string[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<string[]>;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: string[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  createdPreparationStep(preparationStep: string): void {
    this.internalValue.push(preparationStep);

    this.blurred();

    internalValuesChanged(this);
  }

  updatedPreparationStep(index: number, preparationStep: string): void {
    this.internalValue.splice(index, 1, preparationStep);

    this.blurred();

    internalValuesChanged(this);
  }

  deletedPreparationStep(index: number): void {
    this.internalValue.splice(index, 1);

    this.blurred();

    internalValuesChanged(this);
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? []
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<string[]> {
    return this.internalValue.length > 0
      ? this.internalValue
      : null;
  }

}
</script>
<template>
<div class="form-control preparation-steps-form-control" v-bind="$attrs">
  <fieldset class="a-field pt-2 pb-4">
    <legend>{{ labelWithRequiredIndicator(formControl) }}</legend>
    <div class="form-control-input">
      <div class="steps">
        <div
          class="step d-flex"
          v-for="(step, index) in internalValue"
          :key="index"
        >
          <div class="content d-flex flex-grow-1">
            <span class="step-number mb-1">{{ index + 1 }}</span>
            <div class="description" v-html="step" />
          </div>
          <div class="actions flex-shrink-0 pl-2">
            <update-preparation-step-dialog
              :preparation-step="step"
              @submitted="updatedPreparationStep(index, $event)"
              @closed="blurred"
            >
              <icon-button
                :icon="['far', 'pencil']"
                tooltip="Anpassen"
                small
              />
            </update-preparation-step-dialog>
            <icon-button
              :icon="['far', 'times']"
              @click="deletedPreparationStep(index)"
              tooltip="Löschen"
              small
            />
          </div>
        </div>
      </div>
      <create-preparation-step-dialog
        @submitted="createdPreparationStep"
        @closed="blurred"
      >
        <a-execute-secondary-action-button
          @click="focused"
          is-small
          class="mt-2"
        >
          Schritt hinzufügen
        </a-execute-secondary-action-button>
      </create-preparation-step-dialog>
    </div>
  </fieldset>

    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
</div>
</template>
<style lang="sass" scoped>
.steps
  .step
    .step-number
      display: block
      background: var(--color-brand)
      border-radius: 50%
      color: var(--color-white)
      font-weight: 700
      font-family: Montserrat, sans-serif
      width: 1.5rem
      height: 1.5rem
      line-height: 1.5rem
      text-align: center
      flex-grow: 0
      flex-shrink: 0
      margin-right: 0.75rem

  .description::v-deep
    flex-grow: 1

    p
      padding-bottom: 1rem
      margin-bottom: 0

    ul,
    ol
      padding-bottom: 1rem

    li
      p
        padding-bottom: 0
</style>
