<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GetDocumentFileQuery, SendDocumentViaEmailCommand } from '@/private/user/documents/types';
import { downloadFile } from '@/helpers/file-download-helper';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useConsumeDocumentsStore } from '@/private/user/documents/store';
import { useConsumeModulesStore } from '../store';
import { ContentElementDocument } from '../types';

@Component
export default class Document extends Vue {

  readonly store = useConsumeModulesStore();
  readonly consumeDocumentsStore = useConsumeDocumentsStore();

  @Prop({ type: Object, required: true })
  readonly contentElement!: ContentElementDocument;

  downloadDocumentClicked(): void {
    const query: GetDocumentFileQuery = {
      documentId: this.contentElement.idOfReferencedDocument,
    };

    this.consumeDocumentsStore.getDocumentFile(query)
      .then((file) => downloadFile(
        file.data,
        file.contentType,
        this.contentElement.documentFileName
      ))
      .catch((error) => showErrorResponse(error));
  }

  sendDocumentViaEmailClicked(): void {
    const command: SendDocumentViaEmailCommand = {
      documentId: this.contentElement.idOfReferencedDocument,
    };

    this.consumeDocumentsStore.sendDocumentViaEmail(command)
      .then(() => showSuccessMessage('Das Dokument ist per E-Mail unterwegs zu dir.'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<div class="document pt-4">
  <h3>
    {{ contentElement.documentTitle }}
  </h3>
  <span v-html="contentElement.documentExplanation" />
  <v-row
    no-gutters
    class="mt-3"
  >
    <v-col class="mr-1">
      <a-execute-secondary-action-button
        @click="downloadDocumentClicked"
        is-small
        is-always-shown-in-block-mode
      >
        Herunterladen
      </a-execute-secondary-action-button>
    </v-col>
    <v-col class="ml-1">
      <a-execute-secondary-action-button
        @click="sendDocumentViaEmailClicked"
        is-small
        is-always-shown-in-block-mode
      >
        Als E-Mail
      </a-execute-secondary-action-button>
    </v-col>
  </v-row>
</div>
</template>
<style lang="sass" scoped>
.document
  background: var(--color-grey-9)
  padding: 1.25rem 1rem
  border: solid var(--color-grey-8)
  border-width: 1px 0
  color: var(--color-grey-3)

  strong
    font-weight: 700
    font-family: Montserrat, sans-serif
    color: var(--color-grey-2)

  &::v-deep
    p,
    ul
      font-size: 1rem

  .action-link
    color: var(--color-brand)
    font-weight: 600
    cursor: pointer
</style>
