<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { weekdayTranslation } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useAppStore } from '@/application/common/app/store';
import UpdateHabitIntentDialog from './update-habit-intent-dialog.vue';
import DisableHabitIntentDialog from './disable-habit-intent-dialog.vue';
import { useManageHabitIntentsStore } from '../store';
import { EnableHabitIntentCommand, HabitIntent } from '../types';

@Component({
  components: {
    UpdateHabitIntentDialog,
    DisableHabitIntentDialog,
  },
})
export default class Habit extends Vue {

  readonly store = useManageHabitIntentsStore();
  readonly appStore = useAppStore();

  get isLoading(): boolean {
    return this.store.isGetHabitIntentsProcessing
      || !this.store.currentHabitIntent;
  }

  get isCurrentHabitIntentVisible(): boolean {
    return this.store.currentHabitIntent !== null;
  }

  mounted(): void {
    watch(() => this.$route.path, () => {
      this.store.getHabitIntents()
        .then(() => this.store.updateCurrentHabitIntentId(this.$route.params.id))
        .then(() => this.appStore.updateTitle(this.store.currentHabitIntent!.title))
        .catch((error) => showErrorResponse(error));
    }, { immediate: true });
  }

  translatedWeekdays(habitIntent: HabitIntent): string {
    return habitIntent.weekdays.map((weekday) => weekdayTranslation[weekday].toString()).join(', ');
  }

  onEnableHabitIntentClicked(): void {
    const command: EnableHabitIntentCommand = {
      habitIntentId: this.store.currentHabitIntent!.habitIntentId,
    };

    this.store.enableHabitIntent(command)
      .then(() => showSuccessMessage('Gewohnheit wurde aktiviert'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container class="habit">

  <h3>Aktuelle Einstellungen</h3>

  <v-progress-linear v-if="isLoading" class="mb-2" color="primary" indeterminate />

  <template v-if="isCurrentHabitIntentVisible">

    <p>
      <span class="d-block"> <strong>{{ store.currentHabitIntent.trigger }}</strong> an den Tagen <strong>{{ translatedWeekdays(store.currentHabitIntent) }}</strong>.</span>
      <span class="d-block">Nächster Reminder am <strong>{{ store.currentHabitIntent.nextReminderAt | formatDate }} um {{ store.currentHabitIntent.nextReminderAt | formatTime }} Uhr</strong></span>
    </p>

    <div class="actions mt-4">
      <update-habit-intent-dialog :habit-intent="store.currentHabitIntent">
        <a-trigger-secondary-process-button>
          Anpassen
        </a-trigger-secondary-process-button>
      </update-habit-intent-dialog>

      <disable-habit-intent-dialog v-if="store.currentHabitIntent.isEnabled" :habit-intent="store.currentHabitIntent">
        <a-trigger-tertiary-process-button class="mt-4 mt-md-0 ml-0 ml-md-6">
          Deaktivieren
        </a-trigger-tertiary-process-button>
      </disable-habit-intent-dialog>

      <a-trigger-primary-process-button
        v-if="!store.currentHabitIntent.isEnabled"
        @click="onEnableHabitIntentClicked"
        class="mt-4 mt-md-0  ml-0 ml-md-4"
      >
        Aktivieren
      </a-trigger-primary-process-button>
    </div>

    <v-row>
      <v-col cols="12" md="9">
        <v-card outlined class="mt-6 mt-md-4">
          <v-card-text>
            <template>
              <h3>{{ store.currentHabitIntent.title }}</h3>
              <p class="introduction mb-2">{{ store.currentHabitIntent.habitIntroduction }}</p>
              <p class="min-runtime mb-0">Nach ungefähr {{ store.currentHabitIntent.minRuntime }} Wochen kannst du mit Verbesserungen rechnen.</p>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </template>

  <a-trigger-tertiary-process-button
    :to="{ name: 'manage-habit-intents-habit-intent-list' }"
    is-exact
    class="my-4"
  >
    Zurück
  </a-trigger-tertiary-process-button>

</v-container>
</template>
<style lang="sass" scoped>
.habit
  font-size: 0.875rem

  strong
    font-weight: 600

  .introduction
    white-space: pre-wrap

  .min-runtime
    font-size: 0.875rem
    color: var(--color-grey-3)
</style>
