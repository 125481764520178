import { RouteConfig } from 'vue-router';
import { Feature } from '@/types';
import PublicationList from './components/publication-list.vue';

export const bibliographyRoutes: RouteConfig[] = [
  {
    path: '/literaturliste',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'bibliography-bibliography-list',
        path: '/',
        component: PublicationList,
        meta: {
          requiresAuth: true,
          requiresFeature: Feature.BIBLIOGRAPHY,
          title: 'Gesundheitsinformationen',
        },
      },
    ],
  },
];
