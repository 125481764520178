<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { YearMonth } from '@/helpers/data';
import { useAdminAccountingStore } from '../store';

@Component
export default class AvailableSponsorSeats extends Vue {

  readonly store = useAdminAccountingStore();

  get isLoading(): boolean {
    return this.store.isGetAvailableSeatsHistoryProcessing;
  }

  mounted(): void {
    this.store.getAvailableSeatsHistory()
      .catch((error) => showErrorResponse(error));
  }

  yearMonthFormatted(yearMonth: YearMonth): string {
    return dayjs(yearMonth).format('YYYY MMMM');
  }

}
</script>
<template>
<v-container>

  <v-progress-linear v-if="isLoading" color="primary" indeterminate />

  <template v-if="!isLoading">

    <v-card
      outlined
      class="mt-4 mb-4"
      v-if="store.apps.length === 0"
    >
      <v-list flat class="pt-0 pb-0">
        <v-list-item class="pl-4" disabled>
          <v-list-item-content>
            Noch keine Sponsoren für keine App erstellt.
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card
      outlined
      class="mt-4 mb-4"
      v-for="app in store.apps"
      :key="app.appId"
    >
      <v-card-text>
        <strong class="d-block mb-4">{{ app.title }}</strong>
        <v-simple-table class="mb-0">
          <template #default>
            <thead>
            <tr>
              <th class="text-left">
                Monat
              </th>
              <th
                class="text-right"
                v-for="sponsor in app.sponsors"
                :key="sponsor.sponsorId"
              >
                {{ sponsor.name }}
              </th>
              <th class="text-right">
                Gesamt
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(amount, yearMonth) in app.historyOfTotalAvailableSeatsForApp"
              :key="yearMonth"
            >
              <td class="text-left">{{ yearMonthFormatted(yearMonth) }}</td>
              <td
                class="text-right"
                v-for="sponsor in app.sponsors"
                :key="sponsor.sponsorId"
              >
                {{ sponsor.historyOfAvailableSeats[yearMonth] || 0 }}
              </td>
              <td class="text-right">
                <strong>{{ amount }}</strong>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

  </template>

</v-container>
</template>
