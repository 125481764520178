<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { defaultVimeoOptions } from '@/helpers/vimeo-helper';
import { ContentElementVideo } from '../types';

@Component({
  components: {
    'vimeo-player': vueVimeoPlayer,
  },
})
export default class VideoElement extends Vue {

  @Prop({ type: Object, required: true })
  readonly contentElement!: ContentElementVideo;

  readonly vimeoOptions = defaultVimeoOptions();

  // Used from outside
  public pause(): void {
    // @ts-ignore
    const player = this.$refs.player as vueVimeoPlayer;
    if (player) {
      player.pause();
    }
  }

}
</script>
<template>
<div :class="{ 'mb-4': !contentElement.videoTranscript }">
  <vimeo-player
    ref="player"
    :video-id="contentElement.videoId"
    :options="vimeoOptions"
  />
  <v-expansion-panels flat class="mb-4 pl-4 pr-4" v-if="contentElement.videoTranscript">
    <v-expansion-panel>
      <v-expansion-panel-header>Transkript</v-expansion-panel-header>
      <v-expansion-panel-content>{{ contentElement.videoTranscript }}</v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</div>
</template>
<style lang="sass" scoped>
.v-expansion-panels
  .v-expansion-panel
    border: none !important

    .v-expansion-panel-header
      font-weight: normal !important
      color: var(--color-grey-3) !important
      padding: 0 !important
      min-height: 3rem
      font-size: 0.875rem

    .v-expansion-panel-content
      line-height: 1.7
      white-space: pre-wrap
      margin-bottom: 0.75rem

      ::v-deep
        .v-expansion-panel-content__wrap
          padding: 0
          color: var(--color-grey-3)
</style>
