<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { RecipeId } from '@/types';
import CreateRecipeDialog from './create-recipe-dialog.vue';
import UpdateRecipeDialog from './update-recipe-dialog.vue';
import { useManageRecipesStore } from '../store';
import { Recipe, PublishRecipeCommand, UnpublishRecipeCommand, UpdateOrderOfRecipesAsManagerCommand } from '../types';

@Component({
  components: {
    CreateRecipeDialog,
    UpdateRecipeDialog,
  },
})
export default class RecipeList extends Vue {

  readonly store = useManageRecipesStore();

  mounted(): void {
    this.store.getRecipes()
      .catch((error) => showErrorResponse(error));
  }

  sortedRecipes(sortedIds: RecipeId[]): void {
    const command: UpdateOrderOfRecipesAsManagerCommand = {
      sortedIdsOfRecipes: sortedIds,
    };
    this.store.updateOrderOfRecipes(command)
      .then(() => showSuccessMessage('Die Reihenfolge wurde angepasst.'))
      .catch((error) => showErrorResponse(error));
  }

  async publishRecipeConfirmed(blogArticle: Recipe): Promise<void> {
    const command: PublishRecipeCommand = {
      recipeId: blogArticle.recipeId,
    };
    return this.store.publishRecipe(command)
      .catch((error) => showErrorResponse(error));
  }

  async unpublishRecipeConfirmed(blogArticle: Recipe): Promise<void> {
    const command: UnpublishRecipeCommand = {
      recipeId: blogArticle.recipeId,
    };
    return this.store.unpublishRecipe(command)
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <create-recipe-dialog>
    <a-trigger-primary-process-button>
      Rezept erstellen
    </a-trigger-primary-process-button>
  </create-recipe-dialog>

  <v-card
    class="mt-4 mb-4 overflow-hidden"
    outlined
  >
    <a-action-list
      :items="store.recipes"
      item-id="recipeId"
      :is-loading="store.isGetRecipesProcessing"
      empty-list-message="Noch keine Rezepte angelegt."
      is-sortable
      sort-handle=".sort-handle"
      @sorted="sortedRecipes"
    >
      <template #content="{ item: recipe }">
        <span class="item-title">
          {{ recipe.name }}<template v-if="recipe.subTitle"> - {{ recipe.subTitle }}</template>
          <template v-if="!recipe.isPublished"> (Unveröffentlicht)</template>
        </span>
        <span class="details">
          Erstellt am {{ recipe.createdAt | formatDate }}
        </span>
      </template>
      <template #actions="{ item: recipe }">

        <icon-button
          :icon="['far', 'grip-lines']"
          small
          class="sort-handle"
          tooltip="Verschieben um Reihen-folge anzupassen"
        />

        <update-recipe-dialog :recipe="recipe">
          <icon-button small :icon="['far', 'pencil']" tooltip="Rezept anpassen" />
        </update-recipe-dialog>

        <a-confirmation-dialog
          title="Rezept veröffentlichen"
          button-text="Veröffentlichen"
          :action="() => publishRecipeConfirmed(recipe)"
          :is-processing="store.isPublishRecipeProcessing"
        >
          <icon-button
            v-if="!recipe.isPublished"
            small
            :icon="['far', 'eye']"
            tooltip="Veröffentlichen"
          />
          <template #content>
            <p>
              Soll das Rezept wirklich veröffentlicht werden?
            </p>
          </template>
        </a-confirmation-dialog>

        <a-confirmation-dialog
          title="Rezept verstecken"
          button-text="Verstecken"
          highlight="warning"
          :action="() => unpublishRecipeConfirmed(recipe)"
          :is-processing="store.isUnpublishRecipeProcessing"
        >
          <icon-button
            v-if="recipe.isPublished"
            small
            :icon="['far', 'eye-slash']"
            tooltip="Verstecken"
          />
          <template #content>
            <p>
              Soll das Rezept wirklich verstecken werden?
            </p>
          </template>
        </a-confirmation-dialog>

      </template>
    </a-action-list>
  </v-card>

</v-container>
</template>
