import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { createUserGoal, deleteUserGoal, getUserGoalProposals, getUserProgress, logUserGoalRatingRejection, rateUserGoalProgress, updateUserGoal } from './service';
import { CreateUserGoalCommand, DeleteUserGoalCommand, RateUserGoalProgressCommand, UpdateUserGoalCommand, UserGoalProposal, UserProgress } from './types';

interface UserProgressState {
  userProgress: UserProgress | null;
  userGoalProposals: UserGoalProposal[];

  getUserProgressStatus: ActionStatus;
  getUserGoalProposalsStatus: ActionStatus;
  deleteUserGoalStatus: ActionStatus;
  rateUserGoalProgressStatus: ActionStatus;
  createUserGoalStatus: ActionStatus;
  updateUserGoalStatus: ActionStatus;
  logUserGoalRatingRejectionStatus: ActionStatus;
}

function initialState(): UserProgressState {
  return {
    userProgress: null,
    userGoalProposals: [],

    getUserProgressStatus: ActionStatus.None,
    getUserGoalProposalsStatus: ActionStatus.None,
    deleteUserGoalStatus: ActionStatus.None,
    rateUserGoalProgressStatus: ActionStatus.None,
    createUserGoalStatus: ActionStatus.None,
    updateUserGoalStatus: ActionStatus.None,
    logUserGoalRatingRejectionStatus: ActionStatus.None,
  };
}

export const useUserProgressStore = defineStore('userProgress', {
  state: (): UserProgressState => initialState(),
  getters: {
    isGetUserProgressProcessing: (state: UserProgressState): boolean =>
      state.getUserProgressStatus === ActionStatus.InProgress,
    isGetUserGoalProposalsProcessing: (state: UserProgressState): boolean =>
      state.getUserGoalProposalsStatus === ActionStatus.InProgress,
    isDeleteUserGoalProcessing: (state: UserProgressState): boolean =>
      state.deleteUserGoalStatus === ActionStatus.InProgress,
    isRateUserGoalProgressProcessing: (state: UserProgressState): boolean =>
      state.rateUserGoalProgressStatus === ActionStatus.InProgress,
    isCreateUserGoalProcessing: (state: UserProgressState): boolean =>
      state.createUserGoalStatus === ActionStatus.InProgress,
    isUpdateUserGoalProcessing: (state: UserProgressState): boolean =>
      state.updateUserGoalStatus === ActionStatus.InProgress,
    isLogUserGoalRatingRejectionProcessing: (state: UserProgressState): boolean =>
      state.logUserGoalRatingRejectionStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getUserProgress(): Promise<void> {
      const { getUserProgressStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserProgressStatus,
        () => getUserProgress(attachAppAndUser({}))
          .then((userProgress) => {
            this.userProgress = userProgress;
          })
      );
    },

    getUserGoalProposals(): Promise<void> {
      const { getUserGoalProposalsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserGoalProposalsStatus,
        () => getUserGoalProposals(attachAppAndUser({}))
          .then((userGoalProposals) => {
            this.userGoalProposals = userGoalProposals;
          })
      );
    },

    // -- Commands

    deleteUserGoal(command: DeleteUserGoalCommand): Promise<void> {
      const { deleteUserGoalStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteUserGoalStatus,
        () => deleteUserGoal(attachAppAndUser(command))
          .then(() => this.getUserProgress())
      );
    },

    rateUserGoalProgress(command: RateUserGoalProgressCommand): Promise<void> {
      const { rateUserGoalProgressStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        rateUserGoalProgressStatus,
        () => rateUserGoalProgress(attachAppAndUser(command))
          .then(() => this.getUserProgress())
      );
    },

    createUserGoal(command: CreateUserGoalCommand): Promise<void> {
      const { createUserGoalStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createUserGoalStatus,
        () => createUserGoal(attachAppAndUser(command))
          .then(() => this.getUserProgress())
      );
    },

    updateUserGoal(command: UpdateUserGoalCommand): Promise<void> {
      const { updateUserGoalStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserGoalStatus,
        () => updateUserGoal(attachAppAndUser(command))
          .then(() => this.getUserProgress())
      );
    },

    logUserGoalRatingRejection(): Promise<void> {
      const { logUserGoalRatingRejectionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        logUserGoalRatingRejectionStatus,
        () => logUserGoalRatingRejection(attachAppAndUser({}))
          .then(() => this.getUserProgress())
      );
    },

  },
});
