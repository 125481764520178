<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Imprint extends Vue {

}
</script>
<template>
<v-container class="imprint">
  <v-row>
    <v-col md="12" lg="9">

      <h2>
        Angaben gemäß § 5 TMG
      </h2>
      <p>
        <strong class="d-block">Vitomy</strong>
      </p>
      <p>
        Inhaltlich Verantwortlich:<br />
        Vitomy UG (haftungsbeschränkt)<br />
        HRB 249684
      </p>
      <p>
        Vertreten durch Caroline Lutz und Christian Kolb
      </p>
      <p>
        Kontakt: <a href="mailto:info@vitokrates.de" target="_blank">info@vitomy.de</a><br />
        Karlstraße 110<br />
        80335 München
      </p>
      <p>
        Telefon: +49 8761 3989630
      </p>
      <h2>
        Alternative Streitbeilegung
      </h2>
      <p>
        Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, die unter <a href="https://www.ec.europa.eu/consumers/odr" target="_blank">www.ec.europa.eu/consumers/odr</a> aufrufbar ist. Unsere E-Mail-Adresse finden Sie in unserem Impressum. Wir nehmen am Streitschlichtungsverfahren teil. Eine Liste mit den Kontaktdaten der anerkannten Streitschlichtungsstellen finden Sie unter <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.adr.show" target="_blank">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.adr.show</a>.
      </p>
      <h2>
        Haftungsausschluss (Disclaimer)
      </h2>
      <h3>
        Haftung für Inhalte
      </h3>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      </p>
      <h3>
        Haftung für Links
      </h3>
      <p>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </p>
      <h3>
        Urheberrecht
      </h3>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      </p>
      <h3>
        Warnhinweis zu Inhalten
      </h3>
      <p>
        Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Vollständigkeit, Aktualität, Richtigkeit und Ausgewogenheit der dargebotenen Informationen. Die Nutzung dieser Webseiteninhalte erfolgt auf eigene Verantwortung. Allein durch den Aufruf dieser kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters.
      </p>
      <h3>
        Keine Werbung
      </h3>
      <p class="mb-0">
        Die Verwendung der Kontaktdaten des Impressums zur gewerblichen Werbung ist ausdrücklich nicht erwünscht, es sei denn der Anbieter hatte zuvor seine schriftliche Einwilligung erteilt. Der Anbieter und alle auf dieser Website genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten.
      </p>

    </v-col>
  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.imprint
  font-size: 0.875rem
</style>
