import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { getRecipes } from './service';
import { Recipe } from './types';

interface ConsumeRecipesState {
  recipes: Recipe[]
  currentRecipeId: string | null

  getRecipesStatus: ActionStatus;
}

function initialState(): ConsumeRecipesState {
  return {
    recipes: [],
    currentRecipeId: null,

    getRecipesStatus: ActionStatus.None,
  };
}

export const useConsumeRecipesStore = defineStore('consumeRecipes', {
  state: (): ConsumeRecipesState => initialState(),
  getters: {
    currentRecipe: (state: ConsumeRecipesState): Recipe | null =>
      state.currentRecipeId !== null
        ? state.recipes
          .find((recipe) => recipe.recipeId === state.currentRecipeId) ?? null
        : null,
    isGetRecipesProcessing: (state: ConsumeRecipesState): boolean =>
      state.getRecipesStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentRecipe(recipeId: string): void {
      this.currentRecipeId = recipeId;
    },

    resetCurrentRecipe(): void {
      this.currentRecipeId = null;
    },

    // -- Queries

    getRecipes(): Promise<void> {
      const { getRecipesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getRecipesStatus,
        () => getRecipes(attachAppAndUser({}))
          .then(async (recipes) => {
            this.recipes = recipes;
          })
      );
    },

  },
});
