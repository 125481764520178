import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { CreateSponsorCommand, DeleteRegistrationLinkForSponsorCommand, GenerateRegistrationLinkForSponsorCommand, GetSeatsForSponsorQuery, InviteUsersForSponsorCommand, RefreshRegistrationLinkForSponsorCommand, ResendUserInviteForSponsorCommand, RevokeSeatCommand, Sponsor, SponsorSeats, UpdateAvailableSeatsForSponsorCommand, UpdateSponsorNameCommand } from './types';
import { createSponsor, deleteRegistrationLinkForSponsor, generateRegistrationLinkForSponsor, getSeatsForSponsor, getSponsors, inviteUsersForSponsor, refreshRegistrationLinkForSponsor, resendUserInviteForSponsor, revokeSeat, updateAvailableSeatsForSponsor, updateSponsorName } from './service';

interface ManageSponsorsState {
  sponsors: Sponsor[];
  sponsorSeats: SponsorSeats | null;
  currentSponsorId: string | null;

  getSponsorsStatus: ActionStatus;
  getSeatsForSponsorStatus: ActionStatus;
  createSponsorStatus: ActionStatus;
  updateSponsorNameStatus: ActionStatus;
  updateAvailableSeatsForSponsorStatus: ActionStatus;
  inviteUsersForSponsorStatus: ActionStatus;
  resendUserInviteForSponsorStatus: ActionStatus;
  revokeSeatStatus: ActionStatus;
  generateRegistrationLinkForSponsorStatus: ActionStatus;
  refreshRegistrationLinkForSponsorStatus: ActionStatus;
  deleteRegistrationLinkForSponsorStatus: ActionStatus;
}

function initialState(): ManageSponsorsState {
  return {
    sponsors: [],
    sponsorSeats: null,
    currentSponsorId: null,

    getSponsorsStatus: ActionStatus.None,
    getSeatsForSponsorStatus: ActionStatus.None,
    createSponsorStatus: ActionStatus.None,
    updateSponsorNameStatus: ActionStatus.None,
    updateAvailableSeatsForSponsorStatus: ActionStatus.None,
    inviteUsersForSponsorStatus: ActionStatus.None,
    resendUserInviteForSponsorStatus: ActionStatus.None,
    revokeSeatStatus: ActionStatus.None,
    generateRegistrationLinkForSponsorStatus: ActionStatus.None,
    refreshRegistrationLinkForSponsorStatus: ActionStatus.None,
    deleteRegistrationLinkForSponsorStatus: ActionStatus.None,
  };
}

export const useManageSponsorsStore = defineStore('manageSponsors', {
  state: (): ManageSponsorsState => initialState(),
  getters: {
    currentSponsor: (state: ManageSponsorsState): Sponsor | null =>
      state.sponsors.find((sponsor) => sponsor.sponsorId === state.currentSponsorId) ?? null,

    isGetSponsorsProcessing: (state: ManageSponsorsState): boolean =>
      state.getSponsorsStatus === ActionStatus.InProgress,
    isGetSeatsForSponsorProcessing: (state: ManageSponsorsState): boolean =>
      state.getSeatsForSponsorStatus === ActionStatus.InProgress,
    isCreateSponsorProcessing: (state: ManageSponsorsState): boolean =>
      state.createSponsorStatus === ActionStatus.InProgress,
    isUpdateSponsorNameProcessing: (state: ManageSponsorsState): boolean =>
      state.updateSponsorNameStatus === ActionStatus.InProgress,
    isUpdateAvailableSeatsForSponsorProcessing: (state: ManageSponsorsState): boolean =>
      state.updateAvailableSeatsForSponsorStatus === ActionStatus.InProgress,
    isInviteUsersForSponsorProcessing: (state: ManageSponsorsState): boolean =>
      state.inviteUsersForSponsorStatus === ActionStatus.InProgress,
    isResendUserInviteForSponsorProcessing: (state: ManageSponsorsState): boolean =>
      state.resendUserInviteForSponsorStatus === ActionStatus.InProgress,
    isRevokeSeatProcessing: (state: ManageSponsorsState): boolean =>
      state.revokeSeatStatus === ActionStatus.InProgress,
    isGenerateRegistrationLinkForSponsorProcessing: (state: ManageSponsorsState): boolean =>
      state.generateRegistrationLinkForSponsorStatus === ActionStatus.InProgress,
    isRefreshRegistrationLinkForSponsorProcessing: (state: ManageSponsorsState): boolean =>
      state.refreshRegistrationLinkForSponsorStatus === ActionStatus.InProgress,
    isDeleteRegistrationLinkForSponsorProcessing: (state: ManageSponsorsState): boolean =>
      state.deleteRegistrationLinkForSponsorStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentSponsor(sponsorId: string): Promise<void> {
      this.currentSponsorId = sponsorId;

      return this.getSeatsForSponsor();
    },

    resetCurrentSponsor(): void {
      this.currentSponsorId = null;
      this.sponsorSeats = null;
    },

    // -- Queries

    getSponsors(): Promise<void> {
      const { getSponsorsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSponsorsStatus,
        () => getSponsors(attachAppAndUser({}))
          .then(async (sponsors) => {
            this.sponsors = sponsors;
          })
      );
    },

    getSeatsForSponsor(): Promise<void> {
      const query: GetSeatsForSponsorQuery = {
        sponsorId: this.currentSponsorId!,
      };
      const { getSeatsForSponsorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSeatsForSponsorStatus,
        () => getSeatsForSponsor(attachAppAndUser(query))
          .then(async (sponsorSeats) => {
            this.sponsorSeats = sponsorSeats;
          })
      );
    },

    // -- Commands

    createSponsor(command: CreateSponsorCommand): Promise<void> {
      const { createSponsorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createSponsorStatus,
        () => createSponsor(attachAppAndUser(command))
          .then(() => this.getSponsors())
      );
    },

    updateSponsorName(command: UpdateSponsorNameCommand): Promise<void> {
      const { updateSponsorNameStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateSponsorNameStatus,
        () => updateSponsorName(attachAppAndUser(command))
          .then(() => this.getSponsors())
      );
    },

    updateAvailableSeatsForSponsor(command: UpdateAvailableSeatsForSponsorCommand): Promise<void> {
      const { updateAvailableSeatsForSponsorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAvailableSeatsForSponsorStatus,
        () => updateAvailableSeatsForSponsor(attachAppAndUser(command))
          .then(() => this.getSponsors())
      );
    },

    inviteUsersForSponsor(command: InviteUsersForSponsorCommand): Promise<void> {
      const { inviteUsersForSponsorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        inviteUsersForSponsorStatus,
        () => inviteUsersForSponsor(attachAppAndUser(command))
          .then(() => this.getSeatsForSponsor())
      );
    },

    resendUserInviteForSponsor(command: ResendUserInviteForSponsorCommand): Promise<void> {
      const { resendUserInviteForSponsorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        resendUserInviteForSponsorStatus,
        () => resendUserInviteForSponsor(attachAppAndUser(command))
          .then(() => this.getSeatsForSponsor())
      );
    },

    revokeSeat(command: RevokeSeatCommand): Promise<void> {
      const { revokeSeatStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        revokeSeatStatus,
        () => revokeSeat(attachAppAndUser(command))
          .then(() => this.getSeatsForSponsor())
      );
    },

    generateRegistrationLinkForSponsor(command: GenerateRegistrationLinkForSponsorCommand): Promise<void> {
      const { generateRegistrationLinkForSponsorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        generateRegistrationLinkForSponsorStatus,
        () => generateRegistrationLinkForSponsor(attachAppAndUser(command))
          .then(() => this.getSeatsForSponsor())
      );
    },

    refreshRegistrationLinkForSponsor(command: RefreshRegistrationLinkForSponsorCommand): Promise<void> {
      const { refreshRegistrationLinkForSponsorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        refreshRegistrationLinkForSponsorStatus,
        () => refreshRegistrationLinkForSponsor(attachAppAndUser(command))
          .then(() => this.getSeatsForSponsor())
      );
    },

    deleteRegistrationLinkForSponsor(command: DeleteRegistrationLinkForSponsorCommand): Promise<void> {
      const { deleteRegistrationLinkForSponsorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteRegistrationLinkForSponsorStatus,
        () => deleteRegistrationLinkForSponsor(attachAppAndUser(command))
          .then(() => this.getSeatsForSponsor())
      );
    },

  },
});
