import Vue from 'vue';
// -- Form helpers
import FormControlMessages from '@/components/form/form-control-messages.vue';
import Form from '@/components/form/form.vue';
// -- Form controls (alphabetically)
import AppTypeFormControl from '@/components/form/app-type-form-control.vue';
import EmailAddressFormControl from '@/components/form/email-address-form-control.vue';
import PasswordFormControl from '@/components/form/password-form-control.vue';
import CheckboxFormControl from '@/components/form/checkbox-form-control.vue';
import ContentElementQuizAnswerFormControl from '@/components/form/content-element-quiz-answer-form-control.vue';
import DateTimeFormControl from '@/components/form/date-time-form-control.vue';
import DocumentFormControl from '@/components/form/document-form-control.vue';
import FeaturesFormControl from '@/components/form/features-form-control.vue';
import FileFormControl from '@/components/form/file-form-control.vue';
import GenderFormControl from '@/components/form/gender-form-control.vue';
import HabitFormControl from '@/components/form/habit-form-control.vue';
import HtmlFormControl from '@/components/form/html-form-control.vue';
import ImageCropperFormControl from '@/components/form/image-cropper-form-control.vue';
import IngredientUnitFormControl from '@/components/form/ingredient-unit-form-control.vue';
import IssueTypeFormControl from '@/components/form/issue-type-form-control.vue';
import MultiEmailAddressTextareaFormControl from '@/components/form/multi-email-address-textarea-form-control.vue';
import MultiModuleFormControl from '@/components/form/multi-module-form-control.vue';
import MultiTextFormControl from '@/components/form/multi-text-form-control.vue';
import NumberFormControl from '@/components/form/number-form-control.vue';
import NumberSliderFormControl from '@/components/form/number-slider-form-control.vue';
import NutritionDiaryTipTypeFormControl from '@/components/form/nutrition-diary-tip-type-form-control.vue';
import PreventionTypeFormControl from '@/components/form/prevention-type-form-control.vue';
import PublicationTypeFormControl from '@/components/form/publication-type-form-control.vue';
import StyleAliasFormControl from '@/components/form/style-alias-form-control.vue';
import TextareaFormControl from '@/components/form/textarea-form-control.vue';
import TextFormControl from '@/components/form/text-form-control.vue';
import TimeFormControl from '@/components/form/time-form-control.vue';
import UserGoalProposalFormControl from '@/components/form/user-goal-proposal-form-control.vue';
import VisibleForFormControl from '@/components/form/visible-for-form-control.vue';
import WeekdaysFormControl from '@/components/form/weekdays-form-control.vue';
// -- Domain form controls
import IngredientsFormControl from '@/private/management/recipes/components/ingredients-form-control.vue';
import PossibleAnswersFormControl from '@/private/management/modules/components/possible-answers-form-control.vue';
import PreparationStepsFormControl from '@/private/management/recipes/components/preparation-steps-form-control.vue';
import SelfReflectionOptionsFormControl from '@/private/management/modules/components/self-reflection-options-form-control.vue';
import StrategiesFormControl from '@/private/management/blog-articles/components/strategies-form-control.vue';
import UserGoalProgressesFormControl from '@/private/user/user-progress/components/user-goal-progresses-form-control.vue';

// -- Form helpers
Vue.component('a-form-control-messages', FormControlMessages);
Vue.component('a-form', Form);

// -- Form controls (alphabetically)
Vue.component('a-app-type-form-control', AppTypeFormControl);
Vue.component('a-email-address-form-control', EmailAddressFormControl);
Vue.component('a-checkbox-form-control', CheckboxFormControl);
Vue.component('a-content-element-quiz-answer-form-control', ContentElementQuizAnswerFormControl);
Vue.component('a-date-time-form-control', DateTimeFormControl);
Vue.component('a-document-form-control', DocumentFormControl);
Vue.component('a-features-form-control', FeaturesFormControl);
Vue.component('a-file-form-control', FileFormControl);
Vue.component('a-gender-form-control', GenderFormControl);
Vue.component('a-habit-form-control', HabitFormControl);
Vue.component('a-html-form-control', HtmlFormControl);
Vue.component('a-image-cropper-form-control', ImageCropperFormControl);
Vue.component('a-ingredient-unit-form-control', IngredientUnitFormControl);
Vue.component('a-issue-type-form-control', IssueTypeFormControl);
Vue.component('a-number-form-control', NumberFormControl);
Vue.component('a-number-slider-form-control', NumberSliderFormControl);
Vue.component('a-multi-email-address-textarea-form-control', MultiEmailAddressTextareaFormControl);
Vue.component('a-multi-module-form-control', MultiModuleFormControl);
Vue.component('a-multi-text-form-control', MultiTextFormControl);
Vue.component('a-nutrition-diary-tip-type-form-control', NutritionDiaryTipTypeFormControl);
Vue.component('a-prevention-type-form-control', PreventionTypeFormControl);
Vue.component('a-publication-type-form-control', PublicationTypeFormControl);
Vue.component('a-password-form-control', PasswordFormControl);
Vue.component('a-style-alias-form-control', StyleAliasFormControl);
Vue.component('a-textarea-form-control', TextareaFormControl);
Vue.component('a-text-form-control', TextFormControl);
Vue.component('a-time-form-control', TimeFormControl);
Vue.component('a-user-goal-proposal-form-control', UserGoalProposalFormControl);
Vue.component('a-visible-for-form-control', VisibleForFormControl);
Vue.component('a-weekdays-form-control', WeekdaysFormControl);

// -- Domain form controls
Vue.component('a-ingredients-form-control', IngredientsFormControl);
Vue.component('a-possible-answer-form-control', PossibleAnswersFormControl);
Vue.component('a-preparation-steps-form-control', PreparationStepsFormControl);
Vue.component('a-self-reflection-options-form-control', SelfReflectionOptionsFormControl);
Vue.component('a-strategies-form-control', StrategiesFormControl);
Vue.component('a-user-goal-progresses-form-control', UserGoalProgressesFormControl);
