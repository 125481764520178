import { RouteConfig } from 'vue-router';
import DefaultRedirect from '@/application/whitelabel/default-redirect/components/default-redirect.vue';
import RootRedirect from '@/application/whitelabel/default-redirect/components/root-redirect.vue';
import Logout from '@/application/whitelabel/logout/logout.vue';
import NotFound from '@/application/common/not-found/not-found.vue';
import { legalRoutes } from '@/application/whitelabel/legal/routes';
import { authenticationRoutes } from '@/application/whitelabel/authentication/routes';
import { profileRoutes } from '@/private/user/profile/routes';
import { manageModulesRoutes } from '@/private/management/modules/routes';
import { consumeModulesRoutes } from '@/private/user/modules/routes';
import { manageHabitsRoutes } from '@/private/management/habits/routes';
import { manageHabitIntentsRoutes } from '@/private/user/habits/routes';
import { onboardingRoutes } from '@/private/user/onboarding/routes';
import { userProgressRoutes } from '@/private/user/user-progress/routes';
import { contactSupportRoutes } from '@/private/user/contact-support/routes';
import { bibliographyRoutes } from '@/private/user/publications/routes';
import { managePublicationsRoutes } from '@/private/management/publications/routes';
import { dashboardRoutes } from '@/private/user/dashboard/routes';
import { nutritionDiaryRoutes } from '@/private/user/nutrition-diary/routes';
import { managementDashboardRoutes } from '@/private/management/dashboard/routes';
import { userInvitesRoutes } from '@/private/management/user-invites/routes';
import { unauthorizedContactRoutes } from '@/public/contact/routes';
import { certificateManagementRoutes } from '@/private/management/certificate-management/routes';
import { manageBlogArticlesRoutes } from '@/private/management/blog-articles/routes';
import { consumeBlogArticlesRoutes } from '@/private/user/blog-articles/routes';
import { manageExperienceReportsRoutes } from '@/private/management/experience-reports/routes';
import { consumeExperienceReportsRoutes } from '@/private/user/experience-reports/routes';
import { manageRecipesRoutes } from '@/private/management/recipes/routes';
import { consumeRecipesRoutes } from '@/private/user/recipes/routes';
import { manageDocumentsRoutes } from '@/private/management/documents/routes';
import { consumeDocumentsRoutes } from '@/private/user/documents/routes';
import { manageSponsorsRoutes } from '@/private/management/sponsors/routes';
import { aboutNubaliRoutes } from '@/public/about-nubali/routes';
import { goalTrackingManagementRoutes } from '@/private/management/goal-tracking/routes';
import { manageNutritionDiaryRoutes } from '@/private/management/nutrition-diary/routes';

export const routes: RouteConfig[] = [
  {
    name: 'root',
    path: '/',
    component: RootRedirect,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
    },
  },
  {
    name: 'default',
    path: '/default',
    component: DefaultRedirect,
    meta: {
      requiresAuth: true,
      title: 'Weiterleitung',
    },
  },
  {
    name: 'logout',
    path: '/logout',
    component: Logout,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      title: 'Abmeldung',
    },
  },
  {
    path: '/verwaltung',
    component: {
      template: `<div class="admin"><router-view /></div>`,
    },
    children: [
      {
        path: '',
        redirect: 'module',
      },
      ...manageModulesRoutes,
      ...manageHabitsRoutes,
      ...managePublicationsRoutes,
      ...managementDashboardRoutes,
      ...userInvitesRoutes,
      ...certificateManagementRoutes,
      ...manageBlogArticlesRoutes,
      ...manageExperienceReportsRoutes,
      ...manageRecipesRoutes,
      ...manageDocumentsRoutes,
      ...manageSponsorsRoutes,
      ...goalTrackingManagementRoutes,
      ...manageNutritionDiaryRoutes,
    ],
  },
  ...unauthorizedContactRoutes,
  ...dashboardRoutes,
  ...consumeModulesRoutes,
  ...manageHabitIntentsRoutes,
  ...legalRoutes,
  ...authenticationRoutes,
  ...contactSupportRoutes,
  ...bibliographyRoutes,
  ...profileRoutes,
  ...onboardingRoutes,
  ...userProgressRoutes,
  ...nutritionDiaryRoutes,
  ...consumeBlogArticlesRoutes,
  ...consumeExperienceReportsRoutes,
  ...consumeRecipesRoutes,
  ...consumeDocumentsRoutes,
  ...aboutNubaliRoutes,
  {
    name: 'not-found',
    path: '/:catchAll(.*)',
    component: NotFound,
  },
];
