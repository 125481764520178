<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, mountFormControl, wasValidationSuccessful, labelWithRequiredIndicator, isFieldShownAsContainingAnError } from '@/components/form';
import { VuetifySelectItem } from '@/application/types';
import { Time } from '@/types';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class TimeFormControl extends Vue implements FormControlComponent<Time> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Time>;

  @Prop({ type: Number, default: 5 })
  readonly intervalMinutes!: number;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: Time | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get timesSelectItems(): VuetifySelectItem<Time>[] {
    const relevantTimeFrame = {
      timeFrom: new Time(0, 0, 0),
      timeTo: new Time(23, 55, 0),
    };

    const times = [];
    let lastUsedTime = relevantTimeFrame.timeFrom;
    while (lastUsedTime.isBefore(relevantTimeFrame.timeTo)) {
      times.push(lastUsedTime);
      lastUsedTime = lastUsedTime.add(this.intervalMinutes, 'minutes');
    }

    return times
      .map((time) => ({
        text: time.format('HH:mm'),
        value: time,
      }));
  }

  mounted(): void {
    mountFormControl(this);
  }

  timeChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? this.timesSelectItems[0].value
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<Time> {
    return this.internalValue;
  }

}
</script>
<template>
  <div class="form-control time-form-control" v-bind="$attrs">
    <v-autocomplete
      v-model="internalValue"
      :items="timesSelectItems"
      @input="timeChanged"
      @focus="focused"
      @blur="blurred"
      :label="labelWithRequiredIndicator(formControl)"
      :disabled="isDisabled"
      :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
      menu-props="auto"
      hide-details
      outlined
    />
    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
  </div>
</template>
