import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"habits"},[_c(VCardText,{staticClass:"pb-0"},[_c('h2',[_vm._v("Gewohnheiten")]),(_vm.store.featureExamples && _vm.store.featureExamples.habits.length > 0)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"today-view mt-2 mb-4",attrs:{"outlined":""}},[_c(VList,{staticClass:"py-0"},[_vm._l((_vm.store.featureExamples.habits),function(habit,index){return [_c(VListItem,{key:habit.habitId},[_c(VListItemAction,{staticClass:"mr-3"},[(habit.isCompleted)?_c('font-awesome-icon',{staticClass:"check",attrs:{"icon":['far', 'check-circle']}}):_c('font-awesome-icon',{attrs:{"icon":['far', 'circle']}})],1),_c(VListItemContent,[_c('strong',[_vm._v(_vm._s(habit.title))])])],1),(index !== _vm.store.featureExamples.habits.length - 1)?_c(VDivider,{key:index}):_vm._e()]})],2)],1)],1)],1):_vm._e(),_c('p',[_vm._v(" Passend zu den inhaltlichen Themen der Module bieten wir dir Optionen für neue gesunde Lebensgewohnheiten an, die du direkt im jeweiligen Modul erkunden und planen kannst. ")]),_c('p',[_vm._v(" Damit du deine neuen Gewohnheiten im Alltag nicht vergisst, bieten wir dir die Möglichkeit, dich per "),_c('span',{staticClass:"no-wrap"},[_vm._v("E-Mail")]),_vm._v(" oder Push-Benachrichtigung erinnern zu lassen. ")])],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{on:{"click":_vm.skipClicked}},[_vm._v(" Einführung überspringen ")]),_c('a-trigger-primary-process-button',{on:{"click":_vm.submitClicked}},[_vm._v(" Weiter ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }