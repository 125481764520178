<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { AppType } from '@/types';

@Component
export default class TermsOfUse extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  get isAppTypeSponsor(): boolean {
    return this.authenticationStore.appType === AppType.SPONSOR;
  }

  get isAppTypeCourse(): boolean {
    return this.authenticationStore.appType === AppType.COURSE;
  }

}
</script>
<template>
<v-container class="terms-of-use">
  <v-row v-if="isAppTypeSponsor">
    <v-col md="12" lg="9">

      <h3>
        § 1 Geltungsbereich; Vertragspartner
      </h3>
      <p>
        1.1 Die Vitomy UG (haftungsbeschränkt), Karlstraße 110, 80335 München („Vitomy“) bietet Nutzern den in der App enthaltene Module und weitere Leistungen nach Maßgabe der folgenden Allgemeinen Geschäftsbedingungen ("AGB") an.
      </p>
      <p>
        1.2 Die Vitomy Angebote richten sich ausschließlich an natürliche, volljährige Personen.
      </p>
      <p>
        1.3 Abweichende oder ergänzende Regelungen bei Vertragsschluss gelten nur, wenn sie schriftlich vereinbart sind.
      </p>
      <h3>
        § 2 Urheberrecht
      </h3>
      <p>
        Die über die App angebotenen Inhalte (Texte, Bilder und sonstige Werke) der Vitomy Angebote sind urheberrechtlich geschützt. Vitomy stellt diese dem Nutzer ausschließlich für eigene, private Zwecke zur Verfügung. Eine darüber hinausgehende Nutzung ist dem Nutzer ohne vorherige, schriftliche Zustimmung von Vitomy nicht gestattet.
      </p>
      <h3>
        § 3 Mängel; Haftung
      </h3>
      <p>
        3.1 Die Nutzung der App erfolgt auf eigene Gefahr.
      </p>
      <p>
        3.2 Bei Mängeln stehen dem Nutzer die gesetzlichen Mängelrechte zu.
      </p>
      <p>
        3.3 Die Inhalte der Vitomy Angebote wurden auf Basis des aktuellen Standes von Wissenschaft und Technik durch qualifizierte Fachkräfte erstellt und werden fortlaufend aktualisiert. Die Vitomy Angebote stellen keine medizinische Beratung dar. Soweit sich der Nutzer in ärztliche oder therapeutische Behandlung befindet, gelten die Empfehlungen und Aussagen des Arztes oder des Therapeuten uneingeschränkt und ohne Rücksicht auf die Vitomy Angebote. Sämtliche Inhalte werden vom Nutzer auf eigenes Risiko und auf eigene Gefahr genutzt.
      </p>
      <p>
        3.4 Der Erfolg der Vitomy Angebote beim Nutzer hängt von zahlreichen Faktoren ab (körperliche Konstitution, Gesundheitszustand, Häufigkeit und Intensität der Nutzung der App, etc.). Vitomy übernimmt keine Gewähr dafür, wie sich die individuelle körperliche Konstitution des Nutzers durch die Nutzung der Vitomy Angebote entwickelt.
      </p>
      <p>
        3.5 Der Nutzer ist dafür verantwortlich, seine Zugangsdaten geheim zu halten und vor dem Zugriff durch unbefugte Dritte zu schützen. Sollten dem Nutzer die Zugangsdaten abhandenkommen oder hat er den Verdacht, dass seine Zugangsdaten von einem Dritten genutzt werden, ist der Nutzer angehalten, umgehend ein neues Kennwort für die Vitomy Angebote anzufordern. Vitomy übernimmt keine Haftung für Schäden, die dadurch entstehen, dass die Zugangsdaten in die Hände Dritter gelangen.
      </p>
      <h3>
        § 4 Datenschutzerklärung
      </h3>
      <p>
        Die Datenschutzerklärung kann vor Abschluss der Registrierung nochmals in der App aufgerufen und auch nach der Registrierung unter den Informationen jederzeit eingesehen und in wiedergabefähiger Form gespeichert werden.
      </p>
      <h3>
        § 5 Kontakt
      </h3>
      <p>
        Für Fragen, Reklamationen und Beanstandungen kann Vitomy jederzeit per E-Mail, per Telefon oder Post unter folgenden Kontaktdaten erreicht werden:
      </p>
      <p>
        Vitomy UG (haftungsbeschränkt)<br />
        Karlstraße 110<br />
        80335 München<br />
        Deutschland
      </p>
      <p>
        Telefon: +49 8761 3989630<br />
        E-Mail: <a href="mailto:info@vitomy.de" target="_blank">info@vitomy.de</a>
      </p>
      <h3>
        § 6 Außergerichtliche Streitbeilegung
      </h3>
      <p>
        6.1 Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung bereit. Diese gibt dem Nutzer die Möglichkeit, Streitigkeiten im Zusammenhang mit einer Online-Bestellung zunächst ohne Einschaltung eines Gerichts zu klären. Nähere Informationen sind unter folgendem Link verfügbar: http://ec.europa.eu/consumers/odr/.
      </p>
      <p>
        6.2 Vitomy ist grundsätzlich nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <h3>
        § 7 Schlussbestimmungen
      </h3>
      <p>
        7.1 Auf den Vertrag zwischen Vitomy und dem Nutzer findet das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts Anwendung. Diese Rechtswahl gilt nur insoweit, als dass dadurch nicht zwingende anwendbare Verbraucherschutzvorschriften des Staates, in dem der Verbraucher zum Zeitpunkt des Vertragsschlusses seinen gewöhnlichen Aufenthalt hat, entzogen werden.
      </p>
      <p>
        7.2 Soweit der Nutzer keinen allgemeinen Gerichtsstand im Inhalt hat, nach Vertragsschluss seinen Wohnsitz oder gewöhnlichen Aufenthaltsort aus dem Inhalt verlegt oder sein Wohnsitz oder gewöhnlicher Aufenthaltsort zum Zeitpunkt der Klageerhebung nicht bekannt ist, ist ausschließlicher Gerichtsstand für sämtliche Ansprüche aus dem Vertragsverhältnis München.
      </p>
      <p class="mb-0">
        7.3 Die Nutzungsbedingungen bleiben auch bei rechtlicher Unwirksamkeit einzelnen Punkte in ihren übrigen Teilen verbindlich.
      </p>

    </v-col>
  </v-row>

  <v-row v-if="isAppTypeCourse">
    <v-col md="12" lg="9">

      <h3>
        § 1 Geltungsbereich; Vertragspartner
      </h3>
      <p>
        1.1 Die Vitomy UG (haftungsbeschränkt), Karlstraße 110, 80335 München („Vitomy“) bietet Nutzern den in der App enthaltenen Kurs und weitere Leistungen nach Maßgabe der folgenden Allgemeinen Geschäftsbedingungen ("AGB") an.
      </p>
      <p>
        1.2 Die Vitomy Angebote richten sich ausschließlich an natürliche, volljährige Personen.
      </p>
      <p>
        1.3 Abweichende oder ergänzende Regelungen bei Vertragsschluss gelten nur, wenn sie schriftlich vereinbart sind.
      </p>
      <h3>
        § 2 Vertragsschluss
      </h3>
      <p>
        2.1 Im Rahmen des der Bestellung vorgeschalteten Registrierungsprozesses wird der Nutzer aufgefordert, seine E-Mail-Adresse anzugeben und ein Passwort festzulegen sowie im Anschluss den Ausschluss von medizinischen Kontraindikationen zu bestätigen. Darüber hinaus muss der Nutzer die Nutzungsbedingungen von Vitomy akzeptieren. Die Nutzungsbedingungen können vor Abschluss der Registrierung nochmals in der App aufgerufen und auch nach der Registrierung unter den Einstellung jederzeit eingesehen und in wiedergabefähiger Form gespeichert werden.
      </p>
      <p>
        2.2 Nach Registrierung erhält der Nutzer eine Übersicht der enthaltenen Module im Kurs. Um zu den Kursinhalten zu gelangen, muss der Nutzer den Kurs zahlungspflichtig erwerben. Mit Betätigung des Buttons "Jetzt kaufen" gibt der Nutzer ein rechtlich bindendes Angebot zum Abschluss eines Vertrages über den Kurs gegenüber Vitomy ab. Der Vertrag über den Kurs kommt durch die Annahmeerklärung von Vitomy zustande, die nach Zahlung des Kurses von Vitomy gegenüber dem Nutzer angenommen wird. Die Bezahlung wird gemäß Ziffer 4 über den Stripe In-App Payment Service unter der Bedingungen der Stripe Inc. oder In-App Kauf abgewickelt.
      </p>
      <p>
        2.3 Der Inhalt des Vertrags zwischen Vitomy und dem Nutzer bestimmt sich nach diesen Nutzungsbedingungen in ihrer bei Abschluss des Vertrags aktuellsten Fassung. Vitomy behält sich vor, die Nutzungsbedingungen zu ändern, soweit diese Änderungen für den Nutzer zumutbar sind. Die geänderten Nutzungsbedingungen werden dem Nutzer vorab per E-Mail mit Hinweis darauf, dass der Nutzer den geänderten Nutzungsbedingungen innerhalb einer Frist von sechs (6) Wochen nach Zugang des E-Mails schriftlich widersprechen kann, zugeschickt. Widerspricht der Nutzer den geänderten Nutzungsbedingungen nicht innerhalb von sechs (6) Wochen nach Übersendung, gelten sie als angenommen. Widerspricht der Nutzer den geänderten Nutzungsbedingungen innerhalb dieser Frist, gelten für den Nutzer die ursprünglichen Nutzungsbedingungen fort.
      </p>
      <h3>
        § 3 Preis; Zahlungsbedingungen
      </h3>
      <p>
        3.1 Durch das reine Herunterladen der App entstehen für den Nutzer keine Kosten.
      </p>
      <p>
        3.2 Der Vertrag über den Online-Kurs ist kostenpflichtig. Der Preis bestimmt sich nach den bei der Bestellung gültigen Preisangaben in der App. Der Preis ist in Euro aufgeführt und enthält die jeweilige gesetzliche Umsatzsteuer. Die Einmalzahlung ist mit Zustandekommen des Vertrags im Voraus zur Zahlung fällig.
      </p>
      <p>
        3.3 Die Zahlungen werden als In-App Käufe über den jeweiligen App Store des Nutzers abgewickelt. Der Nutzer muss den weiteren allgemeinen Geschäftsbedingen des jeweiligen App Stores zustimmen.
      </p>
      <p>
        3.4 Kann ein Entgelt von einem Nutzer nicht eingezogen werden, trägt der Nutzer sämtliche daraus entstehenden Kosten, insbesondere Bankgebühren oder vergleichbare Gebühren. Vitomy behält sich in diesem Fall vor, den Zugang des Nutzers zu sperren.
      </p>
      <h3>
        § 4 Rückerstattung der Kosten
      </h3>
      <p>
        Die Vitomy Angebote können als Präventionsmaßnahmen grundsätzlich nach § 20 SGB V von gesetzlichen Krankenkassen ganz oder teilweise rückerstattet werden. Die Bedingungen und die Höhe einer Rückerstattung muss der Nutzer mit seiner Krankenkasse abklären. Vitomy übernimmt keine Haftung im Zusammenhang mit der Rückerstattung der Kosten durch gesetzliche oder private Krankenkassen.
      </p>
      <h3>
        § 5 Urheberrecht
      </h3>
      <p>
        Die über die App angebotenen Inhalte (Texte, Bilder und sonstige Werke) der Vitomy Angebote sind urheberrechtlich geschützt. Vitomy stellt diese dem Nutzer ausschließlich für eigene, private Zwecke zur Verfügung. Eine darüber hinausgehende Nutzung ist dem Nutzer ohne vorherige, schriftliche Zustimmung von Vitomy nicht gestattet.
      </p>
      <h3>
        § 6 Laufzeit; Beendigung des Vertrags; Geld-zurück-Garantie
      </h3>
      <p>
        6.1 Der Vertrag über den Online-Kurs wird auf unbestimmte Zeit geschlossen.
      </p>
      <p>
        6.2 Der Nutzer kann den Vertrag über den Online-Kurs jederzeit ohne Einhaltung einer Frist und ohne Angabe von Gründen kündigen. Kündigt der Nutzer innerhalb der ersten vierzehn (14) Tage nach Vertragsschluss, erhält er das geleistete Entgelt vollständig zurück ("Geld-zurück-Garantie"). Kündigt der Nutzer nach Ablauf der ersten vierzehn (14) Tage nach Vertragsschluss, besteht kein Anspruch des Nutzers auf Rückerstattung des geleisteten Entgelts. Die Geld-zurück-Garantie hat keinen Einfluss auf das Widerrufsrecht des Nutzers gemäß Ziffer 10. Vitomy kann den Vertrag über den Online-Kurs nach Ablauf von 4 Monaten nach Vertragsschluss mit einer Frist von einem (1) Monat zum Monatsende kündigen.
      </p>
      <p>
        6.3 Das Recht zur außerordentlichen Kündigung bleibt von den vorstehenden Regelungen unberührt.
      </p>
      <p>
        6.4 Mit Wirksamwerden der Kündigung, ist Vitomy nicht verpflichtet, die App oder die darin enthaltenen Vitomy Angebote weiter vorzuhalten.
      </p>
      <p>
        6.5 Die Kündigung kann schriftlich oder per E-Mail erfolgen.
      </p>
      <h3>
        § 7 Mängel; Haftung
      </h3>
      <p>
        7.1 Die Nutzung der App erfolgt auf eigene Gefahr.
      </p>
      <p>
        7.2 Bei Mängeln stehen dem Nutzer die gesetzlichen Mängelrechte zu.
      </p>
      <p>
        7.3 Die Kursinhalte der Vitomy Angebote wurden auf Basis des aktuellen Standes von Wissenschaft und Technik durch qualifizierte Fachkräfte erstellt und werden fortlaufend aktualisiert. Die Vitomy Angebote stellen keine medizinische Beratung dar. Soweit sich der Nutzer in ärztliche oder therapeutische Behandlung befindet, gelten die Empfehlungen und Aussagen des Arztes oder des Therapeuten uneingeschränkt und ohne Rücksicht auf die Vitomy Angebote. Sämtliche Inhalte werden vom Nutzer auf eigenes Risiko und auf eigene Gefahr genutzt.
      </p>
      <p>
        7.4 Der Erfolg der Vitomy Angebote beim Nutzer hängt von zahlreichen Faktoren ab (körperliche Konstitution, Gesundheitszustand, Häufigkeit und Intensität der Nutzung der App, etc.). Vitomy kann keine Gewähr dafür übernehmen, wie sich die individuelle körperliche Konstitution des Nutzers durch die Nutzung der Vitomy Angebote entwickelt.
      </p>
      <p>
        7.5 Der Nutzer ist dafür verantwortlich, seine Zugangsdaten geheim zu halten und vor dem Zugriff durch unbefugte Dritte zu schützen. Sollten dem Nutzer die Zugangsdaten abhandenkommen oder hat er den Verdacht, dass seine Zugangsdaten von einem Dritten genutzt werden, ist der Nutzer angehalten, umgehend ein neues Kennwort für die Vitomy Angebote anzufordern. Vitomy übernimmt keine Haftung für Schäden, die dadurch entstehen, dass die Zugangsdaten in die Hände Dritter gelangen.
      </p>
      <p>
        7.6 Bei leichter Fahrlässigkeit haftet Vitomy nur bei Verletzung vertragswesentlicher Pflichten (Kardinalpflichten) also solcher, die der Vertrag nach seinem Inhalt und Zweck Vitomy gerade auferlegen will oder deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf. Diese Haftung ist auf den bei Vertragsabschluss vorhersehbaren typischen Schaden begrenzt.
      </p>
      <p>
        7.7 Die vorgenannten Haftungsbeschränkungen gelten nicht bei vorsätzlich oder grob fahrlässig verursachten Schäden, bei schuldhaft verursachten Körperschäden, für die Haftung nach dem Produkthaftungsgesetz sowie für den Fall weiterer zwingender Haftungstatbestände.
      </p>
      <h3>
        § 8 Widerrufsrecht
      </h3>
      <p>
        Als Verbraucher im Sinne von § 13 BGB hat der Nutzer im Falle des Vertragsschlusses ein vierzehn (14)-tägiges Widerrufsrecht. Verbraucher gemäß § 13 BGB ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Im Folgenden wird der Nutzer über sein Widerrufsrecht belehrt.
      </p>
      <h4>
        Widerrufsbelehrung
      </h4>
      <strong class="d-block">
        Widerrufsrecht
      </strong>
      <p>
        Sie haben das Recht, binnen vierzehn (14) Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.<br />
        Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.<br />
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Vitomy UG (haftungsbeschränkt), Karlstraße 110, 80335 München, Telefonnummer: +49 8761 3989630, E-Mail-Adresse: info@vitomy.de) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder ein E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.<br />
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
      </p>
      <strong class="d-block">
        Folgen des Widerrufs
      </strong>
      <p>
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
      </p>
      <strong class="d-block">
        Muster-Widerrufsformular
      </strong>
      <p>
        (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
      </p>
      <ul>
        <li>
          An Vitomy UG (haftungsbeschränkt), Karlstraße 110, 80335 München, E-Mail-Adresse: info@vitomy.de:
        </li>
        <li>
          Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)
        </li>
        <li>
          Bestellt am (*)/erhalten am (*)
        </li>
        <li>
          Name des/der Verbraucher(s)
        </li>
        <li>
          Anschrift des/der Verbraucher(s)
        </li>
        <li>
          Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
        </li>
        <li>
          Datum
        </li>
      </ul>
      <p>
        (*) Unzutreffendes streichen.
      </p>
      <h3>
        § 9 Datenschutzerklärung
      </h3>
      <p>
        Die Datenschutzerklärung kann vor Abschluss der Registrierung nochmals in der App aufgerufen und auch nach der Registrierung unter den Informationen jederzeit eingesehen und in wiedergabefähiger Form gespeichert werden.
      </p>
      <h3>
        § 10 Kontakt
      </h3>
      <p>
        Für Fragen, Reklamationen und Beanstandungen kann Vitomy jederzeit per E-Mail, per Telefon oder Post unter folgenden Kontaktdaten erreicht werden:
      </p>
      <p>
        Vitomy UG (haftungsbeschränkt)<br />
        Karlstraße 110<br />
        80335 München<br />
        Deutschland
      </p>
      <p>
        Telefon: +49 8761 3989630<br />
        E-Mail: <a href="mailto:info@vitomy.de" target="_blank">info@vitomy.de</a>
      </p>
      <h3>
        § 11 Außergerichtliche Streitbeilegung
      </h3>
      <p>
        11.1 Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung bereit. Diese gibt dem Nutzer die Möglichkeit, Streitigkeiten im Zusammenhang mit einer Online-Bestellung zunächst ohne Einschaltung eines Gerichts zu klären. Nähere Informationen sind unter folgendem Link verfügbar: http://ec.europa.eu/consumers/odr/.
      </p>
      <p>
        11.2 Vitomy ist grundsätzlich nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <h3>
        § 12 Schlussbestimmungen
      </h3>
      <p>
        12.1 Auf den Vertrag zwischen Vitomy und dem Nutzer findet das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts Anwendung. Diese Rechtswahl gilt nur insoweit, als dass dadurch nicht zwingende anwendbare Verbraucherschutzvorschriften des Staates, in dem der Verbraucher zum Zeitpunkt des Vertragsschlusses seinen gewöhnlichen Aufenthalt hat, entzogen werden.
      </p>
      <p>
        12.2 Soweit der Nutzer keinen allgemeinen Gerichtsstand im Inhalt hat, nach Vertragsschluss seinen Wohnsitz oder gewöhnlichen Aufenthaltsort aus dem Inhalt verlegt oder sein Wohnsitz oder gewöhnlicher Aufenthaltsort zum Zeitpunkt der Klageerhebung nicht bekannt ist, ist ausschließlicher Gerichtsstand für sämtliche Ansprüche aus dem Vertragsverhältnis München.
      </p>
      <p class="mb-0">
        12.3 Die Nutzungsbedingungen bleiben auch bei rechtlicher Unwirksamkeit einzelnen Punkte in ihren übrigen Teilen verbindlich.
      </p>

    </v-col>
  </v-row>

</v-container>
</template>
<style lang="sass" scoped>
.terms-of-use
  font-size: 0.875rem
</style>
