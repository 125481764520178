import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.dialogMaxWidth,"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"transition":_vm.$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null,"hide-overlay":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"d-block"},on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[(_vm.tip)?_c(VCard,[_c(VCardTitle,[_vm._v("Neuer Tipp")]),_c(VCardText,{staticClass:"pt-0 pb-4"},[_c('strong',[_vm._v(_vm._s(_vm.type))]),_vm._v(": "+_vm._s(_vm.tip.description)+" ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-trigger-primary-process-button',{on:{"click":_vm.closeDialog}},[_vm._v(" Schließen ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }