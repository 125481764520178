import { CommandWithFiles } from '@/application/types';
import { getLocalStorageItem, LOCAL_STORAGE_ADMIN_ID, LOCAL_STORAGE_APP_ID, LOCAL_STORAGE_USER_ID } from '@/helpers/local-storage-helper';

export interface AppAndUserProperty {
  appId: string;
  userId: string;
}

export interface AppProperty {
  appId: string;
}

export interface UserProperty {
  userId: string;
}

export interface AdminProperty {
  adminId: string;
}

export interface AppAndUserPropertyForCommandWithFiles {
  body: {
    appId: string;
    userId: string;
  };
}

export function attachAppAndUser<T>(body: T): T & AppAndUserProperty {
  return {
    ...body,
    appId: getLocalStorageItem(LOCAL_STORAGE_APP_ID)!,
    userId: getLocalStorageItem(LOCAL_STORAGE_USER_ID)!,
  };
}

export function attachUser<T>(body: T): T & UserProperty {
  return {
    ...body,
    userId: getLocalStorageItem(LOCAL_STORAGE_USER_ID)!,
  };
}

export function attachApp<T>(body: T): T & AppProperty {
  return {
    ...body,
    appId: getLocalStorageItem(LOCAL_STORAGE_APP_ID)!,
  };
}

export function attachAdmin<T>(body: T): T & AdminProperty {
  return {
    ...body,
    adminId: getLocalStorageItem(LOCAL_STORAGE_ADMIN_ID)!,
  };
}

export function attachAppAndUserToCommandWithFiles<T extends CommandWithFiles>(
  command: T
): T & AppAndUserPropertyForCommandWithFiles {
  return {
    ...command,
    body: {
      ...command.body,
      appId: getLocalStorageItem(LOCAL_STORAGE_APP_ID)!,
      userId: getLocalStorageItem(LOCAL_STORAGE_USER_ID)!,
    },
  };
}
