import Vue from 'vue';

export function formatHours(value: any): string {
  if (typeof value !== 'number') {
    return value;
  }

  return value.toString().replace('.', ',');
}

Vue.filter('formatHours', formatHours);
