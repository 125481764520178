import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AdminProperty } from '@/helpers/default-parameter-helper';
import { App, CreateAppCommand, UpdateAppCommand } from './types';

export function getApps(query: AdminProperty): Promise<App[]> {
  const url = `${apiUrl}/admin-api/app-management/get-apps-query`;
  return performApiRequest<App[]>({ url, method: 'POST', data: query });
}

export function createApp(command: CreateAppCommand & AdminProperty): Promise<void> {
  const url = `${apiUrl}/admin-api/app-management/create-app-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateApp(command: UpdateAppCommand & AdminProperty): Promise<void> {
  const url = `${apiUrl}/admin-api/app-management/update-app-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
