<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useUserProgressStore } from '@/private/user/user-progress/store';
import { constructForm, Form, FormControl, FormControls, getFormValues, minArrayCountRule } from '@/components/form';
import { Time, Weekday } from '@/types';
import { useManageHabitIntentsStore } from '../store';
import { CreateHabitIntentCommand } from '../types';

interface Controls extends FormControls {
  trigger: FormControl<string>;
  weekdays: FormControl<Weekday[]>;
  notificationTime: FormControl<Time>;
}

@Component
export default class CreateHabitIntentDialog extends Vue {

  readonly store = useManageHabitIntentsStore();
  readonly userProgressStore = useUserProgressStore();

  @Prop({ type: String, required: true })
  readonly habitId!: string;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        trigger: {
          label: 'Nach was willst du die Aktivität ausführen?',
          value: null,
        },
        weekdays: {
          label: 'An welchen Tagen?',
          value: [],
          isRequired: true,
          rules: [
            minArrayCountRule(1, 'Es muss mindestens ein Wochentag ausgewählt werden'),
          ],
        },
        notificationTime: {
          label: 'Wann sollen wir dich erinnern?',
          value: Time.fromString('12:00:00'),
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateHabitIntentCommand = {
      habitId: this.habitId,
      trigger: formValues.trigger,
      weekdays: formValues.weekdays!,
      notificationTime: formValues.notificationTime!,
    };

    this.store.createHabitIntent(command)
      .then(() => this.userProgressStore.getUserProgress())
      .then(() => this.$emit('habit-intent-created'))
      .then(() => showSuccessMessage('Gewohnheit wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateHabitIntentProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Gewohnheit erstellen</v-card-title>
      <v-card-text>

        <p>
          Gewohnheiten lassen sich am leichtesten in den Alltag integrieren, wenn du sie mit bestehenden Verhaltensweisen verknüpfst. Wenn dir jetzt bereits etwas zu einfällt, dann vermerke es dir gleich hier. Ansonsten kannst du es auch später noch eintragen.
        </p>
        <a-text-form-control
          :form-control="form.controls.trigger"
          placeholder="Z.B. Nach dem Aufstehen"
          is-autofocused
        />
        <a-weekdays-form-control
          :form-control="form.controls.weekdays"
          placeholder="Wähle 2 - 4 Tage"
        />

        <a-time-form-control :form-control="form.controls.notificationTime" />

      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateHabitIntentProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateHabitIntentProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Erstellen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
