import { RouteConfig } from 'vue-router';
import AppList from './components/app-list.vue';

export const adminManageAppsRoutes: RouteConfig[] = [
  {
    path: 'apps',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'admin-manage-apps-app-list',
        path: '/',
        component: AppList,
        meta: {
          requiresAuth: true,
          title: 'Apps verwalten',
          breadcrumbs: [
            { text: 'Apps verwalten' },
          ],
        },
      },
    ],
  },
];
