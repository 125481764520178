import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AdminProperty } from '@/helpers/default-parameter-helper';
import { GetImpersonationURLForUserQuery, GetUsersForAppQuery, ImpersonationConfiguration, User } from './types';

// -- Queries

export function getUsersForApp(query: GetUsersForAppQuery & AdminProperty): Promise<User[]> {
  const url = `${apiUrl}/admin-api/user-management/get-users-for-app-query`;
  return performApiRequest<User[]>({ url, method: 'POST', data: query });
}

export function getImpersonationURLForUser(query: GetImpersonationURLForUserQuery & AdminProperty): Promise<ImpersonationConfiguration> {
  const url = `${apiUrl}/admin-api/user-management/get-impersonation-url-for-user-query`;
  return performApiRequest<ImpersonationConfiguration>({ url, method: 'POST', data: query });
}
