<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showError, showSuccessMessage } from '@/application/common/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useManageHabitIntentsStore } from '../store';
import { HabitIntent, DisableHabitIntentCommand } from '../types';

@Component
export default class DisableHabitIntentDialog extends Vue {

  readonly store = useManageHabitIntentsStore();

  @Prop({ type: Object, required: true })
  readonly habitIntent!: HabitIntent;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  disableHabitIntentConfirmed(): void {
    const command: DisableHabitIntentCommand = {
      habitIntentId: this.habitIntent.habitIntentId,
    };

    this.store.disableHabitIntent(command)
      .then(() => showSuccessMessage('Gewohnheit wurde deaktiviert'))
      .then(() => this.closeDialog())
      .catch((error) => {
        const message = error.response?.data.message || 'Gewohnheit konnte nicht deaktiviert werden';
        showError({ message });
      });
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isDisableHabitIntentProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <v-card-title>Gewohnheit deaktivieren</v-card-title>
    <v-card-text>
      <p>Willst du die Gewohnheit <strong>{{ habitIntent.title }}</strong> wirklich deaktivieren?</p>
      <p v-if="!habitIntent.isMinRuntimeReached">
        Eine positive Entwicklung der Gewohnheit sollte nach {{ habitIntent.minRuntime }} Wochen ab dem {{ habitIntent.minRuntimeReachedAt | formatDate }} zu spüren sein.
      </p>
      <p><strong>Beim Deaktivieren wird dein Fortschritt mit dieser Gewohnheit zurückgesetzt!</strong></p>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <a-cancel-action-button
        :is-processing="store.isDisableHabitIntentProcessing"
        @click="closeDialog"
      />
      <a-execute-dangerous-action-button
        @click="disableHabitIntentConfirmed"
        :is-processing="store.isDisableHabitIntentProcessing"
      >
        Deaktivieren
      </a-execute-dangerous-action-button>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
