<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { AppType } from '@/types';
import { ContentElementHabitProposalForPreview } from '../types';

@Component
export default class HabitProposalPreview extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Object, required: true })
  readonly contentElement!: ContentElementHabitProposalForPreview;

  get isZPPRequiredNoticeVisible(): boolean {
    return this.authenticationStore.appType === AppType.COURSE;
  }

}
</script>
<template>
<div class="habit-proposal">
  <strong class="d-block mb-1">Neue Gewohnheit starten:<br class="d-block d-md-none" /> {{ contentElement.habitTitle }}</strong>
  <p v-if="contentElement.habitIntroduction">
    {{ contentElement.habitIntroduction }}
  </p>
  <a-trigger-secondary-process-button is-always-shown-in-block-mode>
    Gewohnheit erstellen
  </a-trigger-secondary-process-button>
  <p class="mb-0 mt-4" v-if="isZPPRequiredNoticeVisible">
    Um neue Verhaltensweisen in deinem Alltag zu verankern, empfehlen wir dir, deine Gewohnheiten hier zu tracken. Das ist optional und du kannst den Präventionskurs auch abschließen, ohne dies zu tun.
  </p>
</div>
</template>
<style lang="sass" scoped>
.habit-proposal
  background: var(--color-grey-9)
  padding: 1.25rem 1rem
  border: solid var(--color-grey-8)
  border-width: 1px 0
  color: var(--color-grey-3)

  ::v-deep
    p
      font-size: 1rem
      color: var(--color-grey-3)

  strong
    font-weight: 700
    font-family: Montserrat, sans-serif
    color: var(--color-grey-2)

  .v-btn
    cursor: not-allowed
</style>
