import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { Recipe } from './types';

// -- Queries

export function getRecipes(query: AppAndUserProperty): Promise<Recipe[]> {
  const url = `${apiUrl}/api/recipes/get-recipes-query`;
  return performApiRequest<Recipe[]>({ url, method: 'POST', data: query });
}
