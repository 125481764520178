<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RootRedirect extends Vue {

  mounted(): void {
    this.$router.push({ name: 'authentication-login', query: this.$route.query });
  }

}
</script>
<template>
<div id="root-redirect"></div>
</template>
