import { apiUrl, performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { AppAndUserProperty, AppAndUserPropertyForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { BlogArticle, BlogArticleListItem, CreateBlogArticleCommand, CreateContentElementImageCommand, CreateContentElementImageWithDescriptionCommand, CreateContentElementRichTextCommand, CreateContentElementStrategiesAsManagerCommand, CreateContentElementTipBoxCommand, DeleteContentElementCommand, GetBlogArticleAsManagerQuery, MoveContentElementDownCommand, MoveContentElementUpCommand, PublishBlogArticleCommand, UnpublishBlogArticleCommand, UpdateBlogArticleCommand, UpdateContentElementImageCommand, UpdateContentElementImageWithDescriptionCommand, UpdateContentElementRichTextCommand, UpdateContentElementStrategiesAsManagerCommand, UpdateContentElementTipBoxCommand } from './types';

// -- Queries

export function getBlogArticles(query: AppAndUserProperty): Promise<BlogArticleListItem[]> {
  const url = `${apiUrl}/api/blog/get-blog-articles-for-management-query`;
  return performApiRequest<BlogArticleListItem[]>({ url, method: 'POST', data: query });
}

export function getBlogArticle(query: GetBlogArticleAsManagerQuery & AppAndUserProperty): Promise<BlogArticle> {
  const url = `${apiUrl}/api/blog/get-blog-article-as-manager-query`;
  return performApiRequest<BlogArticle>({ url, method: 'POST', data: query });
}

// -- Commands

export function createBlogArticle(command: CreateBlogArticleCommand & AppAndUserPropertyForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/blog/create-blog-article-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateBlogArticle(command: UpdateBlogArticleCommand & AppAndUserPropertyForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/blog/update-blog-article-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function publishBlogArticle(command: PublishBlogArticleCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/blog/publish-blog-article-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function unpublishBlogArticle(command: UnpublishBlogArticleCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/blog/unpublish-blog-article-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createContentElementRichText(command: CreateContentElementRichTextCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/blog/create-content-element-rich-text-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateContentElementRichText(command: UpdateContentElementRichTextCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/blog/update-content-element-rich-text-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createContentElementTipBox(command: CreateContentElementTipBoxCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/blog/create-content-element-tip-box-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateContentElementTipBox(command: UpdateContentElementTipBoxCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/blog/update-content-element-tip-box-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createContentElementImage(
  command: CreateContentElementImageCommand & AppAndUserPropertyForCommandWithFiles
): Promise<void> {
  const url = `${apiUrl}/api/blog/create-content-element-image-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateContentElementImage(
  command: UpdateContentElementImageCommand & AppAndUserPropertyForCommandWithFiles
): Promise<void> {
  const url = `${apiUrl}/api/blog/update-content-element-image-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function createContentElementImageWithDescription(
  command: CreateContentElementImageWithDescriptionCommand & AppAndUserPropertyForCommandWithFiles
): Promise<void> {
  const url = `${apiUrl}/api/blog/create-content-element-image-with-description-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateContentElementImageWithDescription(
  command: UpdateContentElementImageWithDescriptionCommand & AppAndUserPropertyForCommandWithFiles
): Promise<void> {
  const url = `${apiUrl}/api/blog/update-content-element-image-with-description-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function createContentElementStrategies(
  command: CreateContentElementStrategiesAsManagerCommand & AppAndUserProperty
): Promise<void> {
  const url = `${apiUrl}/api/blog/create-content-element-strategies-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateContentElementStrategies(
  command: UpdateContentElementStrategiesAsManagerCommand & AppAndUserProperty
): Promise<void> {
  const url = `${apiUrl}/api/blog/update-content-element-strategies-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function moveContentElementDown(command: MoveContentElementDownCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/blog/move-content-element-down-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function moveContentElementUp(command: MoveContentElementUpCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/blog/move-content-element-up-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteContentElement(command: DeleteContentElementCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/blog/delete-content-element-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
