import { FileResponse } from '@/application/types';
import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { getDocumentFile, getDocuments, sendDocumentViaEmail } from './service';
import { Document, GetDocumentFileQuery, SendDocumentViaEmailCommand } from './types';

interface ConsumeDocumentsState {
  documents: Document[];

  getDocumentsStatus: ActionStatus;
  getDocumentFileStatus: ActionStatus;
  sendDocumentViaEmailStatus: ActionStatus;
}

function initialState(): ConsumeDocumentsState {
  return {
    documents: [],

    getDocumentsStatus: ActionStatus.None,
    getDocumentFileStatus: ActionStatus.None,
    sendDocumentViaEmailStatus: ActionStatus.None,
  };
}

export const useConsumeDocumentsStore = defineStore('consumeDocuments', {
  state: (): ConsumeDocumentsState => initialState(),
  getters: {
    isGetDocumentsProcessing: (state: ConsumeDocumentsState): boolean =>
      state.getDocumentsStatus === ActionStatus.InProgress,
    isGetDocumentFileProcessing: (state: ConsumeDocumentsState): boolean =>
      state.getDocumentFileStatus === ActionStatus.InProgress,
    isSendDocumentViaEmailProcessing: (state: ConsumeDocumentsState): boolean =>
      state.sendDocumentViaEmailStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getDocuments(): Promise<void> {
      const { getDocumentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDocumentsStatus,
        () => getDocuments(attachAppAndUser({}))
          .then(async (documents) => {
            this.documents = documents;
          })
      );
    },

    getDocumentFile(query: GetDocumentFileQuery): Promise<FileResponse> {
      const { getDocumentFileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDocumentFileStatus,
        () => getDocumentFile(attachAppAndUser(query))
      );
    },

    // -- Commands

    sendDocumentViaEmail(command: SendDocumentViaEmailCommand): Promise<void> {
      const { sendDocumentViaEmailStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        sendDocumentViaEmailStatus,
        () => sendDocumentViaEmail(attachAppAndUser(command))
      );
    },

  },
});
