<script lang="ts">
import { Component } from 'vue-property-decorator';
import { BaseDialogComponent } from '@/components';
import { requestPermissionForPushNotifications } from '@/helpers/push-notifications-helper';

@Component
export default class IntroducePushNotificationsDialog extends BaseDialogComponent {

  requestPermission(): void {
    requestPermissionForPushNotifications();
    this.hide();
  }

}
</script>
<template>
<v-dialog
  v-model="dialog"
  :max-width="size"
  persistent
>
  <v-card>
    <v-card-title>Benachrichtigungen aktivieren</v-card-title>
    <v-card-text>
      <p>
        Wir schicken Ihnen eine Benachrichtigung sobald ein neues Modul freigeschalten wird und erinnern Sie an Ihre Gewohnheiten.
      </p>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <a-execute-primary-action-button @click="requestPermission">
        Verstanden
      </a-execute-primary-action-button>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
