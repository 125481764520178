<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import debounce from 'lodash-es/debounce';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

@Component({
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class TextFormControl extends Vue implements FormControlComponent<string> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<string>;

  @Prop({ type: Number, default: 0 })
  readonly debounceInterval!: number;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isClearable!: boolean;

  @Prop({ type: String, default: null })
  readonly placeholder!: string | null;

  @Prop({ type: String, default: null })
  readonly dataCy!: string | null;

  readonly formControlId = createFormControlId();

  readonly debounceLocalValue = debounce(this.textChanged, this.debounceInterval);

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  textChanged(): void {
    if (this.internalValue === null) {
      this.internalValue = '';
    }

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ''
      : this.formControl.value.trim();
  }

  formValueFromInternalValues(): FormControlValue<string> {
    return this.internalValue.trim().length > 0
      ? this.internalValue.trim()
      : null;
  }

}
</script>
<template>
<div class="form-control text-form-control" v-bind="$attrs">
  <div class="form-control-input">
    <v-text-field
      type="text"
      v-model="internalValue"
      :label="labelWithRequiredIndicator(formControl)"
      @input="debounceLocalValue"
      @focus="focused"
      @blur="blurred"
      :autofocus="isAutofocused ? $vuetify.breakpoint.mdAndUp : false"
      :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
      :clearable="isClearable"
      :placeholder="placeholder"
      outlined
      hide-details="auto"
      :data-cy="dataCy"
      autocomplete="OFF"
    />
    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
  </div>
</div>
</template>
