<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import CreateModuleDialog from './create-module-dialog.vue';
import EditModuleTitleDialog from './edit-module-title-dialog.vue';
import EditModuleImageDialog from './edit-module-image-dialog.vue';
import DeleteModuleDialog from './delete-module-dialog.vue';
import { useManageModulesStore } from '../store';
import { DisableModuleCommand, EnableModuleCommand, ModuleListItem, MoveModuleDownCommand, MoveModuleUpCommand } from '../types';

@Component({
  components: {
    CreateModuleDialog,
    EditModuleTitleDialog,
    EditModuleImageDialog,
    DeleteModuleDialog,
  },
})
export default class ModuleList extends Vue {

  readonly store = useManageModulesStore();

  get isModuleMoveInProgress(): boolean {
    return this.store.isMoveModuleUpProcessing
      || this.store.isMoveModuleDownProcessing;
  }

  mounted(): void {
    this.store.getModules()
      .catch((error) => showErrorResponse(error));
  }

  isModuleMoveUpDisabled(index: number): boolean {
    return index === 0
      || this.isModuleMoveInProgress
      || this.store.isGetModulesProcessing;
  }

  isModuleMoveDownDisabled(index: number): boolean {
    return index >= this.store.moduleList.length - 1
      || this.isModuleMoveInProgress
      || this.store.isGetModulesProcessing;
  }

  isEnableModuleShown(module: ModuleListItem): boolean {
    return !module.isEnabled;
  }

  isDisableModuleShown(module: ModuleListItem): boolean {
    return module.isEnabled;
  }

  onMoveModuleUpClicked(module: ModuleListItem): void {
    const command: MoveModuleUpCommand = {
      moduleId: module.moduleId,
    };

    this.store.moveModuleUp(command)
      .then(() => showSuccessMessage('Modul wurde verschoben'))
      .catch((error) => showErrorResponse(error));
  }

  onMoveModuleDownClicked(module: ModuleListItem): void {
    const command: MoveModuleDownCommand = {
      moduleId: module.moduleId,
    };

    this.store.moveModuleDown(command)
      .then(() => showSuccessMessage('Modul wurde verschoben'))
      .catch((error) => showErrorResponse(error));
  }

  onEnableModuleClicked(module: ModuleListItem): void {
    const command: EnableModuleCommand = {
      moduleId: module.moduleId,
    };

    this.store.enableModule(command)
      .then(() => showSuccessMessage('Module wurde aktiviert'))
      .catch((error) => showErrorResponse(error));
  }

  onDisableModuleClicked(module: ModuleListItem): void {
    const command: DisableModuleCommand = {
      moduleId: module.moduleId,
    };

    this.store.disableModule(command)
      .then(() => showSuccessMessage('Module wurde ausgeblendet'))
      .catch((error) => showErrorResponse((error)));
  }

}
</script>
<template>
<v-container>
  <create-module-dialog>
    <a-trigger-primary-process-button>
      Modul erstellen
    </a-trigger-primary-process-button>
  </create-module-dialog>

  <v-card
    class="mt-4 mb-4 overflow-hidden"
    :loading="store.isGetModulesProcessing"
    outlined
  >

    <v-progress-linear v-if="store.isGetModulesProcessing && store.moduleList.length === 0" color="primary" indeterminate />

    <a-action-list
      :items="store.moduleList"
      item-id="moduleId"
      empty-list-message="Noch keine Module angelegt."
    >
      <template #content="{ item: module }">
        <router-link :to="{ name: 'manage-modules-content-management', params: { id: module.moduleId }}">
          {{ module.number }}. {{ module.title }}
        </router-link>
        <span
          v-if="!module.isEnabled"
          class="details"
        >
          Noch nicht veröffentlicht
        </span>
      </template>
      <template #actions="{ item: module, itemIndex: moduleIndex }">
        <icon-button
          small
          :icon="['far', 'eye']"
          tooltip="Anzeigen"
          v-if="isEnableModuleShown(module)"
          @click="onEnableModuleClicked(module)"
        />
        <icon-button
          small
          :icon="['far', 'eye-slash']"
          tooltip="Ausblenden"
          v-if="isDisableModuleShown(module)"
          @click="onDisableModuleClicked(module)"
        />
        <icon-button
          small
          :icon="['far', 'arrow-up']"
          :disabled="isModuleMoveUpDisabled(moduleIndex)"
          tooltip="Nach oben schieben"
          disabled-tooltip="Bereits erstes Modul"
          @click="onMoveModuleUpClicked(module)"
        />
        <icon-button
          small
          :icon="['far', 'arrow-down']"
          :disabled="isModuleMoveDownDisabled(moduleIndex)"
          tooltip="Nach unten schieben"
          disabled-tooltip="Bereits letztes Modul"
          @click="onMoveModuleDownClicked(module)"
        />
        <icon-menu small :icon="['far', 'pencil']">
          <edit-module-title-dialog :module="module">
            <v-list-item @click="$root.$emit('close-icon-menu')">
              <v-list-item-title>Bezeichnung anpassen</v-list-item-title>
            </v-list-item>
          </edit-module-title-dialog>
          <edit-module-image-dialog :module="module">
            <v-list-item @click="$root.$emit('close-icon-menu')">
              <v-list-item-title>Bild anpassen</v-list-item-title>
            </v-list-item>
          </edit-module-image-dialog>
        </icon-menu>
        <delete-module-dialog :module="module">
          <icon-button
            small
            :icon="['far', 'times']"
            tooltip="Modul löschen"
          />
        </delete-module-dialog>
      </template>
    </a-action-list>
  </v-card>

</v-container>
</template>
