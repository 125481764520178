import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faFileUpload } from '@fortawesome/pro-regular-svg-icons/faFileUpload';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faSignIn } from '@fortawesome/pro-regular-svg-icons/faSignIn';
import { faGraduationCap } from '@fortawesome/pro-regular-svg-icons/faGraduationCap';
import { faAlarmClock } from '@fortawesome/pro-regular-svg-icons/faAlarmClock';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons/faChartLine';
import { faBooks } from '@fortawesome/pro-regular-svg-icons/faBooks';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock';
import { faFileCertificate } from '@fortawesome/pro-regular-svg-icons/faFileCertificate';
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown';
import { faAlignLeft } from '@fortawesome/pro-regular-svg-icons/faAlignLeft';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faLockOpen } from '@fortawesome/pro-regular-svg-icons/faLockOpen';
import { faSalad } from '@fortawesome/pro-regular-svg-icons/faSalad';
import { faRunning } from '@fortawesome/pro-regular-svg-icons/faRunning';
import { faSmile } from '@fortawesome/pro-regular-svg-icons/faSmile';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut';
import { faFilm } from '@fortawesome/pro-regular-svg-icons/faFilm';
import { faBookMedical } from '@fortawesome/pro-regular-svg-icons/faBookMedical';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faClipboardListCheck } from '@fortawesome/pro-regular-svg-icons/faClipboardListCheck';
import { faBoxes } from '@fortawesome/pro-regular-svg-icons/faBoxes';
import { faShield } from '@fortawesome/pro-regular-svg-icons/faShield';
import { faClipboardList } from '@fortawesome/pro-regular-svg-icons/faClipboardList';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import { faBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faDna } from '@fortawesome/pro-regular-svg-icons/faDna';
import { faUserSecret } from '@fortawesome/pro-regular-svg-icons/faUserSecret';
import { faList } from '@fortawesome/pro-regular-svg-icons/faList';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faNewspaper } from '@fortawesome/pro-regular-svg-icons/faNewspaper';
import { faBookUser } from '@fortawesome/pro-regular-svg-icons/faBookUser';
import { faHatChef } from '@fortawesome/pro-regular-svg-icons/faHatChef';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { faBallotCheck } from '@fortawesome/pro-regular-svg-icons/faBallotCheck';
import { faHeadSideBrain } from '@fortawesome/pro-regular-svg-icons/faHeadSideBrain';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding';
import { faIdBadge } from '@fortawesome/pro-regular-svg-icons/faIdBadge';
import { faCalculator } from '@fortawesome/pro-regular-svg-icons/faCalculator';
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons/faBookmark';
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons/faDotCircle';
import { faSignsPost } from '@fortawesome/pro-regular-svg-icons/faSignsPost';
import { faHeadset } from '@fortawesome/pro-regular-svg-icons/faHeadset';
import { faRotate } from '@fortawesome/pro-regular-svg-icons/faRotate';
import { faLinkSlash } from '@fortawesome/pro-regular-svg-icons/faLinkSlash';
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons/faBullseyeArrow';
import { faGripLines } from '@fortawesome/pro-regular-svg-icons/faGripLines';

import { faSalad as fatSalad } from '@fortawesome/pro-thin-svg-icons/faSalad';

import { faPlay as fasPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faBold as fasBold } from '@fortawesome/pro-solid-svg-icons/faBold';
import { faItalic as fasItalic } from '@fortawesome/pro-solid-svg-icons/faItalic';
import { faLink as fasLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faUnlink as fasUnlink } from '@fortawesome/pro-solid-svg-icons/faUnlink';
import { faListUl as fasListUl } from '@fortawesome/pro-solid-svg-icons/faListUl';
import { faListOl as fasListOl } from '@fortawesome/pro-solid-svg-icons/faListOl';
import { faH2 as fasH2 } from '@fortawesome/pro-solid-svg-icons/faH2';
import { faH3 as fasH3 } from '@fortawesome/pro-solid-svg-icons/faH3';
import { faTable as fasTable } from '@fortawesome/pro-solid-svg-icons/faTable';
import { faTrash as fasTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faLayerPlus as fasLayerPlus } from '@fortawesome/pro-solid-svg-icons/faLayerPlus';
import { faLayerMinus as fasLayerMinus } from '@fortawesome/pro-solid-svg-icons/faLayerMinus';
import { faHeading as fasHeading } from '@fortawesome/pro-solid-svg-icons/faHeading';
import { faTint as fasTint } from '@fortawesome/pro-solid-svg-icons/faTint';
import { faTintSlash as fasTintSlash } from '@fortawesome/pro-solid-svg-icons/faTintSlash';
import { faAlignLeft as fasAlignLeft } from '@fortawesome/pro-solid-svg-icons/faAlignLeft';
import { faAlignRight as fasAlignRight } from '@fortawesome/pro-solid-svg-icons/faAlignRight';
import { faUserTie as fasUserTie } from '@fortawesome/pro-solid-svg-icons/faUserTie';
import { faBell as fasBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faEnvelope as fasEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faBars as fasBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faCaretDown as fasCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCheckSquare as fasCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faChevronLeft as fasChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faTimesCircle as fasTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faArrowTrendUp as fasArrowTrendUp } from '@fortawesome/pro-solid-svg-icons/faArrowTrendUp';
import { faArrowTrendDown as fasArrowTrendDown } from '@fortawesome/pro-solid-svg-icons/faArrowTrendDown';
import { faArrowRotateBack as fasArrowRotateBack } from '@fortawesome/pro-solid-svg-icons/faArrowRotateBack';
import { faLocationDot as fasLocationDot } from '@fortawesome/pro-solid-svg-icons/faLocationDot';
import { faPartyHorn as fasPartyHorn } from '@fortawesome/pro-solid-svg-icons/faPartyHorn';

// Regular
library.add(
  faFileUpload,
  faCheck,
  faSignIn,
  faGraduationCap,
  faAlarmClock,
  faChartLine,
  faBooks,
  faUser,
  faEnvelope,
  faCog,
  faInfoCircle,
  faExclamationCircle,
  faPencil,
  faArrowLeft,
  faArrowRight,
  faLock,
  faFileCertificate,
  faMinus,
  faPlus,
  faBell,
  faCalendar,
  faTrash,
  faFile,
  faEye,
  faEyeSlash,
  faArrowUp,
  faArrowDown,
  faAlignLeft,
  faImage,
  faLockOpen,
  faSalad,
  faRunning,
  faSmile,
  faTimes,
  faSignOut,
  faFilm,
  faBookMedical,
  faClock,
  faClipboardListCheck,
  faBoxes,
  faShield,
  faClipboardList,
  faPaperPlane,
  faBan,
  faLink,
  faDna,
  faUsers,
  faUserSecret,
  faList,
  faCircle,
  faCheckCircle,
  faDownload,
  faNewspaper,
  faBookUser,
  faHatChef,
  faAngleRight,
  faBallotCheck,
  faHeadSideBrain,
  faBuilding,
  faIdBadge,
  faCalculator,
  faSquare,
  faBookmark,
  faDotCircle,
  faSignsPost,
  faHeadset,
  faRotate,
  faLinkSlash,
  faBullseyeArrow,
  faGripLines
);

// Thin
library.add(
  fatSalad
);

// Solid
library.add(
  fasPlay,
  fasExclamationTriangle,
  fasTimes,
  fasBold,
  fasItalic,
  fasLink,
  fasUnlink,
  fasListUl,
  fasListOl,
  fasH2,
  fasH3,
  fasTable,
  fasTrash,
  fasLayerPlus,
  fasLayerMinus,
  fasHeading,
  fasTint,
  fasTintSlash,
  fasAlignLeft,
  fasAlignRight,
  fasUserTie,
  fasBell,
  fasEnvelope,
  fasBars,
  fasChevronDown,
  fasCaretDown,
  fasCheckSquare,
  fasChevronLeft,
  fasChevronRight,
  fasInfoCircle,
  fasTimesCircle,
  fasArrowTrendUp,
  fasArrowTrendDown,
  fasArrowRotateBack,
  fasLocationDot,
  fasPartyHorn
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
