<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useOnboardingStore } from '../store';

@Component
export default class AcknowledgeContraindications extends Vue {

  readonly store = useOnboardingStore();
  readonly authenticationStore = useAuthenticationStore();

  submitted(): void {
    this.store.acknowledgeContraindications()
      .then(() => this.$emit('submitConfirmed'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<div class="acknowledge-contraindications">

  <v-card-text class="pb-0">
    <h2 class="mb-4">Kontraindikationen</h2>
    <p>
      Aufgrund des präventiven Charakters ist dieser Kurs für Personen mit besonderem medizinischen Betreuungsbedarf nicht vorgesehen.<template v-if="authenticationStore.appPreventionType === 'NUTRITION'"> Dieser Kurs richtet sich an Personen mit einem BMI zwischen 25 und unter 30 bzw. nach ärztlicher Rücksprache, einem BMI zwischen 30 und unter 35.</template>
    </p>
    <ol v-if="authenticationStore.appPreventionType === 'NUTRITION'">
      <li>Ich bestätige, dass ich keine akuten oder chronischen psychischen oder physischen Erkrankungen habe.</li>
      <li>Ich bestätige, dass keine Schwangerschaft vorliegt.</li>
      <li>Ich bestätigte, dass keine Essstörungen oder behandlungsbedürftige Erkrankungen des Stoffwechsels vorliegen.</li>
    </ol>
    <ol v-if="authenticationStore.appPreventionType === 'MOVEMENT'">
      <li>Ich bestätige, dass ich keine akuten oder chronischen psychischen oder physischen Erkrankungen habe.</li>
      <li>Ich bestätige, dass keine Schwangerschaft vorliegt.</li>
      <li>Ich bestätige, dass ich keine behandlungsbedürftigen Erkrankungen des aktiven oder passiven Bewegungsapparates habe.</li>
    </ol>
    <ol v-if="authenticationStore.appPreventionType === 'STRESS'">
      <li>Ich bestätige, dass ich keine akuten oder chronischen psychischen oder physischen Erkrankungen habe.</li>
      <li>Ich bestätige, dass keine Schwangerschaft vorliegt.</li>
    </ol>
  </v-card-text>

  <v-divider />
  <v-card-actions>
    <v-spacer />
    <a-trigger-primary-process-button
      :is-processing="store.isAcknowledgeContraindicationsProcessing"
      @click="submitted"
    >
      Bestätigen
    </a-trigger-primary-process-button>
  </v-card-actions>
</div>
</template>
<style lang="sass" scoped>
ol
  li
    padding-bottom: 0.75rem
</style>
