import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { AcceptPushNotificationsForDeviceCommand, RejectPushNotificationsForDeviceCommand } from './types';

export function acceptPushNotificationsForDevice(command: AcceptPushNotificationsForDeviceCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/application/push-notifications/accept-push-notifications-for-device-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function rejectPushNotificationsForDevice(command: RejectPushNotificationsForDeviceCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/application/push-notifications/reject-push-notifications-for-device-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
