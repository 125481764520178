import { RouteConfig } from 'vue-router';
import AvailableSponsorSeats from './components/available-sponsor-seats.vue';

export const adminAccountingRoutes: RouteConfig[] = [
  {
    path: 'buchhaltung',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'admin-accounting-available-sponsor-seats',
        path: '/',
        component: AvailableSponsorSeats,
        meta: {
          requiresAuth: true,
          title: 'Buchhaltung',
          breadcrumbs: [
            { text: 'Buchhaltung', to: { name: 'admin-accounting-available-sponsor-seats' } },
            { text: 'Sponsorenlizenzen' },
          ],
        },
      },
    ],
  },
];
