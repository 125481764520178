import { RouteConfig } from 'vue-router';
import { Feature } from '@/types';
import RecipeList from './components/recipe-list.vue';
import RecipeDetails from './components/recipe-details.vue';

export const consumeRecipesRoutes: RouteConfig[] = [
  {
    path: '/rezepte',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'consume-recipes-recipe-list',
        path: '/',
        component: RecipeList,
        meta: {
          requiresAuth: true,
          requiresFeature: Feature.RECIPES,
          title: 'Rezepte',
        },
      },
      {
        name: 'consume-recipes-recipe-details',
        path: 'rezept/:recipeId',
        component: RecipeDetails,
        meta: {
          requiresAuth: true,
          requiresFeature: Feature.RECIPES,
          title: 'Rezepte',
        },
      },
    ],
  },
];
