<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showSuccessMessage } from '@/application/common/snackbar/service';
import { useManageSponsorsStore } from '../store';

@Component
export default class CopySponsorRegistrationLinkDialog extends Vue {

  readonly store = useManageSponsorsStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  get isLinkVisible(): boolean {
    return !!this.store.sponsorSeats
      && !!this.store.sponsorSeats.registrationLink;
  }

  copyLinkConfirmed(): void {
    navigator.clipboard.writeText(this.store.sponsorSeats!.registrationLink!);

    showSuccessMessage('Link wurde in die Zwischenablage kopiert.');

    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
>
  <template #activator="{ on }">
    <div class="d-inline-block" v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <v-card-title>Link kopieren</v-card-title>
    <v-card-text v-if="isLinkVisible">
      <p>
        Über folgenden Link, kann ein Benutzer die Registrierung starten.
      </p>
      <pre class="mb-4">{{ store.sponsorSeats.registrationLink }}</pre>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <a-cancel-action-button @click="closeDialog" />
      <a-execute-primary-action-button @click="copyLinkConfirmed">
        Link kopieren
      </a-execute-primary-action-button>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
<style lang="sass" scoped>
pre
  width: 100%
  white-space: pre-wrap
  padding: 1rem
  background: var(--color-grey-9)
</style>
