import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAdmin } from '@/helpers/default-parameter-helper';
import { App, CreateAppCommand, UpdateAppCommand } from './types';
import { createApp, getApps, updateApp } from './service';

interface AdminManageAppsState {
  apps: App[];

  getAppsStatus: ActionStatus;
  createAppStatus: ActionStatus;
  updateAppStatus: ActionStatus;
}

function initialState(): AdminManageAppsState {
  return {
    apps: [],

    getAppsStatus: ActionStatus.None,
    createAppStatus: ActionStatus.None,
    updateAppStatus: ActionStatus.None,
  };
}

export const useAdminManageAppsStore = defineStore('adminManageApps', {
  state: (): AdminManageAppsState => initialState(),
  getters: {
    isGetAppsProcessing: (state: AdminManageAppsState): boolean =>
      state.getAppsStatus === ActionStatus.InProgress,
    isCreateAppProcessing: (state: AdminManageAppsState): boolean =>
      state.createAppStatus === ActionStatus.InProgress,
    isUpdateAppProcessing: (state: AdminManageAppsState): boolean =>
      state.updateAppStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getApps(): Promise<void> {
      const { getAppsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAppsStatus,
        () => getApps(attachAdmin({}))
          .then(async (apps) => {
            this.apps = apps;
          })
      );
    },

    // -- Commands

    createApp(command: CreateAppCommand): Promise<void> {
      const { createAppStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createAppStatus,
        () => createApp(attachAdmin(command))
          .then(() => this.getApps())
      );
    },

    updateApp(command: UpdateAppCommand): Promise<void> {
      const { updateAppStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAppStatus,
        () => updateApp(attachAdmin(command))
          .then(() => this.getApps())
      );
    },

  },
});
