<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { NutritionDiaryTipType } from '@/types';
import { useManageNutritionDiaryStore } from '../store';
import { Tip, UpdateTipAsManagerCommand } from '../types';

interface Controls extends FormControls {
  type: FormControl<NutritionDiaryTipType>;
  description: FormControl<string>;
}

@Component
export default class UpdateTipDialog extends Vue {

  readonly store = useManageNutritionDiaryStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly tip!: Tip;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        type: {
          label: 'Typ',
          value: this.tip.type,
          isRequired: true,
        },
        description: {
          label: 'Tip Beschreibung',
          value: this.tip.description,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateTipAsManagerCommand = {
      nutritionDiaryTipId: this.tip.nutritionDiaryTipId,
      type: formValues.type!,
      description: formValues.description!,
    };

    this.store.updateTip(command)
      .then(() => showSuccessMessage('Tipp wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isUpdateTipProcessing"
>
  <template #activator="{ on }">
    <div class="d-inline-block" v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Tipp anpassen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <a-nutrition-diary-tip-type-form-control :form-control="form.controls.type" />
          </v-col>
          <v-col cols="12">
            <a-text-form-control
              :form-control="form.controls.description"
              is-autofocused
            />
          </v-col>
        </v-row>
        <a-info-alert>
          Es sollten nur Anpassungen im Tipp vorgenommen werden, wenn der Inhalt vorher falsch war oder nun besser formuliert ist. Wenn der Tipp sich inhaltlich ändert, sollte er statt dessen deaktiviert und ein Neuer angelegt werden. Ansonsten haben Benutzer ggf. Tipps in ihrer Liste, die sie nie gesehen haben. Dadurch würde der "neue" Tipp wie ein Fehler aussehen oder sie würden den Tipp verpassen.
        </a-info-alert>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isUpdateTipProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isUpdateTipProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
