<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { DocumentIdList } from '@/types';
import CreateDocumentDialog from './create-document-dialog.vue';
import UpdateDocumentDescriptionDialog from './update-document-description-dialog.vue';
import UpdateDocumentFileDialog from './update-document-file-dialog.vue';
import { useManageDocumentsStore } from '../store';
import { DeleteDocumentCommand, Document, UpdateOrderOfDocumentsAsManagerCommand } from '../types';

@Component({
  components: {
    CreateDocumentDialog,
    UpdateDocumentDescriptionDialog,
    UpdateDocumentFileDialog,
  },
})
export default class DocumentList extends Vue {

  readonly store = useManageDocumentsStore();

  mounted(): void {
    this.store.getDocuments()
      .catch((error) => showErrorResponse(error));
  }

  async deleteDocumentConfirmed(document: Document): Promise<void> {
    const command: DeleteDocumentCommand = {
      documentId: document.documentId,
    };

    return this.store.deleteDocument(command)
      .then(() => showSuccessMessage('Das Dokument und die verknüpften Inhaltselemente wurden gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

  sortedTips(sortedIds: DocumentIdList): void {
    const command: UpdateOrderOfDocumentsAsManagerCommand = {
      sortedIdsOfDocuments: sortedIds,
    };
    this.store.updateOrderOfDocuments(command)
      .then(() => showSuccessMessage('Die Reihenfolge wurde angepasst.'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <create-document-dialog>
    <a-trigger-primary-process-button>
      Dokument erstellen
    </a-trigger-primary-process-button>
  </create-document-dialog>

  <v-card
    class="mt-4 mb-4 overflow-hidden"
    outlined
  >
    <a-action-list
      :items="store.documents"
      item-id="documentId"
      :is-loading="store.isGetDocumentsProcessing"
      empty-list-message="Noch keine Dokumente angelegt."
      is-sortable
      sort-handle=".sort-handle"
      @sorted="sortedTips"
    >
      <template #content="{ item: document }">
        <span class="item-title">{{ document.title }}</span>
        <span class="details">
          {{ document.shortDescription }}
        </span>
      </template>
      <template #actions="{ item: document }">

        <icon-button
          :icon="['far', 'grip-lines']"
          small
          class="sort-handle"
          tooltip="Verschieben um Reihen-folge anzupassen"
        />

        <icon-menu small :icon="['far', 'pencil']">
          <v-list-item @click="$root.$emit('close-icon-menu')">
            <update-document-description-dialog :document="document">
              <v-list-item-title>Texte anpassen</v-list-item-title>
            </update-document-description-dialog>
          </v-list-item>
          <v-list-item @click="$root.$emit('close-icon-menu')">
            <update-document-file-dialog :document="document">
              <v-list-item-title>Datei austauschen</v-list-item-title>
            </update-document-file-dialog>
          </v-list-item>
        </icon-menu>

        <a-confirmation-dialog
          title="Dokument löschen"
          button-text="Löschen"
          highlight="danger"
          :action="() => deleteDocumentConfirmed(document)"
          :is-processing="store.isDeleteDocumentProcessing"
        >
          <icon-button
            small
            :icon="['far', 'times']"
            tooltip="Löschen"
          />
          <template #content>
            <p>
              Soll das Dokument mit dem Titel <strong>{{ document.title }}</strong> wirklich gelöscht werden?
            </p>
            <a-danger-alert>
              Inhaltselemente in Modulen, die sich auf dieses Dokument beziehen, werden ebenfalls unwiderruflich gelöscht!
            </a-danger-alert>
          </template>
        </a-confirmation-dialog>
      </template>
    </a-action-list>
  </v-card>

</v-container>
</template>
