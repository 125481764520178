<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { BaseDialogComponent } from '@/components';
import { DeleteNutritionDiaryEntryCommand, NutritionDiaryEntry } from '@/private/user/nutrition-diary/types';
import { formatDate, formatTime } from '@/filters/dates';
import { showErrorResponse } from '@/application/common/snackbar/service';
import AddNutritionDiaryEntryImageDialog from './add-nutrition-diary-entry-image-dialog.vue';
import UpdateNutritionDiaryEntryDescriptionDialog from './update-nutrition-diary-entry-description-dialog.vue';
import UpdateConsumedAtOfNutritionDiaryEntryDialog from './update-consumed-at-of-nutrition-diary-entry-dialog.vue';
import DeleteNutritionDiaryEntryImageDialog from './delete-nutrition-diary-entry-image-dialog.vue';
import { useNutritionDiaryStore } from '../store';

@Component({
  components: {
    AddNutritionDiaryEntryImageDialog,
    UpdateNutritionDiaryEntryDescriptionDialog,
    UpdateConsumedAtOfNutritionDiaryEntryDialog,
    DeleteNutritionDiaryEntryImageDialog,
  },
})
export default class ShowDetailsDialog extends BaseDialogComponent {

  readonly store = useNutritionDiaryStore();

  @Prop({ type: Object, required: true })
  readonly entry!: NutritionDiaryEntry;

  get title(): string {
    const now = dayjs();
    const formattedTime = `${formatTime(this.entry.consumedAt)} Uhr`;

    if (this.entry.consumedAt.isSame(now, 'day')) {
      return `Heute um ${formattedTime}`;
    }

    if (this.entry.consumedAt.isSame(now.subtract(1, 'day'), 'day')) {
      return `Gestern um ${formattedTime}`;
    }

    if (this.entry.consumedAt.isSame(now.subtract(2, 'day'), 'day')) {
      return `Vorgestern um ${formattedTime}`;
    }

    return `${formatDate(this.entry.consumedAt)} um ${formattedTime}`;
  }

  async deleteNutritionDiaryEntryConfirmed(): Promise<void> {
    const command: DeleteNutritionDiaryEntryCommand = {
      nutritionDiaryEntryId: this.entry.nutritionDiaryEntryId,
    };

    return this.store.deleteNutritionDiaryEntry(command)
      .then(() => this.hide())
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-dialog
  v-model="dialog"
  fullscreen
  transition="dialog-bottom-transition"
  hide-overlay
>
  <template #activator="{ on }">
    <div class="d-block" v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>

    <v-card-text class="pt-0 pb-4">

      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-row class="entry no-gutters">
            <v-col cols="4">
              <v-card outlined class="overflow-hidden">
                <div class="image-container">
                  <template v-if="entry.image">
                    <blur-hash-image
                      :hash="entry.image.hash"
                      :src="entry.image.url"
                      :alt="entry.consumedAt | formatTime"
                      :width="20"
                      :height="Math.floor(20 / entry.image.aspectRatio)"
                    />
                  </template>
                  <div class="image-placeholder" v-else>
                    <font-awesome-icon :icon="['fa-thin', 'salad']" />
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="8">
              <div class="details">
                <span class="description">
                  <template v-if="entry.description">{{ entry.description }}</template>
                  <template v-else><em>Keine Beschreibung hinterlegt.</em></template>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="actions mt-6">
        <update-consumed-at-of-nutrition-diary-entry-dialog :entry="entry">
          <a-trigger-secondary-process-button class="mt-2 mt-md-0">
            Zeitpunkt anpassen
          </a-trigger-secondary-process-button>
        </update-consumed-at-of-nutrition-diary-entry-dialog>

        <update-nutrition-diary-entry-description-dialog :entry="entry">
          <a-trigger-secondary-process-button class="mt-2 mt-md-0 ml-0 ml-md-4">
            Beschreibung anpassen
          </a-trigger-secondary-process-button>
        </update-nutrition-diary-entry-description-dialog>

        <delete-nutrition-diary-entry-image-dialog
          v-if="entry.image"
          :entry="entry"
        >
          <a-trigger-tertiary-process-button class="mt-4 mt-md-0 ml-0 ml-md-6">
            Bild löschen
          </a-trigger-tertiary-process-button>
        </delete-nutrition-diary-entry-image-dialog>
        <add-nutrition-diary-entry-image-dialog
          v-if="!entry.image"
          :entry="entry"
        >
          <a-trigger-secondary-process-button class="mt-2 mt-md-0 ml-0 ml-md-4">
            Bild hinzufügen
          </a-trigger-secondary-process-button>
        </add-nutrition-diary-entry-image-dialog>

        <a-confirmation-dialog
          title="Eintrag löschen"
          button-text="Löschen"
          highlight="danger"
          :action="() => deleteNutritionDiaryEntryConfirmed"
          :is-processing="store.isDeleteNutritionDiaryEntryProcessing"
        >
          <a-trigger-tertiary-process-button class="mt-4 mt-md-0 ml-0 ml-md-6">
            Eintrag löschen
          </a-trigger-tertiary-process-button>
          <template #content>
            <p>
              Soll der Eintrag wirklich gelöscht werden?
            </p>
            <p><strong>Der Eintrag und das ggf. vorhandene Bild werden endgültig gelöscht und können nicht wiederhergestellt werden!</strong></p>
          </template>
        </a-confirmation-dialog>
      </div>

    </v-card-text>

    <v-divider />
    <v-card-actions>
      <v-spacer />
      <a-trigger-primary-process-button @click="hide">
        Schließen
      </a-trigger-primary-process-button>
    </v-card-actions>

  </v-card>
</v-dialog>
</template>
<style lang="sass" scoped>
.image-container
  flex-basis: 120px
  flex-shrink: 0
  overflow: hidden
  position: relative
  background: var(--color-grey-9)

  // ::before and ::after are to add aspect ratio
  &::before
    content: ""
    width: 1px
    margin-left: -1px
    float: left
    height: 0
    padding-top: 130px / 200px * 100%

  &::after
    content: ""
    display: table
    clear: both

  .image-placeholder
    display: flex
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    margin: auto
    align-items: center
    justify-content: center
    height: 100%
    padding: 0.75rem 0

    svg
      display: block
      font-size: 3rem
      color: var(--color-grey-8)

.details
  font-size: 0.875rem
  padding: 0.25rem 0.25rem 0 0.75rem
</style>
