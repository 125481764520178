<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DangerAlert extends Vue {}
</script>
<template>
  <v-alert
    type="error"
    :icon="false"
    v-bind="$attrs"
  >
    <p class="mb-0">
      <slot />
    </p>
  </v-alert>
</template>
