<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showError, showSuccessMessage } from '@/application/common/snackbar/service';
import { useManageModulesStore } from '../store';
import { ContentElement, DeleteContentElementCommand, ModulePage } from '../types';

@Component
export default class DeleteContentElementDialog extends Vue {

  readonly store = useManageModulesStore();

  @Prop({ type: Object, required: true })
  readonly contentElement!: ContentElement;

  @Prop({ type: Object, required: true })
  readonly modulePage!: ModulePage;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  deleteContentElementConfirmed(): void {
    const command: DeleteContentElementCommand = {
      contentElementId: this.contentElement.contentElementId,
    };

    this.store.deleteContentElement(command, this.modulePage.modulePageId)
      .then(() => showSuccessMessage('Inhalt wurde gelöscht.'))
      .then(() => this.closeDialog())
      .catch((error) => {
        const message = error.response?.data.message || 'Inhalt konnte nicht gelöscht werden';
        showError({ message });
      });
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isDeleteContentElementProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <v-card-title>Inhalt löschen</v-card-title>
    <v-card-text>
      <p>Soll der Inhalt <strong>{{ contentElement.title }}</strong> wirklich gelöscht werden?</p>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <a-cancel-action-button
        :is-processing="store.isDeleteContentElementProcessing"
        @click="closeDialog"
      />
      <a-execute-dangerous-action-button
        @click="deleteContentElementConfirmed"
        :is-processing="store.isDeleteContentElementProcessing"
      >
        Löschen
      </a-execute-dangerous-action-button>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
