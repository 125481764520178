import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser, attachAppAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { Recipe, CreateRecipeCommand, PublishRecipeCommand, UnpublishRecipeCommand, UpdateRecipeCommand, UpdateOrderOfRecipesAsManagerCommand } from './types';
import { createRecipe, getRecipes, publishRecipe, unpublishRecipe, updateOrderOfRecipes, updateRecipe } from './service';

interface ManageRecipesState {
  recipes: Recipe[];

  getRecipesStatus: ActionStatus;
  createRecipeStatus: ActionStatus;
  updateRecipeStatus: ActionStatus;
  publishRecipeStatus: ActionStatus;
  unpublishRecipeStatus: ActionStatus;
  updateOrderOfRecipesStatus: ActionStatus;
}

function initialState(): ManageRecipesState {
  return {
    recipes: [],

    getRecipesStatus: ActionStatus.None,
    createRecipeStatus: ActionStatus.None,
    updateRecipeStatus: ActionStatus.None,
    publishRecipeStatus: ActionStatus.None,
    unpublishRecipeStatus: ActionStatus.None,
    updateOrderOfRecipesStatus: ActionStatus.None,
  };
}

export const useManageRecipesStore = defineStore('manageRecipes', {
  state: (): ManageRecipesState => initialState(),
  getters: {
    isGetRecipesProcessing: (state: ManageRecipesState): boolean =>
      state.getRecipesStatus === ActionStatus.InProgress,
    isCreateRecipeProcessing: (state: ManageRecipesState): boolean =>
      state.createRecipeStatus === ActionStatus.InProgress,
    isUpdateRecipeProcessing: (state: ManageRecipesState): boolean =>
      state.updateRecipeStatus === ActionStatus.InProgress,
    isPublishRecipeProcessing: (state: ManageRecipesState): boolean =>
      state.publishRecipeStatus === ActionStatus.InProgress,
    isUnpublishRecipeProcessing: (state: ManageRecipesState): boolean =>
      state.unpublishRecipeStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getRecipes(): Promise<void> {
      const { getRecipesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getRecipesStatus,
        () => getRecipes(attachAppAndUser({}))
          .then(async (recipes) => {
            this.recipes = recipes;
          })
      );
    },

    // -- Commands

    createRecipe(command: CreateRecipeCommand): Promise<void> {
      const { createRecipeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createRecipeStatus,
        () => createRecipe(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getRecipes())
      );
    },

    updateRecipe(command: UpdateRecipeCommand): Promise<void> {
      const { updateRecipeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateRecipeStatus,
        () => updateRecipe(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getRecipes())
      );
    },

    publishRecipe(command: PublishRecipeCommand): Promise<void> {
      const { publishRecipeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        publishRecipeStatus,
        () => publishRecipe(attachAppAndUser(command))
          .then(() => this.getRecipes())
      );
    },

    unpublishRecipe(command: UnpublishRecipeCommand): Promise<void> {
      const { unpublishRecipeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        unpublishRecipeStatus,
        () => unpublishRecipe(attachAppAndUser(command))
          .then(() => this.getRecipes())
      );
    },

    updateOrderOfRecipes(command: UpdateOrderOfRecipesAsManagerCommand): Promise<void> {
      const { updateOrderOfRecipesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateOrderOfRecipesStatus,
        () => updateOrderOfRecipes(attachAppAndUser(command))
          .then(() => this.getRecipes())
      );
    },

  },
});
