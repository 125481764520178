import dayjs, { Dayjs } from 'dayjs';
import { DurationUnitType } from 'dayjs/plugin/duration';
import { Weekday } from '@/types';

export function enumerateDates(startDate: Dayjs, endDate: Dayjs, amount: number, unit: DurationUnitType): string[] {
  const dates = [];
  const date = startDate.clone();

  do {
    dates.push(date.format());
  } while (date.add(amount, unit).diff(endDate) <= 0);

  return dates;
}

export function dateTimeWithTime(date: Dayjs, time: string): Dayjs {
  return dayjs(`${date.format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm:ss');
}

export function weekdayOfToday(): Weekday {
  const today = dayjs();

  return weekdayFromIsoWeekday(today.isoWeekday());
}

export function weekdayFromIsoWeekday(isoWeekday: number): Weekday {
  switch (isoWeekday) {
    case 1:
      return Weekday.MONDAY;
    case 2:
      return Weekday.TUESDAY;
    case 3:
      return Weekday.WEDNESDAY;
    case 4:
      return Weekday.THURSDAY;
    case 5:
      return Weekday.FRIDAY;
    case 6:
      return Weekday.SATURDAY;
    case 7:
      return Weekday.SUNDAY;
    default:
      throw new Error('Invalid weekday');
  }
}

export function floorToMinutes(date: Dayjs, interval: number): Dayjs {
  const remainder = date.minute() % interval;
  return date
    .subtract(remainder, 'minutes')
    .second(0)
    .millisecond(0);
}
