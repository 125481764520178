<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useConsumeModulesStore } from '../store';

@Component
export default class TableOfContentsNavigation extends Vue {

  readonly store = useConsumeModulesStore();

  get isNavigationToModuleSuccessPageAllowed(): boolean {
    return this.store.activeModule!.isCompleted;
  }

  isNavigationToModulePageIndexAllowed(index: number): boolean {
    return this.store.activeModule!.isCompleted
      || index <= this.store.activeModule!.lastSeenModulePageIndex;
  }

  isItemActive(index: number): boolean {
    return index === this.store.activeModulePageIndex;
  }

  listItemClicked(index: number): void {
    this.store.navigateToModulePageIndex(index);
    window.scrollTo({ top: 0 });
    this.$emit('navigated');
  }

  successPageClicked(): void {
    this.store.navigateToSuccessPage();
    window.scrollTo({ top: 0 });
    this.$emit('navigated');
  }

}
</script>
<template>
<v-list v-bind="$attrs" class="py-0">
  <v-list-item
    v-for="(activeModulePage, index) in store.activeModule.pages"
    :key="activeModulePage.modulePageId"
    class="pl-4"
    :class="{ active: isItemActive(index) }"
    @click="listItemClicked(index)"
    :disabled="!isNavigationToModulePageIndexAllowed(index)"
  >
    <v-list-item-content>
      <div class="item-container">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'check-circle']" class="mr-1" v-if="isNavigationToModulePageIndexAllowed(index)" />
          <font-awesome-icon :icon="['far', 'circle']" class="mr-1" v-if="!isNavigationToModulePageIndexAllowed(index)" />
        </div>
        <span>{{ activeModulePage.title }}</span>
      </div>
    </v-list-item-content>
  </v-list-item>
  <v-list-item
    class="pl-4"
    :disabled="!isNavigationToModuleSuccessPageAllowed"
    @click="successPageClicked"
  >
    <v-list-item-content>
      <div class="item-container">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'check-circle']" class="mr-1" v-if="isNavigationToModuleSuccessPageAllowed" />
          <font-awesome-icon :icon="['far', 'circle']" class="mr-1" v-if="!isNavigationToModuleSuccessPageAllowed" />
        </div>
        <span>Modul abschließen</span>
      </div>
    </v-list-item-content>
  </v-list-item>
</v-list>
</template>
<style lang="sass" scoped>
.v-list::v-deep
  font-size: 0.875rem

  .v-list-item
    &.active
      .v-list-item__content
        color: var(--color-brand)
        font-weight: 600

    &.v-list-item--disabled
      opacity: 0.7

    .item-container
      display: flex
      flex-direction: row

      .icon
        display: flex
        flex-grow: 0
        padding-top: 3px

      span
        display: flex
        flex-grow: 1
        margin-left: 5px
        line-height: 20px
</style>
