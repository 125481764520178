<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useConsumeModulesStore } from '../store';
import { ContentElementAcknowledgement } from '../types';

@Component
export default class Acknowledgement extends Vue {

  readonly store = useConsumeModulesStore();

  @Prop({ type: Object, required: true })
  readonly contentElement!: ContentElementAcknowledgement;

  onSelectCheckbox(): void {
    this.contentElement.isConfirmed = !this.contentElement.isConfirmed;
  }

}
</script>
<template>
<div class="acknowledgement">
  <div v-html="contentElement.introduction" />
  <v-checkbox
    v-if="store.isAcknowledgementCheckboxShown"
    :label="contentElement.acknowledgement"
    @change="onSelectCheckbox"
    hide-details
  />
  <p
     v-if="!store.isAcknowledgementCheckboxShown"
     class="confirmed mb-0"
  >
    Bestätigung wurde bereits abgegeben.
  </p>
</div>
</template>
<style lang="sass" scoped>
.acknowledgement
  background: var(--color-grey-9)
  padding: 1.25rem 1rem
  border: solid var(--color-grey-8)
  border-width: 1px 0
  color: var(--color-grey-3)

  ::v-deep
    p
      font-size: 1rem

      &.confirmed
        color: var(--color-green-4)

  .v-input::v-deep .v-label
    line-height: 1.5
</style>
