<script lang="ts">
import { Component } from 'vue-property-decorator';
import { BaseDialogComponent } from '@/components';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useUserProgressStore } from '../store';

@Component
export default class LevelUpDialog extends BaseDialogComponent {

  readonly store = useUserProgressStore();
  readonly authenticationStore = useAuthenticationStore();

  get badgeImage(): string {
    if (this.store.userProgress === null) {
      return '';
    }
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require(`@/assets/images/badges/${this.authenticationStore.appPreventionType.toLowerCase()}/level-${this.store.userProgress.level}.png`);
  }

  closeDialog() {
    this.hide();
  }

}
</script>
<template>
<v-dialog
  v-model="dialog"
  :max-width="size"
  persistent
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <v-card>
    <v-card-title>Glückwunsch!</v-card-title>
    <v-card-text>
      <p v-if="store.userProgress">
        Du hast die <strong>Stufe {{ store.userProgress.level }}</strong> erreicht und bist nun <strong>{{ store.userProgress.levelName }}</strong>!
      </p>
      <img :src="badgeImage" alt="Badge" class="mb-2" width="100%" />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <a-execute-primary-action-button @click="closeDialog">
        Super
      </a-execute-primary-action-button>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
