<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useAppStore } from '@/application/common/app/store';
import { ingredientUnitTranslations } from '@/helpers/data';
import { useConsumeRecipesStore } from '../store';

@Component
export default class RecipeDetails extends Vue {

  readonly store = useConsumeRecipesStore();
  readonly appStore = useAppStore();

  readonly ingredientUnitTranslations = ingredientUnitTranslations;

  mounted(): void {
    this.store.updateCurrentRecipe(this.$route.params.recipeId);
    if (this.store.currentRecipe === null) {
      this.store.getRecipes()
        .catch((error) => showErrorResponse(error));
    }

    watch(() => this.store.currentRecipe, () => {
      if (this.store.currentRecipe === null) {
        return;
      }

      this.appStore.updateTitle(this.store.currentRecipe.name);
    });
  }

  destroyed(): void {
    this.store.resetCurrentRecipe();
  }

}
</script>
<template>
<v-container class="recipe-details">
  <v-progress-linear v-if="store.isGetRecipesProcessing" indeterminate class="mb-4" />
  <v-row no-gutters v-if="store.currentRecipe" class="flex-column-reverse flex-md-row">
    <v-col
      cols="12"
      md="7"
      lg="8"
    >
      <v-card outlined>

        <blur-hash-image
          :hash="store.currentRecipe.heroImage.hash"
          :src="store.currentRecipe.heroImage.url"
          :alt="store.currentRecipe.name"
          :width="20"
          :height="Math.floor(20 / store.currentRecipe.heroImage.aspectRatio)"
        />
        <v-card-text>
          <h2 class="mb-4">
            {{ store.currentRecipe.name }}<span class="sub-title" v-if="store.currentRecipe.subTitle"> - {{ store.currentRecipe.subTitle }}</span>
          </h2>
          <div class="steps mb-2">
            <div
              class="step"
              v-for="(preparationStep, index) in store.currentRecipe.preparationSteps"
              :key="index"
            >
              <span class="step-number mb-1">{{ index + 1 }}</span>
              <div class="description" v-html="preparationStep" />
            </div>
          </div>

          <div class="tip-box" v-html="store.currentRecipe.tipBox" v-if="store.currentRecipe.tipBox" />

        </v-card-text>

      </v-card>

      <a-trigger-tertiary-process-button
        :to="{ name: 'consume-recipes-recipe-list' }"
        class="mt-2 mt-md-4 mb-6 mb-md-4"
      >
        Alle Rezepte
      </a-trigger-tertiary-process-button>

    </v-col>
    <v-col
      cols="12"
      md="5"
      lg="4"
    >
      <v-card class="ml-0 ml-md-4" outlined>
        <v-card-text class="pa-0">
          <blur-hash-image
            :hash="store.currentRecipe.ingredientsImage.hash"
            :src="store.currentRecipe.ingredientsImage.url"
            :alt="store.currentRecipe.name"
            :width="20"
            :height="Math.floor(20 / store.currentRecipe.ingredientsImage.aspectRatio)"
          />
          <div class="pa-4">
            <h3>Zutaten</h3>
            <ul class="ingredients mt-3">
              <li
                v-for="(ingredient, ingredientIndex) in store.currentRecipe.ingredients"
                :key="ingredientIndex"
              >
                <template v-if="ingredient.unit === 'TO_TASTE'">
                  {{ ingredient.ingredient }} nach Geschmack
                </template>
                <template v-else-if="ingredient.unit === 'PIECE'">
                  {{ ingredient.amount }} {{ ingredient.ingredient }}
                </template>
                <template v-else>
                  {{ ingredient.amount }} {{ ingredientUnitTranslations[ingredient.unit] }} {{ ingredient.ingredient }}
                </template>
              </li>
            </ul>
            <v-divider class="my-4" />
            <p class="mb-0">
              <span class="d-block">Gesamtzeit: {{ store.currentRecipe.totalTimeInMinutes }}min</span>
              <span class="d-block mt-1">Arbeitszeit: {{ store.currentRecipe.preparationTimeInMinutes }}min</span>
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
span
  font-size: 0.875rem

.sub-title
  font-size: inherit

.steps
  .step
    display: flex

    .step-number
      display: block
      background: var(--color-brand)
      border-radius: 50%
      color: var(--color-white)
      font-weight: 700
      font-family: Montserrat, sans-serif
      width: 1.5rem
      height: 1.5rem
      line-height: 1.5rem
      text-align: center
      flex-grow: 0
      flex-shrink: 0
      margin-right: 0.75rem

  .description::v-deep
    flex-grow: 1

    p
      padding-bottom: 1rem
      margin-bottom: 0

    ul,
    ol
      padding-bottom: 1rem

    li
      p
        padding-bottom: 0

.ingredients
  li
    padding-bottom: 0.25rem

.tip-box::v-deep
  border: 5px solid var(--color-brand)
  padding: 0.75rem 1rem

  p
    margin-bottom: 0.75rem

  p:last-child,
  ul:last-child,
  ol:last-child
    margin-bottom: 0

@media screen and (max-width: 960px)

  .recipe-details
    padding: 0

  .v-card
    border: none
</style>
<style lang="sass">
@media screen and (max-width: 960px)
  .consume-recipes-recipe-details
    .v-main
      background: var(--color-white)
</style>
