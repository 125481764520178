<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useAppStore } from '@/application/common/app/store';
import { useConsumeBlogArticlesStore } from '../store';
import { MarkBlogArticleAsReadCommand } from '../types';

@Component
export default class BlogArticleDetails extends Vue {

  readonly store = useConsumeBlogArticlesStore();
  readonly appStore = useAppStore();

  get isLoading(): boolean {
    return this.store.isGetBlogArticlesProcessing;
  }

  get isModulePromotionVisible(): boolean {
    return this.store.currentBlogArticleDetails !== null
      && this.store.currentBlogArticleDetails.promotedModules.length > 0;
  }

  mounted(): void {
    this.store.updateCurrentBlogArticle(this.$route.params.blogArticleId)
      .catch((error) => showErrorResponse(error));

    watch(() => this.store.currentBlogArticleDetails, () => {
      if (this.store.currentBlogArticleDetails === null) {
        return;
      }

      if (this.store.currentBlogArticleDetails.isUnread) {
        const command: MarkBlogArticleAsReadCommand = {
          blogArticleId: this.store.currentBlogArticleDetails.blogArticleId,
        };
        this.store.markBlogArticleAsRead(command)
          .catch((error) => showErrorResponse(error));
      }

      this.appStore.updateTitle(this.store.currentBlogArticleDetails.title);
    });
  }

  destroyed(): void {
    this.store.resetCurrentBlogArticle();
  }

}
</script>
<template>
<v-container class="blog-article-details">
  <v-progress-linear v-if="isLoading" indeterminate class="mb-4" />
  <v-row no-gutters v-if="store.currentBlogArticleDetails">
    <v-col
      cols="12"
      md="7"
      lg="8"
    >
      <v-card outlined>
        <v-card-text class="pa-0">
          <blur-hash-image
            :hash="store.currentBlogArticleDetails.image.hash"
            :src="store.currentBlogArticleDetails.image.url"
            :alt="store.currentBlogArticleDetails.title"
            :width="20"
            :height="Math.floor(20 / store.currentBlogArticleDetails.image.aspectRatio)"
          />
          <div>
            <h2 class="pa-4">{{ store.currentBlogArticleDetails.title }}</h2>
            <template v-for="contentElement in store.currentBlogArticleDetails.contentElements">
              <div
                v-if="contentElement.type === 'rich-text'"
                v-html="contentElement.textHtml"
                :key="contentElement.blogArticleContentElementId"
                class="rich-text px-4"
              />
              <template >
                <div
                  v-if="contentElement.type === 'tip-box'"
                  v-html="contentElement.tipHtml"
                  :key="contentElement.blogArticleContentElementId"
                  class="tip-box mx-4"
                />
              </template>
              <template >
                <blur-hash-image
                  v-if="contentElement.type === 'image'"
                  :key="contentElement.blogArticleContentElementId"
                  :hash="contentElement.image.hash"
                  :src="contentElement.image.url"
                  :alt="contentElement.title"
                  :width="20"
                  :height="Math.floor(20 / contentElement.image.aspectRatio)"
                  class="mb-4"
                />
              </template>
              <div
                v-if="contentElement.type === 'image-with-description'"
                :key="contentElement.blogArticleContentElementId"
                class="image-with-description"
              >
                <div class="description">{{ contentElement.description }}</div>
                <div class="image-container">
                  <blur-hash-image
                    :hash="contentElement.image.hash"
                    :src="contentElement.image.url"
                    :alt="contentElement.title"
                    :width="20"
                    :height="Math.floor(20 / contentElement.image.aspectRatio)"
                    class="mb-4"
                  />
                </div>
              </div>
              <div
                v-if="contentElement.type === 'strategies'"
                :key="contentElement.blogArticleContentElementId"
                class="strategies px-4"
              >
                <h3>{{ contentElement.headline }}</h3>
                <div
                  v-for="(strategy, index) in contentElement.strategies"
                  :key="index"
                  class="strategy"
                >
                  <div class="number">
                    <span>{{ index + 1 }}</span>
                  </div>
                  <div class="description">
                    <span v-html="strategy" />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="5"
      lg="4"
      v-if="isModulePromotionVisible"
    >
      <v-card class="ml-0 ml-md-4" outlined>
        <v-card-text>
          <h3>Weiterführende Informationen</h3>
          <p class="mb-3">Weitere Tipps findest du in den folgenden Modulen:</p>
          <v-list class="py-0">
            <template v-for="(promotedModule, index) in store.currentBlogArticleDetails.promotedModules">
              <v-list-item
                :key="`${promotedModule.moduleId}-item`"
                :to="{ name: 'consume-modules-consume-module', params: { id: promotedModule.moduleId } }"
              >
                <v-list-item-icon class="mr-0">
                  <font-awesome-icon :icon="['far', 'angle-right']" />
                </v-list-item-icon>
                <v-list-item-content>
                  {{ promotedModule.title }}
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index !== store.currentBlogArticleDetails.promotedModules.length - 1"
                :key="`${promotedModule.moduleId}-divider`"
              />
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.rich-text::v-deep,
.tip-box::v-deep
  p
    padding-bottom: 1rem
    margin-bottom: 0

  ul,
  ol
    padding-bottom: 1rem

  li
    p
      padding-bottom: 0

.tip-box
  border: 5px solid var(--color-brand)
  padding: 0.75rem 1rem 0
  margin-bottom: 1rem

.strategies
  padding-bottom: 1rem

  .strategy
    display: flex
    flex-direction: row
    margin-top: 1rem

    .number
      flex-basis: 55px
      flex-shrink: 0
      flex-grow: 0

      span
        display: block
        width: 40px
        height: 40px
        line-height: 40px
        text-align: center
        font-weight: 700
        font-family: Montserrat, sans-serif
        border-radius: 50%
        color: var(--color-white)
        background: var(--color-brand)

    .description::v-deep
      flex-grow: 1

      p
        margin-bottom: 0

@media screen and (max-width: 959px)
  .image-with-description
    position: relative
    z-index: 100

    .description
      z-index: 200
      position: absolute
      left: 50%
      top: 50%
      width: 100%
      height: 100%
      transform: translate(-50%, -50%)
      font-size: 1.25rem
      font-weight: 700
      font-family: Montserrat, sans-serif
      color: var(--color-grey-2)
      background: rgba(255, 255, 255, 0.7)
      text-shadow: 1px 1px var(--color-white)
      padding: 1rem
      line-height: 1.5

@media screen and (min-width: 960px)
  .image-with-description
    display: flex
    flex-direction: row

    .image-container
      width: 50%

    .description
      width: 50%
      display: flex
      justify-content: center
      align-items: center
      padding: 1rem
      line-height: 1.5
      font-weight: 700
      font-family: Montserrat, sans-serif
      color: var(--color-grey-2)

@media screen and (max-width: 960px)

  .blog-article-details
    padding: 0

  .v-card
    border: none
</style>
<style lang="sass">
@media screen and (max-width: 960px)
  .consume-blog-articles-blog-article-details
    .v-main
      background: var(--color-white)
</style>
