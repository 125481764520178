import axios from 'axios';
import { isNavigationFailure } from 'vue-router';
import { showError } from '@/application/common/snackbar/service';
import { router } from '@/router/admin';

function handleAuthenticationError(error: any): Promise<any> {
  const isUnauthorizedError = (error.response?.status === 401);
  const isAuthenticationRequiredError = (error.response?.data.identifier === 'authentication-required');
  const isUserDisabledError = (error.response?.data.identifier === 'user-disabled');

  // On first authentication try the route path will always be "/" as it's triggered before the app is initialized
  const isRelevantRoute = router.currentRoute.fullPath !== '/';
  const isAlwaysAccessibleRoute = router.currentRoute.meta?.alwaysAccessible || false;

  if (isUnauthorizedError && isRelevantRoute && !isAlwaysAccessibleRoute) {
    router.push({ name: 'logout' })
      .catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });

    if (isAuthenticationRequiredError) {
      // Error has to be thrown first, as all stores (including the snackbar store) are cleared on logout
      showError({ message: 'Deine Anmeldung ist abgelaufen, melde dich bitte neu an.' });
    }
    if (isUserDisabledError) {
      showError({ message: 'Dein Account wurde deaktiviert.' });
    }
  }

  return Promise.reject(error);
}

export default function initAuthenticationInterceptor() {
  axios.interceptors.response.use(
    (response) => response,
    handleAuthenticationError
  );
}
