<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { ActionStatus } from '@/store';
import { ConfirmEmailChangeCommand } from '../types';

@Component
export default class UserEmailChangeConfirmation extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  get message(): string {
    switch (this.authenticationStore.confirmEmailChangeStatus) {
      case ActionStatus.None:
        return 'Die E-Mail Adresse wird bestätigt...';
      case ActionStatus.InProgress:
        return 'Die E-Mail Adresse wird bestätigt...';
      case ActionStatus.Successful:
        return 'Die E-Mail Adresse wurde angepasst. Du kannst dich jetzt mit der neuen E-Mail Adresse anmelden.';
      case ActionStatus.Failed:
        return 'Die E-Mail Adresse konnte nicht angepasst werden!';
      default:
        throw new Error('Invalid status');
    }
  }

  mounted(): void {
    const command: ConfirmEmailChangeCommand = {
      confirmationToken: this.$route.params.confirmationToken,
    };

    this.authenticationStore.confirmEmailChange(command)
      .then(() => showSuccessMessage('Deine E-Mail Adresse wurde angepasst, du kannst dich jetzt damit anmelden.', 5000))
      .then(() => {
        if (this.authenticationStore.isAuthenticated) {
          this.authenticationStore.fetchAuthenticatedUser()
            .then(() => this.$router.push({ name: 'profile-show-profile' }));
        }
      })
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>
  <v-row>
    <v-col md="12" lg="9">
      <v-card outlined>
        <v-card-text>
          <p>
            {{ message }}
          </p>

          <a-trigger-primary-process-button
            :to="{ name: 'authentication-login' }"
            class="mt-4"
          >
            Zur Anmeldung
          </a-trigger-primary-process-button>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
