<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useManageHabitsStore } from '@/private/management/habits/store';
import { useManageModulesStore } from '../store';
import { CreateContentElementHabitProposalCommand, ModulePage } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  habitId: FormControl<string>;
}

@Component
export default class CreateHabitProposalDialog extends Vue {

  readonly store = useManageModulesStore();
  readonly manageHabitsStore = useManageHabitsStore();

  @Prop({ type: Object, required: true })
  readonly modulePage!: ModulePage;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      if (this.isDialogVisible) {
        this.manageHabitsStore.getHabits()
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => showErrorResponse(error));
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Bezeichnung',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        habitId: {
          label: 'Gewohnheit',
          value: this.manageHabitsStore.habits
            && this.manageHabitsStore.habits.length > 0
            ? this.manageHabitsStore.habits[0].habitId
            : null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateContentElementHabitProposalCommand = {
      modulePageId: this.modulePage.modulePageId,
      contentElementTitle: formValues.title!,
      habitId: formValues.habitId!,
    };

    this.store.createContentElementHabitProposal(command)
      .then(() => showSuccessMessage('Gewohnheitsangebot wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateContentElementHabitProposalProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Gewohnheitsangebot erstellen</v-card-title>
      <v-card-text>
        <v-progress-linear
          v-if="manageHabitsStore.isGetHabitsProcessing"
          color="primary"
          indeterminate
        />
        <v-row>
          <v-col>
            <a-text-form-control
              :form-control="form.controls.title"
              is-autofocused
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-habit-form-control
              :form-control="form.controls.habitId"
              :items="manageHabitsStore.habits"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateContentElementHabitProposalProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateContentElementHabitProposalProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Erstellen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
