import { defineStore } from 'pinia';
import { BreadcrumbItem } from './types';

interface BreadcrumbState {
  items: BreadcrumbItem[];
}

function initialState(): BreadcrumbState {
  return {
    items: [],
  };
}

export const useBreadcrumbStore = defineStore('breadcrumb', {
  state: (): BreadcrumbState => initialState(),
  actions: {

    // -- State management

    updateItems(payload?: BreadcrumbItem[]): void {
      this.items = (payload || []).map((breadcrumb: any) => ({
        ...breadcrumb,
        exact: true,
      }));
    },

  },
});
