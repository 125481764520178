import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { Tip, CreateTipAsManagerCommand, PublishTipAsManagerCommand, UnpublishTipAsManagerCommand, UpdateTipAsManagerCommand, UpdateOrderOfTipsAsManagerCommand } from './types';

// -- Queries

export function getTips(query: AppAndUserProperty): Promise<Tip[]> {
  const url = `${apiUrl}/api/nutrition-diary/get-tips-as-manager-query`;
  return performApiRequest<Tip[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createTip(command: CreateTipAsManagerCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/nutrition-diary/create-tip-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateTip(command: UpdateTipAsManagerCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/nutrition-diary/update-tip-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function publishTip(command: PublishTipAsManagerCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/nutrition-diary/publish-tip-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function unpublishTip(command: UnpublishTipAsManagerCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/nutrition-diary/unpublish-tip-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateOrderOfTips(command: UpdateOrderOfTipsAsManagerCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/nutrition-diary/update-order-of-tips-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
