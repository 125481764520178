import { RawLocation, Route, Location, RouteMeta } from 'vue-router';
import { AuthenticatedUser } from '@/application/admin/authentication/types';
import { useAdminAuthenticationStore } from '@/application/admin/authentication/store';

function getRedirectRouteForAuthenticatedUser(): Location {
  return { name: 'default' };
}

function getRedirectRouteForUnauthenticatedUser(): Location {
  return { name: 'root' };
}

export async function accessGuard(
  to: Route,
  from: Route,
  next: (to?: RawLocation | false | ((vm: any) => any) | void) => void
) {
  if (!to.meta) {
    throw Error('The route meta is undefined');
  }

  const adminAuthenticationState = useAdminAuthenticationStore();

  if (!adminAuthenticationState.wasInitialAuthenticationAttempted) {
    await adminAuthenticationState.fetchInitialAuthenticatedUser()
      .catch(() => {});
  }

  const user = adminAuthenticationState.user;

  const redirectLocation = nextPotentialRedirectLocation(
    to.name ?? null,
    to.meta,
    user
  );
  if (redirectLocation === null) {
    next();
  } else {
    next(redirectLocation);
  }
}

export function nextPotentialRedirectLocation(
  routeName: string | null,
  meta: RouteMeta,
  user: AuthenticatedUser | null
): Location | null {
  // Route is always accessible
  if (meta.alwaysAccessible) {
    return null;
  }

  const isAuthenticated = !!user;

  // User is authenticated but route is just accessible as unauthenticated user
  if (isAuthenticated && !meta.requiresAuth) {
    return getRedirectRouteForAuthenticatedUser();
  }

  // User is unauthenticated but route is just accessible as authenticated user
  if (!isAuthenticated && meta.requiresAuth) {
    return getRedirectRouteForUnauthenticatedUser();
  }

  return null;
}
