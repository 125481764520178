<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { ExperienceReportIdList } from '@/types';
import { useManageExperienceReportsStore } from '../store';
import { ExperienceReport, PublishExperienceReportCommand, UnpublishExperienceReportCommand, UpdateOrderOfExperienceReportsAsManagerCommand } from '../types';
import CreateExperienceReportDialog from './create-experience-report-dialog.vue';
import UpdateExperienceReportDialog from './update-experience-report-dialog.vue';

@Component({
  components: {
    CreateExperienceReportDialog,
    UpdateExperienceReportDialog,
  },
})
export default class ExperienceReportsList extends Vue {

  readonly store = useManageExperienceReportsStore();

  mounted(): void {
    this.store.getExperienceReports()
      .catch((error) => showErrorResponse(error));
  }

  async publishExperienceReportConfirmed(experienceReport: ExperienceReport): Promise<void> {
    const command: PublishExperienceReportCommand = {
      experienceReportId: experienceReport.experienceReportId,
    };
    return this.store.publishExperienceReport(command)
      .catch((error) => showErrorResponse(error));
  }

  async unpublishExperienceReportConfirmed(experienceReport: ExperienceReport): Promise<void> {
    const command: UnpublishExperienceReportCommand = {
      experienceReportId: experienceReport.experienceReportId,
    };
    return this.store.unpublishExperienceReport(command)
      .catch((error) => showErrorResponse(error));
  }

  sortedExperienceReports(sortedIds: ExperienceReportIdList): void {
    const command: UpdateOrderOfExperienceReportsAsManagerCommand = {
      sortedIdsOfExperienceReports: sortedIds,
    };
    this.store.updateOrderOfExperienceReports(command)
      .then(() => showSuccessMessage('Die Reihenfolge wurde angepasst.'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <create-experience-report-dialog>
    <a-trigger-primary-process-button>
      Erfahrungsbericht erstellen
    </a-trigger-primary-process-button>
  </create-experience-report-dialog>

  <v-card
    class="mt-4 mb-4 overflow-hidden"
    outlined
  >
    <a-action-list
      :items="store.experienceReports"
      item-id="experienceReportId"
      :is-loading="store.isGetExperienceReportsProcessing"
      empty-list-message="Noch keine Erfahrungsberichte angelegt."
      is-sortable
      sort-handle=".sort-handle"
      @sorted="sortedExperienceReports"
    >
      <template #content="{ item: experienceReport }">
        <span class="item-title">
          {{ experienceReport.name }}
          <template v-if="!experienceReport.isPublished"> (Unveröffentlicht)</template>
        </span>
        <span class="details">
          Erstellt am {{ experienceReport.createdAt | formatDate }}
        </span>
      </template>
      <template #actions="{ item: experienceReport }">

        <icon-button
          :icon="['far', 'grip-lines']"
          small
          class="sort-handle"
          tooltip="Verschieben um Reihen-folge anzupassen"
        />

        <update-experience-report-dialog :experience-report="experienceReport">
          <icon-button small :icon="['far', 'pencil']" tooltip="Erfahrungsbericht anpassen" />
        </update-experience-report-dialog>

        <a-confirmation-dialog
          title="Erfahrungsbericht veröffentlichen"
          button-text="Veröffentlichen"
          :action="() => publishExperienceReportConfirmed(experienceReport)"
          :is-processing="store.isPublishExperienceReportProcessing"
        >
          <icon-button
            v-if="!experienceReport.isPublished"
            small
            :icon="['far', 'eye']"
            tooltip="Veröffentlichen"
          />
          <template #content>
            <p>
              Soll der Erfahrungsbericht wirklich veröffentlicht werden?
            </p>
          </template>
        </a-confirmation-dialog>

        <a-confirmation-dialog
          title="Erfahrungsbericht verstecken"
          button-text="Verstecken"
          highlight="warning"
          :action="() => unpublishExperienceReportConfirmed(experienceReport)"
          :is-processing="store.isUnpublishExperienceReportProcessing"
        >
          <icon-button
            v-if="experienceReport.isPublished"
            small
            :icon="['far', 'eye-slash']"
            tooltip="Verstecken"
          />
          <template #content>
            <p>
              Soll der Erfahrungsbericht wirklich verstecken werden?
            </p>
          </template>
        </a-confirmation-dialog>

      </template>
    </a-action-list>
  </v-card>

</v-container>
</template>
