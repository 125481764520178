import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/src/locale/de';
import { defaultTheme } from './themes/default';
import { beweglicheGelenkeTheme } from './themes/bewegliche-gelenke';
import { schlauerBewegerTheme } from './themes/schlauer-beweger';
import { schlauerStressmanagerTheme } from './themes/schlauer-stressmanager';
import { schlauerVerdauerTheme } from './themes/schlauer-verdauer';
import { unbeschwertEssenTheme } from './themes/unbeschwert-essen';
import { nubaliTheme } from './themes/nubali';

Vue.use(Vuetify);

export type StyleAlias = 'default'
  | 'bewegliche-gelenke'
  | 'schlauer-beweger'
  | 'schlauer-stressmanager'
  | 'schlauer-verdauer'
  | 'unbeschwert-essen'
  | 'nubali';

export const themeMap: { [key: string]: any } = {
  default: defaultTheme,
  'bewegliche-gelenke': beweglicheGelenkeTheme,
  'schlauer-beweger': schlauerBewegerTheme,
  'schlauer-stressmanager': schlauerStressmanagerTheme,
  'schlauer-verdauer': schlauerVerdauerTheme,
  'unbeschwert-essen': unbeschwertEssenTheme,
  nubali: nubaliTheme,
};

export const vuetify = new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  icons: {
    iconfont: 'faSvg',
  },
  theme: {
    themes: {
      light: defaultTheme,
    },
  },
});
