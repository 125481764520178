import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { CreateSupportMessageCommand } from './types';
import { createSupportMessage } from './service';

interface ContactSupportState {
  createSupportMessageStatus: ActionStatus;
}

function initialState(): ContactSupportState {
  return {
    createSupportMessageStatus: ActionStatus.None,
  };
}

export const useContactSupportStore = defineStore('contactSupport', {
  state: (): ContactSupportState => initialState(),
  getters: {
    isCreateSupportMessageProcessing: (state: ContactSupportState): boolean =>
      state.createSupportMessageStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Commands

    createSupportMessage(command: CreateSupportMessageCommand): Promise<void> {
      const { createSupportMessageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createSupportMessageStatus,
        () => createSupportMessage(attachAppAndUser(command))
      );
    },

  },
});
