<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { nutritionDiaryTipTypeTranslations } from '@/helpers/data';
import { NutritionDiaryTipId } from '@/types';
import { useManageNutritionDiaryStore } from '../store';
import { PublishTipAsManagerCommand, Tip, UnpublishTipAsManagerCommand, UpdateOrderOfTipsAsManagerCommand } from '../types';
import CreateTipDialog from './create-tip-dialog.vue';
import UpdateTipDialog from './update-tip-dialog.vue';

@Component({
  components: {
    CreateTipDialog,
    UpdateTipDialog,
  },
})
export default class TipList extends Vue {

  readonly store = useManageNutritionDiaryStore();

  mounted(): void {
    this.store.getTips()
      .catch((error) => showErrorResponse(error));
  }

  translatedType(tip: Tip): string {
    return nutritionDiaryTipTypeTranslations[tip.type];
  }

  async publishTipConfirmed(tip: Tip): Promise<void> {
    const command: PublishTipAsManagerCommand = {
      nutritionDiaryTipId: tip.nutritionDiaryTipId,
    };
    return this.store.publishTip(command)
      .then(() => showSuccessMessage('Tipp wurde veröffentlicht.'))
      .catch((error) => showErrorResponse(error));
  }

  async unpublishTipConfirmed(tip: Tip): Promise<void> {
    const command: UnpublishTipAsManagerCommand = {
      nutritionDiaryTipId: tip.nutritionDiaryTipId,
    };
    return this.store.unpublishTip(command)
      .then(() => showSuccessMessage('Tipp wurde versteckt.'))
      .catch((error) => showErrorResponse(error));
  }

  sortedTips(sortedIds: NutritionDiaryTipId[]): void {
    const command: UpdateOrderOfTipsAsManagerCommand = {
      sortedIdsOfTips: sortedIds,
    };
    this.store.updateOrderOfTips(command)
      .then(() => showSuccessMessage('Die Reihenfolge wurde angepasst.'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <create-tip-dialog>
    <a-trigger-primary-process-button>
      Tipp erstellen
    </a-trigger-primary-process-button>
  </create-tip-dialog>

  <a-info-alert class="mt-4">
    Die Tipps werden in der hier konfigurierten Reihenfolge Stück für Stück in das Ernährungstagebuch eingestreut. Wenn die Reihenfolge angepasst wird, oder neue Tipps hinzu kommen, werden diese anhand der neuen Reihenfolge eingestreut. <br />
    Wenn ein Benutzer bereits einen Tipp erhalten hat, wird für diesen Benutzer die Reihenfolge für den jeweiligen Tipp nicht angepasst. Dadurch kann die Reihenfolge jederzeit optimiert werden, ohne dass die Benutzer die Tipps erneut erhalten.
  </a-info-alert>

  <v-card
    class="mt-4 mb-4 overflow-hidden"
    outlined
  >
    <a-action-list
      :items="store.tips"
      item-id="nutritionDiaryTipId"
      :is-loading="store.isGetTipsProcessing"
      is-sortable
      sort-handle=".sort-handle"
      @sorted="sortedTips"
      empty-list-message="Noch keine Tipps angelegt."
    >
      <template #content="{ item: tip }">
        <span class="item-title">
          {{ tip.description }}
          <template v-if="!tip.isPublished"> (Unveröffentlicht)</template>
        </span>
        <span class="details">
          {{ translatedType(tip) }}
        </span>
      </template>
      <template #actions="{ item: tip }">

        <icon-button
          :icon="['far', 'grip-lines']"
          small
          class="sort-handle"
          tooltip="Verschieben um Reihen-folge anzupassen"
        />

        <update-tip-dialog :tip="tip">
          <icon-button small :icon="['far', 'pencil']" tooltip="Tipp anpassen" />
        </update-tip-dialog>

        <a-confirmation-dialog
          title="Tipp veröffentlichen"
          button-text="Veröffentlichen"
          :action="() => publishTipConfirmed(tip)"
          :is-processing="store.isPublishTipProcessing"
        >
          <icon-button
            v-if="!tip.isPublished"
            small
            :icon="['far', 'eye']"
            tooltip="Veröffentlichen"
          />
          <template #content>
            <p>
              Soll der Tipp wirklich veröffentlicht werden?
            </p>
          </template>
        </a-confirmation-dialog>

        <a-confirmation-dialog
          title="Tipp verstecken"
          button-text="Verstecken"
          highlight="warning"
          :action="() => unpublishTipConfirmed(tip)"
          :is-processing="store.isUnpublishTipProcessing"
        >
          <icon-button
            v-if="tip.isPublished"
            small
            :icon="['far', 'eye-slash']"
            tooltip="Verstecken"
          />
          <template #content>
            <p>
              Soll das Tipp wirklich versteckt werden?
            </p>
          </template>
        </a-confirmation-dialog>

      </template>
    </a-action-list>
  </v-card>

</v-container>
</template>
