import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { LOCAL_STORAGE_APP_ID, LOCAL_STORAGE_USER_ID, removeLocalStorageItem, setLocalStorageItem } from '@/helpers/local-storage-helper';
import { Role } from '@/helpers/data';
import { AppType, Feature, PreventionType } from '@/types';
import { messageFromError } from '@/application/common/snackbar/service';
import { useImpersonationHintStore } from '@/application/whitelabel/app/components/impersonation-hint/store';
import { AuthenticatedUser, ConfirmEmailChangeCommand, ConfirmPasswordResetCommand, ConfirmUserRegistrationCommand, CourseConfiguration, DeleteOwnAccountCommand, GetAuthenticationWithImpersonationQuery, LoginAction, RegisterUserCommand, RequestEmailChangeCommand, RequestPasswordResetCommand, UpdateUserPasswordCommand, UpdateNotificationSettingsCommand, RegisterUserForSponsorCommand, IsSponsorSlugValidQuery, SponsorSlugResponse, UserRegistrationRequest, GetUserRegistrationRequestQuery } from './types';
import { confirmEmailChange, confirmPasswordReset, confirmUserRegistration, deleteOwnAccount, fetchAuthenticatedUser, getAuthenticationWithImpersonation, getUserRegistrationRequest, isSponsorSlugValid, login, logout, registerUser, registerUserForSponsor, requestEmailChange, requestPasswordReset, updateNotificationSettings, updateUserPassword } from './service';

interface AuthenticationState {
  wasInitialAuthenticationAttempted: boolean;
  isAuthenticated: boolean;
  appStyle: string | null;
  appId: string | null;
  appTitle: string | null;
  appType: AppType | null;
  appHost: string | null;
  appPreventionType: PreventionType | null;
  appEnabledFeatures: Feature[] | null;
  appStoreLink: string | null;
  playStoreLink: string | null;
  user: AuthenticatedUser | null;
  courseConfiguration: CourseConfiguration | null;
  sponsorSlugResponse: SponsorSlugResponse | null;
  userRegistrationRequest: UserRegistrationRequest | null;
  userRegistrationRequestError: string | null;

  loginStatus: ActionStatus;
  logoutStatus: ActionStatus;
  fetchAuthenticatedUserStatus: ActionStatus;
  registerUserStatus: ActionStatus;
  registerUserForSponsorStatus: ActionStatus;
  confirmUserRegistrationStatus: ActionStatus;
  requestPasswordResetStatus: ActionStatus;
  confirmPasswordResetStatus: ActionStatus;
  requestEmailChangeStatus: ActionStatus;
  confirmEmailChangeStatus: ActionStatus;
  updateUserPasswordStatus: ActionStatus;
  getAuthenticationWithImpersonationStatus: ActionStatus;
  deleteOwnAccountStatus: ActionStatus;
  updateNotificationSettingsStatus: ActionStatus;
  isSponsorSlugValidStatus: ActionStatus;
  getUserRegistrationRequestStatus: ActionStatus;
}

function initialState(): AuthenticationState {
  return {
    wasInitialAuthenticationAttempted: false,
    isAuthenticated: false,
    appStyle: 'default',
    appId: '',
    appTitle: 'Vitomy',
    appType: AppType.COURSE,
    appHost: null,
    appPreventionType: PreventionType.NUTRITION,
    appEnabledFeatures: [],
    appStoreLink: null,
    playStoreLink: null,
    user: null,
    courseConfiguration: null,
    sponsorSlugResponse: null,
    userRegistrationRequest: null,
    userRegistrationRequestError: null,

    loginStatus: ActionStatus.None,
    logoutStatus: ActionStatus.None,
    fetchAuthenticatedUserStatus: ActionStatus.None,
    registerUserStatus: ActionStatus.None,
    registerUserForSponsorStatus: ActionStatus.None,
    confirmUserRegistrationStatus: ActionStatus.None,
    requestPasswordResetStatus: ActionStatus.None,
    confirmPasswordResetStatus: ActionStatus.None,
    requestEmailChangeStatus: ActionStatus.None,
    confirmEmailChangeStatus: ActionStatus.None,
    updateUserPasswordStatus: ActionStatus.None,
    getAuthenticationWithImpersonationStatus: ActionStatus.None,
    deleteOwnAccountStatus: ActionStatus.None,
    updateNotificationSettingsStatus: ActionStatus.None,
    isSponsorSlugValidStatus: ActionStatus.None,
    getUserRegistrationRequestStatus: ActionStatus.None,
  };
}

export const useAuthenticationStore = defineStore('authentication', {
  state: (): AuthenticationState => initialState(),
  getters: {
    isFeatureEnabled: (state: AuthenticationState): (feature: Feature) => boolean =>
      (feature) => state.appEnabledFeatures !== null
        && state.appEnabledFeatures.includes(feature),
    isAppManager: (state: AuthenticationState): boolean =>
      !!state.user
        && state.user.role.role === Role.ROLE_APP_MANAGER,
    isSponsorManager: (state: AuthenticationState): boolean =>
      !!state.user
        && state.user.role.role === Role.ROLE_SPONSOR_MANAGER,
    isNormalUser: (state: AuthenticationState): boolean =>
      !!state.user
        && state.user.role.role === Role.ROLE_USER,
    isDomainVisible: (state: AuthenticationState): boolean =>
      state.isAuthenticated
        && (state.user?.hasCompletedOnboarding ?? false),
    hasNoPushNotificationConfigurationForDevice: (state: AuthenticationState): (deviceIdentifier: string) => boolean =>
      (deviceIdentifier) => !(state.user?.pushNotificationConfigurations ?? [])
        .some((pushNotificationConfiguration) => pushNotificationConfiguration.deviceIdentifier === deviceIdentifier),

    isLoginProcessing: (state: AuthenticationState): boolean =>
      state.loginStatus === ActionStatus.InProgress,
    isLogoutProcessing: (state: AuthenticationState): boolean =>
      state.logoutStatus === ActionStatus.InProgress,
    isFetchAuthenticatedUserProcessing: (state: AuthenticationState): boolean =>
      state.fetchAuthenticatedUserStatus === ActionStatus.InProgress,
    isRegisterUserProcessing: (state: AuthenticationState): boolean =>
      state.registerUserStatus === ActionStatus.InProgress,
    isRegisterUserForSponsorProcessing: (state: AuthenticationState): boolean =>
      state.registerUserForSponsorStatus === ActionStatus.InProgress,
    isConfirmUserRegistrationProcessing: (state: AuthenticationState): boolean =>
      state.confirmUserRegistrationStatus === ActionStatus.InProgress,
    isRequestPasswordResetProcessing: (state: AuthenticationState): boolean =>
      state.requestPasswordResetStatus === ActionStatus.InProgress,
    isConfirmPasswordResetProcessing: (state: AuthenticationState): boolean =>
      state.confirmPasswordResetStatus === ActionStatus.InProgress,
    isRequestEmailChangeProcessing: (state: AuthenticationState): boolean =>
      state.requestEmailChangeStatus === ActionStatus.InProgress,
    isConfirmEmailChangeProcessing: (state: AuthenticationState): boolean =>
      state.confirmEmailChangeStatus === ActionStatus.InProgress,
    isUpdateUserPasswordProcessing: (state: AuthenticationState): boolean =>
      state.updateUserPasswordStatus === ActionStatus.InProgress,
    isGetAuthenticationWithImpersonationProcessing: (state: AuthenticationState): boolean =>
      state.getAuthenticationWithImpersonationStatus === ActionStatus.InProgress,
    isDeleteOwnAccountProcessing: (state: AuthenticationState): boolean =>
      state.deleteOwnAccountStatus === ActionStatus.InProgress,
    isUpdateNotificationSettingsProcessing: (state: AuthenticationState): boolean =>
      state.updateNotificationSettingsStatus === ActionStatus.InProgress,
    isIsSponsorSlugValidProcessing: (state: AuthenticationState): boolean =>
      state.isSponsorSlugValidStatus === ActionStatus.InProgress,
    isGetUserRegistrationRequestProcessing: (state: AuthenticationState): boolean =>
      state.getUserRegistrationRequestStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    resetWithoutAppData(): void {
      this.isAuthenticated = false;
      this.user = null;
      this.userRegistrationRequest = null;

      this.loginStatus = ActionStatus.None;
      this.logoutStatus = ActionStatus.None;
      this.fetchAuthenticatedUserStatus = ActionStatus.None;
      this.registerUserStatus = ActionStatus.None;
      this.registerUserForSponsorStatus = ActionStatus.None;
      this.confirmUserRegistrationStatus = ActionStatus.None;
      this.requestPasswordResetStatus = ActionStatus.None;
      this.confirmPasswordResetStatus = ActionStatus.None;
      this.requestEmailChangeStatus = ActionStatus.None;
      this.confirmEmailChangeStatus = ActionStatus.None;
      this.updateUserPasswordStatus = ActionStatus.None;
      this.getAuthenticationWithImpersonationStatus = ActionStatus.None;
      this.deleteOwnAccountStatus = ActionStatus.None;
      this.updateNotificationSettingsStatus = ActionStatus.None;
      this.isSponsorSlugValidStatus = ActionStatus.None;
      this.getUserRegistrationRequestStatus = ActionStatus.None;
    },

    // -- Actions

    login(action: LoginAction): Promise<void> {
      const { loginStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        loginStatus,
        () => login(attachAppAndUser(action))
          .then((authentication) => {
            setLocalStorageItem(LOCAL_STORAGE_USER_ID, authentication.user.id);
            setLocalStorageItem(LOCAL_STORAGE_APP_ID, authentication.appId);

            this.isAuthenticated = true;
            this.appId = authentication.appId;
            this.appTitle = authentication.appTitle;
            this.appPreventionType = authentication.appPreventionType;
            this.appStyle = authentication.appStyle;
            this.appType = authentication.appType;
            this.appHost = authentication.appHost;
            this.appEnabledFeatures = authentication.appEnabledFeatures;
            this.user = authentication.user;
            this.courseConfiguration = authentication.courseConfiguration || null;
            this.appStoreLink = authentication.appStoreLink || null;
            this.playStoreLink = authentication.playStoreLink || null;
          })
      );
    },

    logout(): Promise<void> {
      const { logoutStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        logoutStatus,
        () => logout()
          .then(() => {
            removeLocalStorageItem(LOCAL_STORAGE_USER_ID);

            this.isAuthenticated = false;
            this.user = null;
          })
      );
    },

    // -- Queries

    getInitialAuthentication(): Promise<void> {
      const { fetchAuthenticatedUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        fetchAuthenticatedUserStatus,
        () => fetchAuthenticatedUser(attachAppAndUser({}))
          .then(async (authentication) => {
            setLocalStorageItem(LOCAL_STORAGE_USER_ID, authentication.user.id);
            setLocalStorageItem(LOCAL_STORAGE_APP_ID, authentication.appId);

            this.isAuthenticated = true;
            this.appId = authentication.appId;
            this.appTitle = authentication.appTitle;
            this.appPreventionType = authentication.appPreventionType;
            this.appStyle = authentication.appStyle;
            this.appType = authentication.appType;
            this.appHost = authentication.appHost;
            this.appEnabledFeatures = authentication.appEnabledFeatures;
            this.user = authentication.user;
            this.courseConfiguration = authentication.courseConfiguration || null;
            this.appStoreLink = authentication.appStoreLink || null;
            this.playStoreLink = authentication.playStoreLink || null;

            this.wasInitialAuthenticationAttempted = true;
          })
          .catch((error) => {
            const data = error.response?.data;
            if (data) {
              setLocalStorageItem(LOCAL_STORAGE_APP_ID, data.appId);

              this.appStyle = data.appStyle ?? null;
              this.appId = data.appId ?? null;
              this.appTitle = data.appTitle ?? null;
              this.appType = data.appType ?? null;
              this.appHost = data.appHost ?? null;
              this.appStoreLink = data.appStoreLink ?? null;
              this.playStoreLink = data.playStoreLink ?? null;
              this.appEnabledFeatures = data.appEnabledFeatures ?? [];
            }

            this.wasInitialAuthenticationAttempted = true;

            throw error;
          })
      );
    },

    fetchAuthenticatedUser(): Promise<void> {
      const { fetchAuthenticatedUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        fetchAuthenticatedUserStatus,
        () => fetchAuthenticatedUser(attachAppAndUser({}))
          .then(async (authentication) => {
            setLocalStorageItem(LOCAL_STORAGE_USER_ID, authentication.user.id);
            setLocalStorageItem(LOCAL_STORAGE_APP_ID, authentication.appId);

            this.isAuthenticated = true;
            this.appId = authentication.appId;
            this.appTitle = authentication.appTitle;
            this.appPreventionType = authentication.appPreventionType;
            this.appStyle = authentication.appStyle;
            this.appType = authentication.appType;
            this.appHost = authentication.appHost;
            this.user = authentication.user;
            this.courseConfiguration = authentication.courseConfiguration || null;
            this.appStoreLink = authentication.appStoreLink || null;
            this.playStoreLink = authentication.playStoreLink || null;
            this.appEnabledFeatures = authentication.appEnabledFeatures;
          })
          .catch((error) => {
            const data = error.response?.data;
            if (data) {
              setLocalStorageItem(LOCAL_STORAGE_APP_ID, data.appId);

              this.appStyle = data.appStyle;
              this.appId = data.appId;
              this.appTitle = data.appTitle;
              this.appType = data.appType;
              this.appHost = data.appHost;
              this.appStoreLink = data.appStoreLink;
              this.playStoreLink = data.playStoreLink;
              this.appEnabledFeatures = data.appEnabledFeatures;
            }

            throw error;
          })
      );
    },

    getAuthenticationWithImpersonation(query: GetAuthenticationWithImpersonationQuery): Promise<void> {
      const { getAuthenticationWithImpersonationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAuthenticationWithImpersonationStatus,
        () => getAuthenticationWithImpersonation(attachAppAndUser(query))
          .then(() => this.fetchAuthenticatedUser())
          .then(() => useImpersonationHintStore().activateHint())
      );
    },

    isSponsorSlugValid(query: IsSponsorSlugValidQuery): Promise<void> {
      const { isSponsorSlugValidStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        isSponsorSlugValidStatus,
        () => isSponsorSlugValid(attachAppAndUser(query))
          .then((response) => {
            this.sponsorSlugResponse = response;
          })
      );
    },

    getUserRegistrationRequest(query: GetUserRegistrationRequestQuery): Promise<void> {
      const { getUserRegistrationRequestStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserRegistrationRequestStatus,
        () => getUserRegistrationRequest(attachAppAndUser(query))
          .then((userRegistrationRequest) => {
            this.userRegistrationRequest = userRegistrationRequest;
          })
          .catch((error) => {
            this.userRegistrationRequestError = messageFromError(error);
            throw error;
          })
      );
    },

    // -- Commands

    registerUser(command: RegisterUserCommand): Promise<void> {
      const { registerUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        registerUserStatus,
        () => registerUser(attachAppAndUser(command))
      );
    },

    registerUserForSponsor(command: RegisterUserForSponsorCommand): Promise<void> {
      const { registerUserForSponsorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        registerUserForSponsorStatus,
        () => registerUserForSponsor(attachAppAndUser(command))
      );
    },

    confirmUserRegistration(command: ConfirmUserRegistrationCommand): Promise<void> {
      const { confirmUserRegistrationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmUserRegistrationStatus,
        () => confirmUserRegistration(attachAppAndUser(command))
      );
    },

    requestPasswordReset(command: RequestPasswordResetCommand): Promise<void> {
      const { requestPasswordResetStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        requestPasswordResetStatus,
        () => requestPasswordReset(attachAppAndUser(command))
      );
    },

    confirmPasswordReset(command: ConfirmPasswordResetCommand): Promise<void> {
      const { confirmPasswordResetStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmPasswordResetStatus,
        () => confirmPasswordReset(attachAppAndUser(command))
      );
    },

    requestEmailChange(command: RequestEmailChangeCommand): Promise<void> {
      const { requestEmailChangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        requestEmailChangeStatus,
        () => requestEmailChange(attachAppAndUser(command))
      );
    },

    confirmEmailChange(command: ConfirmEmailChangeCommand): Promise<void> {
      const { confirmEmailChangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmEmailChangeStatus,
        () => confirmEmailChange(attachAppAndUser(command))
      );
    },

    updateUserPassword(command: UpdateUserPasswordCommand): Promise<void> {
      const { updateUserPasswordStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserPasswordStatus,
        () => updateUserPassword(attachAppAndUser(command))
      );
    },

    deleteOwnAccount(command: DeleteOwnAccountCommand): Promise<void> {
      const { deleteOwnAccountStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteOwnAccountStatus,
        () => deleteOwnAccount(attachAppAndUser(command))
          .then(() => this.logout())
      );
    },

    updateNotificationSettings(command: UpdateNotificationSettingsCommand): Promise<void> {
      const { updateNotificationSettingsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateNotificationSettingsStatus,
        () => updateNotificationSettings(attachAppAndUser(command))
          .then(() => this.fetchAuthenticatedUser())
      );
    },

  },
});
