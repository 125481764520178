import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modules"},[_c(VCardText,{staticClass:"pb-0"},[_c('h2',[_vm._v("Rezepte")]),(_vm.store.featureExamples.recipes.length > 0)?_c(VRow,{staticClass:"recipe-list mt-4 mb-4 mb-md-2"},_vm._l((_vm.store.featureExamples.recipes),function(recipe,index){return _c(VCol,{key:recipe.recipeId,attrs:{"cols":"12","md":"6","lg":"4"}},[_c(VRow,{staticClass:"no-gutters recipe",class:{ 'mb-4': index !== _vm.store.featureExamples.recipes.length - 1 }},[_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{staticClass:"overflow-hidden",attrs:{"outlined":""}},[_c('div',{staticClass:"blur-hash-image-container"},[_c('blur-hash-image',{attrs:{"hash":recipe.heroImage.hash,"src":recipe.heroImage.url,"alt":recipe.name,"width":20,"height":Math.floor(20 / recipe.heroImage.aspectRatio)}}),_c('span',{staticClass:"total-time"},[_vm._v(" "+_vm._s(recipe.totalTimeInMinutes)+"min ")])],1)])],1),_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"recipe-info"},[_c('strong',{staticClass:"recipe-title"},[_vm._v(_vm._s(recipe.name))]),(recipe.subTitle)?_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(recipe.subTitle))]):_vm._e(),_c('span',{staticClass:"d-block action-link"},[_vm._v("Rezept anzeigen")])])])],1)],1)}),1):_vm._e(),_c('p',[_vm._v(" In der Rubrik \"Rezepte\" findest du eine Liste von schmackhaften Gerichten zum Nachkochen inkl. Tipps von Experten. ")]),_c('p',[_vm._v(" Klasse vor Masse: Wir fokussieren uns an dieser Stelle auf eine handverlesene Sammlung an köstlichen und gesunden Kochideen. ")])],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{on:{"click":_vm.skipClicked}},[_vm._v(" Einführung überspringen ")]),_c('a-trigger-primary-process-button',{on:{"click":_vm.submitClicked}},[_vm._v(" Weiter ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }