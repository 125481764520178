import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.size,"persistent":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"transition":_vm.$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null,"hide-overlay":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Glückwunsch!")]),_c(VCardText,[(_vm.store.userProgress)?_c('p',[_vm._v(" Du hast die "),_c('strong',[_vm._v("Stufe "+_vm._s(_vm.store.userProgress.level))]),_vm._v(" erreicht und bist nun "),_c('strong',[_vm._v(_vm._s(_vm.store.userProgress.levelName))]),_vm._v("! ")]):_vm._e(),_c('img',{staticClass:"mb-2",attrs:{"src":_vm.badgeImage,"alt":"Badge","width":"100%"}})]),_c(VCardActions,[_c(VSpacer),_c('a-execute-primary-action-button',{on:{"click":_vm.closeDialog}},[_vm._v(" Super ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }