<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';

@Component
export default class HealthPointsStep extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  submitClicked(): void {
    this.$emit('submitConfirmed');
  }

}
</script>
<template>
<div class="health-points">
  <v-card-text class="pb-0">
    <h2>Gesundheitspunkte</h2>
    <p>
      Für verschiedene Aktivitäten in der App sammelst du Gesundheitspunkte. Dazu gehören z.B. das  Abschließen eines Wissens-Moduls, das richtige Beantworten von Quiz-Fragen, das Planen und die Durchführung von Gewohnheiten. Stufe für Stufe steigst du so vom Grünschnabel bis zum Experten auf.
    </p>
    <p>
      Und jetzt sind wir schon am Ende der Einführung angelangt. Gratulation, du bekommst die ersten Gesundheitspunkte, da du die Einführung erfolgreich abgeschlossen hast!
    </p>
  </v-card-text>

  <v-divider />
  <v-card-actions>
    <v-spacer />
    <a-trigger-primary-process-button @click="submitClicked">
      Einführung abschließen
    </a-trigger-primary-process-button>
  </v-card-actions>

</div>
</template>
