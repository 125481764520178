<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { formatDate } from '@/filters/dates';
import InviteUserForMachtfitDialog from './invite-user-for-machtfit-dialog.vue';
import InviteUserForProfitDialog from './invite-user-for-profit-dialog.vue';
import InviteUsersWithDirectLicenseDialog from './invite-users-with-direct-license-dialog.vue';
import RegistrationLinkDialog from './registration-link-dialog.vue';
import { useUserInvitesStore } from '../store';
import { CancelUserInviteCommand, ResendUserInviteCommand, UserInvite } from '../types';

@Component({
  components: {
    InviteUserForMachtfitDialog,
    InviteUserForProfitDialog,
    RegistrationLinkDialog,
    InviteUsersWithDirectLicenseDialog,
  },
})
export default class UserInvites extends Vue {

  readonly store = useUserInvitesStore();

  get isLoading(): boolean {
    return this.store.isGetUserInvitesProcessing;
  }

  get isNoInvitesVisible(): boolean {
    return !this.isLoading && this.store.userInvites.length === 0;
  }

  mounted(): void {
    this.store.getUserInvites()
      .catch((error) => showErrorResponse(error));
  }

  async resendUserInviteConfirmed(userInvite: UserInvite): Promise<void> {
    const command: ResendUserInviteCommand = {
      userInviteId: userInvite.userInviteId,
    };

    return this.store.resendUserInvite(command)
      .then(() => showSuccessMessage('Einladung wurde erneut verschickt.'))
      .catch((error) => showErrorResponse(error));
  }

  async cancelUserInviteConfirmed(userInvite: UserInvite): Promise<void> {
    const command: CancelUserInviteCommand = {
      userInviteId: userInvite.userInviteId,
    };

    return this.store.cancelUserInvite(command)
      .then(() => showSuccessMessage('Einladung wurde storniert.'))
      .catch((error) => showErrorResponse(error));
  }

  userInviteDescription(userInvite: UserInvite): string {
    if (!userInvite.registrationCompletedAt && !userInvite.inviteCancelledAt) {
      return `Eingeladen am ${formatDate(userInvite.createdAt)} und noch nicht angenommen.`;
    }

    if (userInvite.registrationCompletedAt && !userInvite.inviteCancelledAt) {
      return `Eingeladen am ${formatDate(userInvite.createdAt)} und angenommen am ${formatDate(userInvite.registrationCompletedAt)}.`;
    }

    if (!userInvite.registrationCompletedAt && userInvite.inviteCancelledAt) {
      return `Eingeladen am ${formatDate(userInvite.createdAt)} und storniert am ${formatDate(userInvite.inviteCancelledAt)}.`;
    }

    if (userInvite.registrationCompletedAt && userInvite.inviteCancelledAt) {
      // eslint-disable-next-line vue/max-len
      return `Eingeladen am ${formatDate(userInvite.createdAt)}, angenommen am ${formatDate(userInvite.registrationCompletedAt)} und storniert am ${formatDate(userInvite.inviteCancelledAt)}.`;
    }

    throw new Error('Invalid user invite configuration');
  }

  isResendUserInviteDisabled(userInvite: UserInvite): boolean {
    return !!userInvite.inviteCancelledAt || !!userInvite.registrationCompletedAt;
  }

  resendUserInviteDisabledTooltip(userInvite: UserInvite): string {
    return userInvite.inviteCancelledAt
      ? 'Einladung wurde bereits storniert'
      : 'Einladung wurde bereits angenommen';
  }

  isRegistrationLinkDisabled(userInvite: UserInvite): boolean {
    return !userInvite.registrationLink;
  }

  isCancelUserInviteDisabled(userInvite: UserInvite): boolean {
    return !!userInvite.inviteCancelledAt;
  }

  invitedFor(userInvite: UserInvite): string {
    switch (userInvite.invitedFor) {
      case 'machtfit':
        return 'Machtfit';
      case 'profit':
        return 'proFit';
      case 'direct-license':
        return 'Direkte Lizenz';
      default:
        throw new Error('Invalid invited for');
    }
  }

}
</script>
<template>
<v-container>

  <icon-menu button-color="primary" button-type="button" :icon="['far', 'paper-plane']" text="Benutzer einladen">
    <invite-user-for-machtfit-dialog>
      <v-list-item @click="$root.$emit('close-icon-menu')">
        <v-list-item-title>Machtfit Benutzer einladen</v-list-item-title>
      </v-list-item>
    </invite-user-for-machtfit-dialog>

    <invite-user-for-profit-dialog>
      <v-list-item @click="$root.$emit('close-icon-menu')">
        <v-list-item-title>proFit Benutzer einladen</v-list-item-title>
      </v-list-item>
    </invite-user-for-profit-dialog>

    <invite-users-with-direct-license-dialog>
      <v-list-item @click="$root.$emit('close-icon-menu')">
        <v-list-item-title>Benutzer mit direkten Lizenzen einladen</v-list-item-title>
      </v-list-item>
    </invite-users-with-direct-license-dialog>
  </icon-menu>

  <v-card class="mt-4 mb-4" outlined :loading="isLoading">

    <v-list flat class="py-0">
      <v-list-item class="pl-4" v-if="isNoInvitesVisible" disabled>
        <v-list-item-content>
          Noch keine Benutzer eingeladen
        </v-list-item-content>
      </v-list-item>

      <template v-for="(userInvite, index) in store.userInvites">

        <v-list-item
          class="pl-4"
          :ripple="false"
          :key="`${index}-list-item`"
        >
          <v-list-item-content>
            <span class="d-block font-weight-medium">{{ userInvite.emailAddress }} ({{ invitedFor(userInvite) }})</span>
            <span class="d-block text--secondary mt-1">
              {{ userInviteDescription(userInvite) }}
            </span>
          </v-list-item-content>
          <v-list-item-icon class="mb-0">
            <registration-link-dialog
              v-if="userInvite.registrationLink"
              :registration-link="userInvite.registrationLink"
              :disabled="isRegistrationLinkDisabled(userInvite)"
            >
              <icon-button
                small
                :icon="['far', 'link']"
                tooltip="Link zum Registrierungsabschluss anzeigen"
                disabled-tooltip="Registrierung wurde bereits abgeschlossen"
                :disabled="isRegistrationLinkDisabled(userInvite)"
              />
            </registration-link-dialog>

            <a-confirmation-dialog
              title="Einladung erneut verschicken"
              button-text="Verschicken"
              highlight="warning"
              :action="() => resendUserInviteConfirmed(userInvite)"
              :is-processing="store.isResendUserInviteProcessing"
              :is-disabled="isResendUserInviteDisabled(userInvite)"
            >
              <icon-button
                small
                :icon="['far', 'paper-plane']"
                tooltip="Einladung erneut versenden"
                :disabled-tooltip="resendUserInviteDisabledTooltip(userInvite)"
                :disabled="isResendUserInviteDisabled(userInvite)"
              />
              <template #content>
                <p>
                  Soll die Einladung wirklich erneut verschickt werden?
                </p>
              </template>
            </a-confirmation-dialog>

            <a-confirmation-dialog
              title="Einladung stornieren"
              button-text="Stornieren"
              highlight="danger"
              :action="() => cancelUserInviteConfirmed(userInvite)"
              :is-processing="store.isCancelUserInviteProcessing"
              :is-disabled="isCancelUserInviteDisabled(userInvite)"
            >
              <icon-button
                small
                :icon="['far', 'ban']"
                tooltip="Einladung stornieren"
                disabled-tooltip="Einladung wurde bereits storniert"
                :disabled="isCancelUserInviteDisabled(userInvite)"
              />
              <template #content>
                <p>
                  Soll die Einladung wirklich storniert werden?
                </p>
                <p>
                  Der Benutzer wird per E-Mail über die Stornierung informiert.
                </p>
                <a-danger-alert>
                  Wenn die Einladung bereits angenommen wurde, werden alle Daten des Benutzers unwiderruflich gelöscht.
                </a-danger-alert>
              </template>
            </a-confirmation-dialog>
          </v-list-item-icon>
        </v-list-item>

        <v-divider
          v-if="index !== store.userInvites.length - 1"
          :key="`${index}-divider`"
        />
      </template>
    </v-list>
  </v-card>

</v-container>
</template>
