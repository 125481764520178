import { ModuleId, NotificationId, NotificationType } from '@/types';

export type PushNotificationData = DeprecatedHabitReminderPushNotification | DeprecatedModuleUnlockedPushNotification;

export interface DeprecatedHabitReminderPushNotification {
  type: 'habit-reminder';
  habitIntentId: string;
}

export interface DeprecatedModuleUnlockedPushNotification {
  type: 'module-unlocked';
  moduleId: string;
}

export interface PushNotification {
  type: NotificationType;
  notificationId: NotificationId;
}

export interface ModuleUnlockedPushNotification extends PushNotification {
  type: NotificationType.MODULE_UNLOCKED;
  moduleId: ModuleId;
}
export function isModuleUnlockedPushNotification(
  notification: PushNotification
): notification is ModuleUnlockedPushNotification {
  return notification.type === NotificationType.MODULE_UNLOCKED;
}

export interface HabitReminderPushNotification extends PushNotification {
  type: NotificationType.HABIT_REMINDER;
  moduleId: ModuleId;
}
export function isHabitReminderPushNotification(
  notification: PushNotification
): notification is HabitReminderPushNotification {
  return notification.type === NotificationType.HABIT_REMINDER;
}
