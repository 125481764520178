<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { getLocalStorageItem, LOCAL_STORAGE_IMPERSONATION_ACTIVE } from '@/helpers/local-storage-helper';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useImpersonationHintStore } from './store';

@Component
export default class ImpersonationHint extends Vue {

  readonly store = useImpersonationHintStore();
  readonly authenticationStore = useAuthenticationStore();

  get isHintVisible(): boolean {
    return this.store.isImpersonationActive
      && this.authenticationStore.isAuthenticated;
  }

  get alertHTML(): string {
    return `Vorsicht: Du hast das Konto von <strong>${this.authenticationStore.user!.emailAddress}</strong> übernommen.`;
  }

  mounted(): void {
    if (getLocalStorageItem(LOCAL_STORAGE_IMPERSONATION_ACTIVE) === '1') {
      this.store.activateHint();
    }
  }
}
</script>
<template>
<v-container class="app-impersonation-hint pb-0" v-if="isHintVisible" data-cy="impersonation-hint">
  <a-danger-alert>
    <font-awesome-icon class="ml-1 ml-sm-2 mr-4" :icon="['fas', 'exclamation-triangle']" />
    <span v-html="alertHTML" />
  </a-danger-alert>
</v-container>
</template>
