<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentElementImageForPreview } from '../types';

@Component
export default class ImageElementPreview extends Vue {

  @Prop()
  readonly contentElement!: ContentElementImageForPreview;

}
</script>
<template>
<div>
  <blur-hash-image
    :hash="contentElement.image.hash"
    :src="contentElement.image.url"
    :alt="contentElement.title"
    :width="20"
    :height="Math.floor(20 / contentElement.image.aspectRatio)"
    class="mb-4"
  />
</div>
</template>
