import { FileResponse } from '@/application/types';
import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { CreateCertificateCommand, Dashboard, MarkNotificationAsSeenCommand, SendCertificateToUserCommand, UnseenNotification } from './types';
import { createCertificate, getDashboard, getNotificationStatus, getUnseenNotifications, getUserCertificatePDF, markAllNotificationAsSeen, markNotificationAsSeen, sendCertificateToUser } from './service';

interface DashboardState {
  dashboard: Dashboard | null;
  unseenNotifications: UnseenNotification[];
  hasUnhandledNotifications: boolean;

  getDashboardStatus: ActionStatus;
  getUserCertificatePDFStatus: ActionStatus;
  createCertificateStatus: ActionStatus;
  sendCertificateToUserStatus: ActionStatus;
  getUnseenNotificationsStatus: ActionStatus;
  getNotificationStatusStatus: ActionStatus;
  markNotificationAsSeenStatus: ActionStatus;
  markAllNotificationAsSeenStatus: ActionStatus;
}

function initialState(): DashboardState {
  return {
    dashboard: null,
    unseenNotifications: [],
    hasUnhandledNotifications: false,

    getDashboardStatus: ActionStatus.None,
    getUserCertificatePDFStatus: ActionStatus.None,
    createCertificateStatus: ActionStatus.None,
    sendCertificateToUserStatus: ActionStatus.None,
    getUnseenNotificationsStatus: ActionStatus.None,
    getNotificationStatusStatus: ActionStatus.None,
    markNotificationAsSeenStatus: ActionStatus.None,
    markAllNotificationAsSeenStatus: ActionStatus.None,
  };
}

export const useDashboardStore = defineStore('dashboard', {
  state: (): DashboardState => initialState(),
  getters: {
    isGetDashboardProcessing: (state: DashboardState): boolean =>
      state.getDashboardStatus === ActionStatus.InProgress,
    isGetUserCertificatePDFProcessing: (state: DashboardState): boolean =>
      state.getUserCertificatePDFStatus === ActionStatus.InProgress,
    isCreateCertificateProcessing: (state: DashboardState): boolean =>
      state.createCertificateStatus === ActionStatus.InProgress,
    isSendCertificateToUserProcessing: (state: DashboardState): boolean =>
      state.sendCertificateToUserStatus === ActionStatus.InProgress,
    isGetUnseenNotificationsProcessing: (state: DashboardState): boolean =>
      state.getUnseenNotificationsStatus === ActionStatus.InProgress,
    isGetNotificationStatusProcessing: (state: DashboardState): boolean =>
      state.getNotificationStatusStatus === ActionStatus.InProgress,
    isMarkNotificationAsSeenProcessing: (state: DashboardState): boolean =>
      state.markNotificationAsSeenStatus === ActionStatus.InProgress,
    isMarkAllNotificationAsSeenProcessing: (state: DashboardState): boolean =>
      state.markAllNotificationAsSeenStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getDashboard(): Promise<void> {
      const { getDashboardStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDashboardStatus,
        () => getDashboard(attachAppAndUser({}))
          .then(async (dashboard) => {
            this.dashboard = dashboard;
          })
      );
    },

    getUserCertificatePDF(): Promise<FileResponse> {
      const { getUserCertificatePDFStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserCertificatePDFStatus,
        () => getUserCertificatePDF(attachAppAndUser({}))
      );
    },

    getUnseenNotifications(): Promise<void> {
      const { getUnseenNotificationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUnseenNotificationsStatus,
        () => getUnseenNotifications(attachAppAndUser({}))
          .then(async (unseenNotifications) => {
            this.unseenNotifications = unseenNotifications;
            this.hasUnhandledNotifications = unseenNotifications.length > 0;
          })
      );
    },

    getNotificationStatus(): Promise<void> {
      const { getNotificationStatusStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getNotificationStatusStatus,
        () => getNotificationStatus(attachAppAndUser({}))
          .then(async (notificationStatus) => {
            this.hasUnhandledNotifications = notificationStatus.hasUnhandledNotifications;
          })
      );
    },

    // -- Commands

    createCertificate(command: CreateCertificateCommand): Promise<void> {
      const { createCertificateStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createCertificateStatus,
        () => createCertificate(attachAppAndUser(command))
          .then(() => this.getDashboard())
      );
    },

    sendCertificateToUser(command: SendCertificateToUserCommand): Promise<void> {
      const { sendCertificateToUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        sendCertificateToUserStatus,
        () => sendCertificateToUser(attachAppAndUser(command))
          .then(() => this.getDashboard())
      );
    },

    // Doesn't refresh the seen notifications as this can also happen outside the dashboard
    markNotificationAsSeen(command: MarkNotificationAsSeenCommand): Promise<void> {
      const { markNotificationAsSeenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markNotificationAsSeenStatus,
        () => markNotificationAsSeen(attachAppAndUser(command))
      );
    },

    markAllNotificationAsSeen(): Promise<void> {
      const { markAllNotificationAsSeenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markAllNotificationAsSeenStatus,
        () => markAllNotificationAsSeen(attachAppAndUser({}))
          .then(() => this.getUnseenNotifications())
      );
    },

  },
});
