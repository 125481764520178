import { RouteConfig } from 'vue-router';
import { Role } from '@/helpers/data';
import Dashboard from './components/dashboard.vue';

export const managementDashboardRoutes: RouteConfig[] = [
  {
    name: 'app-manager-dashboard',
    path: '/app-manager-dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiresRole: Role.ROLE_APP_MANAGER,
      title: 'Übersicht',
      breadcrumbs: [
        { text: 'Übersicht' },
      ],
    },
  },
];
