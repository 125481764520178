import { LOCAL_STORAGE_ADMIN_ID, LOCAL_STORAGE_IMPERSONATION_ACTIVE, LOCAL_STORAGE_USER_ID, removeLocalStorageItem } from '@/helpers/local-storage-helper';
import { useManageModulesStore } from '@/private/management/modules/store';
import { useOnboardingStore } from '@/private/user/onboarding/store';
import { useBreadcrumbStore } from '@/application/common/breadcrumb/store';
import { useSnackbarStore } from '@/application/common/snackbar/store';
import { useAdminAuthenticationStore } from '@/application/admin/authentication/store';
import { useReferencesStore } from '@/private/management/references/store';
import { useAdminUserManagementStore } from '@/admin/user-management/store';
import { useAdminPaywallAccessStore } from '@/admin/paywall-access/store';
import { useAdminManageAppsStore } from '@/admin/manage-apps/store';
import { useAppStore } from '@/application/common/app/store';
import { useImpersonationHintStore } from '@/application/whitelabel/app/components/impersonation-hint/store';
import { useContactSupportStore } from '@/private/user/contact-support/store';
import { useBibliographyStore } from '@/private/user/publications/store';
import { useManagePublicationsStore } from '@/private/management/publications/store';
import { usePushNotificationsStore } from '@/application/whitelabel/push-notifications/store';
import { useManageHabitsStore } from '@/private/management/habits/store';
import { useManageHabitIntentsStore } from '@/private/user/habits/store';
import { useDashboardStore } from '@/private/user/dashboard/store';
import { useNutritionDiaryStore } from '@/private/user/nutrition-diary/store';
import { useConsumeBlogArticlesStore } from '@/private/user/blog-articles/store';
import { useManageBlogArticlesStore } from '@/private/management/blog-articles/store';
import { useUserProgressStore } from '@/private/user/user-progress/store';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useConsumeModulesStore } from '@/private/user/modules/store';
import { useManageSponsorsStore } from '@/private/management/sponsors/store';
import { useAppManagerDashboardStore } from '@/private/management/dashboard/store';
import { useAdminAccountingStore } from '@/admin/accounting/store';
import { useManageDocumentsStore } from '@/private/management/documents/store';
import { useConsumeExperienceReportsStore } from '@/private/user/experience-reports/store';
import { useManageRecipesStore } from '@/private/management/recipes/store';
import { useCertificateManagementStore } from '@/private/management/certificate-management/store';
import { useManageExperienceReportsStore } from '@/private/management/experience-reports/store';
import { useConsumeRecipesStore } from '@/private/user/recipes/store';
import { useConsumeDocumentsStore } from '@/private/user/documents/store';
import { useUnauthorizedContactStore } from '@/public/contact/store';
import { useUserInvitesStore } from '@/private/management/user-invites/store';

export function clearAllStores() {
  // -- Application

  // Common
  useBreadcrumbStore().$reset();
  useSnackbarStore().$reset();
  useAppStore().$reset();

  // Admin
  useAdminAuthenticationStore().$reset();

  // Whitelabel
  useAuthenticationStore().resetWithoutAppData();
  useImpersonationHintStore().$reset();

  // -- Domain

  // Admin
  useAdminManageAppsStore().$reset();
  useAdminPaywallAccessStore().$reset();
  useAdminUserManagementStore().$reset();

  // Whitelabel
  useReferencesStore().$reset();
  useContactSupportStore().$reset();
  useManageModulesStore().$reset();
  useDashboardStore().$reset();
  useConsumeModulesStore().$reset();
  useManageHabitsStore().$reset();
  useManageHabitIntentsStore().$reset();
  useOnboardingStore().$reset();
  useUserProgressStore().$reset();
  useBibliographyStore().$reset();
  useManagePublicationsStore().$reset();
  usePushNotificationsStore().$reset();
  useNutritionDiaryStore().$reset();
  useManageBlogArticlesStore().$reset();
  useConsumeBlogArticlesStore().$reset();
  useManageSponsorsStore().$reset();

  useConsumeDocumentsStore().$reset();
  useUnauthorizedContactStore().$reset();
  useUserInvitesStore().$reset();
  useManageRecipesStore().$reset();
  useConsumeRecipesStore().$reset();
  useManageExperienceReportsStore().$reset();
  useConsumeExperienceReportsStore().$reset();
  useManageDocumentsStore().$reset();
  useAdminAccountingStore().$reset();
  useAppManagerDashboardStore().$reset();
  useCertificateManagementStore().$reset();

  removeLocalStorageItem(LOCAL_STORAGE_USER_ID);
  removeLocalStorageItem(LOCAL_STORAGE_ADMIN_ID);
  removeLocalStorageItem(LOCAL_STORAGE_IMPERSONATION_ACTIVE);
  // App id is not removed as the app never changes
}
