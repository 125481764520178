import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { UserGoalProposal, CreateUserGoalProposalCommand, UpdateUserGoalProposalCommand, DeleteUserGoalProposalCommand } from './types';
import { createUserGoalProposal, deleteUserGoalProposal, getUserGoalProposals, updateUserGoalProposal } from './service';

interface GoalTrackingManagementState {
  userGoalProposals: UserGoalProposal[];

  getUserGoalProposalsStatus: ActionStatus;
  createUserGoalProposalStatus: ActionStatus;
  updateUserGoalProposalStatus: ActionStatus;
  deleteUserGoalProposalStatus: ActionStatus;
}

function initialState(): GoalTrackingManagementState {
  return {
    userGoalProposals: [],

    getUserGoalProposalsStatus: ActionStatus.None,
    createUserGoalProposalStatus: ActionStatus.None,
    updateUserGoalProposalStatus: ActionStatus.None,
    deleteUserGoalProposalStatus: ActionStatus.None,
  };
}

export const useGoalTrackingManagementStore = defineStore('goalTrackingManagement', {
  state: (): GoalTrackingManagementState => initialState(),
  getters: {
    isGetUserGoalProposalsProcessing: (state: GoalTrackingManagementState): boolean =>
      state.getUserGoalProposalsStatus === ActionStatus.InProgress,
    isCreateUserGoalProposalProcessing: (state: GoalTrackingManagementState): boolean =>
      state.createUserGoalProposalStatus === ActionStatus.InProgress,
    isUpdateUserGoalProposalProcessing: (state: GoalTrackingManagementState): boolean =>
      state.updateUserGoalProposalStatus === ActionStatus.InProgress,
    isDeleteUserGoalProposalProcessing: (state: GoalTrackingManagementState): boolean =>
      state.deleteUserGoalProposalStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getUserGoalProposals(): Promise<void> {
      const { getUserGoalProposalsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserGoalProposalsStatus,
        () => getUserGoalProposals(attachAppAndUser({}))
          .then(async (userGoalProposals) => {
            this.userGoalProposals = userGoalProposals;
          })
      );
    },

    // -- Commands

    createUserGoalProposal(command: CreateUserGoalProposalCommand): Promise<void> {
      const { createUserGoalProposalStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createUserGoalProposalStatus,
        () => createUserGoalProposal(attachAppAndUser(command))
          .then(() => this.getUserGoalProposals())
      );
    },

    updateUserGoalProposal(command: UpdateUserGoalProposalCommand): Promise<void> {
      const { updateUserGoalProposalStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserGoalProposalStatus,
        () => updateUserGoalProposal(attachAppAndUser(command))
          .then(() => this.getUserGoalProposals())
      );
    },

    deleteUserGoalProposal(command: DeleteUserGoalProposalCommand): Promise<void> {
      const { deleteUserGoalProposalStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteUserGoalProposalStatus,
        () => deleteUserGoalProposal(attachAppAndUser(command))
          .then(() => this.getUserGoalProposals())
      );
    },

  },
});
