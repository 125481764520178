<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CreateSponsorDialog from './create-sponsor-dialog.vue';
import UpdateSponsorNameDialog from './update-sponsor-name-dialog.vue';
import UpdateAvailableSeatsForSponsorDialog from './update-available-seats-for-sponsor-dialog.vue';
import { useManageSponsorsStore } from '../store';

@Component({
  components: {
    CreateSponsorDialog,
    UpdateSponsorNameDialog,
    UpdateAvailableSeatsForSponsorDialog,
  },
})
export default class SponsorList extends Vue {

  readonly store = useManageSponsorsStore();

  // Load is triggered in the parent component

}
</script>
<template>
<v-container>

  <create-sponsor-dialog>
    <a-trigger-primary-process-button>
      Sponsor erstellen
    </a-trigger-primary-process-button>
  </create-sponsor-dialog>

  <v-card
    class="mt-4 mb-4 overflow-hidden"
    outlined
  >
    <a-action-list
      :items="store.sponsors"
      item-id="sponsorId"
      :is-loading="store.isGetSponsorsProcessing"
      empty-list-message="Noch keine Sponsoren angelegt."
    >
      <template #content="{ item: sponsor }">
        <router-link :to="{ name: 'manage-sponsors-manage-seats', params: { sponsorId: sponsor.sponsorId }}">
          {{ sponsor.name }}
        </router-link>
        <span class="details">
          {{ sponsor.availableSeats }} Lizenzen
        </span>
      </template>
      <template #actions="{ item: sponsor }">

        <icon-menu small :icon="['far', 'pencil']">
          <update-sponsor-name-dialog :sponsor="sponsor">
            <v-list-item @click="$root.$emit('close-icon-menu')">
              <v-list-item-title>Name anpassen</v-list-item-title>
            </v-list-item>
          </update-sponsor-name-dialog>
          <update-available-seats-for-sponsor-dialog :sponsor="sponsor">
            <v-list-item @click="$root.$emit('close-icon-menu')">
              <v-list-item-title>Verfügbare Lizenzen anpassen</v-list-item-title>
            </v-list-item>
          </update-available-seats-for-sponsor-dialog>
        </icon-menu>

      </template>
    </a-action-list>
  </v-card>

</v-container>
</template>
