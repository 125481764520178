import { Command } from '@/application/types';
import { BlurHashImage } from '@/helpers/data';
import { BlogArticleId, DocumentId, ExperienceReportId, HabitId, ModuleId, RecipeId, UserGoalProposalId } from '@/types';

export interface FeatureExamples {
  modules: Module[];
  recipes: Recipe[];
  experienceReports: ExperienceReport[];
  blogArticles: BlogArticle[];
  documents: Document[];
  habits: Habit[];
}

export interface Module {
  moduleId: ModuleId;
  title: string;
  image?: BlurHashImage;
  completionPercentage: number;
  isCompleted: boolean;
}

export interface Recipe {
  recipeId: RecipeId;
  name: string;
  subTitle?: string;
  totalTimeInMinutes: number;
  heroImage: BlurHashImage;
}

export interface ExperienceReport {
  experienceReportId: ExperienceReportId;
  name: string;
  job: string;
  image: BlurHashImage;
}

export interface BlogArticle {
  blogArticleId: BlogArticleId;
  title: string;
  image: BlurHashImage;
  isNew: boolean;
}

export interface Document {
  documentId: DocumentId;
  title: string;
  shortDescription: string;
}

export interface Habit {
  habitId: HabitId;
  title: string;
  isCompleted: boolean;
}

export enum OnboardingStep {
  WELCOME = 'WELCOME',
  ACKNOWLEDGE_CONTRAINDICATION = 'ACKNOWLEDGE_CONTRAINDICATION',
  USER_GOALS = 'USER_GOALS',
  HEALTH_POINTS = 'HEALTH_POINTS',
  MODULES = 'MODULES',
  NUTRITION_DIARY = 'NUTRITION_DIARY',
  HABITS = 'HABITS',
  DOCUMENTS = 'DOCUMENTS',
  RECIPES = 'RECIPES',
  EXPERIENCE_REPORTS = 'EXPERIENCE_REPORTS',
  BLOG = 'BLOG',
}

export interface UserGoalProposal {
  userGoalProposalId: UserGoalProposalId;
  title: string;
}

// -- Commands

export interface MarkOnboardingAsCompletedForUserCommand extends Command {
  hasSeenAllSteps: boolean;
}
