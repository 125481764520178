import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { getExperienceReports } from './service';
import { ExperienceReport } from './types';

interface ConsumeExperienceReportsState {
  experienceReports: ExperienceReport[];
  currentExperienceReportId: string | null;

  getExperienceReportsStatus: ActionStatus;
}

function initialState(): ConsumeExperienceReportsState {
  return {
    experienceReports: [],
    currentExperienceReportId: null,

    getExperienceReportsStatus: ActionStatus.None,
  };
}

export const useConsumeExperienceReportsStore = defineStore('consumeExperienceReports', {
  state: (): ConsumeExperienceReportsState => initialState(),
  getters: {
    currentExperienceReport: (state: ConsumeExperienceReportsState): ExperienceReport | null =>
      state.currentExperienceReportId !== null
        ? state.experienceReports
          .find((experienceReport) => experienceReport.experienceReportId === state.currentExperienceReportId) ?? null
        : null,

    isGetExperienceReportsProcessing: (state: ConsumeExperienceReportsState): boolean =>
      state.getExperienceReportsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentExperienceReport(experienceReportId: string): void {
      this.currentExperienceReportId = experienceReportId;
    },

    resetCurrentExperienceReport(): void {
      this.currentExperienceReportId = null;
    },

    // -- Queries

    getExperienceReports(): Promise<void> {
      const { getExperienceReportsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getExperienceReportsStatus,
        () => getExperienceReports(attachAppAndUser({}))
          .then(async (experienceReports) => {
            this.experienceReports = experienceReports;
          })
      );
    },

  },
});
