import { RouteConfig } from 'vue-router';
import ModuleList from './components/module-list.vue';
import ConsumeModule from './components/consume-module.vue';

export const consumeModulesRoutes: RouteConfig[] = [
  {
    path: '/module',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'consume-modules-module-list',
        path: '/',
        component: ModuleList,
        meta: {
          requiresAuth: true,
          title: 'Alle Module',
        },
      },
      {
        name: 'consume-modules-consume-module',
        path: 'inhalt/:id',
        component: ConsumeModule,
        meta: {
          requiresAuth: true,
          title: 'Modul',
          styleClass: 'consume-modules-consume-module',
        },
      },
    ],
  },
];
