<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth, nutritionDiaryTipTypeTranslations } from '@/helpers/data';
import { NutritionDiaryTip } from '../types';

@Component
export default class ShowNewTipDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.small;

  tip: NutritionDiaryTip | null = null;

  isDialogVisible = false;

  // Used from the timeline
  public showTip(tip: NutritionDiaryTip): void {
    this.tip = tip;
    this.isDialogVisible = true;
  }

  get type(): string {
    return nutritionDiaryTipTypeTranslations[this.tip!.type];
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <div class="d-block" v-on="on">
      <slot />
    </div>
  </template>
  <v-card v-if="tip">
    <v-card-title>Neuer Tipp</v-card-title>

    <v-card-text class="pt-0 pb-4">

      <strong>{{ type }}</strong>: {{ tip.description }}

    </v-card-text>

    <v-divider />
    <v-card-actions>
      <v-spacer />
      <a-trigger-primary-process-button @click="closeDialog">
        Schließen
      </a-trigger-primary-process-button>
    </v-card-actions>

  </v-card>
</v-dialog>
</template>
