<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { Feature } from '@/types';
import { useAppManagerDashboardStore } from '../store';
import { Conversions } from '../types';

@Component
export default class Dashboard extends Vue {

  readonly store = useAppManagerDashboardStore();
  readonly authenticationStore = useAuthenticationStore();

  get isLoading(): boolean {
    return this.store.isGetConversionHistoryProcessing;
  }

  get courseConfigurationText(): string {
    return this.authenticationStore.courseConfiguration!.areContentChangesAllowed
      ? 'Du kannst die Inhalte verwalten.'
      : 'Die Verwaltung der Inhalte ist gesperrt.';
  }

  get isConversionHistoryVisible(): boolean {
    return this.isSalesDashboardFeatureEnabled;
  }

  get isSalesDashboardFeatureEnabled(): boolean {
    return this.authenticationStore.isFeatureEnabled(Feature.SALES_DASHBOARD);
  }

  mounted(): void {
    if (this.isSalesDashboardFeatureEnabled) {
      this.store.getConversionHistory()
        .catch((error) => showErrorResponse(error));
    }
  }

  totalConversions(conversions: Conversions): number {
    return conversions.webPayment
      + conversions.iosPayment
      + conversions.androidPayment
      + conversions.machtfit
      + conversions.proFit
      + conversions.directLicense;
  }

}
</script>
<template>
<v-container>

  <v-card outlined>
    <v-card-text>

      <p class="mb-0">
        {{ courseConfigurationText }}
      </p>

    </v-card-text>
  </v-card>

  <v-card outlined class="mt-4" v-if="isConversionHistoryVisible">
    <v-progress-linear v-if="isLoading" color="primary" indeterminate />
    <v-card-text v-else>
      <strong class="d-block mb-4">Käufe nach Monat und Kanal</strong>
      <v-simple-table class="mb-0">
        <template #default>
          <thead>
          <tr>
            <th class="text-left">Monat</th>
            <th class="text-right">Web</th>
            <th class="text-right">iOS</th>
            <th class="text-right">Android</th>
            <th class="text-right">Machtfit</th>
            <th class="text-right">proFit</th>
            <th class="text-right">Direkt-Lizenz</th>
            <th class="text-right">Gesamt</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="store.conversionHistory.length === 0">
            <td class="text-center" colspan="8">Keine Transaktionen verfügbar</td>
          </tr>
          <tr v-for="conversions in store.conversionHistory" :key="conversions.month.format('YYYY-MM')">
            <td class="text-left">{{ conversions.month.format('YYYY MMMM') }}</td>
            <td class="text-right">{{ conversions.webPayment }}</td>
            <td class="text-right">{{ conversions.iosPayment }}</td>
            <td class="text-right">{{ conversions.androidPayment }}</td>
            <td class="text-right">{{ conversions.machtfit }}</td>
            <td class="text-right">{{ conversions.proFit }}</td>
            <td class="text-right">{{ conversions.directLicense }}</td>
            <td class="text-right"><strong>{{ totalConversions(conversions) }}</strong></td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p class="mt-2 mb-0 notice">
        Es werden ausschließlich die Käufe über verschiedene Kanäle angezeigt. Stornierungen werden nicht abgezogen.
      </p>
    </v-card-text>
  </v-card>

</v-container>
</template>
<style lang="sass" scoped>
.notice
  font-size: 0.75rem
</style>
