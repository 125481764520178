import { apiUrl, performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { AppAndUserProperty, AppAndUserPropertyForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { Document, CreateDocumentCommand, DeleteDocumentCommand, UpdateDocumentFileCommand, UpdateDocumentDescriptionCommand, UpdateOrderOfDocumentsAsManagerCommand } from './types';

// -- Queries

export function getDocuments(query: AppAndUserProperty): Promise<Document[]> {
  const url = `${apiUrl}/api/documents/get-documents-for-management-query`;
  return performApiRequest<Document[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createDocument(command: CreateDocumentCommand & AppAndUserPropertyForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/documents/create-document-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateDocumentDescription(command: UpdateDocumentDescriptionCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/documents/update-document-description-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateDocumentFile(command: UpdateDocumentFileCommand & AppAndUserPropertyForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/documents/update-document-file-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function deleteDocument(command: DeleteDocumentCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/documents/delete-document-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateOrderOfDocuments(command: UpdateOrderOfDocumentsAsManagerCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/documents/update-order-of-documents-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
