import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { acknowledgeContraindications, getFeatureExamples, getUserGoalProposals, markOnboardingAsCompletedForUser } from './service';
import { FeatureExamples, MarkOnboardingAsCompletedForUserCommand, UserGoalProposal } from './types';

interface OnboardingState {
  featureExamples: FeatureExamples | null;
  userGoalProposals: UserGoalProposal[];

  getUserGoalProposalsStatus: ActionStatus;
  acknowledgeContraindicationsStatus: ActionStatus;
  markOnboardingAsCompletedForUserStatus: ActionStatus;
  getFeatureExamplesStatus: ActionStatus;
}

function initialState(): OnboardingState {
  return {
    featureExamples: null,
    userGoalProposals: [],

    getUserGoalProposalsStatus: ActionStatus.None,
    getFeatureExamplesStatus: ActionStatus.None,
    acknowledgeContraindicationsStatus: ActionStatus.None,
    markOnboardingAsCompletedForUserStatus: ActionStatus.None,
  };
}

export const useOnboardingStore = defineStore('onboarding', {
  state: (): OnboardingState => initialState(),
  getters: {
    isGetUserGoalProposalsProcessing: (state: OnboardingState): boolean =>
      state.getUserGoalProposalsStatus === ActionStatus.InProgress,
    isGetFeatureExamplesProcessing: (state: OnboardingState): boolean =>
      state.getFeatureExamplesStatus === ActionStatus.InProgress,
    isAcknowledgeContraindicationsProcessing: (state: OnboardingState): boolean =>
      state.acknowledgeContraindicationsStatus === ActionStatus.InProgress,
    isMarkOnboardingAsCompletedForUserProcessing: (state: OnboardingState): boolean =>
      state.markOnboardingAsCompletedForUserStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getUserGoalProposals(): Promise<void> {
      const { getUserGoalProposalsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserGoalProposalsStatus,
        () => getUserGoalProposals(attachAppAndUser({}))
          .then(async (userGoalProposals) => {
            this.userGoalProposals = userGoalProposals;
          })
      );
    },

    getFeatureExamples(): Promise<void> {
      const { getFeatureExamplesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFeatureExamplesStatus,
        () => getFeatureExamples(attachAppAndUser({}))
          .then(async (featureExamples) => {
            this.featureExamples = featureExamples;
          })
      );
    },

    // -- Commands

    acknowledgeContraindications(): Promise<void> {
      const { acknowledgeContraindicationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        acknowledgeContraindicationsStatus,
        () => acknowledgeContraindications(attachAppAndUser({}))
          .then(() => useAuthenticationStore().fetchAuthenticatedUser())
      );
    },

    markOnboardingAsCompletedForUser(command: MarkOnboardingAsCompletedForUserCommand): Promise<void> {
      const { markOnboardingAsCompletedForUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markOnboardingAsCompletedForUserStatus,
        () => markOnboardingAsCompletedForUser(attachAppAndUser(command))
          .then(() => useAuthenticationStore().fetchAuthenticatedUser())
      );
    },

  },
});
