import { RouteConfig } from 'vue-router';
import Login from './components/login.vue';

export const adminAuthenticationRoutes: RouteConfig[] = [
  {
    name: 'admin-authentication-login',
    path: '/login',
    component: Login,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      title: 'Anmeldung',
    },
  },
];
