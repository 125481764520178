<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { useBreadcrumbStore } from '../store';

@Component
export default class Breadcrumb extends Vue {

  readonly store = useBreadcrumbStore();

  mounted(): void {
    watch(() => this.$route.path, () => {
      this.store.updateItems(this.$route.meta?.breadcrumbs);
    }, { immediate: true });
  }

}
</script>
<template>
<v-container class="breadcrumb-container" :class="{ 'has-items': store.items.length > 0 }">
  <v-breadcrumbs :items="store.items">
    <template #divider>
      <font-awesome-icon :icon="['fas', 'chevron-right']" />
    </template>
  </v-breadcrumbs>
</v-container>
</template>
<style lang="sass">
.container
  &.breadcrumb-container
    padding: 0

    &.has-items
      padding: 30px 15px 15px

    @media print
      padding: 0 15px !important

    ul.v-breadcrumbs
      z-index: 1
      padding: 0

      .v-breadcrumbs__divider
        svg
          font-size: 0.75rem

      li
        font-size: 1rem

        &:nth-child(even)
          padding: 0 8px

        div.v-breadcrumbs__item
          font-weight: 600
          color: var(--color-grey-2)

        a.v-breadcrumbs__item
          font-weight: normal
          color: var(--color-grey-2)

          &:hover
            color: var(--color-brand-5)
</style>
