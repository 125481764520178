<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { UserGoalId } from '@/types';
import { UserGoalProgresses, UserGoalProgressRating } from '../types';

@Component({
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class UserGoalProgressesFormControl extends Vue implements FormControlComponent<UserGoalProgressRating[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<UserGoalProgressRating[]>;

  @Prop({ type: Array, required: true })
  readonly userGoalProgresses!: UserGoalProgresses;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: UserGoalProgressRating[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);

    // Update to do the conversion from null
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  userGoalTitel(userGoalId: UserGoalId): string {
    return this.userGoalProgresses
      .find((userGoalProgress) => userGoalProgress.userGoalId === userGoalId)!
      .title;
  }

  ratingChanged(): void {
    this.focused();

    internalValuesChanged(this);
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? this.userGoalProgresses.map((userGoalProgress) => ({
        userGoalId: userGoalProgress.userGoalId,
        // I'm not sure if it should have a 1 for a fallback or wether that's a bug and should never by null / undefined
        userGoalProgressScore: userGoalProgress.latestScore ?? 1,
      }))
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<UserGoalProgressRating[]> {
    return this.internalValue.length > 0
      ? this.internalValue
      : null;
  }

}
</script>
<template>
<div class="form-control user-goal-progress-form-control" v-bind="$attrs">
  <fieldset class="a-field py-3">
    <legend>{{ labelWithRequiredIndicator(formControl) }}</legend>
    <div class="form-control-input">

      <div
        v-for="(userGoalProgressRating, index) in internalValue"
        :key="userGoalProgressRating.userGoalId"
      >
        <span
          class="d-block user-goal-title"
          :class="{ 'mt-3': index > 0 }"
        >
          {{ userGoalTitel(userGoalProgressRating.userGoalId) }}
        </span>
        <v-slider
          :min="1"
          :max="10"
          color="primary"
          v-model="userGoalProgressRating.userGoalProgressScore"
          @change="ratingChanged"
          @mouseup="blurred"
          thumb-label
          hide-details
        />
      </div>

    </div>
  </fieldset>

    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
</div>
</template>
<style lang="sass" scoped>
.v-input
  ::v-deep
    .v-slider
      margin-left: 0 !important
      margin-right: 0 !important

.user-goal-title
  font-size: 0.875rem
</style>
