<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, minArrayCountRule, ValuesOfFormControls } from '@/components/form';
import { ContentElementQuizAnswerId } from '@/types';
import { useManageModulesStore } from '../store';
import { CreateContentElementQuizCommand, ContentElementQuizAnswer, ModulePage } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  question: FormControl<string>;
  possibleAnswers: FormControl<ContentElementQuizAnswer[]>;
  correctAnswer: FormControl<ContentElementQuizAnswerId>;
}

@Component
export default class CreateQuizDialog extends Vue {

  readonly store = useManageModulesStore();

  @Prop({ type: Object, required: true })
  readonly modulePage!: ModulePage;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get contentElementAnswers(): ContentElementQuizAnswer[] {
    if (!this.form) {
      return [];
    }

    const formValues = getFormValues(this.form);

    return formValues.possibleAnswers ?? [];
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Bezeichnung',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        question: {
          label: 'Frage',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        possibleAnswers: {
          label: 'Mögliche Antworten',
          value: null,
          isRequired: true,
          rules: [
            minArrayCountRule(2, 'Es müssen mindestens zwei mögliche Antworten angegeben werden.'),
          ],
          transformValuesOnInput: (values: ValuesOfFormControls<Controls>) => values.possibleAnswers
            ?.some((possibleAnswer) => possibleAnswer.contentElementQuizAnswerId === values.correctAnswer)
            ? {}
            : {
              correctAnswer: null,
            },
          validateFormControlsOnInput: [
            'correctAnswer',
          ],
        },
        correctAnswer: {
          label: 'Korrekte Antwort',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateContentElementQuizCommand = {
      modulePageId: this.modulePage.modulePageId,
      contentElementTitle: formValues.title!,
      quizQuestion: formValues.question!,
      quizPossibleAnswers: formValues.possibleAnswers!,
      quizCorrectAnswerId: formValues.correctAnswer!,
    };

    this.store.createContentElementQuiz(command)
      .then(() => showSuccessMessage('Quiz wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateContentElementQuizProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Quiz erstellen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <a-text-form-control
              :form-control="form.controls.title"
              is-autofocused
            />
          </v-col>
          <v-col cols="12">
            <a-text-form-control :form-control="form.controls.question" />
          </v-col>
          <v-col cols="12">
            <a-possible-answer-form-control :form-control="form.controls.possibleAnswers" />
          </v-col>
          <v-col cols="12">
            <a-content-element-quiz-answer-form-control
              :form-control="form.controls.correctAnswer"
              :items="contentElementAnswers"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateContentElementQuizProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateContentElementQuizProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Erstellen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
