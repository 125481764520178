import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { FeatureExamples, MarkOnboardingAsCompletedForUserCommand, UserGoalProposal } from './types';

// -- Queries

export function getUserGoalProposals(query: AppAndUserProperty): Promise<UserGoalProposal[]> {
  const url = `${apiUrl}/api/user-progress/get-user-goal-proposals-query`;
  return performApiRequest<UserGoalProposal[]>({ url, method: 'POST', data: query });
}

export function getFeatureExamples(query: AppAndUserProperty): Promise<FeatureExamples> {
  const url = `${apiUrl}/api/onboarding/get-feature-examples-query`;
  return performApiRequest<FeatureExamples>({ url, method: 'POST', data: query });
}

// -- Commands

export function acknowledgeContraindications(command: AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/onboarding/acknowledge-contraindications-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markOnboardingAsCompletedForUser(command: MarkOnboardingAsCompletedForUserCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/onboarding/mark-onboarding-as-completed-for-user-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
