import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { UserProgress, RateUserGoalProgressCommand, DeleteUserGoalCommand, CreateUserGoalCommand, UpdateUserGoalCommand, UserGoalProposal } from './types';

// -- Queries

export function getUserProgress(query: AppAndUserProperty): Promise<UserProgress> {
  const url = `${apiUrl}/api/user-progress/get-user-progress-query`;
  return performApiRequest({ url, method: 'POST', data: query });
}

export function getUserGoalProposals(query: AppAndUserProperty): Promise<UserGoalProposal[]> {
  const url = `${apiUrl}/api/user-progress/get-user-goal-proposals-query`;
  return performApiRequest({ url, method: 'POST', data: query });
}

// -- Commands

export function deleteUserGoal(command: DeleteUserGoalCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-progress/delete-user-goal-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function rateUserGoalProgress(command: RateUserGoalProgressCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-progress/rate-user-goal-progress-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createUserGoal(command: CreateUserGoalCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-progress/create-user-goal-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateUserGoal(command: UpdateUserGoalCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-progress/update-user-goal-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function logUserGoalRatingRejection(command: AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-progress/log-user-goal-rating-rejection-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
