<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { constructForm, Form, FormControl, FormControls, getFormValues, identicalPasswordFormControlRule } from '@/components/form';
import { UpdateUserPasswordCommand } from '../types';

interface Controls extends FormControls {
  currentPassword: FormControl<string>;
  newPassword: FormControl<string>;
  repeatPassword: FormControl<string>;
}

@Component
export default class UpdatePasswordDialog extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        currentPassword: {
          label: 'Aktuelles Passwort',
          value: null,
          isRequired: true,
        },
        newPassword: {
          label: 'Neues Passwort',
          value: null,
          isRequired: true,
          rules: [],
          validateFormControlsOnInput: [
            'repeatPassword',
          ],
        },
        repeatPassword: {
          label: 'Passwort wiederholen',
          value: null,
          isRequired: true,
          rules: [],
          validateFormControlsOnInput: [
            'newPassword',
          ],
        },
      },
    });

    form.controls.newPassword.rules!.push(identicalPasswordFormControlRule(form.controls.repeatPassword));
    form.controls.repeatPassword.rules!.push(identicalPasswordFormControlRule(form.controls.newPassword));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateUserPasswordCommand = {
      userCurrentPassword: formValues.currentPassword!,
      userNewPassword: formValues.newPassword!,
    };

    this.authenticationStore.updateUserPassword(command)
      .then(() => showSuccessMessage('Dein Passwort wurde geändert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="authenticationStore.isUpdateUserPasswordProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Passwort anpassen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <a-password-form-control
              :form-control="form.controls.currentPassword"
              is-autofocused
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <a-password-form-control :form-control="form.controls.newPassword" />
          </v-col>
          <v-col cols="12" md="6">
            <a-password-form-control :form-control="form.controls.repeatPassword" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="authenticationStore.isUpdateUserPasswordProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="authenticationStore.isUpdateUserPasswordProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
