import { RouteConfig } from 'vue-router';
import AboutNubali from './components/about-nubali.vue';

export const aboutNubaliRoutes: RouteConfig[] = [
  {
    name: 'about-nubali',
    path: '/ueber-nubali',
    component: AboutNubali,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      title: 'Über Nubali',
    },
  },
];
