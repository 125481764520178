import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser, attachAppAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { Document, CreateDocumentCommand, DeleteDocumentCommand, UpdateDocumentDescriptionCommand, UpdateDocumentFileCommand, UpdateOrderOfDocumentsAsManagerCommand } from './types';
import { createDocument, deleteDocument, getDocuments, updateDocumentDescription, updateDocumentFile, updateOrderOfDocuments } from './service';

interface ManageDocumentsState {
  documents: Document[];

  getDocumentsStatus: ActionStatus;
  createDocumentStatus: ActionStatus;
  updateDocumentDescriptionStatus: ActionStatus;
  updateDocumentFileStatus: ActionStatus;
  deleteDocumentStatus: ActionStatus;
  updateOrderOfDocumentsStatus: ActionStatus;
}

function initialState(): ManageDocumentsState {
  return {
    documents: [],

    getDocumentsStatus: ActionStatus.None,
    createDocumentStatus: ActionStatus.None,
    updateDocumentDescriptionStatus: ActionStatus.None,
    updateDocumentFileStatus: ActionStatus.None,
    deleteDocumentStatus: ActionStatus.None,
    updateOrderOfDocumentsStatus: ActionStatus.None,
  };
}

export const useManageDocumentsStore = defineStore('manageDocuments', {
  state: (): ManageDocumentsState => initialState(),
  getters: {
    isGetDocumentsProcessing: (state: ManageDocumentsState): boolean =>
      state.getDocumentsStatus === ActionStatus.InProgress,
    isCreateDocumentProcessing: (state: ManageDocumentsState): boolean =>
      state.createDocumentStatus === ActionStatus.InProgress,
    isUpdateDocumentDescriptionProcessing: (state: ManageDocumentsState): boolean =>
      state.updateDocumentDescriptionStatus === ActionStatus.InProgress,
    isUpdateDocumentFileProcessing: (state: ManageDocumentsState): boolean =>
      state.updateDocumentFileStatus === ActionStatus.InProgress,
    isDeleteDocumentProcessing: (state: ManageDocumentsState): boolean =>
      state.deleteDocumentStatus === ActionStatus.InProgress,
    isUpdateOrderOfDocumentsProcessing: (state: ManageDocumentsState): boolean =>
      state.updateOrderOfDocumentsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getDocuments(): Promise<void> {
      const { getDocumentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDocumentsStatus,
        () => getDocuments(attachAppAndUser({}))
          .then(async (documents) => {
            this.documents = documents;
          })
      );
    },

    // -- Commands

    createDocument(command: CreateDocumentCommand): Promise<void> {
      const { createDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createDocumentStatus,
        () => createDocument(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getDocuments())
      );
    },

    updateDocumentDescription(command: UpdateDocumentDescriptionCommand): Promise<void> {
      const { updateDocumentDescriptionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateDocumentDescriptionStatus,
        () => updateDocumentDescription(attachAppAndUser(command))
          .then(() => this.getDocuments())
      );
    },

    updateDocumentFile(command: UpdateDocumentFileCommand): Promise<void> {
      const { updateDocumentFileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateDocumentFileStatus,
        () => updateDocumentFile(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getDocuments())
      );
    },

    deleteDocument(command: DeleteDocumentCommand): Promise<void> {
      const { deleteDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteDocumentStatus,
        () => deleteDocument(attachAppAndUser(command))
          .then(() => this.getDocuments())
      );
    },

    updateOrderOfDocuments(command: UpdateOrderOfDocumentsAsManagerCommand): Promise<void> {
      const { updateOrderOfDocumentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateOrderOfDocumentsStatus,
        () => updateOrderOfDocuments(attachAppAndUser(command))
          .then(() => this.getDocuments())
      );
    },

  },
});
