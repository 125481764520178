import { Route } from 'vue-router';
import dayjs from 'dayjs';
import { getLocalStorageItem, setLocalStorageItem } from '@/helpers/local-storage-helper';
import { useAppStore } from '@/application/common/app/store';

function setAppVersionReloadTimestamp() {
  const currentTime = dayjs().format();
  setLocalStorageItem('last_app_version_reload', currentTime);
}

function isAppReloadNeeded(): boolean {
  const lastAppVersionReloadTime = getLocalStorageItem('last_app_version_reload');
  const wasLastReloadAMinuteAgo = !lastAppVersionReloadTime
    || dayjs().subtract(60, 'seconds').isAfter(lastAppVersionReloadTime);

  return useAppStore().isOutdated && wasLastReloadAMinuteAgo;
}

// eslint-disable-next-line no-unused-vars
export function appVersionGuard(to: Route, from: Route) {
  if (isAppReloadNeeded()) {
    setAppVersionReloadTimestamp();

    // Requires timeout to ensure that the reload process happens on the new route
    setTimeout(() => window.location.reload(), 100);
  }
}
