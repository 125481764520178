<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { downloadFile } from '@/helpers/file-download-helper';
import { useConsumeDocumentsStore } from '../store';
import { Document, GetDocumentFileQuery, SendDocumentViaEmailCommand } from '../types';

@Component
export default class DocumentList extends Vue {

  readonly store = useConsumeDocumentsStore();

  get isLoading(): boolean {
    return this.store.isGetDocumentsProcessing;
  }

  get areNoDocumentsVisible(): boolean {
    return !this.isLoading
      && this.store.documents.length === 0;
  }

  mounted(): void {
    this.store.getDocuments()
      .catch((error) => showErrorResponse(error));
  }

  downloadDocumentClicked(document: Document): void {
    const query: GetDocumentFileQuery = {
      documentId: document.documentId,
    };

    this.store.getDocumentFile(query)
      .then((file) => downloadFile(
        file.data,
        file.contentType,
        document.fileName
      ))
      .catch((error) => showErrorResponse(error));
  }

  sendDocumentViaEmailClicked(document: Document): void {
    const command: SendDocumentViaEmailCommand = {
      documentId: document.documentId,
    };

    this.store.sendDocumentViaEmail(command)
      .then(() => showSuccessMessage('Das Dokument ist per E-Mail unterwegs zu dir.'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container class="document-list">

  <v-progress-linear v-if="isLoading" color="primary" indeterminate />

  <p v-if="areNoDocumentsVisible">
    Noch keine Dokumente angelegt.
  </p>

  <v-row class="mt-2 mt-md-4">
    <v-col
      cols="12"
      md="6"
      lg="4"
      v-for="document in store.documents"
      :key="document.documentId"
      class="document mb-6"
    >
      <strong class="document-title d-block">{{ document.title }}</strong>
      <span class="short-description d-block">{{ document.shortDescription }}</span>

      <v-row
        no-gutters
        class="mt-3"
      >
        <v-col class="mr-1">
          <a-execute-secondary-action-button
            @click="downloadDocumentClicked(document)"
            is-small
            is-always-shown-in-block-mode
          >
            Herunterladen
          </a-execute-secondary-action-button>
        </v-col>
        <v-col class="ml-1">
          <a-execute-secondary-action-button
            @click="sendDocumentViaEmailClicked(document)"
            is-small
            is-always-shown-in-block-mode
          >
            Als E-Mail
          </a-execute-secondary-action-button>
        </v-col>
      </v-row>

    </v-col>
  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.document-list
  font-size: 0.875rem

  .document

    strong.document-title
      font-weight: 700
      font-family: Montserrat, sans-serif
      color: var(--color-grey-2)
</style>
