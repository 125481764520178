import { FormControl, FormControlRule, FormControlValue } from '@/components/form';
import { PublicationType } from '@/types';

export function requiredWhenBookRule(publicationFormControl: FormControl<PublicationType>): FormControlRule<FormControlValue<string>> {
  return (value) => (
    publicationFormControl.value !== PublicationType.BOOK
    || value !== null
  ) ? true
    : 'Dieses Feld ist erforderlich, wenn die der Typ "Buch" ist.';
}

export function requiredWhenOnlineRule(publicationFormControl: FormControl<PublicationType>): FormControlRule<FormControlValue<string>> {
  return (value) => (
    publicationFormControl.value !== PublicationType.ONLINE
    || value !== null
  ) ? true
    : 'Dieses Feld ist erforderlich, wenn die der Typ "Online" ist.';
}
