import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.areNotificationsVisible)?_c('div',_vm._b({},'div',_vm.$attrs,false),[_c('h3',[_vm._v("Benachrichtigungen")]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"9"}},[_c(VCard,{staticClass:"mt-2",attrs:{"outlined":""}},[_c(VList,{staticClass:"py-0",attrs:{"dense":""}},[_vm._l((_vm.knownNotifications),function(unseenNotification,index){return [_c(VListItem,{key:unseenNotification.notificationId,staticClass:"px-3 py-1",on:{"click":function($event){return _vm.notificationClicked(unseenNotification)}}},[_c(VListItemContent,[(_vm.doesNotificationLeadToDifferentPage(unseenNotification.configuration))?_c('strong',{staticClass:"d-block primary--text"},[_vm._v(" "+_vm._s(_vm.notificationDescription(unseenNotification.configuration))+" ")]):_c('span',{staticClass:"d-block primary--text"},[_vm._v(" "+_vm._s(_vm.notificationDescription(unseenNotification.configuration))+" ")]),_c('span',{staticClass:"d-block mt-1"},[_vm._v(_vm._s(unseenNotification.configuration.payload.context))])])],1),(index !== _vm.knownNotifications.length - 1)?_c(VDivider,{key:`divider-${unseenNotification.notificationId}`}):_vm._e()]})],2)],1),(_vm.knownNotifications.length > 0)?_c('a-execute-secondary-action-button',{staticClass:"mt-2",attrs:{"is-processing":_vm.store.isMarkAllNotificationAsSeenProcessing,"is-small":""},on:{"click":_vm.markAllNotificationsAsSeen}},[_vm._v(" Alle als gesehen markieren ")]):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }