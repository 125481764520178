// Vue setup
import Vue from 'vue';
import 'babel-polyfill';
import { App as CapacitorApp } from '@capacitor/app';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { SplashScreen } from '@capacitor/splash-screen';
// -- Core and plugins
import { vuetify } from '@/plugins/vuetify';
import { pinia } from '@/plugins/pinia';
import { router as whitelabelRouter } from '@/router/whitelabel';
import { router as adminRouter } from '@/router/admin';
import '@/plugins/font-awesome';
import '@/plugins/dayjs';
import '@/plugins/deep-links';
import '@/plugins/chartjs';
import '@/plugins/design-system';
import '@/plugins/global-components';
import '@/plugins/form-controls';
import '@/plugins/polyfills';
import '@/sass/app.sass';
import '@/filters';
import { initSentry, initSentryWatchers } from '@/plugins/sentry';
import initAuthenticationInterceptor from '@/application/whitelabel/authentication/axios-authentication-response-interceptor';
import initAdminAuthenticationInterceptor from '@/application/admin/authentication/axios-authentication-response-interceptor';
import initAppVersionInterceptor from '@/application/common/app-version-hint/axios-app-version-interceptor';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { storeDeviceInformation } from '@/infrastructure/device-information';
// -- Components
import WhitelabelApp from '@/application/whitelabel/app/components/app.vue';
import AdminApp from '@/application/admin/app/components/app.vue';

function initWhitelabelApplication(): void {
  new Vue({
    router: whitelabelRouter,
    pinia,
    vuetify,
    render: (h) => h(WhitelabelApp),
    beforeMount() {
      // Maintenance and app version interceptors are registered in the routing as they need to be loaded as part of the access guard
      initAuthenticationInterceptor();
      initSentry();
      initSentryWatchers();
    },
    mounted(): void {
      if (isNativeApplication()) {
        CapacitorApp.getInfo()
          .then((appInfo) => storeDeviceInformation(appInfo));

        CapacitorUpdater.notifyAppReady();

        if (SplashScreen) {
          SplashScreen.hide();
        }
      }
    },
  }).$mount('#app');
}

// Admin application is not used in a native app
function initAdminApplication(): void {
  new Vue({
    router: adminRouter,
    pinia,
    vuetify,
    render: (h) => h(AdminApp),
    beforeMount: () => {
      // Axios executes all interceptors in the order of registration, switching between success/error handler as applicable.
      if (!isNativeApplication()) {
        initAppVersionInterceptor();
      }
      initAdminAuthenticationInterceptor();
      initSentry();
    },
  }).$mount('#app');
}

const adminHostRegex = /^admin\.vitomy\./;
// eslint-disable-next-line no-restricted-globals
const doesApplicationHaveToBeBootedAsAdmin = adminHostRegex.test(location.host);

// Boot depending on host
if (doesApplicationHaveToBeBootedAsAdmin) {
  document.title = `Vitomy Admin`;
  initAdminApplication();
} else {
  initWhitelabelApplication();
}
