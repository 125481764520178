import { RouteConfig } from 'vue-router';
import UserProgress from './components/user-progress.vue';

export const userProgressRoutes: RouteConfig[] = [
  {
    path: '/fortschritt',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        // name is used in health-points-badge
        name: 'user-progress-user-goal-list',
        path: '/',
        component: UserProgress,
        meta: {
          requiresAuth: true,
          title: 'Mein Fortschritt',
        },
      },
    ],
  },
];
