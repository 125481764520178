import { HabitIntentId, ModuleId, NotificationConfiguration, NotificationPayload, NotificationType } from './value-objects';

export interface ModuleUnlockedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.MODULE_UNLOCKED;
  payload: ModuleUnlockedNotificationPayload;
}
export interface ModuleUnlockedNotificationPayload extends NotificationPayload {
  moduleId: ModuleId;
}
export function isModuleUnlockedNotification(
  configuration: NotificationConfiguration
): configuration is ModuleUnlockedNotificationConfiguration {
  return configuration.type === NotificationType.MODULE_UNLOCKED;
}

export interface HabitReminderNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.HABIT_REMINDER;
  payload: HabitReminderNotificationPayload;
}
export interface HabitReminderNotificationPayload extends NotificationPayload {
  habitIntentId: HabitIntentId;
}
export function isHabitReminderNotification(
  configuration: NotificationConfiguration
): configuration is HabitReminderNotificationConfiguration {
  return configuration.type === NotificationType.HABIT_REMINDER;
}
