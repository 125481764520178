<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import CreateUserGoalProposalDialog from './create-user-goal-proposal-dialog.vue';
import UpdateUserGoalProposalDialog from './update-user-goal-proposal-dialog.vue';
import { useGoalTrackingManagementStore } from '../store';
import { DeleteUserGoalProposalCommand, UserGoalProposal } from '../types';

@Component({
  components: {
    CreateUserGoalProposalDialog,
    UpdateUserGoalProposalDialog,
  },
})
export default class UserGoalProposalList extends Vue {

  readonly store = useGoalTrackingManagementStore();

  mounted(): void {
    this.store.getUserGoalProposals()
      .catch((error) => showErrorResponse(error));
  }

  async deleteUserGoalProposalConfirmed(userGoalProposal: UserGoalProposal): Promise<void> {
    const command: DeleteUserGoalProposalCommand = {
      userGoalProposalId: userGoalProposal.userGoalProposalId,
    };
    return this.store.deleteUserGoalProposal(command)
      .then(() => showSuccessMessage('Zielvorschlag wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <create-user-goal-proposal-dialog>
    <a-trigger-primary-process-button>
      Zielvorschlag erstellen
    </a-trigger-primary-process-button>
  </create-user-goal-proposal-dialog>

  <v-card
    class="mt-4 mb-4 overflow-hidden"
    outlined
  >
    <a-action-list
      :items="store.userGoalProposals"
      item-id="userGoalProposalId"
      :is-loading="store.isGetUserGoalProposalsProcessing"
      empty-list-message="Noch keine Zielvorschläge angelegt."
    >
      <template #content="{ item: userGoalProposal }">
        <span class="item-title">
          {{ userGoalProposal.title }}
        </span>
      </template>
      <template #actions="{ item: userGoalProposal }">

        <update-user-goal-proposal-dialog :user-goal-proposal="userGoalProposal">
          <icon-button
            small
            :icon="['far', 'pencil']"
            tooltip="Zielvorschlag anpassen"
          />
        </update-user-goal-proposal-dialog>

        <a-confirmation-dialog
          title="Zielvorschlag löschen"
          button-text="Löschen"
          highlight="danger"
          :action="() => deleteUserGoalProposalConfirmed(userGoalProposal)"
          :is-processing="store.isDeleteUserGoalProposalProcessing"
        >
          <icon-button
            small
            :icon="['far', 'times']"
            tooltip="Löschen"
          />
          <template #content>
            <p>
              Soll der Zielvorschlag wirklich gelöscht werden?
            </p>
          </template>
        </a-confirmation-dialog>

      </template>
    </a-action-list>
  </v-card>

</v-container>
</template>
