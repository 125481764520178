<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { constructForm, Form, FormControl, FormControls, getFormValues, notIdenticalStringRule } from '@/components/form';
import { EmailAddress } from '@/types';
import { RequestEmailChangeCommand } from '../types';

interface Controls extends FormControls {
  emailAddress: FormControl<EmailAddress>;
}

@Component
export default class UpdateEmailAddressDialog extends Vue {

  readonly store = useAuthenticationStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        emailAddress: {
          label: 'E-Mail Adresse',
          value: null,
          isRequired: true,
          rules: [
            notIdenticalStringRule(this.store.user!.emailAddress, 'Das ist bereits die aktuelle E-Mail Adresse'),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: RequestEmailChangeCommand = {
      userEmailAddress: formValues.emailAddress!,
    };

    this.store.requestEmailChange(command)
      .then(() => showSuccessMessage(
        'Anfrage abgeschickt! Bitte klicke auf den Link in der E-Mail die wir dir zur Bestätigung geschickt haben',
        5000
      ))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isRequestEmailChangeProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>E-Mail Adresse anpassen</v-card-title>
      <v-card-text>
        <p>
          Deine aktuelle E-Mail Adresse lautet:<br />
          {{ store.user.emailAddress }}
        </p>
        <p>
          Wenn die E-Mail Adresse angepasst wird, muss die neue E-Mail Adresse erst bestätigt werden. Dazu schicken wir dir eine E-Mail mit einem Bestätigungslink an die neue E-Mail Adresse. Das bedeutet:
        </p>
        <ul class="mb-6">
          <li>Die E-Mail Adresse wird erst mit dem Klick auf den Link geändert.</li>
          <li>Erst danach ist die Anmeldung mit der neuen E-Mail Adresse möglich.</li>
        </ul>
        <v-row>
          <v-col cols="12" sm="9">
            <a-email-address-form-control
              :form-control="form.controls.emailAddress"
              is-autofocused
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isRequestEmailChangeProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isRequestEmailChangeProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
