import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { CreateHabitLogCommand, DisableHabitIntentCommand, EnableHabitIntentCommand, HabitIntent, UpdateHabitIntentCommand, CreateHabitIntentCommand, Habit } from './types';

// -- Queries

export function getHabitIntents(query: AppAndUserProperty): Promise<HabitIntent[]> {
  const url = `${apiUrl}/api/learning/get-habit-intents-query`;
  return performApiRequest<HabitIntent[]>({ url, method: 'POST', data: query });
}

export function getHabits(query: AppAndUserProperty): Promise<Habit[]> {
  const url = `${apiUrl}/api/learning/get-habits-query`;
  return performApiRequest<Habit[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function updateHabitIntent(command: UpdateHabitIntentCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-habit-intent-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function enableHabitIntent(command: EnableHabitIntentCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/enable-habit-intent-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function disableHabitIntent(command: DisableHabitIntentCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/disable-habit-intent-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createHabitLog(command: CreateHabitLogCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-habit-log-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createHabitIntent(command: CreateHabitIntentCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-habit-intent-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
