import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser, attachAppAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { addNutritionDiaryEntryImage, createNutritionDiaryEntry, deleteNutritionDiaryEntry, deleteNutritionDiaryEntryImage, getNutritionDiaryTimeline, updateConsumedAtOfNutritionDiaryEntry, updateNutritionDiaryEntryDescription } from './service';
import { AddNutritionDiaryEntryImageCommand, CreateNutritionDiaryEntryCommand, TimelineEntry, DeleteNutritionDiaryEntryImageCommand, UpdateNutritionDiaryEntryDescriptionCommand, DeleteNutritionDiaryEntryCommand, UpdateConsumedAtOfNutritionDiaryEntryCommand } from './types';

interface NutritionDiaryState {
  timelineEntries: TimelineEntry[];

  getNutritionDiaryTimelineStatus: ActionStatus;
  createNutritionDiaryEntryStatus: ActionStatus;
  updateNutritionDiaryEntryDescriptionStatus: ActionStatus;
  updateConsumedAtOfNutritionDiaryEntryStatus: ActionStatus;
  addNutritionDiaryEntryImageStatus: ActionStatus;
  deleteNutritionDiaryEntryImageStatus: ActionStatus;
  deleteNutritionDiaryEntryStatus: ActionStatus;
}

function initialState(): NutritionDiaryState {
  return {
    timelineEntries: [],

    getNutritionDiaryTimelineStatus: ActionStatus.None,
    createNutritionDiaryEntryStatus: ActionStatus.None,
    updateNutritionDiaryEntryDescriptionStatus: ActionStatus.None,
    updateConsumedAtOfNutritionDiaryEntryStatus: ActionStatus.None,
    addNutritionDiaryEntryImageStatus: ActionStatus.None,
    deleteNutritionDiaryEntryImageStatus: ActionStatus.None,
    deleteNutritionDiaryEntryStatus: ActionStatus.None,
  };
}

export const useNutritionDiaryStore = defineStore('nutritionDiary', {
  state: (): NutritionDiaryState => initialState(),
  getters: {
    isGetNutritionDiaryTimelineProcessing: (state: NutritionDiaryState): boolean =>
      state.getNutritionDiaryTimelineStatus === ActionStatus.InProgress,
    isCreateNutritionDiaryEntryProcessing: (state: NutritionDiaryState): boolean =>
      state.createNutritionDiaryEntryStatus === ActionStatus.InProgress,
    isUpdateNutritionDiaryEntryDescriptionProcessing: (state: NutritionDiaryState): boolean =>
      state.updateNutritionDiaryEntryDescriptionStatus === ActionStatus.InProgress,
    isUpdateConsumedAtOfNutritionDiaryEntryProcessing: (state: NutritionDiaryState): boolean =>
      state.updateConsumedAtOfNutritionDiaryEntryStatus === ActionStatus.InProgress,
    isAddNutritionDiaryEntryImageProcessing: (state: NutritionDiaryState): boolean =>
      state.addNutritionDiaryEntryImageStatus === ActionStatus.InProgress,
    isDeleteNutritionDiaryEntryImageProcessing: (state: NutritionDiaryState): boolean =>
      state.deleteNutritionDiaryEntryImageStatus === ActionStatus.InProgress,
    isDeleteNutritionDiaryEntryProcessing: (state: NutritionDiaryState): boolean =>
      state.deleteNutritionDiaryEntryStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getNutritionDiaryTimeline(): Promise<void> {
      const { getNutritionDiaryTimelineStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getNutritionDiaryTimelineStatus,
        () => getNutritionDiaryTimeline(attachAppAndUser({}))
          .then(async (timelineEntries) => {
            this.timelineEntries = timelineEntries;
          })
      );
    },

    // -- Commands

    createNutritionDiaryEntry(command: CreateNutritionDiaryEntryCommand): Promise<void> {
      const { createNutritionDiaryEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createNutritionDiaryEntryStatus,
        () => createNutritionDiaryEntry(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getNutritionDiaryTimeline())
      );
    },

    addNutritionDiaryEntryImage(command: AddNutritionDiaryEntryImageCommand): Promise<void> {
      const { addNutritionDiaryEntryImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addNutritionDiaryEntryImageStatus,
        () => addNutritionDiaryEntryImage(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getNutritionDiaryTimeline())
      );
    },

    updateNutritionDiaryEntryDescription(command: UpdateNutritionDiaryEntryDescriptionCommand): Promise<void> {
      const { updateNutritionDiaryEntryDescriptionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateNutritionDiaryEntryDescriptionStatus,
        () => updateNutritionDiaryEntryDescription(attachAppAndUser(command))
          .then(() => this.getNutritionDiaryTimeline())
      );
    },

    updateConsumedAtOfNutritionDiaryEntry(command: UpdateConsumedAtOfNutritionDiaryEntryCommand): Promise<void> {
      const { updateConsumedAtOfNutritionDiaryEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateConsumedAtOfNutritionDiaryEntryStatus,
        () => updateConsumedAtOfNutritionDiaryEntry(attachAppAndUser(command))
          .then(() => this.getNutritionDiaryTimeline())
      );
    },

    deleteNutritionDiaryEntryImage(command: DeleteNutritionDiaryEntryImageCommand): Promise<void> {
      const { deleteNutritionDiaryEntryImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteNutritionDiaryEntryImageStatus,
        () => deleteNutritionDiaryEntryImage(attachAppAndUser(command))
          .then(() => this.getNutritionDiaryTimeline())
      );
    },

    deleteNutritionDiaryEntry(command: DeleteNutritionDiaryEntryCommand): Promise<void> {
      const { deleteNutritionDiaryEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteNutritionDiaryEntryStatus,
        () => deleteNutritionDiaryEntry(attachAppAndUser(command))
          .then(() => this.getNutritionDiaryTimeline())
      );
    },

  },
});
