import { IngredientUnit, NutritionDiaryTipType, PublicationType, SponsorId, Weekday } from '@/types';

export interface UserRole {
  role: Role;
  meta: UserRoleMeta | null;
}
export enum Role {
  ROLE_USER = 'ROLE_USER',
  ROLE_SPONSOR_MANAGER = 'ROLE_SPONSOR_MANAGER',
  ROLE_APP_MANAGER = 'ROLE_APP_MANAGER',
}
export type UserRoleMeta = UserRoleMetaForSponsor;

export interface UserRoleMetaForSponsor {
  sponsorId: SponsorId;
}

export enum DialogWidth {
  small = 500,
  medium = 650,
  large = 800,
  largeNested = 760,
}

export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const MONTH_FORMAT = 'MMMM';

export const weekdayTranslation: Record<Weekday, string> = {
  MONDAY: 'Montag',
  TUESDAY: 'Dienstag',
  WEDNESDAY: 'Mittwoch',
  THURSDAY: 'Donnerstag',
  FRIDAY: 'Freitag',
  SATURDAY: 'Samstag',
  SUNDAY: 'Sonntag',
};

export type CoursePaywallAccessThrough =
  | 'manual-release'
  | 'web-payment'
  | 'ios-payment'
  | 'android-payment'
  | 'machtfit'
  | 'profit'
  | 'direct-license';
export const coursePaywallAccessThroughTranslation: Record<CoursePaywallAccessThrough, string> = {
  'manual-release': 'Manuelle Freischaltung',
  'web-payment': 'Web Kauf',
  'ios-payment': 'iOS Kauf',
  'android-payment': 'Android Kauf',
  machtfit: 'Machtfit',
  profit: 'proFit',
  'direct-license': 'Direkte Lizenz',
};

export const ingredientUnitTranslations: Record<IngredientUnit, string> = {
  PIECE: 'Stück',
  GRAM: 'Gramm',
  MILLILITER: 'Milliliter',
  TABLESPOON: 'Esslöffel',
  TEASPOON: 'Teelöffel',
  BUNCH: 'Bund',
  HANDFUL: 'Handvoll',
  TO_TASTE: 'Nach Geschmack',
};

export interface BlurHashImage {
  url: string;
  hash: string;
  aspectRatio: number;
}

export type MimeType =
  | 'image/jpeg'
  | 'image/png'
  | 'application/pdf'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export type MimeTypeKeys =
  | 'JPEG'
  | 'PNG'
  | 'PDF'
  | 'DOC'
  | 'DOCX';
export const MimeTypes: Record<MimeTypeKeys, MimeType> = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export enum SeatStatus {
  INVITED = 'INVITED',
  REGISTRATION_STARTED = 'REGISTRATION_STARTED',
  ACCEPTED = 'ACCEPTED',
}

export type YearMonth = string;

export type NotificationType =
  | 'EMAIL_HABIT_REMINDER'
  | 'PUSH_HABIT_REMINDER'
  | 'EMAIL_MODULE_UNLOCKED'
  | 'PUSH_MODULE_UNLOCKED';
export const notificationTypes: Record<NotificationType, NotificationType> = {
  EMAIL_HABIT_REMINDER: 'EMAIL_HABIT_REMINDER',
  PUSH_HABIT_REMINDER: 'PUSH_HABIT_REMINDER',
  EMAIL_MODULE_UNLOCKED: 'EMAIL_MODULE_UNLOCKED',
  PUSH_MODULE_UNLOCKED: 'PUSH_MODULE_UNLOCKED',
};

export const publicationTypeTranslations: Record<PublicationType, string> = {
  BOOK: 'Buch',
  ONLINE: 'Online',
};

export const nutritionDiaryTipTypeTranslations: Record<NutritionDiaryTipType, string> = {
  CHECK: 'Überprüfe',
  FACT: 'Fakt',
  CHALLENGE: 'Challenge',
};
