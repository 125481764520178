import { apiUrl, performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { AppAndUserProperty, AppAndUserPropertyForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { Recipe, CreateRecipeCommand, PublishRecipeCommand, UnpublishRecipeCommand, UpdateRecipeCommand, UpdateOrderOfRecipesAsManagerCommand } from './types';

// -- Queries

export function getRecipes(query: AppAndUserProperty): Promise<Recipe[]> {
  const url = `${apiUrl}/api/recipes/get-recipes-for-management-query`;
  return performApiRequest<Recipe[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createRecipe(command: CreateRecipeCommand & AppAndUserPropertyForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/recipes/create-recipe-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateRecipe(command: UpdateRecipeCommand & AppAndUserPropertyForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/recipes/update-recipe-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function publishRecipe(command: PublishRecipeCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/recipes/publish-recipe-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function unpublishRecipe(command: UnpublishRecipeCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/recipes/unpublish-recipe-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateOrderOfRecipes(command: UpdateOrderOfRecipesAsManagerCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/recipes/update-order-of-recipes-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
