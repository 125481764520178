import { RouteConfig } from 'vue-router';
import { Role } from '@/helpers/data';
import { Feature } from '@/types';
import ManageSponsors from './components/manage-sponsors.vue';
import SponsorList from './components/sponsor-list.vue';
import ManageSeats from './components/manage-seats.vue';
import ManageSeatsAsSponsor from './components/manage-seats-as-sponsor.vue';

export const manageSponsorsRoutes: RouteConfig[] = [
  {
    path: 'sponsoren-verwalten',
    component: ManageSponsors,
    meta: {
      requiresAuth: true,
      requiresRole: Role.ROLE_APP_MANAGER,
      requiresFeature: Feature.SPONSORS,
    },
    children: [
      {
        name: 'manage-sponsors-sponsor-list',
        path: '/',
        component: SponsorList,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          requiresFeature: Feature.SPONSORS,
          title: 'Sponsoren verwalten',
          breadcrumbs: [
            { text: 'Sponsoren verwalten' },
          ],
        },
      },
      {
        name: 'manage-sponsors-manage-seats',
        path: ':sponsorId/lizenzen-zuordnen',
        component: ManageSeats,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          requiresFeature: Feature.SPONSORS,
          title: 'Lizenzen zuordnen',
          breadcrumbs: [
            { text: 'Sponsoren verwalten', to: { name: 'manage-sponsors-sponsor-list' } },
          ],
        },
      },
    ],
  },
  {
    name: 'manage-seats-as-sponsor',
    path: 'lizenzen-verwalten',
    component: ManageSeatsAsSponsor,
    meta: {
      requiresAuth: true,
      requiresRole: Role.ROLE_SPONSOR_MANAGER,
      requiresFeature: Feature.SPONSORS,
      title: 'Lizenzen verwalten',
      breadcrumbs: [
        { text: 'Lizenzen verwalten' },
      ],
    },
  },
];
