<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRule, InternalValueRules, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { EmailAddress } from '@/types';

function multiEmailRule(): InternalValueRule<string> {
  return (value) => value.trim().length === 0
    || value.split('\n')
      .map((line) => line.toLowerCase().trim())
      .every((line) => isValidEmailAddress(line))
    ? true
    : 'Mindestens eine E-Mail Adresse ist nicht gültig';
}

function isValidEmailAddress(value: string): boolean {
  return /.+@.+\..+/.test(value);
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class MultiEmailAddressTextareaFormControl extends Vue implements FormControlComponent<EmailAddress[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<EmailAddress[]>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  readonly formControlId = createFormControlId();

  readonly internalFieldRules: InternalValueRules = [
    multiEmailRule(),
  ];

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  textChanged(): void {
    if (this.internalValue === null) {
      this.internalValue = '';
    }

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    const messages = [
      ...errorMessagesForInternalRules(this.internalFieldRules, this.internalValue),
    ];

    this.messages = messages;

    return wasValidationSuccessful(messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ''
      : this.formControl.value.join('\n');
  }

  formValueFromInternalValues(): FormControlValue<EmailAddress[]> {
    return this.internalValue.trim().length > 0
      && multiEmailRule()(this.internalValue) === true
      ? this.internalValue
        .split('\n')
        .filter((line) => line.trim().length > 0)
        .map((line) => line.toLowerCase().trim())
      : null;
  }

}
</script>
<template>
<div class="form-control multi-email-address-textarea-form-control" v-bind="$attrs">
  <div class="form-control-input">
    <v-textarea
      v-model="internalValue"
      :label="labelWithRequiredIndicator(formControl)"
      @input="textChanged"
      @focus="focused"
      @blur="blurred"
      :autofocus="isAutofocused ? $vuetify.breakpoint.mdAndUp : false"
      :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
      outlined
      hide-details
    />
    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
  </div>
</div>
</template>
