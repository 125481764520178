import { apiUrl, performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { FileResponse } from '@/application/types';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { CreateContentElementAcknowledgementCommand, CreateContentElementDocumentCommand, CreateContentElementHabitProposalCommand, CreateContentElementImageCommand, CreateContentElementQuizCommand, CreateContentElementRichTextCommand, CreateContentElementSelfReflectionCommand, CreateContentElementVideoCommand, CreateModuleCommand, CreateModulePageCommand, DeleteContentElementCommand, DeleteModuleCommand, DisableModuleCommand, DisableModulePageCommand, EnableModuleCommand, EnableModulePageCommand, GetModulePageForPreviewQuery, GetModuleQuery, GetModuleSummaryPDFQuery, Module, ModuleListItem, ModulePageForPreview, MoveContentElementDownCommand, MoveContentElementUpCommand, MoveModuleDownCommand, MoveModulePageDownCommand, MoveModulePageUpCommand, MoveModuleUpCommand, UpdateContentElementAcknowledgementCommand, UpdateContentElementDocumentCommand, UpdateContentElementHabitProposalCommand, UpdateContentElementImageCommand, UpdateContentElementQuizCommand, UpdateContentElementRichTextCommand, UpdateContentElementSelfReflectionCommand, UpdateContentElementVideoCommand, UpdateModuleImageCommand, UpdateModulePageCommand, UpdateModuleTitleCommand } from './types';

// -- Queries

export function getModules(query: AppAndUserProperty): Promise<ModuleListItem[]> {
  const url = `${apiUrl}/api/learning/get-modules-for-management-query`;
  return performApiRequest({ url, method: 'POST', data: query });
}

export function getModule(query: GetModuleQuery): Promise<Module> {
  const url = `${apiUrl}/api/learning/get-module-for-management-query`;
  return performApiRequest({ url, method: 'POST', data: query });
}

export function getModulePageForPreview(query: GetModulePageForPreviewQuery): Promise<ModulePageForPreview> {
  const url = `${apiUrl}/api/learning/get-module-page-for-preview-for-management-query`;
  return performApiRequest({ url, method: 'POST', data: query });
}

export function getModuleSummaryPDF(query: GetModuleSummaryPDFQuery & AppAndUserProperty): Promise<FileResponse> {
  const url = `${apiUrl}/api/learning/get-module-summary-pdf-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

// -- Commands

export function createModule(command: CreateModuleCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-module-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateModuleImage(command: UpdateModuleImageCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-module-image-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateModuleTitle(command: UpdateModuleTitleCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-module-title-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function moveModuleUp(command: MoveModuleUpCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/move-module-up-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function moveModuleDown(command: MoveModuleDownCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/move-module-down-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function enableModule(command: EnableModuleCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/enable-module-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function disableModule(command: DisableModuleCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/disable-module-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteModule(command: DeleteModuleCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/delete-module-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createModulePage(command: CreateModulePageCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-module-page-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateModulePage(command: UpdateModulePageCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-module-page-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function moveModulePageUp(command: MoveModulePageUpCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/move-module-page-up-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function moveModulePageDown(command: MoveModulePageDownCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/move-module-page-down-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function enableModulePage(command: EnableModulePageCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/enable-module-page-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function disableModulePage(command: DisableModulePageCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/disable-module-page-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createContentElementRichText(command: CreateContentElementRichTextCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-content-element-rich-text-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateContentElementRichText(command: UpdateContentElementRichTextCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-content-element-rich-text-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createContentElementVideo(command: CreateContentElementVideoCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-content-element-video-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateContentElementVideo(command: UpdateContentElementVideoCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-content-element-video-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createContentElementImage(command: CreateContentElementImageCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-content-element-image-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateContentElementImage(command: UpdateContentElementImageCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-content-element-image-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function createContentElementQuiz(command: CreateContentElementQuizCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-content-element-quiz-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateContentElementQuiz(command: UpdateContentElementQuizCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-content-element-quiz-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createContentElementHabitProposal(command: CreateContentElementHabitProposalCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-content-element-habit-proposal-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateContentElementHabitProposal(command: UpdateContentElementHabitProposalCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-content-element-habit-proposal-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function moveContentElementUp(command: MoveContentElementUpCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/move-content-element-up-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function moveContentElementDown(command: MoveContentElementDownCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/move-content-element-down-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteContentElement(command: DeleteContentElementCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/delete-content-element-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createContentElementAcknowledgement(command: CreateContentElementAcknowledgementCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-content-element-acknowledgement-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateContentElementAcknowledgement(command: UpdateContentElementAcknowledgementCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-content-element-acknowledgement-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createContentElementDocument(command: CreateContentElementDocumentCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-content-element-document-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateContentElementDocument(command: UpdateContentElementDocumentCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-content-element-document-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createContentElementSelfReflection(command: CreateContentElementSelfReflectionCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-content-element-self-reflection-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateContentElementSelfReflection(command: UpdateContentElementSelfReflectionCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/update-content-element-self-reflection-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
