<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useReferencesStore } from '@/private/management/references/store';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useManageBlogArticlesStore } from '../store';
import { CreateBlogArticleCommand } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  image: FormControl<Blob>;
  idsOfPromotedModules: FormControl<string[]>;
}

@Component
export default class CreateBlogArticleDialog extends Vue {

  readonly store = useManageBlogArticlesStore();
  readonly referencesStore = useReferencesStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isLoading(): boolean {
    return this.referencesStore.isGetModuleReferencesForAppProcessing;
  }

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      if (this.isDialogVisible) {
        this.referencesStore.getModuleReferencesForApp()
          .then(() => {
            this.form = this.buildForm();
          });
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Titel',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        image: {
          label: 'Cover Bild',
          value: null,
          isRequired: true,
        },
        idsOfPromotedModules: {
          label: 'Links zu Modulen',
          value: [],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateBlogArticleCommand = {
      body: {
        title: formValues.title!,
        idsOfPromotedModules: formValues.idsOfPromotedModules!,
      },
      files: {
        image: formValues.image!,
      },
    };

    this.store.createBlogArticle(command)
      .then(() => showSuccessMessage('Blog Artikel wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateBlogArticleProcessing"
>
  <template #activator="{ on }">
    <div class="d-inline-block" v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <v-progress-linear v-if="isLoading" indeterminate />
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Blog Artikel erstellen</v-card-title>
      <v-card-text>
        <a-image-cropper-form-control :form-control="form.controls.image" />
        <v-row>
          <v-col cols="12">
            <a-text-form-control
              :form-control="form.controls.title"
              is-autofocused
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-multi-module-form-control
              :form-control="form.controls.idsOfPromotedModules"
              :items="referencesStore.modules"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateBlogArticleProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateBlogArticleProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Erstellen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
