<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import TableOfContentsNavigation from './table-of-contents-navigation.vue';

@Component({
  components: {
    TableOfContentsNavigation,
  },
})
export default class TableOfContentsPage extends Vue {}
</script>
<template>
<div>
  <h2 class="pl-4 pt-4 pr-4 pb-2">Inhaltsverzeichnis</h2>
  <table-of-contents-navigation />
</div>
</template>
