<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { EmailAddress } from '@/types';
import { useUnauthorizedContactStore } from '../store';
import { CreateContactMessageCommand } from '../types';

interface Controls extends FormControls {
  emailAddress: FormControl<EmailAddress>;
  message: FormControl<string>;
}

@Component
export default class CreateContactMessageDialog extends Vue {

  readonly store = useUnauthorizedContactStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        emailAddress: {
          label: 'Deine E-Mail Adresse',
          value: null,
          isRequired: true,
        },
        message: {
          label: 'Nachricht',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateContactMessageCommand = {
      emailAddress: formValues.emailAddress!,
      message: formValues.message!,
    };

    this.store.createContactMessage(command)
      .then(() => showSuccessMessage('Die Nachricht wurde abgeschickt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateContactMessageProcessing"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Support anfordern</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <a-email-address-form-control
              :form-control="form.controls.emailAddress"
              is-autofocused
              autocomplete="username"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-textarea-form-control
              :form-control="form.controls.message"
              :rows="10"
              placeholder="Hallo Support Team,"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateContactMessageProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateContactMessageProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Support anfordern
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
