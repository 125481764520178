<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useOnboardingStore } from '../store';

@Component
export default class ExperienceReportsStep extends Vue {

  readonly store = useOnboardingStore();
  readonly authenticationStore = useAuthenticationStore();

  submitClicked(): void {
    this.$emit('submitConfirmed');
  }

  skipClicked(): void {
    this.$emit('skipConfirmed');
  }

}
</script>
<template>
<div class="modules">
  <v-card-text class="pb-0">
    <h2>Erfahrungsberichte</h2>

    <v-row
      v-if="store.featureExamples && store.featureExamples.experienceReports.length > 0"
      class="experience-report-list mt-4 mb-4 mb-md-2"
    >
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="(experienceReport, index) in store.featureExamples.experienceReports"
        :key="experienceReport.experienceReportId"
      >

        <v-row
          class="no-gutters experience-report"
          :class="{ 'mb-4': index !== store.featureExamples.experienceReports.length - 1 }"
        >
          <v-col cols="4">
            <v-card outlined class="overflow-hidden">
              <div class="blur-hash-image-container">
                <blur-hash-image
                  :hash="experienceReport.image.hash"
                  :src="experienceReport.image.url"
                  :alt="experienceReport.name"
                  :width="20"
                  :height="Math.floor(20 / experienceReport.image.aspectRatio)"
                />
              </div>
            </v-card>
          </v-col>
          <v-col cols="8">
            <div class="experience-report-info">
              <strong class="experience-report-title">{{ experienceReport.name }}</strong>
              <span class="d-block">{{ experienceReport.job }}</span>
              <span class="d-block action-link">Lesen</span>
            </div>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <p>
      Hier erzählen andere Nutzer aus ihrem Leben. Das gibt dir eine Einblick, wie andere Personen ihr Leben durch eine angepasste Ernährung verbessert haben. Du findest Geschichten von Personen aus allen Lebensbereichen, Berufen und Altersgruppen. Da entdeckst du bestimmt den einen oder anderen Anknüpfungspunkt für dich.
    </p>
  </v-card-text>

  <v-divider />
  <v-card-actions>
    <v-spacer />
    <a-cancel-action-button @click="skipClicked">
      Einführung überspringen
    </a-cancel-action-button>
    <a-trigger-primary-process-button @click="submitClicked">
      Weiter
    </a-trigger-primary-process-button>
  </v-card-actions>

</div>
</template>
<style lang="sass" scoped>
.experience-report-list
  font-size: 0.875rem

  .experience-report
    cursor: pointer

    .experience-report-info
      padding: 0 0 0 0.75rem
      line-height: 1.15

      strong.experience-report-title
        font-weight: 700
        font-family: Montserrat, sans-serif
        color: var(--color-grey-2)
        display: block
        margin-top: 0.25rem
        margin-bottom: 0.25rem

      span::v-deep
        font-size: 0.875rem

        strong
          font-weight: 600

  .action-link
    color: var(--color-brand)
    font-weight: 600
    margin-top: 0.25rem
</style>
