import { RouteConfig } from 'vue-router';
import DefaultRedirect from '@/application/admin/default-redirect/components/default-redirect.vue';
import Logout from '@/application/admin/logout/logout.vue';
import { adminAuthenticationRoutes } from '@/application/admin/authentication/routes';
import { adminManageAppsRoutes } from '@/admin/manage-apps/routes';
import { adminPaywallAccessRoutes } from '@/admin/paywall-access/routes';
import { adminUserManagementRoutes } from '@/admin/user-management/routes';
import { adminAccountingRoutes } from '@/admin/accounting/routes';

export const routes: RouteConfig[] = [
  {
    name: 'root',
    path: '/',
    redirect: { name: 'admin-authentication-login' },
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
    },
  },
  {
    name: 'default',
    path: '/default',
    component: DefaultRedirect,
    meta: { requiresAuth: true },
  },
  {
    name: 'logout',
    path: '/logout',
    component: Logout,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      title: 'Abmeldung',
    },
  },
  {
    path: '/verwaltung',
    component: {
      template: `<div class="admin"><router-view /></div>`,
    },
    children: [
      {
        path: '',
        redirect: 'module',
      },
      ...adminUserManagementRoutes,
      ...adminPaywallAccessRoutes,
      ...adminManageAppsRoutes,
      ...adminAccountingRoutes,
    ],
  },
  ...adminAuthenticationRoutes,
];
