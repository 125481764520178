import { RouteConfig } from 'vue-router';
import { Role } from '@/helpers/data';
import HabitList from './components/habit-list.vue';

export const manageHabitsRoutes: RouteConfig[] = [
  {
    path: 'gewohnheiten',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'manage-habits-habit-list',
        path: '/',
        component: HabitList,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          title: 'Gewohnheiten verwalten',
          breadcrumbs: [
            { text: 'Gewohnheiten verwalten' },
          ],
        },
      },
    ],
  },
];
