<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useUserProgressStore } from '@/private/user/user-progress/store';
import { AppType, Feature } from '@/types';
import { useOnboardingStore } from '../store';
import { MarkOnboardingAsCompletedForUserCommand, OnboardingStep } from '../types';
import WelcomeStep from './welcome-step.vue';
import HealthPointsStep from './health-points-step.vue';
import AcknowledgeContraindications from './acknowledge-contraindications.vue';
import UserGoalsStep from './user-goals-step.vue';
import HabitsStep from './habits-step.vue';
import ModulesStep from './modules-step.vue';
import NutritionDiaryStep from './nutrition-diary-step.vue';
import DocumentsStep from './documents-step.vue';
import ExperienceReportsStep from './experience-reports-step.vue';
import RecipesStep from './recipes-step.vue';
import BlogStep from './blog-step.vue';

@Component({
  components: {
    WelcomeStep,
    AcknowledgeContraindications,
    UserGoalsStep,
    ModulesStep,
    HabitsStep,
    NutritionDiaryStep,
    HealthPointsStep,
    DocumentsStep,
    ExperienceReportsStep,
    RecipesStep,
    BlogStep,
  },
})
export default class Onboarding extends Vue {

  readonly store = useOnboardingStore();
  readonly authenticationStore = useAuthenticationStore();
  readonly userProgressStore = useUserProgressStore();

  currentStepIndex = 0;

  get currentProgress(): number {
    return (this.currentStepIndex + 1) / this.relevantOnboardingSteps.length * 100;
  }

  get currentStep(): OnboardingStep {
    return this.relevantOnboardingSteps[this.currentStepIndex];
  }

  get isWelcomeStepVisible(): boolean {
    return this.currentStep === OnboardingStep.WELCOME;
  }

  get isAcknowledgeContraindicationsStepVisible(): boolean {
    return this.currentStep === OnboardingStep.ACKNOWLEDGE_CONTRAINDICATION;
  }

  get isUserGoalsStepVisible(): boolean {
    return this.currentStep === OnboardingStep.USER_GOALS;
  }

  get isModulesStepVisible(): boolean {
    return this.currentStep === OnboardingStep.MODULES;
  }

  get isHabitsStepVisible(): boolean {
    return this.currentStep === OnboardingStep.HABITS;
  }

  get isDocumentsStepVisible(): boolean {
    return this.currentStep === OnboardingStep.DOCUMENTS;
  }

  get isExperienceReportsStepVisible(): boolean {
    return this.currentStep === OnboardingStep.EXPERIENCE_REPORTS;
  }

  get isRecipesStepVisible(): boolean {
    return this.currentStep === OnboardingStep.RECIPES;
  }

  get isNutritionDiaryStepVisible(): boolean {
    return this.currentStep === OnboardingStep.NUTRITION_DIARY;
  }

  get isBlogStepVisible(): boolean {
    return this.currentStep === OnboardingStep.BLOG;
  }

  get isHealthPointsStepVisible(): boolean {
    return this.currentStep === OnboardingStep.HEALTH_POINTS;
  }

  get relevantOnboardingSteps(): OnboardingStep[] {
    let onboardingSteps: OnboardingStep[] = [
      OnboardingStep.WELCOME,
    ];

    if (this.authenticationStore.appType === AppType.COURSE) {
      if (!this.authenticationStore.user!.areContraindicationsAcknowledged) {
        onboardingSteps.push(OnboardingStep.ACKNOWLEDGE_CONTRAINDICATION);
      }
    }

    onboardingSteps = onboardingSteps.concat([
      OnboardingStep.MODULES,
      OnboardingStep.HABITS,
    ]);

    if (this.authenticationStore.isFeatureEnabled(Feature.DOCUMENTS)) {
      onboardingSteps.push(OnboardingStep.DOCUMENTS);
    }

    if (this.authenticationStore.isFeatureEnabled(Feature.GOAL_TRACKING)
      && !this.authenticationStore.user!.areUserGoalsDefined
    ) {
      onboardingSteps.push(OnboardingStep.USER_GOALS);
    }

    if (this.authenticationStore.isFeatureEnabled(Feature.NUTRITION_DIARY)) {
      onboardingSteps.push(OnboardingStep.NUTRITION_DIARY);
    }

    if (this.authenticationStore.isFeatureEnabled(Feature.RECIPES)) {
      onboardingSteps.push(OnboardingStep.RECIPES);
    }

    if (this.authenticationStore.isFeatureEnabled(Feature.EXPERIENCE_REPORTS)) {
      onboardingSteps.push(OnboardingStep.EXPERIENCE_REPORTS);
    }

    if (this.authenticationStore.isFeatureEnabled(Feature.BLOG)) {
      onboardingSteps.push(OnboardingStep.BLOG);
    }

    onboardingSteps.push(OnboardingStep.HEALTH_POINTS);

    return onboardingSteps;
  }

  mounted(): void {
    this.store.getFeatureExamples()
      .catch((error) => showErrorResponse(error));
  }

  submitConfirmed(): void {
    if (this.currentStepIndex < this.relevantOnboardingSteps.length - 1) {
      this.currentStepIndex++;
      window.scrollTo({ top: 0 });
    } else {
      const command: MarkOnboardingAsCompletedForUserCommand = {
        hasSeenAllSteps: true,
      };
      this.store.markOnboardingAsCompletedForUser(command)
        .then(() => this.userProgressStore.getUserProgress())
        .then(() => this.$router.push({ name: 'default' }))
        .catch((error) => showErrorResponse(error));
    }
  }

  skipConfirmed(): void {
    const command: MarkOnboardingAsCompletedForUserCommand = {
      hasSeenAllSteps: false,
    };
    this.store.markOnboardingAsCompletedForUser(command)
      .then(() => this.$router.push({ name: 'default' }))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <v-card outlined class="overflow-hidden">

    <v-progress-linear :value="currentProgress" height="20" />

    <welcome-step
      v-if="isWelcomeStepVisible"
      :is-submit-disabled="!store.featureExamples"
      @submitConfirmed="submitConfirmed"
    />

    <acknowledge-contraindications
      v-if="isAcknowledgeContraindicationsStepVisible"
      @submitConfirmed="submitConfirmed"
    />

    <modules-step
      v-if="isModulesStepVisible"
      @submitConfirmed="submitConfirmed"
      @skipConfirmed="skipConfirmed"
    />

    <habits-step
      v-if="isHabitsStepVisible"
      @submitConfirmed="submitConfirmed"
      @skipConfirmed="skipConfirmed"
    />

    <documents-step
      v-if="isDocumentsStepVisible"
      @submitConfirmed="submitConfirmed"
      @skipConfirmed="skipConfirmed"
    />

    <user-goals-step
      v-if="isUserGoalsStepVisible"
      @submitConfirmed="submitConfirmed"
      @skipConfirmed="skipConfirmed"
    />

    <experience-reports-step
      v-if="isExperienceReportsStepVisible"
      @submitConfirmed="submitConfirmed"
      @skipConfirmed="skipConfirmed"
    />

    <recipes-step
      v-if="isRecipesStepVisible"
      @submitConfirmed="submitConfirmed"
      @skipConfirmed="skipConfirmed"
    />

    <nutrition-diary-step
      v-if="isNutritionDiaryStepVisible"
      @submitConfirmed="submitConfirmed"
      @skipConfirmed="skipConfirmed"
    />

    <blog-step
      v-if="isBlogStepVisible"
      @submitConfirmed="submitConfirmed"
      @skipConfirmed="skipConfirmed"
    />

    <health-points-step
      v-if="isHealthPointsStepVisible"
      @submitConfirmed="submitConfirmed"
      @skipConfirmed="skipConfirmed"
    />

  </v-card>

</v-container>
</template>
<style lang="sass" scoped>
ol
  li
    padding-bottom: 0.75rem
</style>
