import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"about-nubali"},[_c(VRow,[_c(VCol,{attrs:{"md":"12","lg":"9"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,{staticClass:"pb-0"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"9","md":"6"}},[_c('img',{staticClass:"mt-1 mb-4",attrs:{"src":_vm.kraaibeekLogoUrl,"alt":"Kraaibeek GmbH","width":"100%"}})])],1),_c('p',[_vm._v(" Nubali ist eine interaktive Ernährungsapp exklusiv und maßgeschneidert für die Partner*innen & Kund*innen der Kraaibeek GmbH. ")]),_c('p',[_vm._v(" Die Kraaibeek GmbH ist ein inhabergeführtes Unternehmen aus Pinneberg, gegründet 2009 durch die Diplom-Oecotrophologin und Geschäftsführerin Hanna-Kathrin Kraaibeek. Wir bringen langjährige Erfahrung in der Ernährungsberatung und -therapie sowie in der Konzeption und Entwicklung von Fortbildungen und Schulungen für Ernährungsfachkräfte mit. ")]),_c('p',[_vm._v(" Seit mehreren Jahren sind wir zudem aktiver Dienstleister in der betrieblichen Gesundheitsförderung. Zu unseren Kunden zählen zahlreiche Krankenkassen, Unternehmen und Institutionen, Verbände und Kliniken. ")]),_c('p',[_vm._v(" Die Inhalte der App werden von den Ernährungsexpert*innen der Kraaibeek GmbH kontinuierlich nach aktuellen Erkenntnissen weiterentwickelt. ")])],1)],1),_c('a-trigger-tertiary-process-button',{staticClass:"my-4",attrs:{"to":{ name: 'authentication-login' }}},[_vm._v(" Zurück ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }