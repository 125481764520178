import { RouteConfig } from 'vue-router';
import UserList from './components/user-list.vue';

export const adminUserManagementRoutes: RouteConfig[] = [
  {
    path: '/benutzerverwaltung',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'admin-user-management-user-list',
        path: '/',
        component: UserList,
        meta: {
          requiresAuth: true,
          title: 'Benutzerverwaltung',
          breadcrumbs: [
            { text: 'Benutzerverwaltung' },
          ],
        },
      },
    ],
  },
];
