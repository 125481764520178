<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useUserProgressStore } from '@/private/user/user-progress/store';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { downloadFile } from '@/helpers/file-download-helper';
import { AppType } from '@/types';
import { useConsumeModulesStore } from '../store';
import { GetModuleSummaryPDFQuery, MarkModuleAsCompletedCommand, SendModuleSummaryPDFViaEmailCommand } from '../types';

@Component
export default class SuccessPage extends Vue {

  readonly store = useConsumeModulesStore();
  readonly authenticationStore = useAuthenticationStore();
  readonly userProgressStore = useUserProgressStore();

  get isModuleSummaryVisible(): boolean {
    return this.authenticationStore.appType === AppType.COURSE;
  }

  onMarkModuleAsCompletedClicked(): void {
    const command: MarkModuleAsCompletedCommand = {
      moduleId: this.store.activeModule!.moduleId,
    };

    this.store.markModuleAsCompleted(command)
      .then(() => showSuccessMessage('Das Modul wurde abgeschlossen'))
      .then(() => this.userProgressStore.getUserProgress())
      .then(() => this.$router.push({ name: 'dashboard-dashboard-list' }))
      .catch((error) => showErrorResponse(error));
  }

  onDownloadModuleSummaryClicked(): void {
    const query: GetModuleSummaryPDFQuery = {
      moduleId: this.store.activeModule!.moduleId,
    };
    this.store.getModuleSummaryPDF(query)
      .then((fileResponse) => downloadFile(
        fileResponse.data,
        fileResponse.contentType,
        `Zusammenfassung Modul ${this.store.activeModule!.number}.pdf`
      ))
      .catch((error) => showErrorResponse(error));
  }

  onSendModuleSummaryViaEmailClicked(): void {
    const command: SendModuleSummaryPDFViaEmailCommand = {
      moduleId: this.store.activeModule!.moduleId,
    };
    this.store.sendModuleSummaryPDFViaEmail(command)
      .then(() => showSuccessMessage('Die Zusammenfassung ist per E-Mail auf dem Weg'));
  }

}
</script>
<template>
<div class="pl-4 pt-4 pr-4">
  <h2>Modul "{{ store.activeModule.title }}" abschließen</h2>
  <p>
    Du hast das Modul erfolgreich durch gearbeitet. Durch den Abschluss hast du Zugriff auf das Inhaltsverzeichnis des Moduls und kannst jederzeit zu jeder beliebigen Stelle springen.
  </p>
  <a-trigger-primary-process-button @click="onMarkModuleAsCompletedClicked">
    Kursinhalt abschließen
  </a-trigger-primary-process-button>

  <template v-if="isModuleSummaryVisible">
    <p class="mt-8">
      Alternativ zum nachschlagen in der App, kannst du dir hier auch die Zusammenfassung des Moduls als PDF herunterladen oder als E-Mail schicken lassen.
    </p>

    <a-execute-secondary-action-button
      :is-processing="store.isGetModuleSummaryPDFProcessing"
      @click="onDownloadModuleSummaryClicked"
    >
      Zusammenfassung herunterladen
    </a-execute-secondary-action-button>

    <a-execute-secondary-action-button
      :is-processing="store.isSendModuleSummaryPDFViaEmailProcessing"
      @click="onSendModuleSummaryViaEmailClicked"
      class="mt-2 mt-md-0"
    >
      Zusammenfassung per E-Mail
    </a-execute-secondary-action-button>
  </template>

</div>
</template>
