import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"module-list"},[_c(VRow,{staticClass:"mt-md-4"},[_c(VCol,{attrs:{"cols":"12"}},[(_vm.isLoading)?_c(VProgressLinear,{staticClass:"mb-4",attrs:{"color":"primary","indeterminate":""}}):_vm._e(),(_vm.areNoModulesAvailable)?_c('p',[_vm._v(" Noch keine Module verfügbar. ")]):_vm._e()],1),_vm._l((_vm.store.moduleList),function(module,index){return _c(VCol,{key:module.moduleId,attrs:{"cols":"12","md":"6","lg":"4"}},[_c(VRow,{staticClass:"no-gutters module",class:{ 'locked': _vm.isModuleLocked(module), 'completed': _vm.isModuleCompleted(module), 'in-progress': _vm.isModuleInProgress(module), 'start': _vm.isModuleStart(module), 'mb-4': index !== _vm.store.moduleList.length - 1 },attrs:{"disabled":!!module.lock},on:{"click":function($event){return _vm.moduleClicked(module)}}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{staticClass:"overflow-hidden",attrs:{"outlined":""}},[(module.image)?_c('blur-hash-image',{attrs:{"hash":module.image.hash,"src":module.image.url,"alt":module.title,"width":20,"height":Math.floor(20 / module.image.aspectRatio)}}):_vm._e()],1)],1),_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"module-info"},[_c('strong',{staticClass:"module-title"},[_vm._v(_vm._s(module.title))]),_c('span',{staticClass:"d-block",domProps:{"innerHTML":_vm._s(_vm.progressDescription(module))}}),(_vm.isModuleActionAvailable(module))?_c('span',{staticClass:"d-block action-link"},[_vm._v(_vm._s(_vm.moduleAction(module)))]):_vm._e()])])],1)],1)})],2),(_vm.isContentCertificationNoticeVisible)?_c(VRow,[_c(VCol,{attrs:{"md":"12","lg":"9"}},[_c('p',{staticClass:"mb-0 mt-4"},[_vm._v(" Alle Informationen in den Modulen wurden von der "),_c('strong',[_vm._v("Zentralen Prüfstelle Prävention")]),_vm._v(" (ZPP) als Kooperationsgemeinschaft gesetzlicher Krankenkassen geprüft und zertifiziert. ")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }