import dayjs from 'dayjs';
import { Location } from 'vue-router';
import { PushNotifications, ActionPerformed, Token, PermissionStatus } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import * as Sentry from '@sentry/vue';
import { router } from '@/router/whitelabel';
import { hasAuthenticationInLocalStorage } from '@/helpers/local-storage-helper';
import { AcceptPushNotificationsForDeviceCommand, RejectPushNotificationsForDeviceCommand } from '@/application/whitelabel/push-notifications/types';
import { usePushNotificationsStore } from '@/application/whitelabel/push-notifications/store';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useDashboardStore } from '@/private/user/dashboard/store';
import { MarkNotificationAsSeenCommand } from '@/private/user/dashboard/types';
import { isHabitReminderPushNotification, isModuleUnlockedPushNotification, PushNotification } from './types';

async function pushNotificationPermissionIsGranted() {
  const deviceId = await Device.getId();
  // @ts-ignore - uuid is the fallback for the previous capacitor version.
  const deviceIdentifier = deviceId.identifier ?? deviceId.uuid;

  // There is no identifier when using the simulator, therefore we need to exit here.
  if (!deviceIdentifier) {
    return;
  }

  const user = useAuthenticationStore().user;
  // This might happen when a user is logged out automatically.
  if (user === null) {
    return;
  }

  /**
   * We register the push notifications and through that updated the push notification configuration when
   * - there is none of the device yet.
   * - the previous configuration was rejected.
   * - the previous configuration was updated longer then 2 weeks ago.
   */
  const twoWeeksAgo = dayjs().subtract(2, 'weeks');
  const pushNotificationConfigurationForDevice = user.pushNotificationConfigurations
    .find((pushNotificationConfiguration) => pushNotificationConfiguration.deviceIdentifier === deviceIdentifier) || null;
  if (pushNotificationConfigurationForDevice === null
    || !pushNotificationConfigurationForDevice.isAccepted
    || pushNotificationConfigurationForDevice.updatedAt.isBefore(twoWeeksAgo)
  ) {
    await PushNotifications.register();
  }
}

async function pushNotificationPermissionIsRejected() {
  const deviceId = await Device.getId();
  // @ts-ignore - uuid is the fallback for the previous capacitor version.
  const deviceIdentifier = deviceId.identifier ?? deviceId.uuid;

  // There is no identifier when using the simulator, therefore we need to exit here.
  if (!deviceIdentifier) {
    return;
  }

  const user = useAuthenticationStore().user;
  // This might happen when a user is logged out automatically.
  if (user === null) {
    return;
  }

  /**
   * We updated the push notification configuration when
   * - there is none of the device yet.
   * - the previous configuration was accepted.
   * - the previous configuration was updated longer then 2 weeks ago.
   */
  const twoWeeksAgo = dayjs().subtract(2, 'weeks');
  const pushNotificationConfigurationForDevice = user.pushNotificationConfigurations
    .find((pushNotificationConfiguration) => pushNotificationConfiguration.deviceIdentifier === deviceIdentifier) || null;
  if (pushNotificationConfigurationForDevice === null
    || pushNotificationConfigurationForDevice.isAccepted
    || pushNotificationConfigurationForDevice.updatedAt.isBefore(twoWeeksAgo)
  ) {
    const deviceInfo = await Device.getInfo();
    const command: RejectPushNotificationsForDeviceCommand = {
      deviceIdentifier,
      operatingSystem: deviceInfo.operatingSystem,
    };

    await usePushNotificationsStore().rejectPushNotificationsForDevice(command)
      .catch((error) => showErrorResponse(error));
  }
}

async function registrationSuccess(token: Token) {
  const deviceId = await Device.getId();
  // @ts-ignore - uuid is the fallback for the previous capacitor version.
  const deviceIdentifier = deviceId.identifier ?? deviceId.uuid;
  // There is no identifier when using the simulator, therefore we need to exit here.
  if (!deviceIdentifier) {
    return;
  }

  const deviceInfo = await Device.getInfo();
  const command: AcceptPushNotificationsForDeviceCommand = {
    pushNotificationToken: token.value,
    deviceIdentifier,
    operatingSystem: deviceInfo.operatingSystem,
  };

  await usePushNotificationsStore().acceptPushNotificationsForDevice(command)
    .catch((error) => showErrorResponse(error));
}

function registrationError(error: any) {
  Sentry.captureException(error);
}

async function pushNotificationReceived() {
  if (document.visibilityState !== 'hidden') {
    await useAuthenticationStore().fetchAuthenticatedUser()
      .catch(() => {});
  }
}

async function pushNotificationActionPerformed(notificationAction: ActionPerformed) {
  const notification = notificationAction.notification.data;

  if (notification.data?.notificationId) {
    if (hasAuthenticationInLocalStorage()) {
      const dashboardStore = useDashboardStore();
      const command: MarkNotificationAsSeenCommand = {
        notificationId: notification.data.notificationId,
      };
      dashboardStore.markNotificationAsSeen(command)
        .then(() => dashboardStore.getNotificationStatus())
        .catch((error) => showErrorResponse(error));
    }

    const redirectedRoute = locationForNotification(notification);
    if (redirectedRoute !== null) {
      await router.push(redirectedRoute);
    }
  } else {
    Sentry.captureMessage('Push notification has no notificationId', {
      extra: {
        notificationData: notification,
      },
    });
  }
}

function locationForNotification(notification: PushNotification): Location | null {
  if (isModuleUnlockedPushNotification(notification)) {
    return { name: 'consume-modules-consume-module', params: { id: notification.moduleId } };
  }
  if (isHabitReminderPushNotification(notification)) {
    return { name: 'manage-habit-intents-habit-intent-list' };
  }

  return null;
}

/**
 * Requests permission for push notification on the current device.
 * In the first time on iOS devices you it asks you for permission.
 * For Android devices the default is push notifications granted.
 * But as you can also change the permission within your device settings,
 * we need to ensure that the permission hasn't changed.
 */
export function requestPermissionForPushNotifications(): void {
  PushNotifications.requestPermissions()
    .then(async (permissionStatus: PermissionStatus) => {
      if (permissionStatus.receive === 'granted') {
        await pushNotificationPermissionIsGranted();
      } else {
        await pushNotificationPermissionIsRejected();
      }
    })
    .catch((error: any) => {
      Sentry.captureException(error);
    });
}

export function listenToPushNotificationRegistration(): void {
  PushNotifications.addListener('registration', registrationSuccess);
  PushNotifications.addListener('registrationError', registrationError);
}

export function listenToPushNotificationAction(): void {
  PushNotifications.addListener('pushNotificationReceived', pushNotificationReceived);
  PushNotifications.addListener('pushNotificationActionPerformed', pushNotificationActionPerformed);
}

export function removeAllListeners(): void {
  PushNotifications.removeAllListeners()
    .catch((error: any) => {
      Sentry.captureException(error);
    });
}
