<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAppStore } from '@/application/common/app/store';

@Component
export default class AppVersionHint extends Vue {

  readonly store = useAppStore();

  onReloadClicked(): void {
    window.location.reload();
  }

}
</script>
<template>
<button type="button" v-if="store.isOutdated" @click="onReloadClicked">
  <v-system-bar class="app-update-hint" fixed height="60" color="warning">
    <font-awesome-icon class="ml-1 ml-sm-2 mr-3" :icon="['fas', 'sync']" />
    <div class="banner">
      <span>Die App wurde aktualisiert.</span>
      <span class="cta">Jetzt neue Version nutzen</span>
    </div>
  </v-system-bar>
</button>
</template>
<style lang="sass">
.v-application .v-system-bar.app-update-hint
  z-index: 999
  font-size: 1rem
  top: env(safe-area-inset-top)
  background: var(--color-green-5) !important

  .cta
    color: var(--color-green-1)
    text-decoration: underline
    margin-left: 5px

  @media screen and (max-width: 960px)
    div.banner
      width: 100%

      span
        margin: 0
        display: block
        text-align: center
</style>
