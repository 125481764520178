import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { ExperienceReport } from './types';

// -- Queries

export function getExperienceReports(query: AppAndUserProperty): Promise<ExperienceReport[]> {
  const url = `${apiUrl}/api/experience-reports/get-experience-reports-query`;
  return performApiRequest<ExperienceReport[]>({ url, method: 'POST', data: query });
}
