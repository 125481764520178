<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CreateSupportMessageDialog from './create-support-message-dialog.vue';

@Component({
  components: {
    CreateSupportMessageDialog,
  },
})
export default class SupportIntroduction extends Vue {}
</script>
<template>
<v-container class="support-introduction">
  <v-row>
    <v-col md="12" lg="9">

      <p>
        Du hast Probleme mit der App oder den Inhalten? Etwas funktioniert nicht so, wie du es dir vorstellst?
      </p>
      <p>
        Eine Antwort kann ein paar Stunden (max. 48 Stunden) dauern, aber wir antworten dir auf jeden Fall.
      </p>

      <create-support-message-dialog>
        <a-trigger-primary-process-button>
          Support anfordern
        </a-trigger-primary-process-button>
      </create-support-message-dialog>

    </v-col>
  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.support-introduction
  font-size: 0.875rem
</style>
