<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CancelActionButton extends Vue {

  @Prop({ type: Boolean, default: false })
  readonly isProcessing!: boolean;

  get isInternalButtonDisabled(): boolean {
    return this.isProcessing;
  }

}
</script>
<template>
<v-btn
  text
  color="secondary"
  :disabled="isInternalButtonDisabled"
  :block="$vuetify.breakpoint.smAndDown"
  v-bind="$attrs"
  v-on="$listeners"
>
  <slot>
    Abbrechen
  </slot>
</v-btn>
</template>
