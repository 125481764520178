import { RouteConfig } from 'vue-router';
import { Role } from '@/helpers/data';
import { Feature } from '@/types';
import UserGoalProposalList from './components/user-goal-proposal-list.vue';

export const goalTrackingManagementRoutes: RouteConfig[] = [
  {
    path: 'zielverfolgung',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'goal-tracking-management',
        path: '/',
        component: UserGoalProposalList,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          requiresFeature: Feature.GOAL_TRACKING,
          title: 'Zielvorschläge verwalten',
          breadcrumbs: [
            { text: 'Zielvorschläge verwalten' },
          ],
        },
      },
    ],
  },
];
