import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VCard,{staticClass:"mb-4",attrs:{"outlined":"","loading":_vm.isLoading}},[_c(VList,{staticClass:"py-0",attrs:{"flat":""}},[(_vm.isNoInvitesVisible)?_c(VListItem,{staticClass:"pl-4",attrs:{"disabled":""}},[_c(VListItemContent,[_vm._v(" Noch keine Benutzer eingeladen ")])],1):_vm._e(),_vm._l((_vm.store.certificates),function(courseCertificate,index){return [_c(VListItem,{key:`${courseCertificate.courseCertificateId}-item`,staticClass:"pl-4",attrs:{"ripple":false}},[_c(VListItemContent,[_c('span',{staticClass:"d-block"},[_vm._v("Bescheinigung für "+_vm._s(courseCertificate.userEmailAddress))]),_c('span',{staticClass:"d-block font-italic mt-1"},[_vm._v(" Erstellt am "+_vm._s(_vm._f("formatDate")(courseCertificate.createdAt))+" um "+_vm._s(_vm._f("formatTime")(courseCertificate.createdAt))+" Uhr ")])]),_c(VListItemIcon,{staticClass:"mb-0"},[_c('icon-button',{attrs:{"small":"","icon":['far', 'download'],"tooltip":"Teilnahmebescheinigung herunterladen"},on:{"click":function($event){return _vm.certificateDownloadClicked(courseCertificate)}}})],1)],1),(index !== _vm.store.certificates.length - 1)?_c(VDivider,{key:`${courseCertificate.courseCertificateId}-divider`}):_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }