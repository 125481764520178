<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { HabitId } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import CreateHabitIntentDialog from './create-habit-intent-dialog.vue';
import { useManageHabitIntentsStore } from '../store';
import { Habit } from '../types';

interface Controls extends FormControls {
  habit: FormControl<HabitId>;
}

@Component({
  components: {
    CreateHabitIntentDialog,
  },
})
export default class SelectNewHabit extends Vue {

  readonly store = useManageHabitIntentsStore();

  form: Form<Controls> | null = null;

  get isLoading(): boolean {
    return this.store.isGetHabitsProcessing
      || this.store.isGetHabitIntentsProcessing;
  }

  get habitsNotStartedYet(): Habit[] {
    return this.store.habits.filter(
      (habit) => !this.store.habitIntents.some((habitIntent) => habitIntent.habitId === habit.habitId)
    );
  }

  get selectedHabit(): Habit|null {
    if (!this.form) {
      return null;
    }

    const formValues = getFormValues(this.form);

    return formValues.habit
      ? this.store.habits.find((habit) => habit.habitId === formValues.habit) ?? null
      : null;
  }

  mounted(): void {
    this.store.getHabits()
      .then(() => this.store.getHabitIntents())
      .then(() => {
        this.form = this.buildForm();
      })
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    const initialHabit = this.habitsNotStartedYet[0]?.habitId ?? null;

    return constructForm({
      submitted: () => {},
      controls: {
        habit: {
          label: 'Gewohnheit',
          value: initialHabit,
          isRequired: true,
        },
      },
    });
  }

  habitIntentCreated(): void {
    this.$router.push({ name: 'manage-habit-intents-habit-intent-list' });
  }
}
</script>
<template>
<v-container class="select-new-habit">
  <v-progress-linear
    v-if="isLoading"
    class="mb-2"
    color="primary"
    indeterminate
  />

  <v-row>
    <v-col cols="12" md="9">
      <a-form
        v-if="form"
        :form="form"
      >
        <v-card outlined>
          <v-card-text>
            <a-habit-form-control
              :form-control="form.controls.habit"
              :items="habitsNotStartedYet"
            />

            <template v-if="selectedHabit">
              <h3>{{ selectedHabit.title }}</h3>
              <p class="introduction mb-2">{{ selectedHabit.introduction }}</p>
              <p class="min-runtime mb-0">Nach ungefähr {{ selectedHabit.minRuntime }} Wochen kannst du mit Verbesserungen rechnen.</p>
              <create-habit-intent-dialog
                :habit-id="selectedHabit.habitId"
                @habit-intent-created="habitIntentCreated"
              >
                <a-trigger-primary-process-button class="mt-4">
                  Gewohnheit starten
                </a-trigger-primary-process-button>
              </create-habit-intent-dialog>
            </template>

          </v-card-text>
        </v-card>

        <a-trigger-tertiary-process-button
          class="mt-4"
          is-exact
          :to="{ name: 'manage-habit-intents-habit-intent-list' }"
        >
          Zurück
        </a-trigger-tertiary-process-button>
      </a-form>
    </v-col>
  </v-row>

</v-container>
</template>
<style lang="sass" scoped>
::v-deep .form-control-input,
::v-deep .form-control-messages
  margin-bottom: 0 !important

.select-new-habit
  font-size: 0.875rem

  .introduction
    white-space: pre-wrap

  .min-runtime
    font-size: 0.875rem
    color: var(--color-grey-3)
</style>
