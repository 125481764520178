import FileSaver from 'file-saver';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { showError, showSuccessMessage } from '@/application/common/snackbar/service';

export async function downloadFile(data: Blob, contentType: string, fileName: string): Promise<void> {
  if (isNativeApplication()) {
    await saveFileForMobile(data, fileName);
  } else {
    FileSaver.saveAs(data, fileName);
  }
}

/**
 * A file is stored into the documents directory on iOS and the downloads directory in Android.
 * On iOS we're able to open the file afterwards, so we do. For .zip files we open the Files app on the directory instead.
 * On Android we're not able to open the file afterwards, so we simply inform the user that the file has been placed into the directory.
 */
async function saveFileForMobile(data: Blob, fileName: string): Promise<void> {
  // Check if user already has the latest version
  if (!Capacitor.isPluginAvailable('Filesystem')) {
    showError({
      // eslint-disable-next-line vue/max-len
      message: 'Das Herunterladen ist in dieser Version der App leider nicht möglich. Bitte aktualisiere auf die neueste Version oder nutze die Web Variante.',
      timeout: 6000,
    });
    return Promise.reject();
  }

  let wasPermissionGranted = false;
  try {
    const permissionStatus = await Filesystem.checkPermissions();

    if (permissionStatus.publicStorage === 'denied') {
      showError({ message: 'Die App hat keine Berechtigung Dateien zu speichern. Bitte überprüfe die Einstellungen.' });
    } else {
      wasPermissionGranted = true;
    }
  } catch (error) {
    showError({
      // eslint-disable-next-line vue/max-len
      message: 'Es kann nicht festgestellt werden, ob die App die Berechtigung zum Speichern einer Datei hat. Bitte überprüfe die Einstellungen.',
    });
  }

  if (!wasPermissionGranted) {
    return Promise.reject();
  }

  const isPlatformIOS = Capacitor.getPlatform() === 'ios';

  const base64Data = await convertBlobToBase64(data);

  const directory = isPlatformIOS
    ? Directory.Documents
    : Directory.ExternalStorage;

  const filePath = isPlatformIOS
    ? fileName
    : `Download/${fileName}`;

  return Filesystem.writeFile({
    path: filePath,
    data: base64Data,
    directory,
  }).then(
    () => {
      if (isPlatformIOS) {
        showSuccessMessage(
          'Die Datei wurde heruntergeladen. Du findest sie in der Dateien-App.',
          6000
        );
      } else {
        showSuccessMessage(
          'Die Datei wurde heruntergeladen. Du findest sie in dem Downloads-Ordner deiner Dateiverwaltung.',
          6000
        );
      }
    },
    () => showError({
      message: 'Die Datei konnte nicht gespeichert werden. Überprüfe bitte deine Berechtigungen der App in deinen Einstellungen.',
    })
  );
}

function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onabort = reject;
    reader.onload = () => {
      const result = reader.result as string;
      const base64Data = result.split(',')[1];

      resolve(base64Data);
    };
    reader.readAsDataURL(blob);
  });
}
