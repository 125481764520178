import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { setUpDocument } from '@/helpers/document-helpers';
import { themeMap } from '@/plugins/vuetify';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { AppId, AppType } from '@/types';
import { LOCAL_STORAGE_APP_ID, setLocalStorageItem } from '@/helpers/local-storage-helper';
import { webOrReviewAppMarker } from './review-app-helper';

export function setupAppStyleAndTitle(): void {
  const style = appStyle();

  setUpDocument(style, appTitle());
  Vue.use(Vuetify, {
    theme: themeMap[style],
  });
}

export function setupAppId(): void {
  setLocalStorageItem(LOCAL_STORAGE_APP_ID, appId());
}

export function appStyle(): string {
  return process.env.MOBILE_APP_APP_STYLE
    && process.env.MOBILE_APP_APP_STYLE !== webOrReviewAppMarker
    ? process.env.MOBILE_APP_APP_STYLE
    : useAuthenticationStore().appStyle ?? 'default';
}

export function appTitle(): string {
  return process.env.MOBILE_APP_APP_TITLE
    && process.env.MOBILE_APP_APP_TITLE !== webOrReviewAppMarker
    ? process.env.MOBILE_APP_APP_TITLE
    : useAuthenticationStore().appTitle ?? 'Vitomy';
}

export function appId(): AppId {
  return process.env.MOBILE_APP_APP_ID
    && process.env.MOBILE_APP_APP_ID !== webOrReviewAppMarker
    ? process.env.MOBILE_APP_APP_ID
    : useAuthenticationStore().appId!;
}

export function appType(): AppType {
  return process.env.MOBILE_APP_APP_TYPE
    && process.env.MOBILE_APP_APP_TYPE !== webOrReviewAppMarker
    ? process.env.MOBILE_APP_APP_TYPE
    : useAuthenticationStore().appType!;
}
