import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { LOCAL_STORAGE_ADMIN_ID, setLocalStorageItem } from '@/helpers/local-storage-helper';
import { AuthenticatedUser, LoginAction } from './types';
import { fetchAuthenticatedUser, login, logout } from './service';

interface AdminAuthenticationState {
  wasInitialAuthenticationAttempted: boolean;
  user: AuthenticatedUser | null;

  loginStatus: ActionStatus;
  logoutStatus: ActionStatus;
  fetchAuthenticatedUserStatus: ActionStatus;
}

function initialState(): AdminAuthenticationState {
  return {
    wasInitialAuthenticationAttempted: false,
    user: null,

    loginStatus: ActionStatus.None,
    logoutStatus: ActionStatus.None,
    fetchAuthenticatedUserStatus: ActionStatus.None,
  };
}

export const useAdminAuthenticationStore = defineStore('adminAuthentication', {
  state: (): AdminAuthenticationState => initialState(),
  getters: {
    isAuthenticated: (state: AdminAuthenticationState): boolean => state.user !== null,

    isLoginProcessing: (state: AdminAuthenticationState): boolean =>
      state.loginStatus === ActionStatus.InProgress,
    isLogoutProcessing: (state: AdminAuthenticationState): boolean =>
      state.logoutStatus === ActionStatus.InProgress,
    isFetchAuthenticatedUserProcessing: (state: AdminAuthenticationState): boolean =>
      state.fetchAuthenticatedUserStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Actions

    login(command: LoginAction): Promise<void> {
      const { loginStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        loginStatus,
        () => login(command)
          .then(async (authentication) => {
            setLocalStorageItem(LOCAL_STORAGE_ADMIN_ID, authentication.user.id);
            this.user = authentication.user;
          })
      );
    },

    logout(): Promise<void> {
      const { logoutStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        logoutStatus,
        () => logout()
          .then(async () => {
            this.user = null;
          })
      );
    },

    fetchInitialAuthenticatedUser(): Promise<void> {
      const { fetchAuthenticatedUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        fetchAuthenticatedUserStatus,
        () => fetchAuthenticatedUser()
          .then(async (authentication) => {
            setLocalStorageItem(LOCAL_STORAGE_ADMIN_ID, authentication.user.id);
            this.user = authentication.user;
            this.wasInitialAuthenticationAttempted = true;
          })
      );
    },

    fetchAuthenticatedUser(): Promise<void> {
      const { fetchAuthenticatedUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        fetchAuthenticatedUserStatus,
        () => fetchAuthenticatedUser()
          .then(async (authentication) => {
            setLocalStorageItem(LOCAL_STORAGE_ADMIN_ID, authentication.user.id);
            this.user = authentication.user;
          })
      );
    },

  },
});
