<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import UpdateEmailAddressDialog from '@/application/whitelabel/authentication/components/update-email-address-dialog.vue';
import UpdatePasswordDialog from '@/application/whitelabel/authentication/components/update-password-dialog.vue';
import DeleteOwnAccountDialog from '@/application/whitelabel/authentication/components/delete-own-account-dialog.vue';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import NotificationSettings from './notification-settings.vue';

@Component({
  components: {
    UpdateEmailAddressDialog,
    UpdatePasswordDialog,
    DeleteOwnAccountDialog,
    NotificationSettings,
  },
})
export default class ProfileView extends Vue {

  readonly authenticationStore = useAuthenticationStore();

}
</script>
<template>
<v-container class="profile-view" v-if="authenticationStore.user">
  <v-row>
    <v-col md="12" lg="9">
      <p class="mb-6">
        Wir versuchen so wenig persönliche Daten wie möglich zu speichern, daher haben wir nur eine E-Mail Adresse und ein Passwort von dir.
      </p>
    </v-col>
  </v-row>

  <h3 class="mb-4">Zugang</h3>
  <v-row>
    <v-col cols="12" md="6" lg="4">

      <strong>E-Mail Adresse</strong>
      <span class="d-block">{{ authenticationStore.user.emailAddress }}</span>
      <update-email-address-dialog>
        <a-trigger-secondary-process-button class="mt-2">
          E-Mail Adresse anpassen
        </a-trigger-secondary-process-button>
      </update-email-address-dialog>

    </v-col>
    <v-col cols="12" md="6" lg="4" class="mt-6 mt-md-0">

      <strong>Passwort</strong>
      <span class="d-block">********</span>

      <update-password-dialog>
        <a-trigger-secondary-process-button class="mt-2">
          Passwort anpassen
        </a-trigger-secondary-process-button>
      </update-password-dialog>

    </v-col>
  </v-row>

  <notification-settings class="mt-8" />

  <hr class="mt-10 mb-10" />

  <v-row>
    <v-col cols="12" md="6" lg="6">
      <p>
        Wenn du die App nicht mehr nutzen möchtest, kannst du hier dein Konto mit allen Daten unwiderruflich löschen.
      </p>
      <delete-own-account-dialog>
        <a-trigger-tertiary-process-button>
          Konto löschen
        </a-trigger-tertiary-process-button>
      </delete-own-account-dialog>
    </v-col>
  </v-row>

</v-container>
</template>
<style lang="sass" scoped>
.profile-view
  font-size: 0.875rem

  strong
    font-weight: 700
    font-family: Montserrat, sans-serif
</style>
