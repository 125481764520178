<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { Feature, UserGoalId } from '@/types';
import UserGoalProgressChart from './user-goal-progress-chart.vue';
import RateGoalProgressDialog from './rate-goal-progress-dialog.vue';
import CreateUserGoalDialog from './create-user-goal-dialog.vue';
import CreateUserGoalFromProposalsDialog from './create-user-goal-from-proposals-dialog.vue';
import UpdateUserGoalDialog from './update-user-goal-dialog.vue';
import HealthPoints from './health-points.vue';
import UserGoalTrends from './user-goal-trends.vue';
import { useUserProgressStore } from '../store';
import { DeleteUserGoalCommand, UserGoalProgress } from '../types';

@Component({
  components: {
    UserGoalProgressChart,
    RateGoalProgressDialog,
    CreateUserGoalDialog,
    CreateUserGoalFromProposalsDialog,
    UpdateUserGoalDialog,
    HealthPoints,
    UserGoalTrends,
  },
})
export default class UserProgress extends Vue {

  readonly store = useUserProgressStore();
  readonly authenticationStore = useAuthenticationStore();

  get isLoading(): boolean {
    return this.store.isGetUserProgressProcessing;
  }

  get isGoalTrackingVisible(): boolean {
    return this.authenticationStore.isFeatureEnabled(Feature.GOAL_TRACKING);
  }

  get hasUserDefinedGoals(): boolean {
    return !!this.store.userProgress
      && this.store.userProgress.userGoalProgress.length > 0;
  }

  mounted(): void {
    this.store.getUserProgress()
      .catch((error) => showErrorResponse(error));
  }

  isUserGoalRated(userGoalProgress: UserGoalProgress): boolean {
    return userGoalProgress.ratedGoalsAt.length > 0;
  }

  async deleteUserGoal(userGoalId: UserGoalId): Promise<void> {
    const command: DeleteUserGoalCommand = {
      userGoalId,
    };

    return this.store.deleteUserGoal(command)
      .then(() => showSuccessMessage('Das Ziel wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container class="user-progress">
  <div>
    <v-progress-linear
      v-if="isLoading"
      color="primary"
      indeterminate
      class="mb-4"
    />

    <v-row
      v-if="store.userProgress"
      class="flex-column-reverse flex-md-row"
    >

      <v-col
        v-if="isGoalTrackingVisible"
        cols="12"
        sm="12"
        md="7"
        lg="8"
        class="mt-4 mt-md-0"
      >
        <h3>Zielerreichung</h3>

        <p>
          Wir fragen dich in regelmäßigen Abständen, wie du bei der Erreichung deiner Ziele vorankommst. Du kannst die Ziele aber auch jederzeit zusätzlich bewerten. <template v-if="hasUserDefinedGoals">Hier findest du den Trend seit dem du mit dem jeweiligen Ziel gestartet bist.</template>
        </p>

        <user-goal-trends
          v-if="hasUserDefinedGoals"
          :user-progress="store.userProgress"
          class="mt-2"
        />

        <rate-goal-progress-dialog
          v-if="hasUserDefinedGoals"
          :user-goal-progresses="store.userProgress.userGoalProgress"
        >
          <a-trigger-primary-process-button class="mt-4">
            Ziele bewerten
          </a-trigger-primary-process-button>
        </rate-goal-progress-dialog>

        <h3 class="mt-8">Deine Ziele</h3>
        <p :class="{ 'mb-0': !hasUserDefinedGoals }">
          <template v-if="hasUserDefinedGoals">
            Hier siehst du den genauen Verlauf deiner Bewertungen für die Ziele und die Möglichkeit deine Ziele zu verwalten.
          </template>
          <template v-else>
            Hier kannst du die Arbeit an einem neuen Ziel starten.
          </template>
        </p>

        <div
          v-for="(userGoalProgress, index) in store.userProgress.userGoalProgress"
          :key="userGoalProgress.userGoalId"
          :class="{ 'mb-6': index !== store.userProgress.userGoalProgress.length - 1 }"
        >
          <v-card outlined>
            <v-card-text>
              <h4 class="d-block mb-4">{{ userGoalProgress.title }}</h4>
              <div class="chart-container" v-if="isUserGoalRated(userGoalProgress)">
                <user-goal-progress-chart :user-goal-progress="userGoalProgress" />
              </div>
              <div v-else>
                <p class="mb-0">Sobald du das Ziel das erste mal bewertet hast, werden wir dir hier einen Verlauf anzeigen.</p>
              </div>
            </v-card-text>
          </v-card>
          <div class="mt-4 d-flex justify-end">
            <update-user-goal-dialog :user-goal-progress="userGoalProgress">
              <a-trigger-tertiary-process-button class="mr-4">
                Ziel anpassen
              </a-trigger-tertiary-process-button>
            </update-user-goal-dialog>
            <a-confirmation-dialog
              title="Ziel löschen"
              button-text="Löschen"
              highlight="danger"
              :action="() => deleteUserGoal(userGoalProgress.userGoalId)"
              :is-processing="store.isDeleteUserGoalProcessing"
            >
              <a-trigger-tertiary-process-button>
                Ziel löschen
              </a-trigger-tertiary-process-button>
              <template #content>
                <p>
                  Soll das Ziel <strong>{{ userGoalProgress.title }}</strong> wirklich gelöscht werden?
                </p>
                <a-danger-alert>
                  Die Bewertungen des Ziels werden ebenfalls gelöscht.<br />
                  Diese Aktion kann nicht rückgängig gemacht werden.
                </a-danger-alert>
              </template>
            </a-confirmation-dialog>
          </div>
        </div>

        <div class="mt-8 mt-md-4">
          <create-user-goal-dialog>
            <a-trigger-secondary-process-button>
              Ziel erstellen
            </a-trigger-secondary-process-button>
          </create-user-goal-dialog>

          <create-user-goal-from-proposals-dialog>
            <a-trigger-secondary-process-button class="mt-4 mt-md-0 ml-0 ml-md-4">
              Ziel auswählen
            </a-trigger-secondary-process-button>
          </create-user-goal-from-proposals-dialog>
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="5" lg="4">

        <h3>Gesundheitspunkte</h3>
        <p>
          Durch das Abschließen von Modulen, das richtige Beantworten von Quizfragen und das Bilden neuer Gewohnheiten steigst du Stufe um Stufe auf.
        </p>

        <health-points :user-progress="store.userProgress" />

      </v-col>
    </v-row>
  </div>

</v-container>
</template>
<style lang="sass" scoped>
.user-progress
  font-size: 0.875rem

  p,
  ul
    font-size: 0.875rem
</style>
