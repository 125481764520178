<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormControlMessages extends Vue {

  @Prop({ type: Array, required: true })
  readonly messages!: string[];

  @Prop({ type: Boolean, required: true })
  readonly isFocussed!: boolean;

  @Prop({ type: Boolean, required: true })
  readonly isTouched!: boolean;

  get areMessagesShown(): boolean {
    return this.isTouched
      || this.isFocussed;
  }

  get hasAtLeastOneMessage(): boolean {
    return this.messages.length > 0;
  }

  get firstMessage(): string | null {
    const [firstErrorMessage] = this.messages;

    return firstErrorMessage ?? null;
  }

}
</script>
<template>
<div
  class="form-control-messages"
  :class="{ 'show-as-error': !isFocussed && isTouched && hasAtLeastOneMessage }"
>
  <v-slide-y-transition>
    <div
      v-if="hasAtLeastOneMessage && areMessagesShown"
      class="message"
    >
      {{ firstMessage }}
    </div>
  </v-slide-y-transition>
</div>
</template>
<style lang="sass" scoped>
.form-control-messages
  margin-top: 6px
  margin-bottom: 14px
  padding: 0 12px
  min-height: 14px
  overflow: hidden

  .message
    display: block
    font-size: 0.75rem
    color: var(--color-grey-3)
    line-height: 1.2

  &.show-as-error
    .message
      color: var(--color-red-5)
</style>
