import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import { checkMaintenanceModeStatus } from './service';
import { PingQuery } from './types';

interface MaintenanceModeState {
  isEnabled: boolean;
}

function initialState(): MaintenanceModeState {
  return {
    isEnabled: false,
  };
}

export const useMaintenanceModeStore = defineStore('maintenanceMode', {
  state: (): MaintenanceModeState => initialState(),
  actions: {

    enableMaintenanceMode(): Promise<void> {
      let checkStatusInterval: number;

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;

      function checkForCurrentStatus() {
        if (document.visibilityState === 'hidden') {
          return;
        }

        const query: PingQuery = {
          timestamp: dayjs(),
        };

        checkMaintenanceModeStatus(query)
          .then(() => {
            context.isEnabled = false;
            clearInterval(checkStatusInterval);
            document.removeEventListener('visibilitychange', checkForCurrentStatus);

            // Force reload to fix state broken through first maintenance mode response
            window.location.reload();
          })
          .catch(() => {});
      }

      if (!this.isEnabled) {
        checkStatusInterval = setInterval(checkForCurrentStatus, 60000);

        document.addEventListener('visibilitychange', checkForCurrentStatus);
      }

      this.isEnabled = true;

      return Promise.resolve();
    },

  },
});
