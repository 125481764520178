import { Dayjs } from 'dayjs';
import { Command, Query } from '@/application/types';
import { BlurHashImage } from '@/helpers/data';
import { SelfReflectionOption } from '@/types';

export enum PageType {
  'TABLE_OF_CONTENTS' = 'TABLE_OF_CONTENTS',
  'MODULE' = 'MODULE',
  'SUCCESS' = 'SUCCESS',
}

export interface ModuleListItem {
  moduleId: string;
  title: string;
  image?: BlurHashImage;
  completionPercentage: number;
  lock?: ModuleLock;
  isBehindPaywall: boolean;
  isCompleted: boolean;
}

export interface Module {
  moduleId: string;
  title: string;
  number: number;
  imageFileName?: string;
  imageBlurHash?: string;
  imageAspectRatio?: number;
  pages: ModulePage[];
  lastSeenModulePageIndex: number;
  completionPercentage: number;
  isMostRecentlyViewedModule: boolean;
  lock?: ModuleLock;
  isBehindPaywall: boolean;
  isCompleted: boolean;
}

export type LockReason = 'paywall' | 'time' | 'previous-not-completed';

export interface ModuleLock {
  lockReason: LockReason;
  unlockedAt?: Dayjs;
}

export interface ModulePage {
  modulePageId: string;
  title: string;
  contentElements: ContentElement[];
  isExerciseIncluded: boolean;
  isExerciseConfirmed: boolean;
}

export interface UserGoal {
  userGoalId: string;
  title: string;
  previousModuleRating?: number;
}

export type ContentElementType = 'rich-text' | 'image' | 'video' | 'acknowledgement';

export interface ContentElement {
  contentElementId: string;
  type: ContentElementType;
  title: string;
}

export interface ContentElementRichText extends ContentElement {
  textHtml: string;
}

export interface ContentElementVideo extends ContentElement {
  videoId: string;
  videoTranscript: string;
}

export interface ContentElementImage extends ContentElement {
  image: BlurHashImage;
  hasToBeRendered: boolean;
}

export interface ContentElementQuiz extends ContentElement {
  question: string;
  possibleAnswers: ContentElementQuizAnswer[];
  isAnswered: boolean;
}

export interface ContentElementQuizAnswer {
  contentElementQuizAnswerId: string;
  text: string;
  explanation: string;
  isCorrectAnswer: boolean;
}

export interface ContentElementHabitProposal extends ContentElement {
  habitId: string;
  habitTitle: string;
  habitIntroduction?: string;
  isHabitIntentCreated: boolean;
}

export interface ContentElementAcknowledgement extends ContentElement {
  introduction: string;
  acknowledgement: string;
  isConfirmed: boolean;
}

export interface ContentElementDocument extends ContentElement {
  idOfReferencedDocument: string;
  documentTitle: string;
  documentExplanation: string;
  documentFileName: string;
}

export interface ContentElementSelfReflection extends ContentElement {
  question: string;
  selfReflectionOptions: SelfReflectionOption[];
}

// -- Commands

export interface MarkModulePageAsSeenCommand extends Command {
  modulePageId: string;
}

export interface MarkModuleAsMostRecentlySeenCommand extends Command {
  moduleId: string;
}

export interface MarkModuleAsCompletedCommand extends Command {
  moduleId: string;
}

export interface MarkQuizAsAnsweredCorrectlyCommand extends Command {
  contentElementId: string;
}

export interface MarkQuizAsAnsweredIncorrectlyCommand extends Command {
  contentElementId: string;
}

export interface SendModuleSummaryPDFViaEmailCommand extends Command {
  moduleId: string;
}

// -- Queries

export interface GetModuleQuery extends Query {
  moduleId: string;
}

export interface GetModuleSummaryPDFQuery extends Query {
  moduleId: string;
}
