<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { Line } from 'vue-chartjs/legacy';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { UserGoalProgress } from '../types';

@Component({
  components: {
    'chart-line': Line,
  },
})
export default class UserGoalProgressChart extends Vue {

  @Prop({ type: Object, required: true })
  readonly userGoalProgress!: UserGoalProgress;

  readonly colorGreen = (this.$vuetify.theme.themes.light.green as any).base.toString();

  readonly chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          font: {
            size: 14,
            family: 'Inter,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif',
          },
          color: '#494C50',
          padding: 16,
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
          color: '#DFE0E2',
        },
        suggestedMin: 1,
        suggestedMax: 10,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  chartData: ChartData | null = null;

  mounted() {
    watch(() => this.userGoalProgress, (userGoalProgress) => {
      const chartDataset: ChartDataset = {
        data: userGoalProgress.scores,
        borderColor: this.colorGreen,
        pointBackgroundColor: this.colorGreen,
      };

      this.chartData = {
        // Create labels through count so that the full width is shown
        labels: userGoalProgress.ratedGoalsAt.map((ratedAt) => ratedAt.format('DD.MM.YY')),
        datasets: [chartDataset],
      };
    }, { deep: true, immediate: true });
  }

}
</script>
<template>
<div class="chart">
  <chart-line
    v-if="chartData"
    :height="250"
    :chart-options="chartOptions"
    :chart-data="chartData"
  />
</div>
</template>
