import Vue from 'vue';

export function formatKilogram(gramm: any): string {
  if (typeof gramm !== 'number') {
    return gramm;
  }

  if (gramm === 0) {
    return `–`;
  }

  return `${(gramm / 1000).toString().replace('.', ',')} kg`;
}

Vue.filter('formatKilogram', formatKilogram);
