import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[(_vm.isLoading)?_c(VProgressLinear,{attrs:{"color":"primary","indeterminate":""}}):_vm._e(),(!_vm.isLoading)?[(_vm.store.apps.length === 0)?_c(VCard,{staticClass:"mt-4 mb-4",attrs:{"outlined":""}},[_c(VList,{staticClass:"pt-0 pb-0",attrs:{"flat":""}},[_c(VListItem,{staticClass:"pl-4",attrs:{"disabled":""}},[_c(VListItemContent,[_vm._v(" Noch keine Sponsoren für keine App erstellt. ")])],1)],1)],1):_vm._e(),_vm._l((_vm.store.apps),function(app){return _c(VCard,{key:app.appId,staticClass:"mt-4 mb-4",attrs:{"outlined":""}},[_c(VCardText,[_c('strong',{staticClass:"d-block mb-4"},[_vm._v(_vm._s(app.title))]),_c(VSimpleTable,{staticClass:"mb-0",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Monat ")]),_vm._l((app.sponsors),function(sponsor){return _c('th',{key:sponsor.sponsorId,staticClass:"text-right"},[_vm._v(" "+_vm._s(sponsor.name)+" ")])}),_c('th',{staticClass:"text-right"},[_vm._v(" Gesamt ")])],2)]),_c('tbody',_vm._l((app.historyOfTotalAvailableSeatsForApp),function(amount,yearMonth){return _c('tr',{key:yearMonth},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.yearMonthFormatted(yearMonth)))]),_vm._l((app.sponsors),function(sponsor){return _c('td',{key:sponsor.sponsorId,staticClass:"text-right"},[_vm._v(" "+_vm._s(sponsor.historyOfAvailableSeats[yearMonth] || 0)+" ")])}),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(amount))])])],2)}),0)]},proxy:true}],null,true)})],1)],1)})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }