<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { ExtensionOption } from '@/components/form/html-form-control.vue';

interface Controls extends FormControls {
  strategy: FormControl<string>;
}

@Component
export default class UpdateStrategyDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.largeNested;

  readonly enabledExtensions: ExtensionOption[] = [
    'bold',
    'italic',
  ];

  @Prop({ type: String, required: true })
  readonly strategy!: string;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        strategy: {
          label: 'Strategie',
          value: this.strategy,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    this.$emit('submitted', formValues.strategy);

    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
    this.$emit('closed');
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Strategie anpassen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <a-html-form-control
              :form-control="form.controls.strategy"
              :extensions="enabledExtensions"
              is-autofocused
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button @click="closeDialog" />
        <a-submit-primary-action-button :is-disabled="isSubmitDisabled">
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
