import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { Sponsor, CreateSponsorCommand, UpdateSponsorNameCommand, UpdateAvailableSeatsForSponsorCommand, GetSeatsForSponsorQuery, SponsorSeats, InviteUsersForSponsorCommand, ResendUserInviteForSponsorCommand, RevokeSeatCommand, GenerateRegistrationLinkForSponsorCommand, RefreshRegistrationLinkForSponsorCommand, DeleteRegistrationLinkForSponsorCommand } from './types';

// -- Queries

export function getSponsors(query: AppAndUserProperty): Promise<Sponsor[]> {
  const url = `${apiUrl}/api/sponsors/get-sponsors-for-management-query`;
  return performApiRequest<Sponsor[]>({ url, method: 'POST', data: query });
}

export function getSeatsForSponsor(query: GetSeatsForSponsorQuery & AppAndUserProperty): Promise<SponsorSeats> {
  const url = `${apiUrl}/api/sponsors/get-seats-for-sponsor-query`;
  return performApiRequest<SponsorSeats>({ url, method: 'POST', data: query });
}

// -- Commands

export function createSponsor(command: CreateSponsorCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/sponsors/create-sponsor-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateSponsorName(command: UpdateSponsorNameCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/sponsors/update-sponsor-name-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateAvailableSeatsForSponsor(command: UpdateAvailableSeatsForSponsorCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/sponsors/update-available-seats-for-sponsor-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function inviteUsersForSponsor(command: InviteUsersForSponsorCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/sponsors/invite-users-for-sponsor-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function resendUserInviteForSponsor(command: ResendUserInviteForSponsorCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/sponsors/resend-user-invite-for-sponsor-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function revokeSeat(command: RevokeSeatCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/sponsors/revoke-seat-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function generateRegistrationLinkForSponsor(
  command: GenerateRegistrationLinkForSponsorCommand & AppAndUserProperty
): Promise<void> {
  const url = `${apiUrl}/api/sponsors/generate-registration-link-for-sponsor-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function refreshRegistrationLinkForSponsor(command: RefreshRegistrationLinkForSponsorCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/sponsors/refresh-registration-link-for-sponsor-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteRegistrationLinkForSponsor(command: DeleteRegistrationLinkForSponsorCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/sponsors/delete-registration-link-for-sponsor-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
