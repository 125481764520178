import { Capacitor } from '@capacitor/core';

export enum Platform {
  'ios' = 'ios',
  'android' = 'android',
  'web' = 'web',
}

export function isNativeApplication(): boolean {
  return Capacitor.isNativePlatform();
}

export function getPlatform(): string {
  return Capacitor.getPlatform();
}

export function isAndroid(): boolean {
  return (Capacitor.getPlatform() as Platform) === Platform.android;
}
