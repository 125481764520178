import Vue, { watch } from 'vue';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { User } from '@sentry/vue';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';

export function initSentry(): void {
  Sentry.init({
    Vue,
    environment: process.env.SENTRY_ENVIRONMENT,
    dsn: process.env.SENTRY_DSN,
    release: process.env.SOURCE_VERSION,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    enableTracing: true,
    tracesSampleRate: process.env.SENTRY_TRACING_RATE_CLIENT,
    logErrors: process.env.ARE_ERRORS_LOGGED_IN_CONSOLE,
    attachStacktrace: true,
    ignoreErrors: [
      'Network Error',
      'Non-Error exception captured',
    ],
    beforeSend(event, hint) {
      const exceptions = event?.exception?.values || [];
      for (const exception of exceptions) {
        // Delete local cache and reload page on chunk error
        if (exception.type === 'ChunkLoadError') {
          // Fallback for old browser versions
          if (!caches) {
            // eslint-disable-next-line no-restricted-globals
            window.location.reload();
            return null;
          }

          caches.keys()
            .then(async (names) => Promise.all(names.map((name) => caches.delete(name))))
            .then(() => window.location.reload());

          return null;
        }
      }

      // Filter out connection errors with ether have a code of "ECONNABORTED" or the name "Network Error"
      if (hint) {
        const error = hint.originalException as AxiosError;
        if (error?.code
          && error.code === 'ECONNABORTED'
        ) {
          return null;
        }

        if (typeof hint.originalException === 'object'
          && hint.originalException != null
          && 'key' in hint.originalException
        ) {
          event.extra = {
            ...event.extra,
            ...hint.originalException,
          };
        }
      }

      return event;
    },
  });
}

export function initSentryWatchers(): void {
  const authenticationStore = useAuthenticationStore();
  watch(() => authenticationStore.user, () => {
    if (authenticationStore.user) {
      addUserToSentry(authenticationStore.user.id, authenticationStore.appId!);
    } else {
      removeUserFromSentry();
    }
  });
}

function addUserToSentry(userId: string, appId: string): void {
  const user: User = {
    id: userId,
    appId,
  };

  Sentry.setUser(user);
}

function removeUserFromSentry(): void {
  Sentry.setUser(null);
}
