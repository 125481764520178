import { defineStore } from 'pinia';

interface AppState {
  title: string | null;
  isOutdated: boolean;
}

function initialState(): AppState {
  return {
    title: null,
    isOutdated: false,
  };
}

export const useAppStore = defineStore('app', {
  state: (): AppState => initialState(),
  getters: {
    titleWithFallback: (state: AppState) => state.title ?? '...',
  },
  actions: {

    // -- State management

    validateAppVersion(serverVersion: string): void {
      if (process.env.SOURCE_VERSION !== serverVersion) {
        this.isOutdated = true;
      }
    },

    updateTitle(title: string | null): void {
      this.title = title ?? null;
    },

  },
});
