import { apiUrl, performApiRequest, performApiRequestForFile } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { Dashboard, CreateCertificateCommand, SendCertificateToUserCommand, UnseenNotification, NotificationStatus, MarkNotificationAsSeenCommand } from './types';

// -- Queries

export function getDashboard(query: AppAndUserProperty): Promise<Dashboard> {
  const url = `${apiUrl}/api/learning/get-dashboard-query`;
  return performApiRequest<Dashboard>({ url, method: 'POST', data: query });
}

export function getUserCertificatePDF(query: AppAndUserProperty): Promise<FileResponse> {
  const url = `${apiUrl}/api/learning/get-user-certificate-pdf-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getUnseenNotifications(query: AppAndUserProperty): Promise<UnseenNotification[]> {
  const url = `${apiUrl}/api/dashboard/get-unseen-notifications-query`;
  return performApiRequest<UnseenNotification[]>({ url, method: 'POST', data: query });
}

export function getNotificationStatus(query: AppAndUserProperty): Promise<NotificationStatus> {
  const url = `${apiUrl}/api/dashboard/get-notification-status-query`;
  return performApiRequest<NotificationStatus>({ url, method: 'POST', data: query });
}

// -- Commands

export function createCertificate(command: CreateCertificateCommand): Promise<void> {
  const url = `${apiUrl}/api/learning/create-certificate-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function sendCertificateToUser(command: SendCertificateToUserCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/learning/send-certificate-to-user-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markNotificationAsSeen(command: MarkNotificationAsSeenCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/dashboard/mark-notification-as-seen-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function markAllNotificationAsSeen(command: AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/dashboard/mark-all-notification-as-seen-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
