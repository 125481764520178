<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRules, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, minLengthRule, mountFormControl, wasValidationSuccessful } from '@/components/form';

@Component({
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class PasswordFormControl extends Vue implements FormControlComponent<string> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<string>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: String, default: null })
  readonly autocomplete!: string | null;

  @Prop({ type: String, default: null })
  readonly dataCy!: string | null;

  readonly formControlId = createFormControlId();

  readonly internalFieldRules: InternalValueRules = [
    minLengthRule(8, 'Das Passwort muss mindestens 8 Zeichen enthalten'),
  ];

  isFocused = false;
  isTouched = false;

  messages: string[] = [];
  internalValue = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  textChanged(): void {
    if (this.internalValue === null) {
      this.internalValue = '';
    }

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    const messages = [
      ...errorMessagesForInternalRules(
        this.internalFieldRules,
        // A min length rule would be triggered, but the required rule should apply instead
        this.internalValue.trim().length > 0
          ? this.internalValue
          : null
      ),
    ];

    this.messages = messages;

    return wasValidationSuccessful(messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ''
      : this.formControl.value.trim();
  }

  formValueFromInternalValues(): FormControlValue<string> {
    return this.internalValue.trim().length > 0
      ? this.internalValue.trim()
      : null;
  }

}
</script>
<template>
<div class="form-control password-form-control" v-bind="$attrs">
  <div class="form-control-input">
    <v-text-field
      type="password"
      v-model="internalValue"
      :label="labelWithRequiredIndicator(formControl)"
      @input="textChanged"
      @focus="focused"
      @blur="blurred"
      :autocomplete="autocomplete"
      :autofocus="isAutofocused ? $vuetify.breakpoint.mdAndUp : false"
      :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
      outlined
      hide-details="auto"
      :data-cy="dataCy"
    />
    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
  </div>
</div>
</template>
