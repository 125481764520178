import { render, staticRenderFns } from "./blog-article-details.vue?vue&type=template&id=432828e6&scoped=true"
import script from "./blog-article-details.vue?vue&type=script&lang=ts"
export * from "./blog-article-details.vue?vue&type=script&lang=ts"
import style0 from "./blog-article-details.vue?vue&type=style&index=0&id=432828e6&prod&lang=sass&scoped=true"
import style1 from "./blog-article-details.vue?vue&type=style&index=1&id=432828e6&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "432828e6",
  null
  
)

export default component.exports