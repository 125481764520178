import { defineStore, storeToRefs } from 'pinia';
import { FileResponse } from '@/application/types';
import { ActionStatus, wrapActionWithProgress } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { getModule, getModules, getModuleSummaryPDF, markModuleAsCompleted, markModuleAsMostRecentlySeen, markModulePageAsSeen, markQuizAsAnsweredCorrectly, markQuizAsAnsweredIncorrectly, sendModuleSummaryPDFViaEmail } from './service';
import { ContentElementAcknowledgement, ContentElementQuiz, ContentElementType, GetModuleQuery, GetModuleSummaryPDFQuery, MarkModuleAsCompletedCommand, MarkModuleAsMostRecentlySeenCommand, MarkModulePageAsSeenCommand, MarkQuizAsAnsweredCorrectlyCommand, MarkQuizAsAnsweredIncorrectlyCommand, Module, ModuleListItem, ModulePage, PageType, SendModuleSummaryPDFViaEmailCommand } from './types';

interface ConsumeModulesState {
  moduleList: ModuleListItem[];
  activeModuleId: string | null;
  activeModule: Module | null;
  activeModulePageIndex: number | null;

  getModuleStatus: ActionStatus;
  getModulesStatus: ActionStatus;
  getModuleSummaryPDFStatus: ActionStatus;
  markModulePageAsSeenStatus: ActionStatus;
  markModuleAsMostRecentlySeenStatus: ActionStatus;
  markModuleAsCompletedStatus: ActionStatus;
  sendModuleSummaryPDFViaEmailStatus: ActionStatus;
  markQuizAsAnsweredCorrectlyStatus: ActionStatus;
  markQuizAsAnsweredIncorrectlyStatus: ActionStatus;
  moveToNextPageStatus: ActionStatus;
}

function initialState(): ConsumeModulesState {
  return {
    moduleList: [],
    activeModuleId: null,
    activeModule: null,
    activeModulePageIndex: null,

    getModuleStatus: ActionStatus.None,
    getModulesStatus: ActionStatus.None,
    getModuleSummaryPDFStatus: ActionStatus.None,
    markModulePageAsSeenStatus: ActionStatus.None,
    markModuleAsMostRecentlySeenStatus: ActionStatus.None,
    markModuleAsCompletedStatus: ActionStatus.None,
    sendModuleSummaryPDFViaEmailStatus: ActionStatus.None,
    markQuizAsAnsweredCorrectlyStatus: ActionStatus.None,
    markQuizAsAnsweredIncorrectlyStatus: ActionStatus.None,
    moveToNextPageStatus: ActionStatus.None,
  };
}

function pageType(module: Module, modulePageIndex: number | null): PageType {
  if (module.isCompleted
    && modulePageIndex === null
  ) {
    return PageType.TABLE_OF_CONTENTS;
  } else if (modulePageIndex === module.pages.length) {
    return PageType.SUCCESS;
  } else {
    return PageType.MODULE;
  }
}

function modulePage(module: Module | null, modulePageIndex: number | null): ModulePage | null {
  return module && modulePageIndex !== null
    ? module.pages[modulePageIndex] ?? null
    : null;
}

export const useConsumeModulesStore = defineStore('ConsumeModules', {
  state: (): ConsumeModulesState => initialState(),
  getters: {
    isActivePageTypeModule: (state: ConsumeModulesState): boolean => {
      if (!state.activeModule) {
        return false;
      }

      return pageType(state.activeModule, state.activeModulePageIndex) === PageType.MODULE;
    },
    isActivePageTypeTableOfContents: (state: ConsumeModulesState): boolean => {
      if (!state.activeModule) {
        return false;
      }

      return pageType(state.activeModule, state.activeModulePageIndex) === PageType.TABLE_OF_CONTENTS;
    },
    isActivePageTypeSuccess: (state: ConsumeModulesState): boolean => {
      if (!state.activeModule) {
        return false;
      }

      return pageType(state.activeModule, state.activeModulePageIndex) === PageType.SUCCESS;
    },
    activeModulePage: (state: ConsumeModulesState): ModulePage|null => modulePage(state.activeModule, state.activeModulePageIndex),
    progress: (state: ConsumeModulesState): number => {
      if (!state.activeModule) {
        return 0;
      }

      const activePageType = pageType(state.activeModule, state.activeModulePageIndex);
      if (activePageType === PageType.TABLE_OF_CONTENTS) {
        return 0;
      }

      if (activePageType === PageType.SUCCESS) {
        return 100;
      }

      return (state.activeModulePageIndex!) / state.activeModule.pages.length * 100;
    },
    isExerciseCheckboxShown: (state: ConsumeModulesState): boolean => {
      const activeModulePage = modulePage(state.activeModule, state.activeModulePageIndex);
      if (!state.activeModule || !activeModulePage || state.activeModulePageIndex === null) {
        return false;
      }

      if (state.activeModule.isCompleted) {
        return false;
      }

      if (state.activeModulePageIndex < state.activeModule.lastSeenModulePageIndex) {
        return false;
      }

      return activeModulePage.isExerciseIncluded
        && state.activeModule.lastSeenModulePageIndex === state.activeModulePageIndex;
    },
    isAcknowledgementCheckboxShown: (state: ConsumeModulesState): boolean => {
      if (!state.activeModule || state.activeModulePageIndex === null) {
        return false;
      }

      if (state.activeModule.isCompleted) {
        return false;
      }

      if (state.activeModulePageIndex < state.activeModule.lastSeenModulePageIndex) {
        return false;
      }

      return state.activeModule.lastSeenModulePageIndex === state.activeModulePageIndex;
    },
    isNextPageButtonShown: (state: ConsumeModulesState): boolean => {
      if (!state.activeModule) {
        return false;
      }

      const activePageType = pageType(state.activeModule, state.activeModulePageIndex);
      if (!activePageType) {
        return false;
      }

      return activePageType === PageType.MODULE
        && state.activeModule.pages.length !== state.activeModulePageIndex;
    },
    isNextPageButtonDisabled: (state: ConsumeModulesState): boolean => {
      if (!state.activeModule || state.activeModulePageIndex === null) {
        return true;
      }

      const activeModulePage = modulePage(state.activeModule, state.activeModulePageIndex);
      if (!activeModulePage) {
        return true;
      }

      const activePageType = pageType(state.activeModule, state.activeModulePageIndex);
      if (activePageType !== PageType.MODULE) {
        return true;
      }

      if (!state.activeModule.isCompleted
        && state.activeModulePageIndex >= state.activeModule.lastSeenModulePageIndex
        && activeModulePage.isExerciseIncluded
        && !activeModulePage.isExerciseConfirmed) {
        return true;
      }

      const quizElements = activeModulePage.contentElements
        .filter((contentElement) => contentElement.type === 'quiz' as ContentElementType) as ContentElementQuiz[];

      const acknowledgmentElements = activeModulePage.contentElements
        .filter((contentElement) => contentElement.type === 'acknowledgement' as ContentElementType) as ContentElementAcknowledgement[];

      if (!state.activeModule.isCompleted
        && state.activeModulePageIndex >= state.activeModule.lastSeenModulePageIndex
        && (
          quizElements.some((quiz) => !quiz.isAnswered)
          || acknowledgmentElements.some((acknowledgement) => !acknowledgement.isConfirmed)
        )
      ) {
        return true;
      }

      return false;
    },
    isFastForwardNextPageButtonAllowed: (state: ConsumeModulesState): boolean =>
      !state.activeModule
      || state.activeModulePageIndex === null
        ? false
        : state.activeModule.isCompleted || state.activeModulePageIndex < state.activeModule.lastSeenModulePageIndex,

    isGetModuleProcessing: (state: ConsumeModulesState): boolean =>
      state.getModuleStatus === ActionStatus.InProgress,
    isGetModulesProcessing: (state: ConsumeModulesState): boolean =>
      state.getModulesStatus === ActionStatus.InProgress,
    isMarkModulePageAsSeenProcessing: (state: ConsumeModulesState): boolean =>
      state.markModulePageAsSeenStatus === ActionStatus.InProgress,
    isMarkModuleAsMostRecentlySeenProcessing: (state: ConsumeModulesState): boolean =>
      state.markModuleAsMostRecentlySeenStatus === ActionStatus.InProgress,
    isMarkModuleAsCompletedProcessing: (state: ConsumeModulesState): boolean =>
      state.markModuleAsCompletedStatus === ActionStatus.InProgress,
    isSendModuleSummaryPDFViaEmailProcessing: (state: ConsumeModulesState): boolean =>
      state.sendModuleSummaryPDFViaEmailStatus === ActionStatus.InProgress,
    isMarkQuizAsAnsweredCorrectlyProcessing: (state: ConsumeModulesState): boolean =>
      state.markQuizAsAnsweredCorrectlyStatus === ActionStatus.InProgress,
    isMarkQuizAsAnsweredIncorrectlyProcessing: (state: ConsumeModulesState): boolean =>
      state.markQuizAsAnsweredIncorrectlyStatus === ActionStatus.InProgress,
    isMoveToNextPageProcessing: (state: ConsumeModulesState): boolean =>
      state.moveToNextPageStatus === ActionStatus.InProgress,
    isGetModuleSummaryPDFProcessing: (state: ConsumeModulesState): boolean =>
      state.getModuleSummaryPDFStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    startModule(moduleId: string): Promise<void> {
      this.activeModuleId = moduleId;
      const query: GetModuleQuery = {
        moduleId: this.activeModuleId!,
      };

      return getModule(attachAppAndUser(query))
        .then(async (module) => {
          this.activeModule = module;
          this.activeModulePageIndex = module.isCompleted
            ? null
            : module.lastSeenModulePageIndex;

          return module;
        })
        .then((module) => {
          if (!module.isMostRecentlyViewedModule) {
            const command: MarkModuleAsMostRecentlySeenCommand = {
              moduleId: module.moduleId,
            };
            return this.markModuleAsMostRecentlySeen(command);
          }

          return Promise.resolve();
        });
    },

    moveToNextPage(): Promise<void> {
      const actions: Promise<any>[] = [];
      if (!this.activeModule!.isCompleted
        && this.activeModulePageIndex! >= this.activeModule!.lastSeenModulePageIndex
      ) {
        const activeModulePage = this.activeModule!.pages[this.activeModulePageIndex!];
        const command: MarkModulePageAsSeenCommand = {
          modulePageId: activeModulePage.modulePageId,
        };
        actions.push(this.markModulePageAsSeen(command));
      }

      const { moveToNextPageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        moveToNextPageStatus,
        () => Promise.all(actions)
          .then(() => {
            this.activeModulePageIndex = this.activeModulePageIndex! + 1;
          })
      );
    },

    moveToPreviousPage(): void {
      if (this.activeModulePageIndex! <= 0) {
        throw new Error('Cannot move to previous page when already on the first page');
      }

      this.activeModulePageIndex = this.activeModulePageIndex! - 1;
    },

    navigateToModulePageIndex(index: number): void {
      this.activeModulePageIndex = index;
    },

    navigateToSuccessPage(): void {
      this.activeModulePageIndex = this.activeModule!.pages.length;
    },

    confirmExerciseForActiveModulePage(): void {
      this.activeModule!.pages[this.activeModulePageIndex!].isExerciseConfirmed = true;
    },

    retractExerciseConfirmationForActiveModulePage(): void {
      this.activeModule!.pages[this.activeModulePageIndex!].isExerciseConfirmed = false;
    },

    // -- Queries

    getModules(): Promise<void> {
      const { getModulesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getModulesStatus,
        () => getModules(attachAppAndUser({}))
          .then(async (moduleList) => {
            this.moduleList = moduleList;
          })
      );
    },

    getModule(): Promise<void> {
      const query: GetModuleQuery = {
        moduleId: this.activeModuleId!,
      };
      const { getModuleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getModuleStatus,
        () => getModule(attachAppAndUser(query))
          .then(async (module) => {
            this.activeModule = module;
          })
      );
    },

    getModuleSummaryPDF(query: GetModuleSummaryPDFQuery): Promise<FileResponse> {
      const { getModuleSummaryPDFStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getModuleSummaryPDFStatus,
        () => getModuleSummaryPDF(attachAppAndUser(query))
      );
    },

    // -- Commands

    markModulePageAsSeen(command: MarkModulePageAsSeenCommand): Promise<void> {
      const { markModulePageAsSeenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markModulePageAsSeenStatus,
        () => markModulePageAsSeen(attachAppAndUser(command))
          .then(() => this.getModule())
      );
    },

    markModuleAsMostRecentlySeen(command: MarkModuleAsMostRecentlySeenCommand): Promise<void> {
      const { markModuleAsMostRecentlySeenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markModuleAsMostRecentlySeenStatus,
        () => markModuleAsMostRecentlySeen(attachAppAndUser(command))
          .then(() => this.getModule())
      );
    },

    markModuleAsCompleted(command: MarkModuleAsCompletedCommand): Promise<void> {
      const { markModuleAsCompletedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markModuleAsCompletedStatus,
        () => markModuleAsCompleted(attachAppAndUser(command))
          .then(() => this.getModule())
      );
    },

    sendModuleSummaryPDFViaEmail(command: SendModuleSummaryPDFViaEmailCommand): Promise<void> {
      const { sendModuleSummaryPDFViaEmailStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        sendModuleSummaryPDFViaEmailStatus,
        () => sendModuleSummaryPDFViaEmail(attachAppAndUser(command))
          .then(() => this.getModule())
      );
    },

    markQuizAsAnsweredCorrectly(command: MarkQuizAsAnsweredCorrectlyCommand): Promise<void> {
      const { markQuizAsAnsweredCorrectlyStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markQuizAsAnsweredCorrectlyStatus,
        () => markQuizAsAnsweredCorrectly(attachAppAndUser(command))
          .then(() => this.getModule())
      );
    },

    markQuizAsAnsweredIncorrectly(command: MarkQuizAsAnsweredIncorrectlyCommand): Promise<void> {
      const { markQuizAsAnsweredIncorrectlyStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markQuizAsAnsweredIncorrectlyStatus,
        () => markQuizAsAnsweredIncorrectly(attachAppAndUser(command))
          .then(() => this.getModule())
      );
    },

  },
});
