<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useManageModulesStore } from '../store';
import { DeleteModuleCommand, ModuleListItem } from '../types';

@Component
export default class DeleteModuleDialog extends Vue {

  readonly store = useManageModulesStore();

  @Prop({ type: Object, required: true })
  readonly module!: ModuleListItem;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  deleteModuleConfirmed(): void {
    const command: DeleteModuleCommand = {
      moduleId: this.module.moduleId,
    };

    this.store.deleteModule(command)
      .then(() => showSuccessMessage('Modul wurde gelöscht.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isDeleteModuleProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <v-card-title>Modul löschen</v-card-title>
    <v-card-text>
      <p>Soll das Modul <strong>{{ module.title }}</strong> wirklich gelöscht werden?</p>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <a-cancel-action-button
        :is-processing="store.isDeleteModuleProcessing"
        @click="closeDialog"
      />
      <a-execute-dangerous-action-button
        @click="deleteModuleConfirmed"
        :is-processing="store.isDeleteModuleProcessing"
      >
        Löschen
      </a-execute-dangerous-action-button>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
