import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { createHabitIntent, createHabitLog, disableHabitIntent, enableHabitIntent, getHabitIntents, getHabits, updateHabitIntent } from './service';
import { CreateHabitLogCommand, DisableHabitIntentCommand, EnableHabitIntentCommand, HabitIntent, UpdateHabitIntentCommand, CreateHabitIntentCommand, Habit } from './types';

interface ManageHabitIntentsState {
  habits: Habit[];
  habitIntents: HabitIntent[];
  currentHabitIntentId: string | null;

  getHabitIntentsStatus: ActionStatus;
  updateHabitIntentStatus: ActionStatus;
  enableHabitIntentStatus: ActionStatus;
  disableHabitIntentStatus: ActionStatus;
  createHabitLogStatus: ActionStatus;
  createHabitIntentStatus: ActionStatus;
  getHabitsStatus: ActionStatus;
}

function initialState(): ManageHabitIntentsState {
  return {
    habits: [],
    habitIntents: [],
    currentHabitIntentId: null,

    getHabitIntentsStatus: ActionStatus.None,
    updateHabitIntentStatus: ActionStatus.None,
    enableHabitIntentStatus: ActionStatus.None,
    disableHabitIntentStatus: ActionStatus.None,
    createHabitLogStatus: ActionStatus.None,
    createHabitIntentStatus: ActionStatus.None,
    getHabitsStatus: ActionStatus.None,
  };
}

export const useManageHabitIntentsStore = defineStore('manageHabitIntents', {
  state: (): ManageHabitIntentsState => initialState(),
  getters: {
    currentHabitIntent: (state: ManageHabitIntentsState): HabitIntent | null =>
      state.habitIntents.find((habitIntent) => habitIntent.habitIntentId === state.currentHabitIntentId) || null,

    isGetHabitIntentsProcessing: (state: ManageHabitIntentsState): boolean =>
      state.getHabitIntentsStatus === ActionStatus.InProgress,
    isUpdateHabitIntentProcessing: (state: ManageHabitIntentsState): boolean =>
      state.updateHabitIntentStatus === ActionStatus.InProgress,
    isEnableHabitIntentProcessing: (state: ManageHabitIntentsState): boolean =>
      state.enableHabitIntentStatus === ActionStatus.InProgress,
    isDisableHabitIntentProcessing: (state: ManageHabitIntentsState): boolean =>
      state.disableHabitIntentStatus === ActionStatus.InProgress,
    isCreateHabitLogProcessing: (state: ManageHabitIntentsState): boolean =>
      state.createHabitLogStatus === ActionStatus.InProgress,
    isCreateHabitIntentProcessing: (state: ManageHabitIntentsState): boolean =>
      state.createHabitIntentStatus === ActionStatus.InProgress,
    isGetHabitsProcessing: (state: ManageHabitIntentsState): boolean =>
      state.getHabitsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentHabitIntentId(habitIntentId: string): void {
      this.currentHabitIntentId = habitIntentId;
    },

    // -- Queries

    getHabitIntents(): Promise<void> {
      const { getHabitIntentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHabitIntentsStatus,
        () => getHabitIntents(attachAppAndUser({}))
          .then(async (habitIntents) => {
            this.habitIntents = habitIntents;
          })
      );
    },

    getHabits(): Promise<void> {
      const { getHabitsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHabitsStatus,
        () => getHabits(attachAppAndUser({}))
          .then(async (habits) => {
            this.habits = habits;
          })
      );
    },

    // -- Commands

    updateHabitIntent(command: UpdateHabitIntentCommand): Promise<void> {
      const { updateHabitIntentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateHabitIntentStatus,
        () => updateHabitIntent(attachAppAndUser(command))
          .then(() => this.getHabitIntents())
      );
    },

    enableHabitIntent(command: EnableHabitIntentCommand): Promise<void> {
      const { enableHabitIntentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableHabitIntentStatus,
        () => enableHabitIntent(attachAppAndUser(command))
          .then(() => this.getHabitIntents())
      );
    },

    disableHabitIntent(command: DisableHabitIntentCommand): Promise<void> {
      const { disableHabitIntentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableHabitIntentStatus,
        () => disableHabitIntent(attachAppAndUser(command))
          .then(() => this.getHabitIntents())
      );
    },

    createHabitLog(command: CreateHabitLogCommand): Promise<void> {
      const { createHabitLogStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHabitLogStatus,
        () => createHabitLog(attachAppAndUser(command))
          .then(() => this.getHabitIntents())
      );
    },

    createHabitIntent(command: CreateHabitIntentCommand): Promise<void> {
      const { createHabitIntentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHabitIntentStatus,
        () => createHabitIntent(attachAppAndUser(command))
      );
    },

  },
});
