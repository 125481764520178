import axios, { AxiosError } from 'axios';
import { useMaintenanceModeStore } from './store';

async function handleMaintenanceError(error: AxiosError): Promise<AxiosError> {
  // With our custom maintenance mode, we will always get a 503 response from the API for every request
  const isMaintenanceMode = error.response?.status === 503;

  if (isMaintenanceMode) {
    return useMaintenanceModeStore().enableMaintenanceMode()
      .then(() => Promise.reject(error));
  }

  return Promise.reject(error);
}

export default function initMaintenanceModeInterceptor() {
  axios.interceptors.response.use(
    (response) => response,
    handleMaintenanceError
  );
}
