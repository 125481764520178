import { Dayjs } from 'dayjs';
import { CommandWithFiles, Command } from '@/application/types';
import { BlurHashImage } from '@/helpers/data';
import { NutritionDiaryEntryId, NutritionDiaryTipId, NutritionDiaryTipType } from '@/types';

export enum TimelineEntryType {
  'DAY' = 'DAY',
  'GAP' = 'GAP',
}

export enum NutritionDiaryItemType {
  'TIP' = 'TIP',
  'ENTRY' = 'ENTRY',
}

export interface TimelineEntry {
  type: TimelineEntryType;
  items: NutritionDiaryItem[];
}

export interface NutritionDiaryItem {
  type: NutritionDiaryItemType;
  content: NutritionDiaryTip | NutritionDiaryEntry;
}

export interface NutritionDiaryItemForEntry extends NutritionDiaryItem {
  type: NutritionDiaryItemType.ENTRY;
  content: NutritionDiaryEntry;
}

export interface NutritionDiaryItemForTip extends NutritionDiaryItem {
  type: NutritionDiaryItemType.TIP;
  content: NutritionDiaryTip;
}

export function isNutritionDiaryItemEntry(nutritionDiaryItem: NutritionDiaryItem): nutritionDiaryItem is NutritionDiaryItemForEntry {
  return nutritionDiaryItem.type === NutritionDiaryItemType.ENTRY;
}

export function isNutritionDiaryItemTip(nutritionDiaryItem: NutritionDiaryItem): nutritionDiaryItem is NutritionDiaryItemForTip {
  return nutritionDiaryItem.type === NutritionDiaryItemType.TIP;
}

export interface TimelineEntryDay extends TimelineEntry {
  type: TimelineEntryType.DAY;
  date: Dayjs;
  nutritionDiaryEntries: NutritionDiaryEntry[];
}

export interface NutritionDiaryTip {
  nutritionDiaryTipId: NutritionDiaryTipId;
  type: NutritionDiaryTipType;
  description: string;
}

export interface TimelineEntryGap extends TimelineEntry {
  type: TimelineEntryType.GAP;
  from: Dayjs;
  to: Dayjs;
}

export function isTypeDay(timelineEntry: TimelineEntry): timelineEntry is TimelineEntryDay {
  return timelineEntry.type === TimelineEntryType.DAY;
}
export function isTypeGap(timelineEntry: TimelineEntry): timelineEntry is TimelineEntryGap {
  return timelineEntry.type === TimelineEntryType.GAP;
}

export interface NutritionDiaryEntry {
  nutritionDiaryEntryId: NutritionDiaryEntryId;
  consumedAt: Dayjs;
  description?: string;
  image?: BlurHashImage;
}

// -- Commands

export interface CreateNutritionDiaryEntryCommand extends CommandWithFiles {
  body: {
    consumedAt: Dayjs;
    description: string | null;
  },
  files: {
    imageFile: Blob | null;
  }
}

export interface AddNutritionDiaryEntryImageCommand extends CommandWithFiles {
  body: {
    nutritionDiaryEntryId: NutritionDiaryEntryId;
  };
  files: {
    imageFile: Blob | null;
  }
}

export interface UpdateNutritionDiaryEntryDescriptionCommand extends Command {
  nutritionDiaryEntryId: NutritionDiaryEntryId;
  description: string | null;
}

export interface UpdateConsumedAtOfNutritionDiaryEntryCommand extends Command {
  nutritionDiaryEntryId: NutritionDiaryEntryId;
  consumedAt: Dayjs;
}

export interface DeleteNutritionDiaryEntryImageCommand extends Command {
  nutritionDiaryEntryId: NutritionDiaryEntryId;
}

export interface DeleteNutritionDiaryEntryCommand extends Command {
  nutritionDiaryEntryId: NutritionDiaryEntryId;
}
