import { defineStore } from 'pinia';

interface ImpersonationHintState {
  isImpersonationActive: boolean;
}

function initialState(): ImpersonationHintState {
  return {
    isImpersonationActive: false,
  };
}

export const useImpersonationHintStore = defineStore('impersonationHint', {
  state: (): ImpersonationHintState => initialState(),
  actions: {

    // -- State management

    activateHint(): void {
      this.isImpersonationActive = true;
    },

  },
});
