<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsRule, maxLengthRule, minNumberRule } from '@/components/form';
import { ExtensionOption } from '@/components/form/html-form-control.vue';
import { useManageRecipesStore } from '../store';
import { CreateRecipeCommand, Ingredient } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
  subTitle: FormControl<string>;
  totalTimeInMinutes: FormControl<number>;
  preparationTimeInMinutes: FormControl<number>;
  ingredients: FormControl<Ingredient[]>;
  preparationSteps: FormControl<string[]>;
  tipBox: FormControl<string>;
  heroImage: FormControl<Blob>;
  ingredientsImage: FormControl<Blob>;
}

@Component
export default class CreateRecipeDialog extends Vue {

  readonly store = useManageRecipesStore();

  readonly dialogMaxWidth = DialogWidth.large;

  readonly enabledExtensionsForTipBox: ExtensionOption[] = [
    'bold',
    'italic',
    'bullet-list',
    'ordered-list',
  ];

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        subTitle: {
          label: 'Untertitel',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        totalTimeInMinutes: {
          label: 'Gesamtzeit in Minuten',
          value: 15,
          isRequired: true,
          rules: [
            maxDecimalsRule(0),
            minNumberRule(0),
          ],
        },
        preparationTimeInMinutes: {
          label: 'Arbeitszeit in Minuten',
          value: 15,
          isRequired: true,
          rules: [
            maxDecimalsRule(0),
            minNumberRule(0),
          ],
        },
        ingredients: {
          label: 'Zutaten',
          value: null,
          isRequired: true,
        },
        preparationSteps: {
          label: 'Zubereitung',
          value: null,
          isRequired: true,
        },
        tipBox: {
          label: 'Tipp-Box',
          value: null,
        },
        heroImage: {
          label: 'Coverbild',
          value: null,
          isRequired: true,
        },
        ingredientsImage: {
          label: 'Bild aller Zutaten',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateRecipeCommand = {
      body: {
        name: formValues.name!,
        subTitle: formValues.subTitle,
        totalTimeInMinutes: formValues.totalTimeInMinutes!,
        preparationTimeInMinutes: formValues.preparationTimeInMinutes!,
        ingredients: formValues.ingredients!,
        preparationSteps: formValues.preparationSteps!,
        tipBox: formValues.tipBox,
      },
      files: {
        heroImage: formValues.heroImage!,
        ingredientsImage: formValues.ingredientsImage!,
      },
    };

    this.store.createRecipe(command)
      .then(() => showSuccessMessage('Rezept wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateRecipeProcessing"
>
  <template #activator="{ on }">
    <div class="d-inline-block" v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Rezept erstellen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <a-image-cropper-form-control :form-control="form.controls.heroImage" />
          </v-col>
          <v-col cols="12">
            <a-image-cropper-form-control :form-control="form.controls.ingredientsImage" />
          </v-col>
          <v-col cols="12" md="6">
            <a-text-form-control
              :form-control="form.controls.name"
              is-autofocused
            />
          </v-col>
          <v-col cols="12" md="6">
            <a-text-form-control :form-control="form.controls.subTitle" />
          </v-col>
          <v-col cols="12" md="6">
            <a-number-form-control :form-control="form.controls.totalTimeInMinutes" />
          </v-col>
          <v-col cols="12" md="6">
            <a-number-form-control :form-control="form.controls.preparationTimeInMinutes" />
          </v-col>
          <v-col cols="12">
            <a-ingredients-form-control :form-control="form.controls.ingredients" />
          </v-col>
          <v-col cols="12">
            <a-preparation-steps-form-control :form-control="form.controls.preparationSteps" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <a-html-form-control
              :form-control="form.controls.tipBox"
              :extensions="enabledExtensionsForTipBox"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateRecipeProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateRecipeProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Erstellen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
