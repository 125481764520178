import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { Tip, CreateTipAsManagerCommand, PublishTipAsManagerCommand, UnpublishTipAsManagerCommand, UpdateTipAsManagerCommand, UpdateOrderOfTipsAsManagerCommand } from './types';
import { createTip, getTips, publishTip, unpublishTip, updateOrderOfTips, updateTip } from './service';

interface ManageNutritionDiaryState {
  tips: Tip[];

  getTipsStatus: ActionStatus;
  createTipStatus: ActionStatus;
  updateTipStatus: ActionStatus;
  publishTipStatus: ActionStatus;
  unpublishTipStatus: ActionStatus;
  updateOrderOfTipsStatus: ActionStatus;
}

function initialState(): ManageNutritionDiaryState {
  return {
    tips: [],

    getTipsStatus: ActionStatus.None,
    createTipStatus: ActionStatus.None,
    updateTipStatus: ActionStatus.None,
    publishTipStatus: ActionStatus.None,
    unpublishTipStatus: ActionStatus.None,
    updateOrderOfTipsStatus: ActionStatus.None,
  };
}

export const useManageNutritionDiaryStore = defineStore('manageNutritionDiary', {
  state: (): ManageNutritionDiaryState => initialState(),
  getters: {
    isGetTipsProcessing: (state: ManageNutritionDiaryState): boolean =>
      state.getTipsStatus === ActionStatus.InProgress,
    isCreateTipProcessing: (state: ManageNutritionDiaryState): boolean =>
      state.createTipStatus === ActionStatus.InProgress,
    isUpdateTipProcessing: (state: ManageNutritionDiaryState): boolean =>
      state.updateTipStatus === ActionStatus.InProgress,
    isPublishTipProcessing: (state: ManageNutritionDiaryState): boolean =>
      state.publishTipStatus === ActionStatus.InProgress,
    isUnpublishTipProcessing: (state: ManageNutritionDiaryState): boolean =>
      state.unpublishTipStatus === ActionStatus.InProgress,
    isUpdateOrderOfTipsProcessing: (state: ManageNutritionDiaryState): boolean =>
      state.updateOrderOfTipsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getTips(): Promise<void> {
      const { getTipsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getTipsStatus,
        () => getTips(attachAppAndUser({}))
          .then(async (tips) => {
            this.tips = tips;
          })
      );
    },

    // -- Commands

    createTip(command: CreateTipAsManagerCommand): Promise<void> {
      const { createTipStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createTipStatus,
        () => createTip(attachAppAndUser(command))
          .then(() => this.getTips())
      );
    },

    updateTip(command: UpdateTipAsManagerCommand): Promise<void> {
      const { updateTipStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateTipStatus,
        () => updateTip(attachAppAndUser(command))
          .then(() => this.getTips())
      );
    },

    publishTip(command: PublishTipAsManagerCommand): Promise<void> {
      const { publishTipStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        publishTipStatus,
        () => publishTip(attachAppAndUser(command))
          .then(() => this.getTips())
      );
    },

    unpublishTip(command: UnpublishTipAsManagerCommand): Promise<void> {
      const { unpublishTipStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        unpublishTipStatus,
        () => unpublishTip(attachAppAndUser(command))
          .then(() => this.getTips())
      );
    },

    updateOrderOfTips(command: UpdateOrderOfTipsAsManagerCommand): Promise<void> {
      const { updateOrderOfTipsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateOrderOfTipsStatus,
        () => updateOrderOfTips(attachAppAndUser(command))
          .then(() => this.getTips())
      );
    },

  },
});
