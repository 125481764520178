<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { isLocalStorageAvailable } from '@/helpers/local-storage-helper';

@Component
export default class LocalStorageHint extends Vue {

  get isLocalStorageDisabled(): boolean {
    return !isLocalStorageAvailable();
  }

}
</script>
<template>
<v-system-bar class="app-local-storage-hint" fixed color="error" v-if="isLocalStorageDisabled">
  <font-awesome-icon class="ml-1 ml-sm-2 mr-4" :icon="['fas', 'exclamation-triangle']" />
  <span>
    Die App funktioniert leider nicht, wenn Cookies vom Browser blockiert werden!
    <br class="d-sm-none" />
    <span class="d-none d-sm-inline-block">Bitte erlauben Sie Cookies für diese Seite.</span>
  </span>
</v-system-bar>
</template>
<style lang="sass">
.v-system-bar.app-local-storage-hint
  z-index: 1000
  font-size: 1rem
  min-height: 60px
  height: auto !important
  color: var(--color-white)
  text-align: left
  cursor: default
  top: env(safe-area-inset-top)

  span
    display: block
    padding: 0.25rem 0
</style>
