import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { BlogArticle, BlogArticleDetails, GetBlogArticleDetailsQuery, MarkBlogArticleAsReadCommand } from './types';

// -- Queries

export function getBlogArticles(query: AppAndUserProperty): Promise<BlogArticle[]> {
  const url = `${apiUrl}/api/blog/get-blog-articles-query`;
  return performApiRequest<BlogArticle[]>({ url, method: 'POST', data: query });
}

export function getBlogArticleDetails(query: GetBlogArticleDetailsQuery & AppAndUserProperty): Promise<BlogArticleDetails> {
  const url = `${apiUrl}/api/blog/get-blog-article-details-query`;
  return performApiRequest<BlogArticleDetails>({ url, method: 'POST', data: query });
}

// -- Commands

export function markBlogArticleAsRead(command: MarkBlogArticleAsReadCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/blog/mark-blog-article-as-read-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
