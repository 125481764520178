import { RouteConfig } from 'vue-router';
import { Role } from '@/helpers/data';
import { Feature } from '@/types';
import ExperienceReportsList from './components/experience-reports-list.vue';

export const manageExperienceReportsRoutes: RouteConfig[] = [
  {
    path: 'erfahrungsberichte-verwalten',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'manage-experience-reports-experience-report-list',
        path: '/',
        component: ExperienceReportsList,
        meta: {
          requiresAuth: true,
          requiresRole: Role.ROLE_APP_MANAGER,
          requiresFeature: Feature.EXPERIENCE_REPORTS,
          title: 'Erfahrungsberichte verwalten',
          breadcrumbs: [
            { text: 'Erfahrungsberichte verwalten' },
          ],
        },
      },
    ],
  },
];
