<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, minNumberRule, moduloByRule } from '@/components/form';
import { useManageSponsorsStore } from '../store';
import { CreateSponsorCommand } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
  availableSeats: FormControl<number>;
}

@Component
export default class CreateSponsorDialog extends Vue {

  readonly store = useManageSponsorsStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        availableSeats: {
          label: 'Verfügbare Lizenzen',
          value: 10,
          isRequired: true,
          rules: [
            minNumberRule(10),
            moduloByRule(10, `Lizenzen können nur in einer 10er Taktung vergeben werden`),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateSponsorCommand = {
      name: formValues.name!,
      availableSeats: formValues.availableSeats!,
    };

    this.store.createSponsor(command)
      .then(() => showSuccessMessage('Sponsor wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateSponsorProcessing"
>
  <template #activator="{ on }">
    <div class="d-inline-block" v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Sponsor erstellen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <a-text-form-control
              :form-control="form.controls.name"
              is-autofocused
            />
          </v-col>
          <v-col cols="12" md="6">
            <a-number-form-control :form-control="form.controls.availableSeats" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateSponsorProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateSponsorProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Erstellen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
