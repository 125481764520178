<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { UserGoalProgresses, RateUserGoalProgressCommand, UserGoalProgressRating } from '../types';
import { useUserProgressStore } from '../store';

interface Controls extends FormControls {
  userGoalProgress: FormControl<UserGoalProgressRating[]>;
}

@Component
export default class RateGoalProgressDialog extends Vue {

  readonly store = useUserProgressStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Array, required: true })
  readonly userGoalProgresses!: UserGoalProgresses;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  show(): void {
    this.isDialogVisible = true;
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        userGoalProgress: {
          label: 'Ziele',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: RateUserGoalProgressCommand = {
      userGoalProgresses: formValues.userGoalProgress!,
    };

    this.store.rateUserGoalProgress(command)
      .then(() => showSuccessMessage('Bewertung wurde abgegeben.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  cancelled(): void {
    this.store.logUserGoalRatingRejection()
      .catch((error) => showErrorResponse(error))
      .finally(() => this.closeDialog());
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
  <v-dialog
    v-model="isDialogVisible"
    :max-width="dialogMaxWidth"
    :persistent="store.isRateUserGoalProgressProcessing"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
    :hide-overlay="$vuetify.breakpoint.smAndDown"
  >
    <template #activator="{ on }">
      <span v-on="on">
        <slot />
      </span>
    </template>
    <v-card>
      <a-form
        v-if="form"
        :form="form"
      >
        <v-card-title>Ziele bewerten</v-card-title>
        <v-card-text>

          <p>
            Wie schätzt du den aktuellen Stand deiner Ziele ein?<br />
            1 ist dabei "noch ganz am Anfang" und 10 "Ich habe es bereits erreicht".
          </p>

          <a-user-goal-progresses-form-control
            :form-control="form.controls.userGoalProgress"
            :user-goal-progresses="userGoalProgresses"
          />

        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <a-cancel-action-button
            :is-processing="store.isRateUserGoalProgressProcessing"
            @click="cancelled"
          />
          <a-submit-primary-action-button
            :is-disabled="isSubmitDisabled"
            :is-processing="store.isRateUserGoalProgressProcessing"
          >
            Ziele bewerten
          </a-submit-primary-action-button>
        </v-card-actions>

      </a-form>
    </v-card>
  </v-dialog>
</template>
