import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.dialogMaxWidth,"persistent":_vm.store.isRequestEmailChangeProcessing,"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"transition":_vm.$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null,"hide-overlay":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c(VCard,[(_vm.form)?_c('a-form',{attrs:{"form":_vm.form}},[_c(VCardTitle,[_vm._v("E-Mail Adresse anpassen")]),_c(VCardText,[_c('p',[_vm._v(" Deine aktuelle E-Mail Adresse lautet:"),_c('br'),_vm._v(" "+_vm._s(_vm.store.user.emailAddress)+" ")]),_c('p',[_vm._v(" Wenn die E-Mail Adresse angepasst wird, muss die neue E-Mail Adresse erst bestätigt werden. Dazu schicken wir dir eine E-Mail mit einem Bestätigungslink an die neue E-Mail Adresse. Das bedeutet: ")]),_c('ul',{staticClass:"mb-6"},[_c('li',[_vm._v("Die E-Mail Adresse wird erst mit dem Klick auf den Link geändert.")]),_c('li',[_vm._v("Erst danach ist die Anmeldung mit der neuen E-Mail Adresse möglich.")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"9"}},[_c('a-email-address-form-control',{attrs:{"form-control":_vm.form.controls.emailAddress,"is-autofocused":""}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{attrs:{"is-processing":_vm.store.isRequestEmailChangeProcessing},on:{"click":_vm.closeDialog}}),_c('a-submit-primary-action-button',{attrs:{"is-processing":_vm.store.isRequestEmailChangeProcessing,"is-disabled":_vm.isSubmitDisabled}},[_vm._v(" Anpassen ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }