<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsRule, maxLengthRule, minNumberRule } from '@/components/form';
import { Module, UpdateModuleTitleCommand } from '../types';
import { useManageModulesStore } from '../store';

interface Controls extends FormControls {
  number: FormControl<number>;
  title: FormControl<string>;
}

@Component
export default class EditModuleTitleDialog extends Vue {

  readonly store = useManageModulesStore();

  @Prop({ type: Object, required: true })
  readonly module!: Module;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        number: {
          label: 'Nummer',
          value: this.module.number,
          isRequired: true,
          rules: [
            minNumberRule(0),
            maxDecimalsRule(0),
          ],
        },
        title: {
          label: 'Bezeichnung',
          value: this.module.title,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateModuleTitleCommand = {
      moduleId: this.module!.moduleId,
      number: formValues.number!,
      title: formValues.title!,
    };

    this.store.updateModuleTitle(command)
      .then(() => showSuccessMessage('Titel wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isUpdateModuleTitleProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Bezeichnung anpassen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <a-number-form-control :form-control="form.controls.number" />
          </v-col>
          <v-col cols="10">
            <a-text-form-control
              :form-control="form.controls.title"
              is-autofocused
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isUpdateModuleTitleProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isUpdateModuleTitleProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
