<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { constructForm, Form, FormControl, FormControls, getFormValues, identicalPasswordFormControlRule } from '@/components/form';
import { ConfirmPasswordResetCommand } from '../types';

interface Controls extends FormControls {
  password: FormControl<string>;
  repeatPassword: FormControl<string>;
}

@Component
export default class PasswordResetConfirmation extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        password: {
          label: 'Passwort',
          value: null,
          isRequired: true,
          rules: [],
        },
        repeatPassword: {
          label: 'Passwort wiederholen',
          value: null,
          isRequired: true,
          rules: [],
        },
      },
    });

    form.controls.password.rules!.push(identicalPasswordFormControlRule(form.controls.repeatPassword));
    form.controls.repeatPassword.rules!.push(identicalPasswordFormControlRule(form.controls.password));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: ConfirmPasswordResetCommand = {
      confirmationToken: this.$route.params.confirmationToken,
      userPassword: formValues.password!,
    };

    this.authenticationStore.confirmPasswordReset(command)
      .then(() => showSuccessMessage('Du kannst dich jetzt mit deinem neuen Passwort anmelden.'))
      .then(() => this.$router.push({ name: 'authentication-login' }))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>
  <v-row>
    <v-col md="12" lg="9">
      <v-card outlined>
        <a-form
          v-if="form"
          :form="form"
        >
          <v-card-text class="pt-6 pb-0">

            <a-password-form-control
              :form-control="form.controls.password"
              is-autofocused
            />

            <a-password-form-control :form-control="form.controls.repeatPassword" />

          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <a-cancel-action-button :to="{ name: 'authentication-login' }">
              Zur Anmeldung
            </a-cancel-action-button>
            <a-submit-primary-action-button
              :is-disabled="isSubmitDisabled"
              :is-processing="authenticationStore.isConfirmPasswordResetProcessing"
            >
              Passwort setzen
            </a-submit-primary-action-button>
          </v-card-actions>
        </a-form>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
