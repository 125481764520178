import { render, staticRenderFns } from "./document-list.vue?vue&type=template&id=cb012630&scoped=true"
import script from "./document-list.vue?vue&type=script&lang=ts"
export * from "./document-list.vue?vue&type=script&lang=ts"
import style0 from "./document-list.vue?vue&type=style&index=0&id=cb012630&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb012630",
  null
  
)

export default component.exports