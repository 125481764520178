<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { ContentElementQuizAnswerId } from '@/types';

interface ContentElementQuizAnswer {
  contentElementQuizAnswerId: ContentElementQuizAnswerId;
  text: string;
}

function validateItems(items: unknown[]): boolean {
  return items.every((item: unknown) => typeof item === 'object'
    && !!item
    // eslint-disable-next-line no-prototype-builtins
    && item.hasOwnProperty('contentElementQuizAnswerId')
    // eslint-disable-next-line no-prototype-builtins
    && item.hasOwnProperty('text'));
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class ContentElementQuizAnswerFormControl extends Vue implements FormControlComponent<ContentElementQuizAnswerId> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<ContentElementQuizAnswerId>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: Array, required: true, validator: validateItems })
  readonly items!: ContentElementQuizAnswer[];

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: ContentElementQuizAnswerId | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  readonly formControlId = createFormControlId();

  mounted(): void {
    mountFormControl(this);
  }

  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<ContentElementQuizAnswerId> {
    return this.internalValue;
  }

}
</script>
<template>
<div class="form-control content-element-quiz-answer-form-control" v-bind="$attrs">
  <div class="form-control-input">
    <v-select
      v-model="internalValue"
      :label="labelWithRequiredIndicator(formControl)"
      :items="items"
      :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
      :autofocus="isAutofocused ? $vuetify.breakpoint.mdAndUp : false"
      @change="selectionChanged"
      @focus="focused"
      @blur="blurred"
      menu-props="auto"
      item-text="text"
      item-value="contentElementQuizAnswerId"
      hide-details
      outlined
    />
    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
  </div>
</div>
</template>
