import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { cancelUserInvite, getUserInvites, inviteUserForMachtfit, inviteUserForProFit, inviteUsersWithDirectLicense, resendUserInvite } from './service';
import { CancelUserInviteCommand, InviteUserForMachtfitCommand, InviteUserForProFitCommand, InviteUsersWithDirectLicenseCommand, ResendUserInviteCommand, UserInvite } from './types';

interface UserInvitesState {
  userInvites: UserInvite[];

  getUserInvitesStatus: ActionStatus;
  inviteUserForMachtfitStatus: ActionStatus;
  inviteUserForProFitStatus: ActionStatus;
  inviteUsersWithDirectLicenseStatus: ActionStatus;
  resendUserInviteStatus: ActionStatus;
  cancelUserInviteStatus: ActionStatus;
}

function initialState(): UserInvitesState {
  return {
    userInvites: [],

    getUserInvitesStatus: ActionStatus.None,
    inviteUserForMachtfitStatus: ActionStatus.None,
    inviteUserForProFitStatus: ActionStatus.None,
    inviteUsersWithDirectLicenseStatus: ActionStatus.None,
    resendUserInviteStatus: ActionStatus.None,
    cancelUserInviteStatus: ActionStatus.None,
  };
}

export const useUserInvitesStore = defineStore('userInvites', {
  state: (): UserInvitesState => initialState(),
  getters: {
    isGetUserInvitesProcessing: (state: UserInvitesState): boolean =>
      state.getUserInvitesStatus === ActionStatus.InProgress,
    isInviteUserForMachtfitProcessing: (state: UserInvitesState): boolean =>
      state.inviteUserForMachtfitStatus === ActionStatus.InProgress,
    isInviteUserForProFitProcessing: (state: UserInvitesState): boolean =>
      state.inviteUserForProFitStatus === ActionStatus.InProgress,
    isInviteUsersWithDirectLicenseProcessing: (state: UserInvitesState): boolean =>
      state.inviteUsersWithDirectLicenseStatus === ActionStatus.InProgress,
    isResendUserInviteProcessing: (state: UserInvitesState): boolean =>
      state.resendUserInviteStatus === ActionStatus.InProgress,
    isCancelUserInviteProcessing: (state: UserInvitesState): boolean =>
      state.cancelUserInviteStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getUserInvites(): Promise<void> {
      const { getUserInvitesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserInvitesStatus,
        () => getUserInvites(attachAppAndUser({}))
          .then(async (userInvites) => {
            this.userInvites = userInvites;
          })
      );
    },

    // -- Commands

    inviteUserForMachtfit(command: InviteUserForMachtfitCommand): Promise<void> {
      const { inviteUserForMachtfitStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        inviteUserForMachtfitStatus,
        () => inviteUserForMachtfit(attachAppAndUser(command))
          .then(() => this.getUserInvites())
      );
    },

    inviteUserForProFit(command: InviteUserForProFitCommand): Promise<void> {
      const { inviteUserForProFitStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        inviteUserForProFitStatus,
        () => inviteUserForProFit(attachAppAndUser(command))
          .then(() => this.getUserInvites())
      );
    },

    inviteUsersWithDirectLicense(command: InviteUsersWithDirectLicenseCommand): Promise<void> {
      const { inviteUsersWithDirectLicenseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        inviteUsersWithDirectLicenseStatus,
        () => inviteUsersWithDirectLicense(attachAppAndUser(command))
          .then(() => this.getUserInvites())
      );
    },

    resendUserInvite(command: ResendUserInviteCommand): Promise<void> {
      const { resendUserInviteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        resendUserInviteStatus,
        () => resendUserInvite(attachAppAndUser(command))
      );
    },

    cancelUserInvite(command: CancelUserInviteCommand): Promise<void> {
      const { cancelUserInviteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        cancelUserInviteStatus,
        () => cancelUserInvite(attachAppAndUser(command))
          .then(() => this.getUserInvites())
      );
    },

  },
});
