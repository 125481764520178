import { render, staticRenderFns } from "./video-element.vue?vue&type=template&id=30667d3d&scoped=true"
import script from "./video-element.vue?vue&type=script&lang=ts"
export * from "./video-element.vue?vue&type=script&lang=ts"
import style0 from "./video-element.vue?vue&type=style&index=0&id=30667d3d&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30667d3d",
  null
  
)

export default component.exports