import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { AcceptPushNotificationsForDeviceCommand, RejectPushNotificationsForDeviceCommand } from './types';
import { acceptPushNotificationsForDevice, rejectPushNotificationsForDevice } from './service';

interface PushNotificationsState {
  acceptPushNotificationsForDeviceStatus: ActionStatus;
  rejectPushNotificationsForDeviceStatus: ActionStatus;
}

function initialState(): PushNotificationsState {
  return {
    acceptPushNotificationsForDeviceStatus: ActionStatus.None,
    rejectPushNotificationsForDeviceStatus: ActionStatus.None,
  };
}

export const usePushNotificationsStore = defineStore('pushNotifications', {
  state: (): PushNotificationsState => initialState(),
  getters: {
    isAcceptPushNotificationsForDeviceProcessing: (state: PushNotificationsState): boolean =>
      state.acceptPushNotificationsForDeviceStatus === ActionStatus.InProgress,
    isRejectPushNotificationsForDeviceProcessing: (state: PushNotificationsState): boolean =>
      state.rejectPushNotificationsForDeviceStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Commands

    acceptPushNotificationsForDevice(command: AcceptPushNotificationsForDeviceCommand): Promise<void> {
      const { acceptPushNotificationsForDeviceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        acceptPushNotificationsForDeviceStatus,
        () => acceptPushNotificationsForDevice(attachAppAndUser(command))
          .then(() => useAuthenticationStore().fetchAuthenticatedUser())
      );
    },

    rejectPushNotificationsForDevice(command: RejectPushNotificationsForDeviceCommand): Promise<void> {
      const { rejectPushNotificationsForDeviceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        rejectPushNotificationsForDeviceStatus,
        () => rejectPushNotificationsForDevice(attachAppAndUser(command))
          .then(() => useAuthenticationStore().fetchAuthenticatedUser())
      );
    },

  },
});
