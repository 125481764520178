<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';

@Component
export default class DefaultRedirect extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  mounted(): void {
    // Not authenticated
    if (!this.authenticationStore.user) {
      this.$router.push({ name: 'root' });
      return;
    }

    // App manager - Redirected to dashboard
    if (this.authenticationStore.isAppManager) {
      this.$router.push({ name: 'app-manager-dashboard' });
      return;
    }

    // Sponsor manager - Redirected to license management
    if (this.authenticationStore.isSponsorManager) {
      this.$router.push({ name: 'manage-seats-as-sponsor' });
      return;
    }

    // Normal user who hasn't completed the onboarding - Redirected to onboarding
    if (!this.authenticationStore.user.hasCompletedOnboarding) {
      this.$router.push({ name: 'onboarding' });
      return;
    }

    // Normal user after onboarding - Redirected to dashboard
    this.$router.push({ name: 'dashboard-dashboard-list' });
  }

}
</script>
<template>
<div id="default-redirect"></div>
</template>
