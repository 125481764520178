<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useOnboardingStore } from '../store';

@Component
export default class WelcomeStep extends Vue {

  readonly store = useOnboardingStore();
  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Boolean, required: true })
  readonly isSubmitDisabled!: boolean;

  get isStartOnboardingDisabled(): boolean {
    return this.isSubmitDisabled
      || this.store.isGetFeatureExamplesProcessing;
  }

  startOnboarding(): void {
    this.$emit('submitConfirmed');
  }

}
</script>
<template>
<div class="welcome">
  <v-card-text class="pb-0">
    <h2 class="mb-4">Willkommen bei <span class="no-wrap">{{ this.authenticationStore.appTitle }}</span></h2>
    <p>
      Mit {{ this.authenticationStore.appTitle }} hast du Zugriff auf eine facetten&shy;reiche App. Unterschiedliche Funktionen unterstützen dich dabei, das Thema Ernährung besser zu verstehen, dein eigenes Verhalten zu durchblicken und gezielt mit neuen gesunden Gewohnheiten zu starten.
    </p>
    <p>
      Damit du anfangs nicht überwältigt wirst, haben wir dir hier eine kurze Einführung zu den verschiedenen Möglichkeiten der App zusammengestellt.
    </p>
    <p>
      Du hast gleich die Möglichkeit, diese Einführung zu überspringen und dich stattdessen eigenständig mit der App und ihren Möglichkeiten vertraut zu machen. Falls du dir die komplette Einführung anschaust, erfährst du, wie das Belohnungssystem in der App funktioniert und bekommst gleich die ersten Gesundheitspunkte.
    </p>
    <v-progress-linear
      v-if="store.isGetFeatureExamplesProcessing"
      indeterminate
      class="mb-4"
    />
  </v-card-text>
  <v-divider />
  <v-card-actions>
    <v-spacer />
    <a-trigger-primary-process-button
      :is-disabled="isStartOnboardingDisabled"
      @click="startOnboarding"
    >
      Starten
    </a-trigger-primary-process-button>
  </v-card-actions>
</div>
</template>
