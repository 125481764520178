import { apiUrl, performApiRequestWithTokenUpdate, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty, AppProperty } from '@/helpers/default-parameter-helper';
import { Authentication, ConfirmEmailChangeCommand, ConfirmPasswordResetCommand, ConfirmUserRegistrationCommand, DeleteOwnAccountCommand, GetAuthenticationWithImpersonationQuery, GetUserRegistrationRequestQuery, IsSponsorSlugValidQuery, LoginAction, RegisterUserCommand, RegisterUserForSponsorCommand, RequestEmailChangeCommand, RequestPasswordResetCommand, SponsorSlugResponse, UpdateNotificationSettingsCommand, UpdateUserPasswordCommand, UserRegistrationRequest } from './types';

// -- Queries

export function fetchAuthenticatedUser(query: AppAndUserProperty): Promise<Authentication> {
  const url = `${apiUrl}/api/application/authentication/authenticated-user`;
  return performApiRequestWithTokenUpdate<Authentication>({ url, method: 'GET', data: query });
}

export function getAuthenticationWithImpersonation(query: GetAuthenticationWithImpersonationQuery): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/application/authentication/authenticated-impersonation/${query.token}`;
  return performApiRequestWithTokenUpdate<void>({ url, method: 'GET', data: query });
}

export function isSponsorSlugValid(query: IsSponsorSlugValidQuery & AppProperty): Promise<SponsorSlugResponse> {
  const url = `${apiUrl}/api/unauthorized/application/authentication/is-sponsor-slug-valid-query`;
  return performApiRequest<SponsorSlugResponse>({ url, method: 'POST', data: query });
}

export function getUserRegistrationRequest(query: GetUserRegistrationRequestQuery & AppProperty): Promise<UserRegistrationRequest> {
  const url = `${apiUrl}/api/unauthorized/application/authentication/get-user-registration-request-query`;
  return performApiRequest<UserRegistrationRequest>({ url, method: 'POST', data: query });
}

// -- Commands

export function login(command: LoginAction): Promise<Authentication> {
  const url = `${apiUrl}/api/unauthorized/application/authentication/login`;
  return performApiRequestWithTokenUpdate<Authentication>({ url, method: 'POST', data: command });
}

export function logout(): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/application/authentication/logout`;
  return performApiRequest({ url, method: 'POST' });
}

export function registerUser(command: RegisterUserCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/application/authentication/register-user-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function registerUserForSponsor(command: RegisterUserForSponsorCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/application/authentication/register-user-for-sponsor-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function confirmUserRegistration(command: ConfirmUserRegistrationCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/application/authentication/confirm-user-registration-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function requestPasswordReset(command: RequestPasswordResetCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/user-management/request-password-reset-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function confirmPasswordReset(command: ConfirmPasswordResetCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/user-management/confirm-password-reset-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function requestEmailChange(command: RequestEmailChangeCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/application/authentication/request-email-address-change-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function confirmEmailChange(command: ConfirmEmailChangeCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/application/authentication/confirm-email-address-change-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateUserPassword(command: UpdateUserPasswordCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/application/authentication/update-user-password-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteOwnAccount(command: DeleteOwnAccountCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/application/authentication/delete-own-account-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateNotificationSettings(command: UpdateNotificationSettingsCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/application/authentication/update-notification-settings-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
