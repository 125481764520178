import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { getBlogArticleDetails, getBlogArticles, markBlogArticleAsRead } from './service';
import { BlogArticle, BlogArticleDetails, GetBlogArticleDetailsQuery, MarkBlogArticleAsReadCommand } from './types';

interface ConsumeBlogArticlesState {
  blogArticles: BlogArticle[];
  currentBlogArticleId: string | null;
  currentBlogArticleDetails: BlogArticleDetails | null;

  getBlogArticlesStatus: ActionStatus;
  getBlogArticleDetailsStatus: ActionStatus;
  markBlogArticleAsReadStatus: ActionStatus;
}

function initialState(): ConsumeBlogArticlesState {
  return {
    blogArticles: [],
    currentBlogArticleId: null,
    currentBlogArticleDetails: null,

    getBlogArticlesStatus: ActionStatus.None,
    getBlogArticleDetailsStatus: ActionStatus.None,
    markBlogArticleAsReadStatus: ActionStatus.None,
  };
}

export const useConsumeBlogArticlesStore = defineStore('consumeBlogArticles', {
  state: (): ConsumeBlogArticlesState => initialState(),
  getters: {
    isGetBlogArticlesProcessing: (state: ConsumeBlogArticlesState): boolean =>
      state.getBlogArticlesStatus === ActionStatus.InProgress,
    isGetBlogArticleDetailsProcessing: (state: ConsumeBlogArticlesState): boolean =>
      state.getBlogArticleDetailsStatus === ActionStatus.InProgress,
    isMarkBlogArticleAsReadProcessing: (state: ConsumeBlogArticlesState): boolean =>
      state.markBlogArticleAsReadStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentBlogArticle(blogArticleId: string): Promise<void> {
      this.currentBlogArticleId = blogArticleId;

      return this.getBlogArticleDetails();
    },

    resetCurrentBlogArticle(): void {
      this.currentBlogArticleId = null;
      this.currentBlogArticleDetails = null;
    },

    // -- Queries

    getBlogArticles(): Promise<void> {
      const { getBlogArticlesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBlogArticlesStatus,
        () => getBlogArticles(attachAppAndUser({}))
          .then(async (blogArticles) => {
            this.blogArticles = blogArticles;
          })
      );
    },

    getBlogArticleDetails(): Promise<void> {
      const query: GetBlogArticleDetailsQuery = {
        blogArticleId: this.currentBlogArticleId!,
      };
      const { getBlogArticleDetailsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBlogArticleDetailsStatus,
        () => getBlogArticleDetails(attachAppAndUser(query))
          .then(async (blogArticleDetails) => {
            this.currentBlogArticleDetails = blogArticleDetails;
          })
      );
    },

    // -- Commands

    markBlogArticleAsRead(command: MarkBlogArticleAsReadCommand): Promise<void> {
      const { markBlogArticleAsReadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markBlogArticleAsReadStatus,
        () => markBlogArticleAsRead(attachAppAndUser(command))
          .then(() => this.getBlogArticles())
      );
    },

  },
});
