// Add deep links. This will only work for links that are part of the mobile app configurations.
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { router } from '@/router/whitelabel';

App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
  const url = new URL(event.url);
  const routePath = decodeURI(`${url.pathname}${url.search}`);

  const isRootUrl = routePath.length === 0 || routePath === '/';
  const isOnCurrentRoute = router.currentRoute.path === routePath;

  if (!isRootUrl && !isOnCurrentRoute) {
    router.push({
      path: routePath,
    });
  }
});
