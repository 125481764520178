<script lang="ts">
/* eslint-disable import/no-duplicates */
import { Component, Vue, Prop } from 'vue-property-decorator';
import Dropzone, { DropzoneFile, DropzoneOptions } from 'dropzone';
import { showError } from '@/application/common/snackbar/service';
/* eslint-enable import/no-duplicates */

// @ts-ignore
Dropzone.autoDiscover = false;

@Component
export default class DropzoneUpload extends Vue {

  @Prop({ default: 'dropzone' })
  readonly id!: string;

  @Prop({ default: 'Datei auswählen' })
  readonly emptyText!: string;

  @Prop({ default: 'Enthält bereits hochgeladene Datei' })
  readonly oldFileText!: string;

  @Prop({ default: 'Datei ausgewählt' })
  readonly selectedText!: string;

  @Prop({ default: 'Datei hochgeladen' })
  readonly uploadedText!: string;

  @Prop({ default: false })
  readonly hasOldFile!: boolean;

  @Prop({ default: false })
  readonly selected!: boolean;

  @Prop({ default: false })
  readonly uploaded!: boolean;

  @Prop({ default() {
    return [
      'image/jpeg',
      'image/jpg',
      'image/gif',
      'image/png',
    ];
  },
  })
  readonly acceptedMimeTypes!: string[];

  @Prop({ default: 10 })
  readonly maxFileSize!: number;

  isDraggingOver = false;

  dropzoneOptions: null|DropzoneOptions = null;

  public get statusClasses(): any[] {
    const classes = ['dropzone-element'];

    if (!this.uploaded && !this.selected && this.hasOldFile) {
      classes.push('old-file');
    } else if (this.uploaded && !this.selected) {
      classes.push('file-uploaded');
    } else if (this.selected) {
      classes.push('file-selected');
    }

    if (this.isDraggingOver) {
      classes.push('dragging-over');
    }

    return classes;
  }

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // @ts-ignore
    const dropzone = new Dropzone(this.$refs[this.id], {
      url: '-',
      acceptedFiles: this.acceptedMimeTypes.join(','),
    });
    dropzone.on('addedfile', (file: DropzoneFile) => {
      self.addFile(file);
      dropzone.removeFile(file);
    });
  }

  public addFile(file: DropzoneFile): void {
    if (file.size / 1024 / 1024 > this.maxFileSize) {
      showError({ message: `Die Datei darf maximal ${this.maxFileSize}MB groß sein` });
      return;
    }

    if (!this.acceptedMimeTypes.includes(file.type)) {
      showError({ message: 'Die Datei ist nicht im richigen Format' });
      return;
    }

    this.$emit('upload-file', file);
  }

  dragEnter(): void {
    this.isDraggingOver = true;
    this.$emit('drag-enter');
  }

  dragLeave(): void {
    this.isDraggingOver = false;
    this.$emit('drag-leave');
  }

}
</script>
<template>
<div class="dropzone-upload">
  <div
    :id="id"
    :ref="id"
    :class="statusClasses"
    @dragenter="dragEnter"
    @dragleave="dragLeave"
  >
    <span v-if="!uploaded && !selected && !hasOldFile"><font-awesome-icon :icon="['far', 'file-upload']" /> {{ emptyText }}</span>
    <span v-if="!uploaded && !selected && hasOldFile"><font-awesome-icon :icon="['far', 'file-upload']" /> {{ oldFileText }}</span>
    <span v-if="uploaded && !selected"><font-awesome-icon :icon="['far', 'check']" /> {{ uploadedText }}</span>
    <span v-if="selected"><font-awesome-icon :icon="['far', 'check']" /> {{ selectedText }}</span>
  </div>
  <span class="notice">Du kannst hier eine Datei mit maximal {{ maxFileSize }}MB nutzen.</span>
</div>
</template>
<style lang="sass">
.dropzone-label
  display: block
  color: var(--color-brand-3)

.dropzone-upload
  .dropzone-element
    width: 100%
    border: 2px dashed var(--color-grey-6)
    background: var(--color-grey-9)
    cursor: pointer
    text-align: center
    font-size: 0.875rem

    &.old-file
      border-color: var(--color-brand-5)

    &.file-uploaded,
    &.file-selected
      border-color: var(--color-green-5)

    &.dragging-over
      border-style: solid

    span
      display: block
      width: 100%
      padding: 1rem
      margin-bottom: 0
      pointer-events: none

      svg
        margin-right: 0.25rem

  .notice
    font-size: 0.75rem
    color: var(--color-grey-6)
    display: block
    margin-top: 0.5rem
</style>
