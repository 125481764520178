<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useMaintenanceModeStore } from '../store';

@Component
export default class MaintenanceOverlay extends Vue {

  readonly store = useMaintenanceModeStore();

}
</script>
<template>
<div class="overlay-container" v-if="store.isEnabled">
  <div class="content-container">
    <h1>Im Wartungs&shy;modus</h1>
    <p>Bitte entschuldige die Unannehmlichkeiten. Wir führen derzeit Wartungsarbeiten durch und stehen automatisch wieder zur Verfügung, sobald die Arbeiten abgeschlossen sind.</p>
    <p>Schaue doch in ein paar Minuten wieder vorbei!</p>
  </div>
</div>
</template>
<style lang="sass" scoped>
.overlay-container
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background: var(--color-white)
  z-index: 1001
  font-size: 18px
  padding: calc(env(safe-area-inset-top) + 40px) 40px 40px

  @media screen and (min-width: 1024px)
    padding: calc(env(safe-area-inset-top) + 160px) 40px 40px

  .content-container
    display: block
    max-width: 650px
    margin: 0 auto

    h1
      font-size: 36px
      margin-top: 0
</style>
