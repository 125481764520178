<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { VuetifySelectItem } from '@/application/types';
import { ingredientUnitTranslations } from '@/helpers/data';
import { IngredientUnit } from '@/types';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class IngredientUnitFormControl extends Vue implements FormControlComponent<IngredientUnit> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<IngredientUnit>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  readonly formControlId = createFormControlId();

  readonly items: VuetifySelectItem<IngredientUnit>[] = [
    { value: IngredientUnit.PIECE, text: ingredientUnitTranslations[IngredientUnit.PIECE] },
    { value: IngredientUnit.GRAM, text: ingredientUnitTranslations[IngredientUnit.GRAM] },
    { value: IngredientUnit.MILLILITER, text: ingredientUnitTranslations[IngredientUnit.MILLILITER] },
    { value: IngredientUnit.TABLESPOON, text: ingredientUnitTranslations[IngredientUnit.TABLESPOON] },
    { value: IngredientUnit.TEASPOON, text: ingredientUnitTranslations[IngredientUnit.TEASPOON] },
    { value: IngredientUnit.BUNCH, text: ingredientUnitTranslations[IngredientUnit.BUNCH] },
    { value: IngredientUnit.HANDFUL, text: ingredientUnitTranslations[IngredientUnit.HANDFUL] },
    { value: IngredientUnit.TO_TASTE, text: ingredientUnitTranslations[IngredientUnit.TO_TASTE] },
  ];

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: IngredientUnit | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? this.items[0].value
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<IngredientUnit> {
    return this.internalValue;
  }

}
</script>
<template>
<div class="form-control ingredient-unit-form-control" v-bind="$attrs">
  <div class="form-control-input">
    <v-autocomplete
      v-model="internalValue"
      :label="labelWithRequiredIndicator(formControl)"
      :items="items"
      :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
      :autofocus="isAutofocused ? $vuetify.breakpoint.mdAndUp : false"
      @change="selectionChanged"
      @focus="focused"
      @blur="blurred"
      menu-props="auto"
      hide-details
      outlined
    />
    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
  </div>
</div>
</template>
