import { RouteConfig } from 'vue-router';
import { Role } from '@/helpers/data';
import { Feature } from '@/types';
import UserInvites from './components/user-invites.vue';

export const userInvitesRoutes: RouteConfig[] = [
  {
    name: 'user-management-user-invites',
    path: 'benutzer-einladungen',
    component: UserInvites,
    meta: {
      requiresAuth: true,
      requiresRole: Role.ROLE_APP_MANAGER,
      requiresFeature: Feature.INVITES,
      title: 'Einladungen',
      breadcrumbs: [
        { text: 'Einladungen' },
      ],
    },
  },
];
