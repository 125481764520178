import { RouteConfig } from 'vue-router';
import NutritionDiaryTimeline from './components/nutrition-diary-timeline.vue';

export const nutritionDiaryRoutes: RouteConfig[] = [
  {
    path: '/ernaehrungstagebuch',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'nutrition-diary-nutrition-diary-timeline',
        path: '/',
        component: NutritionDiaryTimeline,
        meta: {
          requiresAuth: true,
          title: 'Ernährungstagebuch',
        },
      },
    ],
  },
];
