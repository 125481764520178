<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LicenseDocsCard extends Vue {}
</script>
<template>
<v-card
  v-bind="$attrs"
  outlined
>
  <v-card-text class="pb-0">

    <h2>Dokumentation Lizenzverwaltung</h2>

    <h3 class="mt-4">Einleitung</h3>

    <p>
      Einem Unternehmen steht eine spezifische Anzahl an personengebundenen Lizenzen zur Verfügung (Lizenzpaket). Diese Lizenzen können Sie an beliebige Personen vergeben.
    </p>
    <p>
      Eine Lizenz wird automatisch einer Person zugeordnet, sobald eine Person eingeladen wird oder eine Person selbstständig über einen Link eine Registrierung startet. Ob die Person die Registrierung abschließt, ist für die Lizenzzuordnung nicht relevant.
    </p>
    <p>
      Sie können jederzeit eine Lizenz wieder zurückziehen. Dadurch wird die Lizenz frei und kann wieder einer anderen Person zugeordnet werden.
    </p>

    <h3>Lizenzen vergeben</h3>
    <p>
      Es gibt zwei Möglichkeiten, Lizenzen zu vergeben.
    </p>
    <ol>
      <li>
        Eine Person wird per E-Mail eingeladen.
      </li>
      <li>
        Eine Person erhält einen Registrierungslink und startet darüber eine Registrierung.
      </li>
    </ol>

    <h4 class="mt-4">1. Eine Person per E-Mail einladen</h4>

    <p>
      Über die Lizenzverwaltung kann eine neue Person per E-Mail über den nachfolgenden Prozess eingeladen werden.
    </p>

    <ol>
      <li>
        Klicken Sie auf den Button “Benutzer einladen”.
      </li>
      <li>
        Geben Sie die E-Mail Adresse der Person oder der Personen ein und klicken Sie auf “Einladen”.
      </li>
      <li>
        Die Person bekommt eine E-Mail mit einem Link zum Starten der Registrierung.
      </li>
      <li>
        Nachdem die Person auf den Link in der E-Mail klickt, muss sie den Vornamen und Nachnamen eingeben und ein Passwort wählen. Der Name der Person wird abgefragt, um in     der Lizenzverwaltung die Lizenzen leichter den Lizenzinhabern zuordnen zu können, was z.B. beim Zurückziehen von Lizenzen notwendig sein kann.
      </li>
      <li>
        Durch das Abschicken des Formulars, wird das Konto angelegt und die Registrierung damit abgeschlossen.
      </li>
      <li>
        Die Person hat nun ein Konto und kann sich mit der E-Mail Adresse, mit der sie eingeladen wurde, und dem Passwort, das sie gewählt hat, jederzeit in der App anmelden.
      </li>
    </ol>

    <h4 class="mt-4">2. Eine Person registriert sich selbst über einen Registrierungslink</h4>

    <p>
      In der Lizenzverwaltung kann pro Unternehmen optional ein Registrierungslink erstellt werden, über den eine beliebige Anzahl an Personen eine Registrierung starten können. Die einzige Einschränkung für die Anzahl der möglichen Registrierungen über einen Registrierungslink ist die Anzahl der Lizenzen, die dem Unternehmen zur Verfügung gestellt wurden. Eine Registrierung eines neuen Nutzers über den Link ist nicht mehr möglich, sobald für alle verfügbaren Lizenzen aus dem Lizenzpaket vergeben wurden.
    </p>
    <p>
      Der Registrierungslink wird im oberen Bereich der Lizenzverwaltung mit dem Button “Link erstellen” erstellt. Mit einem erstellten Registrierungslink können Personen eine Registrierung über den nachfolgenden Prozess durchführen.
    </p>

    <ol>
      <li>
        Kopieren Sie den Registrierungslink über den Button “Link kopieren”.
      </li>
      <li>
        Verteilen Sie den kopierten Registrierungslink an relevante Personen über einen beliebigen Weg, wie z.B. einen E-Mail Verteiler oder ein Informationsportal.
      </li>
      <li>
        Die Person erhält den Link über den von Ihnen gewählten Weg, klickt auf den Link und landet auf einer Registrierungsseite.
      </li>
      <li>
        Auf der Registrierungsseite gibt die Person ihre E-Mail Adresse ein und startet die Registrierung.
      </li>
      <li>
        Die Person bekommt eine E-Mail mit einem Link zum Abschluss der Registrierung. Sie muss diesen anklicken, damit wir sicherstellen können, dass die Anmeldung    tatsächlich durch die Person erfolgt ist, die auch Kontrolle über die registrierte E-Mail Adresse hat (Double Opt-In Verfahren).
      </li>
      <li>
        Nachdem die Person auf den Link in der E-Mail klickt, muss sie den Vornamen und Nachnamen eingeben und ein Passwort wählen. Der Name der Person wird abgefragt, um in     der Lizenzverwaltung die Lizenzen leichter den Lizenzinhabern zuordnen zu können, was z.B. beim Zurückziehen von Lizenzen notwendig sein kann.
      </li>
      <li>
        Durch das Abschicken des Formulars wird das Konto angelegt und die Registrierung abgeschlossen.
      </li>
      <li>
        Die Person hat nun ein Konto und kann sich mit der E-Mail Adresse, mit der sie die Registrierung gestartet hat, und dem Passwort, das sie gewählt hat, jederzeit in der App anmelden.
      </li>
    </ol>

    <h3 class="mt-4">Unterstützung beim Lizenz-Verteilungsprozess</h3>

    <p>
      Da unterschiedliche E-Mail Anbieter sehr unterschiedliche Prinzipien bei der Filterung der empfangenen E-Mails anwenden, kann nie mit 100%iger Sicherheit garantiert werden, dass alle E-Mails bei den Empfängern ankommen. Zudem haben manche Personen sehr viele ungelesene E-Mails in ihrem Posteingang, wodurch erhaltene E-Mails auch regelmäßig untergehen.
    </p>

    <p>
      Hier bieten wir zwei Möglichkeiten zur Unterstützung an.
    </p>

    <h4>Einladung erneut verschicken</h4>

    <p>
      Sollte eine Person die Einladung nicht finden, kann diese Einladung erneut verschickt werden. Dazu gibt es bei jeder Lizenz, für die bereits eine Einladung versandt wurde, einen “Einladung erneut versenden” Button. Durch einen Klick auf diesen Button und die Bestätigung der Aktion, wird die Einladung erneut per E-Mail verschickt.
    </p>

    <h4>Link zum Registrierungsabschluss direkt versenden</h4>

    <p>
      Wenn Sie sich sicher sind, dass der gewünschte Benutzer hinter einer E-Mail-Adresse steht, kann der Schritt mit der erneuten Einladung auch übersprungen werden. Sie können der Person direkt den Link zum Registrierungsabschluss auf beliebigen Weg zur Verfügung stellen.
    </p>

    <p>
      Dazu gibt es für jede zugeordnete Lizenz einen “Link zum Registrierungsabschluss kopieren” Button. Durch einen Klick auf diesen Button und eine Bestätigung der Aktion, wird der Link kopiert und Sie können ihn auf beliebigen Weg an die Person übermitteln.
    </p>

    <h3 class="mt-4">Lizenz zurückziehen</h3>

    <p>
      Eine Lizenz kann jederzeit zurückgezogen werden. Es ist dafür unerheblich, ob eine Person bereits die Einladung angenommen oder die Registrierung abgeschlossen hat.
    </p>

    <p>
      Typische Fälle, in denen eine Lizenz zurückgezogen wird, sind, wenn ein Unternehmen die Lizenz irrtümlicherweise einer falschen Person zugeordnet hat oder wenn eine Person das Unternehmen verlassen hat und die Lizenz für eine andere Person freigemacht werden soll.
    </p>

    <p>
      Sämtliche Daten, die mit der zurückgezogenen Lizenz verbunden sind (inkl. aller Daten des Benutzers) werden dadurch unwiderruflich gelöscht.
    </p>

    <p>
      In der Liste der vergebenen Lizenzen existiert bei jeder Lizenz ein “Lizenz zurückziehen” Button. Beim Klick öffnet sich ein Fenster, in dem das Zurückziehen noch einmal bestätigt werden muss. Durch den Klick auf “Zurückziehen” wird die Lizenz zurückgezogen und alle Daten gelöscht.
    </p>

  </v-card-text>
</v-card>
</template>
