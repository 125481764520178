<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useAdminPaywallAccessStore } from '../store';
import { GrantUserPaywallAccessCommand, User } from '../types';

@Component
export default class GrantUserPaywallAccessDialog extends Vue {

  readonly store = useAdminPaywallAccessStore();

  @Prop({ type: Object, required: true })
  readonly user!: User;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  grantUserPaywallAccessConfirmed(): void {
    const command: GrantUserPaywallAccessCommand = {
      grantAccessForUserId: this.user.userId,
      appId: this.store.currentAppId!,
    };

    this.store.grantUserPaywallAccess(command)
      .then(() => showSuccessMessage('Benutzer wurde freigeschaltet.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isGrantUserPaywallAccessProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <v-card-title>Inhalte manuell freischalten</v-card-title>
    <v-card-text>
      <p>Soll der Benutzer <strong>{{ user.emailAddress }}</strong> wirklich Zugriff auf die Inhalte hinter der Paywall bekommen?</p>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <a-cancel-action-button
        :is-processing="store.isGrantUserPaywallAccessProcessing"
        @click="closeDialog"
      />
      <a-execute-dangerous-action-button
        @click="grantUserPaywallAccessConfirmed"
        :is-processing="store.isGrantUserPaywallAccessProcessing"
      >
        Freischalten
      </a-execute-dangerous-action-button>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
