<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {}
</script>
<template>
<v-container class="not-found">

  <h1>Nicht gefunden</h1>

  <p>
    Der genutzte Link ist leider nicht mehr gültig.
  </p>

  <a-trigger-primary-process-button
    :to="{ name: 'root' }"
    is-exact
  >
    Zur Anmeldung
  </a-trigger-primary-process-button>

</v-container>
</template>
