<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { useSnackbarStore } from './store';
import { InternalSnack } from './types';

const defaultTimeoutMap = {
  success: 2000,
  info: 3000,
  error: 4000,
};
const timeBetweenSnacks = 500;

/**
 * This component shows snacks added to the snackbar state. It pulls the first snack of a queue and displays it for the timeout defined in
 * the snack or with the default timeout depending on the snack type. After another timout (at the moment 0,25 seconds) the snack is removed
 * from the queue and the next one is pulled. This is enough time for the disappearing snack to vanish.
 */

@Component
export default class SnackbarComponent extends Vue {

  readonly store = useSnackbarStore();

  isShown = false;
  shownSnackId: string|null = null;

  get title(): string | undefined {
    return this.store.firstSnackInQueue?.title;
  }

  get message(): string | undefined {
    return this.store.firstSnackInQueue?.message;
  }

  get type(): string | undefined {
    return this.store.firstSnackInQueue?.type;
  }

  get isMultiline(): boolean {
    return !!this.store.firstSnackInQueue?.title;
  }

  get timeout(): number | undefined {
    if (!this.store.firstSnackInQueue) {
      return undefined;
    }

    return this.timeoutForSnack(this.store.firstSnackInQueue);
  }

  get showBadge(): boolean {
    return this.store.snackQueueLength > 1;
  }

  mounted(): void {
    watch(() => this.store.firstSnackInQueue, () => {
      const snack = this.store.firstSnackInQueue;
      // The watcher is also triggered when a new snack is added, but the first is still the same
      if (!snack || snack.id === this.shownSnackId) {
        return;
      }

      this.shownSnackId = snack.id;
      this.isShown = true;

      const timeoutForSnack = this.timeoutForSnack(snack);
      const timeoutForRemoval = timeoutForSnack + timeBetweenSnacks;

      setTimeout(() => {
        this.isShown = false;
      }, timeoutForSnack);

      setTimeout(() => {
        this.store.deleteSnack(this.store.firstSnackInQueue as InternalSnack);
      }, timeoutForRemoval);
    }, { immediate: true, deep: true });
  }

  close(): void {
    if (!this.store.firstSnackInQueue) {
      return;
    }

    this.isShown = false;
    this.store.deleteSnack(this.store.firstSnackInQueue as InternalSnack);
  }

  timeoutForSnack(snack: InternalSnack): number {
    const defaultTimeout = defaultTimeoutMap[snack.type];
    return snack.timeout ?? defaultTimeout;
  }

}
</script>
<template>
<v-snackbar
  v-model="isShown"
  :color="type"
  :multi-line="isMultiline"
  :timeout="timeout"
  class="snackbar"
>
  <div class="snackbar-container">
    <div class="message-container">
      <h3 v-if="!!title">{{ title }}</h3>
      {{ message }}
    </div>
    <icon-button small :icon="['fas', 'times']" @click="close" />
    <div class="badge" v-if="showBadge">{{ store.snackQueueLength }}</div>
  </div>
</v-snackbar>
</template>
<style lang="sass" scoped>
::v-deep

  .v-snack__content
    padding: 0.7rem 0.75rem 0.7rem 1rem

  .v-snack__action
    margin-right: 0 !important

  .v-sheet.v-snack__wrapper
    box-shadow: none !important

  .v-snack__wrapper
    background-color: transparent
    color: transparent

    &.success
      background-color: var(--color-green-5) !important
      color: var(--color-white) !important

    &.error
      background-color: var(--color-red-5) !important
      color: var(--color-white) !important

.snackbar
  overflow: visible

  .snackbar-container
    position: relative
    display: flex
    overflow: visible
    line-height: 1.65rem
    justify-content: space-between
    width: 100%

    .message-container
      font-size: 1rem
      display: flex
      align-items: center

    .badge
      position: absolute
      color: var(--color-grey-3)
      background: var(--color-grey-8)
      font-weight: 600
      font-family: Montserrat, sans-serif
      width: 2rem
      height: 2rem
      text-align: center
      line-height: 2rem
      border-radius: 50%
      right: -16px
      top: -2rem

    ::v-deep .v-btn
      min-width: auto !important
      height: 31px !important
      margin-right: 0 !important
      padding: 8px !important
      margin-left: 0.5rem
</style>
