<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import debounce from 'lodash-es/debounce';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

@Component({
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class MultiTextFormControl extends Vue implements FormControlComponent<string[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<string[]>;

  @Prop({ type: Number, default: 0 })
  readonly debounceInterval!: number;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isClearable!: boolean;

  @Prop({ type: String, default: null })
  readonly dataCy!: string | null;

  readonly formControlId = createFormControlId();

  readonly debounceLocalValue = debounce(this.textChanged, this.debounceInterval);

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: string[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  textChanged(text: string, index: number): void {
    this.internalValue[index] = text.trim();

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  addString(): void {
    this.internalValue.push('');
  }

  removeString(index: number): void {
    this.internalValue.splice(index, 1);
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ['']
      : this.formControl.value.map((string) => string.trim());
  }

  formValueFromInternalValues(): FormControlValue<string[]> {
    return this.internalValue.map((string) => string.trim());
  }

}
</script>
<template>
<div class="form-control multi-text-form-control" v-bind="$attrs">
  <div class="form-control-input">
    <div
      v-for="(internalString, index) in internalValue"
      :key="index"
      :class="{ 'mb-4': index !== internalValue.length - 1}"
    >
      <v-text-field
        type="text"
        :value="internalString"
        :label="labelWithRequiredIndicator(formControl)"
        @input="textChanged($event, index)"
        @focus="focused"
        @blur="blurred"
        :autofocus="isAutofocused ? $vuetify.breakpoint.mdAndUp : false"
        :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
        :clearable="isClearable"
        outlined
        hide-details
        :data-cy="dataCy"
      >
        <template #append-outer>
          <icon-button
            @click="removeString(index)"
            :icon="['far', 'minus']"
            small
          />
        </template>
      </v-text-field>
    </div>
    <div>
      <icon-button
        @click="addString"
        :icon="['far', 'plus']"
        text="Weiterer Host"
        class="mt-2"
      />
    </div>
    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
  </div>
</div>
</template>
<style lang="sass">
.v-text-field--outlined .v-input__prepend-outer,
.v-text-field--outlined .v-input__append-outer
  margin-top: 12px
</style>
