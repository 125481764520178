import { RouteConfig } from 'vue-router';
import { Feature } from '@/types';
import DocumentList from './components/document-list.vue';

export const consumeDocumentsRoutes: RouteConfig[] = [
  {
    path: '/dokumente',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'consume-documents-document-list',
        path: '/',
        component: DocumentList,
        meta: {
          requiresAuth: true,
          requiresFeature: Feature.DOCUMENTS,
          title: 'Dokumente',
        },
      },
    ],
  },
];
