<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { VuetifySelectItem } from '@/application/types';
import { Weekday } from '@/types';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class WeekdaysFormControl extends Vue implements FormControlComponent<Weekday[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Weekday[]>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: String, default: null })
  readonly placeholder!: string | null;

  readonly formControlId = createFormControlId();

  readonly items: VuetifySelectItem<Weekday>[] = [
    { value: Weekday.MONDAY, text: 'Montag' },
    { value: Weekday.TUESDAY, text: 'Dienstag' },
    { value: Weekday.WEDNESDAY, text: 'Mittwoch' },
    { value: Weekday.THURSDAY, text: 'Donnerstag' },
    { value: Weekday.FRIDAY, text: 'Freitag' },
    { value: Weekday.SATURDAY, text: 'Samstag' },
    { value: Weekday.SUNDAY, text: 'Sonntag' },
  ];

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: Weekday[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? []
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<Weekday[]> {
    return this.internalValue;
  }

}
</script>
<template>
<div class="form-control weekdays-form-control" v-bind="$attrs">
  <div class="form-control-input">
    <v-autocomplete
      v-model="internalValue"
      :label="labelWithRequiredIndicator(formControl)"
      :items="items"
      multiple
      :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
      :autofocus="isAutofocused ? $vuetify.breakpoint.mdAndUp : false"
      @change="selectionChanged"
      @focus="focused"
      @blur="blurred"
      :placeholder="placeholder"
      menu-props="auto"
      hide-details
      outlined
    />
    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
  </div>
</div>
</template>
