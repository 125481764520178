<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useReferencesStore } from '@/private/management/references/store';
import { ExtensionOption } from '@/components/form/html-form-control.vue';
import { useManageModulesStore } from '../store';
import { ContentElementDocument, ModulePage, UpdateContentElementDocumentCommand } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  document: FormControl<string>;
  documentExplanation: FormControl<string>;
}

@Component
export default class UpdateDocumentDialog extends Vue {

  readonly store = useManageModulesStore();
  readonly referencesStore = useReferencesStore();

  @Prop({ type: Object, required: true })
  readonly contentElement!: ContentElementDocument;

  @Prop({ type: Object, required: true })
  readonly modulePage!: ModulePage;

  readonly enabledExtensions: ExtensionOption[] = [
    'bold',
    'italic',
    'bullet-list',
    'ordered-list',
  ];

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isLoading(): boolean {
    return this.referencesStore.isGetDocumentReferencesForAppProcessing;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      if (!this.isDialogVisible) {
        this.form = null;
        return;
      }

      this.referencesStore.getDocumentReferencesForApp()
        .then(() => {
          this.form = this.buildForm();
        })
        .catch((error) => showErrorResponse(error));
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Bezeichnung',
          value: this.contentElement.title,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        document: {
          label: 'Dokument',
          value: this.contentElement.idOfReferencedDocument,
          isRequired: true,
        },
        documentExplanation: {
          label: 'Beschreibung des Dokuments',
          value: this.contentElement.documentExplanation,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateContentElementDocumentCommand = {
      contentElementId: this.contentElement.contentElementId,
      title: formValues.title!,
      idOfReferencedDocument: formValues.document!,
      documentExplanation: formValues.documentExplanation!,
    };

    this.store.updateContentElementDocument(command, this.modulePage.modulePageId)
      .then(() => showSuccessMessage('Dokument wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isUpdateContentElementDocumentProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Dokument anpassen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <a-text-form-control
              :form-control="form.controls.title"
              is-autofocused
            />
          </v-col>
          <v-col>
            <a-document-form-control
              :form-control="form.controls.document"
              :items="referencesStore.documents"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-html-form-control
              :form-control="form.controls.documentExplanation"
              :extensions="enabledExtensions"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isUpdateContentElementDocumentProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isUpdateContentElementDocumentProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Aktualisieren
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
