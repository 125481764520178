import { RouteConfig } from 'vue-router';
import { AppType } from '@/types';
import Login from './components/login.vue';
import UserRegistration from './components/user-registration.vue';
import UserRegistrationWithSponsorLink from './components/user-registration-with-sponsor-link.vue';
import UserRegistrationConfirmation from './components/user-registration-confirmation.vue';
import RequestPasswordReset from './components/request-password-reset.vue';
import PasswordResetConfirmation from './components/password-reset-confirmation.vue';
import UserEmailChangeConfirmation from './components/user-email-change-confirmation.vue';
import UserImpersonation from './components/user-impersonation.vue';

export const authenticationRoutes: RouteConfig[] = [
  {
    name: 'authentication-login',
    path: '/login',
    component: Login,
    meta: {
      alwaysAccessible: true,
      title: 'Anmeldung',
    },
  },
  {
    name: 'authentication-user-registration',
    path: '/registrierung',
    component: UserRegistration,
    meta: {
      title: 'Registrierung',
      requiresAppType: AppType.COURSE,
    },
  },
  {
    name: 'authentication-user-registration-with-sponsor-link',
    path: '/registrierung/s/:registrationLinkSlug',
    component: UserRegistrationWithSponsorLink,
    meta: {
      title: 'Registrierung',
      requiresAppType: AppType.SPONSOR,
    },
  },
  {
    name: 'authentication-user-registration-confirmation',
    path: '/registrierung/:confirmationToken',
    component: UserRegistrationConfirmation,
    meta: {
      title: 'Registrierung abschließen',
    },
  },
  {
    name: 'authentication-request-password-reset',
    path: '/passwort-zuruecksetzen',
    component: RequestPasswordReset,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      title: 'Passwort zurücksetzen',
    },
  },
  {
    name: 'authentication-confirm-password-reset',
    path: '/passwort-zuruecksetzen/:confirmationToken',
    component: PasswordResetConfirmation,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      title: 'Neues Passwort setzen',
    },
  },
  {
    name: 'authentication-confirm-email-change',
    path: '/email-anpassen/:confirmationToken',
    component: UserEmailChangeConfirmation,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      title: 'E-Mail anpassen',
    },
  },
  {
    name: 'authentication-impersonation',
    path: '/profil/:token',
    component: UserImpersonation,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      title: 'Profil',
    },
  },
];
