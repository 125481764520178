import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AdminProperty, AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { AppReference, DocumentReference, ModuleReference } from './types';

// -- Queries

export function getAppReferences(query: AdminProperty): Promise<AppReference[]> {
  const url = `${apiUrl}/admin-api/references/get-app-references-query`;
  return performApiRequest<AppReference[]>({ url, method: 'POST', data: query });
}

export function getModuleReferencesForApp(query: AppAndUserProperty): Promise<ModuleReference[]> {
  const url = `${apiUrl}/api/references/get-module-references-for-app-query`;
  return performApiRequest<ModuleReference[]>({ url, method: 'POST', data: query });
}

export function getDocumentReferencesForApp(query: AppAndUserProperty): Promise<DocumentReference[]> {
  const url = `${apiUrl}/api/references/get-document-references-for-app-query`;
  return performApiRequest<DocumentReference[]>({ url, method: 'POST', data: query });
}
