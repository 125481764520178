<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useOnboardingStore } from '../store';

@Component
export default class BlogStep extends Vue {

  readonly store = useOnboardingStore();
  readonly authenticationStore = useAuthenticationStore();

  submitClicked(): void {
    this.$emit('submitConfirmed');
  }

  skipClicked(): void {
    this.$emit('skipConfirmed');
  }

}
</script>
<template>
<div class="modules">
  <v-card-text class="pb-0">
    <h2>Blog</h2>

    <v-row
      v-if="store.featureExamples && store.featureExamples.blogArticles.length > 0"
      class="blog-article-list mt-4 mb-4 mb-md-2"
    >
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="(blogArticle, index) in store.featureExamples.blogArticles"
        :key="blogArticle.blogArticleId"
      >

        <v-row
          class="no-gutters blog-article"
          :class="{ 'mb-4': index !== store.featureExamples.blogArticles.length - 1 }"
        >
          <v-col cols="4">
            <v-card outlined class="overflow-hidden">
              <div class="blur-hash-image-container">
                <blur-hash-image
                  :hash="blogArticle.image.hash"
                  :src="blogArticle.image.url"
                  :alt="blogArticle.title"
                  :width="20"
                  :height="Math.floor(20 / blogArticle.image.aspectRatio)"
                />
                <span class="new-sign" v-if="blogArticle.isNew">
                Neu
              </span>
              </div>
            </v-card>
          </v-col>
          <v-col cols="8">
            <div class="blog-article-info">
              <strong class="blog-article-title">{{ blogArticle.title }}</strong>
              <span class="d-block action-link">Lesen</span>
            </div>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <p>
      Hier wirst du über Neuigkeiten im Themenfeld Ernährung informiert und bleibst so auf dem Laufenden. In den Blog-Artikeln gehen wir regelmäßig auf neue Trends ein und machen dich auf spannende Themen aufmerksam, mit denen du dich vielleicht noch nicht beschäftigt hast.
    </p>
  </v-card-text>

  <v-divider />
  <v-card-actions>
    <v-spacer />
    <a-cancel-action-button @click="skipClicked">
      Einführung überspringen
    </a-cancel-action-button>
    <a-trigger-primary-process-button @click="submitClicked">
      Weiter
    </a-trigger-primary-process-button>
  </v-card-actions>

</div>
</template>
<style lang="sass" scoped>
.blog-article-list
  font-size: 0.875rem

  .blog-article
    cursor: pointer

    .blur-hash-image-container
      position: relative

      .new-sign
        position: absolute
        bottom: 0
        right: 0
        color: var(--color-white)
        background: var(--color-brand)
        padding: 2px 0.5rem
        font-weight: 600
        border-top-left-radius: 0.25rem

    .blog-article-info
      padding: 0 0 0 0.75rem
      line-height: 1.15

      strong.blog-article-title
        font-weight: 700
        font-family: Montserrat, sans-serif
        color: var(--color-grey-2)
        display: block
        margin-top: 0.25rem
        margin-bottom: 0.25rem

      span::v-deep
        font-size: 0.875rem

        strong
          font-weight: 600

  .action-link
    color: var(--color-brand)
    font-weight: 600
    margin-top: 0.25rem
</style>
