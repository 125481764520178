import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.dialogMaxWidth,"persistent":_vm.store.isUpdateNutritionDiaryEntryDescriptionProcessing,"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"transition":_vm.$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null,"hide-overlay":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c(VCard,[(_vm.form)?_c('a-form',{attrs:{"form":_vm.form}},[_c(VCardTitle,[_vm._v("Beschreibung anpassen")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('a-textarea-form-control',{attrs:{"form-control":_vm.form.controls.description,"rows":4}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{attrs:{"is-processing":_vm.store.isUpdateNutritionDiaryEntryDescriptionProcessing},on:{"click":_vm.closeDialog}}),_c('a-submit-primary-action-button',{attrs:{"is-processing":_vm.store.isUpdateNutritionDiaryEntryDescriptionProcessing,"is-disabled":_vm.isSubmitDisabled}},[_vm._v(" Anpassen ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }