<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { weekdayOfToday } from '@/helpers/date-helpers';
import { weekdayTranslation } from '@/helpers/data';
import { AppType } from '@/types';
import { useUserProgressStore } from '@/private/user/user-progress/store';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import HabitNotificationSettings from '@/private/user/profile/components/habit-notification-settings.vue';
import DisableHabitIntentDialog from './disable-habit-intent-dialog.vue';
import { useManageHabitIntentsStore } from '../store';
import { CreateHabitLogCommand, HabitIntent } from '../types';

@Component({
  components: {
    DisableHabitIntentDialog,
    HabitNotificationSettings,
  },
})
export default class HabitIntentList extends Vue {

  readonly store = useManageHabitIntentsStore();
  readonly userProgressStore = useUserProgressStore();
  readonly authenticationStore = useAuthenticationStore();

  get isLoading(): boolean {
    return this.store.isGetHabitIntentsProcessing;
  }

  get areHabitIntentsAvailable(): boolean {
    return this.store.habitIntents.length > 0;
  }

  get isTodayViewVisible(): boolean {
    return this.areHabitIntentsAvailable;
  }

  get habitIntentsForToday(): HabitIntent[] {
    const today = weekdayOfToday();

    return this.store.habitIntents.filter((habitIntent) => habitIntent.weekdays.includes(today));
  }

  get areHabitIntentsForTodayVisible(): boolean {
    return this.habitIntentsForToday.length > 0;
  }

  get isZPPRequiredNoticeVisible(): boolean {
    return this.authenticationStore.appType === AppType.COURSE;
  }

  mounted(): void {
    this.store.getHabitIntents()
      .catch((error) => showErrorResponse(error));
  }

  translatedWeekdays(habitIntent: HabitIntent): string {
    return habitIntent.weekdays.map((weekday) => weekdayTranslation[weekday].toString()).join(', ');
  }

  habitIntentClicked(habitIntent: HabitIntent): void {
    this.$router.push({ name: 'manage-habit-intents-habit', params: { id: habitIntent.habitIntentId } });
  }

  hasHabitLogForToday(habitIntent: HabitIntent): boolean {
    const today = dayjs();

    return habitIntent.habitLogs
      .filter((habitLog) => habitLog.date.isSame(today, 'day'))
      .length > 0;
  }

  createHabitLog(habitIntent: HabitIntent): void {
    const command: CreateHabitLogCommand = {
      habitIntentId: habitIntent.habitIntentId,
    };

    this.store.createHabitLog(command)
      .then(() => showSuccessMessage('Durchführung wurde eingetragen'))
      .then(() => this.userProgressStore.getUserProgress())
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container class="habit-intent-list">
  <v-row>
    <v-col md="12" lg="9">

      <p>
        Um neue Verhaltensweisen in deinem Alltag zu verankern, empfehlen wir dir, deine Gewohnheiten hier zu tracken. <template v-if="isZPPRequiredNoticeVisible">Das ist optional und du kannst den Präventionskurs auch abschließen, ohne dies zu tun.</template>
      </p>

      <v-progress-linear v-if="isLoading" color="primary" indeterminate class="mb-4" />

    </v-col>

    <v-col cols="12" md="6" v-if="isTodayViewVisible" class="mt-2 mt-md-4 mb-4 mb-md-2">
      <h2>Heutige Gewohnheiten</h2>

      <template v-if="areHabitIntentsForTodayVisible">
        <p>
          Hake alle Gewohnheiten ab, an denen du heute bereits gearbeitet hast.
        </p>
        <v-card outlined class="today-view mb-2">
          <v-list class="py-0">
            <template v-for="(habitIntent, index) in habitIntentsForToday">
              <v-list-item
                :key="habitIntent.habitIntentId"
                :disabled="hasHabitLogForToday(habitIntent)"
                @click="createHabitLog(habitIntent)"
              >
                <v-list-item-action class="mr-3">
                  <font-awesome-icon :icon="['far', 'check-circle']" class="check" v-if="hasHabitLogForToday(habitIntent)" />
                  <font-awesome-icon :icon="['far', 'circle']" v-else />
                </v-list-item-action>
                <v-list-item-content>
                  <strong>{{ habitIntent.title }}</strong>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index !== habitIntentsForToday.length - 1"
                :key="index"
              />
            </template>
          </v-list>
        </v-card>
      </template>
      <template v-else>
        <p>
          Für heute sind keine Gewohnheiten geplant.
        </p>
      </template>
    </v-col>
  </v-row>

  <v-row class="mt-2">
    <v-col cols="12" md="6">
      <h2>Neue Gewohnheit</h2>
      <p>
        Als Teil der Lerninhalte, werden dir neue Gewohnheiten in den Modulen vorgeschlagen. Du kannst aber auch jetzt schon eine neue Gewohnheit hier starten.
      </p>
      <a-trigger-secondary-process-button :to="{ name: 'manage-habit-intents-select-new-habit' }">
        Neue Gewohnheit
      </a-trigger-secondary-process-button>

    </v-col>
  </v-row>

  <template v-if="areHabitIntentsAvailable">
    <h2 class="mt-8 mb-4">Gestartete Gewohnheiten</h2>

    <v-row class="mt-2 mt-md-4">
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="(habitIntent, index) in store.habitIntents"
        :key="habitIntent.habitIntentId"
        class="habit-intent"
        :class="{ 'mb-6': index !== store.habitIntents.length - 1, 'mb-2': index === store.habitIntents.length - 1 }"
        @click="habitIntentClicked(habitIntent)"
      >
        <strong class="habit-intent-title d-block">{{ habitIntent.title }}</strong>
        <span class="d-block weekdays">{{ translatedWeekdays(habitIntent) }}</span>
        <span class="d-block action-link">Gewohnheit anpassen</span>
      </v-col>
    </v-row>
  </template>

  <habit-notification-settings class="mt-6" />

</v-container>
</template>
<style lang="sass" scoped>
.habit-intent-list
  font-size: 0.875rem

  h2
    font-size: 1rem

  .habit-intent
    cursor: pointer

    strong.habit-intent-title
      font-weight: 700
      font-family: Montserrat, sans-serif
      color: var(--color-grey-2)

    .action-link
      color: var(--color-brand)
      font-weight: 600

  .today-view
    strong
      font-weight: 600

    svg
      font-size: 1.25rem
      color: var(--color-grey-5)

      &.check
        color: var(--color-brand)
</style>
