import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.dialogMaxWidth},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Link zum Abschließen der Registrierung")]),_c(VCardText,[_c('p',[_vm._v(" Über folgenden Link, kann der Benutzer die Registrierung abschließen. ")]),_c('pre',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.registrationLink))])]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-execute-primary-action-button',{on:{"click":_vm.copyLinkConfirmed}},[_vm._v(" Link kopieren ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }