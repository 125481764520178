<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { DeleteOwnAccountCommand } from '@/application/whitelabel/authentication/types';
import { clearAllStores } from '@/helpers/store-reset-helper';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { AppType } from '@/types';

interface Controls extends FormControls {
  currentPassword: FormControl<string>;
}

@Component
export default class DeleteOwnAccountDialog extends Vue {

  readonly store = useAuthenticationStore();

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get alertText(): string {
    return this.store.appType === AppType.COURSE
      // eslint-disable-next-line vue/max-len
      ? 'Du löschst damit alle Daten unwiderruflich. Du verlierst damit auch Zugang zu allen gekauften Inhalten und musst diese bei erneutem Interesse erneut kaufen!'
      // eslint-disable-next-line vue/max-len
      : 'Du löscht damit alle Daten unwiderruflich und verlierst den Zugang zur App. Um ein neues Konto erstellen zu können, müsstest du erneut eingeladen werden.';
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        currentPassword: {
          label: 'Aktuelles Passwort',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: DeleteOwnAccountCommand = {
      currentPassword: formValues.currentPassword!,
    };

    this.store.deleteOwnAccount(command)
      .then(() => clearAllStores())
      .then(() => showSuccessMessage('Dein Konto wurde unwiderruflich gelöscht', 6000))
      .then(() => this.$router.push({ name: 'root' }))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isDeleteOwnAccountProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Konto löschen</v-card-title>
      <v-card-text>

        <p>Willst du dein Konto wirklich löschen?</p>

        <a-password-form-control
          :form-control="form.controls.currentPassword"
          is-autofocused
        />

        <a-danger-alert>
          {{ alertText }}
        </a-danger-alert>

      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isDeleteOwnAccountProcessing"
          @click="closeDialog"
        />
        <a-submit-dangerous-action-button
          :is-disabled="isSubmitDisabled"
          :is-processing="store.isDeleteOwnAccountProcessing"
        >
          Löschen
        </a-submit-dangerous-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
