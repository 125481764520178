import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.dialogMaxWidth,"persistent":_vm.store.isDisableHabitIntentProcessing,"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"transition":_vm.$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null,"hide-overlay":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Gewohnheit deaktivieren")]),_c(VCardText,[_c('p',[_vm._v("Willst du die Gewohnheit "),_c('strong',[_vm._v(_vm._s(_vm.habitIntent.title))]),_vm._v(" wirklich deaktivieren?")]),(!_vm.habitIntent.isMinRuntimeReached)?_c('p',[_vm._v(" Eine positive Entwicklung der Gewohnheit sollte nach "+_vm._s(_vm.habitIntent.minRuntime)+" Wochen ab dem "+_vm._s(_vm._f("formatDate")(_vm.habitIntent.minRuntimeReachedAt))+" zu spüren sein. ")]):_vm._e(),_c('p',[_c('strong',[_vm._v("Beim Deaktivieren wird dein Fortschritt mit dieser Gewohnheit zurückgesetzt!")])])]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{attrs:{"is-processing":_vm.store.isDisableHabitIntentProcessing},on:{"click":_vm.closeDialog}}),_c('a-execute-dangerous-action-button',{attrs:{"is-processing":_vm.store.isDisableHabitIntentProcessing},on:{"click":_vm.disableHabitIntentConfirmed}},[_vm._v(" Deaktivieren ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }