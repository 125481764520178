import { FileResponse } from '@/application/types';
import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser, attachAppAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { CreateContentElementAcknowledgementCommand, CreateContentElementDocumentCommand, CreateContentElementHabitProposalCommand, CreateContentElementImageCommand, CreateContentElementQuizCommand, CreateContentElementRichTextCommand, CreateContentElementSelfReflectionCommand, CreateContentElementVideoCommand, CreateModuleCommand, CreateModulePageCommand, DeleteContentElementCommand, DeleteModuleCommand, DisableModuleCommand, DisableModulePageCommand, EnableModuleCommand, EnableModulePageCommand, GetModulePageForPreviewQuery, GetModuleQuery, GetModuleSummaryPDFQuery, Module, ModuleListItem, ModulePageForPreview, MoveContentElementDownCommand, MoveContentElementUpCommand, MoveModuleDownCommand, MoveModulePageDownCommand, MoveModulePageUpCommand, MoveModuleUpCommand, UpdateContentElementAcknowledgementCommand, UpdateContentElementDocumentCommand, UpdateContentElementHabitProposalCommand, UpdateContentElementImageCommand, UpdateContentElementQuizCommand, UpdateContentElementRichTextCommand, UpdateContentElementSelfReflectionCommand, UpdateContentElementVideoCommand, UpdateModuleImageCommand, UpdateModulePageCommand, UpdateModuleTitleCommand } from './types';
import { createContentElementAcknowledgement, createContentElementDocument, createContentElementHabitProposal, createContentElementImage, createContentElementQuiz, createContentElementRichText, createContentElementSelfReflection, createContentElementVideo, createModule, createModulePage, deleteContentElement, deleteModule, disableModule, disableModulePage, enableModule, enableModulePage, getModule, getModulePageForPreview, getModules, getModuleSummaryPDF, moveContentElementDown, moveContentElementUp, moveModuleDown, moveModulePageDown, moveModulePageUp, moveModuleUp, updateContentElementAcknowledgement, updateContentElementDocument, updateContentElementHabitProposal, updateContentElementImage, updateContentElementQuiz, updateContentElementRichText, updateContentElementSelfReflection, updateContentElementVideo, updateModuleImage, updateModulePage, updateModuleTitle } from './service';

interface ManageModulesState {
  moduleList: ModuleListItem[];
  currentModuleId: string | null;
  currentModule: Module | null;
  currentModulePageIdForPreview: string | null;
  currentModulePageForPreview: ModulePageForPreview | null;

  getModulesStatus: ActionStatus;
  getCurrentModuleStatus: ActionStatus;
  getModuleSummaryPDFStatus: ActionStatus;
  createModuleStatus: ActionStatus;
  updateModuleImageStatus: ActionStatus;
  updateModuleTitleStatus: ActionStatus;
  moveModuleUpStatus: ActionStatus;
  moveModuleDownStatus: ActionStatus;
  enableModuleStatus: ActionStatus;
  disableModuleStatus: ActionStatus;
  deleteModuleStatus: ActionStatus;
  createModulePageStatus: ActionStatus;
  updateModulePageStatus: ActionStatus;
  moveModulePageUpStatus: ActionStatus;
  moveModulePageDownStatus: ActionStatus;
  enableModulePageStatus: ActionStatus;
  disableModulePageStatus: ActionStatus;
  createContentElementRichTextStatus: ActionStatus;
  updateContentElementRichTextStatus: ActionStatus;
  createContentElementVideoStatus: ActionStatus;
  updateContentElementVideoStatus: ActionStatus;
  createContentElementImageStatus: ActionStatus;
  updateContentElementImageStatus: ActionStatus;
  createContentElementQuizStatus: ActionStatus;
  updateContentElementQuizStatus: ActionStatus;
  createContentElementHabitProposalStatus: ActionStatus;
  updateContentElementHabitProposalStatus: ActionStatus;
  moveContentElementUpStatus: ActionStatus;
  moveContentElementDownStatus: ActionStatus;
  deleteContentElementStatus: ActionStatus;
  createContentElementAcknowledgementStatus: ActionStatus;
  updateContentElementAcknowledgementStatus: ActionStatus;
  createContentElementDocumentStatus: ActionStatus;
  updateContentElementDocumentStatus: ActionStatus;
  createContentElementSelfReflectionStatus: ActionStatus;
  updateContentElementSelfReflectionStatus: ActionStatus;
  getCurrentModulePageForPreviewStatus: ActionStatus;
}

function initialState(): ManageModulesState {
  return {
    moduleList: [],
    currentModuleId: null,
    currentModule: null,
    currentModulePageIdForPreview: null,
    currentModulePageForPreview: null,

    getModulesStatus: ActionStatus.None,
    getCurrentModuleStatus: ActionStatus.None,
    getModuleSummaryPDFStatus: ActionStatus.None,
    createModuleStatus: ActionStatus.None,
    updateModuleImageStatus: ActionStatus.None,
    updateModuleTitleStatus: ActionStatus.None,
    moveModuleUpStatus: ActionStatus.None,
    moveModuleDownStatus: ActionStatus.None,
    enableModuleStatus: ActionStatus.None,
    disableModuleStatus: ActionStatus.None,
    deleteModuleStatus: ActionStatus.None,
    createModulePageStatus: ActionStatus.None,
    updateModulePageStatus: ActionStatus.None,
    moveModulePageUpStatus: ActionStatus.None,
    moveModulePageDownStatus: ActionStatus.None,
    enableModulePageStatus: ActionStatus.None,
    disableModulePageStatus: ActionStatus.None,
    createContentElementRichTextStatus: ActionStatus.None,
    updateContentElementRichTextStatus: ActionStatus.None,
    createContentElementVideoStatus: ActionStatus.None,
    updateContentElementVideoStatus: ActionStatus.None,
    createContentElementImageStatus: ActionStatus.None,
    updateContentElementImageStatus: ActionStatus.None,
    createContentElementQuizStatus: ActionStatus.None,
    updateContentElementQuizStatus: ActionStatus.None,
    createContentElementHabitProposalStatus: ActionStatus.None,
    updateContentElementHabitProposalStatus: ActionStatus.None,
    moveContentElementUpStatus: ActionStatus.None,
    moveContentElementDownStatus: ActionStatus.None,
    deleteContentElementStatus: ActionStatus.None,
    createContentElementAcknowledgementStatus: ActionStatus.None,
    updateContentElementAcknowledgementStatus: ActionStatus.None,
    createContentElementDocumentStatus: ActionStatus.None,
    updateContentElementDocumentStatus: ActionStatus.None,
    createContentElementSelfReflectionStatus: ActionStatus.None,
    updateContentElementSelfReflectionStatus: ActionStatus.None,
    getCurrentModulePageForPreviewStatus: ActionStatus.None,
  };
}

export const useManageModulesStore = defineStore('manageModulesState', {
  state: (): ManageModulesState => initialState(),
  getters: {
    currentProgressForPreview: (state: ManageModulesState): number => {
      if (state.currentModule === null || state.currentModulePageIdForPreview === null) {
        return 0;
      }

      const index = state.currentModule.pages.findIndex((page) => page.modulePageId === state.currentModulePageIdForPreview);

      return Math.round(index / state.currentModule!.pages.length * 100);
    },
    isGetModulesProcessing: (state: ManageModulesState): boolean =>
      state.getModulesStatus === ActionStatus.InProgress,
    isGetCurrentModuleProcessing: (state: ManageModulesState): boolean =>
      state.getCurrentModuleStatus === ActionStatus.InProgress,
    isGetModuleSummaryPDFProcessing: (state: ManageModulesState): boolean =>
      state.getModuleSummaryPDFStatus === ActionStatus.InProgress,
    isCreateModuleProcessing: (state: ManageModulesState): boolean =>
      state.createModuleStatus === ActionStatus.InProgress,
    isUpdateModuleImageProcessing: (state: ManageModulesState): boolean =>
      state.updateModuleImageStatus === ActionStatus.InProgress,
    isUpdateModuleTitleProcessing: (state: ManageModulesState): boolean =>
      state.updateModuleTitleStatus === ActionStatus.InProgress,
    isMoveModuleUpProcessing: (state: ManageModulesState): boolean =>
      state.moveModuleUpStatus === ActionStatus.InProgress,
    isMoveModuleDownProcessing: (state: ManageModulesState): boolean =>
      state.moveModuleDownStatus === ActionStatus.InProgress,
    isEnableModuleProcessing: (state: ManageModulesState): boolean =>
      state.enableModuleStatus === ActionStatus.InProgress,
    isDisableModuleProcessing: (state: ManageModulesState): boolean =>
      state.disableModuleStatus === ActionStatus.InProgress,
    isDeleteModuleProcessing: (state: ManageModulesState): boolean =>
      state.deleteModuleStatus === ActionStatus.InProgress,
    isCreateModulePageProcessing: (state: ManageModulesState): boolean =>
      state.createModulePageStatus === ActionStatus.InProgress,
    isUpdateModulePageProcessing: (state: ManageModulesState): boolean =>
      state.updateModulePageStatus === ActionStatus.InProgress,
    isMoveModulePageUpProcessing: (state: ManageModulesState): boolean =>
      state.moveModulePageUpStatus === ActionStatus.InProgress,
    isMoveModulePageDownProcessing: (state: ManageModulesState): boolean =>
      state.moveModulePageDownStatus === ActionStatus.InProgress,
    isEnableModulePageProcessing: (state: ManageModulesState): boolean =>
      state.enableModulePageStatus === ActionStatus.InProgress,
    isDisableModulePageProcessing: (state: ManageModulesState): boolean =>
      state.disableModulePageStatus === ActionStatus.InProgress,
    isCreateContentElementRichTextProcessing: (state: ManageModulesState): boolean =>
      state.createContentElementRichTextStatus === ActionStatus.InProgress,
    isUpdateContentElementRichTextProcessing: (state: ManageModulesState): boolean =>
      state.updateContentElementRichTextStatus === ActionStatus.InProgress,
    isCreateContentElementVideoProcessing: (state: ManageModulesState): boolean =>
      state.createContentElementVideoStatus === ActionStatus.InProgress,
    isUpdateContentElementVideoProcessing: (state: ManageModulesState): boolean =>
      state.updateContentElementVideoStatus === ActionStatus.InProgress,
    isCreateContentElementImageProcessing: (state: ManageModulesState): boolean =>
      state.createContentElementImageStatus === ActionStatus.InProgress,
    isUpdateContentElementImageProcessing: (state: ManageModulesState): boolean =>
      state.updateContentElementImageStatus === ActionStatus.InProgress,
    isCreateContentElementQuizProcessing: (state: ManageModulesState): boolean =>
      state.createContentElementQuizStatus === ActionStatus.InProgress,
    isUpdateContentElementQuizProcessing: (state: ManageModulesState): boolean =>
      state.updateContentElementQuizStatus === ActionStatus.InProgress,
    isCreateContentElementHabitProposalProcessing: (state: ManageModulesState): boolean =>
      state.createContentElementHabitProposalStatus === ActionStatus.InProgress,
    isUpdateContentElementHabitProposalProcessing: (state: ManageModulesState): boolean =>
      state.updateContentElementHabitProposalStatus === ActionStatus.InProgress,
    isMoveContentElementUpProcessing: (state: ManageModulesState): boolean =>
      state.moveContentElementUpStatus === ActionStatus.InProgress,
    isMoveContentElementDownProcessing: (state: ManageModulesState): boolean =>
      state.moveContentElementDownStatus === ActionStatus.InProgress,
    isDeleteContentElementProcessing: (state: ManageModulesState): boolean =>
      state.deleteContentElementStatus === ActionStatus.InProgress,
    isCreateContentElementAcknowledgementProcessing: (state: ManageModulesState): boolean =>
      state.createContentElementAcknowledgementStatus === ActionStatus.InProgress,
    isUpdateContentElementAcknowledgementProcessing: (state: ManageModulesState): boolean =>
      state.updateContentElementAcknowledgementStatus === ActionStatus.InProgress,
    isCreateContentElementDocumentProcessing: (state: ManageModulesState): boolean =>
      state.createContentElementDocumentStatus === ActionStatus.InProgress,
    isUpdateContentElementDocumentProcessing: (state: ManageModulesState): boolean =>
      state.updateContentElementDocumentStatus === ActionStatus.InProgress,
    isCreateContentElementSelfReflectionProcessing: (state: ManageModulesState): boolean =>
      state.createContentElementSelfReflectionStatus === ActionStatus.InProgress,
    isUpdateContentElementSelfReflectionProcessing: (state: ManageModulesState): boolean =>
      state.updateContentElementSelfReflectionStatus === ActionStatus.InProgress,
    isGetCurrentModulePageForPreviewProcessing: (state: ManageModulesState): boolean =>
      state.getCurrentModulePageForPreviewStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentModule(currentModuleId: string): Promise<void> {
      this.currentModuleId = currentModuleId;
      return this.getCurrentModule();
    },

    updateCurrentModulePageForPreview(currentModulePageId: string): Promise<void> {
      this.currentModulePageIdForPreview = currentModulePageId;
      return this.getCurrentModulePageForPreview();
    },

    async resetCurrentModule(): Promise<void> {
      this.currentModuleId = null;
      this.currentModule = null;
      this.currentModulePageIdForPreview = null;
      this.currentModulePageForPreview = null;
    },

    // -- Queries

    getModules(): Promise<void> {
      const { getModulesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getModulesStatus,
        () => getModules(attachAppAndUser({}))
          .then(async (moduleList) => {
            this.moduleList = moduleList;
          })
      );
    },

    getCurrentModule(): Promise<void> {
      const query: GetModuleQuery = {
        moduleId: this.currentModuleId!,
      };
      const { getCurrentModuleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCurrentModuleStatus,
        () => getModule(attachAppAndUser(query))
          .then(async (module) => {
            this.currentModule = module;
          })
      );
    },

    getCurrentModulePageForPreview(): Promise<void> {
      const query: GetModulePageForPreviewQuery = {
        moduleId: this.currentModuleId!,
        modulePageId: this.currentModulePageIdForPreview!,
      };
      const { getCurrentModulePageForPreviewStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCurrentModulePageForPreviewStatus,
        () => getModulePageForPreview(attachAppAndUser(query))
          .then(async (modulePageForPreview) => {
            this.currentModulePageForPreview = modulePageForPreview;
          })
      );
    },

    getModuleSummaryPDF(): Promise<FileResponse> {
      const query: GetModuleSummaryPDFQuery = {
        moduleId: this.currentModuleId!,
      };
      const { getModuleSummaryPDFStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getModuleSummaryPDFStatus,
        () => getModuleSummaryPDF(attachAppAndUser(query))
      );
    },

    // -- Commands

    createModule(command: CreateModuleCommand): Promise<void> {
      const { createModuleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createModuleStatus,
        () => createModule(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getModules())
      );
    },

    updateModuleImage(command: UpdateModuleImageCommand): Promise<void> {
      const { updateModuleImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateModuleImageStatus,
        () => updateModuleImage(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getModules())
      );
    },

    updateModuleTitle(command: UpdateModuleTitleCommand): Promise<void> {
      const { updateModuleTitleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateModuleTitleStatus,
        () => updateModuleTitle(attachAppAndUser(command))
          .then(() => this.getModules())
      );
    },

    moveModuleUp(command: MoveModuleUpCommand): Promise<void> {
      const { moveModuleUpStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        moveModuleUpStatus,
        () => moveModuleUp(attachAppAndUser(command))
          .then(() => this.getModules())
      );
    },

    moveModuleDown(command: MoveModuleDownCommand): Promise<void> {
      const { moveModuleDownStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        moveModuleDownStatus,
        () => moveModuleDown(attachAppAndUser(command))
          .then(() => this.getModules())
      );
    },

    enableModule(command: EnableModuleCommand): Promise<void> {
      const { enableModuleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableModuleStatus,
        () => enableModule(attachAppAndUser(command))
          .then(() => this.getModules())
      );
    },

    disableModule(command: DisableModuleCommand): Promise<void> {
      const { disableModuleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableModuleStatus,
        () => disableModule(attachAppAndUser(command))
          .then(() => this.getModules())
      );
    },

    deleteModule(command: DeleteModuleCommand): Promise<void> {
      const { deleteModuleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteModuleStatus,
        () => deleteModule(attachAppAndUser(command))
          .then(() => this.getModules())
      );
    },

    createModulePage(command: CreateModulePageCommand): Promise<void> {
      const { createModulePageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createModulePageStatus,
        () => createModulePage(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    updateModulePage(command: UpdateModulePageCommand): Promise<void> {
      const { updateModulePageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateModulePageStatus,
        () => updateModulePage(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    moveModulePageUp(command: MoveModulePageUpCommand): Promise<void> {
      const { moveModulePageUpStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        moveModulePageUpStatus,
        () => moveModulePageUp(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    moveModulePageDown(command: MoveModulePageDownCommand): Promise<void> {
      const { moveModulePageDownStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        moveModulePageDownStatus,
        () => moveModulePageDown(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    enableModulePage(command: EnableModulePageCommand): Promise<void> {
      const { enableModulePageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableModulePageStatus,
        () => enableModulePage(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    disableModulePage(command: DisableModulePageCommand): Promise<void> {
      const { disableModulePageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableModulePageStatus,
        () => disableModulePage(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    createContentElementRichText(command: CreateContentElementRichTextCommand): Promise<void> {
      const { createContentElementRichTextStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementRichTextStatus,
        () => createContentElementRichText(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    updateContentElementRichText(command: UpdateContentElementRichTextCommand, modulePageId: string): Promise<void> {
      const { updateContentElementRichTextStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementRichTextStatus,
        () => updateContentElementRichText(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(modulePageId))
      );
    },

    createContentElementVideo(command: CreateContentElementVideoCommand): Promise<void> {
      const { createContentElementVideoStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementVideoStatus,
        () => createContentElementVideo(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    updateContentElementVideo(command: UpdateContentElementVideoCommand, modulePageId: string): Promise<void> {
      const { updateContentElementVideoStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementVideoStatus,
        () => updateContentElementVideo(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(modulePageId))
      );
    },

    createContentElementImage(command: CreateContentElementImageCommand): Promise<void> {
      const { createContentElementImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementImageStatus,
        () => createContentElementImage(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.body.modulePageId))
      );
    },

    updateContentElementImage(command: UpdateContentElementImageCommand, modulePageId: string): Promise<void> {
      const { updateContentElementImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementImageStatus,
        () => updateContentElementImage(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(modulePageId))
      );
    },

    createContentElementQuiz(command: CreateContentElementQuizCommand): Promise<void> {
      const { createContentElementQuizStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementQuizStatus,
        () => createContentElementQuiz(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    updateContentElementQuiz(command: UpdateContentElementQuizCommand, modulePageId: string): Promise<void> {
      const { updateContentElementQuizStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementQuizStatus,
        () => updateContentElementQuiz(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(modulePageId))
      );
    },

    createContentElementHabitProposal(command: CreateContentElementHabitProposalCommand): Promise<void> {
      const { createContentElementHabitProposalStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementHabitProposalStatus,
        () => createContentElementHabitProposal(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    updateContentElementHabitProposal(command: UpdateContentElementHabitProposalCommand, modulePageId: string): Promise<void> {
      const { updateContentElementHabitProposalStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementHabitProposalStatus,
        () => updateContentElementHabitProposal(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(modulePageId))
      );
    },

    moveContentElementUp(command: MoveContentElementUpCommand, modulePageId: string): Promise<void> {
      const { moveContentElementUpStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        moveContentElementUpStatus,
        () => moveContentElementUp(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(modulePageId))
      );
    },

    moveContentElementDown(command: MoveContentElementDownCommand, modulePageId: string): Promise<void> {
      const { moveContentElementDownStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        moveContentElementDownStatus,
        () => moveContentElementDown(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(modulePageId))
      );
    },

    deleteContentElement(command: DeleteContentElementCommand, modulePageId: string): Promise<void> {
      const { deleteContentElementStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteContentElementStatus,
        () => deleteContentElement(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(modulePageId))
      );
    },

    createContentElementAcknowledgement(command: CreateContentElementAcknowledgementCommand): Promise<void> {
      const { createContentElementAcknowledgementStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementAcknowledgementStatus,
        () => createContentElementAcknowledgement(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    updateContentElementAcknowledgement(command: UpdateContentElementAcknowledgementCommand, modulePageId: string): Promise<void> {
      const { updateContentElementAcknowledgementStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementAcknowledgementStatus,
        () => updateContentElementAcknowledgement(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(modulePageId))
      );
    },

    createContentElementDocument(command: CreateContentElementDocumentCommand): Promise<void> {
      const { createContentElementDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementDocumentStatus,
        () => createContentElementDocument(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    updateContentElementDocument(command: UpdateContentElementDocumentCommand, modulePageId: string): Promise<void> {
      const { updateContentElementDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementDocumentStatus,
        () => updateContentElementDocument(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(modulePageId))
      );
    },

    createContentElementSelfReflection(command: CreateContentElementSelfReflectionCommand): Promise<void> {
      const { createContentElementSelfReflectionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementSelfReflectionStatus,
        () => createContentElementSelfReflection(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(command.modulePageId))
      );
    },

    updateContentElementSelfReflection(command: UpdateContentElementSelfReflectionCommand, modulePageId: string): Promise<void> {
      const { updateContentElementSelfReflectionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementSelfReflectionStatus,
        () => updateContentElementSelfReflection(attachAppAndUser(command))
          .then(() => this.getCurrentModule())
          .then(() => this.updateCurrentModulePageForPreview(modulePageId))
      );
    },

  },
});
