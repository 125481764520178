<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { isHabitReminderNotification, isModuleUnlockedNotification, NotificationConfiguration } from '@/types';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useDashboardStore } from '../store';
import { MarkNotificationAsSeenCommand, UnseenNotification } from '../types';

@Component
export default class Notifications extends Vue {

  readonly store = useDashboardStore();

  get areNotificationsVisible(): boolean {
    return this.knownNotifications.length > 0;
  }

  // Filtered as it's possible that new notification types are added and the user hasn't updated the app yet (as it's the first page)
  get knownNotifications(): UnseenNotification[] {
    return this.store.unseenNotifications.filter((notification) => isModuleUnlockedNotification(notification.configuration)
      || isHabitReminderNotification(notification.configuration));
  }

  mounted(): void {
    this.store.getUnseenNotifications()
      .catch((error) => showErrorResponse(error));
  }

  notificationDescription(configuration: NotificationConfiguration): string {
    if (isModuleUnlockedNotification(configuration)) {
      return `Neues Modul wurde freigeschaltet`;
    }
    if (isHabitReminderNotification(configuration)) {
      return `Erinnerung an deine Gewohnheit`;
    }

    throw new Error('Unknown notification type');
  }

  doesNotificationLeadToDifferentPage(configuration: NotificationConfiguration): boolean {
    return isModuleUnlockedNotification(configuration)
      || isHabitReminderNotification(configuration);
  }

  notificationClicked(notification: UnseenNotification): void {
    const command: MarkNotificationAsSeenCommand = {
      notificationId: notification.notificationId,
    };

    this.store.markNotificationAsSeen(command)
      .then(() => this.store.getUnseenNotifications())
      .catch((error) => showErrorResponse(error));

    if (isModuleUnlockedNotification(notification.configuration)) {
      this.$router.push({ name: 'consume-modules-consume-module', params: { id: notification.configuration.payload.moduleId } });
      return;
    }
    if (isHabitReminderNotification(notification.configuration)) {
      this.$router.push({ name: 'manage-habit-intents-habit-intent-list' });
      return;
    }

    throw new Error('Unknown notification type');
  }

  markAllNotificationsAsSeen(): void {
    this.store.markAllNotificationAsSeen()
      .then(() => showSuccessMessage('Alle Benachrichtigungen wurden als gesehen markiert.'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<div
  v-if="areNotificationsVisible"
  v-bind="$attrs"
>
  <h3>Benachrichtigungen</h3>

  <v-row>
    <v-col
      cols="12"
      lg="6"
      md="9"
    >

      <v-card
        outlined
        class="mt-2"
      >
        <v-list
          dense
          class="py-0"
        >
          <template v-for="(unseenNotification, index) in knownNotifications">
            <v-list-item
              :key="unseenNotification.notificationId"
              @click="notificationClicked(unseenNotification)"
              class="px-3 py-1"
            >
              <v-list-item-content>
                <strong
                  v-if="doesNotificationLeadToDifferentPage(unseenNotification.configuration)"
                  class="d-block primary--text"
                >
                  {{ notificationDescription(unseenNotification.configuration) }}
                </strong>
                <span
                  v-else
                  class="d-block primary--text"
                >
                  {{ notificationDescription(unseenNotification.configuration) }}
                </span>
                <span class="d-block mt-1">{{ unseenNotification.configuration.payload.context }}</span>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="index !== knownNotifications.length - 1"
              :key="`divider-${unseenNotification.notificationId}`"
            />
          </template>
        </v-list>
      </v-card>

      <a-execute-secondary-action-button
        v-if="knownNotifications.length > 0"
        @click="markAllNotificationsAsSeen"
        :is-processing="store.isMarkAllNotificationAsSeenProcessing"
        class="mt-2"
        is-small
      >
        Alle als gesehen markieren
      </a-execute-secondary-action-button>

    </v-col>
  </v-row>

</div>
</template>
<style lang="sass" scoped>
.v-list-item__content
  strong
    font-weight: 600
  .created-at
    font-size: 0.75rem
</style>
