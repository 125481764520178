<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, minArrayCountRule, noDuplicateStringsRule } from '@/components/form';
import { EmailAddress } from '@/types';
import { useUserInvitesStore } from '../store';
import { InviteUsersWithDirectLicenseCommand } from '../types';

interface Controls extends FormControls {
  emailAddresses: FormControl<EmailAddress[]>;
}

@Component
export default class InviteUsersWithDirectLicenseDialog extends Vue {

  readonly store = useUserInvitesStore();

  readonly dialogMaxWidth = DialogWidth.large;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get submitText(): string {
    return `${this.emailAddresses.length} Benutzer einladen`;
  }

  get emailAddresses(): EmailAddress[] {
    const formValues = getFormValues(this.form!);

    return formValues.emailAddresses || [];
  }

  get isEmptyEmailAddressListVisible(): boolean {
    return this.emailAddresses.length === 0;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        emailAddresses: {
          label: 'E-Mail Adressen',
          value: [],
          rules: [
            minArrayCountRule(1, 'Es muss mindestens eine E-Mail Adresse eingegeben werden'),
            noDuplicateStringsRule('Mindestens eine E-Mail Adresse kommt doppelt vor'),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: InviteUsersWithDirectLicenseCommand = {
      emailAddresses: formValues.emailAddresses!,
    };

    this.store.inviteUsersWithDirectLicense(command)
      .then(() => showSuccessMessage('Benutzer wurden eingeladen.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isInviteUsersWithDirectLicenseProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Benutzer mit direkten Lizenzen einladen</v-card-title>
      <v-card-text>
        <p>
          Es darf <strong>pro Zeile nur eine E-Mail Adresse</strong> eingegeben werden.
        </p>
        <v-row>
          <v-col>
            <a-multi-email-address-textarea-form-control
              :form-control="form.controls.emailAddresses"
              is-autofocused
            />
          </v-col>
        </v-row>
        <v-list flat class="mb-2 pt-0" dense>
          <v-subheader>E-Mail Adressen die eingeladen werden</v-subheader>
          <v-list-item v-if="isEmptyEmailAddressListVisible">
            <v-list-item-content>
              Noch keine E-Mail Adressen eingegeben.
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="(emailAddress, index) in emailAddresses" :key="index">
            <v-list-item-content>
              {{ emailAddress }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <a-info-alert class="mt-4">
          Jeder eingeladene Benutzer wird in Rechnung gestellt.<br />
          Benutzer die hier eingeladen werden, bekommen keine Teilnehmerbescheinigung und können nichts bei der Krankenkasse einreichen.
        </a-info-alert>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isInviteUsersWithDirectLicenseProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isInviteUsersWithDirectLicenseProcessing"
          :is-disabled="isSubmitDisabled"
        >
          {{ submitText }}
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
