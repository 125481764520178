<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, minNumberRule, moduloByRule } from '@/components/form';
import { useManageSponsorsStore } from '../store';
import { Sponsor, UpdateAvailableSeatsForSponsorCommand } from '../types';

interface Controls extends FormControls {
  availableSeats: FormControl<number>;
}

@Component
export default class UpdateAvailableSeatsForSponsorDialog extends Vue {

  readonly store = useManageSponsorsStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly sponsor!: Sponsor;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        availableSeats: {
          label: 'Verfügbare Lizenzen',
          value: this.sponsor.availableSeats,
          isRequired: true,
          rules: [
            minNumberRule(10),
            moduloByRule(10, `Lizenzen können nur in einer 10er Taktung vergeben werden`),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateAvailableSeatsForSponsorCommand = {
      sponsorId: this.sponsor.sponsorId,
      availableSeats: formValues.availableSeats!,
    };

    this.store.updateAvailableSeatsForSponsor(command)
      .then(() => showSuccessMessage('Verfügbare Lizenzen des Sponsors wurden aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isUpdateAvailableSeatsForSponsorProcessing"
>
  <template #activator="{ on }">
    <div class="d-inline-block" v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Verfügbare Lizenzen anpassen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <a-number-form-control
              :form-control="form.controls.availableSeats"
              is-autofocused
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isUpdateAvailableSeatsForSponsorProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isUpdateAvailableSeatsForSponsorProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
