<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { Line } from 'vue-chartjs/legacy';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';

@Component({
  components: {
    'chart-line': Line,
  },
})
export default class UserGoalProgressDummyChart extends Vue {

  readonly colorGreen = (this.$vuetify.theme.themes.light.green as any).base.toString();

  readonly chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          font: {
            size: 14,
            family: 'Inter,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif',
          },
          color: '#494C50',
          padding: 16,
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
          color: '#DFE0E2',
        },
        suggestedMin: 1,
        suggestedMax: 10,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  chartData: ChartData | null = null;

  mounted() {
    const chartDataset: ChartDataset = {
      data: [
        4,
        5,
        4,
        7,
        9,
      ],
      borderColor: this.colorGreen,
      pointBackgroundColor: this.colorGreen,
    };

    const now = dayjs();
    this.chartData = {
      labels: [
        now.subtract(29, 'day').format('DD.MM.YY'),
        now.subtract(22, 'day').format('DD.MM.YY'),
        now.subtract(15, 'day').format('DD.MM.YY'),
        now.subtract(8, 'day').format('DD.MM.YY'),
        now.subtract(1, 'day').format('DD.MM.YY'),
      ],
      datasets: [chartDataset],
    };
  }

}
</script>
<template>
  <v-card outlined>
    <v-card-text>
      <h4 class="d-block mb-4">Essgewohnheiten verbessern</h4>
      <chart-line
        v-if="chartData"
        :height="250"
        :chart-options="chartOptions"
        :chart-data="chartData"
      />
    </v-card-text>
  </v-card>
</template>
