export const unbeschwertEssenTheme = {
  primary: {
    base: '#7E7E7E',
    darken4: '#171717',
    darken3: '#282828',
    darken2: '#3E3E3E',
    darken1: '#5A5A5A',
    lighten1: '#7E7E7E',
    lighten2: '#9D9C9C',
    lighten3: '#B2B2B2',
    lighten4: '#CACACA',
    lighten5: '#F0F0F0',
  },
  secondary: {
    base: '#7E7E7E',
    darken4: '#171717',
    darken3: '#282828',
    darken2: '#3E3E3E',
    darken1: '#5A5A5A',
    lighten1: '#7E7E7E',
    lighten2: '#9D9C9C',
    lighten3: '#B2B2B2',
    lighten4: '#CACACA',
    lighten5: '#F0F0F0',
  },
  accent: {
    base: '#6AB052',
    darken4: '#356127',
    darken3: '#417330',
    darken2: '#4F8B3B',
    darken1: '#5BA941',
    lighten1: '#6AB052',
    lighten2: '#8CBF7A',
    lighten3: '#ABC7A1',
    lighten4: '#C9D8C4',
    lighten5: '#DDEDD7',
  },
  info: {
    base: '#7E7E7E',
    darken4: '#171717',
    darken3: '#282828',
    darken2: '#3E3E3E',
    darken1: '#5A5A5A',
    lighten1: '#7E7E7E',
    lighten2: '#9D9C9C',
    lighten3: '#B2B2B2',
    lighten4: '#CACACA',
    lighten5: '#F0F0F0',
  },
  warning: {
    base: '#FFB800',
    darken4: '#A67700',
    darken3: '#C38D00',
    darken2: '#DEA000',
    darken1: '#F3AF00',
    lighten1: '#FFB800',
    lighten2: '#FFC940',
    lighten3: '#FFD771',
    lighten4: '#FFE9B1',
    lighten5: '#FFECBC',
  },
  error: {
    base: '#EC584A',
    darken4: '#5E1016',
    darken3: '#7D1820',
    darken2: '#AB313B',
    darken1: '#BD4851',
    lighten1: '#EC584A',
    lighten2: '#F3B3B0',
    lighten3: '#F9DCD8',
    lighten4: '#FFEEE9',
    lighten5: '#FFF3FC',
  },
  success: {
    base: '#6AB052',
    darken4: '#356127',
    darken3: '#417330',
    darken2: '#4F8B3B',
    darken1: '#5BA941',
    lighten1: '#6AB052',
    lighten2: '#8CBF7A',
    lighten3: '#ABC7A1',
    lighten4: '#C9D8C4',
    lighten5: '#DDEDD7',
  },
  red: {
    base: '#EC584A',
    darken4: '#5E1016',
    darken3: '#7D1820',
    darken2: '#AB313B',
    darken1: '#BD4851',
    lighten1: '#EC584A',
    lighten2: '#F3B3B0',
    lighten3: '#F9DCD8',
    lighten4: '#FFEEE9',
    lighten5: '#FFF3FC',
  },
  green: {
    base: '#6AB052',
    darken4: '#356127',
    darken3: '#417330',
    darken2: '#4F8B3B',
    darken1: '#5BA941',
    lighten1: '#6AB052',
    lighten2: '#8CBF7A',
    lighten3: '#ABC7A1',
    lighten4: '#C9D8C4',
    lighten5: '#DDEDD7',
  },
  yellow: {
    base: '#ECEF59',
    darken4: '#B7B937',
    darken3: '#C6C83E',
    darken2: '#D2D444',
    darken1: '#DCDF4B',
    lighten1: '#ECEF59',
    lighten2: '#F2F481',
    lighten3: '#F2F39B',
    lighten4: '#F9FAC5',
    lighten5: '#FDFEE6',
  },
  blue: {
    base: '#1FAFC2',
    darken4: '#004A61',
    darken3: '#006786',
    darken2: '#0180A6',
    darken1: '#0E9FC0',
    lighten1: '#1FAFC2',
    lighten2: '#2BBAC4',
    lighten3: '#3AC8C5',
    lighten4: '#6CDCDA',
    lighten5: '#B2EAE9',
  },
  grey: {
    base: '#7E7E7E',
    darken4: '#171717',
    darken3: '#282828',
    darken2: '#3E3E3E',
    darken1: '#5A5A5A',
    lighten1: '#7E7E7E',
    lighten2: '#9D9C9C',
    lighten3: '#B2B2B2',
    lighten4: '#CACACA',
    lighten5: '#F0F0F0',
  },
};
