<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/common/snackbar/service';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import CreateHabitIntentDialog from '@/private/user/habits/components/create-habit-intent-dialog.vue';
import { AppType } from '@/types';
import { useConsumeModulesStore } from '../store';
import { ContentElementHabitProposal } from '../types';

@Component({
  components: {
    CreateHabitIntentDialog,
  },
})
export default class HabitProposal extends Vue {

  readonly store = useConsumeModulesStore();
  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Object, required: true })
  readonly contentElement!: ContentElementHabitProposal;

  get isZPPRequiredNoticeVisible(): boolean {
    return this.authenticationStore.appType === AppType.COURSE;
  }

  habitIntentCreated(): void {
    this.store.getModule()
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<div class="habit-proposal">
  <strong class="d-block mb-1">Neue Gewohnheit starten:<br class="d-block d-md-none" /> {{ contentElement.habitTitle }}</strong>
  <p v-if="contentElement.habitIntroduction">
    {{ contentElement.habitIntroduction }}
  </p>
  <create-habit-intent-dialog
    :habit-id="contentElement.habitId"
    v-if="!contentElement.isHabitIntentCreated"
    @habit-intent-created="habitIntentCreated"
  >
    <a-trigger-secondary-process-button>
      Gewohnheit erstellen
    </a-trigger-secondary-process-button>
  </create-habit-intent-dialog>
  <template v-else>
    <div>
      <font-awesome-icon :icon="['far', 'check']" class="mr-2" />
      <span>Die Gewohnheit "{{ contentElement.habitTitle }}" ist in der Liste der Gewohnheiten an denen du arbeitest.</span>
    </div>
    <div class="mt-4">
      <a-trigger-secondary-process-button :to="{ name: 'manage-habit-intents-habit-intent-list' }">
        Gewohnheiten anzeigen
      </a-trigger-secondary-process-button>
    </div>
  </template>
  <p class="mb-0 mt-4" v-if="isZPPRequiredNoticeVisible">
    Um neue Verhaltensweisen in deinem Alltag zu verankern, empfehlen wir dir, deine Gewohnheiten hier zu tracken. Das ist optional und du kannst den Präventionskurs auch abschließen, ohne dies zu tun.
  </p>
</div>
</template>
<style lang="sass" scoped>
.habit-proposal
  background: var(--color-grey-9)
  padding: 1.25rem 1rem
  border: solid var(--color-grey-8)
  border-width: 1px 0
  color: var(--color-grey-3)

  ::v-deep
    p
      font-size: 1rem
      color: var(--color-grey-3)

  strong
    font-weight: 700
    font-family: Montserrat, sans-serif
    color: var(--color-grey-2)
</style>
