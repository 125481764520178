import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.dialogMaxWidth,"persistent":_vm.store.isGrantUserPaywallAccessProcessing},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Inhalte manuell freischalten")]),_c(VCardText,[_c('p',[_vm._v("Soll der Benutzer "),_c('strong',[_vm._v(_vm._s(_vm.user.emailAddress))]),_vm._v(" wirklich Zugriff auf die Inhalte hinter der Paywall bekommen?")])]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{attrs:{"is-processing":_vm.store.isGrantUserPaywallAccessProcessing},on:{"click":_vm.closeDialog}}),_c('a-execute-dangerous-action-button',{attrs:{"is-processing":_vm.store.isGrantUserPaywallAccessProcessing},on:{"click":_vm.grantUserPaywallAccessConfirmed}},[_vm._v(" Freischalten ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }