import { render, staticRenderFns } from "./nutrition-diary-timeline.vue?vue&type=template&id=0fa5e966&scoped=true"
import script from "./nutrition-diary-timeline.vue?vue&type=script&lang=ts"
export * from "./nutrition-diary-timeline.vue?vue&type=script&lang=ts"
import style0 from "./nutrition-diary-timeline.vue?vue&type=style&index=0&id=0fa5e966&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa5e966",
  null
  
)

export default component.exports