import { apiUrl, performApiRequest, performApiRequestForFile } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { Document, GetDocumentFileQuery, SendDocumentViaEmailCommand } from './types';

// -- Queries

export function getDocuments(query: AppAndUserProperty): Promise<Document[]> {
  const url = `${apiUrl}/api/documents/get-documents-query`;
  return performApiRequest<Document[]>({ url, method: 'POST', data: query });
}

export function getDocumentFile(query: GetDocumentFileQuery & AppAndUserProperty): Promise<FileResponse> {
  const url = `${apiUrl}/api/documents/get-document-file-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

// -- Commands

export function sendDocumentViaEmail(query: SendDocumentViaEmailCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/documents/send-document-via-email-command`;
  return performApiRequest({ url, method: 'POST', data: query });
}
