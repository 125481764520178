import { RouteConfig } from 'vue-router';
import { Feature } from '@/types';
import BlogArticleList from './components/blog-article-list.vue';
import BlogArticleDetails from './components/blog-article-details.vue';

export const consumeBlogArticlesRoutes: RouteConfig[] = [
  {
    path: '/blog',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'consume-blog-articles-blog-article-list',
        path: '/',
        component: BlogArticleList,
        meta: {
          requiresAuth: true,
          requiresFeature: Feature.BLOG,
          title: 'Blog',
        },
      },
      {
        name: 'consume-blog-articles-blog-article-details',
        path: 'artikel/:blogArticleId',
        component: BlogArticleDetails,
        meta: {
          requiresAuth: true,
          requiresFeature: Feature.BLOG,
          title: 'Blog',
        },
      },
    ],
  },
];
