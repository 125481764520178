<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CreateContactMessageDialog from './create-contact-message-dialog.vue';

@Component({
  components: {
    CreateContactMessageDialog,
  },
})
export default class ContactIntroduction extends Vue {}
</script>
<template>
<v-container class="contact-introduction">
  <v-row>
    <v-col md="12" lg="9">
      <p>
        Wenn du technische Probleme haben solltest, kannst du dich jederzeit bei uns melden.
      </p>
      <p>
        Eine Antwort kann ein paar Stunden (max. 48 Stunden) dauern, aber wir antworten dir auf jeden Fall.
      </p>

      <create-contact-message-dialog>
        <a-trigger-primary-process-button>
          Support anfordern
        </a-trigger-primary-process-button>
      </create-contact-message-dialog>

      <a-trigger-tertiary-process-button
        :to="{ name: 'authentication-login' }"
        class="mt-6 mt-md-0 ml-0 ml-md-4"
      >
        Zurück
      </a-trigger-tertiary-process-button>

    </v-col>
  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.contact-introduction
  font-size: 0.875rem
</style>
