import { apiUrl, performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { AppAndUserProperty, AppAndUserPropertyForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { ExperienceReport, CreateExperienceReportCommand, PublishExperienceReportCommand, UnpublishExperienceReportCommand, UpdateExperienceReportCommand, UpdateOrderOfExperienceReportsAsManagerCommand } from './types';

// -- Queries

export function getExperienceReports(query: AppAndUserProperty): Promise<ExperienceReport[]> {
  const url = `${apiUrl}/api/experience-reports/get-experience-reports-for-management-query`;
  return performApiRequest<ExperienceReport[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createExperienceReport(command: CreateExperienceReportCommand & AppAndUserPropertyForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/experience-reports/create-experience-report-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateExperienceReport(command: UpdateExperienceReportCommand & AppAndUserPropertyForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/experience-reports/update-experience-report-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function publishExperienceReport(command: PublishExperienceReportCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/experience-reports/publish-experience-report-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function unpublishExperienceReport(command: UnpublishExperienceReportCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/experience-reports/unpublish-experience-report-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateOrderOfExperienceReports(
  command: UpdateOrderOfExperienceReportsAsManagerCommand & AppAndUserProperty
): Promise<void> {
  const url = `${apiUrl}/api/experience-reports/update-order-of-experience-reports-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
