import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"md":"12","lg":"9"}},[_c(VCard,{attrs:{"outlined":""}},[(_vm.form)?_c('a-form',{attrs:{"form":_vm.form}},[_c(VCardText,{staticClass:"pt-6 pb-0"},[_c('a-password-form-control',{attrs:{"form-control":_vm.form.controls.password,"is-autofocused":""}}),_c('a-password-form-control',{attrs:{"form-control":_vm.form.controls.repeatPassword}})],1),_c(VCardActions,[_c(VSpacer),_c('a-cancel-action-button',{attrs:{"to":{ name: 'authentication-login' }}},[_vm._v(" Zur Anmeldung ")]),_c('a-submit-primary-action-button',{attrs:{"is-disabled":_vm.isSubmitDisabled,"is-processing":_vm.authenticationStore.isConfirmPasswordResetProcessing}},[_vm._v(" Passwort setzen ")])],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }