import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AdminProperty } from '@/helpers/default-parameter-helper';
import { App } from './types';

// -- Queries

export function getAvailableSeatsHistory(query: AdminProperty): Promise<App[]> {
  const url = `${apiUrl}/admin-api/sponsors/get-available-seats-history-as-admin-query`;
  return performApiRequest<App[]>({ url, method: 'POST', data: query });
}
