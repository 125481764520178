<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentElementRichTextForPreview } from '../types';

@Component
export default class RichTextPreview extends Vue {

  @Prop()
  readonly contentElement!: ContentElementRichTextForPreview;

}
</script>
<template>
<div class="rich-text" v-html="contentElement.textHtml" />
</template>
<style lang="sass" scoped>
.rich-text::v-deep
  h2
    margin-top: 1rem

  p,
  li
    font-size: 1rem

  > ul,
  > ol
    margin-bottom: 1rem
    padding-left: 1rem

    li
      padding-bottom: 0.5rem

    p
      margin-bottom: 0

  > p
    margin-bottom: 1rem

  table
    border-collapse: collapse
    margin-bottom: 1rem
    width: 100%
    max-width: 100%
    font-size: 0.875rem

    th
      background: var(--color-grey-9)
      border: 1px solid var(--color-grey-5)
      padding: 0.25rem 0.5rem
      font-weight: bold
      text-align: left
      color: var(--color-grey-2)
      line-height: 1.7

      p
        padding: 0
        margin: 0

    thead
      td
        background: var(--color-grey-9)
        font-weight: bold
        color: var(--color-grey-2)
        line-height: 1.7

    td
      padding: 0.25rem 0.5rem
      border: 1px solid var(--color-grey-5)
      word-break: break-all
      hyphens: auto
      color: var(--color-grey-3)
      line-height: 1.7

      p
        padding: 0
        margin: 0
</style>
