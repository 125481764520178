<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { isNativeApplication } from '@/helpers/detection-helpers';

type IconButtonType = 'button' | 'icon' | 'menu';

@Component
export default class IconButton extends Vue {

  @Prop({ type: Object })
  readonly to!: RawLocation;

  @Prop({ type: String, default: 'button' })
  readonly type!: IconButtonType;

  @Prop({ type: Array, default: () => ['far', 'exclamation-circle'] })
  readonly icon!: string[];

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: String })
  readonly tooltip!: string;

  @Prop({ type: String, default: '' })
  readonly disabledTooltip!: string;

  @Prop({ type: String })
  readonly text!: string;

  @Prop({ type: Boolean, default: false })
  readonly small!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly statusIcon!: boolean;

  get isTooltipDisabled(): boolean {
    return isNativeApplication() || (!this.tooltip && !this.$slots.tooltip && !this.disabledTooltip && !this.$slots.disabledTooltip);
  }

  // We use this to push through the Vuetify activation listeners, we are not 100% sure why we need this
  onClick(event: MouseEvent): void {
    this.$emit('click', event);
  }
}
</script>
<template>
<div class="icon-button">
  <template v-if="type === 'button' || type === 'icon'">
    <v-tooltip bottom :disabled="isTooltipDisabled" :max-width="200">
      <template #activator="{ on: tooltip }">
        <div v-on="tooltip">
          <v-btn text @click="onClick" :to="to" :disabled="disabled" :class="{ 'small': small, 'no-action': type === 'icon', 'status-icon': statusIcon }">
            <font-awesome-icon :icon="icon" />
            <span class="text" v-if="text">{{ text }}</span>
          </v-btn>
        </div>
      </template>
      <slot name="tooltip" v-if="!disabled">
        <span>{{ tooltip }}</span>
      </slot>
      <slot name="disabledTooltip" v-if="disabled">
        <span>{{ disabledTooltip }}</span>
      </slot>
    </v-tooltip>
  </template>

  <template v-if="type === 'menu'">
    <v-menu>
      <template #activator="{ on: menu }">
        <v-tooltip bottom :disabled="isTooltipDisabled" :max-width="200">
          <template #activator="{ on: tooltip }">
            <div v-on="tooltip">
              <v-btn text :disabled="disabled" v-on="menu" :class="{ 'small': small }">
                <font-awesome-icon :icon="icon" />
                <span class="text" v-if="text">{{ text }}</span>
              </v-btn>
            </div>
          </template>
          <slot name="tooltip" v-if="!disabled">
            <span>{{ tooltip }}</span>
          </slot>
          <slot name="disabledTooltip" v-if="disabled">
            <span>{{ disabledTooltip }}</span>
          </slot>
        </v-tooltip>
      </template>
      <slot name="menu" />
    </v-menu>
  </template>
</div>
</template>
<style lang="sass" scoped>
.icon-button
  display: inline-block

  .v-btn
    outline: none

    .text
      margin-left: 0.5rem

    svg
      margin: 0 !important
      outline: none

    &.v-btn--disabled
      background-color: transparent !important

      svg
        color: var(--color-grey-8)

    &.small
      padding: 6px 8px !important
      min-width: auto !important
      height: 31px !important
      margin: 0

    &.no-action
      height: auto
      cursor: default
      transition: none

      // Disables animation on button leave
      &:before
        transition: none

      // Disables hover animation
      &:hover:before
        background-color: transparent

      // Disables click animation
      .v-ripple__container
        display: none

    &.status-icon
      svg
        font-size: 1.2rem
        margin-top: 4px

// Vuetify uses an important style and therefore we have to define the whole path
.theme--light
  .icon-button
    .v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
      background-color: transparent !important
</style>
