import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser, attachAppAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { ExperienceReport, CreateExperienceReportCommand, PublishExperienceReportCommand, UnpublishExperienceReportCommand, UpdateExperienceReportCommand, UpdateOrderOfExperienceReportsAsManagerCommand } from './types';
import { createExperienceReport, getExperienceReports, publishExperienceReport, unpublishExperienceReport, updateExperienceReport, updateOrderOfExperienceReports } from './service';

interface ManageExperienceReportsState {
  experienceReports: ExperienceReport[];

  getExperienceReportsStatus: ActionStatus;
  createExperienceReportStatus: ActionStatus;
  updateExperienceReportStatus: ActionStatus;
  publishExperienceReportStatus: ActionStatus;
  unpublishExperienceReportStatus: ActionStatus;
  updateOrderOfExperienceReportsStatus: ActionStatus;
}

function initialState(): ManageExperienceReportsState {
  return {
    experienceReports: [],

    getExperienceReportsStatus: ActionStatus.None,
    createExperienceReportStatus: ActionStatus.None,
    updateExperienceReportStatus: ActionStatus.None,
    publishExperienceReportStatus: ActionStatus.None,
    unpublishExperienceReportStatus: ActionStatus.None,
    updateOrderOfExperienceReportsStatus: ActionStatus.None,
  };
}

export const useManageExperienceReportsStore = defineStore('manageExperienceReports', {
  state: (): ManageExperienceReportsState => initialState(),
  getters: {
    isGetExperienceReportsProcessing: (state: ManageExperienceReportsState): boolean =>
      state.getExperienceReportsStatus === ActionStatus.InProgress,
    isCreateExperienceReportProcessing: (state: ManageExperienceReportsState): boolean =>
      state.createExperienceReportStatus === ActionStatus.InProgress,
    isUpdateExperienceReportProcessing: (state: ManageExperienceReportsState): boolean =>
      state.updateExperienceReportStatus === ActionStatus.InProgress,
    isPublishExperienceReportProcessing: (state: ManageExperienceReportsState): boolean =>
      state.publishExperienceReportStatus === ActionStatus.InProgress,
    isUnpublishExperienceReportProcessing: (state: ManageExperienceReportsState): boolean =>
      state.unpublishExperienceReportStatus === ActionStatus.InProgress,
    isUpdateOrderOfExperienceReportsProcessing: (state: ManageExperienceReportsState): boolean =>
      state.updateOrderOfExperienceReportsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getExperienceReports(): Promise<void> {
      const { getExperienceReportsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getExperienceReportsStatus,
        () => getExperienceReports(attachAppAndUser({}))
          .then(async (experienceReports) => {
            this.experienceReports = experienceReports;
          })
      );
    },

    // -- Commands

    createExperienceReport(command: CreateExperienceReportCommand): Promise<void> {
      const { createExperienceReportStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createExperienceReportStatus,
        () => createExperienceReport(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getExperienceReports())
      );
    },

    updateExperienceReport(command: UpdateExperienceReportCommand): Promise<void> {
      const { updateExperienceReportStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateExperienceReportStatus,
        () => updateExperienceReport(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getExperienceReports())
      );
    },

    publishExperienceReport(command: PublishExperienceReportCommand): Promise<void> {
      const { publishExperienceReportStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        publishExperienceReportStatus,
        () => publishExperienceReport(attachAppAndUser(command))
          .then(() => this.getExperienceReports())
      );
    },

    unpublishExperienceReport(command: UnpublishExperienceReportCommand): Promise<void> {
      const { unpublishExperienceReportStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        unpublishExperienceReportStatus,
        () => unpublishExperienceReport(attachAppAndUser(command))
          .then(() => this.getExperienceReports())
      );
    },

    updateOrderOfExperienceReports(command: UpdateOrderOfExperienceReportsAsManagerCommand): Promise<void> {
      const { updateOrderOfExperienceReportsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateOrderOfExperienceReportsStatus,
        () => updateOrderOfExperienceReports(attachAppAndUser(command))
          .then(() => this.getExperienceReports())
      );
    },

  },
});
