import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"select-new-habit"},[(_vm.isLoading)?_c(VProgressLinear,{staticClass:"mb-2",attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"9"}},[(_vm.form)?_c('a-form',{attrs:{"form":_vm.form}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[_c('a-habit-form-control',{attrs:{"form-control":_vm.form.controls.habit,"items":_vm.habitsNotStartedYet}}),(_vm.selectedHabit)?[_c('h3',[_vm._v(_vm._s(_vm.selectedHabit.title))]),_c('p',{staticClass:"introduction mb-2"},[_vm._v(_vm._s(_vm.selectedHabit.introduction))]),_c('p',{staticClass:"min-runtime mb-0"},[_vm._v("Nach ungefähr "+_vm._s(_vm.selectedHabit.minRuntime)+" Wochen kannst du mit Verbesserungen rechnen.")]),_c('create-habit-intent-dialog',{attrs:{"habit-id":_vm.selectedHabit.habitId},on:{"habit-intent-created":_vm.habitIntentCreated}},[_c('a-trigger-primary-process-button',{staticClass:"mt-4"},[_vm._v(" Gewohnheit starten ")])],1)]:_vm._e()],2)],1),_c('a-trigger-tertiary-process-button',{staticClass:"mt-4",attrs:{"is-exact":"","to":{ name: 'manage-habit-intents-habit-intent-list' }}},[_vm._v(" Zurück ")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }