<script lang="ts">
import { watch } from 'vue';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Device } from '@capacitor/device';
import Breadcrumb from '@/application/common/breadcrumb/components/breadcrumb.vue';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { listenToPushNotificationAction, listenToPushNotificationRegistration, removeAllListeners, requestPermissionForPushNotifications } from '@/helpers/push-notifications-helper';
import { ActionStatus } from '@/store';
import IntroducePushNotificationsDialog from '@/application/whitelabel/push-notifications/components/introduce-push-notifications-dialog.vue';
import Snackbar from '@/application/common/snackbar/snackbar.vue';
import LocalStorageHint from '@/application/common/local-storage-hint/local-storage-hint.vue';
import AppVersionHint from '@/application/common/app-version-hint/app-version-hint.vue';
import ImpersonationHint from '@/application/whitelabel/app/components/impersonation-hint/impersonation-hint.vue';
import MaintenanceOverlay from '@/application/whitelabel/maintenance-overlay/components/maintenance-overlay.vue';
import { useUserProgressStore } from '@/private/user/user-progress/store';
import AppHeader from './app-header.vue';
import { appStyle, setupAppStyleAndTitle, setupAppId } from '../app-globals';

@Component({
  components: {
    AppHeader,
    Breadcrumb,
    Snackbar,
    LocalStorageHint,
    IntroducePushNotificationsDialog,
    AppVersionHint,
    ImpersonationHint,
    MaintenanceOverlay,
  },
})
export default class App extends Vue {

  readonly authenticationStore = useAuthenticationStore();
  readonly userProgressStore = useUserProgressStore();

  readonly isNativeApplication = isNativeApplication;

  @Ref()
  readonly introducePushNotificationsDialog!: IntroducePushNotificationsDialog;

  get styleClasses(): string[] {
    const styleClasses = [
      // Only defined in mobile app
      appStyle(),
    ];

    if (isNativeApplication()) {
      styleClasses.push('is-native');
    }

    if (this.$route.name) {
      styleClasses.push(this.$route.name);
    }

    if (this.$route.meta?.styleClass) {
      styleClasses.push(this.$route.meta.styleClass);
    }

    return styleClasses;
  }

  async mounted() {
    // We initialize the styling directly as we don't wait for the authentication in the native app
    if (isNativeApplication()) {
      setupAppStyleAndTitle();
      setupAppId();
    }

    if (!isNativeApplication()) {
      // eslint-disable-next-line no-restricted-globals
      if (this.authenticationStore.appHost !== location.hostname
        && this.authenticationStore.appHost !== null
      ) {
        // eslint-disable-next-line no-restricted-globals
        location.hostname = this.authenticationStore.appHost!;
      }
    }

    document.addEventListener('visibilitychange', this.documentVisibilityChanged);

    watch(() => this.authenticationStore.loginStatus, (loginStatus) => {
      if (loginStatus === ActionStatus.Successful
        && isNativeApplication()
      ) {
        this.checkForPushNotificationsPermission();
      }
    });

    watch(() => this.authenticationStore.fetchAuthenticatedUserStatus, (fetchAuthenticatedUserStatus) => {
      if (fetchAuthenticatedUserStatus === ActionStatus.Successful
        && isNativeApplication()
      ) {
        this.checkForPushNotificationsPermission();
      }

      setupAppStyleAndTitle();
    });
  }

  documentVisibilityChanged(): void {
    if (document.visibilityState === 'hidden') {
      return;
    }

    this.authenticationStore.fetchAuthenticatedUser()
      .then(() => {
        if (this.authenticationStore.isAuthenticated) {
          return this.userProgressStore.getUserProgress();
        }
        return Promise.resolve();
      })
      .catch(() => {});
  }

  async checkForPushNotificationsPermission() {
    removeAllListeners();
    listenToPushNotificationRegistration();
    listenToPushNotificationAction();

    const deviceId = await Device.getId();
    // @ts-ignore - uuid is the fallback for the previous capacitor version.
    const deviceIdentifier = deviceId.identifier ?? deviceId.uuid;
    if (this.authenticationStore.isAuthenticated
      && this.authenticationStore.hasNoPushNotificationConfigurationForDevice(deviceIdentifier)
    ) {
      // The dialog informs the user of the reason behind the push notifications and triggers the request below through the submit button.
      this.introducePushNotificationsDialog.show();
    } else {
      requestPermissionForPushNotifications();
    }
  }

}
</script>
<template>
<div id="app" :class="styleClasses">
  <v-app>

    <app-version-hint />
    <local-storage-hint />

    <!-- Navigation -->
    <app-header />

    <!--  Content  -->
    <v-main>
      <impersonation-hint />
      <breadcrumb />
      <v-container
        v-if="authenticationStore.wasInitialAuthenticationAttempted"
        fluid
        class="main-container"
      >
        <router-view />
      </v-container>
      <v-container
        v-else
        class="py-0"
      >
        <v-progress-linear
          color="grey"
          indeterminate
          class="full-with mt-4"
        />
      </v-container>
    </v-main>

    <snackbar />

    <maintenance-overlay />

    <introduce-push-notifications-dialog ref="introducePushNotificationsDialog" />

  </v-app>
</div>
</template>
<style lang="sass">
.container
  &.impersonated-user,
  &.demo-farm
    padding-bottom: 0

    .v-alert
      margin-bottom: 0

  &.demo-farm
    .v-alert
      background: var(--color-grey-7) !important
      color: var(--color-grey-3) !important
</style>
