import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"blog-article-list"},[_c(VRow,{staticClass:"mt-md-4"},[_c(VCol,{attrs:{"cols":"12"}},[(_vm.isLoading)?_c(VProgressLinear,{staticClass:"mb-4",attrs:{"color":"primary","indeterminate":""}}):_vm._e(),(_vm.areNoBlogArticlesAvailable)?_c('p',[_vm._v(" Noch keine Blog Artikel verfügbar. ")]):_vm._e()],1),_vm._l((_vm.store.blogArticles),function(blogArticle,index){return _c(VCol,{key:blogArticle.blogArticleId,attrs:{"cols":"12","md":"6","lg":"4"}},[_c(VRow,{staticClass:"no-gutters blog-article",class:{ 'mb-4': index !== _vm.store.blogArticles.length - 1 },on:{"click":function($event){return _vm.blogArticleClicked(blogArticle)}}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{staticClass:"overflow-hidden",attrs:{"outlined":""}},[_c('div',{staticClass:"blur-hash-image-container"},[_c('blur-hash-image',{attrs:{"hash":blogArticle.image.hash,"src":blogArticle.image.url,"alt":blogArticle.title,"width":20,"height":Math.floor(20 / blogArticle.image.aspectRatio)}}),(blogArticle.isNew)?_c('span',{staticClass:"new-sign"},[_vm._v(" Neu ")]):_vm._e()],1)])],1),_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"blog-article-info"},[_c('strong',{staticClass:"blog-article-title"},[_vm._v(_vm._s(blogArticle.title))]),_c('span',{staticClass:"d-block action-link"},[_vm._v("Lesen")])])])],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }