<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { Gender } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useDashboardStore } from '../store';
import { CreateCertificateCommand } from '../types';

interface Controls extends FormControls {
  gender: FormControl<Gender>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
}

@Component
export default class CreateCertificateDialog extends Vue {

  readonly store = useDashboardStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        gender: {
          label: 'Anrede',
          value: Gender.MALE,
          isRequired: true,
        },
        firstName: {
          label: 'Vorname',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        lastName: {
          label: 'Nachname',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateCertificateCommand = {
      gender: formValues.gender!,
      firstName: formValues.firstName!,
      lastName: formValues.lastName!,
    };

    this.store.createCertificate(command)
      .then(() => showSuccessMessage('Teilnahmebestätigung wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateCertificateProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Teilnahmebestätigung ausstellen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <a-gender-form-control
              :form-control="form.controls.gender"
              is-autofocused
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <a-text-form-control :form-control="form.controls.firstName" />
          </v-col>
          <v-col cols="12" sm="6">
            <a-text-form-control :form-control="form.controls.lastName" />
          </v-col>
        </v-row>
        <a-info-alert class="mt-4">
          Die eingegebenen Daten werden ausschließlich für die Ausstellung der Teilnahmebestätigung verwendet und nicht gespeichert!
        </a-info-alert>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateCertificateProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateCertificateProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Ausstellen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
