<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { IssueType } from '@/types';
import { useContactSupportStore } from '../store';
import { CreateSupportMessageCommand } from '../types';

interface Controls extends FormControls {
  issueType: FormControl<IssueType>;
  message: FormControl<string>;
}

@Component
export default class CreateSupportMessageDialog extends Vue {

  readonly store = useContactSupportStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        issueType: {
          label: 'Art des Problems',
          value: IssueType.TECHNICAL,
          isRequired: true,
        },
        message: {
          label: 'Nachricht',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateSupportMessageCommand = {
      issueType: formValues.issueType!,
      message: formValues.message!,
    };

    this.store.createSupportMessage(command)
      .then(() => showSuccessMessage('Die Nachricht wurde abgeschickt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateSupportMessageProcessing"
  :fullscreen="$vuetify.breakpoint.smAndDown"
  :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition': null"
  :hide-overlay="$vuetify.breakpoint.smAndDown"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Support kontaktieren</v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="12" lg="6">
            <a-issue-type-form-control
              :form-control="form.controls.issueType"
              is-autofocused
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-textarea-form-control
              :form-control="form.controls.message"
              :rows="10"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateSupportMessageProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateSupportMessageProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Abschicken
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
