import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser } from '@/helpers/default-parameter-helper';
import { Publication } from './types';
import { getPublications } from './service';

interface BibliographyState {
  publications: Publication[];

  getPublicationsStatus: ActionStatus;
}

function initialState(): BibliographyState {
  return {
    publications: [],

    getPublicationsStatus: ActionStatus.None,
  };
}

export const useBibliographyStore = defineStore('bibliography', {
  state: (): BibliographyState => initialState(),
  getters: {
    isGetPublicationsProcessing: (state: BibliographyState): boolean =>
      state.getPublicationsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getPublications(): Promise<void> {
      const { getPublicationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPublicationsStatus,
        () => getPublications(attachAppAndUser({}))
          .then(async (publications) => {
            this.publications = publications;
          })
      );
    },

  },
});
