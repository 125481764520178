<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { SelfReflectionOption } from '@/types';
import CreateSelfReflectionOptionDialog from './create-self-reflection-option-dialog.vue';
import UpdateSelfReflectionOptionDialog from './update-self-reflection-option-dialog.vue';

@Component({
  components: {
    CreateSelfReflectionOptionDialog,
    UpdateSelfReflectionOptionDialog,
  },
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class SelfReflectionOptionsFormControl extends Vue implements FormControlComponent<SelfReflectionOption[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<SelfReflectionOption[]>;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: SelfReflectionOption[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  createItem(item: SelfReflectionOption): void {
    this.internalValue.push(item);

    this.blurred();

    internalValuesChanged(this);
  }

  updateItem(index: number, item: SelfReflectionOption): void {
    this.internalValue.splice(index, 1, item);

    this.blurred();

    internalValuesChanged(this);
  }

  deleteItem(index: number): void {
    this.internalValue.splice(index, 1);

    this.blurred();

    internalValuesChanged(this);
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? []
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<SelfReflectionOption[]> {
    return this.internalValue.length > 0
      ? this.internalValue
      : null;
  }

}
</script>
<template>
<div class="form-control self-reflection-options-form-control" v-bind="$attrs">
  <fieldset class="a-field pt-2 pb-4">
    <legend>{{ labelWithRequiredIndicator(formControl) }}</legend>
    <div class="form-control-input">
      <div class="mt-3">
        <div
          v-for="(item, index) in internalValue"
          :key="index"
        >
          <div class="d-flex">
            <div class="flex-grow-1">
              <strong class="d-block answer">{{ item.answer }}</strong>
              <span v-html="item.reaction" />
            </div>
            <div class="actions flex-shrink-0 pl-2">
              <update-self-reflection-option-dialog
                :self-reflection-option="item"
                @submitted="updateItem(index, $event)"
                @closed="blurred"
              >
                <icon-button
                  :icon="['far', 'pencil']"
                  tooltip="Anpassen"
                  small
                />
              </update-self-reflection-option-dialog>
              <icon-button
                :icon="['far', 'times']"
                @click="deleteItem(index)"
                tooltip="Löschen"
                small
              />
            </div>
          </div>
        </div>
      </div>
      <create-self-reflection-option-dialog
        @submitted="createItem"
        @closed="blurred"
      >
        <a-execute-secondary-action-button
          @click="focused"
          is-small
          class="mt-2"
        >
          Antwort hinzufügen
        </a-execute-secondary-action-button>
      </create-self-reflection-option-dialog>
    </div>
  </fieldset>

    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
</div>
</template>
<style lang="sass" scoped>
strong.answer
  font-weight: 600
</style>
