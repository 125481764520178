<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import Cropper from 'cropperjs';
import { v4 } from 'uuid';
import { DropzoneFile } from 'dropzone';
import { Camera, CameraResultType } from '@capacitor/camera';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import DropzoneUpload from '@/components/dropzone-upload.vue';
import { isNativeApplication } from '@/helpers/detection-helpers';

@Component({
  components: { DropzoneUpload },
  methods: { isNativeApplication, labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class ImageCropperFormControl extends Vue implements FormControlComponent<Blob> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Blob>;

  @Prop({ type: Number, default: 10 })
  readonly maxFileSize!: number;

  @Prop({ type: Number, default: 1.5 })
  readonly imageAspectRatio!: number;

  @Prop({ type: Boolean, default: true })
  readonly fixedAspectRatio!: boolean;

  @Prop({ type: Number, default: 1000 })
  readonly imageWidth!: number;

  readonly formControlId = createFormControlId();

  dropzoneFile: File | null = null;

  cropper: Cropper | null = null;
  imageSrc: string | ArrayBuffer = '';

  imageId = `image-${v4()}`;

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalValue: Blob | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  destroyed(): void {
    this.reset();
  }

  reset(): void {
    this.dropzoneFile = null;
    this.imageSrc = '';
    if (this.cropper) {
      this.cropper.destroy();
    }
  }

  dropzoneFileUploaded(file: DropzoneFile) {
    this.dropzoneFile = file;
    this.importImageToCropper(file);
    this.blurred();
  }

  onTakePictureClicked(): void {
    this.focused();
    this.takePicture();
  }

  takePicture(): void {
    Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      quality: 80,
      allowEditing: false,
      saveToGallery: false,
      correctOrientation: true,
      promptLabelHeader: 'Bild auswählen',
      promptLabelCancel: 'Abbrechen',
      promptLabelPhoto: 'Aus bestehenden Bildern',
      promptLabelPicture: 'Bild aufnehmen',
    })
      .then((image) => {
        if (image.dataUrl) {
          this.urlToFile(image.dataUrl, 'image.jpg', 'image/jpeg')
            .then((file) => {
              this.importImageToCropper(file);
            });
        }
      })
      .catch(() => {
        // The function triggers an error when the user cancels the action which we will just ignore.
        this.blurred();
      });
  }

  importImageToCropper(file: Blob) {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageSrc = e.target!.result!;
      setTimeout(() => {
        this.initCropper();
      }, 200);
    };
    reader.readAsDataURL(file);
  }

  initCropper() {
    // Reset cropper for new image
    if (this.cropper) {
      this.cropper.destroy();
    }

    const image = document.getElementById(this.imageId) as HTMLCanvasElement;
    this.cropper = new Cropper(image, {
      aspectRatio: this.fixedAspectRatio ? this.imageAspectRatio : undefined,
      zoomable: false,
      scalable: true,
      rotatable: true,
      viewMode: 2,
      autoCropArea: 1,
      ready: this.updateFile,
      cropend: this.updateFile,
    });
  }

  updateFile(): void {
    this.cropper!.getCroppedCanvas({
      width: this.imageWidth,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
      fillColor: '#ffffff',
    }).toBlob((blob) => {
      this.internalValue = blob;
      this.blurred();
      internalValuesChanged(this);
    }, 'image/jpeg', 1);
  }

  async urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    // TODO: Add when value exists
    // this.internalValue = this.formControl.value === null
    //   ? ''
    //   : this.formControl.value.trim();
  }

  formValueFromInternalValues(): FormControlValue<Blob> {
    return this.internalValue;
  }

}
</script>
<template>
<div class="form-control image-cropper-form-control" v-bind="$attrs">
  <fieldset class="a-field pt-3">
    <legend>{{ labelWithRequiredIndicator(formControl) }}</legend>
    <div class="form-control-input">

      <div class="image-cropper-upload">
        <template v-if="isNativeApplication()">
          <a-trigger-secondary-process-button @click="onTakePictureClicked">
            Bild auswählen
          </a-trigger-secondary-process-button>
        </template>
        <template v-else>
          <dropzone-upload
            id="attachmentUpload"
            empty-text="Bild auswählen"
            selected-text="Neues Bild zum Hochladen ausgewählt"
            :selected="!!dropzoneFile"
            :max-file-size="10"
            @drag-enter="focused"
            @drag-leave="blurred"
            @upload-file="dropzoneFileUploaded"
          />
        </template>

        <div
          v-if="imageSrc"
          class="image-cropper mt-4 mb-2"
        >
          <img
            v-if="imageSrc"
            :id="imageId"
            :src="imageSrc"
            alt="Bild"
          />
        </div>
      </div>
    </div>
  </fieldset>

    <a-form-control-messages
      :messages="messages"
      :is-focussed="isFocused"
      :is-touched="isTouched"
    />
</div>
</template>
<style lang="sass" scoped>
.image-cropper
  display: block
  width: 100%
  min-height: 150px
  background: var(--color-grey-8)

  @media (min-width: 600px)
    min-height: 300px

  .cropper-bg
    background-repeat: repeat

  .cropper-crop-box
    .cropper-view-box
      outline-color: var(--color-brand)
      outline-width: 2px

    .cropper-line,
    .cropper-point
      background-color: var(--color-brand)

    .cropper-point
      opacity: 1

  img
    display: block
    max-width: 100%
</style>
<style src="../../../node_modules/cropperjs/dist/cropper.css" lang="css" />
