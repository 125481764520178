<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ExecutePrimaryActionButton extends Vue {

  @Prop({ type: Boolean, default: false })
  readonly isSmall!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isProcessing!: boolean;

  get isInternalButtonDisabled(): boolean {
    return this.isDisabled
      || this.isProcessing;
  }

}
</script>
<template>
<v-btn
  type="button"
  color="primary"
  :block="$vuetify.breakpoint.smAndDown"
  v-bind="$attrs"
  v-on="$listeners"
  :disabled="isInternalButtonDisabled"
  :small="isSmall"
  :loading="isProcessing"
  depressed
>
  <slot />
</v-btn>
</template>
<style lang="sass" scoped>
.v-btn
  background: white !important
</style>
