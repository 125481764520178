<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ActionBanner extends Vue {}
</script>
<template>
<v-card
  outlined
  v-bind="$attrs"
>
  <div class="content">
    <slot name="content" />
  </div>
  <div class="actions">
    <slot name="actions" />
  </div>
</v-card>
</template>
<style lang="sass" scoped>
.v-card
  display: flex
  justify-content: space-between

  .content
    font-size: 0.875rem
    padding: 0.75rem

  .actions
    padding: 0.5rem 1rem
    display: flex
    justify-content: center
    align-items: center
</style>
