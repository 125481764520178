<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DataProtection extends Vue {

}
</script>
<template>
<v-container class="data-protection">
  <v-row>
    <v-col md="12" lg="9">

      <h2 id="m14" class="mt-0">Einleitung</h2>
      <p>Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als "Onlineangebot“).</p>
      <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
      <img
        class="mt-0 mb-0"
        src="@/assets/images/dsg-seal-pp-de.png"
        alt="Rechtstext von Dr. Schwenke." width="250" height="250">
      <h2>Inhaltsübersicht</h2>
      <ul class="index">
        <li><a class="index-link" href="#m14">Einleitung</a></li>
        <li><a class="index-link" href="#m3">Verantwortlicher</a></li>
        <li><a class="index-link" href="#mOverview">Übersicht der Verarbeitungen</a></li>
        <li><a class="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a></li>
        <li><a class="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
        <li><a class="index-link" href="#m25">Übermittlung und Offenbarung von personenbezogenen Daten</a></li>
        <li><a class="index-link" href="#m24">Datenverarbeitung in Drittländern</a></li>
        <li><a class="index-link" href="#m134">Einsatz von Cookies</a></li>
        <li><a class="index-link" href="#m317">Kommerzielle und geschäftliche Leistungen</a></li>
        <li><a class="index-link" href="#m326">Zahlungsdienstleister</a></li>
        <li><a class="index-link" href="#m225">Bereitstellung des Onlineangebotes und Webhosting</a></li>
        <li><a class="index-link" href="#m469">Besondere Hinweise zu Applikationen (Apps)</a></li>
        <li><a class="index-link" href="#m1466">Bezug von Applikationen über Appstores</a></li>
        <li><a class="index-link" href="#m367">Registrierung, Anmeldung und Nutzerkonto</a></li>
        <li><a class="index-link" href="#m182">Kontaktaufnahme</a></li>
        <li><a class="index-link" href="#m391">Kommunikation via Messenger</a></li>
        <li><a class="index-link" href="#m406">Chatbots und Chatfunktionen</a></li>
        <li><a class="index-link" href="#m1643">Push-Nachrichten</a></li>
        <li><a class="index-link"
               href="#m735">Videokonferenzen, Onlinemeetings, Webinare und Bildschirm-Sharing</a></li>
        <li><a class="index-link" href="#m29">Cloud-Dienste</a></li>
        <li><a class="index-link" href="#m17">Newsletter und elektronische Benachrichtigungen</a></li>
        <li><a class="index-link" href="#m263">Webanalyse, Monitoring und Optimierung</a></li>
        <li><a class="index-link" href="#m328">Plugins und eingebettete Funktionen sowie Inhalte</a></li>
        <li><a class="index-link" href="#m723">Planung, Organisation und Hilfswerkzeuge</a></li>
        <li><a class="index-link" href="#m12">Löschung von Daten</a></li>
        <li><a class="index-link" href="#m15">Änderung und Aktualisierung der Datenschutzerklärung</a></li>
        <li><a class="index-link" href="#m10">Rechte der betroffenen Personen</a></li>
        <li><a class="index-link" href="#m42">Begriffsdefinitionen</a></li>
      </ul>
      <h2 id="m3">Verantwortlicher</h2>
      <p>Christian Kolb<br>Vitomy UG (haftungsbeschränkt)<br>Karlstraße 110<br>80335 München</p>
      <p><strong>Vertretungsberechtigte Personen:</strong> Caroline Lutz</p>
      <p><strong>E-Mail-Adresse:</strong> <a href="mailto:christian.kolb@vitomy.de">christian.kolb@vitomy.de</a>
      </p>
      <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
      <p>Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.</p>
      <h3>Arten der verarbeiteten Daten</h3>
      <ul>
        <li>Bestandsdaten (z.B. Namen, Adressen).</li>
        <li>Inhaltsdaten (z.B. Eingaben in Onlineformularen).</li>
        <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
        <li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
        <li>Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</li>
        <li>Bild- und/ oder Videoaufnahmen (z. B. Fotografien oder Videoaufnahmen einer Person).</li>
        <li>Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).</li>
        <li>Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie).</li>
      </ul>
      <h3>Kategorien betroffener Personen</h3>
      <ul>
        <li>Beschäftigte (z.B. Angestellte, Bewerber, ehemalige Mitarbeiter).</li>
        <li>Geschäfts- und Vertragspartner.</li>
        <li>Interessenten.</li>
        <li>Kommunikationspartner.</li>
        <li>Kunden.</li>
        <li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
      </ul>
      <h3>Zwecke der Verarbeitung</h3>
      <ul>
        <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
        <li>Konversionsmessung (Messung der Effektivität von Marketingmaßnahmen).</li>
        <li>Büro- und Organisationsverfahren.</li>
        <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
        <li>Feedback (z.B. Sammeln von Feedback via Online-Formular).</li>
        <li>Kontaktanfragen und Kommunikation.</li>
        <li>Profiling (Erstellen von Nutzerprofilen).</li>
        <li>Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher).</li>
        <li>Sicherheitsmaßnahmen.</li>
        <li>Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies).</li>
        <li>Umfragen und Fragebögen (z.B. Umfragen mit Eingabemöglichkeiten, Multiple-Choice-Fragen).</li>
        <li>Erbringung vertragliche Leistungen und Kundenservice.</li>
        <li>Verwaltung und Beantwortung von Anfragen.</li>
      </ul>
      <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
      <p>Im Folgenden teilen wir die Rechtsgrundlagen der Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass zusätzlich zu den Regelungen der DSGVO die nationalen Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.</p>
      <ul>
        <li>
          <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> - Die betroffene Person hat ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
        </li>
        <li>
          <strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO)</strong> - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen.
        </li>
        <li>
          <strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)</strong> - Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche unterliegt.
        </li>
        <li>
          <strong>Wahrnehmung öffentlicher Interessen (Art. 6 Abs. 1 S. 1 lit. e. DSGVO)</strong> - Die Verarbeitung ist für die Wahrnehmung einer Aufgabe erforderlich, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
        </li>
        <li>
          <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
        </li>
      </ul>
      <p>
        <strong>Nationale Datenschutzregelungen in Deutschland</strong>: Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.
      </p>
      <h2 id="m27">Sicherheitsmaßnahmen</h2>
      <p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.</p>
      <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>
      <p>
        <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser Online-Angebot übermittelten Daten zu schützen, nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.
      </p>
      <h2 id="m25">Übermittlung und Offenbarung von personenbezogenen Daten</h2>
      <p>Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten können z.B. Zahlungsinstitute im Rahmen von Zahlungsvorgängen, mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen Fall beachten wir die gesetzlichen Vorgaben und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.</p>
      <h2 id="m24">Datenverarbeitung in Drittländern</h2>
      <p>Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben. </p>
      <p>Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, beim Vorliegen von Zertifizierungen oder verbindlicher internen Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite der EU-Kommission:
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
          target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a> ).
      </p>
      <h2 id="m134">Einsatz von Cookies</h2>
      <p>Cookies sind Textdateien, die Daten von besuchten Websites oder Domains enthalten und von einem Browser auf dem Computer des Benutzers gespeichert werden. Ein Cookie dient in erster Linie dazu, die Informationen über einen Benutzer während oder nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Zu den gespeicherten Angaben können z.B. die Spracheinstellungen auf einer Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut wurde, gehören. Zu dem Begriff der Cookies zählen wir ferner andere Technologien, die die gleichen Funktionen wie Cookies erfüllen (z.B., wenn Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen gespeichert werden, auch als "Nutzer-IDs" bezeichnet)</p>
      <p><strong>Die folgenden Cookie-Typen und Funktionen werden unterschieden:</strong></p>
      <ul>
        <li>
          <strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und seinen Browser geschlossen hat.
        </li>
        <li>
          <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des Browsers gespeichert. So kann beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die Interessen von Nutzern, die zur Reichweitenmessung oder zu Marketingzwecken verwendet werden, in einem solchen Cookie gespeichert werden.
        </li>
        <li><strong>First-Party-Cookies:</strong> First-Party-Cookies werden von uns selbst gesetzt.</li>
        <li>
          <strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>: Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden (sog. Dritten) verwendet, um Benutzerinformationen zu verarbeiten.
        </li>
        <li>
          <strong>Notwendige (auch: essentielle oder unbedingt erforderliche) Cookies:</strong> Cookies können zum einen für den Betrieb einer Webseite unbedingt erforderlich sein (z.B. um Logins oder andere Nutzereingaben zu speichern oder aus Gründen der Sicherheit).
        </li>
        <li>
          <strong>Statistik-, Marketing- und Personalisierungs-Cookies</strong>: Ferner werden Cookies im Regelfall auch im Rahmen der Reichweitenmessung eingesetzt sowie dann, wenn die Interessen eines Nutzers oder sein Verhalten (z.B. Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf einzelnen Webseiten in einem Nutzerprofil gespeichert werden. Solche Profile dienen dazu, den Nutzern z.B. Inhalte anzuzeigen, die ihren potentiellen Interessen entsprechen. Dieses Verfahren wird auch als "Tracking", d.h., Nachverfolgung der potentiellen Interessen der Nutzer bezeichnet. Soweit wir Cookies oder "Tracking"-Technologien einsetzen, informieren wir Sie gesondert in unserer Datenschutzerklärung oder im Rahmen der Einholung einer Einwilligung.
        </li>
      </ul>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen: </strong> Auf welcher Rechtsgrundlage wir Ihre personenbezogenen Daten mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Sie um eine Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung von Cookies einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen.
      </p>
      <p>
        <strong>Speicherdauer: </strong>Sofern wir Ihnen keine expliziten Angaben zur Speicherdauer von permanenten Cookies mitteilen (z. B. im Rahmen eines sog. Cookie-Opt-Ins), gehen Sie bitte davon aus, dass die Speicherdauer bis zu zwei Jahre betragen kann.
      </p>
      <p>
        <strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong> Abhängig davon, ob die Verarbeitung auf Grundlage einer Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die Möglichkeit, eine erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer Daten durch Cookie-Technologien zu widersprechen (zusammenfassend als "Opt-Out" bezeichnet). Sie können Ihren Widerspruch zunächst mittels der Einstellungen Ihres Browsers erklären, z.B., indem Sie die Nutzung von Cookies deaktivieren (wobei hierdurch auch die Funktionsfähigkeit unseres Onlineangebotes eingeschränkt werden kann). Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken des Onlinemarketings kann auch mittels einer Vielzahl von Diensten, vor allem im Fall des Trackings, über die Webseiten
        <a href="https://optout.aboutads.info" target="_blank">https://optout.aboutads.info</a> und <a
        href="https://www.youronlinechoices.com/"
        target="_blank">https://www.youronlinechoices.com/</a> erklärt werden. Daneben können Sie weitere Widerspruchshinweise im Rahmen der Angaben zu den eingesetzten Dienstleistern und Cookies erhalten.
      </p>
      <p>
        <strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung</strong>: Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies, bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen und Anbieter eingeholt sowie von den Nutzern verwaltet und widerrufen werden können. Hierbei wird die Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut wiederholen zum müssen und die Einwilligung entsprechend der gesetzlichen Verpflichtung nachweisen zu können. Die Speicherung kann serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien) erfolgen, um die Einwilligung einem Nutzer, bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller Angaben zu den Anbietern von Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu zwei Jahren betragen. Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit dem Zeitpunkt der Einwilligung, Angaben zur Reichweite der Einwilligung (z. B. welche Kategorien von Cookies und/oder Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät gespeichert.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>Klaro!:</strong> Cookie-Einwilligungs-Management; Dienstanbieter: KIProtect GmbH, Bismarckstr. 10-12, 10625 Berlin, Deutschland; Website:
          <a href="https://kiprotect.com/klaro"
             target="_blank">https://kiprotect.com/klaro</a>; Datenschutzerklärung: <a
          href="https://kiprotect.com/de/ressourcen/datenschutz"
          target="_blank">https://kiprotect.com/de/ressourcen/datenschutz</a>.
        </li>
      </ul>
      <h2 id="m317">Kommerzielle und geschäftliche Leistungen</h2>
      <p>Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden und Interessenten (zusammenfassend bezeichnet als "Vertragspartner") im Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Rahmen der Kommunikation mit den Vertragspartnern (oder vorvertraglich), z.B., um Anfragen zu beantworten.</p>
      <p>Diese Daten verarbeiten wir zur Erfüllung unserer vertraglichen Pflichten, zur Sicherung unserer Rechte und zu Zwecken der mit diesen Angaben einhergehenden Verwaltungsaufgaben sowie der unternehmerischen Organisation. Die Daten der Vertragspartner geben wir im Rahmen des geltenden Rechts nur insoweit an Dritte weiter, als dies zu den vorgenannten Zwecken oder zur Erfüllung gesetzlicher Pflichten erforderlich ist oder mit Einwilligung der betroffenen Personen erfolgt (z.B. an beteiligte Telekommunikations-, Transport- und sonstige Hilfsdienste sowie Subunternehmer, Banken, Steuer- und Rechtsberater, Zahlungsdienstleister oder Steuerbehörden). Über weitere Verarbeitungsformen, z.B. zu Zwecken des Marketings, werden die Vertragspartner im Rahmen dieser Datenschutzerklärung informiert.</p>
      <p>Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole (z.B. Sternchen o.ä.), oder persönlich mit.</p>
      <p>Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4 Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert werden, z.B., solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden müssen (z.B. für Steuerzwecke im Regelfall 10 Jahre). Daten, die uns im Rahmen eines Auftrags durch den Vertragspartner offengelegt wurden, löschen wir entsprechend den Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.</p>
      <p>Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen, gelten im Verhältnis zwischen den Nutzern und den Anbietern die Geschäftsbedingungen und Datenschutzhinweise der jeweiligen Drittanbieter oder Plattformen.</p>
      <p>
        <strong>Kundenkonto</strong>: Vertragspartner können innerhalb unseres Onlineangebotes ein Konto anlegen (z.B. Kunden- bzw. Nutzerkonto, kurz "Kundenkonto"). Falls die Registrierung eines Kundenkontos erforderlich ist, werden Vertragspartner hierauf ebenso hingewiesen wie auf die für die Registrierung erforderlichen Angaben. Die Kundenkonten sind nicht öffentlich und können von Suchmaschinen nicht indexiert werden. Im Rahmen der Registrierung sowie anschließender Anmeldungen und Nutzungen des Kundenkontos speichern wir die IP-Adressen der Kunden nebst den Zugriffszeitpunkten, um die Registrierung nachweisen und etwaigem Missbrauch des Kundenkontos vorbeugen zu können.
      </p>
      <p>Wenn Kunden ihr Kundenkonto gekündigt haben, werden die das Kundenkonto betreffenden Daten gelöscht, vorbehaltlich, deren Aufbewahrung ist aus gesetzlichen Gründen erforderlich. Es obliegt den Kunden, ihre Daten bei erfolgter Kündigung des Kundenkontos zu sichern.</p>
      <p>
        <strong>Shop und E-Commerce</strong>: Wir verarbeiten die Daten unserer Kunden, um ihnen die Auswahl, den Erwerb, bzw. die Bestellung der gewählten Produkte, Waren sowie verbundener Leistungen, als auch deren Bezahlung und Zustellung, bzw. Ausführung zu ermöglichen. Sofern für die Ausführung einer Bestellung erforderlich, setzen wir Dienstleister, insbesondere Post-, Speditions- und Versandunternehmen ein, um die Lieferung, bzw. Ausführung gegenüber unseren Kunden durchzuführen. Für die Abwicklung der Zahlungsvorgänge nehmen wir die Dienste von Banken und Zahlungsdienstleistern in Anspruch. Die erforderlichen Angaben sind als solche im Rahmen des Bestell- bzw. vergleichbaren Erwerbsvorgangs gekennzeichnet und umfassen die zur Auslieferung, bzw. Zurverfügungstellung und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprache halten zu können.
      </p>
      <p>
        <strong>Angebot von Software- und Plattformleistungen</strong>: Wir verarbeiten die Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber unsere vertraglichen Leistungen erbringen zu können sowie auf Grundlage berechtigter Interessen, um die Sicherheit unseres Angebotes gewährleisten und es weiterentwickeln zu können. Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie), Kontaktdaten (z.B. E-Mail, Telefonnummern), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li><strong>Betroffene Personen:</strong> Interessenten, Geschäfts- und Vertragspartner, Kunden.</li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen und Kundenservice, Kontaktanfragen und Kommunikation, Büro- und Organisationsverfahren, Verwaltung und Beantwortung von Anfragen, Sicherheitsmaßnahmen.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <h2 id="m326">Zahlungsdienstleister</h2>
      <p>Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund gesetzlicher Pflichten oder sonst auf Grundlage unserer berechtigten Interessen bieten wir den betroffenen Personen effiziente und sichere Zahlungsmöglichkeiten an und setzen hierzu neben Banken und Kreditinstituten weitere Zahlungsdienstleister ein (zusammenfassend "Zahlungsdienstleister").</p>
      <p>Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie z.B. Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie die Vertrags-, Summen- und empfängerbezogenen Angaben. Die Angaben sind erforderlich, um die Transaktionen durchzuführen. Die eingegebenen Daten werden jedoch nur durch die Zahlungsdienstleister verarbeitet und bei diesen gespeichert. D.h., wir erhalten keine konto- oder kreditkartenbezogenen Informationen, sondern lediglich Informationen mit Bestätigung oder Negativbeauskunftung der Zahlung. Unter Umständen werden die Daten seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf die AGB und die Datenschutzhinweise der Zahlungsdienstleister.</p>
      <p>Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche innerhalb der jeweiligen Webseiten bzw. Transaktionsapplikationen abrufbar sind. Wir verweisen auf diese ebenfalls zwecks weiterer Informationen und Geltendmachung von Widerrufs-, Auskunfts- und anderen Betroffenenrechten.</p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li><strong>Betroffene Personen:</strong> Kunden, Interessenten.</li>
        <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen und Kundenservice.</li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>Stripe:</strong> Zahlungsdienstleistungen; Dienstanbieter: Stripe, Inc., 510 Townsend Street, San Francisco, CA 94103, USA; Website:
          <a href="https://stripe.com/de" target="_blank">https://stripe.com/de</a>; Datenschutzerklärung: <a
          href="https://stripe.com/de/privacy" target="_blank">https://stripe.com/de/privacy</a>.
        </li>
      </ul>
      <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
      <p>Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die Leistungen von einem oder mehreren Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern) das Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und technische Wartungsleistungen in Anspruch nehmen.</p>
      <p>Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die Nutzer unseres Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und der Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres Onlineangebotes oder von Webseiten getätigten Eingaben.</p>
      <p>
        <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst (bzw. unser Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören.
      </p>
      <p>Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen.</p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten), Kunden.
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen und Kundenservice, Sicherheitsmaßnahmen.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>Heroku:</strong> Webhosting und infrastrukturelle Dienstleistungen; Dienstanbieter: Salesforce.com, Inc., The Landmark @ One Market, Suite 300, San Francisco, California 94105, USA; Website:
          <a href="https://www.heroku.com"
             target="_blank">https://www.heroku.com</a>; Datenschutzerklärung: <a
          href="https://www.salesforce.com/company/privacy"
          target="_blank">https://www.salesforce.com/company/privacy</a>.
        </li>
        <li>
          <strong>Amazon Web Services (AWS):</strong> Webhosting und infrastrukturelle Dienstleistungen; Dienstanbieter: Amazon Web Services, Inc., 410 Terry Avenue North, Seattle WA 98109, USA; Website:
          <a href="https://aws.amazon.com/de"
             target="_blank">https://aws.amazon.com/de</a>; Datenschutzerklärung: <a
          href="https://aws.amazon.com/de/privacy/?nc1=f_pr"
          target="_blank">https://aws.amazon.com/de/privacy/?nc1=f_pr</a>.
        </li>
        <li>
          <strong>Sentry:</strong> Überwachung der Systemstabilität und Ermittlung von Codefehlern - Angaben zum Gerät oder Fehlerzeitpunkt werden pseudonym erhoben und werden anschließend gelöscht; Dienstanbieter: Functional Software Inc., Sentry, 132 Hawthorne Street, San Francisco, California 94107, USA; Website:
          <a href="https://sentry.io"
             target="_blank">https://sentry.io</a>; Sicherheitsmaßnahmen: IP-Masking (Pseudonymisierung der IP-Adresse); Datenschutzerklärung:
          <a href="https://sentry.io/privacy" target="_blank">https://sentry.io/privacy</a>.
        </li>
      </ul>
      <h2 id="m469">Besondere Hinweise zu Applikationen (Apps)</h2>
      <p>Wir verarbeiten die Daten der Nutzer unserer Applikation, soweit diese erforderlich sind, um den Nutzern die Applikation sowie deren Funktionalitäten bereitstellen, deren Sicherheit überwachen und sie weiterentwickeln zu können. Wir können ferner Nutzer unter Beachtung der gesetzlichen Vorgaben kontaktieren, sofern die Kommunikation zu Zwecken der Administration oder Nutzung der Applikation erforderlich ist. Im Übrigen verweisen wir im Hinblick auf die Verarbeitung der Daten der Nutzer auf die Datenschutzhinweise in dieser Datenschutzerklärung.</p>
      <p>
        <strong>Rechtsgrundlagen: </strong>Die Verarbeitung von Daten, die für die Bereitstellung der Funktionalitäten der Applikation erforderlich ist, dient der Erfüllung von vertraglichen Pflichten. Dies gilt auch, wenn die Bereitstellung der Funktionen eine Berechtigung der Nutzer (z. B. Freigaben von Gerätefunktionen) voraussetzt. Sofern die Verarbeitung von Daten für die Bereitstellung der Funktionalitäten der Applikation nicht erforderlich ist, aber der Sicherheit der Applikation oder unseren betriebswirtschaftlichen Interessen dient (z. B. Erhebung von Daten zu Zwecken der Optimierung der Applikation oder Sicherheitszwecken), erfolgt sie auf Grundlage unserer berechtigten Interessen. Sofern Nutzer ausdrücklich deren Einwilligung in die Verarbeitung ihrer Daten gebeten werden, erfolgt die Verarbeitung der von der Einwilligung umfassten Daten auf Grundlage der Einwilligung.
      </p>
      <p>
        <strong>Kommerzielle Nutzung</strong>: Wir verarbeiten die Daten der Nutzer unserer Applikation, angemeldeter und etwaiger Testnutzer (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber unsere vertraglichen Leistungen erbringen zu können sowie auf Grundlage berechtigter Interessen, um die Sicherheit unserer Applikation gewährleisten und sie weiterzuentwickeln zu können. Die erforderlichen Angaben sind als solche im Rahmen des Nutzungs-, Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und können die zur Leistungserbringung und zur etwaigen Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können, umfassen.
      </p>
      <p>
        <strong>Geräteberechtigungen für den Zugriff auf Funktionen und Daten</strong>: Die Nutzung unserer Applikation oder ihrer Funktionalitäten kann Berechtigungen der Nutzer für Zugriff auf bestimmten Funktionen der verwendeten Geräte oder auf die, auf den Geräten gespeicherten oder mit Hilfe der Geräte zugänglichen Daten voraussetzen. Standardmäßig müssen diese Berechtigungen von den Nutzern erteilt werden und können jederzeit in den Einstellungen der jeweiligen Geräte widerrufen werden. Das genaue Verfahren zur Kontrolle der App-Berechtigungen kann vom Gerät und der Software der Nutzern abhängig sein. Bei Erläuterungsbedarf können sich Nutzer an uns wenden. Wir weisen darauf hin, dass die Versagung oder Widerruf der jeweiligen Berechtigungen die Funktionsfähigkeit unsere Applikation beeinflussen kann.
      </p>
      <p>
        <strong>Zugriff auf die Kamera sowie gespeicherten Aufnahmen</strong>: Im Rahmen der Nutzung unserer Applikation werden Bild- und/ oder Videoaufnahmen (wobei auch Audioaufnahmen umfasst sind) der Nutzer (und von anderen, von den Aufnahmen erfassten Personen) durch den Zugriff auf die Kamerafunktionen oder auf gespeicherte Aufnahmen verarbeitet. Der Zugriff auf die Kamerafunktionen oder gespeicherte Aufnahmen setzt eine jederzeit widerrufbare Berechtigung durch die Nutzer voraus. Die Verarbeitung der Bild- und/ oder Videoaufnahmen dient jeweils nur der Bereitstellung der jeweiligen Funktionalität unserer Applikation, entsprechend ihrer Beschreibung gegenüber den Nutzern, bzw. ihrer typischen und erwartbaren Funktionsweise.
      </p>
      <p><strong>Hinweise zu Funktionen der Applikation:</strong></p>
      <p>- Kamerafunktion: Erstellen von Bildaufnahmen als Erinnerung an die Durchführung von Gewohnheiten sowie die Dokumentation im Ernährungstagebuch, die im Profil der Nutzer gespeichert werden.</p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie), Bild- und/ oder Videoaufnahmen (z. B. Fotografien oder Videoaufnahmen einer Person).
        </li>
        <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
        <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen und Kundenservice.</li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <h2 id="m1466">Bezug von Applikationen über Appstores</h2>
      <p>Der Bezug unserer Applikation erfolgt über über spezielle Online-Plattformen, die von anderen Dienstanbietern betrieben werden, an (so genannte "Appstores"). In diesem Zusammenhang gelten zusätzlich zu unseren Datenschutzhinweisen die Datenschutzhinweise der jeweiligen Appstores. Dies gilt insbesondere im Hinblick auf die auf den Plattformen eingesetzten Verfahren zur Reichweitemessung und zum interessensbezogenen Marketing sowie etwaige Kostenpflicht.</p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie), Kontaktdaten (z.B. E-Mail, Telefonnummern), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li><strong>Betroffene Personen:</strong> Kunden.</li>
        <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen und Kundenservice.</li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>Apple App Store:</strong> App und Software Verkaufsplattform; Dienstanbieter: Apple Inc., Infinite Loop, Cupertino, CA 95014, USA; Website:
          <a href="https://www.apple.com/de/ios/app-store/"
             target="_blank">https://www.apple.com/de/ios/app-store/</a>; Datenschutzerklärung: <a
          href="https://www.apple.com/legal/privacy/de-ww/"
          target="_blank">https://www.apple.com/legal/privacy/de-ww/</a>.
        </li>
        <li>
          <strong>Google Play:</strong> App- und Software-Verkaufsplattform; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
          <a href="https://play.google.com/store/apps?hl=de"
             target="_blank">https://play.google.com/store/apps?hl=de</a>; Datenschutzerklärung: <a
          href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.
        </li>
      </ul>
      <h2 id="m367">Registrierung, Anmeldung und Nutzerkonto</h2>
      <p>Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu Zwecken der Bereitstellung des Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung verarbeitet. Zu den verarbeiteten Daten gehören insbesondere die Login-Informationen (Name, Passwort sowie eine E-Mail-Adresse). Die im Rahmen der Registrierung eingegebenen Daten werden für die Zwecke der Nutzung des Nutzerkontos und dessen Zwecks verwendet. </p>
      <p>Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind, wie z.B. technische Änderungen, per E-Mail informiert werden. Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf das Nutzerkonto, vorbehaltlich einer gesetzlichen Aufbewahrungspflicht, gelöscht. Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu sichern. Wir sind berechtigt, sämtliche während der Vertragsdauer gespeicherte Daten des Nutzers unwiederbringlich zu löschen.</p>
      <p>Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei denn, sie ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung hierzu.</p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Eingaben in Onlineformularen), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen und Kundenservice, Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <h2 id="m182">Kontaktaufnahme</h2>
      <p>Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale Medien) werden die Angaben der anfragenden Personen verarbeitet, soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.</p>
      <p>Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an der Beantwortung der Anfragen.</p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Eingaben in Onlineformularen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kommunikationspartner, Kunden, Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation, Verwaltung und Beantwortung von Anfragen, Feedback (z.B. Sammeln von Feedback via Online-Formular), Umfragen und Fragebögen (z.B. Umfragen mit Eingabemöglichkeiten, Multiple-Choice-Fragen).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO), Wahrnehmung öffentlicher Interessen (Art. 6 Abs. 1 S. 1 lit. e. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>HubSpot:</strong> Kundenpflege und -servicesoftware (Verwaltung von Kundenanfragen aus verschiedenen Kanälen), Ticketingsystem, Feedback, Zufriedenheits- und andere Umfragen; Dienstanbieter: HubSpot, Inc., 25 First St., 2nd floor, Cambridge, Massachusetts 02141, USA; Website:
          <a href="https://www.hubspot.de" target="_blank">https://www.hubspot.de</a>; Datenschutzerklärung: <a
          href="https://legal.hubspot.com/de/privacy-policy"
          target="_blank">https://legal.hubspot.com/de/privacy-policy</a>.
        </li>
      </ul>
      <h2 id="m391">Kommunikation via Messenger</h2>
      <p>Wir setzen zu Zwecken der Kommunikation Messenger ein und bitten daher darum, die nachfolgenden Hinweise zur Funktionsfähigkeit der Messenger, zur Verschlüsselung, zur Nutzung der Metadaten der Kommunikation und zu Ihren Widerspruchsmöglichkeiten zu beachten.</p>
      <p>Sie können uns auch auf alternativen Wegen, z.B. via Telefon oder E-Mail, kontaktieren. Bitte nutzen Sie die Ihnen mitgeteilten Kontaktmöglichkeiten oder die innerhalb unseres Onlineangebotes angegebenen Kontaktmöglichkeiten.</p>
      <p>Im Fall einer Ende-zu-Ende-Verschlüsselung von Inhalten (d.h., der Inhalt Ihrer Nachricht und Anhänge) weisen wir darauf hin, dass die Kommunikationsinhalte (d.h., der Inhalt der Nachricht und angehängte Bilder) von Ende zu Ende verschlüsselt werden. Das bedeutet, dass der Inhalt der Nachrichten nicht einsehbar ist, nicht einmal durch die Messenger-Anbieter selbst. Sie sollten immer eine aktuelle Version der Messenger mit aktivierter Verschlüsselung nutzen, damit die Verschlüsselung der Nachrichteninhalte sichergestellt ist. </p>
      <p>Wir weisen unsere Kommunikationspartner jedoch zusätzlich darauf hin, dass die Anbieter der Messenger zwar nicht den Inhalt einsehen, aber in Erfahrung bringen können, dass und wann Kommunikationspartner mit uns kommunizieren sowie technische Informationen zum verwendeten Gerät der Kommunikationspartner und je nach Einstellungen ihres Gerätes auch Standortinformationen (sogenannte Metadaten) verarbeitet werden.</p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen: </strong> Sofern wir Kommunikationspartner vor der Kommunikation mit ihnen via Messenger um eine Erlaubnis bitten, ist die Rechtsgrundlage unserer Verarbeitung ihrer Daten deren Einwilligung. Im Übrigen, falls wir nicht um eine Einwilligung bitten und sie z.B. von sich aus Kontakt mit uns aufnehmen, nutzen wir Messenger im Verhältnis zu unseren Vertragspartnern sowie im Rahmen der Vertragsanbahnung als eine vertragliche Maßnahme und im Fall anderer Interessenten und Kommunikationspartner auf Grundlage unserer berechtigten Interessen an einer schnellen und effizienten Kommunikation und Erfüllung der Bedürfnisse unser Kommunikationspartner an der Kommunikation via Messenger. Ferner weisen wir Sie darauf hin, dass wir die uns mitgeteilten Kontaktdaten ohne Ihre Einwilligung nicht erstmalig an die Messenger übermitteln.
      </p>
      <p>
        <strong>Widerruf, Widerspruch und Löschung:</strong> Sie können jederzeit eine erteilte Einwilligung widerrufen und der Kommunikation mit uns via Messenger jederzeit widersprechen. Im Fall der Kommunikation via Messenger löschen wir die Nachrichten entsprechend unseren generellen Löschrichtlinien (d.h. z.B., wie oben beschrieben, nach Ende vertraglicher Beziehungen, im Kontext von Archivierungsvorgaben etc.) und sonst, sobald wir davon ausgehen können, etwaige Auskünfte der Kommunikationspartner beantwortet zu haben, wenn kein Rückbezug auf eine vorhergehende Konversation zu erwarten ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
      </p>
      <p>
        <strong>Vorbehalt des Verweises auf andere Kommunikationswege:</strong> Zum Abschluss möchten wir darauf hinweisen, dass wir uns aus Gründen Ihrer Sicherheit vorbehalten, Anfragen über Messenger nicht zu beantworten. Das ist der Fall, wenn z.B. Vertragsinterna besonderer Geheimhaltung bedürfen oder eine Antwort über Messenger den formellen Ansprüchen nicht genügt. In solchen Fällen verweisen wir Sie auf adäquatere Kommunikationswege.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen), Inhaltsdaten (z.B. Eingaben in Onlineformularen).
        </li>
        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation, Direktmarketing (z.B. per E-Mail oder postalisch).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>Signal:</strong> Signal Messenger mit Ende-zu-Ende-Verschlüsselung; Dienstanbieter: Privacy Signal Messenger, LLC 650 Castro Street, Suite 120-223 Mountain View, CA 94041, USA; Website:
          <a href="https://signal.org/de" target="_blank">https://signal.org/de</a>; Datenschutzerklärung: <a
          href="https://signal.org/legal/" target="_blank">https://signal.org/legal/</a>.
        </li>
        <li>
          <strong>Slack:</strong> Slack Messenger ohne Ende-zu-Ende-Verschlüsselung; Dienstanbieter: Slack Technologies, Inc., 500 Howard Street, San Francisco, CA 94105, USA; Website:
          <a href="https://slack.com/intl/de-de/"
             target="_blank">https://slack.com/intl/de-de/</a>; Datenschutzerklärung: <a
          href="https://slack.com/intl/de-de/legal" target="_blank">https://slack.com/intl/de-de/legal</a>.
        </li>
      </ul>
      <h2 id="m406">Chatbots und Chatfunktionen</h2>
      <p>Wir bieten als Kommunikationsmöglichkeit Online-Chats und Chatbot-Funktionen an (zusammen als "Chat-Dienste" bezeichnet). Bei einem Chat handelt es sich um eine mit gewisser Zeitnähe geführte Online-Unterhaltung. Bei einem Chatbot handelt es sich um eine Software, die Fragen der Nutzer beantwortet oder sie über Nachrichten informiert. Wenn Sie unsere Chat-Funktionen nutzen, können wir Ihre personenbezogenen Daten verarbeiten.</p>
      <p>Falls Sie unsere Chat-Dienste innerhalb einer Online-Plattform nutzen, wird zusätzlich Ihre Identifikationsnummer innerhalb der jeweiligen Plattform gespeichert. Wir können zudem Informationen darüber erheben, welche Nutzer wann mit unseren Chat-Diensten interagieren. Ferner speichern wir den Inhalt Ihrer Konversationen über die Chat-Dienste und protokollieren Registrierungs- und Einwilligungsvorgänge, um diese nach gesetzlichen Vorgaben nachweisen zu können. </p>
      <p>Wir weisen Nutzer darauf hin, dass der jeweilige Plattformanbieter in Erfahrung bringen kann, dass und wann Nutzer mit unseren Chat-Diensten kommunizieren sowie technische Informationen zum verwendeten Gerät der Nutzer und je nach Einstellungen ihres Gerätes auch Standortinformationen (sogenannte Metadaten) zu Zwecken der Optimierung der jeweiligen Dienste und Zwecken der Sicherheit erheben kann. Ebenfalls könnten die Metadaten der Kommunikation via Chat-Diensten (d.h. z.B., die Information, wer mit wem kommuniziert hat) durch die jeweiligen Plattformanbieter nach Maßgabe ihrer Bestimmungen, auf die wir zwecks weiterer Information verweisen, für Zwecke des Marketings oder zur Anzeige auf Nutzer zugeschnittener Werbung verwendet werden.</p>
      <p>Sofern Nutzer sich gegenüber einem Chatbot bereiterklären, Informationen mit regelmäßigen Nachrichten zu aktivieren, steht ihnen jederzeit die Möglichkeit zur Verfügung, die Informationen für die Zukunft abzubestellen. Der Chatbot weist Nutzer darauf hin, wie und mit welchen Begriffen sie die Nachrichten abbestellen können. Mit dem Abbestellen der Chatbotnachrichten werden Daten der Nutzer aus dem Verzeichnis der Nachrichtenempfänger gelöscht.</p>
      <p>Die vorgenannten Angaben nutzen wir, um unsere Chat-Dienste zu betreiben, z.B., um Nutzer persönlich anzusprechen, um ihre Anfragen zu beantworten, etwaige angeforderte Inhalte zu übermitteln und auch, um unsere Chat-Dienste zu verbessern (z.B., um Chatbots Antworten auf häufig gestellte Fragen "beizubringen“ oder unbeantwortete Anfragen zu erkennen).</p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen:</strong> Wir setzen die Chat-Dienste auf Grundlage einer Einwilligung ein, wenn wir zuvor eine Erlaubnis der Nutzer in die Verarbeitung ihrer Daten im Rahmen unserer Chat-Dienste eingeholt haben (dies gilt für die Fälle, in denen Nutzer um eine Einwilligung gebeten werden, z.B., damit ein Chatbot ihnen regelmäßig Nachrichten zusendet). Sofern wir Chat-Dienste einsetzen, um Anfragen der Nutzer zu unseren Leistungen oder unserem Unternehmen zu beantworten, erfolgt dies zur vertraglichen und vorvertraglichen Kommunikation. Im Übrigen setzen wir Chat-Dienste auf Grundlage unserer berechtigten Interessen an einer Optimierung der Chat-Dienste, ihrer Betriebswirtschaftlichkeit sowie einer Steigerung der positiven Nutzererfahrung ein.
      </p>
      <p>
        <strong>Widerruf, Widerspruch und Löschung: </strong>Sie können jederzeit eine erteilte Einwilligung widerrufen oder der Verarbeitung Ihrer Daten im Rahmen unserer Chat-Dienste widersprechen.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation, Direktmarketing (z.B. per E-Mail oder postalisch).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>HubSpot:</strong> Chatbot- und Assistenz-Software sowie verbundene Dienste; Dienstanbieter: HubSpot, Inc., 25 First St., 2nd floor, Cambridge, Massachusetts 02141, USA; Website:
          <a href="https://www.hubspot.de" target="_blank">https://www.hubspot.de</a>; Datenschutzerklärung: <a
          href="https://legal.hubspot.com/de/privacy-policy"
          target="_blank">https://legal.hubspot.com/de/privacy-policy</a>.
        </li>
      </ul>
      <h2 id="m1643">Push-Nachrichten</h2>
      <p>Mit der Zustimmung der Nutzer, können wir den Nutzern so genannte "Push-Benachrichtigungen" zusenden. Dabei handelt es sich um Nachrichten, die auf den Bildschirmen, Endgeräten oder in Browsern der Nutzer angezeigt werden, auch wenn unser Onlinedienst gerade nicht aktiv genutzt wird. </p>
      <p>Um sich für die Push-Nachrichten anzumelden, müssen Nutzer die Abfrage ihres Browsers bzw. Endgerätes zum Erhalt der Push-Nachrichten bestätigen. Dieser Zustimmungsprozess wird dokumentiert und gespeichert. Die Speicherung ist erforderlich, um zu erkennen, ob Nutzer dem Empfang der Push-Nachrichten zugestimmt haben sowie um die Zustimmung nachweisen zu können. Zu diesen Zwecken wird ein pseudonymer Identifikator des Browsers (sog. "Push-Token") oder die Geräte-ID eines Endgerätes gespeichert.</p>
      <p>Die Push-Nachrichten können zum einen für die Erfüllung von vertraglichen Pflichten erforderlich sein (z. B. für die Nutzung unseres Onlineangebotes relevante technische und organisatorische Informationen) und werden ansonsten, sofern nicht nachfolgend speziell genannt, auf Grundlage einer Einwilligung der Nutzer versendet. Nutzer können den Empfang der Push-Nachrichten jederzeit mit Hilfe der Benachrichtigungseinstellungen ihrer jeweiligen Browser, bzw. Endgeräte ändern.</p>
      <p><strong>Inhalte:</strong></p>
      <ul>
        <li>Hinweise wenn ein neues Modul freigeschalten wurde.</li>
        <li>Erinnerung an die Durchführung einer Gewohnheit.</li>
        <li>Technische Hinweise und Änderungen unserer Applikation sowie ihrer Funktionen.</li>
      </ul>
      <p><strong>Unsere Einstellungs- und Abbestellmöglichkeiten:</strong></p>
      <p>Unsere Push-Nachrichten können Sie in dem Bereich "Einstellungen" unserer App anpassen oder abbestellen.</p>
      <ul class="m-elements">
        <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen und Kundenservice.</li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>Firebase:</strong> Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
          <a href="https://firebase.google.com"
             target="_blank">https://firebase.google.com</a>; Datenschutzerklärung: <a
          href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.
        </li>
      </ul>
      <h2 id="m735">Videokonferenzen, Onlinemeetings, Webinare und Bildschirm-Sharing</h2>
      <p>Wir setzen Plattformen und Anwendungen anderer Anbieter (nachfolgend bezeichnet als “Drittanbieter”) zu Zwecken der Durchführung von Video und Audio-Konferenzen, Webinaren und sonstigen Arten von Video und Audio-Meetings ein. Bei der Auswahl der Drittanbieter und ihrer Leistungen beachten wir die gesetzlichen Vorgaben. </p>
      <p>In diesem Rahmen werden Daten der Kommunikationsteilnehmer verarbeitet und auf den Servern der Drittanbieter gespeichert, soweit diese Bestandteil von Kommunikationsvorgängen mit uns sind. Zu diesen Daten können insbesondere Anmelde- und Kontaktdaten, visuelle sowie stimmliche Beiträge sowie Eingaben in Chats und geteilte Bildschirminhalte gehören.</p>
      <p>Sofern Nutzer im Rahmen der Kommunikation, Geschäfts- oder anderen Beziehungen mit uns auf die Drittanbieter, bzw. deren Software oder Plattformen verwiesen werden, können die Drittanbieter Nutzungsdaten und Metadaten zu Sicherheitszwecken, Serviceoptimierung oder Marketingzwecken verarbeiten. Wir bitten daher, die Datenschutzhinweise der jeweiligen Drittanbieter zu beachten.</p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in den Einsatz der Drittanbieter oder bestimmter Funktionen bitten (z. B. Einverständnis mit einer Aufzeichnung von Gesprächen), ist die Rechtsgrundlage der Verarbeitung die Einwilligung. Ferner kann deren Einsatz ein Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der Einsatz der Drittanbieter in diesem Rahmen vereinbart wurde. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Kommunikation mit unseren unser Kommunikationspartnern verarbeitet. In diesem Zusammenhang möchten wir Sie zusätzlich auf die Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung verweisen.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kommunikationspartner, Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen und Kundenservice, Kontaktanfragen und Kommunikation, Büro- und Organisationsverfahren.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>Google Hangouts / Meet:</strong> Messenger- und Konferenz-Software; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
          <a href="https://hangouts.google.com/"
             target="_blank">https://hangouts.google.com/</a>; Datenschutzerklärung: <a
          href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.
        </li>
        <li>
          <strong>Slack:</strong> Messenger- und Konferenz-Software; Dienstanbieter: Slack Technologies, Inc., 500 Howard Street, San Francisco, CA 94105, USA; Website:
          <a href="https://slack.com/intl/de-de/"
             target="_blank">https://slack.com/intl/de-de/</a>; Datenschutzerklärung: <a
          href="https://slack.com/intl/de-de/legal" target="_blank">https://slack.com/intl/de-de/legal</a>.
        </li>
      </ul>
      <h2 id="m29">Cloud-Dienste</h2>
      <p>Wir nutzen über das Internet zugängliche und auf den Servern ihrer Anbieter ausgeführte Softwaredienste (sogenannte "Cloud-Dienste", auch bezeichnet als "Software as a Service") für die folgenden Zwecke: Dokumentenspeicherung und Verwaltung, Kalenderverwaltung, E-Mail-Versand, Tabellenkalkulationen und Präsentationen, Austausch von Dokumenten, Inhalten und Informationen mit bestimmten Empfängern oder Veröffentlichung von Webseiten, Formularen oder sonstigen Inhalten und Informationen sowie Chats und Teilnahme an Audio- und Videokonferenzen.</p>
      <p>In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den Servern der Anbieter gespeichert werden, soweit diese Bestandteil von Kommunikationsvorgängen mit uns sind oder von uns sonst, wie im Rahmen dieser Datenschutzerklärung dargelegt, verarbeitet werden. Zu diesen Daten können insbesondere Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen, sonstigen Prozessen und deren Inhalte gehören. Die Anbieter der Cloud-Dienste verarbeiten ferner Nutzungsdaten und Metadaten, die von ihnen zu Sicherheitszwecken und zur Serviceoptimierung verwendet werden.</p>
      <p>Sofern wir mit Hilfe der Cloud-Dienste für andere Nutzer oder öffentlich zugängliche Webseiten Formulare o.a. Dokumente und Inhalte bereitstellen, können die Anbieter Cookies auf den Geräten der Nutzer für Zwecke der Webanalyse oder, um sich Einstellungen der Nutzer (z.B. im Fall der Mediensteuerung) zu merken, speichern.</p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir um eine Einwilligung in den Einsatz der Cloud-Dienste bitten, ist die Rechtsgrundlage der Verarbeitung die Einwilligung. Ferner kann deren Einsatz ein Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der Einsatz der Cloud-Dienste in diesem Rahmen vereinbart wurde. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h., Interesse an effizienten und sicheren Verwaltungs- und Kollaborationsprozessen) verarbeitet
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kunden, Beschäftigte (z.B. Angestellte, Bewerber, ehemalige Mitarbeiter), Interessenten, Kommunikationspartner.
        </li>
        <li><strong>Zwecke der Verarbeitung:</strong> Büro- und Organisationsverfahren.</li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>Google Cloud-Dienste:</strong> Cloud-Speicher-Dienste; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
          <a href="https://cloud.google.com/" target="_blank">https://cloud.google.com/</a>; Datenschutzerklärung:
          <a href="https://www.google.com/policies/privacy"
             target="_blank">https://www.google.com/policies/privacy</a>, Sicherheitshinweise: <a
          href="https://cloud.google.com/security/privacy"
          target="_blank">https://cloud.google.com/security/privacy</a>; Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):
          <a href="https://cloud.google.com/terms/data-processing-terms"
             target="_blank">https://cloud.google.com/terms/data-processing-terms</a>; <a
          href="https://cloud.google.com/terms/data-processing-terms"
          target="_blank">https://cloud.google.com/terms/data-processing-terms</a>.
        </li>
      </ul>
      <h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2>
      <p>Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen (nachfolgend "Newsletter“) nur mit der Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie für die Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu unseren Leistungen und uns.</p>
      <p>Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre E-Mail-Adresse angeben. Wir können Sie jedoch bitten, einen Namen, zwecks persönlicher Ansprache im Newsletter, oder weitere Angaben, sofern diese für die Zwecke des Newsletters erforderlich sind, zu tätigen.</p>
      <p>
        <strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem Newsletter erfolgt grundsätzlich in einem sogenannte Double-Opt-In-Verfahren. D.h., Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung ist notwendig, damit sich niemand mit fremden E-Mail-Adressen anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde- und des Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem Versanddienstleister gespeicherten Daten protokolliert.
      </p>
      <p>
        <strong>Löschung und Einschränkung der Verarbeitung: </strong> Wir können die ausgetragenen E-Mail-Adressen bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern, bevor wir sie löschen, um eine ehemals gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten zur dauerhaften Beachtung von Widersprüchen behalten wir uns die Speicherung der E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste (sogenannte "Blocklist") vor.
      </p>
      <p>Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen zu Zwecken des Nachweises seines ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an einem effizienten und sicheren Versandsystem.</p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen:</strong> Der Versand der Newsletter erfolgt auf Grundlage einer Einwilligung der Empfänger oder, falls eine Einwilligung nicht erforderlich ist, auf Grundlage unserer berechtigten Interessen am Direktmarketing, sofern und soweit diese gesetzlich, z.B. im Fall von Bestandskundenwerbung, erlaubt ist. Soweit wir einen Dienstleister mit dem Versand von E-Mails beauftragen, geschieht dies auf der Grundlage unserer berechtigten Interessen. Das Registrierungsverfahren wird auf der Grundlage unserer berechtigten Interessen aufgezeichnet, um nachzuweisen, dass es in Übereinstimmung mit dem Gesetz durchgeführt wurde.
      </p>
      <p><strong>Inhalte:</strong> Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.</p>
      <p>
        <strong>Messung von Öffnungs- und Klickraten</strong>: Die Newsletter enthalten einen sogenannte "web-beacon“, d.h., eine pixelgroße Datei, die beim Öffnen des Newsletters von unserem Server, bzw., sofern wir einen Versanddienstleister einsetzen, von dessen Server abgerufen wird. Im Rahmen dieses Abrufs werden zunächst technische Informationen, wie Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse und der Zeitpunkt des Abrufs, erhoben.
      </p>
      <p>Diese Informationen werden zur technischen Verbesserung unseres Newsletters anhand der technischen Daten oder der Zielgruppen und ihres Leseverhaltens auf Basis ihrer Abruforte (die mit Hilfe der IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt. Diese Analyse beinhaltet ebenfalls die Feststellung, ob die Newsletter geöffnet werden, wann sie geöffnet werden und welche Links geklickt werden. Diese Informationen werden den einzelnen Newsletterempfängern zugeordnet und in deren Profilen bis zu deren Löschung gespeichert. Die Auswertungen dienen uns dazu, die Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte an sie anzupassen oder unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu versenden.</p>
      <p>Die Messung der Öffnungsraten und der Klickraten sowie Speicherung der Messergebnisse in den Profilen der Nutzer sowie deren weitere Verarbeitung erfolgen auf Grundlage einer Einwilligung der Nutzer. </p>
      <p>Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich, in diesem Fall muss das gesamte Newsletterabonnement gekündigt, bzw. muss ihm widersprochen werden. In diesem Fall werden die gespeicherten Profilinformationen gelöscht.</p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).
        </li>
        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
        <li><strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder postalisch).</li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
        <li>
          <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Sie können den Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen, bzw. dem weiteren Empfang widersprechen. Einen Link zur Kündigung des Newsletters finden Sie entweder am Ende eines jeden Newsletters oder können sonst eine der oben angegebenen Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>SendGrid:</strong> E-Mail-Marketing-Plattform; Dienstanbieter: SendGrid, Inc. 1801 California Street, Suite 500 Denver, Colorado 80202, USA.; Website:
          <a href="https://sendgrid.com" target="_blank">https://sendgrid.com</a>; Datenschutzerklärung: <a
          href="https://sendgrid.com/policies/privacy/"
          target="_blank">https://sendgrid.com/policies/privacy/</a>.
        </li>
      </ul>
      <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
      <p>Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der Auswertung der Besucherströme unseres Onlineangebotes und kann Verhalten, Interessen oder demographische Informationen zu den Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser Onlineangebot oder dessen Funktionen oder Inhalte am häufigsten genutzt werden oder zur Wiederverwendung einladen. Ebenso können wir nachvollziehen, welche Bereiche der Optimierung bedürfen. </p>
      <p>Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B. unterschiedliche Versionen unseres Onlineangebotes oder seiner Bestandteile zu testen und optimieren.</p>
      <p>Zu diesen Zwecken können sogenannte Nutzerprofile angelegt und in einer Datei (sogenannte "Cookie") gespeichert oder ähnliche Verfahren mit dem gleichen Zweck genutzt werden. Zu diesen Angaben können z.B. betrachtete Inhalte, besuchte Webseiten und dort genutzte Elemente und technische Angaben, wie der verwendete Browser, das verwendete Computersystem sowie Angaben zu Nutzungszeiten gehören. Sofern Nutzer in die Erhebung ihrer Standortdaten eingewilligt haben, können je nach Anbieter auch diese verarbeitet werden.</p>
      <p>Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von Webanalyse, A/B-Testings und Optimierung keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der eingesetzten Software kennen nicht die tatsächliche Identität der Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in deren Profilen gespeicherten Angaben.</p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher), Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies), Konversionsmessung (Messung der Effektivität von Marketingmaßnahmen), Profiling (Erstellen von Nutzerprofilen).
        </li>
        <li><strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der IP-Adresse).</li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>Google Analytics:</strong> Reichweitenmessung und Webanalyse; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
          <a href="https://marketingplatform.google.com/intl/de/about/analytics/"
             target="_blank">https://marketingplatform.google.com/intl/de/about/analytics/</a>; Datenschutzerklärung:
          <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.
        </li>
      </ul>
      <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
      <p>Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet als "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um Grafiken, Videos oder Social-Media-Schaltflächen sowie Beiträge handeln (nachfolgend einheitlich bezeichnet als "Inhalte”).</p>
      <p>Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und zum Betriebssystem, zu verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten als auch mit solchen Informationen aus anderen Quellen verbunden werden.</p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit, Erbringung vertragliche Leistungen und Kundenservice.
        </li>
        <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>Font Awesome:</strong> Darstellung von Schriftarten und Symbolen; Dienstanbieter: Fonticons, Inc. ,6 Porter Road Apartment 3R, Cambridge, MA 02140, USA; Website:
          <a href="https://fontawesome.com/" target="_blank">https://fontawesome.com/</a>; Datenschutzerklärung:
          <a href="https://fontawesome.com/privacy" target="_blank">https://fontawesome.com/privacy</a>.
        </li>
        <li>
          <strong>Google Fonts:</strong> Wir binden die Schriftarten ("Google Fonts") des Anbieters Google ein, wobei die Daten der Nutzer allein zu Zwecken der Darstellung der Schriftarten im Browser der Nutzer verwendet werden. Die Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an einer technisch sicheren, wartungsfreien und effizienten Nutzung von Schriftarten, deren einheitlicher Darstellung sowie unter Berücksichtigung möglicher lizenzrechtlicher Restriktionen für deren Einbindung. Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
          <a href="https://fonts.google.com/" target="_blank">https://fonts.google.com/</a>; Datenschutzerklärung:
          <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.
        </li>
      </ul>
      <h2 id="m723">Planung, Organisation und Hilfswerkzeuge</h2>
      <p>Wir setzen Dienstleistungen, Plattformen und Software anderer Anbieter (nachfolgend bezeichnet als "Drittanbieter”) zu Zwecken der Organisation, Verwaltung, Planung sowie Erbringung unserer Leistungen ein. Bei der Auswahl der Drittanbieter und ihrer Leistungen beachten wir die gesetzlichen Vorgaben. </p>
      <p>In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den Servern der Drittanbieter gespeichert werden. Hiervon können diverse Daten betroffen sein, die wir entsprechend dieser Datenschutzerklärung verarbeiten. Zu diesen Daten können insbesondere Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen, sonstigen Prozessen und deren Inhalte gehören.</p>
      <p>Sofern Nutzer im Rahmen der Kommunikation, von Geschäfts- oder anderen Beziehungen mit uns auf die Drittanbieter bzw. deren Software oder Plattformen verwiesen werden, können die Drittanbieter Nutzungsdaten und Metadaten zu Sicherheitszwecken, zur Serviceoptimierung oder zu Marketingzwecken verarbeiten. Wir bitten daher darum, die Datenschutzhinweise der jeweiligen Drittanbieter zu beachten.</p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ferner kann deren Einsatz ein Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der Einsatz der Drittanbieter in diesem Rahmen vereinbart wurde. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kommunikationspartner, Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
      <ul class="m-elements">
        <li>
          <strong>Trello:</strong> Projektmanagement-Tool; Dienstanbieter: Trello Inc., 55 Broadway New York, NY 10006, USA, Mutterunternehmen: Atlassian Inc. (San Francisco, Harrison Street Location), 1098 Harrison Street, San Francisco, California 94103, USA; Website:
          <a href="https://trello.com/" target="_blank">https://trello.com/</a>; Datenschutzerklärung: <a
          href="https://trello.com/privacy" target="_blank">https://trello.com/privacy</a>.
        </li>
      </ul>
      <h2 id="m12">Löschung von Daten</h2>
      <p>Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind).</p>
      <p>Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.</p>
      <p>Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner im Rahmen der einzelnen Datenschutzhinweise dieser Datenschutzerklärung erfolgen.</p>
      <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
      <p>Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>
      <p>Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.</p>
      <h2 id="m10">Rechte der betroffenen Personen</h2>
      <p>Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:</p>
      <ul>
        <li>
          <strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong>
        </li>
        <li>
          <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.
        </li>
        <li>
          <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
        </li>
        <li>
          <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
        </li>
        <li>
          <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.
        </li>
        <li>
          <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu fordern.
        </li>
        <li>
          <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes Beschwerde einzulegen, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
        </li>
      </ul>
      <h2 id="m42">Begriffsdefinitionen</h2>
      <p>In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch sortiert.</p>
      <ul class="glossary">
        <li>
          <strong>IP-Masking:</strong> Als "IP-Masking” wird eine Methode bezeichnet, bei der das letzte Oktett, d.h., die letzten beiden Zahlen einer IP-Adresse, gelöscht wird, damit die IP-Adresse nicht mehr der eindeutigen Identifizierung einer Person dienen kann. Daher ist das IP-Masking ein Mittel zur Pseudonymisierung von Verarbeitungsverfahren, insbesondere im Onlinemarketing
        </li>
        <li>
          <strong>Konversionsmessung:</strong> Die Konversionsmessung (auch als "Besuchsaktionsauswertung" bezeichnet) ist ein Verfahren, mit dem die Wirksamkeit von Marketingmaßnahmen festgestellt werden kann. Dazu wird im Regelfall ein Cookie auf den Geräten der Nutzer innerhalb der Webseiten, auf denen die Marketingmaßnahmen erfolgen, gespeichert und dann erneut auf der Zielwebseite abgerufen. Beispielsweise können wir so nachvollziehen, ob die von uns auf anderen Webseiten geschalteten Anzeigen erfolgreich waren.
        </li>
        <li>
          <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden "betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
        </li>
        <li>
          <strong>Profiling:</strong> Als "Profiling“ wird jede Art der automatisierten Verarbeitung personenbezogener Daten bezeichnet, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen (je nach Art des Profilings gehören dazu Informationen betreffend das Alter, das Geschlecht, Standortdaten und Bewegungsdaten, Interaktion mit Webseiten und deren Inhalten, Einkaufsverhalten, soziale Interaktionen mit anderen Menschen) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf einer Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies und Web-Beacons eingesetzt.
        </li>
        <li>
          <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web Analytics bezeichnet) dient der Auswertung der Besucherströme eines Onlineangebotes und kann das Verhalten oder Interessen der Besucher an bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie sich interessieren. Dadurch können sie z.B. die Inhalte der Webseite besser an die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere Analysen zur Nutzung eines Onlineangebotes zu erhalten.
        </li>
        <li>
          <strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das Verhalten von Nutzern über mehrere Onlineangebote hinweg nachvollzogen werden kann. Im Regelfall werden im Hinblick auf die genutzten Onlineangebote Verhaltens- und Interessensinformationen in Cookies oder auf Servern der Anbieter der Trackingtechnologien gespeichert (sogenanntes Profiling). Diese Informationen können anschließend z.B. eingesetzt werden, um den Nutzern Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen entsprechen.
        </li>
        <li>
          <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
        </li>
        <li>
          <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.
        </li>
      </ul>

    </v-col>
  </v-row>
</v-container>
</template>
<style lang="sass" scoped>
.data-protection
  font-size: 0.875rem

  h2
    margin-top: 2rem
    margin-bottom: 1rem

  h3
    margin-top: 2rem
    margin-bottom: 1rem

  a
    color: var(--color-brand)

  ul
    margin-bottom: 1rem
    li
      margin-bottom: 0.5rem
</style>
