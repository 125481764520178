import { RouteConfig } from 'vue-router';
import UserList from './components/user-list.vue';

export const adminPaywallAccessRoutes: RouteConfig[] = [
  {
    path: '/paywall-zugriff',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'admin-paywall-access-user-list',
        path: '/',
        component: UserList,
        meta: {
          requiresAuth: true,
          title: 'Paywall-Zugriff',
          breadcrumbs: [
            { text: 'Paywall-Zugriff' },
          ],
        },
      },
    ],
  },
];
