<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
// @ts-ignore
import Sortable from 'sortablejs/modular/sortable.core.esm';
import { watch } from 'vue';
import { SortableEvent } from 'sortablejs';
import { Id } from '@/types';

@Component
export default class ActionList extends Vue {

  @Prop({ type: Array, required: true })
  readonly items!: any[];

  // Used for the key in the list item and for sorting to return a list of sorted ids.
  @Prop({ type: String, required: true })
  readonly itemId!: string;

  @Prop({ type: String, default: 'Noch keine Elemente vorhanden.' })
  readonly emptyListMessage!: string;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isSortable!: boolean;

  @Prop({ type: String, default: null })
  readonly sortHandle!: string | null;

  /**
   * The sorted items are used to show the list and are updated every time the items change. They are separated from the items to keep the
   * items that are coming from the store immutable.
   */
  sortedItems: any[] = [];

  /**
   * Data container that is always in sync with the sorted order (instead of the original items array). When sorting the items, the order of
   * the items and the sortedItems stays the same. So we need to have a separate list to give to the outside.
   */
  sortedIds: Id[] = [];

  get isListEmpty(): boolean {
    return this.sortedItems.length === 0;
  }

  mounted(): void {
    if (this.isSortable) {
      if (!this.sortHandle) {
        throw new Error('Sort handle must be set when sorting is enabled.');
      }

      watch(() => this.sortedItems, () => {
        this.sortedIds = this.sortedItems.map((item) => item[this.itemId]);
      }, { deep: true });
    }

    watch(() => this.items, (items) => {
      if (items.length > 0
        && !items[0][this.itemId]
      ) {
        throw new Error('No item with item id found.');
      }

      this.sortedItems = [...items];
    }, { deep: true, immediate: true });

    Sortable.create(
      this.$el.getElementsByClassName('v-list')[0],
      {
        animation: 150,
        onUpdate: (event: any) => {
          this.sortArray(this.sortedIds, event);
          this.$emit('sorted', this.sortedIds);
        },
        handle: this.sortHandle ?? '.sort-handle',
      }
    );
  }

  sortArray<T>(items: T[], event: SortableEvent): void {
    const movedItem = items.splice(event.oldIndex!, 1)[0];
    items.splice(event.newIndex!, 0, movedItem);
  }

  key(item: any): string {
    return item[this.itemId];
  }

}
</script>
<template>
<div class="action-list">
  <v-progress-linear
    v-if="isLoading"
    color="primary"
    indeterminate
  />

  <v-list
    flat
    class="py-0"
  >

    <v-list-item
      v-if="isListEmpty"
      disabled
    >
      <v-list-item-content>
        {{ emptyListMessage }}
      </v-list-item-content>
    </v-list-item>

    <template v-for="(item, itemIndex) in sortedItems">

      <v-list-item
        class="pl-4 pr-0"
        :ripple="false"
        :key="key(item)"
      >
        <v-list-item-content>
          <slot
            name="content"
            v-bind:item="item"
            v-bind:itemIndex="itemIndex"
          />
        </v-list-item-content>
        <v-list-item-action class="pr-4">
          <slot
            name="actions"
            v-bind:item="item"
            v-bind:itemIndex="itemIndex"
          />
        </v-list-item-action>

      </v-list-item>

    </template>

  </v-list>
</div>
</template>
<style lang="sass" scoped>
.v-list-item
  border-bottom: 1px solid var(--color-grey-8)

  &:last-child
    border-bottom: none

.v-list-item__content
  ::v-deep
    a
      font-weight: 600
      color: var(--color-brand)
      text-decoration: none

      &:hover
        color: var(--color-brand-3)

    .item-title
      font-weight: 600
      color: var(--color-grey-4)

    .details
      display: block
      margin-top: 0.25rem
      color: var(--color-grey-5)

.v-list-item__action
  min-height: 40px
  margin-top: 0.5rem
  margin-bottom: 0.5rem

  ::v-deep
    > span,
    > div,
    > .v-btn
      align-self: center

.v-list-item__action--stack
  flex-direction: row
  align-items: center
</style>
