import { defineStore } from 'pinia';
import { v4 } from 'uuid';
import { InternalSnack, Snack } from './types';

interface SnackbarState {
  snackQueue: InternalSnack[]
}

function initialState(): SnackbarState {
  return {
    snackQueue: [],
  };
}

export const useSnackbarStore = defineStore('snackbar', {
  state: (): SnackbarState => initialState(),
  getters: {
    firstSnackInQueue(state: SnackbarState): InternalSnack | undefined {
      return state.snackQueue[0];
    },
    snackQueueLength(state: SnackbarState): number {
      return state.snackQueue.length;
    },
  },
  actions: {

    // -- State management

    showSuccess(payload: Snack): void {
      const snack: InternalSnack = {
        id: v4(),
        type: 'success',
        ...payload,
      };

      this.snackQueue.push(snack);
    },

    showError(payload: Snack): void {
      const snack: InternalSnack = {
        id: v4(),
        type: 'error',
        ...payload,
      };

      this.snackQueue.push(snack);
    },

    deleteSnack(snack: InternalSnack): void {
      this.snackQueue = this.snackQueue.filter((currentSnack) => currentSnack.id !== snack.id);
    },

  },
});
