<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT } from '@/helpers/data';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, mountFormControl, wasValidationSuccessful, labelWithRequiredIndicator, isFieldShownAsContainingAnError } from '@/components/form';
import { VuetifySelectItem } from '@/application/types';
import { Time } from '@/types';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class DateTimeFormControl extends Vue implements FormControlComponent<Dayjs> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Dayjs>;

  @Prop({ type: Function, default: null })
  readonly isDateAllowed!: ((date: Dayjs) => boolean) | null;

  @Prop({ type: Number, default: 5 })
  readonly intervalMinutes!: number;

  @Prop({ type: Boolean, default: false })
  readonly isClearable!: boolean;

  readonly formControlId = createFormControlId();

  isMenuVisible = false;

  isFocused = false;
  isTouched = false;

  messages: string[] = [];

  internalDateValue = '';
  internalTimeValue: Time | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get formattedTextFieldValue(): string {
    if (this.internalDateValue === '') {
      return '';
    }

    const today = dayjs();
    if (today.isSame(this.internalDateValue, 'day')) {
      return 'Heute';
    }

    const yesterday = today.subtract(1, 'day');
    if (yesterday.isSame(this.internalDateValue, 'day')) {
      return 'Gestern';
    }

    const dayBeforeYesterday = yesterday.subtract(1, 'day');
    if (dayBeforeYesterday.isSame(this.internalDateValue, 'day')) {
      return 'Vorgestern';
    }

    const tomorrow = today.add(1, 'day');
    if (tomorrow.isSame(this.internalDateValue, 'day')) {
      return 'Morgen';
    }

    const dayAfterTomorrow = tomorrow.add(1, 'day');
    if (dayAfterTomorrow.isSame(this.internalDateValue, 'day')) {
      return 'Übermorgen';
    }

    return dayjs(this.internalDateValue).format(DATE_FORMAT);
  }

  get timesSelectItems(): VuetifySelectItem<Time>[] {
    const relevantTimeFrame = {
      timeFrom: new Time(0, 0, 0),
      timeTo: new Time(23, 55, 0),
    };

    const times = [];
    let lastUsedTime = relevantTimeFrame.timeFrom;
    while (lastUsedTime.isBefore(relevantTimeFrame.timeTo)) {
      times.push(lastUsedTime);
      lastUsedTime = lastUsedTime.add(this.intervalMinutes, 'minutes');
    }

    return times
      .map((time) => ({
        text: time.format('HH:mm'),
        value: time,
      }));
  }

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  dateChanged(): void {
    if (this.internalDateValue === null) {
      this.internalDateValue = '';
    }

    internalValuesChanged(this);

    this.isMenuVisible = false;
  }

  timeChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  allowedDates(date: string): boolean {
    return this.isDateAllowed !== null
      ? this.isDateAllowed(dayjs(date))
      : true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalDateValue = this.formControl.value === null
      ? ''
      : this.formControl.value.format('YYYY-MM-DD');
    this.internalTimeValue = this.formControl.value === null
      ? null
      : Time.fromDate(this.formControl.value);
  }

  formValueFromInternalValues(): FormControlValue<Dayjs> {
    return this.internalDateValue.trim().length > 0
      && this.internalTimeValue !== null
      ? dayjs(this.internalDateValue).startOf('day')
        .hour(this.internalTimeValue.hour)
        .minute(this.internalTimeValue.minute)
        .second(this.internalTimeValue.second)
      : null;
  }

}
</script>
<template>
<div class="form-control date-time-picker-form-control" v-bind="$attrs">
  <fieldset class="a-field pb-4">
    <legend>{{ labelWithRequiredIndicator(formControl) }}</legend>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-menu
          v-model="isMenuVisible"
          max-width="290"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <div class="form-control-input mb-0">
              <v-text-field
                :value="formattedTextFieldValue"
                label="Datum"
                readonly
                hide-details
                outlined
                :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
                v-on="on"
              >
                <template #append>
                  <font-awesome-icon :icon="['far', 'calendar']" />
                </template>
              </v-text-field>
            </div>
          </template>
          <v-date-picker
            v-model="internalDateValue"
            first-day-of-week="1"
            :allowed-dates="allowedDates"
            @input="dateChanged"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="mt-4 mt-md-0"
      >
        <v-autocomplete
          v-model="internalTimeValue"
          :items="timesSelectItems"
          @change="timeChanged"
          :error="isFieldShownAsContainingAnError(isFocused, isTouched, messages)"
          label="Uhrzeit"
          menu-props="auto"
          hide-details="auto"
          outlined
        />
      </v-col>
    </v-row>
  </fieldset>
  <a-form-control-messages
    :messages="messages"
    :is-focussed="isFocused"
    :is-touched="isTouched"
  />
</div>
</template>
<style lang="sass" scoped>
fieldset.a-field
  padding-top: 0.75rem

  ::v-deep .v-text-field
    margin-bottom: 0 !important

::v-deep .v-input__append-inner
  .fa-calendar
    margin-top: 4px
    margin-right: 0.25rem
    color: var(--color-grey-5)
</style>
