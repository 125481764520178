import { defineStore, storeToRefs } from 'pinia';
import { attachAdmin } from '@/helpers/default-parameter-helper';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { getImpersonationURLForUser, getUsersForApp } from './service';
import { GetImpersonationURLForUserQuery, GetUsersForAppQuery, User } from './types';

interface AdminUserManagementState {
  currentAppId: string | null;
  usersForCurrentApp: User[];
  idOfUserToImpersonate: string | null;

  getUsersForAppStatus: ActionStatus;
  getImpersonationURLForUserStatus: ActionStatus;
}

function initialState(): AdminUserManagementState {
  return {
    currentAppId: null,
    usersForCurrentApp: [],
    idOfUserToImpersonate: null,

    getUsersForAppStatus: ActionStatus.None,
    getImpersonationURLForUserStatus: ActionStatus.None,
  };
}

export const useAdminUserManagementStore = defineStore('adminUserManagement', {
  state: (): AdminUserManagementState => initialState(),
  getters: {
    isGetImpersonationURLForUserProcessingForUser: (state: AdminUserManagementState) => (userId: string): boolean =>
      state.getImpersonationURLForUserStatus === ActionStatus.InProgress
        && state.idOfUserToImpersonate === userId,

    isGetUsersForAppProcessing: (state: AdminUserManagementState): boolean =>
      state.getUsersForAppStatus === ActionStatus.InProgress,
    isGetImpersonationURLForUserProcessing: (state: AdminUserManagementState): boolean =>
      state.getImpersonationURLForUserStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentApp(appId: string): Promise<void> {
      this.currentAppId = appId;

      return this.getUsersForCurrentApp();
    },

    // -- Queries

    getUsersForCurrentApp(): Promise<void> {
      const query: GetUsersForAppQuery = {
        appId: this.currentAppId!,
      };
      const { getUsersForAppStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUsersForAppStatus,
        () => getUsersForApp(attachAdmin(query))
          .then(async (users) => {
            this.usersForCurrentApp = users;
          })
      );
    },

    getImpersonationURLForUser(query: GetImpersonationURLForUserQuery): Promise<string> {
      this.idOfUserToImpersonate = query.userId;
      const { getImpersonationURLForUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getImpersonationURLForUserStatus,
        () => getImpersonationURLForUser(attachAdmin(query))
          .then(async (impersonationConfiguration) => impersonationConfiguration.impersonationURL)
          .finally(() => {
            this.idOfUserToImpersonate = null;
          })
      );
    },

  },
});
