import { apiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { AppAndUserProperty } from '@/helpers/default-parameter-helper';
import { CancelUserInviteCommand, InviteUserForMachtfitCommand, InviteUserForProFitCommand, InviteUsersWithDirectLicenseCommand, ResendUserInviteCommand, UserInvite } from './types';

// -- Queries

export function getUserInvites(query: AppAndUserProperty): Promise<UserInvite[]> {
  const url = `${apiUrl}/api/user-management/get-user-invites-query`;
  return performApiRequest<UserInvite[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function inviteUserForMachtfit(command: InviteUserForMachtfitCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/invite-user-for-machtfit-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function inviteUserForProFit(command: InviteUserForProFitCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/invite-user-for-profit-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function inviteUsersWithDirectLicense(command: InviteUsersWithDirectLicenseCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/invite-users-with-direct-license-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function resendUserInvite(command: ResendUserInviteCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/resend-user-invite-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function cancelUserInvite(command: CancelUserInviteCommand & AppAndUserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/cancel-user-invite-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
