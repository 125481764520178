<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isNativeApplication } from '@/helpers/detection-helpers';

/**
 * The event `close-icon-menu` must be send from the list item to the $root to close the menu when it's itself a slot.
 *
 * A full example might look like this:
 *
 * <icon-menu small :icon="['far', 'pencil']">
 *   <edit-module-title-dialog :module="module">
 *     <v-list-item @click="$root.$emit('close-icon-menu')">
 *       <v-list-item-title>Bezeichnung anpassen</v-list-item-title>
 *     </v-list-item>
 *   </edit-module-title-dialog>
 *   <v-list-item :to="{ name: 'manage-modules-content-management', params: { id: module.id }}">
 *     <v-list-item-title>Inhalte bearbeiten</v-list-item-title>
 *   </v-list-item>
 * </icon-menu>
 */

@Component
export default class IconMenu extends Vue {

  @Prop({ type: Array, default: () => ['far', 'exclamation-circle'] })
  readonly icon!: string[];

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: String })
  readonly tooltip!: string;

  @Prop({ type: String, default: '' })
  readonly disabledTooltip!: string;

  @Prop({ type: String })
  readonly text!: string;

  @Prop({ type: Boolean, default: false })
  readonly small!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly statusIcon!: boolean;

  @Prop({ type: String, default: 'text' })
  readonly buttonType!: string;

  @Prop({ type: String, default: '' })
  readonly buttonColor!: string;

  isMenuOpen = false;

  get isTooltipDisabled(): boolean {
    return isNativeApplication() || (!this.tooltip && !this.$slots.tooltip && !this.disabledTooltip && !this.$slots.disabledTooltip);
  }

  get isButtonTypeText(): boolean {
    return this.buttonType === 'text';
  }

  mounted(): void {
    this.$root.$on('close-icon-menu', () => {
      this.isMenuOpen = false;
    });
  }

}
</script>
<template>
<div class="icon-menu">
  <v-menu v-model="isMenuOpen">
    <template #activator="{ on: menu }">
      <v-tooltip bottom :disabled="isTooltipDisabled" :max-width="200">
        <template #activator="{ on: tooltip }">
          <div v-on="tooltip">
            <v-btn
              :text="isButtonTypeText"
              :color="buttonColor"
              :disabled="disabled"
              depressed
              v-on="menu"
              :class="{ 'small': small }"
            >
              <font-awesome-icon :icon="icon" />
              <span class="text" v-if="text">{{ text }}</span>
            </v-btn>
          </div>
        </template>
        <slot name="tooltip" v-if="!disabled">
          <span>{{ tooltip }}</span>
        </slot>
        <slot name="disabledTooltip" v-if="disabled">
          <span>{{ disabledTooltip }}</span>
        </slot>
      </v-tooltip>
    </template>
    <v-list>
      <slot />
    </v-list>
  </v-menu>
</div>
</template>
<style lang="sass">
.icon-menu
  display: inline-block

  .v-btn
    outline: none

    .text
      margin-left: 0.5rem

    svg
      margin: 0 !important
      outline: none

    &.v-btn--disabled
      background-color: transparent !important

    &.small
      padding: 6px 8px !important
      min-width: auto !important
      height: 31px !important
      margin: 0

    &.no-action
      height: auto
      cursor: default
      transition: none

      // Disables animation on button leave
      &:before
        transition: none

      // Disables hover animation
      &:hover:before
        background-color: transparent

      // Disables click animation
      .v-ripple__container
        display: none

    &.status-icon
      svg
        font-size: 1.2rem
        margin-top: 4px

// Vuetify uses an important style and therefore we have to define the whole path
.theme--light
  .icon-button
    .v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
      background-color: transparent !important
</style>
