<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import dayjs, { Dayjs } from 'dayjs';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';

@Component
export default class NutritionDiaryStep extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  get threeDaysAgo(): Dayjs {
    return dayjs().subtract(3, 'days');
  }

  get porridgeUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nutrition-diary-introduction/porridge.jpg');
  }

  get salmonUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nutrition-diary-introduction/salmon.jpg');
  }

  get saladUrl(): string {
    // @ts-ignore
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require('@/assets/images/nutrition-diary-introduction/salad.jpg');
  }

  submitClicked(): void {
    this.$emit('submitConfirmed');
  }

  skipClicked(): void {
    this.$emit('skipConfirmed');
  }

}
</script>
<template>
<div class="nutrition-diary">
  <v-card-text class="pb-0">
    <h2>Ernährungstagebuch</h2>
    <p>
      Kalorien zählen war gestern! Stattdessen geht es im Ernährungstagebuch darum, sich einen besseren Überblick über das eigene Ernährungsverhalten zu verschaffen. Schieß beim Essen einfach kurz ein Foto, lade es hoch, ergänze ein paar Informationen und fertig ist der Tagebucheintrag. Schon hast du eine Übersicht, wie du dich die letzten Tage ernährt hast. So kannst du konkrete Veränderungen einleiten und beobachten, was gut bei dir klappt.
    </p>

    <hr class="mt-6 mb-6" />

    <h4>Ein Beispiel</h4>

    <p>
      Sanne hat vor 3 Tagen mit dem Ernährungstagebuch begonnen. Am ersten Tag ist sie total motiviert mit einem Porridge in den Tag gestartet. Mittags gab es Fisch und abends einen großen Salat.
    </p>

    <v-timeline
      dense
      align-top
      class="mt-4"
    >
      <v-timeline-item small>
        <div class="timeline-entry">
          <strong class="date">{{ threeDaysAgo | formatDate }}</strong>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="4"
              class="mt-2"
            >
              <v-row class="entry no-gutters">
                <v-col cols="4">
                  <v-card outlined class="overflow-hidden">
                    <div class="image-container">
                      <blur-hash-image
                        :src="porridgeUrl"
                        hash="LFD]k^xG008_?^ogIADi56odxusp"
                        alt="09:00 Uhr"
                        :width="20"
                        :height="Math.floor(20 / 1.5)"
                      />
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="8">
                  <div class="details">
                    <strong class="d-block time">09:00 Uhr</strong>
                    <span class="description">War ziemlich voll danach. Kaffee dazu war nötig.</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              md="6"
              lg="4"
              class="mt-2"
            >
              <v-row class="entry no-gutters">
                <v-col cols="4">
                  <v-card outlined class="overflow-hidden">
                    <div class="image-container">
                      <blur-hash-image
                        :src="salmonUrl"
                        hash="LHHUUbAI-UOZ}6MxE2IqRB?GRjR*"
                        alt="12:05 Uhr"
                        :width="20"
                        :height="Math.floor(20 / 1.5)"
                      />
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="8">
                  <div class="details">
                    <strong class="d-block time">12:05 Uhr</strong>
                    <span class="description">Das Kartoffelpüree dazu war ne gute Idee.</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              md="6"
              lg="4"
              class="mt-2"
            >
              <v-row class="entry no-gutters">
                <v-col cols="4">
                  <v-card outlined class="overflow-hidden">
                    <div class="image-container">
                      <blur-hash-image
                        :src="saladUrl"
                        hash="LqKv7_%fx[%1}?x[XSaf%1S#WVjF"
                        alt="18:30 Uhr"
                        :width="20"
                        :height="Math.floor(20 / 1.5)"
                      />
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="8">
                  <div class="details">
                    <strong class="d-block time">18:30 Uhr</strong>
                    <span class="description">Fühle mich sehr gut nach dem Salat.</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>

          </v-row>
        </div>
      </v-timeline-item>
    </v-timeline>

    <hr class="mt-6 mb-6" />

    <p>
      Solltest du das Eintragen mal einen Tag vergessen haben, gar kein Problem: Du kannst die Einträge auch im Nachhinein erfassen!
    </p>

  </v-card-text>

  <v-divider />
  <v-card-actions>
    <v-spacer />
    <a-cancel-action-button @click="skipClicked">
      Einführung überspringen
    </a-cancel-action-button>
    <a-trigger-primary-process-button @click="submitClicked">
      Weiter
    </a-trigger-primary-process-button>
  </v-card-actions>

</div>
</template>
<style lang="sass" scoped>
.v-timeline
  font-size: 0.875rem

.entry
  display: flex

  .image-container
    flex-basis: 120px
    flex-shrink: 0
    overflow: hidden
    position: relative
    background: var(--color-grey-8)

    // ::before and ::after are to add aspect ratio
    &::before
      content: ""
      width: 1px
      margin-left: -1px
      float: left
      height: 0
      padding-top: 133px / 200px * 100%

    &::after
      content: ""
      display: table
      clear: both

    ::v-deep .delete-nutrition-diary-entry-image
      position: absolute
      top: 2px
      right: 2px

      .v-btn
        background: rgba(255, 255, 255, 0.5)
        border-radius: 50%
        padding: 8px 4px !important
        overflow: hidden
        font-size: 12px
        height: 16px !important

        svg
          color: var(--color-grey-3)

    .image-placeholder
      display: flex
      align-items: center
      justify-content: center
      height: 100%
      padding: 0.75rem 0

      svg
        display: block
        cursor: pointer
        font-size: 3rem
        color: var(--color-grey-6)

  ::v-deep .details
    padding: 0.25rem 0.25rem 0 0.75rem
    flex-grow: 1
    cursor: help

    .description
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      text-overflow: ellipsis
      overflow: hidden
      max-height: 48px
</style>
