<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { ExtensionOption } from '@/components/form/html-form-control.vue';
import { useManageExperienceReportsStore } from '../store';
import { ExperienceReport, UpdateExperienceReportCommand } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
  ageInYears: FormControl<number>;
  job: FormControl<string>;
  familySituation: FormControl<string>;
  content: FormControl<string>;
  image: FormControl<File>;
}

@Component
export default class UpdateExperienceReportDialog extends Vue {

  readonly store = useManageExperienceReportsStore();

  @Prop({ type: Object, required: true })
  readonly experienceReport!: ExperienceReport;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get enabledExtensions(): ExtensionOption[] {
    return [
      'h2',
      'h3',
      'bold',
      'italic',
      'bullet-list',
      'ordered-list',
    ];
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: this.experienceReport.name,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        ageInYears: {
          label: 'Alter in Jahren',
          value: this.experienceReport.ageInYears,
          isRequired: true,
        },
        job: {
          label: 'Beruf',
          value: this.experienceReport.job,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        familySituation: {
          label: 'Familiensituation',
          value: this.experienceReport.familySituation,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        content: {
          label: 'Inhalt',
          value: this.experienceReport.content,
          isRequired: true,
        },
        image: {
          label: 'Profilfoto der Person',
          value: null,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateExperienceReportCommand = {
      body: {
        experienceReportId: this.experienceReport!.experienceReportId,
        name: formValues.name!,
        ageInYears: formValues.ageInYears!,
        job: formValues.job!,
        familySituation: formValues.familySituation!,
        content: formValues.content!,
      },
      files: {
        image: formValues.image,
      },
    };

    this.store.updateExperienceReport(command)
      .then(() => showSuccessMessage('Erfahrungsbericht wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isUpdateExperienceReportProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Erfahrungsbericht anpassen</v-card-title>
      <v-card-text>
        <a-image-cropper-form-control
          :form-control="form.controls.image"
          :image-width="1000"
        />
        <v-row>
          <v-col cols="12" md="6">
            <a-text-form-control
              :form-control="form.controls.name"
              is-autofocused
            />
          </v-col>
          <v-col cols="12" md="6">
            <a-number-form-control :form-control="form.controls.ageInYears" />
          </v-col>
          <v-col cols="12" md="6">
            <a-text-form-control :form-control="form.controls.job" />
          </v-col>
          <v-col cols="12" md="6">
            <a-text-form-control :form-control="form.controls.familySituation" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-html-form-control
              :form-control="form.controls.content"
              :extensions="enabledExtensions"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isUpdateExperienceReportProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isUpdateExperienceReportProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
