import { isNavigationFailure } from 'vue-router';
import { useSnackbarStore } from './store';
import { Snack } from './types';

export function showSuccess(snack: Snack): void {
  useSnackbarStore().showSuccess(snack);
}

export function showError(snack: Snack): void {
  useSnackbarStore().showError(snack);
}

export function showErrorResponse(error: any): void {
  if (isNavigationFailure(error)) {
    return;
  }

  useSnackbarStore().showError({ message: messageFromError(error) });
}

export function showSuccessMessage(message: string, timeout?: number): void {
  showSuccess({ message, timeout });
}

export function showErrorMessage(message: string, timeout?: number): void {
  showError({ message, timeout });
}

export function messageFromError(error: any): string {
  return error?.response?.data.message || 'Ein interner Fehler ist aufgetreten.';
}
